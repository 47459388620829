/**
 *
 *
 * <CurrentChallenges />
 *
 *
 */
import React from "react";

import { ChallengeList } from "../../components/ChallengeListV3";
import { CreateChallenge } from "./CreateChallenge";
import { Header } from "./Header";
import { useChallenges } from "./useChallenges";

export const UpcomingChallenges = (props: ReturnType<typeof useChallenges>) => {
  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="px-2 sm:px-8">
        <Header section="upcoming" count={props.upcomingChallenges.length} />
      </div>
      <ChallengeList
        challenges={props.upcomingChallenges}
        isLoadingChallenges={props.isLoading}
        section="upcoming"
        isListMode={props.currentChallenges.length === 0}
        className="flex w-full flex-wrap justify-center gap-y-10"
      />
      <CreateChallenge {...props} />
    </div>
  );
};
