/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `HOME` - Home
 * `OFFICE` - Office
 * `MOBILE` - Mobile
 * `OTHER` - Other
 */
export type PhoneNumberLabel =
  (typeof PhoneNumberLabel)[keyof typeof PhoneNumberLabel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhoneNumberLabel = {
  HOME: "HOME",
  OFFICE: "OFFICE",
  MOBILE: "MOBILE",
  OTHER: "OTHER",
} as const;
