/**
 *
 *
 * <CompletedBadge />
 *
 *
 */
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Activity } from "../../services/teambuilder/schemas";

interface Props {
  activity: Partial<Activity>;
  badgeColor: string;
  isComment?: boolean;
}

export const CompletedBadge = ({ activity, badgeColor, isComment }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {activity.isCompleted && (
        <div
          className={clsx(
            "flex h-6 w-full items-center px-3 text-xs text-white",
            badgeColor
          )}
        >
          {isComment ? (
            <>{t("translation:challenge:activity_completed_1")}</>
          ) : (
            <>
              {t("translation:challenge:completed")}{" "}
              {/* {dayjs(activity?.completedOn).format("MMM D")} */}
            </>
          )}
        </div>
      )}
    </>
  );
};
