/**
 *
 *
 * <Bio>
 *
 *
 */
import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { PeopleRetrieve, UserGuides } from "../../services/teambuilder/schemas";
import { firstName } from "../../utils/username";
import { EmptySection } from "./EmptySection";

interface Props {
  userGuide: UserGuides;
  openEditProfileInfoForm: () => void;
  user: PeopleRetrieve;
  userIsMe: boolean;
}

export const Bio = (props: Props) => {
  const { t } = useTranslation();
  const shouldTruncate =
    props.userGuide?.bio && props.userGuide.bio?.length > 200;
  const [isTruncated, setIsTruncated] = React.useState(shouldTruncate);
  return (
    <div>
      <span className="sr-only">{t("translation:user_guide:bio")}</span>

      {props.userGuide.bio ? (
        <div className="hyphens-auto text-sm leading-loose text-zinc-900">
          {shouldTruncate
            ? isTruncated
              ? `${props.userGuide.bio.substring(0, 200)}...`
              : props.userGuide.bio
            : props.userGuide.bio}

          {shouldTruncate && (
            <button
              className="ml-1 text-zinc-500 underline hover:text-zinc-600"
              onClick={() => setIsTruncated(!isTruncated)}
            >
              {isTruncated
                ? t("translation:common:more")
                : t("translation:common:show_less")}
            </button>
          )}
        </div>
      ) : props.userIsMe ? (
        <EmptySection>
          {t("translation:user_guide:create_bio_description")}

          <Button
            size="md"
            variant="highlight"
            onClick={props.openEditProfileInfoForm}
          >
            <PlusIcon className="h-5 w-5 text-white" />
            {t("translation:user_guide:create_bio")}
          </Button>
        </EmptySection>
      ) : (
        <div className="pt-4 text-sm text-zinc-500">
          {t("translation:user_guide:name_no_bio", {
            name: firstName(props.user),
          })}
        </div>
      )}
    </div>
  );
};
