/**
 *
 *
 * SkeletonPostItem
 *
 *
 */
import clsx from "clsx";

import { Avatar } from "../AvatarV3";

interface Props {
  className?: string;
}

export const SkeletonPostListItem = (props: Props) => {
  return (
    <li className={clsx("animate-pulse space-y-4 px-4 pb-4", props.className)}>
      <div className="flex gap-4">
        <div>
          <Avatar loading size="lg" />
        </div>

        <div className="h-12 flex-grow rounded bg-zinc-200" />
      </div>

      <div className="space-y-2">
        <div className="h-6 w-full rounded bg-zinc-200" />

        <div className="h-6 w-full rounded bg-zinc-200" />
      </div>
    </li>
  );
};
