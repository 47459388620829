/**
 *
 *
 * <Banter />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { BanterSettings } from "../BanterSettingsV3";
import { BanterToolbox } from "../BanterToolboxV3";

export const Banter = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="border-b border-b-zinc-300 px-8 py-6 text-lg text-zinc-500">
        {t("translation:settings:menu:banter")}
      </div>
      <div className="animate-crossfadein">
        <BanterSettings />
        <BanterToolbox />
      </div>
    </>
  );
};
