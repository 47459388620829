export const convertHeic = async (img: string) => {
  const { default: heic2any } = await import("heic2any");
  const blobRes = await fetch(img);
  const blob = await blobRes.blob();
  const heicBlob = (await heic2any({
    blob,
    multiple: undefined,
    toType: "image/jpeg",
    quality: 0.3,
  })) as Blob;
  const imgFile = new File([heicBlob], `my_image${new Date()}.jpeg`, {});
  return imgFile;
};
