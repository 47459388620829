/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type { AsdsListParams, PaginatedAdsList } from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Advertising feed
 */
export const asdsList = (
  params?: AsdsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedAdsList>(
    { url: `/api/v1/asds/`, method: "GET", params, signal },
    options
  );
};

export const getAsdsListQueryKey = (params?: AsdsListParams) => {
  return [`/api/v1/asds/`, ...(params ? [params] : [])] as const;
};

export const getAsdsListQueryOptions = <
  TData = Awaited<ReturnType<typeof asdsList>>,
  TError = ErrorType<unknown>,
>(
  params?: AsdsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof asdsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAsdsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof asdsList>>> = ({
    signal,
  }) => asdsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof asdsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AsdsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof asdsList>>
>;
export type AsdsListQueryError = ErrorType<unknown>;

export const useAsdsList = <
  TData = Awaited<ReturnType<typeof asdsList>>,
  TError = ErrorType<unknown>,
>(
  params?: AsdsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof asdsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAsdsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
