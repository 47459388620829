/**
 *
 *
 * <PrimaryNav />
 *
 */
import { Menu } from "@headlessui/react";

import { NavItem } from "../../components/NavItem";
import { NotificationsNavItem } from "../../components/NotificationsNavItem";
import { useNavItems } from "../../hooks/useNavItems";
import { Language } from "../LanguageV3";

interface Props {
  navItems: ReturnType<typeof useNavItems>;
  close: () => void;
}

export const PrimaryNav = (props: Props) => {
  return (
    <>
      <div className="space-y-6 py-6">
        <ul className="list-none space-y-2.5">
          {props.navItems.primary.map((item, i) => (
            <Menu.Item key={i}>
              <li>
                <NavItem
                  item={{
                    ...item,
                    onClick: () => {
                      item.onClick?.();
                      props.close();
                    },
                  }}
                />
              </li>
            </Menu.Item>
          ))}
        </ul>

        <div className="border-t border-zinc-300 pt-6">
          <Menu.Item>
            <div>
              <NotificationsNavItem />
            </div>
          </Menu.Item>

          <Menu.Item>
            <div>
              <Language />
            </div>
          </Menu.Item>
        </div>

        <ul className="list-none space-y-2.5 border-t border-zinc-300 pt-6">
          {props.navItems.secondary.map((item, i) => (
            <Menu.Item key={i}>
              <li>
                <NavItem
                  item={{
                    ...item,
                    onClick: () => {
                      item.onClick?.();
                      props.close();
                    },
                  }}
                />
              </li>
            </Menu.Item>
          ))}
        </ul>
      </div>
    </>
  );
};
