/**
 *
 *
 * MixPanel
 *
 *
 */
import mixpanel from "mixpanel-browser";

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
const api_host =
  import.meta.env.VITE_MIXPANEL_PROXY || "https://api.mixpanel.com";

export const initMixpanel = () =>
  mixpanelToken && mixpanel.init(mixpanelToken, { api_host });

export const trackPageView = () => {
  if (mixpanelToken) {
    const { searchParams } = new URL(window.location.href);

    mixpanel.track("Page View", {
      utm_campaign: searchParams.get("utm_campaign"),
      utm_source: searchParams.get("utm_source"),
      utm_medium: searchParams.get("utm_medium"),
      utm_content: searchParams.get("utm_content"),
      utm_term: searchParams.get("utm_term"),
      ref: searchParams.get("ref"),
    });
  }
};

export const identify = (id: string) => {
  if (mixpanelToken) {
    mixpanel.identify(id);
  }
};

export const track = (event: string, properties?: Record<string, string>) => {
  if (mixpanelToken) {
    mixpanel.track(event, properties);
  }
};

export const peopleSet = (properties: Record<string, string>) => {
  if (mixpanelToken) {
    mixpanel.people.set_once(properties);
  }
};
