/**
 *
 *
 * <IconChallenge />
 *
 *
 */
export const IconChallenge = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        aria-hidden="true"
        data-slot="icon"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3143 2.71846L12 0L9.68572 2.71846L6.38238 1.39479L5.58728 4.88527L2.05166 5.25964L2.95792 8.72252L0 10.7092L2.40001 13.3511L0.697398 16.4949L4.04134 17.7107L3.98409 21.2915L7.50591 20.8026L9.10714 24L12 21.9184L14.8929 24L16.4942 20.8026L20.016 21.2915L19.9587 17.7107L23.3027 16.4949L21.6001 13.3511L24.0001 10.7092L21.0421 8.72252L21.9484 5.25964L18.4128 4.88527L17.6177 1.39479L14.3143 2.71846ZM12.0001 20.2949C16.4509 20.2949 20.0589 16.6603 20.0589 12.1769C20.0589 7.6935 16.4509 4.05897 12.0001 4.05897C7.54938 4.05897 3.94134 7.6935 3.94134 12.1769C3.94134 16.6603 7.54938 20.2949 12.0001 20.2949Z"
          fill="currentColor"
          scale={0.5}
        />
      </svg>
    </>
  );
};
