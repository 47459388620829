/**
 *
 *
 * useGetPinnedPosts
 *
 * Used by FeaturedPosts to fetch pinned posts, and by
 * Post to refetch posts after a post is pinned.
 *
 */
import {
  getSocialPostsListQueryKey,
  useSocialPostsList,
} from "../services/teambuilder/endpoints/social/social";
import { SocialPostsListParams } from "../services/teambuilder/schemas";

const params: SocialPostsListParams = {
  limit: 999,
  offset: 0,
  ordering: "-created_at",
  pinned: true,
};

export const queryKey = getSocialPostsListQueryKey(params);

export const useGetPinnedPosts = () => {
  const response = useSocialPostsList(params, {
    query: {
      select: ({ data }) => data,
    },
  });

  return {
    queryKey,
    response,
  };
};
