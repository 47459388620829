/**
 *
 *
 * <SkeletonChallengeListItem />
 *
 *
 */
import clsx from "clsx";
import React from "react";

export const SkeletonChallengeVerticalListItem = () => {
  return (
    <li className="w-72 max-w-sm flex-grow animate-pulse p-2">
      <div className="block:bg-zinc-50">
        <div className="relative pt-14">
          <div
            className={clsx(
              "w-full p-0 pt-24 shadow-md",
              "rounded-lg bg-white",
              "flex flex-col gap-y-0 overflow-hidden"
            )}
          >
            <div className="flex flex-grow flex-col gap-y-4 p-4">
              <div className="h-10 w-full rounded-lg bg-zinc-300 p-2 py-4"></div>
              <div className="h-10 w-full flex-grow rounded-lg bg-zinc-300 pl-0"></div>
            </div>
            <div className="h-10 w-full rounded-none bg-zinc-300 p-2 py-4"></div>
          </div>
          <div
            className={clsx(
              "absolute h-40 w-40 border-4 border-white",
              "left-1/2 top-0 -translate-x-1/2",
              "rounded-full bg-zinc-300"
            )}
          />
        </div>
      </div>
    </li>
  );
};
