/**
 *
 *
 * <CopyButton />
 *
 *
 */
import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  beforeTooltip?: string;
  afterTooltip?: string;
  children?: React.ReactNode;
  className?: string;
}

export const CopyButton = ({
  value,
  beforeTooltip = "copy_to_clipboard",
  afterTooltip = "copied",
  children,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const copy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
    });
  };
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <button
      className={clsx(className)}
      onClick={() => copy()}
      data-tooltip-id="tooltip"
      data-tooltip-content={
        copied
          ? afterTooltip === "copied"
            ? t("translation:common:copied")
            : afterTooltip
          : beforeTooltip === "copy_to_clipboard"
            ? t("translation:common:copy_to_clipboard")
            : beforeTooltip
      }
      data-tooltip-place="bottom"
      data-tooltip-delay-show={500}
    >
      {children}
    </button>
  );
};
