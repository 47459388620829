/**
 *
 *
 * <CreateChallengeButton />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const CreateChallengeButton = () => {
  const { t } = useTranslation();
  return (
    <Link
      to="/challenges/wizard"
      className="group flex items-center hover:underline"
    >
      <PlusIcon className="h-5 w-5 text-zinc-400 group-hover:text-zinc-500" />
      <span className="text-sm font-medium leading-5 text-zinc-500 group-hover:text-zinc-600">
        {t("translation:challenge:create_challenge")}
      </span>
    </Link>
  );
};
