import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  getValuesListQueryKey,
  useValuesCreate,
  useValuesDestroy,
  useValuesList,
  useValuesPartialUpdate,
} from "../../services/teambuilder/endpoints/values/values";
import {
  PaginatedValuesModelList,
  PatchedValuesModelRequest,
  ValuesListParams,
  ValuesModel,
  ValuesModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { FormData } from "./ValueForm";

export const useValues = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has("values");
  const onClose = () => setSearchParams({});
  const { openToast } = useToast();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  // Preserve the value id for updates and deletes
  const [valueId, setValueId] = useState<number | undefined>();
  // Scroll to the last item in the values list
  const [shouldScroll, setShouldScroll] = useState(false);
  const [editValueIds, setEditValueIds] = useState<Array<number>>([]);
  const [isAddValue, setIsAddValue] = useState(false);

  const showToast = (message: string, type: "success" | "danger") => {
    openToast({
      title: message,
      type,
    });
  };

  const { isIT, isHR } = useRoles();

  const params: ValuesListParams = {
    ordering: "position",
  };

  const {
    data: values,
    isError,
    isLoading,
  } = useValuesList(params, {
    query: {
      select: ({ data }) => data,
    },
  });
  const queryKey = getValuesListQueryKey(params);

  useEffect(() => {
    if (!isOpen) {
      setEditValueIds([]);
      setIsAddValue(false);
      setValueId(undefined);
    } else {
      if (values && values.length === 0) setIsAddValue(true);
    }
  }, [isOpen]);
  /**
   *
   * Create
   *
   */
  // Define generics for <error, context>. Context is the return value of onMutate.
  // Context is passed from onMutate to onSuccess and onError.

  const { mutate: createValue } = useValuesCreate(
    optimisticMutationOptions<
      ValuesModel,
      PaginatedValuesModelList,
      { data: ValuesModelRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        setIsAddValue(false);
        setShouldScroll(true);
        return {
          ...context,
          data: [...context.data, requestVariables?.data as ValuesModel],
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:value_add_success"), "success");
      },
      onError: () => {
        showToast(t("translation:toast:value_add_failed"), "danger");
      },
    })
  );

  /**
   *
   * Destroy
   *
   */

  const { mutate: destroyValue } = useValuesDestroy(
    optimisticMutationOptions<
      void,
      PaginatedValuesModelList,
      {
        id: number;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        setIsConfirmationModalOpen(false);
        return {
          ...context,
          data: context.data.filter(
            (value) => value.id !== requestVariables?.id
          ),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:value_delete_success"), "success");
      },
      onError: () => {
        showToast(t("translation:toast:value_delete_failed"), "danger");
      },
    })
  );

  const handleDelete = (valueId?: number) => {
    if (!valueId)
      showToast(t("translation:toast:value_id_not_found"), "danger");
    setValueId(valueId);
    setIsConfirmationModalOpen(true);
  };

  /**
   *
   * Update
   *
   */

  const { mutate: updateValue } = useValuesPartialUpdate(
    optimisticMutationOptions<
      ValuesModel,
      PaginatedValuesModelList,
      {
        id: number;
        data: PatchedValuesModelRequest;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        setEditValueIds(
          editValueIds.filter((id) => id !== requestVariables?.id)
        );
        return {
          ...context,
          data: context.data.map((value) => {
            if (value.id === requestVariables?.id) {
              return { ...value, ...requestVariables?.data };
            }
            return value;
          }),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:value_add_success"), "success");
      },
      onError: () => {
        showToast(t("translation:toast:value_update_failed"), "danger");
      },
    })
  );

  // TODO: enable drag and drop
  // const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
  //   if (values) {
  //     const dragItem = values[dragIndex];
  //     if (dragItem) {
  //       setValues((prevState) => {
  //         if (prevState) {
  //           const copiedStateArray = [...prevState];
  //           // remove item by "hoverIndex" and put "dragItem" instead
  //           const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);
  //           // remove item by "dragIndex" and put "prevItem" instead
  //           copiedStateArray.splice(dragIndex, 1, prevItem[0]);
  //
  //           return copiedStateArray;
  //         }
  //       });
  //     }
  //   }
  // }, []);
  //

  /**
   *
   * handleSubmit
   *
   */
  const handleSubmit: SubmitHandler<FormData> = (value: FormData) => {
    // Values are sorted by position in the original request.
    // The next position is one after the last value in the list.
    const position = values?.length
      ? // TODO: fix ValuesModel type on the backend and update this
        (values[values.length - 1]?.position || 0) + 1
      : 0;

    if (value.id) {
      updateValue({ id: value.id, data: value });
    } else {
      createValue({
        data: {
          ...value,
          position,
          description: value.description || "",
          isPrivate: false,
        },
      });
    }
  };

  const toggleEditMode = (id?: number) => {
    if (typeof id !== "number") return;
    if (editValueIds.find((valueId) => valueId === id)) {
      setEditValueIds(editValueIds.filter((valueId) => valueId !== id));
    } else {
      setEditValueIds([...editValueIds, id]);
    }
  };

  const openPostModal = () => {
    if (searchParams.has(SearchParam.SHOW_POST_MODAL)) {
      searchParams.set(SearchParam.SHOW_POST_MODAL, "1");
    } else {
      searchParams.append(SearchParam.SHOW_POST_MODAL, "1");
    }
    if (searchParams.has(SearchParam.WITH_SHOUTOUT)) {
      searchParams.set(SearchParam.WITH_SHOUTOUT, "1");
    } else {
      searchParams.append(SearchParam.WITH_SHOUTOUT, "1");
    }
    setSearchParams(searchParams);
  };

  return {
    isOpen,
    onClose,
    isLoading,
    values,
    valueId,
    setValueId,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    destroyValue,
    handleSubmit,
    handleDelete,
    shouldScroll,
    isError,
    isAddValue,
    setIsAddValue,
    editValueIds,
    toggleEditMode,
    openPostModal,
    isCrudEnabled: isIT || isHR,
  };
};
