/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  BillingPortal,
  BillingRequestRequest,
  SubscriptionModel,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const billingMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SubscriptionModel>(
    { url: `/api/v1/billing/me/`, method: "GET", signal },
    options
  );
};

export const getBillingMeRetrieveQueryKey = () => {
  return [`/api/v1/billing/me/`] as const;
};

export const getBillingMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof billingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof billingMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBillingMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof billingMeRetrieve>>
  > = ({ signal }) => billingMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof billingMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BillingMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof billingMeRetrieve>>
>;
export type BillingMeRetrieveQueryError = ErrorType<unknown>;

export const useBillingMeRetrieve = <
  TData = Awaited<ReturnType<typeof billingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof billingMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBillingMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a portal session to manage the payment methods and view the invoice history

https://dashboard.stripe.com/test/settings/billing/portal

# Frontend to display the pricing table
dev: https://dashboard.stripe.com/test/pricing-tables/prctbl_1MZZOzLEt7U9muB6RcTbnxwL
prod: https://dashboard.stripe.com/pricing-tables/prctbl_1MZZYqLEt7U9muB6oLA72Btd

When a customer clicks on the pricing table and checks out, the customer is redirected back to the frontend
public url, which needs to determine the tenant and redirect to the tenant's billing portal. There, the customer
will see information on their new subscription. While that is happening, the payment at stripe is processed
and a webhook is sent to the backend to update the subscription status. By the time the user is redirected to
the frontend billing portal, their subscription should be active.

See https://acme.teambuilder.getintuition.dev/api/v1/billing/portal-test/ for a demo of the frontend portal.

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT", "Manager"]
```
 */
export const billingPortalCreate = (
  billingRequestRequest: BillingRequestRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BillingPortal>(
    {
      url: `/api/v1/billing/portal/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: billingRequestRequest,
    },
    options
  );
};

export const getBillingPortalCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof billingPortalCreate>>,
    TError,
    { data: BillingRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof billingPortalCreate>>,
  TError,
  { data: BillingRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof billingPortalCreate>>,
    { data: BillingRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return billingPortalCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BillingPortalCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof billingPortalCreate>>
>;
export type BillingPortalCreateMutationBody = BillingRequestRequest;
export type BillingPortalCreateMutationError = ErrorType<unknown>;

export const useBillingPortalCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof billingPortalCreate>>,
    TError,
    { data: BillingRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBillingPortalCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
