/**
 *
 *
 * useCoverImageUpload
 *
 *
 */
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Area, Point } from "react-easy-crop";
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import { usePeopleRetrieve } from "../../services/teambuilder/endpoints/people/people";
import {
  getUserguidesRetrieveQueryKey,
  useUserguidesCoverImageUploadCreate,
  useUserguidesCoverImageUploadDestroy,
  useUserguidesRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  UserGuideCoverImageUploadRequest,
  UserGuides,
} from "../../services/teambuilder/schemas";
import getCroppedImg from "../../utils/crop-image";
import { convertHeic } from "../../utils/heic-to-jpg";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  userId: number;
  className?: string;
}

export const useCoverImageUpload = ({ userId, className }: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  const { data: user, isLoading: isLoadingUser } = usePeopleRetrieve(userId);
  const queryKey = useMemo(
    () =>
      user?.userGuideId ? getUserguidesRetrieveQueryKey(user?.userGuideId) : [],
    [user]
  );

  const [previewImage, setPreviewImage] = useState<Blob>();
  const [previewImageUrl, setPreviewImageUrl] = useState<string>();

  /**
   *
   * User Guide request
   *
   */
  const { data: userGuide, isLoading: isLoadingUserGuide } =
    useUserguidesRetrieve(Number(user?.userGuideId), {
      query: {
        enabled: Boolean(user?.userGuideId),
      },
    });

  /**
   *
   * File input
   *
   */
  const [isHeicToJpg, setIsHeicToJpg] = useState(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const onClickInput = () => {
    inputFileRef.current?.click();
  };
  const onFileAdded = async (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0] as File;
    if (file.name.match(/.*\.heic$/i)) {
      setIsHeicToJpg(true);
      file = await convertHeic(URL.createObjectURL(file));
      setIsHeicToJpg(false);
    }
    setPreviewImage(file);
    setIsCropping(true);
    if (file) {
      setPreviewImageUrl(URL.createObjectURL(file));
    }
    if (inputFileRef.current) inputFileRef.current.value = "";
  };

  /**
   *
   * Crop
   *
   */
  const [isCropping, setIsCropping] = useState(false);
  const [crop, onCropChange] = useState<Point>({
    x: 0,
    y: 0,
  });
  const [zoom, onZoomChange] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropStart = () => {
    if (previewImage) {
      setIsCropping(true);
    }
  };
  const onCropCancel = () => setIsCropping(false);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCropImage = async () => {
    if (!previewImageUrl || !croppedAreaPixels || !user?.userGuideId) return;
    const croppedImage = await getCroppedImg(
      previewImageUrl,
      croppedAreaPixels
    );
    if (!croppedImage) return;
    setPreviewImage(croppedImage);
    const file = new File([croppedImage], "coverimage.png");
    uploadCoverImage({ id: user?.userGuideId, data: { file } });
  };

  /**
   *
   * Create Cover Image
   *
   */
  const { mutate: uploadCoverImage, isLoading: isLoadingCreateCoverImage } =
    useUserguidesCoverImageUploadCreate(
      optimisticMutationOptions<
        void | UserGuides,
        UserGuides,
        { id: number; data: UserGuideCoverImageUploadRequest }
      >({
        queryKey,
        optimisticUpdateFn: (context, requestVariable) => {
          if (!requestVariable?.data.file) return context;
          setIsCropping(false);
          const imageUrl = URL.createObjectURL(requestVariable?.data.file);
          return {
            ...context,
            coverImage: {
              thumbnail: imageUrl,
              medium: imageUrl,
              large: imageUrl,
            },
          };
        },
        onSuccess: () => {
          openToast({
            title: "",
            description: t("translation:toast:cover_image_save_success"),
          });
        },
        onError: (_context, _requestVariable, error) => {
          if (error?.response?.status === 413) {
            openToast({
              title: t("translation:common:error"),
              description: t(
                "translation:toast:cover_image_save_filesize_failed"
              ),
              type: "danger",
            });
          } else {
            openToast({
              title: t("translation:common:error"),
              description: t("translation:toast:cover_image_save_failed"),
              type: "danger",
            });
          }
        },
      })
    );

  /**
   *
   *
   * Delete Cover Image
   *
   */
  const { mutate: deleteCoverImage } = useUserguidesCoverImageUploadDestroy(
    optimisticMutationOptions<
      void | UserGuides,
      UserGuides,
      {
        id: number;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context) => {
        return { ...context, coverImage: null };
      },
      onSuccess: () => {
        openToast({
          title: "",
          description: t("translation:toast:cover_image_delete_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:cover_image_delete_failed"),
          type: "danger",
        });
      },
    })
  );

  const onDelete = () => {
    if (user?.userGuideId) {
      deleteCoverImage({ id: user?.userGuideId });
    }
  };

  return {
    className,
    userGuide,

    inputFileRef,
    onClickInput,
    onFileAdded,

    isLoading: isLoadingUser || isLoadingUserGuide || isLoadingCreateCoverImage,
    isHeicToJpg,
    previewImage,
    onDelete,
    isCropping,
    crop,
    onCropStart,
    onCropCancel,
    onCropComplete,
    onCropImage,
    onCropChange,
    zoom,
    onZoomChange,
    previewImageUrl,
  };
};
