/**
 *
 *
 * <CommentList />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { ErrorBoundarySuspense } from "../../components/ErrorBoundarySuspense";
import { isCommentType } from "../../utils/type-guards";
import { CommentForm } from "../CommentFormV3";
import { useCommentList } from "./useCommentList";

// Lazy load to avoid circular dependency error
const Post = React.lazy(() => import("../PostV3"));

export const CommentList = (props: ReturnType<typeof useCommentList>) => {
  return (
    <div className={clsx(props.className)}>
      {props.isCommentFormVisible && (
        <CommentForm
          post={props.post}
          queryKey={props.queryKey}
          detailQueryKey={props.detailQueryKey}
          onSubmit={props.onSubmit}
          className="mb-6"
        />
      )}

      <ErrorBoundarySuspense location="PostV3">
        {props.comments && props.comments?.length > 0 && (
          <ul className="space-y-2">
            {props.comments?.map((comment) => (
              <li key={comment.id}>
                <Post
                  post={comment}
                  detailQueryKey={props.detailQueryKey}
                  activity={
                    props.activities && isCommentType(comment)
                      ? props.activities?.find(
                          (activity) => activity.id === comment.actionId
                        )
                      : undefined
                  }
                  listQueryKey={props.queryKey}
                  showComments={true}
                />
              </li>
            ))}
          </ul>
        )}
      </ErrorBoundarySuspense>
    </div>
  );
};
