import isProduction from "./is-production";

declare global {
  interface Window {
    hj: (..._: unknown[]) => void;
  }
}

export interface SetupHotJarOptions {
  userId: number;
  fullName: string;
}

export const setupHotJar = ({ userId, fullName }: SetupHotJarOptions): void => {
  if (isProduction(true) && window && window.hj) {
    window.hj("identify", userId, {
      Name: fullName,
    });
  }
};
