/**
 *
 *
 * <UpcomingAnniversaries />
 *
 *
 */
import { ChatBubbleLeftIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/Carousel";
import { UserGuides } from "../../services/teambuilder/schemas";
import { pluralize } from "../../utils/pluralize";
import { fullName } from "../../utils/username";
import { useUpcomingAnniversaries } from "./useUpcomingAnniversaries";

export const UpcomingAnniversaries = (
  props: ReturnType<typeof useUpcomingAnniversaries>
) => {
  const { t } = useTranslation();
  if (props.members?.data?.length === 0) return null;

  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="px-12">
        <p className="mb-0.5 text-lg font-bold leading-7 text-zinc-900">
          {t("translation:people:upcoming_anniversaries")}
        </p>
        <p className="text-sm font-normal leading-5 text-zinc-500">
          {t("translation:people:celebrate_colleagues")}
        </p>
      </div>
      <Carousel className="w-full">
        <CarouselPrevious className="flex w-8 items-center justify-center bg-zinc-100" />
        <CarouselContent className="w-full items-center gap-x-6 py-4">
          {props.isLoadingMembers
            ? [...Array(20).keys()].map((i) => (
                <CarouselItem
                  key={i}
                  className="h-36 w-44 animate-pulse rounded-lg bg-zinc-200"
                />
              ))
            : props.members?.data?.map((member: UserGuides) => {
                const years =
                  new Date().getFullYear() - dayjs(member.startDate).year();
                return (
                  <CarouselItem
                    key={member.id}
                    className={clsx(
                      "w-fit overflow-hidden rounded-lg bg-white shadow-lg"
                    )}
                  >
                    <div className="flex items-center gap-x-3 px-3 py-2">
                      <img
                        src={member.user?.avatar?.thumbnail}
                        alt=""
                        className="h-12 w-12 rounded-full"
                      />
                      <div className="space-y-1.5">
                        <p
                          className="cursor-pointer whitespace-nowrap text-sm font-medium text-zinc-800 underline hover:text-zinc-900"
                          onClick={() => props.openProfileModal(member.id!)}
                        >
                          {fullName(member.user)}
                        </p>
                        <div className="flex flex-row items-center gap-x-1 text-sm text-zinc-900">
                          {years}{" "}
                          {t(
                            `translation:common:${pluralize(
                              "year",
                              "years",
                              years
                            )}`
                          )}{" "}
                          on {dayjs(member.startDate).format("MMM D")}
                        </div>
                      </div>
                    </div>
                    <button
                      className="group flex w-full justify-center border-t border-zinc-300  py-2 hover:bg-zinc-200"
                      onClick={() =>
                        member.user?.id && props.onPost(member.user?.id, years)
                      }
                    >
                      <ChatBubbleLeftIcon className="mr-1 h-4 w-4 text-zinc-400 group-hover:text-zinc-500" />
                      <span className="text-xs font-semibold text-zinc-500 group-hover:text-zinc-600">
                        {t("translation:people:send_congrat")}
                      </span>
                    </button>
                  </CarouselItem>
                );
              })}
        </CarouselContent>
        <CarouselNext className="flex w-8 items-center justify-center bg-zinc-100" />
      </Carousel>
    </div>
  );
};
