/**
 *
 *
 * <ChallengeWizardTemplates />
 *
 *
 */
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeCreateProgress } from "../../components/ChallengeCreateProgress";
import { ChallengeList } from "../../components/ChallengeListV3";
import { FilterMenu } from "./FilterMenu";
import { useChallengeWizardTemplates } from "./useChallengeWizardTemplates";

export const ChallengeWizardTemplates = (
  props: ReturnType<typeof useChallengeWizardTemplates>
) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-rows-[auto_auto_1fr_auto] overflow-hidden">
      <ChallengeCreateProgress
        title={t("translation:challenge:select_template")}
        total={5}
        current={1}
      />
      <div className="relative flex w-full flex-grow flex-row justify-between border-y border-y-zinc-300 px-4 py-4 lg:px-8">
        <div className="flex flex-grow flex-col gap-x-3 gap-y-1 sm:flex-row sm:items-center">
          <div className="relative max-w-xs flex-grow">
            <input
              type="text"
              className="w-full rounded-md border-0 text-sm ring-1 ring-inset ring-zinc-300 focus:ring-zinc-300"
              placeholder={t("translation:common:search")}
              value={props.searchTerm}
              onChange={(e) => props.setSearchTerm(e.target.value)}
            />
            <MagnifyingGlassIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-zinc-300" />
          </div>
          <span className="whitespace-nowrap text-sm text-zinc-500">
            {props.templates.length} Results
          </span>
        </div>
        <FilterMenu items={props.filterItems} filterBy={props.filterBy} />
      </div>
      {/**
       *
       * Templates grid
       *
       */}
      <div className="grid animate-crossfadein overflow-hidden bg-zinc-100 px-4 py-2 lg:px-8">
        <ChallengeList
          challenges={props.templates}
          isLoadingChallenges={props.isLoadingTemplates}
          section="templates"
          className={clsx(
            "flex w-full flex-wrap justify-center gap-y-10",
            "scrollbar-hide overflow-y-scroll"
          )}
          onTemplatePreview={props.onTemplatePreview}
          onSelect={props.onSelectTemplate}
          selectedId={props.template?.id}
          allowCarousel={false}
        />
      </div>
      <div className="flex flex-row items-center justify-end gap-x-4 bg-white px-4 py-4 lg:px-8">
        <Button
          variant="text"
          size="md"
          onClick={props.goBack}
          disabled={props.isLoadingCreateChallenge}
        >
          {t("translation:common:cancel")}
        </Button>
        <Button
          variant="challenge"
          size="md"
          onClick={props.onNext}
          disabled={!props.isNextEnabled || props.isLoadingCreateChallenge}
        >
          {t("translation:common:next")}{" "}
          <ChevronRightIcon className="h-5 text-white" />
        </Button>
      </div>
    </div>
  );
};
// Lazy load requires a default export
export default ChallengeWizardTemplates;
