/**
 *
 *
 * usePost
 *
 *
 */
import { LinkIcon, TrashIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { MenuItem } from "../../components/DropdownMenu/DropdownMenu";
import { IconThumbtack } from "../../components/IconThumbtack";
import { SearchParam } from "../../consts";
import { useDeletePost } from "../../hooks/useDeletePost";
import { queryKey as pinnedPostsQueryKey } from "../../hooks/useGetPinnedPosts";
import { useMe } from "../../hooks/useMe";
import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import {
  getSocialPostsCommentsListQueryKey,
  useSocialPostsPartialUpdate,
} from "../../services/teambuilder/endpoints/social/social";
import {
  Activity,
  PaginatedPostList,
  PatchedPostWriteRequest,
  Post,
  PostWrite,
} from "../../services/teambuilder/schemas";
import { PostType } from "../../types";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { parseRoles } from "../../utils/parse-roles";
import { getKindOfPost } from "../../utils/post-utils";
import {
  isCommentType,
  isPostType,
  isReplyType,
} from "../../utils/type-guards";

interface Props {
  className?: string;
  post: PostType;
  activity?: Activity;
  // May be list or detail query key, depending on the view.
  listQueryKey: readonly unknown[];
  detailQueryKey?: readonly unknown[];
  // Triggered in the onSettled callback after an optimistic update.
  onSettledDelete?: () => void;
  showComments?: boolean;
  // Post is rendered in PostDetail Modal
  isModal?: boolean;
}

export const usePost = (props: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();

  const { me } = useMe();
  const roles = useMemo(() => parseRoles(me?.roles || []), [me]);
  const isDetail = Boolean(props.detailQueryKey);
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(
    isPostType(props.post) && props.isModal
  );
  const hideCommentForm = () => {
    setIsCommentFormVisible(false);
  };
  const [isCommentsListVisible, setIsCommentsListVisible] = useState(
    // On mount, show comments if the post is a comment, reply or rebuttal, but not the form.
    // Only show the form on reply click though.
    Boolean(props.showComments) || (isPostType(props.post) && props.isModal)
  );
  const showComments = () => {
    setIsCommentFormVisible(true);
    setIsCommentsListVisible(true);
  };
  const commentCount: number = isPostType(props.post)
    ? props.post.commentsCount ?? 0
    : isCommentType(props.post)
      ? props.post.repliesCount ?? 0
      : isReplyType(props.post)
        ? props.post.rebuttalsCount ?? 0
        : 0;
  const [isImagePreviewVisible, setIsImagePreviewVisible] =
    React.useState<boolean>(false);
  const openImagePreview = () => {
    setIsImagePreviewVisible(true);
  };
  const closeImagePreview = () => {
    setIsImagePreviewVisible(false);
  };

  /**
   * Optimistically update `pinned` for posts in list view
   */
  const { mutate: updatePostList } = useSocialPostsPartialUpdate(
    optimisticMutationOptions<
      PostWrite,
      PaginatedPostList,
      { id: number; data: PatchedPostWriteRequest }
    >({
      queryKey: props.listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: context.data.map((post) => {
          if (!requestVariables) return post;
          if (post.id === requestVariables.id) {
            return {
              ...post,
              pinned: requestVariables.data.pinned,
            };
          } else {
            return post;
          }
        }),
      }),
      onSettled: () => {
        queryClient.invalidateQueries(pinnedPostsQueryKey);
        queryClient.invalidateQueries(props.listQueryKey);
      },
    })
  );

  /**
   * Optimistically update `pinned` for posts in detail view
   */
  const { mutate: updatePostDetail } = useSocialPostsPartialUpdate(
    optimisticMutationOptions<
      PostWrite,
      Post,
      { id: number; data: PatchedPostWriteRequest }
    >({
      queryKey: props.detailQueryKey || [],
      optimisticUpdateFn: (context, requestVariables) => ({
        ...context,
        pinned: requestVariables?.data.pinned,
      }),
      onSettled: () => {
        queryClient.invalidateQueries(pinnedPostsQueryKey);
        queryClient.invalidateQueries(props.listQueryKey);
        queryClient.invalidateQueries(props.detailQueryKey);
      },
    })
  );

  // Pinned == favorite
  const toggleFavorite = () => {
    if (isPostType(props.post)) {
      const args = {
        id: props.post.id as number,
        data: { pinned: !props.post.pinned },
      };
      if (isDetail) {
        updatePostDetail(args);
      } else {
        updatePostList(args);
      }
    } else {
      throw new Error(t("translation:post:you_can_only_pin_a_post"));
    }
  };

  const { openDeleteConfirmationModal } = useDeletePost({
    queryKey: props.listQueryKey,
    detailQueryKey: props.detailQueryKey,
    onSettled: () => {
      queryClient.invalidateQueries(pinnedPostsQueryKey);
      queryClient.invalidateQueries(props.listQueryKey);
      props.onSettledDelete?.();
    },
  });

  /**
   * Dropdown menu items
   */
  const menuItems: MenuItem[] = [
    ...(isPostType(props.post) && roles.isHR
      ? [
          {
            name: props.post.pinned
              ? t("translation:post:unpin_post")
              : t("translation:post:pin_post"),
            icon: props.post.pinned ? IconThumbtack : IconThumbtack,
            onClick: toggleFavorite,
            iconClassName: props.post.pinned ? "rotate-45" : "",
          },
        ]
      : []),
    ...(isPostType(props.post)
      ? [
          {
            name: t("translation:post:copy_post_url"),
            icon: LinkIcon,
            onClick: () => {
              navigator.clipboard
                .writeText(`${window.location.href}?post=${props.post.id}`)
                .then(() => {
                  openToast({
                    title: t("translation:post:link_copied"),
                    description: t("translation:post:paste_with_key"),
                  });
                });
            },
          },
        ]
      : []),
    ...(props.post.user?.id !== 1 &&
    (roles.isHR || props.post.user?.id === me?.id)
      ? [
          {
            name: `${t("translation:post:delete")} ${getKindOfPost(
              props.post,
              t
            )}`,
            icon: TrashIcon,
            onClick: () => openDeleteConfirmationModal(props.post),
          },
        ]
      : []),
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const onShowPostDetail = (post: Post) => {
    if (post.id) {
      searchParams.set(SearchParam.SHOW_POST_DETAIL_MODAL, post.id.toString());
      setSearchParams(searchParams);
    }
  };

  /**
   * Refetch Comment list
   */
  const [commentsQueryKey, setCommentsQueryKey] =
    useState<ReturnType<typeof getSocialPostsCommentsListQueryKey>>();
  const childrenCount = isPostType(props.post)
    ? Number(props.post.childrenCount)
    : 0;
  useEffect(() => {
    if (childrenCount > 0 && commentsQueryKey) {
      queryClient.invalidateQueries(commentsQueryKey);
    }
  }, [childrenCount]);

  return {
    className: props.className,
    post: props.post,
    isDetail,
    listQueryKey: props.listQueryKey,
    detailQueryKey: props.detailQueryKey,
    roles,
    isCommentsListVisible,
    isCommentFormVisible,
    hideCommentForm,
    showComments,
    isImagePreviewVisible,
    openImagePreview,
    closeImagePreview,
    toggleFavorite,
    menuItems,
    openDeleteConfirmationModal,
    commentCount,
    activity: props.activity,
    isModal: props.isModal,
    onShowPostDetail,
    childrenCount,
    setCommentsQueryKey,
  };
};
