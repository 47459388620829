/**
 *
 *
 * usePhoneNumberList
 *
 *
 */
import { useEffect, useState } from "react";

import { useUserguidesPhonesList } from "../../services/teambuilder/endpoints/userguides/userguides";
import { PhoneNumber } from "../../services/teambuilder/schemas";

export interface PhoneNumberWithKey extends PhoneNumber {
  key?: number;
}

interface Props {
  userId?: number;
  className?: string;
}

export const usePhoneNumberList = (props: Props) => {
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberWithKey[]>([]);

  const {
    data: phones,
    isLoading: isLoadingPhoneNumbers,
    refetch: refetchPhones,
  } = useUserguidesPhonesList(
    {
      limit: 999,
      user: props.userId,
    },
    {
      query: { enabled: Boolean(props.userId) },
    }
  );

  useEffect(() => {
    if (phones && phoneNumbers.length === 0) {
      setPhoneNumbers(
        phones.data.map((phone) => ({
          id: phone.id,
          key: phone.id,
          label: phone.label,
          phoneNumber: phone.phoneNumber,
        }))
      );
    }
  }, [phones]);

  const onAddPhoneNumber = () => {
    const phoneNumber: PhoneNumberWithKey = [...phoneNumbers].sort(
      (a, b) => b.key! - a.key!
    )[0];
    const phone: PhoneNumberWithKey = {
      key: phoneNumber && phoneNumber.key ? phoneNumber.key + 100 : 100,
      phoneNumber: "",
    };
    setPhoneNumbers([...phoneNumbers, phone]);
  };

  const onSaveSuccess = (key: number, data: PhoneNumberWithKey) => {
    setPhoneNumbers(
      phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.key === key) {
          return { ...data, key };
        }
        return phoneNumber;
      })
    );
    refetchPhones();
  };

  const onDeleteSuccess = (key: number) => {
    setPhoneNumbers(
      phoneNumbers.filter((phoneNumber) => phoneNumber.key !== key)
    );
    refetchPhones();
  };

  return {
    phoneNumbers,
    isLoading: !props.userId || isLoadingPhoneNumbers,
    className: props.className,
    onAddPhoneNumber,
    onSaveSuccess,
    onDeleteSuccess,
  };
};
