/**
 *
 *
 * <CountBadge />
 *
 *
 */
import clsx from "clsx";
import React from "react";

interface Props {
  count: number;
  className?: string;
  isCollapsed?: boolean;
}

export const CountBadge = (props: Props) => {
  return (
    <div
      className={clsx(
        "h-6 w-6 grow-0 rounded-full bg-red-700 p-1 text-xs font-bold leading-none text-white",
        "flex items-center justify-center",
        props.isCollapsed ? "absolute -bottom-2 -right-2" : "relative",
        props.className
      )}
    >
      {props.count}
    </div>
  );
};
