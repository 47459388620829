/**
 *
 *
 * stripUtmParams
 *
 * UTM params should not be forwarded once they have been tracked.
 * Returns a new URL. Does not mutate the original url.
 */
export const stripUtmParams = (_url: URL): URL => {
  const url = new URL(_url.toString());
  const keys = [...url.searchParams.keys()];
  keys.forEach((key) => {
    if (/utm/.test(key) || /ref/.test(key)) url.searchParams.delete(key);
  });
  return url;
};
