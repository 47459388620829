/**
 *
 *
 * <Integration />
 *
 *
 */
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { classNames } from "../../utils";
import { IntegrationKey } from "./types";
import { useIntegration } from "./useIntegration";

export const Integration = (props: ReturnType<typeof useIntegration>) => {
  const { t } = useTranslation();
  const connectionUrl = (key: IntegrationKey) =>
    `/api/v1/integrations/authorize/?provider=${key}`;
  return (
    <div className="min-w-52 max-w-96 flex-grow">
      <div
        className={classNames(
          "w-full border border-zinc-300 bg-zinc-100 p-4",
          props.integration.connected ? "rounded-t-md" : "rounded-md"
        )}
      >
        <div className="flex flex-row gap-x-3">
          <img
            src={props.integration.logo}
            className="h-12 w-12 rounded-full"
            alt="logo"
          />

          <div className="flex w-full flex-col gap-y-1">
            <div className="text-sm font-medium capitalize text-zinc-900">
              {props.integration.name}
            </div>

            {props.integration.connected ? (
              <button
                className={clsx("text-left text-zinc-500 underline")}
                aria-disabled={props.isLoading}
                disabled={props.isLoading}
                onClick={() =>
                  props.onButtonClick &&
                  props.integration?.apiID &&
                  props.onButtonClick(props.index, props.integration?.id)
                }
              >
                <>{t("translation:common:disconnect")}</>
              </button>
            ) : (
              <a
                className="text-left text-zinc-500 underline"
                href={connectionUrl(props.integration.key)}
              >
                {t("translation:settings:integrations:connect")}
              </a>
            )}
          </div>
        </div>

        {props.integration.connected && (
          <div className="relative mt-4 w-full">
            <input
              type="text"
              className="w-full rounded-md border-0 text-sm ring-1 ring-inset ring-zinc-300 focus:ring-zinc-400"
              placeholder={t("translation:common:search")}
              onChange={(e) => props.setSearchTerm(e.target.value)}
              defaultValue={props.searchTerm}
            />
            <MagnifyingGlassIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-zinc-400" />
          </div>
        )}
      </div>

      {props.integration.connected && (
        <div className="max-h-80 w-full divide-y divide-zinc-300 overflow-hidden rounded-b-md border-x border-b border-zinc-300 hover:overflow-y-auto">
          {props.isLoading ? null : props.filteredChannels.length > 0 ? (
            props.filteredChannels.map((channel) => (
              <div key={channel.channelId} className="px-4 py-2">
                <input
                  id={channel.channelId}
                  type="radio"
                  className="mr-2 h-4 w-4 rounded-full border-zinc-300 text-zinc-500 focus:ring-zinc-300"
                  checked={props.channelId === channel.channelId}
                  name="channel"
                  onChange={() => props.updateChannel(channel.channelId)}
                />
                <label htmlFor={channel.channelId}>{channel.channelName}</label>
              </div>
            ))
          ) : (
            <div className="px-4 py-2">
              {t("translation:settings:banter:no_channel")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
