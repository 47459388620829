/**
 *
 *
 * useChallenges
 *
 *
 */
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { useCreateChallenge } from "../../hooks/useCreateChallenge";
import {
  useChallengesTemplatesActivitiesList,
  useChallengesTemplatesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import { ChallengesActivitiesListParams } from "../../services/teambuilder/schemas";

export const useChallengeTemplateModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isTemplatePreview = searchParams.has(SearchParam.TEMPLATE_PREVIEW);
  const templateId = Number(searchParams.get(SearchParam.TEMPLATE_PREVIEW));
  const { onCreateDraftChallenge, isCreatingChallenge } = useCreateChallenge();
  /**
   * Template requests
   */
  const activitiesParams: ChallengesActivitiesListParams = {
    limit: 999,
    ordering: "position",
  };

  const { data: template, isInitialLoading: isLoadingTemplate } =
    useChallengesTemplatesRetrieve(templateId || 0, {
      query: {
        enabled: Boolean(templateId) && isTemplatePreview,
        select: (data) => data,
      },
    });

  const {
    data: templateActivities,
    isInitialLoading: isLoadingTemplateActivities,
  } = useChallengesTemplatesActivitiesList(templateId, activitiesParams, {
    query: {
      enabled: Boolean(templateId) && isTemplatePreview,
      select: ({ data }) => data,
    },
  });

  const onCloseTemplatePreview = () => {
    searchParams.delete(SearchParam.TEMPLATE_PREVIEW);
    setSearchParams(searchParams);
  };

  return {
    isLoadingTemplate,
    isLoadingTemplateActivities,
    isTemplatePreview,
    template,
    templateActivities,
    onCloseTemplatePreview,
    onSelectTemplate: () => onCreateDraftChallenge(template),
    isLoadingCreateChallenge: isCreatingChallenge,
  };
};
