/**
 *
 *
 * <PostList />
 *
 *
 */
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { useLayoutContext } from "../../components/LayoutProvider";
import { SkeletonPostListItem } from "../../components/SkeletonPostListItemV3";
import { CoffeeChatModal } from "../CoffeeChatModal";
import { CoffeeChat } from "../CoffeeChatV3";
import { PostDetail } from "../PostDetailV3";
import { PostModal } from "../PostModal";
import { Post } from "../PostV3";
import { Shoutout } from "../Shoutout";
import { PinnedPostsMenu } from "./PinnedPostsMenu";
import { PostModalLauncher } from "./PostModalLauncher";
import { usePostList } from "./usePostList";

export const PostList = (props: ReturnType<typeof usePostList>) => {
  const { t } = useTranslation();
  const { width } = useLayoutContext();

  const Skeletons = () => (
    <ul role="list" className="flex w-full flex-col gap-4">
      {[...Array(3).keys()].map((i) => (
        <SkeletonPostListItem
          key={i}
          className="w-full rounded-xl bg-white px-4 pt-4 shadow"
        />
      ))}
    </ul>
  );

  return (
    <>
      <div
        className={clsx(
          "group/post-list w-full",
          "grid grid-rows-[auto_1fr] overflow-hidden",
          props.className
        )}
        style={{ height: width < 768 ? "calc(100vh - 66px)" : "100vh" }}
      >
        <div className="relative flex w-full justify-center px-6 pb-2 pt-4">
          <div className="w-full max-w-xl space-y-2">
            <PostModalLauncher openPostModal={props.openPostModal} />

            {!props.isLoadingPinnedPosts && (
              <div
                className={clsx("flex flex-row items-center justify-between")}
              >
                {props.posts && (
                  <PinnedPostsMenu
                    items={props.pinnedPosts || []}
                    onClick={props.onClickPinnedPost}
                  />
                )}
              </div>
            )}
          </div>

          <button
            className={clsx(
              "hidden bg-zinc-100 p-0.5 group-hover/post-list:flex",
              "absolute left-0 top-9 cursor-pointer rounded-r-md shadow",
              "bg-zinc-50 text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500"
            )}
            onClick={props.toggleFocusMode}
          >
            <span className="sr-only">
              {props.isFocusMode ? "collapse posts" : "expand posts"}
            </span>
            {props.isFocusMode ? (
              <ChevronDoubleRightIcon className="h-5 w-5" />
            ) : (
              <ChevronDoubleLeftIcon className="h-5 w-5" />
            )}
          </button>
        </div>
        <div id="scrollableDiv" className={clsx("overflow-y-auto")}>
          <div className="bg-zinc-100 p-6">
            {(props.isLoadingPosts || props.isLoadingCoffeeChat) &&
              !props.posts?.length && <Skeletons />}
            {!props.isLoadingPosts && !props.posts?.length && (
              <p className="p-2 text-center text-sm">
                {t("translation:post:no_posts")}
              </p>
            )}

            <InfiniteScroll
              dataLength={props.posts?.length || 0}
              next={props.loadMore}
              hasMore={!!props.hasMore}
              loader={
                <div className="mx-auto mb-4 h-full w-full max-w-xl bg-zinc-100">
                  <Skeletons />
                </div>
              }
              endMessage={<></>}
              style={{ overflow: "visible" }}
              // Add space between posts and loader
              className="space-y-6"
              scrollableTarget="scrollableDiv"
            >
              <ul role="list" className="mx-auto flex max-w-xl flex-col gap-6">
                {props.posts?.map((post, index) => {
                  // CoffeeChat Ads places following at 2, 4, 6, 8
                  const remain = index % 10;
                  let isCoffeeChatVisible = false;
                  let ccIndex = -1;
                  if (remain > 0 && remain % 2 === 0 && remain / 2 < 5) {
                    ccIndex = Math.floor(index / 10) * 4 + remain / 2 - 1;
                    isCoffeeChatVisible =
                      (props.coffeeChats || []).length > ccIndex;
                  }
                  return (
                    <Fragment key={post.id}>
                      <li
                        className={clsx(
                          "rounded-xl bg-white",
                          "border border-zinc-300 pt-4"
                        )}
                      >
                        <Post
                          post={post}
                          listQueryKey={props.postListQueryKey}
                        />
                      </li>
                      {isCoffeeChatVisible &&
                        props.coffeeChats?.[ccIndex].objectId !== -1 && (
                          <>
                            <CoffeeChat
                              listQueryKey={props.adsQueryKey}
                              className={clsx(
                                "rounded-xl bg-white",
                                "border border-zinc-300"
                              )}
                              id={props.coffeeChats?.[ccIndex].objectId}
                            />
                          </>
                        )}
                    </Fragment>
                  );
                })}
              </ul>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Shoutout queryKey={props.postListQueryKey} />

      <PostDetail listQueryKey={props.postListQueryKey} />

      <PostModal listQueryKey={props.postListQueryKey} />

      <CoffeeChatModal listQueryKey={props.adsQueryKey} />
    </>
  );
};
