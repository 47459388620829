/**
 *
 *
 * <ReorderItem />
 *
 *
 */
import { Reorder, useDragControls } from "framer-motion";
import React from "react";

import { ChallengeActivityListItem } from "../../components/ChallengeActivityListItemV3/ChallengeActivityListItem";
import {
  Activity,
  TemplateActivities,
} from "../../services/teambuilder/schemas";

type Props = {
  activity: TemplateActivities | Activity;
  index: number;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
};

export const ReorderItem = (props: Props) => {
  const controls = useDragControls();
  return (
    <Reorder.Item
      key={props.activity.id}
      value={props.activity}
      dragListener={false}
      dragControls={controls}
    >
      <ChallengeActivityListItem
        {...props}
        index={props.index}
        activity={props.activity}
        isTemplate
        isComment
        dragControls={controls}
        isDraft
      />
    </Reorder.Item>
  );
};
// Lazy load requires a default export
export default ReorderItem;
