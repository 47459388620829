/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `not required` - Not Required
 * `text` - Text
 * `shoutout` - ShoutOut
 * `image` - Image
 * `link` - Link
 */
export type ActivityProofType =
  (typeof ActivityProofType)[keyof typeof ActivityProofType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityProofType = {
  not_required: "not required",
  text: "text",
  shoutout: "shoutout",
  image: "image",
  link: "link",
} as const;
