/**
 *
 *
 * MenuButtonContent
 *
 *
 */
import { ChevronDownIcon, GlobeAltIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "../../components/AvatarV3";
import { PeopleMe } from "../../services/teambuilder/schemas";

interface Props {
  open?: boolean;
  isCollapsed?: boolean;
  currentLanguage?: string;
}

export const MenuButtonContent = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <GlobeAltIcon
        className={clsx(
          props.isCollapsed
            ? "h-8 w-8 group-hover/nav-item:text-zinc-500"
            : "h-5 w-5 group-hover/nav-item:text-zinc-500",
          props.isCollapsed
            ? props.open
              ? "text-zinc-500"
              : "text-zinc-500"
            : props.open
              ? "text-zinc-500"
              : "text-zinc-300"
        )}
      />

      {!props.isCollapsed && (
        <span
          className={clsx(
            "text-primary-4 flex-auto text-left text-sm font-medium"
          )}
        >
          {props.currentLanguage || t("translation:common:language")}
        </span>
      )}

      <ChevronDownIcon
        className={clsx(
          "text-zinc-300 group-hover/nav-item:text-zinc-500",
          props.isCollapsed ? "h-8 w-8" : "h-5 w-5"
        )}
      />
    </>
  );
};
