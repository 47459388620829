/**
 *
 *
 * <ButtonAnchor />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { Size, Variant, buttonStyles } from "../../utils/button-styles-v3";

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  variant?: Variant;
  size?: Size;
}

/**
 *
 * <AButton />
 *
 * @param variant
 *  "contained" - Solid blue background, white text
 *  "outlined" - White background, gray border
 *  "inline" - No padding, no background, no border. Displays inline.
 *  "text" - Transparent background, blue text, no border
 *  "danger" - Red background, white text
 *  "text-danger" - Red text, transparent background, no border.
 *
 * @param size Change font size. Corresponds to tailwind text sizes "text-[size]".
 *  "xl" - 20px, for Edit buttons in tables
 *  "lg" - 18px, for primary buttons like Give ShoutOut
 *  "sm" - 14px, for modal form buttons
 */
const ButtonAnchor = React.forwardRef<HTMLAnchorElement, Props>(
  (
    { variant = "contained", size = "lg", className = "", children, ...props },
    ref
  ) => {
    return (
      <a
        {...props}
        ref={ref}
        className={clsx(buttonStyles({ variant, size }), className)}
      >
        {children && <>{children}</>}
      </a>
    );
  }
);

ButtonAnchor.displayName = "ButtonAnchor";

export { ButtonAnchor };
