/**
 *
 *
 * <NotificationsNavItem />
 *
 * Open notifications modal. See LayoutV3.
 */
import { BellIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { useLayoutContext } from "../LayoutProvider";
import { NavItem } from "../NavItem";

interface Props {
  collapsed?: boolean;
  onClick?: () => void;
}

export const NotificationsNavItem = (props: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const openNotificationsModal = () => {
    searchParams.append(SearchParam.NOTIFICATIONS, "1");
    setSearchParams(searchParams);
    props.onClick?.();
  };

  const layoutContext = useLayoutContext();

  return (
    <NavItem
      item={{
        name: t("translation:header:notification:notifications"),
        onClick: openNotificationsModal,
        icon: BellIcon,
        alertCount: layoutContext.unreadNotificationsCount,
      }}
      collapsed={props.collapsed}
      rounded
    />
  );
};
