/**
 *
 *
 * <ChallengeWizardInvitePeoples />
 *
 *
 */
import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeCreateProgress } from "../../components/ChallengeCreateProgress";
import { useLayoutContext } from "../../components/LayoutProvider";
import { fullName } from "../../utils/username";
import { PeoplesList } from "./PeoplesList";
import { useChallengeWizardInvitePeoples } from "./useChallengeWizardInvitePeoples";

export const ChallengeWizardInvitePeoples = (
  props: ReturnType<typeof useChallengeWizardInvitePeoples>
) => {
  const { t } = useTranslation();
  const { width, isNavCollapsed } = useLayoutContext();
  const isMobileMode = useMemo(() => {
    return (
      (width >= 1024 && width < 1350) ||
      (!isNavCollapsed && width >= 1350 && width < 1525) ||
      width < 875
    );
  }, [width, isNavCollapsed]);
  return (
    <div className="grid grid-rows-[auto_1fr_auto] overflow-hidden">
      <ChallengeCreateProgress
        title={t("translation:challenge:invite_people")}
        description={t("translation:challenge:invite_people_description")}
        total={5}
        current={3}
      />
      <div
        className={clsx(
          "h-full overflow-y-auto px-4 py-4 sm:py-0 lg:px-8",
          "flex flex-col items-center justify-center gap-x-8 gap-y-6",
          !isMobileMode && "sm:flex-row"
        )}
      >
        <PeoplesList
          isAdd
          isLoading={props.isLoadingMembers}
          disableActions={props.isLoadingMembers}
          peoples={props.peoples}
          allPeoples={props.allPeoples - props.members.length}
          onClickAll={props.onAddAll}
          onClickPeople={props.onAddMemeber}
          search={props.searchPeople}
          setSearch={(search: string) => props.setSearchPeople(search)}
          loadMore={props.loadMorePeoples}
          hasMore={props.morePeoples}
        />
        <ArrowRightIcon
          className={clsx(
            "h-6 w-6 flex-shrink-0 rotate-90 text-zinc-500",
            !isMobileMode && "sm:rotate-0"
          )}
        />
        <PeoplesList
          peoples={props.members.sort((a, b) =>
            fullName(a).localeCompare(fullName(b))
          )}
          onClickAll={props.onRemoveAll}
          onClickPeople={props.onRemoveMember}
          search={props.searchMember}
          setSearch={(search: string) => props.setSearchMember(search)}
          isFilterBySearch
          isLoading={props.isLoadingMembers && props.addAll}
          disableActions={props.isLoadingMembers}
        />
      </div>
      <div className="flex flex-row items-center justify-end gap-x-4 bg-white px-4 py-4 lg:px-8">
        <Button variant="text" size="md" onClick={props.onCancel}>
          {t("translation:common:cancel")}
        </Button>
        <Button
          variant="outlined"
          size="md"
          onClick={props.onBack}
          disabled={
            props.isCreating || props.isUpdating || props.isLoadingMembers
          }
        >
          <ChevronLeftIcon className="h-5 text-zinc-900" />{" "}
          {t("translation:common:back")}
        </Button>
        <Button
          variant="challenge"
          size="md"
          onClick={props.onNext}
          disabled={
            props.isCreating ||
            props.isUpdating ||
            props.members.length === 0 ||
            props.isLoadingMembers
          }
        >
          {t("translation:challenge:save_continue")}{" "}
          <ChevronRightIcon className="h-5 text-white" />
        </Button>
      </div>
    </div>
  );
};
// Lazy load requires a default export
export default ChallengeWizardInvitePeoples;
