/**
 *
 *
 * <SkeletonDomain />
 *
 *
 */
import React from "react";

export const SkeletonDomain = () => {
  return (
    <div className="animate-pulse">
      <div className="flex flex-row items-start gap-1 sm:flex-row  sm:gap-3">
        <div className="item-start flex flex-1 flex-row gap-2">
          <div className="h-10 w-full rounded-lg bg-zinc-300" />
        </div>
        <div className="flex flex-row gap-2 sm:w-auto">
          <div className="h-10 w-20 rounded-lg bg-zinc-300  py-1 sm:py-3" />
          <div className="h-10 w-20 rounded-lg bg-zinc-300  py-1 sm:py-3" />
        </div>
      </div>
    </div>
  );
};
