/**
 *
 *
 * Comment
 *
 * Layout for comments, replies and rebuttals
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar } from "../../components/AvatarV3";
import { SearchParam } from "../../consts";
import {
  Comment as CommentType,
  Rebuttal,
  Reply,
} from "../../services/teambuilder/schemas";
import { isCommentType, isRebuttalType } from "../../utils/type-guards";
import { Reactions } from "../ReactionsV3";
import { ActivityCompleted } from "./ActivityCompleted";
import { CommentHeader } from "./CommentHeader";
import { PostContent } from "./PostContent";
import { usePost } from "./usePost";

interface Props extends Omit<ReturnType<typeof usePost>, "post" | "className"> {
  post: CommentType | Reply | Rebuttal;
}

export const Comment = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-row gap-x-2">
      <Link
        to={`?${SearchParam.PROFILE}=${props.post.user?.id}`}
        className="block flex-shrink-0"
      >
        <Avatar size="post" user={props.post.user} />
      </Link>
      <div className="flex-grow">
        <div className="rounded-md bg-zinc-100 p-2">
          <CommentHeader post={props.post} menuItems={props.menuItems} />

          {isCommentType(props.post) &&
          props.post.action === "completed a challenge activity" &&
          props.activity ? (
            <ActivityCompleted
              post={props.post}
              activity={props.activity}
              className="mt-2 rounded-md bg-zinc-100 text-sm"
            />
          ) : (
            <PostContent
              post={props.post}
              className="mt-2 rounded-md bg-zinc-100 text-sm"
            />
          )}
        </div>

        <div className="flex justify-end gap-1.5 py-2 pl-14">
          <Reactions post={props.post} listQueryKey={props.listQueryKey} />

          {!isRebuttalType(props.post) && (
            <button
              onClick={props.showComments}
              className={clsx(
                "flex items-center gap-1 rounded-md px-1 text-xs font-medium",
                "text-zinc-500 underline hover:bg-zinc-100 hover:text-zinc-600"
              )}
            >
              {t("translation:comment:reply")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
