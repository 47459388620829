/**
 *
 *
 * usePostDetail
 *
 *
 */
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import {
  getSocialPostsRetrieveQueryKey,
  useSocialPostsRetrieve,
} from "../../services/teambuilder/endpoints/social/social";

interface Props {
  // Use listQueryKey in optimistic updates to the post detail object.
  listQueryKey: readonly unknown[];
}

export const usePostDetail = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const postsPk = Number(searchParams.get(SearchParam.SHOW_POST_DETAIL_MODAL));
  const isOpen = searchParams.has(SearchParam.SHOW_POST_DETAIL_MODAL);
  const onClose = () => {
    searchParams.delete(SearchParam.SHOW_POST_DETAIL_MODAL);
    setSearchParams(searchParams);
  };

  /**
   * Retrieve post if not provided in props (i.e. detail page).
   */
  const detailQueryKey = getSocialPostsRetrieveQueryKey(postsPk);
  const {
    data: post,
    isInitialLoading: isLoadingPost,
    isError: isPostError,
  } = useSocialPostsRetrieve(postsPk, {
    query: {
      enabled: isOpen,
    },
  });

  return {
    isOpen,
    onClose,
    post,
    isLoadingPost,
    isPostError,
    listQueryKey: props.listQueryKey,
    detailQueryKey,
  };
};
