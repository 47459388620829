/**
 *
 *
 * <ProfileForm />
 *
 *
 */
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { FormHeader } from "../../components/FormHeader";
import { AvatarUpload } from "../AvatarUploadV3";
import { BioForm } from "../BioFormV3";
import { BirthdayForm } from "../BirthdayFormV3";
import { CoverImageUpload } from "../CoverImageUploadV3";
import { LanguageList } from "../LanguageListV3";
import { PersonalInfoForm } from "../PersonalInfoFormV3";
import { StartDateForm } from "../StartDateFormV3";
import { TimezoneForm } from "../TimezoneFormV3";
import { useProfileForm } from "./useProfileForm";

export const ProfileForm = (props: ReturnType<typeof useProfileForm>) => {
  const { t } = useTranslation();
  return (
    <div className={clsx("mt-2 space-y-6 rounded-md bg-zinc-100 p-4")}>
      <div className="flex">
        <h2 className="grow text-2xl font-light tracking-tight text-zinc-900">
          {t("translation:user_guide:edit_personal_info")}
        </h2>

        <Button variant="outlined" onClick={props.onCancel} size="md">
          <div className="hidden sm:block">{t("translation:common:close")}</div>
          <XMarkIcon
            className={clsx(
              "h-5 w-5 text-zinc-500 group-hover/cancel-button:text-zinc-600"
            )}
          />
        </Button>
      </div>

      <div className="space-y-12 divide-y divide-zinc-300">
        <section className="space-y-6">
          <FormHeader
            title={t("translation:user_guide:background_image")}
            description={t("translation:user_guide:show_off_background_image")}
          />
          <CoverImageUpload userId={props.userId} />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:user_guide:profile_photo")}
            description={t("translation:user_guide:upload_photo")}
          />
          <AvatarUpload userId={props.userId} />
        </section>

        <section className="space-y-6 pt-12">
          <PersonalInfoForm userId={props.userId} />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:user_guide:bio")}
            description={t("translation:user_guide:bio_form_description")}
          />
          <BioForm userGuideId={props.user?.userGuideId} className="w-full" />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:user_guide:birthday")}
            description={t("translation:user_guide:birthday_description", {
              name: props.company?.name,
            })}
          />
          <BirthdayForm
            userGuideId={props.user?.userGuideId}
            className="w-full"
          />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:user_guide:start_date")}
            description={t("translation:user_guide:start_date_detail", {
              name: props.company?.name,
            })}
          />
          <StartDateForm
            userGuideId={props.user?.userGuideId}
            className="w-full"
          />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:user_guide:languages")}
            description={t("translation:user_guide:languages_detail")}
          />
          <LanguageList
            userGuideId={props.user?.userGuideId}
            className="w-full"
          />
        </section>

        <section className="space-y-6 pt-12">
          <FormHeader
            title={t("translation:common:timezone")}
            description={t("translation:common:type_timezone")}
          />
          <TimezoneForm
            userGuideId={props.user?.userGuideId}
            className="w-full"
          />
        </section>

        <div className="flex border-t border-zinc-300 pb-2 pt-6 sm:justify-end">
          <Button
            variant="outlined"
            onClick={props.onCancel}
            size="md"
            className="w-full sm:w-auto"
          >
            {t("translation:common:close")}
            <XMarkIcon
              className={clsx(
                "h-5 w-5 text-slate-500 group-hover/cancel-button:text-slate-600"
              )}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
