/**
 *
 *
 * <IconBlankCanvas />
 *
 *
 */
import React from "react";

interface Props {
  className?: string;
}

export const IconBlankCanvas = ({ className = "" }: Props) => {
  return (
    <svg
      width="275"
      height="205"
      viewBox="0 0 275 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M274.99 180.063H0V180.201H274.99V180.063Z" fill="#EBEBEB" />
      <path
        d="M47.0669 185.299H28.8516V185.437H47.0669V185.299Z"
        fill="#EBEBEB"
      />
      <path
        d="M117.514 183.809H94.123V183.946H117.514V183.809Z"
        fill="#EBEBEB"
      />
      <path
        d="M61.9604 187.229H51.4062V187.367H61.9604V187.229Z"
        fill="#EBEBEB"
      />
      <path
        d="M247.436 189.484H239.06V189.622H247.436V189.484Z"
        fill="#EBEBEB"
      />
      <path
        d="M234.16 189.484H215.301V189.622H234.16V189.484Z"
        fill="#EBEBEB"
      />
      <path d="M182.5 185.525H169.394V185.662H182.5V185.525Z" fill="#EBEBEB" />
      <path
        d="M130.345 155.534H24.1495C23.3176 155.533 22.5202 155.201 21.9325 154.612C21.3448 154.024 21.0146 153.226 21.0146 152.394V3.11288C21.0219 2.28573 21.3552 1.49483 21.9422 0.911986C22.5291 0.32914 23.3223 0.00141696 24.1495 0H130.345C131.178 0 131.977 0.330861 132.566 0.919797C133.155 1.50873 133.485 2.3075 133.485 3.14038V152.394C133.485 153.227 133.155 154.025 132.566 154.614C131.977 155.203 131.178 155.534 130.345 155.534ZM24.1495 0.109996C23.3541 0.111453 22.5917 0.42847 22.0297 0.991462C21.4678 1.55445 21.1521 2.31742 21.1521 3.11288V152.394C21.1521 153.189 21.4678 153.952 22.0297 154.515C22.5917 155.078 23.3541 155.395 24.1495 155.397H130.345C131.141 155.395 131.904 155.078 132.467 154.515C133.03 153.953 133.346 153.19 133.348 152.394V3.11288C133.346 2.31691 133.03 1.55396 132.467 0.991127C131.904 0.428291 131.141 0.11145 130.345 0.109996H24.1495Z"
        fill="#EBEBEB"
      />
      <path
        d="M249.311 155.534H143.11C142.278 155.533 141.48 155.201 140.891 154.613C140.303 154.024 139.971 153.226 139.97 152.394V3.11288C139.978 2.28523 140.313 1.49434 140.901 0.911642C141.489 0.328947 142.282 0.00140151 143.11 0H249.311C250.137 0.00286236 250.929 0.331225 251.515 0.913916C252.101 1.49661 252.433 2.28668 252.44 3.11288V152.394C252.44 153.225 252.111 154.022 251.525 154.61C250.938 155.199 250.142 155.531 249.311 155.534ZM143.11 0.109996C142.314 0.11145 141.551 0.428291 140.988 0.991127C140.426 1.55396 140.109 2.31691 140.107 3.11288V152.394C140.109 153.19 140.426 153.953 140.988 154.515C141.551 155.078 142.314 155.395 143.11 155.397H249.311C250.107 155.395 250.87 155.078 251.433 154.515C251.996 153.953 252.313 153.19 252.314 152.394V3.11288C252.313 2.31691 251.996 1.55396 251.433 0.991127C250.87 0.428291 250.107 0.11145 249.311 0.109996H143.11Z"
        fill="#EBEBEB"
      />
      <path
        d="M29.1382 120.902H26.6963L27.1583 180.063H31.2061L29.1382 120.902Z"
        fill="#E0E0E0"
      />
      <path
        d="M28.1094 119.851H70.3808L75.0831 180.063H32.8117L28.1094 119.851Z"
        fill="#F5F5F5"
      />
      <path
        d="M28.1094 119.851H26.4814L31.1838 180.063H32.8117L28.1094 119.851Z"
        fill="#EBEBEB"
      />
      <path
        d="M95.416 61.5977H178.578L187.835 180.063H104.672L95.416 61.5977Z"
        fill="#F5F5F5"
      />
      <path
        d="M147.675 105.492C150.381 110.177 155.644 112.746 160.781 114.396C163.19 115.177 166.374 115.578 167.776 113.466C168.876 111.816 168.172 109.572 168.667 107.653C169.536 104.287 173.557 103.022 176.95 102.263C178.553 101.906 180.124 101.419 181.647 100.806L179.491 73.1968C179.062 73.7823 178.742 74.4398 178.545 75.1382C177.995 76.8761 178.952 78.8341 178.331 80.539C177.781 82.1064 175.999 82.9644 174.332 83.0689C172.666 83.1734 171.032 82.6839 169.382 82.3649C162.854 81.078 155.6 82.6014 150.821 87.2212C146.041 91.8411 144.353 99.7333 147.675 105.492Z"
        fill="#F5F5F5"
      />
      <path
        d="M95.416 61.5977L100.096 121.815C101.12 122.974 102.207 124.075 103.352 125.115C106.361 127.865 109.798 130.268 113.692 131.511C115.01 131.931 116.372 132.203 117.751 132.319C115.226 135.773 114.561 140.795 117.146 144.177C118.543 146.003 120.649 147.125 122.453 148.577C124.257 150.029 125.885 152.075 125.643 154.363C125.472 156.013 124.345 157.421 123.206 158.647C118.807 163.404 113.384 167.447 110.414 173.221C109.523 174.948 108.863 176.912 109.253 178.809C109.342 179.244 109.482 179.667 109.671 180.069H149.594C151.403 175.496 151.537 170.431 149.974 165.769C147.318 158.07 140.448 152.223 132.732 149.556C136.032 146.492 140.547 145.156 144.909 143.99C149.27 142.824 153.796 141.746 157.41 139.04C163.404 134.563 164.867 122.018 154.599 122.475C151.618 122.607 148.984 124.328 145.86 124.405C142.736 124.482 139.673 124.405 136.631 124.603C137.9 123.201 138.977 121.637 139.832 119.95C141.213 117.201 142.071 114.099 141.444 111.107C139.838 103.44 129.966 100.613 126.187 93.7494C121.623 85.4613 127.485 74.8852 124.906 65.7775C124.481 64.2867 123.816 62.875 122.937 61.5977H95.416Z"
        fill="#F5F5F5"
      />
      <path
        d="M95.416 61.5976L100.096 121.815C101.12 122.974 102.207 124.075 103.352 125.115C106.361 127.865 109.798 130.268 113.692 131.511C115.01 131.931 116.372 132.203 117.751 132.319C124.691 132.941 131.874 129.85 136.582 124.587C137.851 123.185 138.927 121.62 139.783 119.934C141.163 117.184 142.021 114.082 141.394 111.09C139.788 103.423 129.916 100.597 126.138 93.7328C121.573 85.4447 127.436 74.8686 124.856 65.7609C124.431 64.2701 123.766 62.8584 122.887 61.5811L95.416 61.5976Z"
        fill="#E6E6E6"
      />
      <path
        d="M109.275 178.804C109.364 179.239 109.504 179.662 109.693 180.063H149.594C151.403 175.491 151.537 170.426 149.974 165.764C147.317 158.064 140.448 152.218 132.732 149.55C136.032 146.487 140.547 145.151 144.908 143.985C149.27 142.819 153.796 141.741 157.409 139.035C163.404 134.558 164.867 122.013 154.599 122.469C151.618 122.601 148.984 124.323 145.86 124.4C142.736 124.477 139.673 124.4 136.631 124.598C134.507 124.698 132.395 124.978 130.317 125.434C125.868 126.429 121.358 128.184 118.372 131.61C118.174 131.841 117.981 132.083 117.822 132.331C115.297 135.784 114.632 140.806 117.217 144.188C118.614 146.014 120.72 147.136 122.524 148.588C124.328 150.04 125.956 152.086 125.714 154.374C125.544 156.024 124.416 157.432 123.278 158.658C118.878 163.415 113.455 167.458 110.485 173.233C109.545 174.943 108.896 176.906 109.275 178.804Z"
        fill="white"
      />
      <path
        d="M147.675 105.492C150.381 110.177 155.644 112.746 160.781 114.396C163.19 115.177 166.374 115.578 167.776 113.466C168.876 111.816 168.172 109.572 168.667 107.653C169.536 104.287 173.557 103.022 176.95 102.263C178.553 101.906 180.124 101.419 181.647 100.806L179.491 73.1968C179.062 73.7823 178.742 74.4398 178.545 75.1382C177.995 76.8761 178.952 78.8341 178.331 80.539C177.781 82.1064 175.999 82.9644 174.332 83.0689C172.666 83.1734 171.032 82.6839 169.382 82.3649C162.854 81.078 155.6 82.6014 150.821 87.2212C146.041 91.8411 144.353 99.7333 147.675 105.492Z"
        fill="#F0F0F0"
      />
      <path
        d="M94.5244 63.616L92.4785 63.1541L95.4154 180.063H102.818L94.5244 63.616Z"
        fill="#E0E0E0"
      />
      <path
        d="M95.4163 61.5977H92.21L101.466 180.063H104.672L95.4163 61.5977Z"
        fill="#EBEBEB"
      />
      <path
        d="M173.733 89.7072H237.162L244.224 180.063H180.795L173.733 89.7072Z"
        fill="#F5F5F5"
      />
      <path
        d="M173.733 89.7072H171.285L175.333 180.063H179.381L173.733 89.7072Z"
        fill="#E0E0E0"
      />
      <path
        d="M173.733 89.7072H171.285L178.347 180.063H180.794L173.733 89.7072Z"
        fill="#EBEBEB"
      />
      <path
        d="M43.9545 44.9773H56.1641L56.1641 11.2471H43.9545V44.9773Z"
        fill="#EBEBEB"
      />
      <path
        d="M56.1643 44.9773L222.396 44.9773V11.2471L56.1643 11.2471V44.9773Z"
        fill="#F5F5F5"
      />
      <path
        d="M219.09 41.6774V14.5469L59.4584 14.5469V41.6774L219.09 41.6774Z"
        fill="#E6E6E6"
      />
      <path
        d="M102.323 41.672V14.5415H88.5133V41.672H102.323Z"
        fill="#E0E0E0"
      />
      <path
        d="M105.497 41.672V14.5415H102.324V41.672H105.497Z"
        fill="#F5F5F5"
      />
      <path
        d="M192.174 41.6775H209.289C210.03 41.6775 210.631 41.0767 210.631 40.3355V24.6886C210.631 23.9475 210.03 23.3467 209.289 23.3467L192.174 23.3467C191.432 23.3467 190.832 23.9475 190.832 24.6886V40.3355C190.832 41.0767 191.432 41.6775 192.174 41.6775Z"
        fill="#FAFAFA"
      />
      <path
        d="M193.241 23.3467L208.222 23.3467C208.456 23.3467 208.646 23.1571 208.646 22.9232V21.5098C208.646 21.2759 208.456 21.0863 208.222 21.0863H193.241C193.007 21.0863 192.817 21.2759 192.817 21.5098V22.9232C192.817 23.1571 193.007 23.3467 193.241 23.3467Z"
        fill="white"
      />
      <path
        d="M210.631 37.3987H195.473C195.279 37.3987 195.093 37.3216 194.956 37.1844C194.819 37.0473 194.742 36.8612 194.742 36.6672V28.357C194.742 28.163 194.819 27.977 194.956 27.8398C195.093 27.7026 195.279 27.6256 195.473 27.6256H210.631V37.3987Z"
        fill="#F0F0F0"
      />
      <path
        d="M168.833 41.6775H185.948C186.689 41.6775 187.29 41.0767 187.29 40.3355V24.6886C187.29 23.9475 186.689 23.3467 185.948 23.3467L168.833 23.3467C168.092 23.3467 167.491 23.9475 167.491 24.6886V40.3355C167.491 41.0767 168.092 41.6775 168.833 41.6775Z"
        fill="#F5F5F5"
      />
      <path
        d="M169.9 23.3467L184.881 23.3467C185.115 23.3467 185.305 23.1571 185.305 22.9232V21.5098C185.305 21.2759 185.115 21.0863 184.881 21.0863H169.9C169.666 21.0863 169.476 21.2759 169.476 21.5098V22.9232C169.476 23.1571 169.666 23.3467 169.9 23.3467Z"
        fill="white"
      />
      <path
        d="M187.29 37.3987H172.133C171.939 37.3987 171.753 37.3216 171.615 37.1844C171.478 37.0473 171.401 36.8612 171.401 36.6672V28.357C171.4 28.2605 171.419 28.1648 171.455 28.0754C171.492 27.986 171.545 27.9047 171.613 27.8362C171.681 27.7677 171.762 27.7134 171.851 27.6763C171.941 27.6391 172.036 27.62 172.133 27.6201H187.29V37.3987Z"
        fill="#EBEBEB"
      />
      <path
        d="M157.696 41.6775H169.548C170.061 41.6775 170.478 41.2614 170.478 40.748V29.9079C170.478 29.3946 170.061 28.9785 169.548 28.9785H157.696C157.183 28.9785 156.767 29.3946 156.767 29.9079V40.748C156.767 41.2614 157.183 41.6775 157.696 41.6775Z"
        fill="#FAFAFA"
      />
      <path
        d="M158.433 28.9785H168.811C168.972 28.9785 169.103 28.848 169.103 28.687V27.7026C169.103 27.5416 168.972 27.4111 168.811 27.4111H158.433C158.272 27.4111 158.141 27.5416 158.141 27.7026V28.687C158.141 28.848 158.272 28.9785 158.433 28.9785Z"
        fill="white"
      />
      <path
        d="M170.478 38.7131H163.245C163.111 38.7131 162.982 38.6598 162.888 38.5649C162.793 38.47 162.739 38.3413 162.739 38.2072V32.4489C162.739 32.3147 162.793 32.186 162.888 32.0911C162.982 31.9962 163.111 31.9429 163.245 31.9429H170.478V38.7131Z"
        fill="#F0F0F0"
      />
      <path
        d="M67.6365 41.6775H79.4885C80.0018 41.6775 80.418 41.2614 80.418 40.748V29.9079C80.418 29.3946 80.0018 28.9785 79.4885 28.9785H67.6365C67.1231 28.9785 66.707 29.3946 66.707 29.9079V40.748C66.707 41.2614 67.1231 41.6775 67.6365 41.6775Z"
        fill="#F5F5F5"
      />
      <path
        d="M68.3734 28.9785H78.7515C78.9125 28.9785 79.043 28.848 79.043 28.687V27.7026C79.043 27.5416 78.9125 27.4111 78.7515 27.4111H68.3734C68.2124 27.4111 68.0819 27.5416 68.0819 27.7026V28.687C68.0819 28.848 68.2124 28.9785 68.3734 28.9785Z"
        fill="white"
      />
      <path
        d="M69.655 38.7131H78.3282C78.9721 38.7131 79.4941 38.1911 79.4941 37.5472V33.1088C79.4941 32.4649 78.9721 31.9429 78.3282 31.9429H69.655C69.0111 31.9429 68.4891 32.4649 68.4891 33.1088V37.5472C68.4891 38.1911 69.0111 38.7131 69.655 38.7131Z"
        fill="#EBEBEB"
      />
      <path
        d="M131.126 41.6775H150.865V20.7343L131.126 20.7343V41.6775Z"
        fill="#F0F0F0"
      />
      <path
        d="M131.126 23.5005L150.865 23.5005V20.7341L131.126 20.7341V23.5005Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M133.276 41.6775H136.796V20.7343H133.276V41.6775Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.3"
        d="M138.358 41.6775H140.118V20.7343H138.358V41.6775Z"
        fill="#E0E0E0"
      />
      <path
        d="M111.481 41.6775H129.174V22.9067L111.481 22.9067V41.6775Z"
        fill="#E0E0E0"
      />
      <path
        d="M111.481 25.387L129.174 25.387V22.9066L111.481 22.9066V25.387Z"
        fill="#F0F0F0"
      />
      <path
        opacity="0.3"
        d="M113.411 41.6775H116.562V22.9067H113.411V41.6775Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.3"
        d="M117.96 41.6775H119.538V22.9067H117.96V41.6775Z"
        fill="#F5F5F5"
      />
      <path
        d="M137.495 204.9C196.388 204.9 244.13 202.113 244.13 198.674C244.13 195.236 196.388 192.449 137.495 192.449C78.6017 192.449 30.8594 195.236 30.8594 198.674C30.8594 202.113 78.6017 204.9 137.495 204.9Z"
        fill="#F5F5F5"
      />
      <path
        d="M84.8945 197.013H88.0679L94.5082 19.4307H91.3293L84.8945 197.013Z"
        fill="#7F56D9"
      />
      <path
        d="M138.342 197.013H135.163L105.629 19.4307H108.808L138.342 197.013Z"
        fill="#7F56D9"
      />
      <path
        d="M68.5381 197.014H71.717L102.318 13.106H99.1389L68.5381 197.014Z"
        fill="#7F56D9"
      />
      <path
        d="M93.0401 24.1826H107.351V21.9112H93.0401V24.1826Z"
        fill="#7F56D9"
      />
      <path
        d="M89.6957 132.82H125.505V130.345H89.6957V132.82Z"
        fill="#7F56D9"
      />
      <path
        d="M70.6344 43.6056L70.0859 43.6465L70.2743 46.1749L70.8227 46.134L70.6344 43.6056Z"
        fill="#7F56D9"
      />
      <path
        d="M69.7428 31.6441L69.1943 31.6848L69.866 40.7235L70.4145 40.6828L69.7428 31.6441Z"
        fill="#7F56D9"
      />
      <path
        d="M77.3923 116.53L78.4813 116.541L71.6065 23.4016L70.5176 23.3906L77.3923 116.53Z"
        fill="#7F56D9"
      />
      <path
        d="M78.4822 116.541L143.099 117.162L136.224 24.0231L71.6074 23.4016L78.4822 116.541Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.8"
        d="M78.4822 116.541L143.099 117.162L136.224 24.0231L71.6074 23.4016L78.4822 116.541Z"
        fill="white"
      />
      <path
        d="M80.3237 116.007L141.954 116.04L135.289 24.5566L72.7725 24.5181L80.3237 116.007Z"
        fill="white"
      />
      <path
        d="M105.667 117.806H122.166C122.48 117.805 122.782 117.688 123.014 117.478C123.247 117.267 123.393 116.979 123.426 116.667L123.778 113.367H104.732L104.408 116.376C104.385 116.554 104.401 116.736 104.453 116.908C104.506 117.08 104.595 117.24 104.715 117.375C104.834 117.51 104.98 117.618 105.144 117.693C105.309 117.767 105.487 117.805 105.667 117.806Z"
        fill="#7F56D9"
      />
      <path
        d="M173.59 64.8647C172.972 64.6275 172.378 64.3348 171.813 63.9902C171.186 63.6107 170.537 63.1983 169.899 62.7473C168.618 61.8453 167.342 60.8498 166.077 59.8269C163.564 57.7645 162.343 56.89 159.906 54.6846L157.398 56.9395C159.036 59.2281 160.839 61.3935 162.794 63.4182C163.987 64.6612 165.236 65.8657 166.589 67.0261C167.265 67.5761 167.969 68.1811 168.734 68.72C169.553 69.3156 170.426 69.8348 171.34 70.271C171.929 70.543 172.547 70.746 173.183 70.876C174.189 71.0854 175.236 70.9798 176.18 70.5735C176.776 70.3001 177.305 69.9003 177.731 69.402C177.921 69.1794 178.09 68.9401 178.237 68.687C178.358 68.4671 178.479 68.2251 178.567 68.0161L178.683 67.7026L178.765 67.4441L178.864 67.1141L173.59 64.8647Z"
        fill="#FFB573"
      />
      <path
        d="M164.18 99.0293C165.23 92.9355 166.803 89.8281 167.534 88.6402C168.117 86.9572 168.964 85.5933 169.366 83.5254C168.959 75.9412 169.234 65.4475 170.07 63.7261C170.548 62.9671 173.92 61.1192 174.189 61.0532C174.695 56.2024 178.127 51.9511 178.127 51.9511C179.004 51.8913 179.884 51.8913 180.761 51.9511C177.363 56.4719 176.912 59.3923 176.642 60.5912C180.246 60.0865 183.898 60.0293 187.515 60.4207C188.494 55.5864 192.619 52.787 192.619 52.787C193.342 52.874 194.053 53.0362 194.742 53.271C191.217 57.0769 190.562 59.4363 190.128 60.7507C191.607 60.9707 193.29 63.8086 193.29 63.8086C190.881 73.6202 189.11 82.1669 189.066 84.2733C189.022 86.3798 189.869 85.9233 190.166 87.8262C193.048 88.1892 193.306 88.7392 193.306 88.7392C193.375 89.4666 193.269 90.2 192.998 90.8786C192.026 90.5291 191.013 90.3019 189.985 90.2021C187.273 104.634 183.478 118.482 183.198 132.424L153.273 131.594C153.273 131.594 160.11 105.646 164.18 99.0293Z"
        fill="white"
      />
      <path
        d="M161.771 55.7019L160.935 52.281L156.194 54.8164C156.194 54.8164 157.663 58.7268 159.863 58.3693L161.205 57.3408C161.448 57.1518 161.631 56.8963 161.731 56.6054C161.832 56.3145 161.846 56.0006 161.771 55.7019Z"
        fill="#FFB573"
      />
      <path
        d="M158.147 48.6897L155.342 52.9245L156.183 54.8165L160.935 52.2811L158.147 48.6897Z"
        fill="#FFB573"
      />
      <path
        d="M192.828 41.3364C191.519 44.5758 189.655 50.5376 191.475 52.886C189.347 54.5359 186.619 56.4224 182.736 56.2959C178.853 56.1694 180.074 53.546 181.383 52.193C185.183 51.5715 185.645 48.8491 185.535 46.2587L192.828 41.3364Z"
        fill="#FFB573"
      />
      <path
        d="M182.208 33.7743C182.239 33.7916 182.274 33.8007 182.309 33.8007C182.345 33.8007 182.38 33.7916 182.411 33.7743C182.642 33.6397 182.903 33.5634 183.17 33.5519C183.438 33.5404 183.704 33.5941 183.946 33.7083C183.97 33.7218 183.998 33.7302 184.026 33.7331C184.054 33.7359 184.083 33.7332 184.11 33.725C184.137 33.7168 184.162 33.7034 184.184 33.6854C184.206 33.6674 184.224 33.6453 184.237 33.6203C184.263 33.5712 184.269 33.514 184.253 33.4607C184.238 33.4073 184.203 33.3621 184.155 33.3343C183.849 33.1892 183.514 33.1194 183.176 33.1309C182.838 33.1423 182.508 33.2348 182.213 33.4003C182.188 33.4131 182.166 33.4306 182.148 33.452C182.131 33.4734 182.117 33.4981 182.109 33.5248C182.101 33.5514 182.098 33.5794 182.101 33.6072C182.103 33.6349 182.112 33.6618 182.125 33.6863C182.142 33.7242 182.171 33.7552 182.208 33.7743Z"
        fill="#263238"
      />
      <path
        d="M182.439 35.7488C181.79 36.7439 181.007 37.6452 180.112 38.4272C180.321 38.6534 180.577 38.8307 180.862 38.9467C181.148 39.0627 181.455 39.1144 181.762 39.0982L182.439 35.7488Z"
        fill="#ED893E"
      />
      <path
        d="M182.439 35.8642C182.368 36.2162 182.115 36.4692 181.889 36.4142C181.664 36.3592 181.532 36.0347 181.603 35.6827C181.675 35.3308 181.928 35.0778 182.153 35.1328C182.379 35.1878 182.516 35.5068 182.439 35.8642Z"
        fill="#263238"
      />
      <path
        d="M194.539 37.2282C193.356 41.5291 192.889 44.103 190.221 45.8794C186.206 48.5523 181.251 45.4339 181.119 40.8691C181.004 36.7552 182.951 30.4195 187.576 29.578C188.595 29.3867 189.646 29.4592 190.629 29.7887C191.612 30.1181 192.494 30.6936 193.192 31.4603C193.89 32.227 194.38 33.1595 194.615 34.1691C194.851 35.1787 194.825 36.2318 194.539 37.2282Z"
        fill="#FFB573"
      />
      <path
        d="M185.222 37.9486C184.818 38.8036 184.622 39.7416 184.649 40.6867C184.676 41.6317 184.925 42.5571 185.376 43.3879C186.261 45.1918 186.949 46.2918 190.359 46.8638C193.769 47.4358 192.619 42.7444 194.209 40.88C195.798 39.0156 201.446 33.1803 195.732 31.7173C196.959 27.4055 193.862 25.9701 189.913 27.1635C185.964 28.357 180.619 29.8309 181.345 27.4385C181.345 27.4385 176.637 33.3673 186.844 32.6633C183.484 35.5342 185.222 37.9486 185.222 37.9486Z"
        fill="#263238"
      />
      <path
        d="M187.147 27.73C188.968 26.8823 190.985 26.5476 192.982 26.7621C196.172 27.0261 198.68 28.687 199.582 33.1308C200.484 37.5747 199.857 41.43 197.701 41.045C196.992 40.1486 194.071 42.53 194.071 42.53C193.461 43.7454 189.292 40.6546 187.505 37.1237C185.717 33.5928 184.557 28.1425 187.147 27.73Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.3"
        d="M187.147 27.73C188.968 26.8823 190.985 26.5476 192.982 26.7621C196.172 27.0261 198.68 28.687 199.582 33.1308C200.484 37.5747 199.857 41.43 197.701 41.045C196.992 40.1486 194.071 42.53 194.071 42.53C193.461 43.7454 189.292 40.6546 187.505 37.1237C185.717 33.5928 184.557 28.1425 187.147 27.73Z"
        fill="white"
      />
      <path
        d="M185.343 27.9885C183.875 28.2305 184.397 32.8778 186.047 36.6946C187.697 40.5115 190.491 43.8169 193.345 44.2734C193.584 44.0497 193.775 43.7799 193.907 43.4802C194.038 43.1805 194.107 42.8572 194.11 42.5299C193.318 41.9799 190.53 40.5445 187.967 35.4132C185.404 30.2819 187.175 27.7135 187.175 27.7135C187.092 27.3835 185.343 27.9885 185.343 27.9885Z"
        fill="#7F56D9"
      />
      <path
        d="M186.63 37.9486C186.353 38.8513 185.769 39.6292 184.98 40.1485C183.946 40.814 183.226 40.0055 183.33 38.8615C183.435 37.8386 184.1 36.2546 185.217 36.0512C186.333 35.8477 186.96 36.8486 186.63 37.9486Z"
        fill="#FFB573"
      />
      <path
        d="M176.054 194.088H171.561L172.457 183.682H176.95L176.054 194.088Z"
        fill="#FFB573"
      />
      <path
        d="M170.961 193.593H176.433C176.523 193.592 176.61 193.622 176.68 193.678C176.75 193.734 176.799 193.813 176.818 193.901L177.703 197.894C177.725 197.991 177.724 198.091 177.701 198.188C177.679 198.285 177.634 198.375 177.571 198.452C177.508 198.529 177.428 198.59 177.337 198.632C177.247 198.673 177.148 198.693 177.049 198.691C175.283 198.664 174.002 198.559 171.78 198.559C170.416 198.559 167.413 198.702 165.527 198.702C163.64 198.702 163.398 196.838 164.168 196.667C167.628 195.908 169.118 194.869 170.218 193.868C170.423 193.687 170.688 193.589 170.961 193.593Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M176.95 183.688H172.457L171.995 189.05H176.488L176.95 183.688Z"
        fill="black"
      />
      <path
        d="M158.328 46.2643C157.778 46.4348 157.877 46.7263 157.811 46.9848L157.674 47.7768L157.399 49.3607L156.893 52.5396C156.735 53.5956 156.583 54.657 156.436 55.724C156.315 56.7909 156.161 57.8524 156.062 58.9249C156.058 58.994 156.079 59.0623 156.122 59.1166C156.165 59.171 156.227 59.2074 156.295 59.2191C156.363 59.2307 156.433 59.2166 156.492 59.1795C156.551 59.1425 156.593 59.0851 156.612 59.0184C156.893 57.9789 157.118 56.9339 157.371 55.889C157.624 54.844 157.833 53.7936 158.047 52.7431L158.674 49.5917L158.966 48.0078L159.114 47.2213C159.114 46.9518 159.334 46.7153 158.878 46.3578C158.801 46.2993 158.71 46.2602 158.614 46.2439C158.519 46.2276 158.42 46.2346 158.328 46.2643Z"
        fill="#263238"
      />
      <path
        d="M158.488 46.5448C159.065 46.6548 160.138 46.2973 159.802 44.8344C159.467 43.3714 158.576 43.0249 159.725 41.98C157.542 43.2394 156.557 46.1598 158.488 46.5448Z"
        fill="#7F56D9"
      />
      <path
        d="M198.284 56.7963C192.009 73.1637 193.23 89.7951 192.482 93.3149L168.569 92.5725C167.936 71.5688 171.418 58.2208 173.574 54.2279C173.781 53.8359 174.076 53.4969 174.435 53.2374C174.795 52.978 175.21 52.805 175.647 52.732C176.279 52.633 177.093 52.523 178.006 52.424C178.672 52.3525 179.387 52.2865 180.135 52.2425C180.542 52.215 180.96 52.1985 181.383 52.1875C184.76 52.2229 188.131 52.4542 191.481 52.8805C192.108 52.9575 192.746 53.051 193.362 53.15C193.978 53.249 194.489 53.348 195.012 53.4525C195.677 53.5789 196.299 53.7164 196.849 53.8429C197.154 53.9158 197.44 54.0556 197.685 54.2522C197.93 54.4489 198.128 54.6974 198.265 54.9799C198.403 55.2624 198.476 55.5719 198.479 55.886C198.482 56.2001 198.416 56.511 198.284 56.7963Z"
        fill="#7F56D9"
      />
      <path
        d="M170.763 63.0937L170.01 65.8436C188.187 71.7173 180.762 51.9456 180.762 51.9456C179.888 51.8248 179.001 51.8248 178.127 51.9456C181.647 61.7792 178.314 66.586 170.763 63.0937Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M180.607 58.8038C184.897 58.6498 186.965 66.3495 186.965 66.3495L186.415 66.762L190.16 73.2407L185.761 75.8037L181.652 69.4954L180.607 70.2709C178.83 68.9024 177.36 67.1769 176.29 65.2056C173.914 60.7617 175.404 58.9798 180.607 58.8038Z"
        fill="black"
      />
      <path
        d="M191.811 73.9447C191.767 73.571 191.686 73.2025 191.569 72.8448C191.517 72.6786 191.456 72.5152 191.388 72.3553L191.223 71.9868C190.909 71.2883 190.601 70.5789 190.255 69.9024C189.569 68.5357 188.811 67.2065 187.983 65.9206C187.16 64.6363 186.272 63.3954 185.321 62.2027C184.837 61.6087 184.348 61.0257 183.831 60.4538C183.578 60.1623 183.308 59.9038 183.044 59.5958L182.621 59.1723L182.153 58.7158L177.451 63.6656L178.056 64.3201C178.276 64.5566 178.496 64.7931 178.716 65.0406C179.151 65.5301 179.58 66.0415 179.997 66.553C180.85 67.576 181.647 68.6319 182.45 69.7099C183.253 70.7879 183.99 71.9098 184.678 73.0098C185.019 73.5323 185.321 74.0712 185.635 74.6102C185.232 74.9042 184.801 75.1567 184.348 75.3637C183.18 75.8887 181.971 76.3191 180.734 76.6506C178.155 77.3711 175.372 77.9211 172.655 78.4161L172.793 81.7875C175.77 81.984 178.758 81.8975 181.719 81.529C183.292 81.3394 184.845 81.0025 186.355 80.5225C187.233 80.2418 188.074 79.8597 188.863 79.384C189.354 79.0858 189.808 78.7314 190.216 78.3281C190.747 77.8095 191.169 77.1908 191.459 76.5077C191.791 75.6968 191.912 74.8151 191.811 73.9447Z"
        fill="#FFB573"
      />
      <path
        d="M174.25 78.0972L170.741 78.4602L172.611 83.5035C172.611 83.5035 176.686 82.585 176.631 80.3576L175.796 78.8891C175.642 78.6219 175.414 78.4049 175.14 78.2644C174.866 78.1238 174.557 78.0658 174.25 78.0972Z"
        fill="#FFB573"
      />
      <path
        d="M167.578 80.1156L168.288 84.7904L172.61 83.531L170.741 78.4822L167.578 80.1156Z"
        fill="#FFB573"
      />
      <path
        d="M178.276 52.391C182.549 52.842 187.752 63.6326 187.752 63.6326L180.602 68.4174C180.602 68.4174 177.632 67.3669 174.431 62.8351C170.598 57.3793 172.693 51.8135 178.276 52.391Z"
        fill="#7F56D9"
      />
      <path
        d="M177.049 92.8365C177.049 92.8365 172.144 128.145 170.868 141.273C169.465 155.672 170.07 187.345 170.07 187.345H178.32C178.32 187.345 180.63 155.672 183.22 141.631C186.168 125.643 194.77 101.686 192.504 93.3205L177.049 92.8365Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M178.529 103.352C178.232 103.451 176.923 103.605 175.548 103.831C174.552 111.233 173.238 121.138 172.215 129.498C176.021 122.838 179.563 109.996 178.529 103.352Z"
        fill="black"
      />
      <path
        d="M169.372 188.049H179.062L179.458 185.156L169.339 184.689L169.372 188.049Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.2"
        d="M169.372 188.049H179.062L179.458 185.156L169.339 184.689L169.372 188.049Z"
        fill="black"
      />
      <path
        d="M169.47 194.352C169.867 194.341 170.262 194.288 170.647 194.192C170.668 194.188 170.687 194.177 170.702 194.161C170.717 194.146 170.727 194.126 170.73 194.104C170.733 194.083 170.73 194.061 170.721 194.042C170.712 194.022 170.698 194.006 170.68 193.994C170.515 193.895 169.069 193.004 168.568 193.257C168.514 193.287 168.468 193.331 168.436 193.384C168.404 193.437 168.387 193.498 168.387 193.56C168.377 193.662 168.392 193.766 168.43 193.861C168.468 193.957 168.529 194.042 168.607 194.11C168.859 194.286 169.163 194.371 169.47 194.352ZM170.339 194.038C169.575 194.192 168.992 194.165 168.744 193.961C168.691 193.913 168.65 193.853 168.626 193.786C168.601 193.719 168.592 193.647 168.601 193.576C168.601 193.488 168.64 193.461 168.667 193.45C168.926 193.318 169.751 193.703 170.339 194.038Z"
        fill="#7F56D9"
      />
      <path
        d="M170.626 194.192C170.64 194.192 170.655 194.189 170.668 194.183C170.682 194.177 170.694 194.169 170.704 194.158C170.713 194.147 170.721 194.134 170.726 194.12C170.73 194.106 170.732 194.091 170.73 194.077C170.73 194.022 170.62 192.729 170.098 192.3C170.035 192.248 169.962 192.209 169.883 192.185C169.805 192.162 169.723 192.154 169.641 192.163C169.561 192.164 169.485 192.192 169.424 192.243C169.362 192.294 169.321 192.365 169.306 192.443C169.212 192.944 170.109 193.928 170.571 194.181L170.626 194.192ZM169.718 192.372C169.807 192.374 169.893 192.407 169.96 192.465C170.28 192.869 170.471 193.36 170.51 193.873C170.048 193.521 169.476 192.773 169.531 192.482C169.531 192.454 169.531 192.394 169.685 192.377H169.735L169.718 192.372Z"
        fill="#7F56D9"
      />
      <path
        d="M172.655 189.523L168.304 191.041L165.956 181.119L170.312 179.601L172.655 189.523Z"
        fill="#FFB573"
      />
      <path
        d="M167.787 191.321C167.76 191.277 167.237 190.183 166.528 189.941C166.428 189.905 166.322 189.89 166.216 189.895C166.111 189.901 166.007 189.928 165.912 189.974C165.692 190.084 165.637 190.216 165.637 190.326C165.634 190.348 165.634 190.37 165.637 190.392C165.741 190.898 167.083 191.437 167.677 191.492C167.7 191.501 167.726 191.501 167.749 191.492C167.764 191.485 167.778 191.474 167.788 191.461C167.799 191.448 167.806 191.433 167.81 191.416C167.814 191.4 167.814 191.382 167.81 191.366C167.806 191.35 167.798 191.334 167.787 191.321ZM165.851 190.353C165.848 190.329 165.852 190.303 165.862 190.281C165.873 190.258 165.89 190.24 165.912 190.227C165.94 190.203 165.972 190.183 166.005 190.166C166.082 190.128 166.167 190.108 166.253 190.106C166.324 190.108 166.394 190.121 166.462 190.144C166.912 190.396 167.273 190.78 167.496 191.244C166.863 191.118 165.912 190.656 165.851 190.353Z"
        fill="#7F56D9"
      />
      <path
        d="M167.744 191.453C167.758 191.44 167.769 191.424 167.774 191.406C167.78 191.387 167.781 191.368 167.777 191.349C167.773 191.328 167.762 191.308 167.746 191.294C167.73 191.279 167.71 191.269 167.689 191.266C167.458 191.233 165.451 191.002 165.044 191.475C165.004 191.519 164.977 191.573 164.965 191.631C164.954 191.689 164.958 191.749 164.978 191.805C165.001 191.899 165.045 191.986 165.109 192.058C165.172 192.131 165.251 192.188 165.341 192.223C165.957 192.46 167.101 191.844 167.728 191.459L167.744 191.453ZM165.236 191.585C165.511 191.36 166.556 191.371 167.343 191.442C166.474 191.937 165.764 192.157 165.418 192.02C165.358 191.997 165.306 191.96 165.264 191.912C165.223 191.864 165.194 191.807 165.181 191.745C165.169 191.726 165.162 191.704 165.162 191.682C165.162 191.659 165.169 191.637 165.181 191.618C165.202 191.602 165.227 191.591 165.253 191.585H165.236Z"
        fill="#7F56D9"
      />
      <path
        d="M167.958 190.716L172.556 189.017C172.64 188.985 172.732 188.983 172.817 189.011C172.902 189.039 172.975 189.096 173.024 189.171L175.224 192.608C175.28 192.692 175.316 192.787 175.329 192.886C175.343 192.986 175.333 193.087 175.301 193.182C175.269 193.277 175.216 193.364 175.145 193.435C175.075 193.506 174.989 193.56 174.894 193.593C173.244 194.176 170.791 194.968 168.706 195.738C166.265 196.64 166.281 196.75 163.416 197.806C161.689 198.449 160.561 196.876 161.216 196.453C164.246 194.511 164.84 194.253 167.034 191.431C167.268 191.109 167.588 190.861 167.958 190.716Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M165.962 181.125L167.166 186.24L171.522 184.716L170.312 179.601L165.962 181.125Z"
        fill="black"
      />
      <path
        d="M166.869 138.144C170.68 128.58 184.287 93.0456 184.287 93.0456L168.569 92.5562C168.569 92.5562 158.119 121.903 154.011 135.422C149.721 149.479 165.142 185.096 165.142 185.096L173.051 182.626C173.051 182.626 163.058 147.708 166.869 138.144Z"
        fill="#263238"
      />
      <path
        d="M164.763 185.943L173.695 182.797L173.051 180.052L163.768 182.841L164.763 185.943Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.2"
        d="M164.763 185.943L173.695 182.797L173.051 180.052L163.768 182.841L164.763 185.943Z"
        fill="black"
      />
      <path
        d="M168.294 87.3973L168.041 89.8557C179.156 90.1527 184.315 91.4561 184.315 91.4561C184.458 90.7161 184.541 89.9657 184.562 89.2122C180.504 87.6558 172.804 87.3093 168.294 87.3973Z"
        fill="white"
      />
      <path
        d="M193.29 88.7282L192.982 90.8677C190.057 90.7904 187.131 90.9874 184.243 91.4561C184.157 90.6956 184.257 89.9255 184.534 89.2122C189.072 88.0738 191.943 88.4092 193.29 88.7282Z"
        fill="white"
      />
      <path
        d="M194.742 53.2601L192.619 52.7761C192.982 61.1853 195.507 65.8381 195.507 65.8381C196.053 64.815 196.389 63.6931 196.496 62.5383C195.177 59.6307 194.575 56.4487 194.742 53.2601Z"
        fill="white"
      />
      <path
        d="M183.083 91.4561C182.896 92.0941 184.326 91.7971 184.535 91.5881C184.744 91.3791 187.07 88.8822 185.822 88.7447C184.573 88.6072 184.474 88.8822 184.474 88.8822C184.02 88.5963 183.498 88.4368 182.962 88.4202C182.093 88.4092 183.473 90.1526 183.083 91.4561Z"
        fill="white"
      />
      <path
        d="M182.956 88.9263C182.005 89.6687 179.31 93.2216 179.15 96.912C179.842 96.5796 180.555 96.293 181.284 96.054C181.284 96.054 181.741 93.0016 183.391 91.1482L182.956 88.9263Z"
        fill="white"
      />
      <path
        d="M185.415 89.1957C186.273 90.3781 188.671 95.284 188.753 99.3483C188.139 98.7266 187.493 98.1373 186.817 97.5829C186.817 97.5829 186.097 93.6505 184.617 90.9831L185.415 89.1957Z"
        fill="white"
      />
      <path
        d="M177.456 105.756H175.806L160.165 198.675H161.815L177.456 105.756Z"
        fill="#7F56D9"
      />
      <path
        d="M196.282 105.756H194.632L178.985 198.675H180.635L196.282 105.756Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.2"
        d="M196.282 105.756H194.632L178.985 198.675H180.635L196.282 105.756Z"
        fill="black"
      />
      <path
        d="M198.416 105.756H200.066L215.713 198.675H214.063L198.416 105.756Z"
        fill="#7F56D9"
      />
      <path
        d="M179.596 105.756H181.246L196.893 198.675H195.243L179.596 105.756Z"
        fill="#7F56D9"
      />
      <path
        d="M177.435 101.686H198.444C198.881 101.686 199.301 101.86 199.61 102.169C199.92 102.478 200.094 102.898 200.094 103.336V105.778H175.807V103.336C175.807 102.902 175.977 102.486 176.282 102.177C176.587 101.868 177.001 101.692 177.435 101.686Z"
        fill="#7F56D9"
      />
    </svg>
  );
};
