/**
 *
 *
 * DropdownMenu
 *
 *
 */
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

import { HeroIcon } from "../../types";

export interface MenuItem {
  name: string;
  icon?: HeroIcon | React.FC;
  onClick: () => void;
  iconClassName?: string;
}

interface Props {
  toggle: React.ReactNode;
  options: MenuItem[];
}

export const DropdownMenu = (props: Props) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>{props.toggle}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            "bg-white-300 absolute right-0 z-10 mt-2 w-max origin-top-right rounded-md",
            "bg-white shadow-lg ring-1 ring-slate-700 ring-opacity-5 focus:outline-none"
          )}
        >
          {props.options.map((option) => (
            <Menu.Item key={option.name}>
              <button
                onClick={option.onClick}
                className={clsx(
                  "group flex items-center gap-2 px-2 py-2 text-sm text-zinc-900",
                  "w-full rounded-t-md hover:bg-zinc-100"
                )}
              >
                {option.icon && (
                  <option.icon
                    className={clsx(
                      "h-4 w-4 text-zinc-300 group-hover:text-zinc-500",
                      option.iconClassName
                    )}
                  />
                )}
                {option.name}
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
