/**
 *
 *
 * <ChallengeVerticalListItem />
 *
 *
 */
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/20/solid";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TabKey } from "../../containers/ChallengeTabs";
import { Challenge, Template } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { pluralize } from "../../utils/pluralize";
import { isChallengeResponse } from "../../utils/type-guards";
import { Button } from "../ButtonV3";
import { useLayoutContext } from "../LayoutProvider";
import { WrapComponent } from "./WrapComponent";

interface Props {
  section?: TabKey;
  challenge: Challenge | Template;
  onTemplatePreview?: (id: number) => void;
  onSelect?: (template: Challenge | Template) => void;
  selectedId?: number;
  isPost?: boolean;
}

export const ChallengeVerticalListItem = ({
  challenge,
  section,
  onTemplatePreview,
  onSelect,
  selectedId,
  isPost,
}: Props) => {
  const { t } = useTranslation();
  const layoutContext = useLayoutContext();
  const isChallengeType = isChallengeResponse(challenge);
  const completions = useMemo(
    () =>
      isChallengeType
        ? challenge.activities?.filter((activity) => activity.isCompleted)
            .length || 0
        : 0,
    [challenge]
  );
  return (
    <li
      className={clsx("w-full", !isPost ? "min-w-60 max-w-80 p-2" : "pt-2")}
      onClick={() => (isPost ? layoutContext.setNoFocusMode() : {})}
    >
      <WrapComponent challenge={challenge}>
        <div className="relative h-full pt-14">
          <div
            className={clsx(
              "h-full w-full p-0 pt-24 shadow-md",
              "rounded-lg bg-white",
              "flex flex-col overflow-hidden",
              "ring-2",
              selectedId === challenge.id
                ? "ring-challenge"
                : "ring-transparent"
            )}
          >
            <div className="flex flex-grow flex-col gap-y-4 p-4 pt-0">
              <span className="sr-only">Challenge Title</span>
              <div className="w-full rounded-lg bg-zinc-100 px-4 py-4">
                <h1 className="mb-1 truncate whitespace-nowrap text-center text-base font-medium leading-7 text-zinc-900">
                  {challenge.name}
                </h1>
                <div>
                  {isChallengeType && (
                    <>
                      <span className="sr-only">Dates</span>
                      <div className="flex flex-col items-center gap-x-4 text-zinc-500">
                        <div className="flex items-center gap-x-1">
                          <ClockIcon className="inline h-5 w-5 min-w-[1rem] text-zinc-400" />
                          {section === "current" && (
                            <>
                              {t("translation:challenge:end_in", {
                                time:
                                  challenge.endAt &&
                                  dayjs(challenge.endAt).toNow(true),
                              })}
                            </>
                          )}
                          {section === "upcoming" && (
                            <>
                              {t("translation:challenge:start_in", {
                                time:
                                  challenge.startAt &&
                                  dayjs(challenge.startAt).toNow(true),
                              })}
                            </>
                          )}
                          {section === "ended" && (
                            <>
                              {t("translation:challenge:ended", {
                                time:
                                  challenge.endAt &&
                                  dayjs(challenge.endAt).format("MMM D"),
                              })}
                            </>
                          )}
                        </div>
                        {/**
                         *
                         * Hide participants until challenge groups is implemented.
                         *
                         * Currently, the whole team is invited to all challenges.
                         *
                         */}
                        {/* <span className="hidden h-4 w-px bg-zinc-500" />*/}
                        {/* <div className="flex items-center gap-x-1">*/}
                        {/*  <UserGroupIcon className="inline h-5 w-5 min-w-[1rem]" />*/}
                        {/*  {t(*/}
                        {/*    `translation:challenge:${pluralize("n_participant", "ns_participant", 5)}`,*/}
                        {/*    { n: 5 }*/}
                        {/*  )}*/}
                        {/* </div>*/}
                      </div>
                    </>
                  )}
                  {!isChallengeType && (
                    <div className="flex items-center justify-center gap-x-1">
                      {challenge.activities?.length}{" "}
                      {t(
                        `translation:challenge:${pluralize("action", "actions", challenge.activities?.length || 0)}`
                      )}
                    </div>
                  )}
                </div>
              </div>

              <span className="sr-only">Challenge Description</span>
              {!!challenge.description && (
                <p className="line-clamp-3 min-h-16 flex-grow truncate whitespace-pre-line pl-0 text-sm text-zinc-900">
                  {challenge.description}
                </p>
              )}
            </div>

            {(section === "ended" || section === "current") && !isPost && (
              <>
                <span className="sr-only">Actions Completed</span>
                {challenge.activities && (
                  <div className="w-full rounded-none bg-zinc-100 px-4 py-3">
                    <p className="mb-1 text-sm font-medium leading-5 text-zinc-900">
                      {t("translation:challenge:actions_completed")}
                    </p>
                    <div className="h-5 overflow-hidden rounded-full bg-zinc-200">
                      <div
                        className={clsx(
                          "relative h-5 overflow-hidden rounded-full",
                          section === "current" ? "bg-challenge" : "bg-zinc-600"
                        )}
                        style={{
                          width: `${Math.ceil((completions / challenge.activities.length) * 100)}%`,
                        }}
                      >
                        <p className="absolute right-2 top-1/2 -translate-y-1/2 text-sm font-bold text-white">
                          {completions}/{challenge.activities.length}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {section === "templates" && (
              <>
                <span className="sr-only">Actions</span>
                <div className="flex flex-row justify-center gap-x-4 gap-y-2 bg-zinc-100 px-4 py-3">
                  <Button
                    className="flex flex-row gap-x-2 text-sm font-semibold"
                    variant="outlined"
                    size="md"
                    onClick={() =>
                      onTemplatePreview && onTemplatePreview(challenge.id!)
                    }
                  >
                    <ArrowsPointingOutIcon className="h-5 w-5" />
                    {t("translation:common:preview")}
                  </Button>

                  <Button
                    className={clsx(
                      selectedId === challenge.id
                        ? "bg-challenge"
                        : "bg-zinc-500"
                    )}
                    size="md"
                    variant="challenge"
                    onClick={() => onSelect && onSelect(challenge)}
                  >
                    {t("translation:common:select")}
                    {selectedId === challenge.id && (
                      <CheckCircleIcon className="h-5" />
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>

          <div
            className={clsx(
              "absolute h-40 w-40",
              "left-1/2 -translate-x-1/2",
              "top-0"
            )}
          >
            {challenge.banner ? (
              <div
                className={clsx(
                  "h-full w-full bg-cover bg-center",
                  "shadow-m rounded-full border-4 border-white bg-zinc-100"
                )}
                style={{
                  backgroundImage: `url(${challenge.banner.medium})`,
                }}
              />
            ) : (
              <div
                className={clsx(
                  "pointer-events-none h-full w-full rounded-full border-4 border-white shadow-md",
                  "bg-zinc-100"
                )}
              />
            )}
          </div>
        </div>
      </WrapComponent>
    </li>
  );
};
