/**
 *
 *
 * Notifications
 *
 *
 */
import { useTranslation } from "react-i18next";

import { classNames } from "../../utils";
import { ChallengeNotification } from "./ChallengeNotification";
import { GenericNotification } from "./GenericNotification";
import { LinkedNotification } from "./LinkedNotification";
import { ReactionNotification } from "./ReactionNotification";
import { ShoutoutNotification } from "./ShoutoutNotification";
import { SkeletonNotificationListItem } from "./SkeletonNotificationListItem";
import { useNotifications } from "./useNotifications";

export const Notifications = (props: ReturnType<typeof useNotifications>) => {
  const { t } = useTranslation();
  return (
    <div className="grid max-h-96 grid-rows-[auto_1fr]">
      <div className="relative flex-row border-b border-zinc-300 bg-white p-4">
        <div className="relative flex items-center justify-between">
          <h1 className="text-base font-medium text-zinc-900">
            {t("translation:header:notification:notifications")}
          </h1>

          {props.unreadMessages?.length > 0 && (
            <button
              className="cursor-pointer text-xs font-medium text-zinc-500 underline"
              onClick={props.onMarkAllAsRead}
            >
              {t("translation:header:notification:click_mark_read")}
            </button>
          )}
        </div>
      </div>

      <ul className="relative grid grid-flow-row auto-rows-min overflow-y-scroll bg-white">
        {props.isLoading ? (
          <SkeletonNotificationListItem />
        ) : props.isError ? (
          <li className="p-4 text-zinc-500">
            {t("translation:toast:notification_load_error")}
          </li>
        ) : !props.messages?.length ? (
          <li className="p-4 text-zinc-500">
            {t("translation:header:notification:no_notifications")}
          </li>
        ) : (
          props.messages?.map((message, index) => (
            <li
              key={`message-${index}`}
              className={classNames(
                message.read
                  ? "bg-white"
                  : "cursor-pointer bg-zinc-100 hover:bg-zinc-50",
                "border-b border-zinc-200 p-4 transition duration-150 ease-in-out"
              )}
              onClick={() => props.onReadMessage(message)}
            >
              {message.source === "SHOUTOUT" ? (
                <ShoutoutNotification message={message} />
              ) : message.source === "MENTION" ||
                message.source === "COMMENT" ||
                message.source === "REPLY" ||
                message.source === "REBUTTAL" ? (
                <LinkedNotification message={message} />
              ) : message.source === "REACTION" ? (
                <ReactionNotification message={message} />
              ) : message.source === "CHALLENGE" ? (
                <ChallengeNotification message={message} />
              ) : (
                <GenericNotification message={message} />
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};
