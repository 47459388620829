/**
 *
 *
 * <SkeletonMembersCardItem />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { Avatar } from "../../components/AvatarV3";

export const SkeletonMembersCardItem = () => {
  return (
    <li
      className={clsx(
        "animate-pulse",
        "flex h-fit flex-col divide-y divide-zinc-200 rounded-lg bg-white text-center shadow"
      )}
    >
      <div className="flex flex-col gap-2 p-8">
        <div className="mb-6 flex justify-center">
          <Avatar loading={true} size="xxl" />
        </div>
        <div className="h-5 rounded bg-zinc-300" />
        <div className="h-5 rounded bg-zinc-300" />
        <div className="h-5 rounded bg-zinc-300" />
      </div>
      <div className="border-t-1 border border-zinc-200 px-8 py-4">
        <div className="h-6 rounded bg-zinc-300" />
      </div>
    </li>
  );
};
