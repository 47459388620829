/**
 *
 *
 * useLayout
 *
 *
 */
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useLayoutContext } from "../../components/LayoutProvider";
import { SearchParam } from "../../consts";
import { useConfirmationModal } from "../../hooks/useConfirmationModal";
import { useNotificationsMeList } from "../../services/teambuilder/endpoints/notifications/notifications";

export const useLayout = () => {
  const layoutContext = useLayoutContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNotificationsModalOpen = searchParams.has(SearchParam.NOTIFICATIONS);
  const closeNotificationsModal = () => {
    searchParams.delete(SearchParam.NOTIFICATIONS);
    setSearchParams(searchParams);
  };

  const { data: notifications } = useNotificationsMeList(
    // params should match those in useNotifications.ts
    { ordering: "-created_at" },
    {
      query: {
        select: ({ data }) => data,
        // Poll every 15 seconds to update the unread messages count.
        // We should not need to also poll in the Notifications container.
        refetchInterval: 15000,
      },
    }
  );

  useEffect(() => {
    if (notifications) {
      const unreadMessages = notifications?.filter((m) => !m.read).length;
      layoutContext.setUnreadNotificationsCount(unreadMessages);
    }
  }, [notifications]);

  const { confirmationModalContext } = useConfirmationModal();

  return {
    isNavCollapsed: layoutContext.isNavCollapsed,
    isNotificationsModalOpen,
    closeNotificationsModal,
    isFocusMode: layoutContext.isFocusMode,
    confirmationModalContext,
  };
};
