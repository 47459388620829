/**
 *
 *
 * main
 *
 *
 */
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { PageProvider } from "./components/PageProvider";
import { ToastProvider } from "./hooks/useToast";
import "./index.css";
import "./locales";
import { queryClient } from "./query-client";
import { createRoutes } from "./router";
import { initMixpanel } from "./utils/mixpanel";
import { initSentry } from "./utils/sentry";

initMixpanel();
initSentry();

const router = createBrowserRouter(createRoutes());

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <PageProvider>
          <ToastProvider>
            <RouterProvider router={router} />
            <ReactQueryDevtools position="bottom-right" />
          </ToastProvider>
        </PageProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
