/**
 *
 *
 * Shared constants
 *
 *
 */
export const TEAM_EVERYONE_ID = 2;

export const LANGUAGE_EN = "en";
export const LANGUAGE_ES = "es";

export const SUPPORT_LINK = "https://support.intuition.us/support/tickets/new";

/**
 *
 * Modal search params
 *
 * Add one of these to the URL to open a modal.
 */
export enum ModalSearchParam {
  edit_bio = "edit_bio",
  edit_avatar = "edit_avatar",
  edit_profile = "edit_profile",
  edit_question = "edit_questions",
  edit_cover_image = "edit_cover_image",
}

/**
 *
 * An enum of search params for type safety.
 *
 * ** This is a v3 thing which is intended to replace ModalSearchParam. **
 *
 * Note that most of the time only the existence of the param
 * is important. The value is typically ignored.
 */
export enum SearchParam {
  /**
   * Left sidebar is collapsed to show only icons when nav_collapsed exists.
   */
  NAV_COLLAPSED = "nav_collapsed",
  /**
   * Notifications modal is open when notifications exists.
   */
  NOTIFICATIONS = "notifications",
  /**
   * Profile modal is open when profile exists.
   * A value of "me" means the current user.
   * A value of "123" means the user with id 123.
   */
  PROFILE = "profile",
  /**
   * Template search
   */
  SEARCH = "search",
  /**
   * Profile modal form visibility.
   * When the value exists show the form instead of the contents
   * of the profile section.
   */
  SHOW_PROFILE_FORM = "show_profile_form",
  SHOW_CONTACT_FORM = "show_contact_form",
  SHOW_ANSWERS_FORM = "show_answers_form",
  /**
   * Post create form modal visibility.
   * The value is ignored but is typically set to "1" to indicate visibility.
   */
  SHOW_POST_MODAL = "post_modal",
  WITH_SHOUTOUT = "with_shoutout",
  WITH_IMAGE = "with_image",
  WITH_URL = "with_url",
  IS_CHALLENGE_COMPLETE = "challenge_complete",
  /**
   * Post detail modal visibility.
   * The value of the param is the id of the post to show.
   *
   * Links to post detail in emails, Slack, and Teams use this param, so
   * don't change this without updating the backend.
   */
  SHOW_POST_DETAIL_MODAL = "post",
  /**
   * Template preview modal visibility.
   * The value of the param is the id of the template to preview.
   */
  TEMPLATE_PREVIEW = "template_preview",
  /**
   * Currently selected template.
   * The value of the param is the id of the selected template.
   */
  SELECTED_TEMPLATE = "template",
  /**
   * Currently selected challenge.
   * The value of the param is the id of the selected challenge.
   */
  CHALLENGE = "id",
  /**
   * Used to identify that current post modal is to send Work Anniversary Congratulation
   * Its value is id of the user who receives the congrat message
   * This is used with YEARS param which is work anniversary years
   * Example:
   * /?post_modal=1&work_anniversary_user_id=177&years=6
   * Message: Congratulations on your {years} work anniversary @user
   * years is passed by YEARS param
   */
  WORK_ANNIVERSARY_USER_ID = "work_anniversary_user_id",
  YEARS = "years",
  /**
   * Used to identify that current post modal is to send Happy Birthday
   * Its value is id of the user who receives Happy Birthday
   * Example:
   * /?post_modal=1&birthday_user_id=177
   * Message: Happy birthday @user
   */
  BIRTHDAY_USER_ID = "birthday_user_id",
  /**
   * Question Modal to add or edit
   * Its value is number or undefined, number means to edit question, undefined means to create new question
   */
  SHOW_QUESTION_MODAL = "show_question_modal",
  /**
   * Add Activity
   * Its value is true
   */
  ADD_ACTIVITY = "add_activity",
  /**
   * Edit Activity
   * Its value is activity's id to edit
   */
  EDIT_ACTIVITY = "edit_activity",
}
