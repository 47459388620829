import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import * as React from "react";

import { useCarousel } from "./hook";

interface Props {
  className?: string;
}

const CarouselNext = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
      <button
        ref={ref}
        className={clsx(
          orientation === "vertical" && "rotate-90",
          "flex-shrink-0 hover:opacity-60",
          className
        )}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        <ChevronRightIcon className="h-8 w-8" />
        <span className="sr-only">Next slide</span>
      </button>
    );
  }
);
CarouselNext.displayName = "CarouselNext";

export { CarouselNext };
