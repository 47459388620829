/**
 *
 *
 * <FormHeader />
 *
 *
 */
import React from "react";

interface Props {
  title: string;
  description?: string;
  className?: string;
}

export const FormHeader = (props: Props) => {
  return (
    <div className={props.className}>
      <h2 className="text-base font-medium leading-7 text-zinc-900">
        {props.title}
      </h2>
      {props.description && (
        <p className="text-sm text-zinc-500">{props.description}</p>
      )}
    </div>
  );
};
