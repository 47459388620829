/**
 *
 *
 * TeamMembers <MemberListItem />
 *
 *
 *
 */
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { _PeopleResponse } from "services/teambuilder/schemas";

import { Avatar } from "../../components/Avatar";
import { fullName } from "../../utils/username";
import { UserDropdown } from "./UserDropdown";

interface Props {
  member: _PeopleResponse;
  currentUserIsAdmin: boolean;
  onEdit: () => void;
  onRemove: () => void;
  onEmailInvite: () => void;
  openProfileModal: (id: number) => void;
}

export const MemberListItem = (props: Props) => {
  const { t } = useTranslation();

  return (
    <li
      className={clsx(
        "relative h-fit bg-white p-4",
        "border-t border-zinc-400 last:border-b",
        "flex gap-x-3"
      )}
    >
      <button onClick={() => props.openProfileModal(props.member?.id || 0)}>
        <Avatar user={props.member} size="xl" />
      </button>

      <div className="flex grow flex-col gap-1">
        <div className={clsx("flex gap-2")}>
          <button
            className="grow"
            onClick={() => props.openProfileModal(props.member?.id || 0)}
          >
            <h3
              className={clsx(
                "text-left text-sm font-medium ",
                "text-zinc-900 underline hover:text-zinc-500"
              )}
            >
              {fullName(props.member)}
            </h3>
          </button>

          {props.currentUserIsAdmin && (
            <UserDropdown
              member={props.member}
              onEdit={props.onEdit}
              onRemove={props.onRemove}
              onEmailInvite={props.onEmailInvite}
            />
          )}
        </div>

        {props.member.jobTitle && (
          <div className="text-sm text-zinc-500">
            <span className="sr-only">{t("translation:common:title")}</span>
            {props.member.jobTitle}
          </div>
        )}

        <div className="truncate text-sm text-zinc-900">
          <span className="sr-only">{t("translation:common:email")}</span>
          {props.member.email}
        </div>
      </div>
    </li>
  );
};
