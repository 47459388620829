/**
 *
 *
 * useCommentList
 *
 *
 */
import React, { useEffect, useMemo } from "react";

import {
  getSocialPostsCommentsListQueryKey,
  getSocialPostsCommentsRepliesListQueryKey,
  getSocialPostsCommentsRepliesRebuttalsListQueryKey,
  useSocialPostsCommentsList,
  useSocialPostsCommentsRepliesList,
  useSocialPostsCommentsRepliesRebuttalsList,
} from "../../services/teambuilder/endpoints/social/social";
import {
  SocialPostsCommentsListOrdering,
  SocialPostsCommentsRepliesListOrdering,
  SocialPostsCommentsRepliesRebuttalsListOrdering,
} from "../../services/teambuilder/schemas";
import { PostType } from "../../types";
import { getPostAncestorPks } from "../../utils/post-utils";
import {
  _isComment,
  _isPost,
  _isReply,
  isPostType,
} from "../../utils/type-guards";

interface Props {
  post: PostType;
  detail?: boolean;
  detailQueryKey?: readonly unknown[];
  isCommentFormVisible?: boolean;
  onSubmit?: () => void;
  className?: string;
  setCommentsQueryKey: React.Dispatch<
    React.SetStateAction<
      ReturnType<typeof getSocialPostsCommentsListQueryKey> | undefined
    >
  >;
}

const commentsListParams = {
  limit: 999,
  // TODO: this type is insane
  ordering: "-created_at" as SocialPostsCommentsListOrdering,
};

export const useCommentList = (props: Props) => {
  const isComments = _isPost(props.post);
  const isReplies = _isComment(props.post);
  const isRebuttals = _isReply(props.post);
  const { postsPk, commentsPk, repliesPk } = getPostAncestorPks(props.post);

  /**
   * Comments list request
   */
  const commentsQueryKey = useMemo(
    () => getSocialPostsCommentsListQueryKey(postsPk, commentsListParams),
    [postsPk]
  );
  const { data: commentsList } = useSocialPostsCommentsList(
    postsPk,
    commentsListParams,
    {
      query: {
        enabled: isComments,
        select: ({ data }) => data,
      },
    }
  );
  useEffect(() => {
    props.setCommentsQueryKey(commentsQueryKey);
  }, [commentsQueryKey]);

  /**
   * Replies list request
   */
  const repliesListParams = {
    limit: 999,
    ordering: "created_at" as SocialPostsCommentsRepliesListOrdering,
  };
  const repliesQueryKey = getSocialPostsCommentsRepliesListQueryKey(
    postsPk,
    commentsPk,
    repliesListParams
  );
  const { data: repliesList } = useSocialPostsCommentsRepliesList(
    postsPk,
    commentsPk,
    repliesListParams,
    {
      query: {
        enabled: isReplies,
        select: ({ data }) => data,
      },
    }
  );

  /**
   * Rebuttals list request
   */
  const rebuttalsListParams = {
    limit: 999,
    ordering: "created_at" as SocialPostsCommentsRepliesRebuttalsListOrdering,
  };
  const rebuttalsQueryKey = getSocialPostsCommentsRepliesRebuttalsListQueryKey(
    postsPk,
    commentsPk,
    repliesPk,
    rebuttalsListParams
  );
  const { data: rebuttalsList } = useSocialPostsCommentsRepliesRebuttalsList(
    postsPk,
    commentsPk,
    repliesPk,
    rebuttalsListParams,
    {
      query: {
        enabled: isRebuttals,
        select: ({ data }) => data,
      },
    }
  );

  const comments = isComments
    ? commentsList
    : isReplies
      ? repliesList
      : rebuttalsList;

  /**
   * queryKey
   */
  const queryKey = isComments
    ? commentsQueryKey
    : isReplies
      ? repliesQueryKey
      : rebuttalsQueryKey;

  return {
    post: props.post,
    comments,
    queryKey,
    className: props.className,
    isDetail: Boolean(props.detail),
    isCommentFormVisible: Boolean(props.isCommentFormVisible),
    onSubmit: props.onSubmit,
    activities: isPostType(props.post)
      ? props.post.challenge?.activities
      : undefined,
    detailQueryKey: props.detailQueryKey,
  };
};
