import { ChevronDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { NavItemV3 } from "../../types";

type Props = {
  items: NavItemV3[];
  filterBy?: string | null;
};

export const FilterMenu = (props: Props) => {
  const { t } = useTranslation();
  return (
    <DropdownMenu
      items={props.items}
      menuButtonContent={() => (
        <>
          <span className="text-sm font-medium text-zinc-600">
            {props.filterBy || t("translation:common:show_all")}
          </span>
          <ChevronDownIcon className="h-4 w-4" />
        </>
      )}
      menuButtonClassNames={(open) =>
        clsx(
          "hidden",
          "flex flex-row items-center gap-1 rounded-md px-3 py-2",
          open && "bg-zinc-100"
        )
      }
      menuClassNames={"right-0 mt-2 origin-top-left"}
    />
  );
};
