/**
 *
 *
 * <ChallengeWizard />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { Breadcrumbs } from "../../components/Breadcrumbs";

export const ChallengeWizard = () => {
  const { t } = useTranslation();
  return (
    <div className="grid h-full min-h-[44rem] grid-rows-[auto_1fr] content-start overflow-auto sm:h-screen sm:overflow-hidden">
      <Breadcrumbs
        paths={[
          { name: t("translation:challenge:challenges"), to: "/challenges" },
          { name: "Create Challenge", to: "" },
        ]}
      />
      <Outlet />
    </div>
  );
};
