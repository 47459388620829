/**
 *
 *
 * Register Link
 *
 *
 */

/**
 *
 * Returns https://auth.{domain}/register
 *
 */
export const registerLink = () =>
  `${import.meta.env.VITE_AUTH_API_DOMAIN}/register`;
