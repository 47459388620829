/**
 *
 *
 * useNavItems
 *
 * Returns lists of navigation items for the sidebar,
 * avatar menu, and mobil nav.
 *
 *
 */
import {
  ArrowRightStartOnRectangleIcon,
  BuildingOfficeIcon,
  HomeIcon,
  LockClosedIcon,
  PlayCircleIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { IconChallenge } from "../components/IconChallenge";
import { useLayoutContext } from "../components/LayoutProvider";
import { NavItemV3 } from "../types";
import { logout } from "../utils/logout";
import { useRoles } from "./useRoles";

export const useNavItems = () => {
  const { t } = useTranslation();
  const { isAdmin } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const layoutContext = useLayoutContext();

  const primary: NavItemV3[] = useMemo(
    () => [
      {
        name: t("translation:header:home"),
        to: "/",
        icon: HomeIcon,
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:header:challenges"),
        to: "/challenges",
        icon: IconChallenge,
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:header:people"),
        to: "/people",
        icon: UserGroupIcon,
        onClick: layoutContext.setNoFocusMode,
      },
    ],
    [isAdmin, t]
  );

  const secondary: NavItemV3[] = useMemo(() => {
    const items = [
      {
        name: t("translation:common:user_guide"),
        onClick: () => {
          searchParams.delete("profile");
          searchParams.append("profile", "me");
          setSearchParams(searchParams);
        },
        icon: UserIcon,
      },
      isAdmin && {
        name: t("translation:settings:company:title"),
        to: "/company-settings/general",
        icon: BuildingOfficeIcon,
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:header:menu:privacy_and_security"),
        to: "/privacy-and-security",
        icon: LockClosedIcon,
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:header:help"),
        href: "https://support-teambuilder.intuition.us/",
        icon: QuestionMarkCircleIcon,
      },
      {
        name: t("translation:header:menu:logout"),
        icon: ArrowRightStartOnRectangleIcon,
        onClick: logout,
      },
    ];
    // This clever trick removes all falsy values from the array
    return items.filter(Boolean) as NavItemV3[];
  }, [isAdmin, t]);

  // Paths without "/" are relative to the current parent route.
  const companySettings: NavItemV3[] = useMemo(() => {
    const items = [
      {
        name: t("translation:settings:company:general_settings"),
        to: "/company-settings/general",
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:settings:menu:banter"),
        to: "/company-settings/banter",
        onClick: layoutContext.setNoFocusMode,
      },
      {
        name: t("translation:settings:menu:departments_and_locations"),
        to: "/company-settings/departments-and-locations",
        onClick: layoutContext.setNoFocusMode,
      },
      isAdmin && {
        name: t("translation:profile_settings:profile_settings"),
        to: "/company-settings/profile-settings",
        onClick: layoutContext.setNoFocusMode,
      },
    ];
    return items as NavItemV3[];
  }, [isAdmin, t]);
  return { primary, secondary, companySettings };
};
