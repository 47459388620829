/**
 *
 *
 * <PeopleForm />
 *
 *
 */
import {
  BriefcaseIcon,
  DocumentDuplicateIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/ButtonV3";
import { Checkbox } from "../../components/Checkbox";
import { CopyButton } from "../../components/CopyButton";
import { SlideOverFormInputGroup } from "../../components/SlideOverFormInputGroupV3";
import { SlideOverForm } from "../../components/SlideOverFormV3";
import { SlideOver } from "../../components/SlideOverV3";
import { registerLink } from "../../utils/register-link";
import { getTranslation } from "../../utils/translation";
import { MultiSelect } from "./MultiSelect";
import { Skeleton } from "./Skeleton";
import { usePeopleForm } from "./usePeopleForm";

export const PeopleForm = (props: ReturnType<typeof usePeopleForm>) => {
  const { t } = useTranslation();
  const errors = props.form.formState.errors;

  return (
    <>
      <SlideOver
        isOpen={props.isOpen}
        onClose={props.close}
        title={
          props.isUpdate
            ? t("translation:people:edit_person_modal:edit_person")
            : t("translation:people:invitation")
        }
        subtitle={
          props.isUpdate ? (
            t("translation:people:edit_person_modal:edit_team_member")
          ) : (
            <div className="space-y-2">
              <p>{t("translation:people:invitation_description_1")}</p>
              <p className="italic">
                <Trans
                  i18nKey="translation:people:invitation_description_2"
                  components={{
                    a: (
                      <Link
                        to="/company/settings"
                        className={clsx(
                          "font-semibold text-zinc-500 underline"
                        )}
                      />
                    ),
                  }}
                />
              </p>
              <CopyButton
                value={registerLink()}
                beforeTooltip={t(
                  "translation:people:click_copy_registration_link"
                )}
                className={clsx(
                  "inline-flex items-center gap-0.5 text-xs text-white",
                  "rounded bg-zinc-600 px-2 py-1"
                )}
              >
                <DocumentDuplicateIcon className="h-4 w-4 stroke-white stroke-1 hover:stroke-white" />
                {t("translation:people:copy_registration_link")}
              </CopyButton>
            </div>
          )
        }
      >
        {props.isLoading ? (
          <div className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <Skeleton />
          </div>
        ) : (
          <SlideOverForm
            onSubmit={props.onHandleSubmit}
            footer={
              <>
                <Button
                  variant="outlined"
                  type="button"
                  size="lg"
                  data-testid="cancel-button"
                  onClick={props.close}
                >
                  {/* <Upload /> */}
                  {/* Bulk Invite */}
                  {t("translation:common:cancel")}
                </Button>

                <Button
                  variant="contained"
                  size="lg"
                  type="submit"
                  data-testid="submit-button"
                  disabled={Boolean(
                    errors.firstName || errors.email || errors.lastName
                  )}
                >
                  {props.isUpdate
                    ? t("translation:common:save")
                    : t("translation:common:submit")}
                </Button>
              </>
            }
          >
            <SlideOverFormInputGroup
              id="firstName"
              label={t("translation:common:first_name")}
              errorMessage={getTranslation(t, errors.firstName?.message)}
              {...props.form.register("firstName")}
            />

            <SlideOverFormInputGroup
              id="lastName"
              label={t("translation:common:last_name")}
              errorMessage={getTranslation(t, errors.lastName?.message)}
              {...props.form.register("lastName")}
            />

            <SlideOverFormInputGroup
              id="email"
              label={t("translation:common:email")}
              errorMessage={getTranslation(t, errors.email?.message)}
              {...props.form.register("email")}
            />

            <SlideOverFormInputGroup
              id="jobTitle"
              label={t("translation:common:job_title")}
              {...props.form.register("jobTitle")}
            />

            <input type="hidden" {...props.form.register("locations")} />
            {props.allLocations && (
              <MultiSelect
                name="locs"
                label={t("translation:common:locations")}
                icon={<MapPinIcon className="h-4 w-4" />}
                onChange={(options) => {
                  const ids = options.map((o) => o.id as number);
                  props.form.setValue("locations", ids, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={
                  props.person?.locations
                    ? props.person.locations.map((location) => ({
                        id: location.id,
                        name: location.name,
                      }))
                    : []
                }
                options={props.allLocations}
                errorMessage={getTranslation(t, errors.locations?.message)}
              />
            )}

            <input type="hidden" {...props.form.register("departments")} />
            {props.allDepartments && (
              <MultiSelect
                name="depts"
                label={t("translation:common:departments")}
                icon={<BriefcaseIcon className="h-4 w-4" />}
                onChange={(options) => {
                  const ids = options.map((o) => o.id as number);
                  props.form.setValue("departments", ids, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={
                  props.person?.departments
                    ? props.person.departments.map((department) => ({
                        id: department.id,
                        name: department.name,
                      }))
                    : []
                }
                options={props.allDepartments}
                errorMessage={getTranslation(t, errors.departments?.message)}
              />
            )}

            {/* <input type="hidden" {...register("roles")} />*/}
            <div className="space-y-1 border-zinc-300 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:border-b sm:px-6 sm:py-8">
              <div className="flex items-start gap-2 sm:col-span-2 sm:col-start-2">
                <Checkbox id="hrRole" {...props.form.register("isHR")} />
                <div>
                  <label
                    htmlFor="hrRole"
                    className="mb-1 block text-sm font-medium text-zinc-900"
                  >
                    {t("translation:people:edit_person_modal:hr_privileges")}
                  </label>
                  <p className="text-sm text-zinc-300">
                    {t(
                      "translation:people:edit_person_modal:hr_privileges_description"
                    )}
                  </p>
                </div>
              </div>
            </div>

            {!props.person && (
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-8">
                <div className="flex items-center sm:col-span-2 sm:col-start-2">
                  <Checkbox
                    id="inviteViaEmail"
                    {...props.form.register("inviteViaEmail")}
                  />
                  <label
                    htmlFor="inviteViaEmail"
                    className="ml-2 block text-sm font-medium text-zinc-900"
                  >
                    {t(
                      "translation:people:edit_person_modal:send_email_invite"
                    )}
                  </label>
                </div>
              </div>
            )}
          </SlideOverForm>
        )}
      </SlideOver>
    </>
  );
};
