/**
 *
 *
 * <ChallengeWizardPreview />
 *
 *
 */
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeActivityListItem } from "../../components/ChallengeActivityListItemV3/ChallengeActivityListItem";
import { ChallengeCreateProgress } from "../../components/ChallengeCreateProgress";
import { ChallengePublishConfirmationModal } from "../../components/ChallengePublishConfirmationModal";
import { useLayoutContext } from "../../components/LayoutProvider";
import { pluralize } from "../../utils/pluralize";
import { useChallengeWizardPreview } from "./useChallengeWizardPreview";

export const ChallengeWizardPreview = (
  props: ReturnType<typeof useChallengeWizardPreview>
) => {
  const { t } = useTranslation();
  const { isMobileMode } = useLayoutContext();

  return (
    <>
      <div className="grid grid-rows-[auto_auto_1fr_auto] overflow-hidden">
        <ChallengeCreateProgress
          title={t("translation:challenge:confirm_launch")}
          total={5}
          current={5}
        />
        {/**
         *
         * Challenge header
         *
         */}
        <div className={clsx("px-4", !isMobileMode && "sm:px-8")}>
          <div
            className={clsx(
              "relative grid pt-[9.5rem]",
              !isMobileMode && "sm:pl-20 sm:pt-0"
            )}
          >
            <div
              className={clsx(
                "w-full bg-white",
                "flex min-h-full flex-col gap-y-4 overflow-hidden",
                !isMobileMode && "sm:min-h-40 sm:py-4 sm:pl-4"
              )}
            >
              <div className="flex flex-grow flex-col gap-y-4">
                <span className="sr-only">Challenge Title</span>
                <div
                  className={clsx(
                    "relative w-full rounded-lg bg-zinc-100 p-4",
                    !isMobileMode && "sm:py-2 sm:pl-20"
                  )}
                >
                  <h1
                    className={clsx(
                      "mb-1 truncate whitespace-nowrap text-center text-lg font-semibold leading-7 text-zinc-900",
                      !isMobileMode && "sm:text-left"
                    )}
                  >
                    {props.challenge?.name}
                  </h1>
                  <div
                    className={clsx(
                      "text-center text-sm leading-5 text-zinc-600",
                      !isMobileMode && "sm:text-left"
                    )}
                  >
                    {t("translation:challenge:template")}
                  </div>
                </div>

                <span className="sr-only">Challenge Description</span>
                <p
                  className={clsx(
                    "flex-grow whitespace-pre-line pl-0 text-center text-sm font-normal leading-5 text-zinc-900",
                    !isMobileMode && "sm:min-h-14 sm:pl-20 sm:text-left"
                  )}
                >
                  {props.challenge?.description}
                </p>
                <div
                  className={clsx(
                    "ml-0 space-y-1 border-y border-y-zinc-300 py-3 text-sm",
                    !isMobileMode && "sm:ml-20"
                  )}
                >
                  <div className="flex flex-row items-center gap-x-2">
                    <CalendarIcon className="inline h-5 w-5 min-w-[1rem]" />
                    {t("translation:challenge:starts", {
                      time:
                        props.challenge?.startAt &&
                        dayjs(props.challenge?.startAt).format(
                          "MMM D, YYYY [at] h:mma"
                        ),
                    })}
                  </div>
                  <div className="flex flex-row items-center gap-x-2">
                    <CalendarIcon className="inline h-5 w-5 min-w-[1rem]" />
                    {t("translation:challenge:ends", {
                      time:
                        props.challenge?.endAt &&
                        dayjs(props.challenge?.endAt).format(
                          "MMM D, YYYY [at] h:mma"
                        ),
                    })}
                  </div>
                  {props.dateError && (
                    <p className="ml-7 mt-1 text-sm tracking-wide text-red-500">
                      {props.dateError}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/**
             *
             * Badge
             *
             */}
            <div
              className={clsx(
                "absolute h-40 w-40",
                "left-1/2 top-0 -translate-x-1/2 ",
                "rounded-full border-4 border-white bg-zinc-100",
                !isMobileMode &&
                  "sm:left-0 sm:top-20 sm:-translate-y-1/2 sm:translate-x-0"
              )}
            >
              {props.challenge?.banner ? (
                <div
                  className={clsx(
                    "h-full w-full bg-cover bg-center",
                    "rounded-full shadow-md"
                  )}
                  style={{
                    backgroundImage: `url(${props.challenge?.banner?.medium})`,
                  }}
                />
              ) : (
                <div
                  className={clsx(
                    "pointer-events-none h-full w-full rounded-full border-4 border-white shadow-md",
                    "bg-zinc-200"
                  )}
                />
              )}
            </div>
          </div>
        </div>

        {/**
         *
         * Activities
         *
         */}
        <div
          className={clsx(
            "gird-rows-[auto_1fr] mt-4 grid overflow-hidden px-4",
            !isMobileMode && "sm:grid-cols-[auto_1fr] sm:px-8"
          )}
        >
          {/**
           * Tab
           */}
          <div
            className={clsx(
              "h-fit w-full flex-shrink-0 p-4",
              "text-center text-sm text-zinc-600",
              "leading-4",
              !isMobileMode &&
                "sm:w-44 sm:rounded-l-lg sm:rounded-tr-none sm:px-4 sm:text-base"
            )}
          >
            <div className="font-medium">
              {props.challenge?.activities?.length}{" "}
              {t(
                `translation:challenge:${pluralize("action", "actions", props.challenge?.activities?.length || 0)}`
              )}
            </div>
          </div>

          {/**
           * List
           */}
          <div
            className={clsx(
              "rounded-lg bg-zinc-100 p-4",
              "text-center text-base font-medium",
              "grid content-start gap-y-6 overflow-y-auto",
              !isMobileMode && "sm:bg-zinc-100 sm:p-6"
            )}
          >
            {props.challenge?.activities?.map((activity, i) => (
              <ChallengeActivityListItem
                index={i}
                key={i}
                activity={activity}
                isComment
                isTemplate
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end gap-x-4 bg-white px-4 py-4 lg:px-8">
          <Button variant="text" size="md" onClick={props.onCancel}>
            {t("translation:common:cancel")}
          </Button>
          <Button variant="outlined" size="md" onClick={props.onBack}>
            <ChevronLeftIcon className="h-5 text-zinc-900" />{" "}
            {t("translation:common:back")}
          </Button>
          <Button
            variant="challenge"
            size="md"
            onClick={() => props.setIsOpenModal(true)}
            disabled={props.isLoading}
          >
            {t("translation:challenge:launch_challenge")}{" "}
            <ChevronRightIcon className="h-5 text-white" />
          </Button>
        </div>
      </div>
      <ChallengePublishConfirmationModal
        isOpen={props.isOpenModal}
        onCancel={() => props.setIsOpenModal(false)}
        onConfirm={props.onNext}
      />
    </>
  );
};
// Lazy load requires a default export
export default ChallengeWizardPreview;
