/**
 *
 *
 * <Profile />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { Avatar } from "../../components/AvatarV3";
import { Modal } from "../../components/ModalV3";
import { Badges } from "../Badges";
import { ProfileForm } from "../ProfileForm";
import { ContactInfo } from "./ContactInfo";
import { ProfileInfo } from "./ProfileInfo";
import { QAndA } from "./QAndA";
import { useProfileModal } from "./useProfileModal";

export const ProfileModal = (props: ReturnType<typeof useProfileModal>) => {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.close}
        className={clsx("w-screen max-w-4xl bg-white")}
        showCloseButton
      >
        <div
          className={clsx(
            "aspect-4/1 w-full",
            !props.userGuide?.coverImage && props.coverImagePattern
          )}
          style={
            props.userGuide?.coverImage?.large
              ? {
                  background: `url('${props.userGuide?.coverImage?.large}') center/cover no-repeat`,
                }
              : {}
          }
        ></div>

        <div
          className={clsx(
            "relative flex justify-center",
            "-top-16 -mb-16 sm:-top-24 sm:-mb-40 sm:block sm:px-8"
          )}
        >
          <Avatar
            user={props.user}
            loading={props.isLoadingUser}
            size="xxxl"
            className="ring-4 ring-white"
          />
        </div>

        <section
          className={clsx(
            "border-b border-zinc-300",
            "px-4 pb-16 sm:pl-60 sm:pr-48",
            // Render on top of the avatar container
            "z-1 relative"
          )}
        >
          {props.isProfileFormOpen && props.user?.id ? (
            <ProfileForm
              userId={props.user.id}
              onCancel={props.closeProfileForm}
            />
          ) : (
            <ProfileInfo
              showEditButton={props.userIsMe}
              user={props.user}
              userIsMe={props.userIsMe}
              userGuide={props.userGuide}
              openProfileInfoForm={props.openProfileForm}
              allLanguages={props.allLanguages}
            />
          )}
        </section>

        {props.user?.id && (
          <ContactInfo
            user={props.user}
            userIsMe={props.userIsMe}
            userGuide={props.userGuide}
            phones={props.phones}
            isContactFormOpen={props.isContactFormOpen}
            closeContactForm={props.closeContactForm}
            openContactInfoForm={props.openContactForm}
          />
        )}

        {props.user && props.questions?.length && (
          <QAndA
            user={props.user}
            userIsMe={props.userIsMe}
            answeredQuestions={props.answeredQuestions}
            isQAFormOpen={props.isAnswersFormOpen}
            openQAForm={props.openAnswersForm}
            closeQAForm={props.closeAnswersForm}
          />
        )}

        {props.user && <Badges user={props.user} />}
      </Modal>
    </>
  );
};
