/**
 *
 *
 * usePostList
 *
 *
 */
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useLayoutContext } from "../../components/LayoutProvider";
import { SearchParam } from "../../consts";
import { useGetPinnedPosts } from "../../hooks/useGetPinnedPosts";
import { useMe } from "../../hooks/useMe";
import {
  usePostFeedList,
  usePostFeedListParams,
} from "../../hooks/usePostFeedList";
import { usePostModalContent } from "../../hooks/usePostModalContent";
import { Post } from "../../services/teambuilder/schemas";
import { Feature as PostModalFeature } from "../PostModal/usePostModal";

interface Props {
  className?: string;
}

export const schema = z.object({
  content: z
    .string()
    .min(1, { message: "Please type little something." })
    .max(5000, { message: "translation:validation:less_than_5000" }),
});

export type FormData = z.infer<typeof schema>;

export const usePostList = ({ className }: Props) => {
  const layoutContext = useLayoutContext();
  const { reset } = usePostModalContent();
  const [searchParams, setSearchParams] = useSearchParams();
  const openPostModal = (feature?: PostModalFeature) => {
    reset();
    if (searchParams.has(SearchParam.SHOW_POST_MODAL)) {
      searchParams.set(SearchParam.SHOW_POST_MODAL, "1");
    } else {
      searchParams.append(SearchParam.SHOW_POST_MODAL, "1");
    }
    if (feature) {
      if (searchParams.has(feature)) {
        searchParams.set(feature, "1");
      } else {
        searchParams.append(feature, "1");
      }
    }
    setSearchParams(searchParams);
  };

  /**
   *
   *
   * Pinned Posts
   *
   */
  const {
    response: { data: pinnedPosts = [], isLoading: isLoadingPinnedPosts },
  } = useGetPinnedPosts();

  const onClickPinnedPost = (post: Post) => {
    if (post.id) {
      searchParams.set(SearchParam.SHOW_POST_DETAIL_MODAL, post.id.toString());
      setSearchParams(searchParams);
    }
  };

  /**
   * PostModal state
   */
  const { me, loading: isLoadingMe } = useMe();

  const feedParams = usePostFeedListParams();
  const feed = usePostFeedList(
    feedParams.postsListParams,
    feedParams.adsListParams
  );

  return {
    me,
    isLoadingMe,
    adsQueryKey: feedParams.adsListQueryKey,
    coffeeChats: feed.adsListResponse?.data,
    postListQueryKey: feedParams.postsListQueryKey,
    posts: feed.postsListResponse?.data,
    pinnedPosts,
    isLoadingPosts: feed.isLoadingPostsList,
    isLoadingCoffeeChat: feed.isLoadingAdsList,
    isLoadingPinnedPosts,
    loadMore: feedParams.loadMore,
    hasMore: feed.postsListResponse?.meta?.pagination.hasMore,
    className,
    isFocusMode: layoutContext.isFocusMode,
    toggleFocusMode: layoutContext.toggleFocusMode,
    openPostModal,
    onClickPinnedPost,
  };
};
