/**
 *
 *
 * <SkeletonChallengeHorizontalListItem />
 *
 *
 */
import clsx from "clsx";
import React from "react";

export const SkeletonChallengeHorizontalListItem = () => {
  return (
    <li className="w-full animate-pulse p-4">
      <div className="block:bg-zinc-50">
        <div className="relative pl-32 pt-0">
          <div
            className={clsx(
              "h-60 w-full p-4 pt-4 shadow-md",
              "rounded-lg bg-white",
              "flex flex-col gap-y-4 overflow-hidden"
            )}
          >
            <div className="flex flex-grow flex-col gap-y-4 p-0">
              <div className="h-10 w-full rounded-lg bg-zinc-300 px-2 py-2 pl-32"></div>
              <div className="h-10 w-full flex-grow rounded-lg bg-zinc-300 pl-32"></div>
            </div>
            <div className="h-10 w-full rounded-lg bg-zinc-300 px-2 py-2 pl-32"></div>
          </div>
          <div
            className={clsx(
              "absolute h-64 w-64 border-4 border-white",
              "left-0 translate-x-0",
              "top-1/2 -translate-y-1/2",
              "rounded-full bg-zinc-300"
            )}
          />
        </div>
      </div>
    </li>
  );
};
