/**
 *
 *
 * usePostModal
 *
 *
 */
import { XCircleIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReactEditor } from "slate-react";
import { z } from "zod";

import { SearchParam, TEAM_EVERYONE_ID } from "../../consts";
import { useMe } from "../../hooks/useMe";
import {
  PostModalContentItem,
  usePostModalContent,
} from "../../hooks/usePostModalContent";
import { useToast } from "../../hooks/useToast";
import { ErrorType } from "../../services/teambuilder/axios-instance";
import { usePeopleRetrieve } from "../../services/teambuilder/endpoints/people/people";
import { useRecognitionShoutoutsCreate } from "../../services/teambuilder/endpoints/recognition/recognition";
import {
  useSocialPostsCreate,
  useSocialPostsDestroy,
  useSocialPostsPartialUpdate,
  useSocialPostsPostImageUploadCreate,
} from "../../services/teambuilder/endpoints/social/social";
import {
  PaginatedPostList,
  Post,
  PostImageUploadRequest,
  PostRequest,
  PostWrite,
  PostWriteRequest,
  ShoutoutCreate,
  ShoutoutCreateRequest,
  SimpleUserModel,
} from "../../services/teambuilder/schemas";
import { NavItemV3 } from "../../types";
import { numberOrdinalSuffix } from "../../utils/number-ordinal-suffix";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export type Feature =
  | SearchParam.WITH_SHOUTOUT
  | SearchParam.WITH_IMAGE
  | SearchParam.WITH_URL;

interface Props {
  listQueryKey: readonly unknown[];
  isOpen?: boolean;
  onClose?: () => void;
}

export const schema = z.object({
  // Content is optional for image posts. Validation for shoutouts and posts is
  // handled on submit.
  content: z
    .string()
    .max(5000, { message: "translation:validation:less_than_5000" })
    .optional(),
  // Url is optional, but if it's set, it must be a valid url
  url: z.union([
    z
      .string()
      .regex(
        // accepts full url or url without protocol
        // https://www.linkedin.com or www.linkedin.com
        /^(?:(?:https?|http):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i,
        { message: "translation:validation:invalid_url" }
      )
      .nullish(),
    z.literal(""),
  ]),
  mentions: z.array(
    z.object({
      user: z.number(),
      contentText: z.string(),
      contentOffset: z.number(),
    })
  ),
});

export type FormData = z.infer<typeof schema>;

export const usePostModal = (props: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const [tempPost, setTempPost] = useState<PostWriteRequest | null>(null);
  const [editor, setEditor] = useState<ReactEditor>();

  const {
    previewImage,
    previewImageUrl,
    selectedRecipient,
    selectedValueId,
    selectedURL,
    selectedValueLabel,
    reset,
    values,
    onContextUpdate,
  } = usePostModalContent();
  /**
   * Get requests
   */
  const { me, loading: isLoadingMe } = useMe();

  /**
   * Search params
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const isChallengeCompleteModal = searchParams.has(
    SearchParam.IS_CHALLENGE_COMPLETE
  );
  const toggleSearchParam = (key: Feature) => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
    } else {
      searchParams.append(key, "1");
    }
    setSearchParams(searchParams);
  };

  /**
   * Feature visibility toggles
   */
  const isShoutoutVisible = searchParams.has(SearchParam.WITH_SHOUTOUT);
  const toggleShoutout = () => {
    // Shoutouts don't allow images or links
    if (
      searchParams.has(SearchParam.WITH_IMAGE) ||
      searchParams.has(SearchParam.WITH_URL)
    ) {
      searchParams.delete(SearchParam.WITH_IMAGE);
      searchParams.delete(SearchParam.WITH_URL);
      setSearchParams(searchParams);
      // Reset URL input so validation errors don't block other requests
      form.setValue("url", "", { shouldValidate: true });
    }

    toggleSearchParam(SearchParam.WITH_SHOUTOUT);
  };
  // Hide shoutout if other features are toggled
  const hideShoutout = () => {
    if (!searchParams.has(SearchParam.WITH_SHOUTOUT)) return;
    searchParams.delete(SearchParam.WITH_SHOUTOUT);
    setSearchParams(searchParams);
  };
  const hideURL = () => {
    if (!searchParams.has(SearchParam.WITH_URL)) return;
    searchParams.delete(SearchParam.WITH_URL);
    setSearchParams(searchParams);
  };
  const hideImage = () => {
    if (!searchParams.has(SearchParam.WITH_IMAGE)) return;
    searchParams.delete(SearchParam.WITH_IMAGE);
    setSearchParams(searchParams);
  };

  const isImageEditorVisible = searchParams.has(SearchParam.WITH_IMAGE);
  const toggleImage = () => {
    hideShoutout();
    hideURL();
    toggleSearchParam(SearchParam.WITH_IMAGE);
  };

  const isUrlInputVisible = searchParams.has(SearchParam.WITH_URL);
  const toggleUrl = () => {
    hideShoutout();
    hideImage();
    toggleSearchParam(SearchParam.WITH_URL);
  };

  const onCancel = () => {
    onDeleteClick(true);
    hideImage();
    hideShoutout();
    hideURL();
    if (isChallengeComplete) {
      reset();
      onClose();
    }
  };

  /**
   * Modal visibility
   */
  const isOpen =
    searchParams.has(SearchParam.SHOW_POST_MODAL) || !!props.isOpen;
  const content = searchParams.get(SearchParam.WITH_SHOUTOUT);
  const onClose = (isCancel?: boolean) => {
    searchParams.delete(SearchParam.SHOW_POST_MODAL);
    if (searchParams.has(SearchParam.WITH_IMAGE))
      searchParams.delete(SearchParam.WITH_IMAGE);
    if (searchParams.has(SearchParam.WITH_URL))
      searchParams.delete(SearchParam.WITH_URL);
    if (searchParams.has(SearchParam.WITH_SHOUTOUT))
      searchParams.delete(SearchParam.WITH_SHOUTOUT);
    if (searchParams.has(SearchParam.BIRTHDAY_USER_ID))
      searchParams.delete(SearchParam.BIRTHDAY_USER_ID);
    if (searchParams.has(SearchParam.WORK_ANNIVERSARY_USER_ID))
      searchParams.delete(SearchParam.WORK_ANNIVERSARY_USER_ID);
    if (searchParams.has(SearchParam.YEARS))
      searchParams.delete(SearchParam.YEARS);
    searchParams.delete(SearchParam.IS_CHALLENGE_COMPLETE);
    setSearchParams(searchParams);
    if (isCancel) reset();
  };

  const [isChallengeComplete, setIsChallengeComplete] = useState(false);
  useEffect(() => {
    if (isOpen) {
      form.reset({});
      reset();
      setIsChallengeComplete(isChallengeCompleteModal);
    }
  }, [isOpen]);

  /**
   *
   */
  const congratUserId = parseInt(
    searchParams.get(SearchParam.WORK_ANNIVERSARY_USER_ID) || ""
  );
  const years = parseInt(searchParams.get(SearchParam.YEARS) || "");
  const isCongratAnniversary =
    searchParams.has(SearchParam.WORK_ANNIVERSARY_USER_ID) &&
    Number.isInteger(congratUserId) &&
    Number.isInteger(years);
  const birthUserId = parseInt(
    searchParams.get(SearchParam.BIRTHDAY_USER_ID) || ""
  );
  const isHappyBirthday =
    searchParams.has(SearchParam.BIRTHDAY_USER_ID) &&
    Number.isInteger(birthUserId);
  const userId = isCongratAnniversary
    ? congratUserId
    : isHappyBirthday
      ? birthUserId
      : null;
  const { data: user, isLoading: isLoadingUser = false } = usePeopleRetrieve(
    userId!,
    {
      query: {
        enabled: Boolean(userId !== null),
      },
    }
  );

  const defaultPostValue = useMemo(() => {
    if (isCongratAnniversary && user && years) {
      return [
        {
          type: "paragraph",
          children: [
            {
              text: `Congratulations on your ${numberOrdinalSuffix(years)} work anniversary`,
            },
            {
              text: " ",
            },
            {
              type: "mention",
              person: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              },
              children: [
                {
                  text: "",
                },
              ],
            },
            {
              text: "",
            },
          ],
        },
      ];
    }
    if (isHappyBirthday && user) {
      return [
        {
          type: "paragraph",
          children: [
            {
              text: "Happy birthday",
            },
            {
              text: " ",
            },
            {
              type: "mention",
              person: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              },
              children: [
                {
                  text: "",
                },
              ],
            },
            {
              text: "!",
            },
            {
              text: " ",
            },
          ],
        },
      ];
    }
    return [
      {
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ];
  }, [isCongratAnniversary, isHappyBirthday, user, years]);

  /**
   *
   *
   * Create requests
   *
   *
   */
  const onMutate = () => {
    onClose();
  };

  const onSuccess = async () => {
    openToast({
      title: t("translation:toast:post_add_success"),
      type: "success",
    });
    form.reset();
  };

  const onError = (
    _context?: PaginatedPostList,
    _requestVariable?: {
      data: PostWriteRequest;
    },
    _error?: ErrorType<unknown>
  ) => {
    const error = _error as AxiosError<{
      detail: string;
    }>;
    openToast({
      title: t("translation:common:error"),
      description:
        error.response?.data.detail || t("translation:toast:post_add_failed"),
      type: "danger",
    });
  };

  const onSettled = (data?: void | Post) => {
    // if post is in draft, need to keep cached posts
    if (data?.isDraft) return true;
    onContextUpdate(PostModalContentItem.PreviewImage, undefined);
    onContextUpdate(PostModalContentItem.PreviewImageUrl, undefined);
  };

  /**
   *
   * Post Image Upload
   *
   */
  const { mutate: uploadPostImage, isLoading: isLoadingPostImage } =
    useSocialPostsPostImageUploadCreate(
      optimisticMutationOptions<
        void | Post,
        PaginatedPostList,
        {
          id: number;
          data: PostImageUploadRequest;
        }
      >({
        queryKey: props.listQueryKey,
        optimisticUpdateFn: (context, requestVariables) => {
          const imageUrl = previewImage && URL.createObjectURL(previewImage);
          return {
            meta: context.meta,
            data: context.data.map((post) => {
              if (post.id === requestVariables?.id) {
                return {
                  id: requestVariables?.id,
                  user: me as SimpleUserModel,
                  commentsCount: 0,
                  content: tempPost?.content,
                  image: {
                    thumbnail: imageUrl,
                    medium: imageUrl,
                    large: imageUrl,
                  },
                };
              }
              return post;
            }),
          };
        },
        onSuccess: (
          _data?: void | Post,
          variables?: {
            id: number;
            data: PostImageUploadRequest;
          }
        ) => {
          if (variables?.id) {
            updatePost({
              id: variables?.id,
              data: {
                isDraft: false,
              },
            });
          }
        },
        onError: (
          _?: PaginatedPostList,
          variables?: {
            id: number;
            data: PostImageUploadRequest;
          },
          _error?: ErrorType<unknown>
        ) => {
          const error = _error as AxiosError<{ detail: string }>;
          openToast({
            type: "danger",
            title: t("translation:common:error"),
            description: error.response?.data.detail,
          });
          if (variables?.id) {
            deletePost({ id: variables?.id });
          }
        },
        onSettled,
      })
    );

  /**
   * Create post request
   */
  const { mutate: createPost, isLoading: isCreating } = useSocialPostsCreate(
    optimisticMutationOptions<
      PostWrite,
      PaginatedPostList,
      {
        data: PostWriteRequest;
      }
    >({
      queryKey: props.listQueryKey,
      optimisticUpdateFn: (context) => {
        let newPost: Post = {
          id: Math.random(),
          user: me as SimpleUserModel,
          commentsCount: 0,
          content: tempPost?.content,
        };
        const imageUrl = previewImage && URL.createObjectURL(previewImage);
        if (imageUrl) {
          newPost = {
            ...newPost,
            image: {
              thumbnail: imageUrl,
              medium: imageUrl,
              large: imageUrl,
            },
          };
        }
        return {
          meta: context.meta,
          data: [newPost, ...context.data],
        };
      },
      onMutate,
      onSuccess: async (data?: Post) => {
        if (previewImage && data) {
          uploadPostImage({
            id: data.id!,
            data: { file: previewImage },
          });
        } else {
          onSuccess();
        }
      },
      onError,
      onSettled,
    })
  );

  /**
   * Delete post with error on image upload
   */
  const { mutate: deletePost } = useSocialPostsDestroy(
    optimisticMutationOptions<void, PaginatedPostList, { id: number }>({
      queryKey: props.listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: context.data.filter((post) => post.id !== requestVariables?.id),
      }),
    })
  );

  /**
   * Update post
   */
  const { mutate: updatePost } = useSocialPostsPartialUpdate(
    optimisticMutationOptions<
      PostWrite,
      PaginatedPostList,
      {
        id: number;
        data: PostRequest;
      }
    >({
      queryKey: props.listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        return {
          meta: context.meta,
          data: context.data.map((post) =>
            post.id === requestVariables?.id
              ? { ...post, isDraft: requestVariables?.data.isDraft }
              : post
          ),
        };
      },
      onError: async (
        _?: PaginatedPostList,
        variables?: {
          id: number;
          data: PostRequest;
        },
        error?: AxiosError
      ) => {
        openToast({
          type: "danger",
          title: t("translation:common:error"),
          description: error?.message,
        });
        // We use `updatePost` mutation to update `isDraft=false` when post image is uploaded successfully
        // but `updatePost` mutation responses with error, need to delete the new Post
        if (variables?.id) {
          deletePost({ id: variables?.id });
        }
      },
      onSettled,
    })
  );

  /**
   * Create shoutout request
   */
  const { mutate: createShoutout } = useRecognitionShoutoutsCreate(
    optimisticMutationOptions<
      ShoutoutCreate,
      PaginatedPostList,
      {
        data: ShoutoutCreateRequest;
      }
    >({
      queryKey: props.listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        meta: context.meta,
        data: [
          {
            id: Math.random(),
            user: me as SimpleUserModel,
            commentsCount: 0,
            content: requestVariables?.data.message,
            action: "shouted out to",
            actionDetail: values?.find((value) => value.id === selectedValueId)
              ?.name,
            recipient: selectedRecipient,
            value: selectedValueId,
          },
          ...context.data,
        ],
      }),
      onMutate,
      onSuccess,
      onError,
      onSettled,
    })
  );

  /**
   * Form
   */
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      content: content || "",
      mentions: [],
    },
  });
  const setContentError = () => {
    form.setError(
      "content",
      { message: t("translation:toast:type_something") },
      { shouldFocus: true }
    );
  };
  const setUrlError = () => {
    form.setError(
      "url",
      { message: t("translation:toast:type_something") },
      { shouldFocus: true }
    );
  };

  const onSetFeature = (data: {
    previewImage?: Blob;
    previewImageUrl?: string;
    recipient?: SimpleUserModel;
    valueId?: number;
    url?: string;
  }) => {
    if (isImageEditorVisible) {
      if (data.previewImage) {
        onContextUpdate(PostModalContentItem.PreviewImage, data.previewImage);
        onContextUpdate(
          PostModalContentItem.PreviewImageUrl,
          data.previewImageUrl
        );
        hideImage();
      }
    }
    if (isShoutoutVisible) {
      if (data.recipient) {
        onContextUpdate(PostModalContentItem.SelectedRecipient, data.recipient);
        onContextUpdate(PostModalContentItem.SelectedValueId, data.valueId);
        hideShoutout();
      }
    }
    if (isUrlInputVisible) {
      if (!data.url) {
        setUrlError();
      } else {
        onContextUpdate(PostModalContentItem.SelectedURL, data.url);
        form.setValue("url", data.url);
        hideURL();
      }
    }
    if (editor) {
      setTimeout(() => {
        ReactEditor.focus(editor);
      }, 1000);
    }
  };

  const onSubmit = (formData: FormData) => {
    // All posts require content except for image posts
    if (!previewImage && !formData.content) {
      setContentError();
      return;
    }
    if (isChallengeComplete) {
      onContextUpdate(PostModalContentItem.Content, formData.content);
      onClose();
      return;
    }
    const data = {
      ...formData,
      mentions: formData.mentions.map((mention) => ({
        user: mention.user,
        contentOffset: mention.contentOffset,
        contentText: mention.contentText,
      })),
    };
    if (selectedRecipient && data.content) {
      const { content, ...rest } = data;
      createShoutout({
        data: {
          ...rest,
          message: content,
          team: TEAM_EVERYONE_ID,
          recipient: selectedRecipient.id as number,
          value: selectedValueId,
        },
      });
    } else if (previewImageUrl) {
      setTempPost(data);
      createPost({
        data: {
          ...data,
          team: TEAM_EVERYONE_ID,
          isDraft: true,
        },
      });
    } else if (selectedURL) {
      setTempPost(data);
      const url =
        selectedURL?.indexOf("http") === -1
          ? `https://${selectedURL}`
          : selectedURL;
      createPost({
        data: {
          ...data,
          team: TEAM_EVERYONE_ID,
          url,
        },
      });
    } else {
      delete data.url;
      setTempPost(data);
      createPost({
        data: { ...data, team: TEAM_EVERYONE_ID },
      });
    }
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  /**
   * Rich text editor
   */
  const [clearEditor, setClearEditor] = useState(false);

  const setPostValue = (data: FormData) => {
    form.setValue("content", data.content);
    form.setValue("mentions", data.mentions);
    setClearEditor(false);
  };

  const onEditClick = () => {
    if (selectedRecipient) {
      toggleShoutout();
    }
    if (selectedURL) {
      toggleUrl();
    }
    if (previewImageUrl) {
      toggleImage();
    }
  };
  const onDeleteClick = (fromCancel: boolean = false) => {
    if (!fromCancel) {
      reset();
      if (selectedURL) {
        form.setValue("url", undefined);
      }
    }
  };

  const menuItems: NavItemV3[] = [
    {
      name: t("translation:common:edit"),
      icon: PencilSquareIcon,
      onClick: onEditClick,
    },
    {
      name: t("translation:common:remove"),
      icon: XCircleIcon,
      onClick: () => onDeleteClick(false),
    },
  ];

  return {
    isOpen,

    me,
    isLoading: isLoadingMe || (Boolean(userId !== null) && isLoadingUser),
    form,
    onHandleSubmit,

    isShoutoutVisible,
    toggleShoutout,

    isImageEditorVisible,
    toggleImage,

    isUrlInputVisible,
    toggleUrl,

    clearEditor,
    setPostValue,

    values,
    selectedRecipient,
    selectedValueId,
    selectedValueLabel,

    selectedURL,

    previewImageUrl,

    isCreating: isCreating || isLoadingPostImage,

    onClose,
    onCancel,
    menuItems,
    defaultPostValue,
    isChallengeComplete,
    onSetFeature,
    setEditor,
  };
};
