/**
 *
 *
 * useHome
 *
 *
 */
import { useMe } from "../../hooks/useMe";

export const useHome = () => {
  const { me } = useMe();
  return {
    me,
  };
};
