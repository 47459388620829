/**
 *
 *
 * Sentry
 *
 *
 */
import * as Sentry from "@sentry/react";

//
const { VITE_SENTRY_DSN, VITE_SENTRY_ENV } = import.meta.env;

export const initSentry = () => {
  if (VITE_SENTRY_DSN && VITE_SENTRY_ENV) {
    Sentry.init({
      environment: VITE_SENTRY_ENV,
      dsn: VITE_SENTRY_DSN,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};
