/**
 *
 *
 * <ContactInfoForm />
 *
 *
 */
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { FormHeader } from "../../components/FormHeader";
import { LinkedInUrlForm } from "../LinkedInUrlFormV3";
import { PhoneNumberList } from "../PhoneNumberListV3";
import { useContactInfoForm } from "./useContactInfoForm";

export const ContactInfoForm = (
  props: ReturnType<typeof useContactInfoForm>
) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "rounded-md text-sm",
        "grow overflow-hidden bg-zinc-100 p-4 sm:mr-40"
      )}
    >
      <div className="flex">
        <h2 className="grow text-2xl font-light tracking-tight text-zinc-900">
          {t("translation:user_guide:edit_profile")}
        </h2>

        <Button variant="outlined" onClick={props.onCancel} size="md">
          <div className="hidden sm:block">{t("translation:common:close")}</div>
          <XMarkIcon
            className={clsx(
              "h-5 w-5 text-zinc-500 group-hover/cancel-button:text-zinc-600"
            )}
          />
        </Button>
      </div>

      <div className="space-y-12 divide-y">
        <section className="-mt-6 space-y-2 pt-12">
          <FormHeader
            title={t("translation:common:email")}
            description={t("translation:user_guide:email_cannot_change")}
          />
          <div className="flex h-9 items-center rounded-lg border border-zinc-300 bg-zinc-50 px-3 text-sm font-normal text-zinc-500">
            {props.user?.email}
          </div>
        </section>
        <section className="space-y-2 pt-12">
          <FormHeader
            title={t("translation:user_guide:linkedin_url")}
            description={t("translation:user_guide:add_linkedin_url_detail")}
          />
          <LinkedInUrlForm
            userGuideId={props.user?.userGuideId}
            className="w-full"
          />
        </section>
        <section className="space-y-2 pt-12">
          <FormHeader
            title={t("translation:user_guide:phone_numbers")}
            description={t(
              "translation:user_guide:add_new_phone_number_detail"
            )}
          />
          <PhoneNumberList userId={props.userId} className="w-full" />
        </section>
        <div className="flex border-t border-zinc-300 pb-2 pt-6 sm:justify-end">
          <Button
            variant="outlined"
            onClick={props.onCancel}
            size="md"
            className="w-full sm:w-auto"
          >
            {t("translation:common:close")}
            <XMarkIcon
              className={clsx(
                "h-5 w-5 text-zinc-500 group-hover/cancel-button:text-zinc-600"
              )}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
