/**
 *
 *
 * <SelectUser />
 *
 *
 */
import { Combobox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";

import { LoadingStar } from "../../components/LoadingStar";
import useDebounceValue from "../../hooks/useDebounceValue";
import { usePeopleList } from "../../services/teambuilder/endpoints/people/people";
import { _PeopleResponse } from "../../services/teambuilder/schemas";

interface Props {
  setValue: (user: _PeopleResponse) => void;
  placeholder?: string;
  excludeIds?: number[];
}

export const SelectUser = ({
  setValue,
  placeholder = "Search",
  excludeIds = [],
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounceValue(searchTerm, 500);

  const { data: users, isLoading } = usePeopleList(
    {
      limit: 10,
      offset: 0,
      search: debouncedSearchTerm,
      ordering: "first_name",
    },
    {
      query: {
        select: ({ data }) =>
          data.filter((user) => !excludeIds.includes(user.id as number)),
      },
    }
  );

  return (
    <>
      <Combobox
        name="user"
        by="id"
        defaultValue={{
          roles: [],
        }}
        onChange={(user: _PeopleResponse) => {
          setValue(user);
        }}
      >
        <Combobox.Label className="sr-only">User Search</Combobox.Label>
        <div className="relative">
          <Combobox.Input
            className={clsx(
              "block h-9 w-full rounded border border-zinc-300 px-2.5 py-1.5 pr-10 sm:h-10 sm:py-2.5",
              "text-zinc-900 placeholder-zinc-500 focus:border-zinc-300",
              "focus:outline-none focus:ring-zinc-200 sm:text-sm"
            )}
            displayValue={(user: _PeopleResponse) =>
              `${user?.firstName || ""} ${user?.lastName || ""}`
            }
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={placeholder}
          />
          <MagnifyingGlassIcon className="absolute right-2 top-2.5 h-5 w-5 text-zinc-400" />
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Combobox.Options
              className={clsx(
                "absolute z-40 mt-1 max-h-56",
                "w-full overflow-auto rounded-md bg-white",
                "border border-zinc-300 text-sm shadow-lg focus:outline-none"
              )}
            >
              {isLoading ? (
                <li className="flex justify-center p-2">
                  <LoadingStar />
                </li>
              ) : users?.length ? (
                users.map((user) => (
                  <Combobox.Option
                    key={user.id}
                    value={user}
                    className={clsx(
                      "cursor-pointer bg-white p-2 text-zinc-900 ui-active:bg-zinc-700 ui-active:text-white"
                    )}
                  >
                    {user.firstName || ""} {user.lastName || ""}{" "}
                    <span className="ml-3 text-zinc-900 ui-active:text-white">
                      ({user.email})
                    </span>
                  </Combobox.Option>
                ))
              ) : (
                <li className="p-2 text-zinc-500">
                  🤔 Hm, no person found by that name. Ask your HR person to add
                  them in People.
                </li>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </>
  );
};
