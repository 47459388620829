import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import * as React from "react";

import { useCarousel } from "./hook";

interface Props {
  className?: string;
}

const CarouselPrevious = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <button
        ref={ref}
        className={clsx(
          orientation === "vertical" && "rotate-90",
          "flex-shrink-0 hover:opacity-60",
          className
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <ChevronLeftIcon className="h-8 w-8" />
        <span className="sr-only">Previous slide</span>
      </button>
    );
  }
);
CarouselPrevious.displayName = "CarouselPrevious";

export { CarouselPrevious };
