/**
 *
 *
 * useApp
 *
 *
 */
import { IBrowserSettings } from "@splitsoftware/splitio/types/splitio";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { usePageContext } from "../../components/PageProvider";
import { useLocalStorageSettings } from "../../hooks/useLocalStorageSettings";
import { useMe } from "../../hooks/useMe";
import { useToast } from "../../hooks/useToast";
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";
import { setupHotJar } from "../../utils/hotjar";
import { identify, peopleSet, trackPageView } from "../../utils/mixpanel";
import { getSplitConfig } from "../../utils/split";

export const useApp = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { settings, setSettings } = useLocalStorageSettings();
  const [splitConfig, setSplitConfig] =
    useState<IBrowserSettings>(getSplitConfig());
  const { pageContext } = usePageContext();

  const { me, error: isMeError } = useMe();

  /**
   * me onSuccess
   */
  useEffect(() => {
    if (me) {
      setupHotJar({
        userId: me.id || 0,
        fullName: `${me.firstName} ${me.lastName}`,
      });

      // Open notifications if this is the first time the user has logged in
      // with this device.
      if (settings.isFirstTime) {
        setSettings({ ...settings, isFirstTime: false });
        setSearchParams({ ...Object.fromEntries(searchParams), tour: "" });
      }

      // identify user
      if (me.email) {
        identify(me.email);
        if (me.dateJoined) {
          peopleSet({
            "Date Joined": me.dateJoined,
            Name: `${me.firstName} ${me.lastName}`,
            Email: me.email,
          });
        }
      }
    }
  }, [me]);

  useEffect(() => {
    trackPageView();
  }, [location]);

  const { data: company } = useIntuitionCustomersCompaniesMeRetrieve({
    query: { enabled: Boolean(me) },
  });

  /**
   * company onSuccess
   */
  useEffect(() => {
    if (company) {
      // Add company ID to split config to enable company-level splits.
      setSplitConfig(getSplitConfig("company", company?.id));
    }
  }, [company]);

  const toast = useToast();

  return {
    me,
    isMeError,
    splitConfig,
    pageContext,
    toast,
  };
};
