/**
 *
 *
 * usePhoneNumberForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { PhoneNumberWithKey } from "containers/PhoneNumberList/usePhoneNumberList";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  useUserguidesPhonesCreate,
  useUserguidesPhonesDestroy,
  useUserguidesPhonesPartialUpdate,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import { PhoneNumberRequestLabel } from "../../services/teambuilder/schemas";

export const schema = z.object({
  label: z.string(),
  phoneNumber: z.string(),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  data: PhoneNumberWithKey;
  className?: string;
  onSaveSuccess: (key: number, data: PhoneNumberWithKey) => void;
  onDeleteSuccess: (key: number) => void;
}

export const usePhoneNumberForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  /**
   * Form
   */
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const label = form.watch("label");
  const phoneNumber = form.watch("phoneNumber");

  useEffect(() => {
    if (props.data) {
      form.reset({
        label: props.data.label,
        phoneNumber: props.data.phoneNumber,
      });
    }
  }, [props.data]);

  const { mutate: createUserGuidePhone, isLoading: isCreating } =
    useUserguidesPhonesCreate<AxiosError>({
      mutation: {
        onSuccess: async (data, requestVariable) => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:phonenumber_create_success"),
          });
          props.onSaveSuccess(props.data.key!, {
            ...data,
            label: requestVariable.data.label,
            phoneNumber: requestVariable.data.phoneNumber,
          });
        },
        onError: () => {
          form.control.setError("phoneNumber", {
            message: t("translation:toast:phonenumber_create_failed"),
          });
        },
        onSettled: () => {},
      },
    });

  const { mutate: saveUserGuidePhone, isLoading: isSaving } =
    useUserguidesPhonesPartialUpdate<AxiosError>({
      mutation: {
        onSuccess: async (data, requestVariable) => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:phonenumber_update_success"),
          });
          props.onSaveSuccess(props.data.key!, {
            ...data,
            ...requestVariable.data,
          });
        },
        onError: () => {
          form.control.setError("phoneNumber", {
            message: t("translation:toast:phonenumber_update_failed"),
          });
        },
        onSettled: () => {},
      },
    });

  const { mutate: deleteUserGuidePhone, isLoading: isDeleting } =
    useUserguidesPhonesDestroy<AxiosError>({
      mutation: {
        onSuccess: async () => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:phonenumber_delete_success"),
          });
          props.onDeleteSuccess(props.data.key!);
        },
        onError: () => {
          form.control.setError("phoneNumber", {
            message: t("translation:toast:phonenumber_delete_failed"),
          });
        },
        onSettled: () => {},
      },
    });

  const onSavePhoneNumber: SubmitHandler<FormData> = (formData: FormData) => {
    const { id } = props.data;
    const { label, phoneNumber } = formData;
    if (formData) {
      if (id === undefined) {
        createUserGuidePhone({
          data: {
            label: label.toUpperCase() as PhoneNumberRequestLabel,
            phoneNumber: phoneNumber,
          },
        });
      } else {
        saveUserGuidePhone({
          id,
          data: {
            label: label.toUpperCase() as PhoneNumberRequestLabel,
            phoneNumber: phoneNumber,
          },
        });
      }
    }
  };

  const onDeletePhoneNumber = async () => {
    if (Number.isInteger(props.data.id)) {
      deleteUserGuidePhone({
        id: props.data.id!,
      });
    } else {
      props.onDeleteSuccess(props.data.key!);
    }
  };

  const onHandleSubmit = form.handleSubmit(onSavePhoneNumber);

  return {
    data: props.data,
    label,
    phoneNumber,
    form,
    isLoading: false,
    className: props.className,
    onHandleSubmit,
    onDeletePhoneNumber,
    isCreating,
    isDeleting,
    isSaving,
  };
};
