/**
 *
 *
 * <EmptyDepartments />
 *
 *
 */
import { BuildingOfficeIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";

interface Props {
  onAddClick: () => void;
}

export const EmptyDepartments = ({ onAddClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "my-6 flex flex-col justify-center text-center",
        "rounded-md border-4 border-dashed border-zinc-300 p-6"
      )}
      data-testid="no-departments"
    >
      <div className="mb-2">
        <BuildingOfficeIcon className="mx-auto h-12 w-12 text-zinc-400" />
      </div>

      <h3 className="mb-1 text-sm font-semibold text-zinc-900">
        {t("translation:settings:departments:no_departments")}
      </h3>

      <p className="mb-6 text-sm text-zinc-500">
        {t("translation:settings:departments:create_new_departments")}
      </p>
      <div className="flex justify-center">
        <Button
          type="button"
          variant="contained"
          onClick={onAddClick}
          size="md"
        >
          <PlusIcon className="h-5 w-5" />{" "}
          {t("translation:settings:departments:add_department")}
        </Button>
      </div>
    </div>
  );
};
