/**
 *
 *
 * useDomainList
 *
 *
 */
import {
  AllowedDomainModel,
  PaginatedAllowedDomainModelList,
} from "services/teambuilder/schemas";

import { queryClient } from "../../query-client";
import {
  getIntuitionCustomersDomainsListQueryKey,
  useIntuitionCustomersDomainsList,
} from "../../services/teambuilder/endpoints/intuition/intuition";

export interface DomainWithPosition extends AllowedDomainModel {
  position?: number;
}

interface Props {
  className?: string;
}

export const useDomainList = (props: Props) => {
  const params = {
    limit: 999,
    ordering: "createdAt",
  };
  const domainsQueryKey = getIntuitionCustomersDomainsListQueryKey(params);

  const { data: domains, isLoading: isLoadingDomains } =
    useIntuitionCustomersDomainsList(params, {
      query: {
        select: ({ data }) => data,
      },
    });

  /**
   * Add a domain form to the list by adding an unsaved domain to the cache.
   */
  const addUnsavedDomain = () => {
    queryClient.setQueryData(
      domainsQueryKey,
      (oldData: PaginatedAllowedDomainModelList | undefined) => {
        if (!oldData) return;
        return {
          meta: oldData.meta,
          data: [
            // TODO: ideally, domain list results would be sorted DESC by createdAt,
            // but currently new domains are added to the top of the list.
            // So I just gave up and put the Add button at the top.
            {
              id: Math.random(),
              createdAt: "",
              updatedAt: "",
              domain: "",
              company: 0,
            },
            ...oldData.data,
          ],
        };
      }
    );
  };

  return {
    domains,
    isLoading: isLoadingDomains,
    className: props.className,
    domainsQueryKey,
    addUnsavedDomain,
  };
};
