/**
 *
 *
 * useIntegrations
 *
 *
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import { useIntegrationsChannelsList } from "../../services/teambuilder/endpoints/integrations/integrations";
import {
  getTeamsRetrieveQueryKey,
  useTeamsPartialUpdate,
} from "../../services/teambuilder/endpoints/teams/teams";
import {
  Channels,
  PatchedTeamModelRequest,
  TeamModel,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { IntegrationItem } from "./types";

type Source = "Slack" | "Teams";

interface Channel extends Channels {
  source: Source;
}

interface Props {
  integration: IntegrationItem;
  className?: string;
  onButtonClick?: (index: number, id: number) => void;
  isLoading?: boolean;
  index: number;
  channelId: string | null;
}

export const useIntegration = ({
  integration,
  className,
  onButtonClick,
  isLoading,
  index,
  channelId,
}: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const [searchTerm, setSearchTerm] = useState<string>("");
  /**
   *
   * Fetch channels from slack integration
   *
   **/
  const integrationId =
    integration && integration.connected ? Number(integration.id) : 0;
  const { data: channels = [], isInitialLoading: isLoadingChannels } =
    useIntegrationsChannelsList<Channel[]>(
      integrationId,
      {
        limit: 999,
      },
      {
        query: {
          enabled: integrationId !== 0,
          select: ({ data }) => {
            return data
              .sort((a, b) =>
                String(a?.channelName).localeCompare(String(b.channelName))
              )
              .map((ch) => ({ ...ch, source: integration.name as Source }));
          },
          onError: () => {
            openToast({
              title: t("translation:toast:slack_channel_load_failed"),
              type: "danger",
            });
          },
        },
      }
    );

  /**
   *
   * Update channel
   *
   */
  const teamId = TEAM_EVERYONE_ID;
  const teamQueryKey = getTeamsRetrieveQueryKey(teamId);

  const { mutate: updateTeam } = useTeamsPartialUpdate(
    optimisticMutationOptions<
      TeamModel,
      TeamModel,
      { id: number; data: PatchedTeamModelRequest }
    >({
      queryKey: teamQueryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        ...context,
        ...requestVariables,
      }),
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:channel_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:channel_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const updateChannel = (channelId: string | null) =>
    updateTeam({ id: teamId, data: { channelId } });

  const [filteredChannels, setFilteredChannels] = useState<Channel[]>([]);
  useEffect(() => {
    if (channels.length === 0) return;
    if (searchTerm.trim() === "") {
      setFilteredChannels(channels);
      return;
    }
    setFilteredChannels(
      channels.filter((channel) => channel.channelName.indexOf(searchTerm) > -1)
    );
  }, [channels, searchTerm]);

  return {
    integration,
    className,
    onButtonClick,
    isLoading: isLoading || isLoadingChannels,
    index,
    channelId,
    filteredChannels: filteredChannels.filter(
      (channel, index) =>
        filteredChannels.findIndex(
          (channel1) => channel1.channelId === channel.channelId
        ) === index
    ),
    searchTerm,
    setSearchTerm,
    updateChannel,
  };
};
