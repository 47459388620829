/**
 *
 *
 * useUpcomingBirthdays
 *
 *
 */
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import { useToast } from "../../hooks/useToast";
import { useUserguidesList } from "../../services/teambuilder/endpoints/userguides/userguides";
import { UserguidesListOrdering } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";

const ROW_LIMIT = 20;

export const useUpcomingBirthdays = () => {
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams();

  const openProfileModal = (id: number) => {
    if (searchParams.has(SearchParam.PROFILE)) {
      searchParams.set(SearchParam.PROFILE, id.toString());
    } else {
      searchParams.append(SearchParam.PROFILE, id.toString());
    }
    setSearchParams(searchParams);
  };

  const userGuidesListRequestParams = useMemo(
    () => ({
      limit: ROW_LIMIT,
      offset: 0,
      ordering: UserguidesListOrdering.birth_date,
      birth_date__lte: dayjs().add(1, "month").format("YYYY-MM-DD"),
    }),
    []
  );

  const {
    data: response,
    refetch: refetchPeople,
    isLoading: isLoadingMembers,
    isError: isMembersError,
  } = useUserguidesList(userGuidesListRequestParams);

  const onPost = (userId: number) => {
    if (searchParams.has(SearchParam.SHOW_POST_MODAL)) {
      searchParams.set(SearchParam.SHOW_POST_MODAL, "1");
    } else {
      searchParams.append(SearchParam.SHOW_POST_MODAL, "1");
    }
    if (searchParams.has(SearchParam.BIRTHDAY_USER_ID)) {
      searchParams.set(SearchParam.BIRTHDAY_USER_ID, userId.toString());
    } else {
      searchParams.append(SearchParam.BIRTHDAY_USER_ID, userId.toString());
    }
    setSearchParams(searchParams);
  };

  return {
    members: response,
    toast,
    isLoadingMembers,
    isMembersError,
    refetchPeople,
    ROW_LIMIT,
    openProfileModal,
    onPost,
  };
};
