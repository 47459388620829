/**
 *
 *
 * parseRoles
 *
 *
 */
import { Role } from "../services/teambuilder/schemas";

export interface RoleStatus {
  isManager: boolean;
  isHR: boolean;
  isIT: boolean;
  isAdmin: boolean;
}

export const parseRoles = (roles: Role[]): RoleStatus =>
  roles.reduce(
    (acc: RoleStatus, role: Role) => {
      switch (role.name) {
        case "Manager":
          return {
            ...acc,
            isManager: true,
            isAdmin: true,
          };
        case "HR":
          return {
            ...acc,
            isHR: true,
            isAdmin: true,
          };
        case "IT":
          return {
            ...acc,
            isIT: true,
            isAdmin: true,
          };
        default:
          return acc;
      }
    },
    {
      isManager: false,
      isHR: false,
      isIT: false,
      isAdmin: false,
    }
  );
