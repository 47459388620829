/**
 *
 *
 * CompanyName
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { CompanyModel } from "../../services/teambuilder/schemas";

interface Props {
  company: CompanyModel | undefined;
  isLoadingCompany: boolean;
}

export const CompanyName = (props: Props) => {
  return (
    <Link
      reloadDocument
      to="/"
      className={clsx(
        "h-10 rounded-md",
        "flex flex-shrink-0 grow items-center justify-center"
      )}
    >
      {props.isLoadingCompany ? (
        <div className="h-7 w-36 animate-pulse rounded-md bg-zinc-400" />
      ) : props.company?.logo ? (
        <img
          src={props.company?.logo.medium}
          alt="logo"
          className="h-full max-w-full"
        />
      ) : (
        <div
          className={clsx(
            "text-base font-bold tracking-tighter",
            "text-zinc-900",
            "truncate"
          )}
        >
          {props.company?.name}
        </div>
      )}
    </Link>
  );
};
