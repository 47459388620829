/**
 *
 *
 * <ModalConfirmation />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import React, { useRef } from "react";

import { classNames } from "../../utils";
import { Button } from "../Button";
import { LoadingStar } from "../LoadingStar";
import { Modal } from "../Modal";

export type Variant = "success" | "danger" | "warn";

export interface Props {
  isOpen: boolean;
  title: string;
  description: React.ReactNode;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
  variant?: Variant;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const ModalConfirmation = (props: Props) => {
  const variant = props.variant || "success";
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onCancel}
      ref={cancelButtonRef}
      showCloseButton={false}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div className="sm:flex sm:items-start">
          <div
            className={classNames(
              "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full p-1  sm:mx-0 sm:h-10 sm:w-10",
              {
                success: "bg-green-100",
                danger: "bg-red-100",
                warn: "bg-amber-100",
              }[variant]
            )}
          >
            {
              {
                success: (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                ),
                danger: (
                  <ExclamationTriangleIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                ),
                warn: (
                  <ExclamationTriangleIcon
                    className="h-6 w-6 text-amber-500"
                    aria-hidden="true"
                  />
                ),
              }[variant]
            }
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-slate-700"
            >
              {props.title}
            </Dialog.Title>

            <div className="mt-2">
              <div className="text-sm text-slate-500">{props.description}</div>
            </div>
          </div>
        </div>

        <div className="mt-5 flex flex-row-reverse justify-center gap-3 sm:mt-4 sm:justify-start">
          <Button
            variant={variant}
            size="sm"
            onClick={props.onConfirm}
            disabled={props.isLoading}
            dark
          >
            {props.confirmButtonTitle}
            {props.isLoading && <LoadingStar />}
          </Button>

          <Button
            variant="outlined"
            size="sm"
            onClick={props.onCancel}
            ref={cancelButtonRef}
            disabled={props.isLoading}
            dark
          >
            {props.cancelButtonTitle}
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
