/**
 *
 *
 * useAddActivityModal
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { SearchParam } from "../../consts";
import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import {
  useChallengesActivitiesCreate,
  useChallengesActivitiesPartialUpdate,
  useChallengesActivitiesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import {
  ActivityProofType,
  ActivityRequestProofType,
} from "../../services/teambuilder/schemas";

export const schema = z
  .object({
    name: z
      .string()
      .max(254, { message: "translation:validation:less_than_250" })
      .min(1, { message: "translation:validation:required_name" }),
    description: z.string().optional(),
    requireProof: z.boolean().nullable(),
    proofType: z.string().nullable(),
  })
  .refine(
    (data) => (data.requireProof && !!data.proofType) || !data.requireProof,
    {
      message: "translation:validation:proof_type_required",
      path: ["proofType"],
    }
  );

export type FormData = z.infer<typeof schema>;

type Props = {
  queryKey: readonly unknown[];
};

export const useAddActivityModal = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdd = searchParams.has(SearchParam.ADD_ACTIVITY);
  const isEdit = searchParams.has(SearchParam.EDIT_ACTIVITY);
  const isOpen = isAdd || isEdit;

  const challengeId = Number(searchParams.get(SearchParam.CHALLENGE));
  const activityId = Number(searchParams.get(SearchParam.EDIT_ACTIVITY));

  /**
   * Fetch activity
   */
  const { data: activity, isInitialLoading: isLoading } =
    useChallengesActivitiesRetrieve(challengeId, activityId, {
      query: {
        enabled: challengeId > 0 && activityId > 0,
      },
    });

  /**
   * Form
   */
  const defaultValues = useMemo(() => {
    return {
      name: activity?.name,
      description: activity?.description,
      requireProof:
        !!activity?.proofType &&
        activity?.proofType !== ActivityProofType.not_required,
      proofType: activity?.proofType,
    };
  }, [activity]);

  const { handleSubmit, reset, setValue, ...form } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    setValue("name", defaultValues.name || "");
    setValue("description", defaultValues.description || "");
    setValue("requireProof", defaultValues.requireProof);
    setValue("proofType", defaultValues.proofType || "");
  }, [defaultValues]);

  const isRequireProof = useWatch({
    control: form.control,
    name: "requireProof",
  });

  /**
   * Add activity
   * @param data
   */
  const { mutate: createActivity, isLoading: isCreating } =
    useChallengesActivitiesCreate({
      mutation: {
        onSuccess: () => {
          openToast({ title: t("translation:toast:activity_create_success") });
          onCancel();
        },
        onError: () => {
          openToast({
            title: t("translation:toast:activity_create_failed"),
            type: "danger",
          });
        },
        onSettled: () => queryClient.invalidateQueries(props.queryKey),
      },
    });

  /**
   * Edit activity
   * @param data
   */
  const { mutate: updateActivity, isLoading: isUpdating } =
    useChallengesActivitiesPartialUpdate({
      mutation: {
        onSuccess: () => {
          openToast({ title: t("translation:toast:activity_update_success") });
          onCancel();
        },
        onError: () => {
          openToast({
            title: t("translation:toast:activity_update_failed"),
            type: "danger",
          });
        },
        onSettled: () => queryClient.invalidateQueries(props.queryKey),
      },
    });

  /**
   * Submit
   */
  const onSubmit = (data: FormData) => {
    if (activityId > 0) {
      updateActivity({
        challengesPk: challengeId,
        id: activityId,
        data: {
          challenge: challengeId,
          name: data.name,
          description: data.description,
          proofType: data.requireProof
            ? (data.proofType! as ActivityRequestProofType)
            : ActivityRequestProofType.not_required,
        },
      });
    } else {
      createActivity({
        challengesPk: challengeId,
        data: {
          challenge: challengeId,
          name: data.name,
          description: data.description,
          proofType: data.requireProof
            ? (data.proofType! as ActivityRequestProofType)
            : ActivityRequestProofType.not_required,
        },
      });
    }
  };

  const onCreate = handleSubmit(onSubmit);

  const onCancel = () => {
    searchParams.delete(SearchParam.ADD_ACTIVITY);
    searchParams.delete(SearchParam.EDIT_ACTIVITY);
    setSearchParams(searchParams);
  };

  return {
    isOpen,

    form,
    isRequireProof,

    isEdit,

    onCreate,
    onCancel,

    isCreating,
    isLoading,
    isUpdating,
  };
};
