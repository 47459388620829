/**
 *
 *
 * <ChallengesEmpty />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

export const ChallengesEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className="animate-crossfadein bg-zinc-200 p-8">
      <p className="text-sm font-normal leading-5 text-zinc-500">
        {t("translation:challenge:no_challenges_member")}
      </p>
    </div>
  );
};
