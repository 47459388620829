/**
 *
 *
 * <Badges />
 *
 *
 */
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/Carousel";
import { useBadges } from "./useBadges";

export const Badges = (props: ReturnType<typeof useBadges>) => {
  const { t } = useTranslation();

  if (!props.badges || props.badges.meta.pagination.total === 0) return null;

  return (
    <div className="space-y-6 py-8">
      <div className="mb-8 px-12">
        <p className="mb-0.5 text-lg font-bold leading-7 text-zinc-900">
          {t("translation:user_guide:badge_collection")}
        </p>
        <p className="text-sm font-normal leading-5 text-zinc-500">
          <Trans
            i18nKey="translation:user_guide:badge_collections_description"
            components={{
              a: <Link to="/challenges" className="font-medium underline" />,
              b: <Link to="/?profile=me" className="font-medium underline" />,
            }}
          />
        </p>
      </div>
      <Carousel className="w-full">
        <CarouselPrevious className="flex w-8 items-center justify-center bg-zinc-100" />
        <CarouselContent className="w-full items-center gap-x-6 py-4">
          {props.isLoading
            ? [...Array(3).keys()].map((i) => (
                <CarouselItem
                  key={i}
                  className="h-[23rem] w-60 animate-pulse rounded-md bg-zinc-200"
                />
              ))
            : props.badges?.data?.map((badge) => {
                return (
                  <CarouselItem
                    key={badge.id}
                    className={clsx(
                      "flex w-64 flex-shrink-0 flex-col items-center gap-y-2",
                      "h-full overflow-hidden rounded-md bg-white p-4 shadow-md"
                    )}
                  >
                    <Link
                      to={`/challenges/${badge.badge.objectId}`}
                      className="group/carousel-item flex flex-col items-center"
                    >
                      <img
                        src={badge.badge.image.large}
                        alt="Badge image"
                        className="mb-4 w-56"
                      />
                      <p
                        className={clsx(
                          "mb-1 group-hover/carousel-item:underline",
                          "text-center text-sm font-medium text-zinc-900",
                          "w-56 truncate whitespace-nowrap"
                        )}
                      >
                        {badge.badge.name}
                      </p>
                      <p className="text-center text-sm text-zinc-500">
                        {dayjs(badge.createdAt).format("MMM D, YYYY")}
                      </p>
                    </Link>
                  </CarouselItem>
                );
              })}
        </CarouselContent>
        <CarouselNext className="flex w-8 items-center justify-center bg-zinc-100" />
      </Carousel>
    </div>
  );
};
