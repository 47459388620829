/**
 *
 *
 * useHomeChallenges
 *
 *
 */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { usePageContext } from "../../components/PageProvider";
import { SearchParam } from "../../consts";
import { useCreateChallenge } from "../../hooks/useCreateChallenge";
import { useMe } from "../../hooks/useMe";
import { useRoles } from "../../hooks/useRoles";
import {
  useChallengesCountRetrieve,
  useChallengesList,
  useChallengesMeList,
  useChallengesTemplatesList,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import { Challenge } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { TabKey } from "./types";

const SHOW_TEMPLATES = 6;

export type HomeChallengeSectionKey = TabKey | "no_challenge";

interface Props {
  className?: string;
  isHome?: boolean;
}

export const useHomeChallenge = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { challengeId: _challengeId } = useParams();
  const { isAdmin } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { onCreateDraftChallenge } = useCreateChallenge();

  const { me, loading } = useMe();
  const [section, setSection] = useState<HomeChallengeSectionKey>();

  const { setPageContext } = usePageContext();
  useEffect(() => {
    setPageContext({
      title: t("translation:challenge:challenges"),
      subtitle: t("translation:challenge:browse_challenges"),
      learnMoreUrl: "https://intuition.click/teambuilder-challenges-hc",
    });
    // Adding `setPageContext` causes "Maximum update depth exceeded" error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, t]);

  const { data: challengesCount, isLoading: isLoadingCount } =
    useChallengesCountRetrieve();

  useEffect(() => {
    if (challengesCount) {
      if (challengesCount.current && challengesCount.current > 0) {
        setSection("current");
        return;
      }
      if (challengesCount.upcoming && challengesCount.upcoming > 0) {
        setSection("upcoming");
        return;
      }
      if (isAdmin) {
        setSection("templates");
        return;
      }
      setSection("no_challenge");
    }
  }, [challengesCount, isAdmin]);

  /**
   * current challenges
   */
  const { data: currentChallenges, isFetching: isLoadingChallengesMe } =
    useChallengesMeList<Challenge[]>(
      {
        limit: 999,
        status: "running",
        is_draft: false,
      },
      {
        query: {
          enabled: section === "current" && !isLoadingCount,
          select: ({ data }) => data,
          staleTime: 30000,
        },
      }
    );

  const now = useMemo(() => dayjs().toISOString(), []);
  /**
   * upcoming challenges
   */
  const { data: upcomingChallenges, isFetching: isLoadingUpcomingChallenges } =
    useChallengesList<Challenge[]>(
      {
        start_at__gte: now,
        ordering: "start_at",
        limit: 999,
        is_draft: false,
      },
      {
        query: {
          enabled: section === "upcoming" && !isLoadingCount,
          select: ({ data }) => data,
          staleTime: 30000,
        },
      }
    );

  useEffect(() => {
    if (
      section === "current" &&
      !isLoadingChallengesMe &&
      currentChallenges &&
      currentChallenges.length === 0
    ) {
      setSection("upcoming");
      return;
    }
    if (
      section === "upcoming" &&
      !isLoadingUpcomingChallenges &&
      upcomingChallenges &&
      upcomingChallenges.length === 0
    ) {
      if (isAdmin) {
        setSection("templates");
        return;
      }
      setSection("no_challenge");
      return;
    }
  }, [
    isAdmin,
    section,
    currentChallenges,
    isLoadingChallengesMe,
    upcomingChallenges,
    isLoadingUpcomingChallenges,
  ]);

  /**
   * template challenges
   */
  const { data: templates, isInitialLoading: isLoadingTemplates } =
    useChallengesTemplatesList(
      {
        limit: SHOW_TEMPLATES,
      },
      {
        query: {
          enabled: section === "templates" && !isLoadingCount,
          select: ({ data }) => data,
        },
      }
    );

  const onTemplatePreview = (id: number) => {
    if (searchParams.has(SearchParam.TEMPLATE_PREVIEW)) {
      searchParams.set(SearchParam.TEMPLATE_PREVIEW, id.toString());
    } else {
      searchParams.append(SearchParam.TEMPLATE_PREVIEW, id.toString());
    }
    setSearchParams(searchParams);
  };

  return {
    me,
    isAdmin,
    section,
    challenges:
      section === "current"
        ? currentChallenges
        : section === "upcoming"
          ? upcomingChallenges
          : section === "templates"
            ? templates
            : [],
    challengeId: _challengeId,
    isEmpty: !isLoadingCount && section === "no_challenge",
    isLoading:
      loading ||
      isLoadingUpcomingChallenges ||
      isLoadingTemplates ||
      isLoadingChallengesMe ||
      isLoadingCount,
    onTemplatePreview,
    onSelectTemplate: onCreateDraftChallenge,
    className: props.className,
  };
};
