/**
 *
 *
 * <Card />
 *
 *
 */
import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { QuestionModel } from "../../services/teambuilder/schemas";
import { NavItemV3 } from "../../types";
import { DropdownMenu } from "../DropdownMenuV3";

interface Props {
  data: {
    id?: number;
    name: string;
    description?: string;
  };
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  scrollTo?: boolean;
  dataTestID?: string;
  icon?: React.ReactNode;
}

export const SettingsCard = ({
  data,
  icon,
  onEditClick,
  onDeleteClick,
  scrollTo,
  dataTestID,
}: Props) => {
  const { t } = useTranslation();
  // If isLast is true, scroll to this item on mount
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (scrollTo) ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const menuItems: NavItemV3[] = [
    ...(onEditClick
      ? [
          {
            name: t("translation:common:edit"),
            icon: PencilSquareIcon,
            onClick: onEditClick,
          },
        ]
      : []),
    ...(onDeleteClick
      ? [
          {
            name: t("translation:common:delete"),
            icon: TrashIcon,
            onClick: onDeleteClick,
          },
        ]
      : []),
  ];

  return (
    <li
      ref={ref}
      className="flex items-stretch justify-start gap-x-6 rounded-md border border-zinc-300 bg-zinc-100 px-3 py-2"
      data-testid={dataTestID}
    >
      <div className="flex flex-grow items-center gap-x-2 text-sm">
        {icon && icon}

        <div>
          <h2 className="flex items-center text-sm font-normal text-zinc-900">
            {data.name}
          </h2>
        </div>
      </div>

      {(onDeleteClick || onEditClick) && (
        <DropdownMenu
          items={menuItems}
          menuButtonContent={(open) => (
            <EllipsisHorizontalIcon
              className={clsx(
                "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                open ? "bg-zinc-200 text-zinc-600" : "bg-zinc-100 text-zinc-500"
              )}
            />
          )}
          menuClassNames={clsx("right-0 mt-5 origin-top-right")}
        />
      )}
    </li>
  );
};
