/**
 *
 *
 * <TimezoneSelect />
 *
 *
 */
import { Combobox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Spinner } from "../../components/Spinner";
import { useToast } from "../../hooks/useToast";
import { useIntuitionCustomersTimezonesRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";

interface Props {
  setValue: (timezone: string | null) => void;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
}

export const TimezoneSelect = (props: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { openToast } = useToast();

  const { data: timezones, isLoading } = useIntuitionCustomersTimezonesRetrieve(
    {
      query: {
        select: ({ data }) => data,
        onError: () =>
          openToast({
            title: t("translation:toast:timezone_load_failed"),
            type: "danger",
          }),
      },
    }
  );

  const filteredTimezones = timezones?.filter((tz) =>
    tz.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Combobox
        name="timezone"
        defaultValue={props.defaultValue}
        onChange={(tz) => props.setValue(tz)}
        nullable
      >
        <div className={clsx("z-1 relative", props.className || "mt-2")}>
          <Combobox.Input
            className={clsx(
              "px-3 py-2",
              "block w-full rounded-md border-0",
              "ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-500",
              "focus:ring-2 focus:ring-inset focus:ring-zinc-300",
              "text-sm"
            )}
            placeholder={t("translation:common:start_typing_to_search")}
            displayValue={(timezone: string) => timezone}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={inputRef}
            id="timezone-select"
          />

          <button className="absolute right-2 top-2" type="button">
            <span className="sr-only">{t("translation:common:clear")}</span>
            <XMarkIcon
              onClick={() => {
                if (inputRef.current) inputRef.current.value = "";
                props.setValue("");
              }}
              className="h-5 w-5 text-zinc-500"
            />
          </button>
        </div>

        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options
            className={clsx(
              "absolute z-10 mt-1 max-h-56",
              "w-full overflow-y-auto rounded-md bg-white",
              "border border-zinc-300 text-sm text-zinc-900 shadow-lg focus:outline-none"
            )}
          >
            {isLoading ? (
              <li className="flex justify-center p-2">
                <Spinner />
              </li>
            ) : filteredTimezones?.length ? (
              filteredTimezones?.map((tz) => (
                <Combobox.Option
                  key={tz}
                  value={tz}
                  className={clsx(
                    "cursor-pointer p-2 text-zinc-900 ui-active:bg-zinc-100"
                  )}
                >
                  {tz}
                </Combobox.Option>
              ))
            ) : (
              <li className="p-2 text-zinc-500">
                🤔 {t("translation:common:no_timezone")}
              </li>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </>
  );
};
