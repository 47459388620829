/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  Comment,
  Mention,
  MentionCreate,
  MentionCreateRequest,
  PaginatedCommentList,
  PaginatedMentionList,
  PaginatedPostList,
  PaginatedReactionList,
  PaginatedRebuttalList,
  PaginatedReplyList,
  PatchedMentionCreateRequest,
  PatchedPostWriteRequest,
  PatchedReactionRequest,
  Post,
  PostImageUploadRequest,
  PostRequest,
  PostWrite,
  PostWriteRequest,
  Reaction,
  ReactionRequest,
  Rebuttal,
  Reply,
  SocialPostsCommentsListParams,
  SocialPostsCommentsMentionsListParams,
  SocialPostsCommentsReactionsListParams,
  SocialPostsCommentsRepliesListParams,
  SocialPostsCommentsRepliesMentionsListParams,
  SocialPostsCommentsRepliesReactionsListParams,
  SocialPostsCommentsRepliesRebuttalsListParams,
  SocialPostsCommentsRepliesRebuttalsMentionsListParams,
  SocialPostsCommentsRepliesRebuttalsReactionsListParams,
  SocialPostsListParams,
  SocialPostsMentionsListParams,
  SocialPostsReactionsListParams,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const socialIntranetRssRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    { url: `/api/v1/social/intranet/rss/`, method: "GET", signal },
    options
  );
};

export const getSocialIntranetRssRetrieveQueryKey = () => {
  return [`/api/v1/social/intranet/rss/`] as const;
};

export const getSocialIntranetRssRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialIntranetRssRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof socialIntranetRssRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSocialIntranetRssRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialIntranetRssRetrieve>>
  > = ({ signal }) => socialIntranetRssRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof socialIntranetRssRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialIntranetRssRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialIntranetRssRetrieve>>
>;
export type SocialIntranetRssRetrieveQueryError = ErrorType<unknown>;

export const useSocialIntranetRssRetrieve = <
  TData = Awaited<ReturnType<typeof socialIntranetRssRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof socialIntranetRssRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialIntranetRssRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsList = (
  params?: SocialPostsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPostList>(
    { url: `/api/v1/social/posts/`, method: "GET", params, signal },
    options
  );
};

export const getSocialPostsListQueryKey = (params?: SocialPostsListParams) => {
  return [`/api/v1/social/posts/`, ...(params ? [params] : [])] as const;
};

export const getSocialPostsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SocialPostsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSocialPostsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof socialPostsList>>> = ({
    signal,
  }) => socialPostsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsList>>
>;
export type SocialPostsListQueryError = ErrorType<unknown>;

export const useSocialPostsList = <
  TData = Awaited<ReturnType<typeof socialPostsList>>,
  TError = ErrorType<unknown>,
>(
  params?: SocialPostsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCreate = (
  postWriteRequest: PostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postWriteRequest,
    },
    options
  );
};

export const getSocialPostsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCreate>>,
    TError,
    { data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCreate>>,
  TError,
  { data: PostWriteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCreate>>,
    { data: PostWriteRequest }
  > = (props) => {
    const { data } = props ?? {};

    return socialPostsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCreate>>
>;
export type SocialPostsCreateMutationBody = PostWriteRequest;
export type SocialPostsCreateMutationError = ErrorType<unknown>;

export const useSocialPostsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCreate>>,
    TError,
    { data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Post>(
    { url: `/api/v1/social/posts/${id}/`, method: "GET", signal },
    options
  );
};

export const getSocialPostsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/social/posts/${id}/`] as const;
};

export const getSocialPostsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSocialPostsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsRetrieve>>
  > = ({ signal }) => socialPostsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsRetrieve>>
>;
export type SocialPostsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsPartialUpdate = (
  id: number,
  patchedPostWriteRequest: PatchedPostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPostWriteRequest,
    },
    options
  );
};

export const getSocialPostsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPartialUpdate>>,
    TError,
    { id: number; data: PatchedPostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsPartialUpdate>>,
  TError,
  { id: number; data: PatchedPostWriteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsPartialUpdate>>,
    { id: number; data: PatchedPostWriteRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return socialPostsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsPartialUpdate>>
>;
export type SocialPostsPartialUpdateMutationBody = PatchedPostWriteRequest;
export type SocialPostsPartialUpdateMutationError = ErrorType<unknown>;

export const useSocialPostsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPartialUpdate>>,
    TError,
    { id: number; data: PatchedPostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/social/posts/${id}/`, method: "DELETE" },
    options
  );
};

export const getSocialPostsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return socialPostsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsDestroy>>
>;

export type SocialPostsDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Approve content flagged as potentially inappropriate
 */
export const socialPostsApproveCreate = (
  id: number,
  postRequest: PostRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Post>(
    {
      url: `/api/v1/social/posts/${id}/approve/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postRequest,
    },
    options
  );
};

export const getSocialPostsApproveCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsApproveCreate>>,
    TError,
    { id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsApproveCreate>>,
  TError,
  { id: number; data: PostRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsApproveCreate>>,
    { id: number; data: PostRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return socialPostsApproveCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsApproveCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsApproveCreate>>
>;
export type SocialPostsApproveCreateMutationBody = PostRequest;
export type SocialPostsApproveCreateMutationError = ErrorType<unknown>;

export const useSocialPostsApproveCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsApproveCreate>>,
    TError,
    { id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsApproveCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsPostImageUploadCreate = (
  id: number,
  postImageUploadRequest: PostImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (postImageUploadRequest.file !== null) {
    formData.append("file", postImageUploadRequest.file);
  }

  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${id}/post_image_upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getSocialPostsPostImageUploadCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPostImageUploadCreate>>,
    TError,
    { id: number; data: PostImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsPostImageUploadCreate>>,
  TError,
  { id: number; data: PostImageUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsPostImageUploadCreate>>,
    { id: number; data: PostImageUploadRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return socialPostsPostImageUploadCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsPostImageUploadCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsPostImageUploadCreate>>
>;
export type SocialPostsPostImageUploadCreateMutationBody =
  PostImageUploadRequest;
export type SocialPostsPostImageUploadCreateMutationError = ErrorType<unknown>;

export const useSocialPostsPostImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPostImageUploadCreate>>,
    TError,
    { id: number; data: PostImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsPostImageUploadCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsPostImageUploadDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite | void>(
    { url: `/api/v1/social/posts/${id}/post_image_upload/`, method: "DELETE" },
    options
  );
};

export const getSocialPostsPostImageUploadDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPostImageUploadDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsPostImageUploadDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsPostImageUploadDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return socialPostsPostImageUploadDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsPostImageUploadDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsPostImageUploadDestroy>>
>;

export type SocialPostsPostImageUploadDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsPostImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsPostImageUploadDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsPostImageUploadDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsList = (
  postsPk: number,
  params?: SocialPostsCommentsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCommentList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsListQueryKey = (
  postsPk: number,
  params?: SocialPostsCommentsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsCommentsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsListQueryKey(postsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsList>>
  > = ({ signal }) =>
    socialPostsCommentsList(postsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsList>>
>;
export type SocialPostsCommentsListQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsCommentsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsListQueryOptions(
    postsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsCreate = (
  postsPk: number,
  postWriteRequest: PostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsCreate>>,
    TError,
    { postsPk: number; data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsCreate>>,
  TError,
  { postsPk: number; data: PostWriteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsCreate>>,
    { postsPk: number; data: PostWriteRequest }
  > = (props) => {
    const { postsPk, data } = props ?? {};

    return socialPostsCommentsCreate(postsPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsCreate>>
>;
export type SocialPostsCommentsCreateMutationBody = PostWriteRequest;
export type SocialPostsCommentsCreateMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsCreate>>,
    TError,
    { postsPk: number; data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsCommentsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsMentionsList = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsMentionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsMentionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsMentionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsMentionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsMentionsListQueryKey(postsPk, commentsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>
  > = ({ signal }) =>
    socialPostsCommentsMentionsList(
      postsPk,
      commentsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsMentionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>
>;
export type SocialPostsCommentsMentionsListQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsMentionsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsMentionsListQueryOptions(
    postsPk,
    commentsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsMentionsCreate = (
  postsPk: number,
  commentsPk: number,
  mentionCreateRequest: MentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsMentionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: MentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsCreate>>,
  TError,
  { postsPk: number; commentsPk: number; data: MentionCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsCreate>>,
    { postsPk: number; commentsPk: number; data: MentionCreateRequest }
  > = (props) => {
    const { postsPk, commentsPk, data } = props ?? {};

    return socialPostsCommentsMentionsCreate(
      postsPk,
      commentsPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsMentionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsCreate>>
>;
export type SocialPostsCommentsMentionsCreateMutationBody =
  MentionCreateRequest;
export type SocialPostsCommentsMentionsCreateMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsMentionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: MentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsMentionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsMentionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsMentionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsMentionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsMentionsRetrieveQueryKey(postsPk, commentsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsMentionsRetrieve(
      postsPk,
      commentsPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsMentionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>
>;
export type SocialPostsCommentsMentionsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsMentionsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsMentionsRetrieveQueryOptions(
    postsPk,
    commentsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsMentionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  id: number,
  patchedMentionCreateRequest: PatchedMentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsMentionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsPartialUpdate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    id: number;
    data: PatchedMentionCreateRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsPartialUpdate>>,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, id, data } = props ?? {};

    return socialPostsCommentsMentionsPartialUpdate(
      postsPk,
      commentsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsMentionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsPartialUpdate>>
  >;
export type SocialPostsCommentsMentionsPartialUpdateMutationBody =
  PatchedMentionCreateRequest;
export type SocialPostsCommentsMentionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsMentionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsMentionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsMentionsDestroy = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsMentionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsDestroy>>,
    { postsPk: number; commentsPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, id } = props ?? {};

    return socialPostsCommentsMentionsDestroy(
      postsPk,
      commentsPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsMentionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsDestroy>>
>;

export type SocialPostsCommentsMentionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsMentionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsMentionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reactions I've received
 */
export const socialPostsCommentsMentionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsMentionsMeRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/mentions/me/`,
  ] as const;
};

export const getSocialPostsCommentsMentionsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsMentionsMeRetrieveQueryKey(postsPk, commentsPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsMentionsMeRetrieve(
      postsPk,
      commentsPk,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsMentionsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>
>;
export type SocialPostsCommentsMentionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsMentionsMeRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsMentionsMeRetrieveQueryOptions(
    postsPk,
    commentsPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsReactionsList = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsReactionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedReactionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsReactionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsReactionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsReactionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsReactionsListQueryKey(postsPk, commentsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>
  > = ({ signal }) =>
    socialPostsCommentsReactionsList(
      postsPk,
      commentsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsReactionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>
>;
export type SocialPostsCommentsReactionsListQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsReactionsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsReactionsListQueryOptions(
    postsPk,
    commentsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsReactionsCreate = (
  postsPk: number,
  commentsPk: number,
  reactionRequest: ReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: reactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsReactionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: ReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsCreate>>,
  TError,
  { postsPk: number; commentsPk: number; data: ReactionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsCreate>>,
    { postsPk: number; commentsPk: number; data: ReactionRequest }
  > = (props) => {
    const { postsPk, commentsPk, data } = props ?? {};

    return socialPostsCommentsReactionsCreate(
      postsPk,
      commentsPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsReactionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsCreate>>
>;
export type SocialPostsCommentsReactionsCreateMutationBody = ReactionRequest;
export type SocialPostsCommentsReactionsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsReactionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: ReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsReactionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsReactionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsReactionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsReactionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsReactionsRetrieveQueryKey(postsPk, commentsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsReactionsRetrieve(
      postsPk,
      commentsPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsReactionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>
>;
export type SocialPostsCommentsReactionsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsReactionsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsReactionsRetrieveQueryOptions(
    postsPk,
    commentsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsReactionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  id: number,
  patchedReactionRequest: PatchedReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedReactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsReactionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsPartialUpdate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    id: number;
    data: PatchedReactionRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsPartialUpdate>>,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedReactionRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, id, data } = props ?? {};

    return socialPostsCommentsReactionsPartialUpdate(
      postsPk,
      commentsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsReactionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsPartialUpdate>>
  >;
export type SocialPostsCommentsReactionsPartialUpdateMutationBody =
  PatchedReactionRequest;
export type SocialPostsCommentsReactionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsReactionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsReactionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsReactionsDestroy = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsReactionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsDestroy>>,
    { postsPk: number; commentsPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, id } = props ?? {};

    return socialPostsCommentsReactionsDestroy(
      postsPk,
      commentsPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsReactionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsDestroy>>
>;

export type SocialPostsCommentsReactionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsReactionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsReactionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reaction I've received
 */
export const socialPostsCommentsReactionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsReactionsMeRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/reactions/me/`,
  ] as const;
};

export const getSocialPostsCommentsReactionsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsReactionsMeRetrieveQueryKey(postsPk, commentsPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsReactionsMeRetrieve(
      postsPk,
      commentsPk,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsReactionsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>
>;
export type SocialPostsCommentsReactionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsReactionsMeRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsReactionsMeRetrieveQueryOptions(
    postsPk,
    commentsPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesList = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsRepliesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedReplyList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesListQueryKey = (
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsRepliesListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsRepliesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesListQueryKey(postsPk, commentsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesList(
      postsPk,
      commentsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>
>;
export type SocialPostsCommentsRepliesListQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsRepliesList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  params?: SocialPostsCommentsRepliesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRepliesListQueryOptions(
    postsPk,
    commentsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesCreate = (
  postsPk: number,
  commentsPk: number,
  postWriteRequest: PostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesCreate>>,
  TError,
  { postsPk: number; commentsPk: number; data: PostWriteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesCreate>>,
    { postsPk: number; commentsPk: number; data: PostWriteRequest }
  > = (props) => {
    const { postsPk, commentsPk, data } = props ?? {};

    return socialPostsCommentsRepliesCreate(
      postsPk,
      commentsPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesCreate>>
>;
export type SocialPostsCommentsRepliesCreateMutationBody = PostWriteRequest;
export type SocialPostsCommentsRepliesCreateMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsRepliesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesCreate>>,
    TError,
    { postsPk: number; commentsPk: number; data: PostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRetrieve = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reply>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesRetrieveQueryKey(postsPk, commentsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesRetrieve(
      postsPk,
      commentsPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>
>;
export type SocialPostsCommentsRepliesRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRepliesRetrieveQueryOptions(
    postsPk,
    commentsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  id: number,
  patchedPostWriteRequest: PatchedPostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPostWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedPostWriteRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesPartialUpdate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    id: number;
    data: PatchedPostWriteRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPartialUpdate>>,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedPostWriteRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, id, data } = props ?? {};

    return socialPostsCommentsRepliesPartialUpdate(
      postsPk,
      commentsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesPartialUpdate>>
>;
export type SocialPostsCommentsRepliesPartialUpdateMutationBody =
  PatchedPostWriteRequest;
export type SocialPostsCommentsRepliesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PatchedPostWriteRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesDestroy = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesDestroy>>,
    { postsPk: number; commentsPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, id } = props ?? {};

    return socialPostsCommentsRepliesDestroy(
      postsPk,
      commentsPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesDestroy>>
>;

export type SocialPostsCommentsRepliesDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsRepliesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Approve content flagged as potentially inappropriate
 */
export const socialPostsCommentsRepliesApproveCreate = (
  postsPk: number,
  commentsPk: number,
  id: number,
  postRequest: PostRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Post>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/approve/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesApproveCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesApproveCreate>>,
    TError,
    { postsPk: number; commentsPk: number; id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesApproveCreate>>,
  TError,
  { postsPk: number; commentsPk: number; id: number; data: PostRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesApproveCreate>>,
    { postsPk: number; commentsPk: number; id: number; data: PostRequest }
  > = (props) => {
    const { postsPk, commentsPk, id, data } = props ?? {};

    return socialPostsCommentsRepliesApproveCreate(
      postsPk,
      commentsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesApproveCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesApproveCreate>>
>;
export type SocialPostsCommentsRepliesApproveCreateMutationBody = PostRequest;
export type SocialPostsCommentsRepliesApproveCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesApproveCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesApproveCreate>>,
    TError,
    { postsPk: number; commentsPk: number; id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesApproveCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsRepliesPostImageUploadCreate = (
  postsPk: number,
  commentsPk: number,
  id: number,
  postImageUploadRequest: PostImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (postImageUploadRequest.file !== null) {
    formData.append("file", postImageUploadRequest.file);
  }

  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/post_image_upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesPostImageUploadCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesPostImageUploadCreate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        id: number;
        data: PostImageUploadRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPostImageUploadCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PostImageUploadRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesPostImageUploadCreate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        id: number;
        data: PostImageUploadRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesPostImageUploadCreate(
        postsPk,
        commentsPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesPostImageUploadCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPostImageUploadCreate>>
  >;
export type SocialPostsCommentsRepliesPostImageUploadCreateMutationBody =
  PostImageUploadRequest;
export type SocialPostsCommentsRepliesPostImageUploadCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesPostImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPostImageUploadCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      id: number;
      data: PostImageUploadRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesPostImageUploadCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsRepliesPostImageUploadDestroy = (
  postsPk: number,
  commentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${id}/post_image_upload/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesPostImageUploadDestroyMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesPostImageUploadDestroy>
      >,
      TError,
      { postsPk: number; commentsPk: number; id: number },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesPostImageUploadDestroy>
    >,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesPostImageUploadDestroy>
      >,
      { postsPk: number; commentsPk: number; id: number }
    > = (props) => {
      const { postsPk, commentsPk, id } = props ?? {};

      return socialPostsCommentsRepliesPostImageUploadDestroy(
        postsPk,
        commentsPk,
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesPostImageUploadDestroyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesPostImageUploadDestroy>>
  >;

export type SocialPostsCommentsRepliesPostImageUploadDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesPostImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesPostImageUploadDestroy>
    >,
    TError,
    { postsPk: number; commentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesPostImageUploadDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesMentionsList = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesMentionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesMentionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesMentionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesMentionsListQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesMentionsList(
      postsPk,
      commentsPk,
      repliesPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesMentionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>
>;
export type SocialPostsCommentsRepliesMentionsListQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRepliesMentionsListQueryOptions(
    postsPk,
    commentsPk,
    repliesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesMentionsCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  mentionCreateRequest: MentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: MentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsCreate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    repliesPk: number;
    data: MentionCreateRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsCreate>>,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: MentionCreateRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, data } = props ?? {};

    return socialPostsCommentsRepliesMentionsCreate(
      postsPk,
      commentsPk,
      repliesPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesMentionsCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsCreate>>
  >;
export type SocialPostsCommentsRepliesMentionsCreateMutationBody =
  MentionCreateRequest;
export type SocialPostsCommentsRepliesMentionsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: MentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesMentionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesMentionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesMentionsRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesMentionsRetrieveQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      id
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesMentionsRetrieve(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesMentionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>>
>;
export type SocialPostsCommentsRepliesMentionsRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesMentionsRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesMentionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  patchedMentionCreateRequest: PatchedMentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsPartialUpdateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesMentionsPartialUpdate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedMentionCreateRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesMentionsPartialUpdate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedMentionCreateRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesMentionsPartialUpdate(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesMentionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsPartialUpdate>>
  >;
export type SocialPostsCommentsRepliesMentionsPartialUpdateMutationBody =
  PatchedMentionCreateRequest;
export type SocialPostsCommentsRepliesMentionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsPartialUpdate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesMentionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesMentionsDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; repliesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsDestroy>>,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, id } = props ?? {};

    return socialPostsCommentsRepliesMentionsDestroy(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesMentionsDestroyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsDestroy>>
  >;

export type SocialPostsCommentsRepliesMentionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesMentionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reactions I've received
 */
export const socialPostsCommentsRepliesMentionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesMentionsMeRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/mentions/me/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesMentionsMeRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesMentionsMeRetrieveQueryKey(
      postsPk,
      commentsPk,
      repliesPk
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesMentionsMeRetrieve(
      postsPk,
      commentsPk,
      repliesPk,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesMentionsMeRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>>
  >;
export type SocialPostsCommentsRepliesMentionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesMentionsMeRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesMentionsMeRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesReactionsList = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesReactionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedReactionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesReactionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesReactionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesReactionsListQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesReactionsList(
      postsPk,
      commentsPk,
      repliesPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesReactionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>
>;
export type SocialPostsCommentsRepliesReactionsListQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRepliesReactionsListQueryOptions(
    postsPk,
    commentsPk,
    repliesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesReactionsCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  reactionRequest: ReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: reactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: ReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsCreate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    repliesPk: number;
    data: ReactionRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsCreate>>,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: ReactionRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, data } = props ?? {};

    return socialPostsCommentsRepliesReactionsCreate(
      postsPk,
      commentsPk,
      repliesPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesReactionsCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsCreate>>
  >;
export type SocialPostsCommentsRepliesReactionsCreateMutationBody =
  ReactionRequest;
export type SocialPostsCommentsRepliesReactionsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: ReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesReactionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesReactionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesReactionsRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesReactionsRetrieveQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      id
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesReactionsRetrieve(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesReactionsRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>>
  >;
export type SocialPostsCommentsRepliesReactionsRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesReactionsRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesReactionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  patchedReactionRequest: PatchedReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedReactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsPartialUpdateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesReactionsPartialUpdate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedReactionRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesReactionsPartialUpdate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesReactionsPartialUpdate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedReactionRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesReactionsPartialUpdate(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesReactionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsPartialUpdate>>
  >;
export type SocialPostsCommentsRepliesReactionsPartialUpdateMutationBody =
  PatchedReactionRequest;
export type SocialPostsCommentsRepliesReactionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesReactionsPartialUpdate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesReactionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesReactionsDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; repliesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsDestroy>>,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, id } = props ?? {};

    return socialPostsCommentsRepliesReactionsDestroy(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesReactionsDestroyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsDestroy>>
  >;

export type SocialPostsCommentsRepliesReactionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesReactionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reaction I've received
 */
export const socialPostsCommentsRepliesReactionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesReactionsMeRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/reactions/me/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesReactionsMeRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesReactionsMeRetrieveQueryKey(
      postsPk,
      commentsPk,
      repliesPk
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesReactionsMeRetrieve(
      postsPk,
      commentsPk,
      repliesPk,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesReactionsMeRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>>
  >;
export type SocialPostsCommentsRepliesReactionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesReactionsMeRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesReactionsMeRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRebuttalsList = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedRebuttalList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesRebuttalsListQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesRebuttalsList(
      postsPk,
      commentsPk,
      repliesPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesRebuttalsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>
>;
export type SocialPostsCommentsRepliesRebuttalsListQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsList = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRepliesRebuttalsListQueryOptions(
    postsPk,
    commentsPk,
    repliesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRebuttalsCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  postWriteRequest: PostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: PostWriteRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsCreate>>,
  TError,
  {
    postsPk: number;
    commentsPk: number;
    repliesPk: number;
    data: PostWriteRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsCreate>>,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: PostWriteRequest;
    }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, data } = props ?? {};

    return socialPostsCommentsRepliesRebuttalsCreate(
      postsPk,
      commentsPk,
      repliesPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesRebuttalsCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsCreate>>
  >;
export type SocialPostsCommentsRepliesRebuttalsCreateMutationBody =
  PostWriteRequest;
export type SocialPostsCommentsRepliesRebuttalsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsCreate>>,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      data: PostWriteRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRebuttalsRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Rebuttal>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesRebuttalsRetrieveQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      id
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesRebuttalsRetrieve(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesRebuttalsRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>>
  >;
export type SocialPostsCommentsRepliesRebuttalsRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRebuttalsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  patchedPostWriteRequest: PatchedPostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPostWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsPartialUpdateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsPartialUpdate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedPostWriteRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsPartialUpdate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedPostWriteRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsPartialUpdate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PatchedPostWriteRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsPartialUpdate(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsPartialUpdate>>
  >;
export type SocialPostsCommentsRepliesRebuttalsPartialUpdateMutationBody =
  PatchedPostWriteRequest;
export type SocialPostsCommentsRepliesRebuttalsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsPartialUpdate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PatchedPostWriteRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsRepliesRebuttalsDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsDestroy>>,
  TError,
  { postsPk: number; commentsPk: number; repliesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsDestroy>>,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number }
  > = (props) => {
    const { postsPk, commentsPk, repliesPk, id } = props ?? {};

    return socialPostsCommentsRepliesRebuttalsDestroy(
      postsPk,
      commentsPk,
      repliesPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsRepliesRebuttalsDestroyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsDestroy>>
  >;

export type SocialPostsCommentsRepliesRebuttalsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsDestroy>>,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Approve content flagged as potentially inappropriate
 */
export const socialPostsCommentsRepliesRebuttalsApproveCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  postRequest: PostRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Post>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/approve/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsApproveCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsApproveCreate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PostRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsApproveCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PostRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsApproveCreate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PostRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsApproveCreate(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsApproveCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsApproveCreate>>
  >;
export type SocialPostsCommentsRepliesRebuttalsApproveCreateMutationBody =
  PostRequest;
export type SocialPostsCommentsRepliesRebuttalsApproveCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsApproveCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsApproveCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PostRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsApproveCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsRepliesRebuttalsPostImageUploadCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  postImageUploadRequest: PostImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (postImageUploadRequest.file !== null) {
    formData.append("file", postImageUploadRequest.file);
  }

  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/post_image_upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsPostImageUploadCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsPostImageUploadCreate
        >
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PostImageUploadRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadCreate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PostImageUploadRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsPostImageUploadCreate
        >
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        id: number;
        data: PostImageUploadRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id, data } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsPostImageUploadCreate(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsPostImageUploadCreateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadCreate
      >
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsPostImageUploadCreateMutationBody =
  PostImageUploadRequest;
export type SocialPostsCommentsRepliesRebuttalsPostImageUploadCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsPostImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadCreate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      id: number;
      data: PostImageUploadRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsPostImageUploadCreateMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${id}/post_image_upload/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsPostImageUploadDestroyMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy
        >
      >,
      TError,
      { postsPk: number; commentsPk: number; repliesPk: number; id: number },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy
      >
    >,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy
        >
      >,
      { postsPk: number; commentsPk: number; repliesPk: number; id: number }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, id } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy(
        postsPk,
        commentsPk,
        repliesPk,
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsPostImageUploadDestroyMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy
      >
    >
  >;

export type SocialPostsCommentsRepliesRebuttalsPostImageUploadDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsPostImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsPostImageUploadDestroy
      >
    >,
    TError,
    { postsPk: number; commentsPk: number; repliesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsPostImageUploadDestroyMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsMentionsList = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsMentionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsMentionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsListQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesRebuttalsMentionsListQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesRebuttalsMentionsList(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesRebuttalsMentionsListQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>>
  >;
export type SocialPostsCommentsRepliesRebuttalsMentionsListQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsList = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsList>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsListQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsMentionsCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  mentionCreateRequest: MentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsCreate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        data: MentionCreateRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      data: MentionCreateRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsCreate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        data: MentionCreateRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, data } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsMentionsCreate(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsMentionsCreateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsCreate>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsMentionsCreateMutationBody =
  MentionCreateRequest;
export type SocialPostsCommentsRepliesRebuttalsMentionsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      data: MentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsCreateMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsMentionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
    >,
    TError = ErrorType<unknown>,
  >(
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number,
    id: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof axiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryKey(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
      >
    > = ({ signal }) =>
      socialPostsCommentsRepliesRebuttalsMentionsRetrieve(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk && id),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      id,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  patchedMentionCreateRequest: PatchedMentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsPartialUpdateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate
        >
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
        data: PatchedMentionCreateRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate
        >
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
        data: PatchedMentionCreateRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, id, data } =
        props ?? {};

      return socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsMentionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate
      >
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsMentionsPartialUpdateMutationBody =
  PatchedMentionCreateRequest;
export type SocialPostsCommentsRepliesRebuttalsMentionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsMentionsPartialUpdate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
      data: PatchedMentionCreateRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsPartialUpdateMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsMentionsDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsDestroyMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsDestroy>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsDestroy>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsDestroy>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, id } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsMentionsDestroy(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsMentionsDestroyMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsDestroy>
    >
  >;

export type SocialPostsCommentsRepliesRebuttalsMentionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsDestroy>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsDestroyMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Reactions I've received
 */
export const socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryKey =
  (
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number
  ) => {
    return [
      `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/mentions/me/`,
    ] as const;
  };

export const getSocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
    >,
    TError = ErrorType<unknown>,
  >(
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof axiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryKey(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
      >
    > = ({ signal }) =>
      socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsMentionsMeRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsMentionsMeRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsMentionsMeRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsReactionsList = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsReactionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedReactionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsListQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsReactionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsListQueryOptions = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRepliesRebuttalsReactionsListQueryKey(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>>
  > = ({ signal }) =>
    socialPostsCommentsRepliesRebuttalsReactionsList(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRepliesRebuttalsReactionsListQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>>
  >;
export type SocialPostsCommentsRepliesRebuttalsReactionsListQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsList = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  params?: SocialPostsCommentsRepliesRebuttalsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsList>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsListQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsReactionsCreate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  reactionRequest: ReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: reactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsCreate>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        data: ReactionRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      data: ReactionRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsCreate>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        data: ReactionRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, data } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsReactionsCreate(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsReactionsCreateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsCreate>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsReactionsCreateMutationBody =
  ReactionRequest;
export type SocialPostsCommentsRepliesRebuttalsReactionsCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsCreate>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      data: ReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsCreateMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsReactionsRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryKey = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number
) => {
  return [
    `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/${id}/`,
  ] as const;
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
    >,
    TError = ErrorType<unknown>,
  >(
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number,
    id: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof axiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryKey(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
      >
    > = ({ signal }) =>
      socialPostsCommentsRepliesRebuttalsReactionsRetrieve(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk && id),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      id,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  patchedReactionRequest: PatchedReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedReactionRequest,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsPartialUpdateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate
        >
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
        data: PatchedReactionRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate
        >
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
        data: PatchedReactionRequest;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, id, data } =
        props ?? {};

      return socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsReactionsPartialUpdateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate
      >
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsReactionsPartialUpdateMutationBody =
  PatchedReactionRequest;
export type SocialPostsCommentsRepliesRebuttalsReactionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof socialPostsCommentsRepliesRebuttalsReactionsPartialUpdate
      >
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
      data: PatchedReactionRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsPartialUpdateMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsCommentsRepliesRebuttalsReactionsDestroy = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsDestroyMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsDestroy>
      >,
      TError,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsDestroy>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsDestroy>
      >,
      {
        postsPk: number;
        commentsPk: number;
        repliesPk: number;
        rebuttalsPk: number;
        id: number;
      }
    > = (props) => {
      const { postsPk, commentsPk, repliesPk, rebuttalsPk, id } = props ?? {};

      return socialPostsCommentsRepliesRebuttalsReactionsDestroy(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type SocialPostsCommentsRepliesRebuttalsReactionsDestroyMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsDestroy>
    >
  >;

export type SocialPostsCommentsRepliesRebuttalsReactionsDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsDestroy>
    >,
    TError,
    {
      postsPk: number;
      commentsPk: number;
      repliesPk: number;
      rebuttalsPk: number;
      id: number;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsDestroyMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * Reaction I've received
 */
export const socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve = (
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryKey =
  (
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number
  ) => {
    return [
      `/api/v1/social/posts/${postsPk}/comments/${commentsPk}/replies/${repliesPk}/rebuttals/${rebuttalsPk}/reactions/me/`,
    ] as const;
  };

export const getSocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve>
    >,
    TError = ErrorType<unknown>,
  >(
    postsPk: number,
    commentsPk: number,
    repliesPk: number,
    rebuttalsPk: number,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof axiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getSocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryKey(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve
        >
      >
    > = ({ signal }) =>
      socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve(
        postsPk,
        commentsPk,
        repliesPk,
        rebuttalsPk,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(postsPk && commentsPk && repliesPk && rebuttalsPk),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type SocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve>
    >
  >;
export type SocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useSocialPostsCommentsRepliesRebuttalsReactionsMeRetrieve = <
  TData = Awaited<
    ReturnType<typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  commentsPk: number,
  repliesPk: number,
  rebuttalsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          typeof socialPostsCommentsRepliesRebuttalsReactionsMeRetrieve
        >
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSocialPostsCommentsRepliesRebuttalsReactionsMeRetrieveQueryOptions(
      postsPk,
      commentsPk,
      repliesPk,
      rebuttalsPk,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsRetrieve = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Comment>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsCommentsRetrieveQueryKey = (
  postsPk: number,
  id: number
) => {
  return [`/api/v1/social/posts/${postsPk}/comments/${id}/`] as const;
};

export const getSocialPostsCommentsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsCommentsRetrieveQueryKey(postsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>
  > = ({ signal }) =>
    socialPostsCommentsRetrieve(postsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsCommentsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>
>;
export type SocialPostsCommentsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsCommentsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsCommentsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsCommentsRetrieveQueryOptions(
    postsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Common to all social views
 */
export const socialPostsCommentsPartialUpdate = (
  postsPk: number,
  id: number,
  patchedPostWriteRequest: PatchedPostWriteRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPostWriteRequest,
    },
    options
  );
};

export const getSocialPostsCommentsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedPostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsPartialUpdate>>,
  TError,
  { postsPk: number; id: number; data: PatchedPostWriteRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsPartialUpdate>>,
    { postsPk: number; id: number; data: PatchedPostWriteRequest }
  > = (props) => {
    const { postsPk, id, data } = props ?? {};

    return socialPostsCommentsPartialUpdate(postsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsPartialUpdate>>
>;
export type SocialPostsCommentsPartialUpdateMutationBody =
  PatchedPostWriteRequest;
export type SocialPostsCommentsPartialUpdateMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedPostWriteRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Common to all social views
 */
export const socialPostsCommentsDestroy = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsDestroy>>,
  TError,
  { postsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsDestroy>>,
    { postsPk: number; id: number }
  > = (props) => {
    const { postsPk, id } = props ?? {};

    return socialPostsCommentsDestroy(postsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsDestroy>>
>;

export type SocialPostsCommentsDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsCommentsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Approve content flagged as potentially inappropriate
 */
export const socialPostsCommentsApproveCreate = (
  postsPk: number,
  id: number,
  postRequest: PostRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Post>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/approve/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postRequest,
    },
    options
  );
};

export const getSocialPostsCommentsApproveCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsApproveCreate>>,
    TError,
    { postsPk: number; id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsApproveCreate>>,
  TError,
  { postsPk: number; id: number; data: PostRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsApproveCreate>>,
    { postsPk: number; id: number; data: PostRequest }
  > = (props) => {
    const { postsPk, id, data } = props ?? {};

    return socialPostsCommentsApproveCreate(postsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsApproveCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsCommentsApproveCreate>>
>;
export type SocialPostsCommentsApproveCreateMutationBody = PostRequest;
export type SocialPostsCommentsApproveCreateMutationError = ErrorType<unknown>;

export const useSocialPostsCommentsApproveCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsApproveCreate>>,
    TError,
    { postsPk: number; id: number; data: PostRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsApproveCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsPostImageUploadCreate = (
  postsPk: number,
  id: number,
  postImageUploadRequest: PostImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (postImageUploadRequest.file !== null) {
    formData.append("file", postImageUploadRequest.file);
  }

  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/post_image_upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getSocialPostsCommentsPostImageUploadCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadCreate>>,
    TError,
    { postsPk: number; id: number; data: PostImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadCreate>>,
  TError,
  { postsPk: number; id: number; data: PostImageUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadCreate>>,
    { postsPk: number; id: number; data: PostImageUploadRequest }
  > = (props) => {
    const { postsPk, id, data } = props ?? {};

    return socialPostsCommentsPostImageUploadCreate(
      postsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsPostImageUploadCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadCreate>>
  >;
export type SocialPostsCommentsPostImageUploadCreateMutationBody =
  PostImageUploadRequest;
export type SocialPostsCommentsPostImageUploadCreateMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsPostImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadCreate>>,
    TError,
    { postsPk: number; id: number; data: PostImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsPostImageUploadCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Post image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own posts
 */
export const socialPostsCommentsPostImageUploadDestroy = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PostWrite | void>(
    {
      url: `/api/v1/social/posts/${postsPk}/comments/${id}/post_image_upload/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsCommentsPostImageUploadDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadDestroy>>,
  TError,
  { postsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadDestroy>>,
    { postsPk: number; id: number }
  > = (props) => {
    const { postsPk, id } = props ?? {};

    return socialPostsCommentsPostImageUploadDestroy(
      postsPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsCommentsPostImageUploadDestroyMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadDestroy>>
  >;

export type SocialPostsCommentsPostImageUploadDestroyMutationError =
  ErrorType<unknown>;

export const useSocialPostsCommentsPostImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsCommentsPostImageUploadDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsCommentsPostImageUploadDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsMentionsList = (
  postsPk: number,
  params?: SocialPostsMentionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsMentionsListQueryKey = (
  postsPk: number,
  params?: SocialPostsMentionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/mentions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsMentionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsMentionsListQueryKey(postsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsMentionsList>>
  > = ({ signal }) =>
    socialPostsMentionsList(postsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMentionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsMentionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsList>>
>;
export type SocialPostsMentionsListQueryError = ErrorType<unknown>;

export const useSocialPostsMentionsList = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsMentionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsMentionsListQueryOptions(
    postsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsMentionsCreate = (
  postsPk: number,
  mentionCreateRequest: MentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsMentionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsCreate>>,
    TError,
    { postsPk: number; data: MentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsMentionsCreate>>,
  TError,
  { postsPk: number; data: MentionCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsMentionsCreate>>,
    { postsPk: number; data: MentionCreateRequest }
  > = (props) => {
    const { postsPk, data } = props ?? {};

    return socialPostsMentionsCreate(postsPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsMentionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsCreate>>
>;
export type SocialPostsMentionsCreateMutationBody = MentionCreateRequest;
export type SocialPostsMentionsCreateMutationError = ErrorType<unknown>;

export const useSocialPostsMentionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsCreate>>,
    TError,
    { postsPk: number; data: MentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsMentionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsMentionsRetrieve = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsMentionsRetrieveQueryKey = (
  postsPk: number,
  id: number
) => {
  return [`/api/v1/social/posts/${postsPk}/mentions/${id}/`] as const;
};

export const getSocialPostsMentionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsMentionsRetrieveQueryKey(postsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>
  > = ({ signal }) =>
    socialPostsMentionsRetrieve(postsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsMentionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>
>;
export type SocialPostsMentionsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsMentionsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsMentionsRetrieveQueryOptions(
    postsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsMentionsPartialUpdate = (
  postsPk: number,
  id: number,
  patchedMentionCreateRequest: PatchedMentionCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentionCreate>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentionCreateRequest,
    },
    options
  );
};

export const getSocialPostsMentionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedMentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsMentionsPartialUpdate>>,
  TError,
  { postsPk: number; id: number; data: PatchedMentionCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsMentionsPartialUpdate>>,
    { postsPk: number; id: number; data: PatchedMentionCreateRequest }
  > = (props) => {
    const { postsPk, id, data } = props ?? {};

    return socialPostsMentionsPartialUpdate(postsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsMentionsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsPartialUpdate>>
>;
export type SocialPostsMentionsPartialUpdateMutationBody =
  PatchedMentionCreateRequest;
export type SocialPostsMentionsPartialUpdateMutationError = ErrorType<unknown>;

export const useSocialPostsMentionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedMentionCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsMentionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsMentionsDestroy = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsMentionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsMentionsDestroy>>,
  TError,
  { postsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsMentionsDestroy>>,
    { postsPk: number; id: number }
  > = (props) => {
    const { postsPk, id } = props ?? {};

    return socialPostsMentionsDestroy(postsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsMentionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsDestroy>>
>;

export type SocialPostsMentionsDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsMentionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsMentionsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsMentionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reactions I've received
 */
export const socialPostsMentionsMeRetrieve = (
  postsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Mention>(
    {
      url: `/api/v1/social/posts/${postsPk}/mentions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsMentionsMeRetrieveQueryKey = (postsPk: number) => {
  return [`/api/v1/social/posts/${postsPk}/mentions/me/`] as const;
};

export const getSocialPostsMentionsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSocialPostsMentionsMeRetrieveQueryKey(postsPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsMentionsMeRetrieve(postsPk, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsMentionsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>
>;
export type SocialPostsMentionsMeRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsMentionsMeRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsMentionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsMentionsMeRetrieveQueryOptions(
    postsPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsReactionsList = (
  postsPk: number,
  params?: SocialPostsReactionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedReactionList>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSocialPostsReactionsListQueryKey = (
  postsPk: number,
  params?: SocialPostsReactionsListParams
) => {
  return [
    `/api/v1/social/posts/${postsPk}/reactions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSocialPostsReactionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsReactionsListQueryKey(postsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsReactionsList>>
  > = ({ signal }) =>
    socialPostsReactionsList(postsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsReactionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsReactionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsList>>
>;
export type SocialPostsReactionsListQueryError = ErrorType<unknown>;

export const useSocialPostsReactionsList = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsList>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  params?: SocialPostsReactionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsReactionsListQueryOptions(
    postsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsReactionsCreate = (
  postsPk: number,
  reactionRequest: ReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: reactionRequest,
    },
    options
  );
};

export const getSocialPostsReactionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsCreate>>,
    TError,
    { postsPk: number; data: ReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsReactionsCreate>>,
  TError,
  { postsPk: number; data: ReactionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsReactionsCreate>>,
    { postsPk: number; data: ReactionRequest }
  > = (props) => {
    const { postsPk, data } = props ?? {};

    return socialPostsReactionsCreate(postsPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsReactionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsCreate>>
>;
export type SocialPostsReactionsCreateMutationBody = ReactionRequest;
export type SocialPostsReactionsCreateMutationError = ErrorType<unknown>;

export const useSocialPostsReactionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsCreate>>,
    TError,
    { postsPk: number; data: ReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getSocialPostsReactionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsReactionsRetrieve = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsReactionsRetrieveQueryKey = (
  postsPk: number,
  id: number
) => {
  return [`/api/v1/social/posts/${postsPk}/reactions/${id}/`] as const;
};

export const getSocialPostsReactionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsReactionsRetrieveQueryKey(postsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>
  > = ({ signal }) =>
    socialPostsReactionsRetrieve(postsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(postsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsReactionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>
>;
export type SocialPostsReactionsRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsReactionsRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsReactionsRetrieveQueryOptions(
    postsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const socialPostsReactionsPartialUpdate = (
  postsPk: number,
  id: number,
  patchedReactionRequest: PatchedReactionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedReactionRequest,
    },
    options
  );
};

export const getSocialPostsReactionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsReactionsPartialUpdate>>,
  TError,
  { postsPk: number; id: number; data: PatchedReactionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsReactionsPartialUpdate>>,
    { postsPk: number; id: number; data: PatchedReactionRequest }
  > = (props) => {
    const { postsPk, id, data } = props ?? {};

    return socialPostsReactionsPartialUpdate(postsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsReactionsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsPartialUpdate>>
>;
export type SocialPostsReactionsPartialUpdateMutationBody =
  PatchedReactionRequest;
export type SocialPostsReactionsPartialUpdateMutationError = ErrorType<unknown>;

export const useSocialPostsReactionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsPartialUpdate>>,
    TError,
    { postsPk: number; id: number; data: PatchedReactionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsReactionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const socialPostsReactionsDestroy = (
  postsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getSocialPostsReactionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof socialPostsReactionsDestroy>>,
  TError,
  { postsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof socialPostsReactionsDestroy>>,
    { postsPk: number; id: number }
  > = (props) => {
    const { postsPk, id } = props ?? {};

    return socialPostsReactionsDestroy(postsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SocialPostsReactionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsDestroy>>
>;

export type SocialPostsReactionsDestroyMutationError = ErrorType<unknown>;

export const useSocialPostsReactionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof socialPostsReactionsDestroy>>,
    TError,
    { postsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getSocialPostsReactionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reaction I've received
 */
export const socialPostsReactionsMeRetrieve = (
  postsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Reaction>(
    {
      url: `/api/v1/social/posts/${postsPk}/reactions/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getSocialPostsReactionsMeRetrieveQueryKey = (postsPk: number) => {
  return [`/api/v1/social/posts/${postsPk}/reactions/me/`] as const;
};

export const getSocialPostsReactionsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSocialPostsReactionsMeRetrieveQueryKey(postsPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>
  > = ({ signal }) =>
    socialPostsReactionsMeRetrieve(postsPk, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!postsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsReactionsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>
>;
export type SocialPostsReactionsMeRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsReactionsMeRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  postsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof socialPostsReactionsMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsReactionsMeRetrieveQueryOptions(
    postsPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * My posts
 */
export const socialPostsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Post>(
    { url: `/api/v1/social/posts/me/`, method: "GET", signal },
    options
  );
};

export const getSocialPostsMeRetrieveQueryKey = () => {
  return [`/api/v1/social/posts/me/`] as const;
};

export const getSocialPostsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof socialPostsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSocialPostsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof socialPostsMeRetrieve>>
  > = ({ signal }) => socialPostsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SocialPostsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof socialPostsMeRetrieve>>
>;
export type SocialPostsMeRetrieveQueryError = ErrorType<unknown>;

export const useSocialPostsMeRetrieve = <
  TData = Awaited<ReturnType<typeof socialPostsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof socialPostsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSocialPostsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
