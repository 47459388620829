/**
 *
 *
 * <Reactions />
 *
 *
 */
import { Popover } from "@headlessui/react";
import { FaceSmileIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { useReactions } from "./useReactions";

export const Reactions = (props: ReturnType<typeof useReactions>) => {
  const { t } = useTranslation();
  return (
    <>
      <Popover className="relative">
        <ul className="flex items-center gap-2">
          {Object.keys(props.existingReactions).map((key) => (
            <li key={props.existingReactions[key].key}>
              <button
                type="button"
                onClick={() =>
                  props.toggleReaction(props.existingReactions[key].key)
                }
                className={clsx(
                  "text-zinc-300 ring-1 ring-zinc-300 hover:text-zinc-400",
                  "rounded-full px-1 text-xl font-medium leading-6 hover:bg-zinc-50",
                  "flex items-center",
                  props.isLoading && "cursor-wait"
                )}
                data-tooltip-id="tooltip"
                data-tooltip-html={props
                  .truncateNameList(props.existingReactions[key].names)
                  .join("<br/>")}
                data-tooltip-place="top"
                disabled={props.isLoading}
              >
                <span>{props.existingReactions[key].value}</span>
                <p className="ml-0.5 text-xs">
                  {props.existingReactions[key].count}
                </p>
              </button>
            </li>
          ))}

          <li>
            <Popover.Button
              className={clsx(
                "rounded-md text-zinc-500 hover:bg-zinc-100",
                "flex items-center gap-1 hover:text-zinc-600"
              )}
              onClick={() => props.setFilteredEmojis(null)}
            >
              <FaceSmileIcon className="h-6 w-6" />
            </Popover.Button>
          </li>
        </ul>

        <Popover.Panel
          className={clsx(
            "absolute -right-8 top-4 z-10 mt-3 shadow-xl",
            "opacity-100 sm:px-0 lg:max-w-3xl"
          )}
        >
          <div className="overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-zinc-300">
            <ul className={clsx("flex border-b")}>
              {props.categories.map((category) => (
                <li key={`category-${category.key}`}>
                  <button
                    type="button"
                    onClick={() => {
                      if (props.searchRef.current) {
                        props.searchRef.current.value = "";
                      }
                      props.setFilteredEmojis(null);
                      props.onSearchEmoji("");
                      props.setActiveCategory(category.key);
                    }}
                    className={clsx(
                      "p-2 text-zinc-500 hover:bg-zinc-100 hover:text-zinc-600",
                      category.isActive ? "border-b" : ""
                    )}
                  >
                    <category.icon className={clsx("h-5 w-5")} />
                  </button>
                </li>
              ))}
            </ul>
            {(props.activeCategory === "search" || !props.activeCategory) && (
              <div className="px-2 py-2.5" ref={props.categoryRefs["search"]}>
                <input
                  type="text"
                  ref={props.searchRef}
                  placeholder={t("translation:common:search_all_emoji")}
                  className={clsx(
                    "block h-8 w-full rounded-md border border-zinc-300 shadow-sm",
                    "focus:border-0 focus:ring-2 focus:ring-zinc-400 sm:text-sm"
                  )}
                  onChange={(e) => props.onSearchEmoji(e.target.value)}
                />
              </div>
            )}
            {props.filteredEmojis && (
              <div className="h-96 overflow-y-auto px-2">
                {props.filteredEmojis.length === 0 && (
                  <p className="px-2 text-xs">
                    {t("translation:common:no_results")}
                  </p>
                )}
                <ul className="relative grid grid-cols-8">
                  {props.filteredEmojis.map((emoji) => (
                    <li key={`result-emoji-${emoji.key}`}>
                      <Popover.Button
                        type="button"
                        onClick={() => props.toggleReaction(emoji.key)}
                        className={clsx(
                          "h-8 w-8 rounded p-1 text-xl hover:bg-zinc-100",
                          props.isLoading && "cursor-wait"
                        )}
                        disabled={props.isLoading}
                      >
                        {emoji.value}
                      </Popover.Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!props.filteredEmojis && (
              <ul className="h-96 overflow-y-auto px-2">
                {props.categories
                  .filter((category) =>
                    props.activeCategory !== "search"
                      ? category.key === props.activeCategory
                      : category.key !== "search"
                  )
                  .map((category) => (
                    <li
                      key={`category-${category.key}`}
                      ref={
                        category.key !== "search"
                          ? props.categoryRefs[category.key]
                          : undefined
                      }
                    >
                      <h3 className="my-1 px-1 text-sm font-semibold capitalize">
                        {props.t(`translation:reaction:${category.key}`)}
                      </h3>
                      <ul className="relative grid grid-cols-8">
                        {category.emojis.map((emoji) => (
                          <li key={`emoji-${emoji.key}`}>
                            <Popover.Button
                              type="button"
                              onClick={() => props.toggleReaction(emoji.key)}
                              className={clsx(
                                "h-8 w-8 rounded p-1 text-xl hover:bg-zinc-100",
                                props.isLoading && "cursor-wait"
                              )}
                              disabled={props.isLoading}
                              data-tooltip-id="confetti"
                              data-tooltip-content={emoji.key}
                            >
                              {emoji.value}
                            </Popover.Button>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
};
