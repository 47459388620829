/**
 *
 *
 *
 * pluralize
 *
 *
 */
export const pluralize = (word: string, pluralWord: string, count: number) =>
  count ? (count === 1 ? word : pluralWord) : word;
