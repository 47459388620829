import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonAnchor } from "../ButtonAnchorV3";

export const FallbackComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="p-8">
      <p className="text-lg font-bold text-zinc-900 sm:text-xl">
        {t("translation:toast:something_went_wrong")}
      </p>
      <p className="mb-4 text-base font-normal text-zinc-500">
        {t("translation:toast:unexpected_error")}
      </p>
      <div className="flex">
        <ButtonAnchor href="/" size="md" variant="contained">
          {t("translation:common:refresh")}
        </ButtonAnchor>
      </div>
    </div>
  );
};
