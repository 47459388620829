/**
 *
 *
 * <SelectDropdown />
 *
 *
 */
import { Combobox, Transition } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRef } from "react";

import { InputError } from "../InputError";
import { Label } from "../LabelV3";
import { Spinner } from "../Spinner";

interface Props {
  setValue: (id: number | string) => void;
  isLoading: boolean;
  name: string;
  label?: string;
  options: Option[];
  setSearchTerm?: (searchTerm: string) => void;
  placeholder?: string;
  defaultValue?: Option;
  error?: string;
}

interface Option {
  id: number | string;
  name: string;
  avatar?: string;
}

export const SelectDropdown = (props: Props) => {
  const comboBtn = useRef<HTMLButtonElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const onSearchInputFocus = () => {
    comboBtn.current?.click();
    if (parentRef.current) {
      parentRef.current.style.zIndex = "1000";
    }
  };
  const onLostFocus = () => {
    if (parentRef.current) {
      parentRef.current.style.zIndex = "1";
    }
  };

  return (
    <Combobox
      name={props.name}
      by="id"
      defaultValue={props.defaultValue}
      onChange={(option) => props.setValue(option.id)}
    >
      <div
        ref={parentRef}
        className="relative mt-2 cursor-pointer space-y-2 sm:grid sm:grid-cols-3 sm:items-center sm:gap-3 sm:space-y-0"
      >
        {props.label && (
          <Label variant="combobox" htmlFor={props.name}>
            {props.label}
          </Label>
        )}
        <div className={props.label ? "col-span-2" : "col-span-3"}>
          <Combobox.Button className="hidden" ref={comboBtn}></Combobox.Button>
          <div className="relative flex items-center justify-end">
            <Combobox.Input
              className={clsx(
                "block w-full rounded-md border-0",
                "ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-500",
                "focus:ring-2 focus:ring-inset focus:ring-zinc-300",
                "tracking-wide sm:text-sm sm:leading-6",
                props.error
                  ? "ring-red-500 focus:ring-red-500"
                  : "ring-zinc-300"
              )}
              displayValue={(option: Option) => option.name}
              onChange={(e) => {
                props.setSearchTerm && props.setSearchTerm(e.target.value);
              }}
              placeholder={props.placeholder}
              onClick={onSearchInputFocus}
              name={props.name}
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-zinc-400"
                aria-hidden="true"
              />
            </div>
            {props.isLoading && <Spinner className="absolute mr-2" />}
          </div>

          {props.error && <InputError>{props.error}</InputError>}

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            afterLeave={onLostFocus}
          >
            <Combobox.Options
              className={clsx(
                "absolute z-40 mt-1 max-h-56",
                "w-full overflow-auto rounded-md bg-white py-1",
                "border border-zinc-300 text-sm focus:outline-none"
              )}
            >
              {props.options.map((option) => (
                <Combobox.Option
                  key={option.id}
                  value={option}
                  className={clsx(
                    "text-zinc-900 hover:bg-zinc-100 ui-active:bg-zinc-100",
                    "cursor-pointer p-2"
                  )}
                >
                  {option.name}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </div>
    </Combobox>
  );
};
