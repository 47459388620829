/**
 *
 *
 * PostModalLauncher
 *
 *
 */
import { LinkIcon, MegaphoneIcon, PhotoIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { SearchParam } from "../../consts";
import { Feature as PostModalFeature } from "../PostModal/usePostModal";

interface Props {
  className?: string;
  openPostModal: (feature?: PostModalFeature) => void;
}

export const PostModalLauncher = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "tour-social-feed flex items-center gap-2",
        props.className
      )}
    >
      <div className="relative min-w-0 flex-1">
        <button
          type="button"
          className={clsx(
            "relative w-full rounded-full",
            "h-10 px-4 text-left text-sm font-semibold text-zinc-300",
            "border border-zinc-300"
          )}
          onClick={() => props.openPostModal()}
        >
          <span>{t("translation:post:add_your_thoughts")}</span>
        </button>

        <div className="absolute right-3 top-0 flex h-full items-center gap-1">
          <button
            onClick={() => props.openPostModal(SearchParam.WITH_SHOUTOUT)}
            className={clsx(
              "rounded-full p-1",
              "shadow hover:shadow-none",
              "text-white",
              "bg-fuchsia-950",
              "tour-shoutout"
            )}
            data-tooltip-id="tooltip"
            data-tooltip-content={t("translation:post:shoutout_colleague")}
            data-tooltip-place="bottom"
          >
            <MegaphoneIcon className="h-4 w-4" />
          </button>

          <button
            onClick={() => props.openPostModal(SearchParam.WITH_IMAGE)}
            className={clsx(
              "rounded-full p-1",
              "text-white",
              "shadow hover:shadow-none",
              "bg-green-950"
            )}
            data-tooltip-id="tooltip"
            data-tooltip-content={t("translation:post:post_image")}
            data-tooltip-place="bottom"
          >
            <PhotoIcon className="h-4 w-4" />
          </button>

          <button
            onClick={() => props.openPostModal(SearchParam.WITH_URL)}
            className={clsx(
              "rounded-full p-1",
              "text-white",
              "shadow hover:shadow-none",
              "bg-blue-950"
            )}
            data-tooltip-id="tooltip"
            data-tooltip-content={t("translation:post:post_link_website")}
            data-tooltip-place="bottom"
          >
            <LinkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};
