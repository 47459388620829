/**
 *
 *
 * <Billing />
 *
 *
 */
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Skeleton } from "./Skeleton";
import { useBilling } from "./useBilling";

export const Billing = (props: ReturnType<typeof useBilling>) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="border-b border-b-zinc-300 px-8 py-6 text-lg text-zinc-500">
        {t("translation:settings:menu:billing")}
      </div>
      <div className="animate-crossfadein">
        <div className="px-8 py-12">
          {props.isLoading ? (
            <Skeleton />
          ) : (
            props.billingInfo && (
              <>
                <div className="mb-3 space-y-1">
                  <p className="text-sm font-medium text-zinc-900">
                    {t("translation:settings:billing:current_subscription")}
                  </p>
                  <p className="text-sm font-normal text-zinc-900">
                    {t("translation:settings:billing:engage_monthly")}
                  </p>
                  <p className="text-sm font-normal text-zinc-500">
                    {t("translation:settings:billing:price_seats", {
                      price: props.billingInfo?.subscriptionPrice,
                      seats: props.billingInfo?.seatsInUse,
                    })}
                  </p>
                  <p className="text-sm font-normal text-zinc-500">
                    {t("translation:settings:billing:renews")}{" "}
                    {dayjs(props.billingInfo?.updatedAt).format("MMMM D, YYYY")}
                  </p>
                </div>
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <Button
                    variant="outlined"
                    size="md"
                    className="flex-0"
                    onClick={props.onManage}
                  >
                    {t("translation:settings:billing:manage_payments")}
                    <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                  </Button>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};
