/**
 *
 *
 * <TimezoneForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { InputError } from "../../components/InputError";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { getTranslation } from "../../utils/translation";
import { TimezoneSelect } from "../TimezoneSelectV3";
import { useTimezoneForm } from "./useTimezoneForm";

export const TimezoneForm = (props: ReturnType<typeof useTimezoneForm>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:user_guide:save_timezone");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="space-y-6">
          <SkeletonInputGroup />
          <Button variant="skeleton" size="md" className="w-full sm:w-auto">
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <form onSubmit={props.onHandleSubmit} className="relative">
          <input
            type="hidden"
            id="timezone"
            {...props.form.register("timezone")}
          />

          <TimezoneSelect
            setValue={(tz) => {
              props.form.setValue("timezone", tz || "", {
                shouldValidate: true,
              });
            }}
            defaultValue={props.me?.timezone?.timezone}
            className="mt-2"
          />

          {props.form.formState.errors.timezone?.message && (
            <InputError>
              {getTranslation(t, props.form.formState.errors.timezone?.message)}
            </InputError>
          )}

          <Button
            size="md"
            variant="contained"
            type="submit"
            disabled={
              props.me?.timezone?.timezone === props.timezone || !props.timezone
            }
            className="mt-6 w-full sm:w-auto"
          >
            {submitButtonText}
          </Button>
        </form>
      )}
    </div>
  );
};
