/**
 *
 *
 * <Icon />
 *
 *
 */
import clsx from "clsx";
import { NavItemV3 } from "types";

interface Props {
  item: NavItemV3;
  isCollapsed?: boolean;
  isActive?: boolean;
}

export const Icon = (props: Props) => {
  return props.item.icon ? (
    <props.item.icon
      className={clsx(
        props.isCollapsed
          ? "h-8 w-8 group-hover/nav-item:text-zinc-500"
          : "h-5 w-5 group-hover/nav-item:text-zinc-500",
        props.isCollapsed
          ? props.isActive
            ? "text-zinc-500"
            : "text-zinc-500"
          : props.isActive
            ? "text-zinc-500"
            : "text-zinc-300"
      )}
    />
  ) : (
    <div className="w-5" />
  );
};
