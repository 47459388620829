import { clsx } from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/Button";
import { IconCoffeeCup } from "../../components/IconCoffeeCup";
import { Modal } from "../../components/Modal";
import { useCoffeeChatModal } from "./useCoffeeChatModal";

export const CoffeeChatModal = (
  props: ReturnType<typeof useCoffeeChatModal>
) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      className={clsx("w-screen max-w-lg overflow-hidden rounded-xl bg-white")}
    >
      <div className="absolute right-0 top-0 w-[80%]">
        <IconCoffeeCup />
      </div>
      <div className="z-1 relative">
        <div className="border-b border-slate-200 p-4">
          <h2
            className={clsx(
              "flex items-center gap-x-2 leading-6",
              "text-lg font-medium leading-loose tracking-tight"
            )}
          >
            {t("translation:coffeechat:title")}
          </h2>
        </div>
        <div className="p-4">
          <div className="mb-1 text-sm font-medium text-black">
            {t("translation:coffeechat:interest")}
          </div>
          <div className="text-sm leading-6 text-slate-600">
            {t("translation:coffeechat:enter_your_coffeechat_topic")}
          </div>
          <form onSubmit={props.onHandleSubmit}>
            <div className="mt-2">
              <textarea
                className={clsx(
                  "focus:border-amber-500",
                  "leading-6 tracking-wide",
                  "placeholder:text-slate-400 ",
                  "border-0 shadow",
                  "bg-white text-sm text-slate-500",
                  "w-full px-3 py-1.5",
                  "rounded-lg",
                  "ring-1 ring-slate-300 focus:ring-amber-500"
                )}
                rows={5}
                {...props.form.register("answer")}
              />
            </div>
            <div className="mt-2 flex flex-row items-center justify-end gap-4">
              <Button
                variant="outlined"
                size="md"
                dark
                onClick={props.onCancel}
              >
                {t("translation:coffeechat:no_thanks")}
              </Button>
              <Button variant="highlight" size="md" dark type="submit">
                <span className="w-4">
                  <IconCoffeeCup />
                </span>
                {t("translation:coffeechat:match_me")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
