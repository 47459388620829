/**
 *
 *
 * <QAndA />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  PeopleRetrieve,
  QuestionModel,
} from "../../services/teambuilder/schemas";
import { firstName } from "../../utils/username";
import { QAndAForm } from "../QAndAFormV3";
import { QAndAEmpty } from "./QAndAEmpty";
import { Section } from "./Section";

interface Props {
  user: PeopleRetrieve;
  userIsMe: boolean;
  answeredQuestions: QuestionModel[];
  isQAFormOpen: boolean;
  closeQAForm: () => void;
  openQAForm: () => void;
}

export const QAndA = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t("translation:user_guide:q_and_a")}
      onEditClick={props.openQAForm}
      showEditButton={props.userIsMe}
    >
      {props.isQAFormOpen && props.user?.id ? (
        <QAndAForm userId={props.user.id} onCancel={props.closeQAForm} />
      ) : (
        <>
          {props.answeredQuestions.length > 0 ? (
            <ul className="space-y-12">
              {props.answeredQuestions?.map((q) => (
                <li key={Math.random()} className="text-sm">
                  <dl className="space-y-4">
                    <div>
                      <dt className="sr-only">Question</dt>
                      <dd className={clsx("text-zinc-500")}>{q.question}</dd>
                    </div>

                    <div>
                      <dt className="sr-only">Answer</dt>
                      <dd
                        className={clsx(
                          "text-zinc-900",
                          "border-l-4 border-zinc-300 py-2 pl-4"
                        )}
                      >
                        {q?.answers?.[0]?.answer}
                      </dd>
                    </div>
                  </dl>
                </li>
              ))}
            </ul>
          ) : props.userIsMe ? (
            <QAndAEmpty openAnswersForm={props.openQAForm} />
          ) : (
            <div className="flex items-center text-sm text-zinc-500">
              {t("translation:user_guide:name_no_answers", {
                name: firstName(props.user),
              })}
            </div>
          )}
        </>
      )}
    </Section>
  );
};
