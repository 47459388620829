/**
 *
 *
 * <Integrations />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { ModalConfirmation } from "../../components/ModalConfirmation";
import { Integration } from "../../containers/IntegrationV3";
import { Skeleton } from "./Skeleton";
import { useIntegrations } from "./useIntegrations";

export const Integrations = (props: ReturnType<typeof useIntegrations>) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="animate-crossfadein">
        {props.isLoading ? (
          <Skeleton />
        ) : props.isError ? (
          <>{t("translation:toast:unable_load_integrations")}</>
        ) : (
          <div className="flex flex-wrap gap-8">
            {props.formattedIntegrationList.map((integration, i) => (
              <Integration
                key={i}
                index={i}
                integration={integration}
                onButtonClick={props.handleDisconnect}
                channelId={props.channelId || ""}
                isLoading={props.destroyInProgress && props.loadingIndex === i}
              />
            ))}
          </div>
        )}
      </div>

      <ModalConfirmation
        isOpen={props.isDisconnectConfirmationModalOpen}
        title={t("translation:settings:integrations:disconnect_integration")}
        cancelButtonTitle={t("translation:common:cancel")}
        confirmButtonTitle={t("translation:common:disconnect")}
        onCancel={() => props.setIsDisconnectConfirmationModalOpen(false)}
        onConfirm={() => {}}
        description={t(
          "translation:settings:integrations:disconnect_integration_description"
        )}
      />
    </>
  );
};
