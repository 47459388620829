/**
 *
 *
 * <Label />
 *
 *
 */
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import React, { useMemo } from "react";

interface Props {
  children: React.ReactNode;
  htmlFor: string;
  // Change wrapper element.
  variant?: "combobox";
  className?: string;
}

export const Label = (props: Props) => {
  const classNames = useMemo(
    () =>
      clsx(
        "block text-sm font-medium leading-6 text-zinc-900",
        props.className
      ),
    [props.className]
  );

  return props.variant === "combobox" ? (
    <Combobox.Label className={classNames} htmlFor={props.htmlFor}>
      {props.children}
    </Combobox.Label>
  ) : (
    <label htmlFor={props.htmlFor} className={classNames}>
      {props.children}
    </label>
  );
};
