/**
 *
 *
 * <Header />
 *
 *
 */
import { useTranslation } from "react-i18next";

import { HomeChallengeSectionKey } from "./useHomeChallenge";

interface Props {
  section: HomeChallengeSectionKey;
  name?: string;
  count: number;
}

export const Header = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.section === "no_challenge" ? (
        <>
          <p className="mb-2 text-2xl font-bold leading-8 text-zinc-900">
            {t("translation:challenge:no_challenge_home_title", {
              name: props.name,
            })}
          </p>
          <p className="text-sm font-normal leading-5 text-zinc-500">
            {t("translation:challenge:no_challenge_home_desc")}
          </p>
        </>
      ) : (
        <>
          <p className="mb-0.5 text-2xl font-bold leading-8 text-zinc-900">
            {t(`translation:challenge:${props.section}_challenge_home_title`)}
          </p>
          <p className="text-sm font-normal leading-5 text-zinc-500">
            {t(
              `translation:challenge:${props.section}_challenges_description${props.count > 1 ? (props.section === "templates" ? "" : "s") : ""}`
            )}
          </p>
        </>
      )}
    </div>
  );
};
