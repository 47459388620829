/**
 *
 *
 * <AvatarMenu />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { TeamBuilderLogo } from "../../components/TeamBuilderLogoV3";
import { MenuButtonContent } from "./MenuButtonContent";
import { useAvatarMenu } from "./useAvatarMenu";

export const AvatarMenu = (props: ReturnType<typeof useAvatarMenu>) => {
  return (
    <DropdownMenu
      classNames="flex"
      header={
        <div className="border-b border-zinc-300 p-1">
          <TeamBuilderLogo className="h-[66px] w-[232px]" />
        </div>
      }
      menuButtonContent={(open) => (
        <MenuButtonContent
          open={open}
          isCollapsed={props.isCollapsed}
          isLoadingMe={props.isLoadingMe}
          me={props.me}
        />
      )}
      menuButtonClassNames={(open) =>
        clsx(
          "flex w-full items-start",
          "cursor-pointer rounded-md px-3 py-2 hover:bg-zinc-100",
          open && "bg-zinc-100",
          "group/nav-item",
          props.isCollapsed ? "items-start gap-0" : "items-stretch gap-3"
        )
      }
      menuClassNames={clsx(
        "mb-2 origin-bottom-left",
        props.isCollapsed ? "bottom-12 left-0" : "bottom-14"
      )}
      items={props.navItems.secondary.map((item) => ({
        ...item,
        onClick: () => {
          props.onClick?.();
          item.onClick && item.onClick();
        },
      }))}
    />
  );
};
