/**
 *
 *
 * <ChallengeWizardDates />
 *
 *
 */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeCreateProgress } from "../../components/ChallengeCreateProgress";
import { Input } from "../../components/InputV3";
import { useLayoutContext } from "../../components/LayoutProvider";
import { getTranslation } from "../../utils/translation";
import { useChallengeWizardDates } from "./useChallengeWizardDates";

export const ChallengeWizardDates = (
  props: ReturnType<typeof useChallengeWizardDates>
) => {
  const { t } = useTranslation();
  const { isMobileMode } = useLayoutContext();

  return (
    <form className="grid grid-rows-[auto_1fr_auto] overflow-hidden">
      <ChallengeCreateProgress
        title={t("translation:challenge:select_date")}
        total={5}
        current={4}
      />
      <div className="bg-white px-8">
        <div className="flex flex-col gap-4">
          <div
            className={clsx(
              "flex w-full flex-col flex-wrap items-start justify-normal gap-4",
              !isMobileMode && "sm:flex-row sm:justify-between"
            )}
          >
            <div
              className={clsx(
                "w-full",
                !isMobileMode && "sm:w-auto sm:flex-grow"
              )}
            >
              <Input
                id="startAt"
                label={t("translation:challenge:start_time")}
                type="datetime-local"
                className="rounded-lg"
                error={getTranslation(t, props.errors.startAt?.message)}
                {...props.register("startAt")}
                min={dayjs().format("YYYY-MM-DDT00:00")}
                disabled={props.isLoading}
                value={
                  props.startAt
                    ? dayjs(props.startAt).format("YYYY-MM-DDTHH:mm")
                    : ""
                }
              />
            </div>
            <span
              className={clsx(
                "hidden items-center font-medium text-slate-700",
                !isMobileMode && "sm:flex sm:w-auto sm:pt-10"
              )}
            >
              to
            </span>
            <div
              className={clsx(
                "w-full",
                !isMobileMode && "sm:w-auto sm:flex-grow"
              )}
            >
              <Input
                id="startAt"
                label={t("translation:challenge:end_time")}
                type="datetime-local"
                className="rounded-lg"
                error={getTranslation(t, props.errors.endAt?.message)}
                {...props.register("endAt")}
                min={dayjs(props.startAt || new Date()).format(
                  "YYYY-MM-DDT00:00"
                )}
                disabled={props.isLoading}
                value={
                  props.endAt
                    ? dayjs(props.endAt).format("YYYY-MM-DDTHH:mm")
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end gap-x-4 bg-white px-4 py-4 lg:px-8">
        <Button
          variant="text"
          size="md"
          onClick={props.onCancel}
          disabled={props.isLoading}
        >
          {t("translation:common:cancel")}
        </Button>
        <Button
          variant="outlined"
          size="md"
          onClick={props.onBack}
          disabled={props.isLoading}
        >
          <ChevronLeftIcon className="h-5 text-zinc-900" />{" "}
          {t("translation:common:back")}
        </Button>
        <Button
          variant="challenge"
          size="md"
          disabled={props.isLoading || !props.startAt || !props.endAt}
          onClick={props.onNext}
        >
          {t("translation:challenge:save_continue")}{" "}
          <ChevronRightIcon className="h-5 text-white" />
        </Button>
      </div>
    </form>
  );
};
// Lazy load requires a default export
export default ChallengeWizardDates;
