/**
 *
 *
 * <ProofItem />
 *
 *
 */
import clsx from "clsx";
import React, { forwardRef, useRef } from "react";

import { Radio } from "../../components/Radio";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title: string;
  description: string;
  isDisabled?: boolean | null;
  className?: string;
  circleBackground: string;
  icon: React.ReactElement;
}

export const ProofItem = forwardRef<HTMLInputElement, Props>(
  ({ className = "", isDisabled, circleBackground, icon, ...props }, ref) => {
    const parentRef = useRef<HTMLDivElement>(null);
    return (
      <div
        className={clsx(
          "relative cursor-pointer overflow-hidden rounded-md border-2 p-2",
          isDisabled && "pointer-events-none opacity-60",
          className
        )}
        onClick={() =>
          (parentRef.current?.firstChild as HTMLInputElement).click()
        }
      >
        <div
          className={clsx(
            "absolute -right-4 top-1/2 -translate-y-1/2",
            "h-32 w-32 rounded-full opacity-15",
            "flex items-center justify-center",
            circleBackground
          )}
        >
          {icon}
        </div>
        <div ref={parentRef} className="relative z-10">
          <Radio label={props.title} {...props} ref={ref} />
          <p className="ml-6 text-sm font-normal leading-6">
            {props.description}
          </p>
        </div>
      </div>
    );
  }
);

ProofItem.displayName = "ProofItem";
