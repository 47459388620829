/**
 *
 *
 * <SlideOverFormTextareaGroup />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { SlideOverFormGroup } from "../SlideOverFormGroup";

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  id: string;
  label: string;
  rows?: number;
  errorMessage?: string;
}

/**
 * A textarea with a label to the left.
 * Useful for including in SlideOverForm.
 *
 * @example
 * <SlideOver ... >
 *   <SlideOverHeader ... />
 *   <SlideOverForm ... >
 *     <SlideOverFormTextareaGroup ... />
 *   </SlideOverForm>
 * </SlideOver>
 */
const SlideOverFormTextareaGroup = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    { id, label, rows = 5, errorMessage, className = "", ...props }: Props,
    ref
  ) => {
    return (
      <SlideOverFormGroup
        label={label}
        htmlFor={id}
        className={className}
        errorMessage={errorMessage}
      >
        <textarea
          id={id}
          className={clsx(
            "block w-full rounded-md border border-zinc-300",
            "shadow-sm focus:border-zinc-300 focus:ring-zinc-300 sm:text-sm"
          )}
          ref={ref}
          rows={rows}
          {...props}
        />
      </SlideOverFormGroup>
    );
  }
);

SlideOverFormTextareaGroup.displayName = "SlidOverFormInputGroup";

export { SlideOverFormTextareaGroup };
