/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  InvitationFile,
  InvitationFileRequest,
  InvitationRequest,
  PaginatedInvitationFileList,
  PaginatedInvitationSerializerResponseList,
  PaginatedPeopleResponseList,
  PatchedPeopleRequestRequest,
  PeopleBulkInviteExampleListParams,
  PeopleBulkInviteListParams,
  PeopleInvitationsListParams,
  PeopleListParams,
  PeopleMe,
  PeopleRetrieve,
  SendInvitationRequest,
  _InvitationSerializerResponse,
  _PeopleResponse,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # People, People, People

This is an expanded Users endpoint providing data for admin/people UI in a single GET request.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const peopleList = (
  params?: PeopleListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPeopleResponseList>(
    { url: `/api/v1/people/`, method: "GET", params, signal },
    options
  );
};

export const getPeopleListQueryKey = (params?: PeopleListParams) => {
  return [`/api/v1/people/`, ...(params ? [params] : [])] as const;
};

export const getPeopleListQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPeopleListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof peopleList>>> = ({
    signal,
  }) => peopleList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleListQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleList>>
>;
export type PeopleListQueryError = ErrorType<unknown>;

export const usePeopleList = <
  TData = Awaited<ReturnType<typeof peopleList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # People, People, People

This is an expanded Users endpoint providing data for admin/people UI in a single GET request.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const peopleRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PeopleRetrieve>(
    { url: `/api/v1/people/${id}/`, method: "GET", signal },
    options
  );
};

export const getPeopleRetrieveQueryKey = (id: number) => {
  return [`/api/v1/people/${id}/`] as const;
};

export const getPeopleRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPeopleRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof peopleRetrieve>>> = ({
    signal,
  }) => peopleRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleRetrieve>>
>;
export type PeopleRetrieveQueryError = ErrorType<unknown>;

export const usePeopleRetrieve = <
  TData = Awaited<ReturnType<typeof peopleRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # People, People, People

This is an expanded Users endpoint providing data for admin/people UI in a single GET request.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const peoplePartialUpdate = (
  id: number,
  patchedPeopleRequestRequest: PatchedPeopleRequestRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<_PeopleResponse>(
    {
      url: `/api/v1/people/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPeopleRequestRequest,
    },
    options
  );
};

export const getPeoplePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peoplePartialUpdate>>,
    TError,
    { id: number; data: PatchedPeopleRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peoplePartialUpdate>>,
  TError,
  { id: number; data: PatchedPeopleRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peoplePartialUpdate>>,
    { id: number; data: PatchedPeopleRequestRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return peoplePartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeoplePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof peoplePartialUpdate>>
>;
export type PeoplePartialUpdateMutationBody = PatchedPeopleRequestRequest;
export type PeoplePartialUpdateMutationError = ErrorType<unknown>;

export const usePeoplePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peoplePartialUpdate>>,
    TError,
    { id: number; data: PatchedPeopleRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getPeoplePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # People, People, People

This is an expanded Users endpoint providing data for admin/people UI in a single GET request.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const peopleDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/people/${id}/`, method: "DELETE" },
    options
  );
};

export const getPeopleDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peopleDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peopleDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return peopleDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeopleDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof peopleDestroy>>
>;

export type PeopleDestroyMutationError = ErrorType<unknown>;

export const usePeopleDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getPeopleDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Bulk Invite

Upload via POST multipart a CSV file of data to invite to the platform. "file" is the only required parameter.

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleBulkInviteList = (
  params?: PeopleBulkInviteListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedInvitationFileList>(
    { url: `/api/v1/people/bulk-invite/`, method: "GET", params, signal },
    options
  );
};

export const getPeopleBulkInviteListQueryKey = (
  params?: PeopleBulkInviteListParams
) => {
  return [`/api/v1/people/bulk-invite/`, ...(params ? [params] : [])] as const;
};

export const getPeopleBulkInviteListQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleBulkInviteListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleBulkInviteListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleBulkInviteList>>
  > = ({ signal }) => peopleBulkInviteList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleBulkInviteListQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleBulkInviteList>>
>;
export type PeopleBulkInviteListQueryError = ErrorType<unknown>;

export const usePeopleBulkInviteList = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleBulkInviteListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleBulkInviteListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Upload via POST multipart a CSV file of data to invite to the platform.
 */
export const peopleBulkInviteCreate = (
  invitationFileRequest: InvitationFileRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  formData.append("file", invitationFileRequest.file);

  return axiosInstance<InvitationFile>(
    {
      url: `/api/v1/people/bulk-invite/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getPeopleBulkInviteCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleBulkInviteCreate>>,
    TError,
    { data: InvitationFileRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peopleBulkInviteCreate>>,
  TError,
  { data: InvitationFileRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peopleBulkInviteCreate>>,
    { data: InvitationFileRequest }
  > = (props) => {
    const { data } = props ?? {};

    return peopleBulkInviteCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeopleBulkInviteCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof peopleBulkInviteCreate>>
>;
export type PeopleBulkInviteCreateMutationBody = InvitationFileRequest;
export type PeopleBulkInviteCreateMutationError = ErrorType<unknown>;

export const usePeopleBulkInviteCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleBulkInviteCreate>>,
    TError,
    { data: InvitationFileRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getPeopleBulkInviteCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Provide an example upload file for bulk invites
 */
export const peopleBulkInviteExampleList = (
  params?: PeopleBulkInviteExampleListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/people/bulk-invite-example/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getPeopleBulkInviteExampleListQueryKey = (
  params?: PeopleBulkInviteExampleListParams
) => {
  return [
    `/api/v1/people/bulk-invite-example/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getPeopleBulkInviteExampleListQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteExampleList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleBulkInviteExampleListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteExampleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleBulkInviteExampleListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleBulkInviteExampleList>>
  > = ({ signal }) =>
    peopleBulkInviteExampleList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteExampleList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleBulkInviteExampleListQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleBulkInviteExampleList>>
>;
export type PeopleBulkInviteExampleListQueryError = ErrorType<unknown>;

export const usePeopleBulkInviteExampleList = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteExampleList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleBulkInviteExampleListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteExampleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleBulkInviteExampleListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Bulk Invite

Upload via POST multipart a CSV file of data to invite to the platform. "file" is the only required parameter.

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleBulkInviteRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<InvitationFile>(
    { url: `/api/v1/people/bulk-invite/${id}/`, method: "GET", signal },
    options
  );
};

export const getPeopleBulkInviteRetrieveQueryKey = (id: number) => {
  return [`/api/v1/people/bulk-invite/${id}/`] as const;
};

export const getPeopleBulkInviteRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleBulkInviteRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>
  > = ({ signal }) => peopleBulkInviteRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleBulkInviteRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>
>;
export type PeopleBulkInviteRetrieveQueryError = ErrorType<unknown>;

export const usePeopleBulkInviteRetrieve = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleBulkInviteRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleBulkInviteRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * All bulk invitation files uploaded by the current user.
 */
export const peopleBulkInviteMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<InvitationFile>(
    { url: `/api/v1/people/bulk-invite/me/`, method: "GET", signal },
    options
  );
};

export const getPeopleBulkInviteMeRetrieveQueryKey = () => {
  return [`/api/v1/people/bulk-invite/me/`] as const;
};

export const getPeopleBulkInviteMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleBulkInviteMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>
  > = ({ signal }) => peopleBulkInviteMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleBulkInviteMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>
>;
export type PeopleBulkInviteMeRetrieveQueryError = ErrorType<unknown>;

export const usePeopleBulkInviteMeRetrieve = <
  TData = Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof peopleBulkInviteMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleBulkInviteMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Invitations

Invite a user to the platform

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleInvitationsList = (
  params?: PeopleInvitationsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedInvitationSerializerResponseList>(
    { url: `/api/v1/people/invitations/`, method: "GET", params, signal },
    options
  );
};

export const getPeopleInvitationsListQueryKey = (
  params?: PeopleInvitationsListParams
) => {
  return [`/api/v1/people/invitations/`, ...(params ? [params] : [])] as const;
};

export const getPeopleInvitationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleInvitationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleInvitationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleInvitationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleInvitationsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleInvitationsList>>
  > = ({ signal }) => peopleInvitationsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleInvitationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleInvitationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleInvitationsList>>
>;
export type PeopleInvitationsListQueryError = ErrorType<unknown>;

export const usePeopleInvitationsList = <
  TData = Awaited<ReturnType<typeof peopleInvitationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: PeopleInvitationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleInvitationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleInvitationsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Invitations

Invite a user to the platform

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleInvitationsCreate = (
  invitationRequest: InvitationRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<_InvitationSerializerResponse>(
    {
      url: `/api/v1/people/invitations/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: invitationRequest,
    },
    options
  );
};

export const getPeopleInvitationsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleInvitationsCreate>>,
    TError,
    { data: InvitationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peopleInvitationsCreate>>,
  TError,
  { data: InvitationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peopleInvitationsCreate>>,
    { data: InvitationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return peopleInvitationsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeopleInvitationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof peopleInvitationsCreate>>
>;
export type PeopleInvitationsCreateMutationBody = InvitationRequest;
export type PeopleInvitationsCreateMutationError = ErrorType<unknown>;

export const usePeopleInvitationsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleInvitationsCreate>>,
    TError,
    { data: InvitationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getPeopleInvitationsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Invitations

Invite a user to the platform

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleInvitationsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<_InvitationSerializerResponse>(
    { url: `/api/v1/people/invitations/${id}/`, method: "GET", signal },
    options
  );
};

export const getPeopleInvitationsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/people/invitations/${id}/`] as const;
};

export const getPeopleInvitationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleInvitationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleInvitationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPeopleInvitationsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleInvitationsRetrieve>>
  > = ({ signal }) => peopleInvitationsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleInvitationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleInvitationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleInvitationsRetrieve>>
>;
export type PeopleInvitationsRetrieveQueryError = ErrorType<unknown>;

export const usePeopleInvitationsRetrieve = <
  TData = Awaited<ReturnType<typeof peopleInvitationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof peopleInvitationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleInvitationsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Invitations

Invite a user to the platform

## Permissions

```
permission_classes = [iperm.HasRolePermission]
required_roles = ["HR", "IT"]
```
 */
export const peopleInvitationsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/people/invitations/${id}/`, method: "DELETE" },
    options
  );
};

export const getPeopleInvitationsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleInvitationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peopleInvitationsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peopleInvitationsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return peopleInvitationsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeopleInvitationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof peopleInvitationsDestroy>>
>;

export type PeopleInvitationsDestroyMutationError = ErrorType<unknown>;

export const usePeopleInvitationsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleInvitationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getPeopleInvitationsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get the current user.
 */
export const peopleMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PeopleMe>(
    { url: `/api/v1/people/me/`, method: "GET", signal },
    options
  );
};

export const getPeopleMeRetrieveQueryKey = () => {
  return [`/api/v1/people/me/`] as const;
};

export const getPeopleMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof peopleMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof peopleMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPeopleMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof peopleMeRetrieve>>
  > = ({ signal }) => peopleMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof peopleMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PeopleMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof peopleMeRetrieve>>
>;
export type PeopleMeRetrieveQueryError = ErrorType<unknown>;

export const usePeopleMeRetrieve = <
  TData = Awaited<ReturnType<typeof peopleMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof peopleMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPeopleMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Provide a list of User ID
 */
export const peopleSendInvitationsCreate = (
  sendInvitationRequest: SendInvitationRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/people/send_invitations/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: sendInvitationRequest,
    },
    options
  );
};

export const getPeopleSendInvitationsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleSendInvitationsCreate>>,
    TError,
    { data: SendInvitationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof peopleSendInvitationsCreate>>,
  TError,
  { data: SendInvitationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof peopleSendInvitationsCreate>>,
    { data: SendInvitationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return peopleSendInvitationsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PeopleSendInvitationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof peopleSendInvitationsCreate>>
>;
export type PeopleSendInvitationsCreateMutationBody = SendInvitationRequest;
export type PeopleSendInvitationsCreateMutationError = ErrorType<unknown>;

export const usePeopleSendInvitationsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof peopleSendInvitationsCreate>>,
    TError,
    { data: SendInvitationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getPeopleSendInvitationsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
