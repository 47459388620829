/**
 *
 *
 * useRoles
 *
 *
 */
import { usePeopleMeRetrieve } from "../services/teambuilder/endpoints/people/people";
import { RoleStatus, parseRoles } from "../utils/parse-roles";

export const useRoles = () => {
  const { data, isLoading, isSuccess } = usePeopleMeRetrieve<RoleStatus>({
    query: {
      select: ({ roles }) => parseRoles(roles),
    },
  });

  return {
    isLoadingRoles: isLoading,
    isRolesSuccess: isSuccess,
    isManager: Boolean(data?.isManager),
    isHR: Boolean(data?.isHR),
    isIT: Boolean(data?.isIT),
    // An admin is any user with elevated privileges
    isAdmin: Boolean(data?.isAdmin),
  };
};
