/**
 *
 *
 * <App />
 *
 *
 */
import {
  SplitFactoryProvider, // SplitTreatments,
} from "@splitsoftware/splitio-react";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Tooltip } from "react-tooltip";

import { AppLoader as AppLoaderV3 } from "../../components/AppLoaderV3";
import { LayoutProvider } from "../../components/LayoutProvider";
import { Toast } from "../../components/Toast";
import { ConfirmationModalProvider } from "../../hooks/useConfirmationModal";
import { PostModalContentProvider } from "../../hooks/usePostModalContent";
import isProduction from "../../utils/is-production";
import { Layout } from "../LayoutV3";
import { useApp } from "./useApp";

export const App = (props: ReturnType<typeof useApp>) => {
  return (
    // SplitFactory is used here instead of main.tsx because
    // splitConfig is updated with a company ID after company data is fetched.
    <SplitFactoryProvider config={props.splitConfig}>
      <>
        {isProduction() && (
          <Helmet>
            <script>
              {`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3464552,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
            </script>
          </Helmet>
        )}

        {props.me ? (
          // Conditionally render providers that make requests
          <LayoutProvider>
            <ConfirmationModalProvider>
              <PostModalContentProvider>
                <Layout />
              </PostModalContentProvider>
            </ConfirmationModalProvider>
          </LayoutProvider>
        ) : (
          <AppLoaderV3 error={props.isMeError} />
        )}

        <Toast {...props.toast} />

        <Tooltip
          id="tooltip"
          className="!z-50 !rounded-lg !bg-slate-600 !text-xs"
          variant="dark"
          clickable
        />
      </>
    </SplitFactoryProvider>
  );
};
