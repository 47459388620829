export const Skeleton = () => {
  return (
    <div role="status" className="animate-pulse divide-y rounded">
      <div className="mb-4 rounded-b-lg bg-white p-4 shadow-md">
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      </div>
      <div className="mb-4 rounded-b-lg bg-white p-4 shadow-md">
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      </div>
      <div className="mb-4 rounded-b-lg bg-white p-4 shadow-md">
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
        <div className="h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
