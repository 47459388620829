/**
 *
 *
 * <PostHeader />
 *
 *
 */
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar } from "../../components/AvatarV3";
import { DropdownMenu } from "../../components/DropdownMenu";
import { MenuItem } from "../../components/DropdownMenu/DropdownMenu";
import { SearchParam } from "../../consts";
import { PostType } from "../../types";
import { dayjs } from "../../utils/days";
import { AuthorLink } from "./AuthorLink";

interface Props {
  post: PostType;
  menuItems: MenuItem[];
}

export const PostHeader = (props: Props) => {
  const { t } = useTranslation();
  // Ada has random cute job titles, but make sure they're consistent for a given post.
  const aiJobTitles = [
    t("translation:post:job_title_talkie_bot"),
    t("translation:post:job_title_chatty_buddy"),
    t("translation:post:job_title_friendy_ai"),
    t("translation:post:job_title_social_bot"),
    t("translation:post:job_title_kindly_robot"),
    t("translation:post:job_title_caco"),
    t("translation:post:job_title_aws"),
    t("translation:post:job_title_pun_master"),
    t("translation:post:job_title_robot_anthropologist"),
  ];
  const adaJobTitle =
    aiJobTitles[(props.post.id as number) % aiJobTitles.length];

  return (
    <div className="flex gap-2">
      <Link
        to={`?${SearchParam.PROFILE}=${props.post.user?.id}`}
        className="block"
      >
        <Avatar size="post" user={props.post.user} />
      </Link>

      <div className="flex-1 flex-grow text-sm">
        <AuthorLink user={props.post.user} className="block" />

        <dl className="text-xs text-zinc-500">
          {/* Ada has no job title. Her ID is always 1. */}
          {props.post.user?.jobTitle ||
            (props.post.user?.id === 1 && (
              <div>
                <dt className="sr-only">Job title</dt>
                <dd>
                  {props.post.user?.id === 1
                    ? adaJobTitle
                    : props.post.user.jobTitle}
                </dd>
              </div>
            ))}
        </dl>
      </div>

      <div className="flex gap-1.5">
        <div className="text-xs text-zinc-500">
          <div className="sr-only">Posted time from now</div>
          {dayjs(props.post.createdAt).fromNow()}
        </div>

        {Boolean(props.menuItems.length) && (
          <DropdownMenu
            toggle={
              <EllipsisHorizontalIcon className="h-4 w-4 text-zinc-500" />
            }
            options={props.menuItems}
          />
        )}
      </div>
    </div>
  );
};
