/*
 *
 *
 * Shoutout
 *
 *
 */
import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { Modal } from "../../components/Modal";
import { _PeopleResponse } from "../../services/teambuilder/schemas";
import { getTranslation } from "../../utils/translation";
import { SelectUser } from "../SelectUser";
import { useShoutout } from "./useShoutout";

// translation
export const Shoutout = ({
  isOpen,
  close,
  values,
  onHandleSubmit,
  isLoadingCreateShoutout,
  register,
  setValue,
  errors,
}: ReturnType<typeof useShoutout>) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={close}
        className="max-w-md bg-slate-700 p-8 ring-4 ring-white"
      >
        <Dialog.Title
          as="h3"
          className="text-center text-2xl font-semibold leading-7 text-white sm:text-3xl"
        >
          ShoutOut
        </Dialog.Title>
        <div className="mb-4 mt-3 text-center text-sm text-slate-300">
          Celebrate your peers by sending them a shoutout! ShoutOuts appear in
          the activity feed.
        </div>
        <form onSubmit={onHandleSubmit} className="flex flex-col gap-y-4">
          {/* User select */}
          <div className="z-10">
            <label
              htmlFor="recipient"
              className="block text-sm font-medium leading-6 text-white"
            >
              Recipient
            </label>
            <input
              type="hidden"
              id="recipient"
              {...register("recipient", { valueAsNumber: true })}
            />
            <div className="mt-2">
              <SelectUser
                setValue={(user: _PeopleResponse) => {
                  setValue("recipient", user.id as number, {
                    shouldValidate: true,
                  });
                }}
                placeholder="Type to search for a colleague"
              />
            </div>
            {errors.recipient && (
              <p className={clsx("mt-2 text-sm text-red-500")}>
                {getTranslation(t, errors.recipient.message)}
              </p>
            )}
          </div>

          {/* Value select */}
          <div>
            <label
              htmlFor="value"
              className="block text-sm font-medium leading-6 text-white"
            >
              Value
            </label>
            <select
              id="value"
              className={clsx(
                "mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10",
                "text-slate-900 ring-1 ring-inset ring-slate-300 focus:ring-2 focus:ring-slate-300",
                "disabled:cursor-not-allowed sm:text-sm sm:leading-6"
              )}
              {...register("value")}
              disabled={!values?.length}
            >
              <option value=""></option>
              {values?.map((value) => (
                <option key={Math.random()} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
            <p
              className={clsx(
                "mt-2 text-sm",
                errors.value ? "text-red-500" : "text-slate-300"
              )}
            >
              {errors.value
                ? getTranslation(t, errors.value.message)
                : "Correlate your ShoutOut to a company value."}
            </p>
          </div>

          {/* Textarea */}
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium leading-6 text-white"
            >
              Message
            </label>
            <textarea
              id="message"
              {...register("message")}
              className={clsx(
                "mt-2 block w-full rounded-md border-0 text-slate-900 shadow-sm",
                "ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 ",
                "focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:py-1.5 sm:text-sm sm:leading-6"
              )}
              rows={4}
            />
            {errors.message && (
              <p
                className={clsx(
                  "mt-2 text-sm text-slate-500",
                  errors.message ? "text-red-500" : "text-slate-300"
                )}
              >
                {getTranslation(t, errors.message.message)}
              </p>
            )}
          </div>

          <div className="">
            <div className="flex items-center">
              <Checkbox
                {...register("isPrivate")}
                id="is-private"
                defaultChecked={false}
              />
              <label
                htmlFor="is-private"
                className="ml-3 text-sm font-medium leading-6 text-white"
              >
                Private
              </label>
            </div>
            <div className="text-sm text-slate-300">
              Limit visibility to you and the recipient.
            </div>
          </div>

          <div className="mt-4 flex justify-center gap-4 sm:justify-end">
            <Button
              type="button"
              variant="text"
              onClick={() => close()}
              disabled={isLoadingCreateShoutout}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="outlined"
              disabled={isLoadingCreateShoutout}
            >
              ShoutOut
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};
