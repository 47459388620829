/**
 *
 *
 * <SlideOverFormGroup />
 *
 *
 */
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { classNames } from "../../utils";

interface Props {
  label: string;
  htmlFor: string;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  errorMessage?: string;
  className?: string;
}

/**
 * Slide-over form input group with the label to the left in the
 * style of https://tailwindui.com/components/application-ui/overlays/slide-overs#component-4d1d575bd79eef81afc9a4f2e1900ecb
 *
 * Put an input in it and include it in SlideOverForm.
 *
 * @label Text label visible in the form.
 * @htmlFor ID of the input component.
 * @children Form element like <input />, <textarea />
 * @errorMessage Message to display with the input fails validation.
 * @className CSS classes.
 */
export const SlideOverFormGroup = ({
  label,
  htmlFor,
  tooltip,
  children,
  errorMessage,
  className = "",
}: Props) => {
  return (
    <div
      className={classNames(
        "space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0",
        "border-zinc-300 sm:border-b sm:px-6 sm:py-8",
        className
      )}
    >
      <div className="align-center flex items-center">
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-slate-900"
        >
          {label}
        </label>
        {tooltip && (
          <>
            <QuestionMarkCircleIcon
              className="ml-1 h-4 w-4 text-slate-500"
              data-tooltip-id="tooltip"
              data-tooltip-content={tooltip}
              data-tooltip-place="bottom"
            />
          </>
        )}
      </div>

      <div className="sm:col-span-2">
        <div className="relative mt-1 rounded-md">
          {children}

          {errorMessage && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};
