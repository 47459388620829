/**
 *
 *
 * <FormTextAreaGroup />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { classNames } from "../../utils";
import { FormGroup } from "../FormGroupV3";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "size"
  > {
  id: string;
  label?: string;
  errorMessage?: string;
  size?: string;
  rows?: number;
}

/**
 * An input with a label to the left.
 *
 * @example
 *
 *   <form ...>
 *     <FormTextAreaGroup ... />
 *   </form>
 *
 */
const FormTextAreaGroup = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    { id, label, errorMessage, className = "", size, rows, ...props }: Props,
    ref
  ) => {
    return (
      <FormGroup
        label={label}
        htmlFor={id}
        className={classNames("items-center", className)}
        errorMessage={errorMessage}
        size={size}
      >
        <textarea
          type="text"
          id={id}
          ref={ref}
          rows={rows}
          className={clsx(
            "py-3",
            "block w-full rounded-lg border-0 shadow",
            "ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400",
            "focus:ring-2 focus:ring-inset focus:ring-zinc-300",
            "tracking-wide sm:text-sm sm:leading-6"
          )}
          {...props}
        />
      </FormGroup>
    );
  }
);

FormTextAreaGroup.displayName = "FormTextAreaGroup";

export { FormTextAreaGroup };
