/**
 *
 *
 * <Skeleton />
 *
 *
 */
export const Skeleton = () => {
  return (
    <div className="animate-pulse p-6">
      <div className="mb-2 h-12 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      <div className="mb-2 h-12 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      <div className="mb-2 h-12 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
      <div className="mb-2 h-12 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
    </div>
  );
};
