/**
 *
 *
 * useMe
 *
 *
 */
import { usePeopleMeRetrieve } from "../services/teambuilder/endpoints/people/people";

export const useMe = () => {
  const {
    data: me,
    isInitialLoading: loading,
    isError: error,
    refetch,
  } = usePeopleMeRetrieve({
    query: {
      staleTime: Infinity,
    },
  });

  return { me, loading, error, refetch };
};
