/**
 *
 *
 * usePostList
 *
 *
 */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchParam, TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  getUserguidesQuestionsListQueryKey,
  useUserguidesQuestionsDestroy,
  useUserguidesQuestionsList,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  PaginatedLocationModelList,
  QuestionModel,
  UserguidesQuestionsListParams,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const useEditQuestions = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // Preserve the Question id for updates and deletes
  const [questionId, setQuestionId] = useState<number | undefined>();

  // Set the toast message before opening to prevent FOUC.
  const { openToast } = useToast();
  const showToast = (message: string) => {
    openToast({
      title: message,
    });
  };

  const params: UserguidesQuestionsListParams = {
    ordering: "-created_at",
    limit: 999,
    team: TEAM_EVERYONE_ID,
  };

  const queryKey = getUserguidesQuestionsListQueryKey(params);

  const { data: questions = [], isLoading } = useUserguidesQuestionsList(
    params,
    {
      query: {
        select: ({ data }) => data,
        onError: () => {
          showToast(t("translation:toast:location_load_error"));
        },
      },
    }
  );

  /**
   *
   * Add or Edit Question
   *
   */
  const openQuestionModal = (question?: QuestionModel) => {
    searchParams.append(
      SearchParam.SHOW_QUESTION_MODAL,
      `${question?.id || 0}`
    );
    setSearchParams(searchParams);
    setQuestionId(question && question.id);
  };

  /**
   *
   * @param questionId question's id to delete
   */
  const handleDelete = (questionId?: number) => {
    if (!questionId) showToast(t("translation:toast:location_delete_failed"));
    setQuestionId(questionId);
    setIsConfirmationModalOpen(true);
  };

  /**
   *
   * Destroy
   *
   */
  const { mutate: destroyQuestion } = useUserguidesQuestionsDestroy(
    optimisticMutationOptions<void, PaginatedLocationModelList, { id: number }>(
      {
        queryKey,
        optimisticUpdateFn: (context, requestVariable) => {
          setIsConfirmationModalOpen(false);
          return {
            meta: context.meta,
            data: context.data.filter(
              (item) => item.id !== requestVariable?.id
            ),
          };
        },
        onSuccess: () => {
          showToast(t("translation:toast:location_delete_success"));
        },
        onError: () => {
          showToast(t("translation:toast:location_delete_failed"));
        },
      }
    )
  );

  const modalOnConfirmation = () => {
    questionId && destroyQuestion({ id: questionId });
    setIsConfirmationModalOpen(false);
  };

  const onClose = () => {
    setIsConfirmationModalOpen(false);
    setQuestionId(undefined);
  };

  return {
    queryKey,
    questions,
    questionId,
    isLoading,
    modalOnConfirmation,
    handleDelete,
    onClose,
    openQuestionModal,
    isConfirmationModalOpen,
  };
};
