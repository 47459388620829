/**
 *
 *
 * PrimaryPost
 *
 * Layout for top-level posts
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar } from "../../components/AvatarV3";
import { SearchParam } from "../../consts";
import { Post } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";
import { Reactions } from "../ReactionsV3";
import { PostHeader } from "./PostHeader";
import { PrimaryPostBody } from "./PrimaryPostBody";
import { usePost } from "./usePost";

interface Props extends Omit<ReturnType<typeof usePost>, "post" | "className"> {
  post: Post;
  isDetail: boolean;
}

export const PrimaryPost = (props: Props) => {
  const { recentCommenters = [] } = props.post;
  const { t } = useTranslation();
  const childrenCount = Number(props.childrenCount);

  return (
    <div className="relative">
      {recentCommenters.length > 0 && (
        <div
          className={clsx(
            "mb-3 flex items-center border-b border-zinc-300 text-sm",
            props.isModal ? "px-4 pb-4" : "px-4 pb-4"
          )}
        >
          <div className="relative flex flex-row">
            {recentCommenters.slice(0, 3).map((user) => (
              <Link
                key={user.id}
                to={`?${SearchParam.PROFILE}=${user.id}`}
                className="block h-6 w-3"
              >
                <Avatar
                  size="commentor"
                  user={user}
                  className={clsx("border-2 border-white")}
                />
              </Link>
            ))}
          </div>

          <div className="ml-4 text-zinc-500">
            {recentCommenters.length === 1 && (
              <Trans
                i18nKey="translation:post:commented_a"
                values={{ a: fullName(recentCommenters[0]) }}
                components={{
                  a: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[0].id}`}
                      className="font-medium text-black"
                    />
                  ),
                }}
              />
            )}
            {recentCommenters.length === 2 && (
              <Trans
                i18nKey="translation:post:commented_a_b"
                values={{
                  a: recentCommenters[0].firstName,
                  b: recentCommenters[1].firstName,
                }}
                components={{
                  a: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[0].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  b: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[1].id}`}
                      className="font-medium text-black"
                    />
                  ),
                }}
              />
            )}
            {recentCommenters.length === 3 && (
              <Trans
                i18nKey="translation:post:commented_a_b_c"
                values={{
                  a: recentCommenters[0].firstName,
                  b: recentCommenters[1].firstName,
                  c: recentCommenters[2].firstName,
                }}
                components={{
                  a: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[0].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  b: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[1].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  c: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[2].id}`}
                      className="font-medium text-black"
                    />
                  ),
                }}
              />
            )}
            {recentCommenters.length > 3 && (
              <Trans
                i18nKey="translation:post:commented_a_b_c_other"
                values={{
                  a: recentCommenters[0].firstName,
                  b: recentCommenters[1].firstName,
                  c: recentCommenters[2].firstName,
                }}
                components={{
                  a: (
                    <Link
                      to={`?${SearchParam.PROFILE}==${recentCommenters[0].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  b: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[1].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  c: (
                    <Link
                      to={`?${SearchParam.PROFILE}=${recentCommenters[2].id}`}
                      className="font-medium text-black"
                    />
                  ),
                  bold: <span className="font-medium text-black" />,
                }}
              />
            )}
          </div>
        </div>
      )}

      <div className="space-y-4 px-4">
        <PostHeader post={props.post} menuItems={props.menuItems} />

        <PrimaryPostBody
          post={props.post}
          openImagePreview={props.openImagePreview}
        />
      </div>

      {/**
       *
       *
       * Footer
       *
       *
       */}
      <div className="flex justify-end gap-1.5 p-4 pl-14">
        <Reactions
          post={props.post}
          listQueryKey={props.listQueryKey}
          detailQueryKey={props.detailQueryKey}
        />

        <button
          onClick={
            childrenCount > 3 && !props.isModal
              ? () => props.onShowPostDetail(props.post)
              : props.showComments
          }
          className={clsx(
            "flex items-center gap-1 text-xs font-medium",
            "rounded-md px-1 capitalize text-zinc-500",
            (childrenCount < 4 || props.isModal) &&
              "underline hover:bg-zinc-100 hover:text-zinc-600"
          )}
        >
          {childrenCount === 1
            ? `${childrenCount} ${t("translation:comment:lc_comment")}`
            : childrenCount > 1
              ? `${childrenCount} ${t("translation:comment:lc_comments")}`
              : t("translation:comment:reply")}
        </button>
      </div>
    </div>
  );
};
