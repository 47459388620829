/**
 *
 *
 * useChallengeWizardActivities
 *
 *
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { SearchParam } from "../../consts";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  getChallengesRetrieveQueryKey,
  useChallengesActivitiesDestroy,
  useChallengesPartialUpdate,
  useChallengesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import {
  Activity,
  ActivityProofType,
  Challenge,
  PatchedChallengeRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

const schema = z
  .object({
    startAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:start_date_be_future"),
    endAt: z.coerce
      .date()
      .min(new Date(), "translation:validation:end_date_be_future"),
  })
  .refine((data) => data.startAt < data.endAt, {
    message: "translation:validation:end_date_after_start_date",
    path: ["endAt"],
  });

export type FormData = z.infer<typeof schema>;

export const useChallengeWizardActivities = () => {
  const { t } = useTranslation();
  const { isAdmin } = useRoles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const challengeId = Number(searchParams.get(SearchParam.CHALLENGE));

  const { openToast } = useToast();

  const [isNextEnabled] = useState(true);

  /**
   * Challenge requests
   */
  const queryKey = getChallengesRetrieveQueryKey(challengeId);
  const { data: challenge, isInitialLoading: isLoadingChallenge } =
    useChallengesRetrieve(
      challengeId || 0,
      {},
      {
        query: {
          enabled: challengeId > 0,
          select: (data) => data,
        },
      }
    );

  /**
   * update challenge
   */
  const { mutate: updateChallenge, isLoading: isLoadingUpdateChallenge } =
    useChallengesPartialUpdate({
      mutation: {
        onSuccess: (_data, variables) => {
          // Forward use to the challenge detail page
          openToast({ title: t("translation:toast:challenge_update_success") });
          const url = `/challenges/wizard/invite-people?${SearchParam.CHALLENGE}=${variables.id}`;
          navigate(url);
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenge_update_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * update challenge
   */
  const { mutate: deleteActivity } = useChallengesActivitiesDestroy(
    optimisticMutationOptions<
      void,
      Challenge,
      {
        id: number;
        challengesPk: number;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, variables) => {
        const filtered = activities.filter(
          (activity) => activity.id !== variables?.id
        );
        return { ...context, activities: filtered };
      },
      onSuccess: () => {
        openToast({ title: t("translation:toast:activity_delete_success") });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:activity_delete_failed"),
          type: "danger",
        });
      },
    })
  );

  /**
   * onCancel : click on Cancel Button
   */
  const onCancel = () => {
    navigate("/challenges");
  };

  /**
   * go back
   */
  const onBack = () => {
    navigate("/challenges/wizard");
  };

  /**
   * next
   */
  const onNext = async () => {
    if (challengeId > 0) {
      // If challengeId is specified, update the challenge
      const data: { id: number; data: PatchedChallengeRequest } = {
        id: challengeId,
        data: {
          // TODO: activities position update is still being failed, we will update this by other ticket
          activities: activities.map((activity, index) => ({
            availableAfter: activity.availableAfter,
            challenge: challengeId,
            description: activity.description,
            isPublished: activity.isPublished,
            linkDescription: activity.linkDescription,
            linkUrl: activity.linkUrl,
            maxPoints: activity.maxPoints,
            name: activity.name,
            points: activity.points,
            pointsFrequency: activity.pointsFrequency,
            pointsType: activity.pointsType,
            position: index + 1,
            proofType: activity.proofType || ActivityProofType.not_required,
          })),
        },
      };
      updateChallenge(data);
    }
  };

  const [activities, setActivities] = useState<Activity[]>([]);
  const onReorder = (activities: Activity[]) => {
    setActivities(
      activities.map((activity, index) => ({
        ...activity,
        position: index + 1,
      }))
    );
  };

  useEffect(() => {
    if (challenge) {
      setActivities(
        (challenge?.activities || [])
          .map((activity: Activity) => ({
            ...activity,
            position:
              (activities.find((a) => a.id === activity.id)?.position ||
                activity.position ||
                0) + 1,
          }))
          .sort((a, b) => a.position! - b.position!)
      );
    }
  }, [challenge]);

  const onAddActivity = () => {
    searchParams.append(SearchParam.ADD_ACTIVITY, "true");
    setSearchParams(searchParams);
  };

  const onEditActivity = (activityId: number) => {
    searchParams.append(SearchParam.EDIT_ACTIVITY, activityId.toString());
    setSearchParams(searchParams);
  };

  const onDeleteActivity = (activityId: number) => {
    if (challengeId && activityId) {
      deleteActivity({
        challengesPk: challengeId,
        id: activityId,
      });
    }
  };

  return {
    isAdmin,
    isNextEnabled,

    onCancel,
    onBack,
    onNext,
    onAddActivity,
    onEditActivity,
    onDeleteActivity,

    queryKey,
    template: challenge,

    isLoading: isLoadingChallenge,
    isCreating: isLoadingUpdateChallenge,

    activities,
    onReorder,
  };
};
