/**
 *
 *
 * <People />
 *
 *
 */
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Breadcrumbs } from "../../components/Breadcrumbs";
import { CopyButton } from "../../components/CopyButton";
import { ModalConfirmation } from "../../components/ModalConfirmationV3";
import { ModalImportUsers } from "../../components/ModalImportUsers";
import { Pagination } from "../../components/Pagination";
import { _PeopleResponse } from "../../services/teambuilder/schemas";
import { ViewModeEnum } from "../../utils/enums";
import { registerLink } from "../../utils/register-link";
import { PeopleForm } from "../PeopleFormV3";
import { MemberCard } from "./MemberCard";
import { MemberListItem } from "./MemberListItem";
import { SkeletonMembersCardItem } from "./SkeletonMembersCardItem";
import { SkeletonMembersListItem } from "./SkeletonMembersListItem";
import { ViewMode } from "./ViewMode";
import { usePeople } from "./usePeople";

export const People = (props: ReturnType<typeof usePeople>) => {
  const { t } = useTranslation();
  const isAdmin = props.roles.isHR || props.roles.isIT;

  return (
    <>
      <div className="animate-crossfadein">
        <Breadcrumbs
          paths={[{ name: t("translation:people:people"), to: "/people" }]}
        />

        <Disclosure
          as="section"
          aria-labelledby="filter-heading"
          className="grid items-center bg-zinc-100 p-2"
        >
          <div className="col-start-1 row-start-1 py-2">
            <div className="flex w-full flex-col items-center justify-between gap-y-3 px-2 sm:flex-row">
              <div className="relative w-full sm:max-w-sm">
                <input
                  type="text"
                  className="w-full rounded-md border-0 text-sm ring-1 ring-inset ring-zinc-400 focus:ring-zinc-400"
                  placeholder={t("translation:common:search")}
                  value={props.searchTerm}
                  onChange={(e) => props.setSearchTerm(e.target.value)}
                />
                <MagnifyingGlassIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-zinc-300" />
              </div>
              <div className="flex max-w-7xl items-center gap-2">
                <div className="ml-4 flex max-w-7xl items-center gap-2">
                  <ViewMode
                    setViewMode={props.setViewMode}
                    viewMode={props.viewMode}
                  />
                </div>
                {isAdmin && (
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex items-center text-zinc-500">
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div>
                          <Menu.Item>
                            <CopyButton
                              className={clsx(
                                "flex w-full items-center gap-2 p-2 text-sm font-normal text-zinc-500 hover:bg-zinc-100 hover:text-slate-600"
                              )}
                              value={registerLink()}
                              beforeTooltip={t(
                                "translation:people:share_link_to_invite_people"
                              )}
                            >
                              <DocumentDuplicateIcon className="h-5 w-5" />{" "}
                              {t("translation:people:copy_registration_link")}
                            </CopyButton>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={clsx(
                                "flex w-full items-center gap-2 p-2 text-sm font-normal text-zinc-500 hover:bg-zinc-100"
                              )}
                              onClick={() => props.openPeopleForm()}
                              data-tooltip-id="tooltip"
                              data-tooltip-content={t(
                                "translation:people:add_a_person"
                              )}
                              data-tooltip-place="bottom"
                            >
                              <UserPlusIcon className="h-5 w-5" />{" "}
                              {t("translation:people:invite_user")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={clsx(
                                "hidden w-full items-center gap-2 p-2 text-sm font-normal text-zinc-500 hover:bg-zinc-100"
                              )}
                              onClick={() => props.setIsImportUsers(true)}
                              data-tooltip-id="tooltip"
                              data-tooltip-content={t(
                                "translation:people:import_users"
                              )}
                              data-tooltip-place="bottom"
                            >
                              <UserGroupIcon className="h-5 w-5" />{" "}
                              {t("translation:people:import_users")}
                            </button>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
        </Disclosure>

        {props.viewMode === ViewModeEnum.VIEW_MODE_LIST &&
          (props.isLoadingMembers ||
            (!props.isLoadingMembers &&
              props.members?.data &&
              props.members?.data.length > 0)) && (
            <ul className="scrollbar-hide overflow-y-scroll">
              {props.isLoadingMembers
                ? [...Array(3).keys()].map((i) => (
                    <SkeletonMembersListItem key={i} />
                  ))
                : props.members?.data?.map((member) => (
                    <MemberListItem
                      currentUserIsAdmin={isAdmin}
                      member={member}
                      key={member.id}
                      onEdit={() =>
                        props.openPeopleForm(String(member.id as number))
                      }
                      onRemove={() => props.onClickDeactivateUser(member)}
                      onEmailInvite={() => props.onClickEmailInvite(member)}
                      openProfileModal={props.openProfileModal}
                    />
                  ))}
            </ul>
          )}

        {props.viewMode === ViewModeEnum.VIEW_MODE_GRID &&
          (props.isLoadingMembers ||
            (!props.isLoadingMembers &&
              props.members?.data &&
              props.members?.data.length > 0)) && (
            <ul className="scrollbar-hide grid grid-cols-1 gap-6 overflow-y-scroll p-6 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
              {props.isLoadingMembers
                ? [...Array(3).keys()].map((i) => (
                    <SkeletonMembersCardItem key={i} />
                  ))
                : props.members?.data?.map((member) => (
                    <MemberCard
                      currentUserIsAdmin={isAdmin}
                      member={member}
                      key={member.id}
                      onEdit={() =>
                        props.openPeopleForm(String(member.id as number))
                      }
                      onRemove={() => props.onClickDeactivateUser(member)}
                      onEmailInvite={() => props.onClickEmailInvite(member)}
                      openProfileModal={props.openProfileModal}
                    />
                  ))}
            </ul>
          )}
        {!props.isLoadingMembers &&
          props.members?.data &&
          props.members?.data.length === 0 && (
            <div className="rounded-b-3xl bg-slate-100 p-6 text-center">
              {t("translation:people:no_result", {
                searchText: props.searchTerm,
              })}
            </div>
          )}
        {!props.isLoadingMembers && props.isMembersError && (
          <div className="rounded-b-3xl bg-slate-100 p-6 text-center">
            {t("translation:people:problem_with_loading_members")}
          </div>
        )}
        <div className="flex justify-center">
          <Pagination
            currentPage={props.currentPage}
            totalCount={props.members?.meta?.pagination?.total || 0}
            pageSize={props.ROW_LIMIT}
            onPageChange={props.onPageChange}
          />
        </div>
      </div>

      {isAdmin && (
        <ModalConfirmation
          variant="warn"
          isOpen={props.isEmailPersonOpen}
          title={t("translation:people:invite", {
            firstName: props.selectedPerson?.firstName,
          })}
          cancelButtonTitle={t("translation:common:cancel")}
          confirmButtonTitle={t("translation:common:send_invite")}
          onCancel={() => props.setIsEmailPersonOpen(false)}
          onConfirm={props.onEmailConfirm}
          description={t("translation:people:are_you_sure_deactivate", {
            firstName: props.selectedPerson?.firstName,
            lastName: props.selectedPerson?.lastName,
          })}
        />
      )}
      {isAdmin && (
        <ModalConfirmation
          isOpen={props.isDeactivateUserOpen}
          title={t("translation:people:deactivate", {
            firstName: props.selectedPerson?.firstName,
            lastName: props.selectedPerson?.lastName,
          })}
          cancelButtonTitle={t("translation:common:cancel")}
          confirmButtonTitle={t("translation:common:deactivate")}
          onCancel={() => props.setIsDeactivateUserOpen(false)}
          onConfirm={props.onDeactivateConfirm}
          description={t(
            "translation:people:are_you_sure_deactivate_no_longer_access",
            {
              firstName: props.selectedPerson?.firstName,
              lastName: props.selectedPerson?.lastName,
            }
          )}
          variant="danger"
        />
      )}
      {props.isImportUsers && (
        <ModalImportUsers
          isOpen
          onImportFromMicrosoft={() => {}}
          onImportFromGoogle={() => {}}
          onCancel={() => props.setIsImportUsers(false)}
        />
      )}
      {isAdmin && (
        <PeopleForm
          onSuccess={() => {
            props.refetchPeople();
          }}
        />
      )}
    </>
  );
};
