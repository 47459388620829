/*
 *
 *
 * <ValueForm />
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Button } from "../../components/ButtonV3";
import { FormInputGroup } from "../../components/FormInputGroupV3";
import { FormTextAreaGroup } from "../../components/FormTextAreaGroupV3";
import { getTranslation } from "../../utils/translation";

interface Props {
  onClose: () => void;
  onSubmit: SubmitHandler<FormData>;
  formData: FormData;
  isUpdate?: boolean;
}

const schema = z.object({
  id: z.number().optional(),
  name: z
    .string()
    .min(1, { message: "translation:validation:required_name" })
    .max(250, { message: "translation:validation:less_than_250" }),
  description: z
    .string()
    .max(5000, { message: "translation:validation:less_than_5000" })
    .optional(),
});

export type FormData = z.infer<typeof schema>;

export const ValueForm = ({ onClose, onSubmit, formData, isUpdate }: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: formData,
    resolver: zodResolver(schema),
  });

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="relative">
        <div className="px-6 py-4">
          <FormInputGroup
            id="name"
            placeholder={t("translation:common:name")}
            errorMessage={getTranslation(t, errors.name?.message)}
            {...register("name")}
          />
        </div>
        <div className="bg-white px-6 pb-4">
          <FormTextAreaGroup
            id="description"
            placeholder={t("translation:common:description")}
            errorMessage={getTranslation(t, errors.description?.message)}
            rows={5}
            {...register("description")}
          />
        </div>
      </div>

      <div className="flex justify-end gap-2 px-6 pb-4">
        <Button
          variant="outlined"
          type="button"
          size="sm"
          onClick={onClose}
          data-testid="cancel-button"
        >
          {t("translation:common:cancel")}
        </Button>

        <Button
          variant="highlight"
          size="sm"
          type="submit"
          data-testid="submit-button"
          disabled={Boolean(errors.name || errors.description)}
        >
          {isUpdate
            ? t("translation:common:update")
            : t("translation:common:create")}
        </Button>
      </div>
    </form>
  );
};
