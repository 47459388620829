/**
 *
 *
 * <DomainForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { getTranslation } from "../../utils/translation";
import { useDomainForm } from "./useDomainForm";

export const DomainForm = (props: ReturnType<typeof useDomainForm>) => {
  const { t } = useTranslation();

  return (
    <div>
      <form onSubmit={props.onHandleSubmit}>
        <div className="flex flex-col items-start gap-1 sm:flex-row  sm:gap-3">
          <Input
            label="domain"
            labelClassName="sr-only"
            className="w-full max-w-md"
            type="text"
            id="domain"
            error={getTranslation(
              t,
              props.form.formState.errors.domain?.message
            )}
            {...props.form.register("domain")}
          />
          <div className="flex w-full flex-row items-center justify-end gap-2 sm:w-auto sm:justify-start">
            <Button
              variant="contained"
              size="md"
              className="mt-2 h-9"
              type="submit"
              disabled={
                props.isCreating ||
                props.isDeleting ||
                props.isSaving ||
                !props.form.formState.isDirty
              }
            >
              {t("translation:common:save")}
            </Button>
            <Button
              variant="outlined"
              size="md"
              className="mt-2 h-9"
              onClick={(e) => {
                e.preventDefault();
                props.onDeleteDomain();
              }}
              disabled={props.isCreating || props.isDeleting || props.isSaving}
            >
              {t("translation:common:delete")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
