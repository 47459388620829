/**
 *
 *
 * <Checkbox />
 *
 *
 */
import React, { forwardRef } from "react";

import { classNames } from "../../utils";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className = "", ...props }, ref) => {
    return (
      <input
        type="checkbox"
        className={classNames(
          "h-4 w-4 rounded border-zinc-300 text-zinc-500",
          "checked:bg-zinc-500 hover:bg-zinc-300 focus:bg-zinc-500",
          "focus:ring-zinc-500",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Checkbox.displayName = "Checkbox";

export { Checkbox };
