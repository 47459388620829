/**
 *
 *
 * <Challenges />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ChallengeTemplates } from "./ChallengeTemplates";
import { ChallengesEmpty } from "./ChallengesEmpty";
import { CompletedChallenges } from "./CompletedChallenges";
import { CurrentChallenges } from "./CurrentChallenges";
import { UpcomingChallenges } from "./UpcomingChallenges";
import { useChallenges } from "./useChallenges";

export const Challenges = (props: ReturnType<typeof useChallenges>) => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t("translation:challenge:challenges"), to: "/challenges" },
        ]}
      />
      <div className="[&>*:nth-child(odd)]:bg-zinc-100">
        {props.isEmpty && <ChallengesEmpty />}
        {!props.isEmpty && props.currentChallenges.length > 0 && (
          <CurrentChallenges {...props} />
        )}
        {!props.isEmpty && props.upcomingChallenges.length > 0 && (
          <UpcomingChallenges {...props} />
        )}
        {!props.isEmpty && props.completedChallenges.length > 0 && (
          <CompletedChallenges {...props} />
        )}
        {props.isAdmin && <ChallengeTemplates {...props} />}
      </div>
    </>
  );
};
