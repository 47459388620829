/**
 *
 *
 * <InputError />
 *
 *
 */
import React from "react";

interface Props {
  children?: React.ReactNode;
}

export const InputError = (props: Props) => {
  return <p className="mt-2 text-xs text-red-500">{props.children}</p>;
};
