/**
 *
 *
 * useCoffeeChatModal
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import { useCoffeechatsPartialUpdate } from "../../services/teambuilder/endpoints/coffeechats/coffeechats";
import {
  CoffeeChat,
  PaginatedAdsList,
  PatchedCoffeeChatRSVPRequest,
  PatchedCoffeeChatRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  listQueryKey: readonly unknown[];
  className?: string;
}

export const useCoffeeChatModal = ({ listQueryKey, className = "" }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has("coffeechat");
  const id = Number(searchParams.get("coffeechat"));
  const { openToast } = useToast();

  /**
   * update coffeechat
   */
  const { mutate: updateCoffeeChat } = useCoffeechatsPartialUpdate(
    optimisticMutationOptions<
      CoffeeChat,
      PaginatedAdsList,
      {
        id: number;
        data: PatchedCoffeeChatRSVPRequest;
      }
    >({
      queryKey: listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        return {
          ...context,
          data: context.data.map((coffeeChat) =>
            coffeeChat.objectId !== requestVariables?.id
              ? coffeeChat
              : { ...coffeeChat, objectId: -1 }
          ),
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:coffechat_update_success"),
        });
        close();
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:coffeechat_update_failed"),
          type: "danger",
        });
      },
    })
  );
  /**
   * Form config
   */
  const schema = z.object({
    answer: z.string().min(1),
  });

  type FormData = z.infer<typeof schema>;

  const defaultValues: FormData = {
    answer: "",
  };

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const close = () => {
    searchParams.delete("coffeechat");
    setSearchParams(searchParams);
  };

  const onCancel = () => {
    if (id === undefined) return;
    updateCoffeeChat({
      id,
      data: {
        participating: false,
      },
    });
  };

  const onSubmit = (formData: FormData) => {
    if (id === undefined) return;
    updateCoffeeChat({
      id,
      data: {
        participating: true,
        interests: formData.answer,
      },
    });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    form,
    isOpen,
    className,
    onClose: close,
    onHandleSubmit,
    onCancel,
  };
};
