/**
 *
 *
 * useCompanyPrefsForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  getIntuitionPrefsCompanyMeRetrieveQueryKey,
  useIntuitionPrefsCompanyMeRetrieve,
  useIntuitionPrefsCompanyPartialUpdate,
} from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  CompanyPreferenceModel,
  PatchedCompanyPreferenceModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const useCompanyPrefsForm = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  /**
   * Form config
   */

  const schema = z.object({
    isOpen: z.boolean(),
  });
  type FormData = z.infer<typeof schema>;

  const defaultValues: FormData = {
    isOpen: false,
  };

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { data: prefs, isLoading: isLoadingPrefs } =
    useIntuitionPrefsCompanyMeRetrieve();

  useEffect(() => {
    if (prefs)
      form.reset({
        isOpen: prefs.isOpen || false,
      });
  }, [prefs]);

  const { mutate: updatePrefs } = useIntuitionPrefsCompanyPartialUpdate(
    optimisticMutationOptions<
      CompanyPreferenceModel,
      CompanyPreferenceModel,
      {
        id: number;
        data: PatchedCompanyPreferenceModelRequest;
      }
    >({
      queryKey: getIntuitionPrefsCompanyMeRetrieveQueryKey(),
      optimisticUpdateFn: (context, requestData) => ({
        ...context,
        ...requestData?.data,
      }),
      onSuccess: () =>
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:company_prefs_update_success"),
        }),
      onError: () =>
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:company_prefs_update_failed"),
          type: "danger",
        }),
    })
  );

  const onChangeIsOpen = (e: ChangeEvent<HTMLInputElement>) => {
    if (!prefs) return;
    updatePrefs({
      id: prefs.company,
      data: {
        isOpen: e.target.checked,
      },
    });
  };

  return {
    prefs,
    form,
    onChangeIsOpen,
    isLoading: isLoadingPrefs,
    companyUrl: `https://${window.location.hostname}`,
  };
};
