/**
 *
 *
 * redirectToAuth
 *
 * Forward the user to auth.
 * * Uses the tenant auth if available.
 * * Strips UTM search params from the next url, so they aren't
 *   reported twice.
 */
import { stripUtmParams } from "./strip-utm-params";

export const redirectToAuth = (isLoginFailed = false) => {
  const url = new URL(window.location.href);
  const joinToken = url.searchParams.get("ptoken");
  let additionalParams = "";
  if (joinToken) {
    additionalParams = `&token=${joinToken}`;
  }
  const nextUrl = encodeURIComponent(stripUtmParams(url).toString());

  window.location.assign(
    `${import.meta.env.VITE_AUTH_API_DOMAIN}` +
      (!isLoginFailed ? `/?next=${nextUrl}${additionalParams}` : "")
  );
};
