import { withHook } from "../../utils";
import { ChallengeDetail as ChallengeDetailComponent } from "./ChallengeDetail";
import { useChallengeDetail } from "./useChallengeDetail";

export const ChallengeDetail = withHook(
  ChallengeDetailComponent,
  useChallengeDetail
);
// Lazy load requires a default export
export default ChallengeDetail;
