/**
 *
 *
 * Post Utils
 *
 *
 */
import { TFunction } from "i18next";

import { PostType } from "../types";
import {
  isCommentType,
  isPostType,
  isRebuttalType,
  isReplyType,
} from "./type-guards";

export const getPostAncestorPks = (post: PostType) => ({
  postsPk:
    isCommentType(post) || isReplyType(post) || isRebuttalType(post)
      ? (post.post as number)
      : (post.id as number),
  commentsPk: isCommentType(post)
    ? (post.id as number)
    : isReplyType(post) || isRebuttalType(post)
      ? (post.comment as number)
      : 0,
  repliesPk: isReplyType(post)
    ? (post.id as number)
    : isRebuttalType(post)
      ? (post.reply as number)
      : 0,
  rebuttalsPk: isRebuttalType(post) ? (post.id as number) : 0,
});

export const getKindOfPost = (post: PostType, t: TFunction) =>
  isPostType(post)
    ? t("translation:post:post")
    : isCommentType(post)
      ? t("translation:post:comment")
      : isReplyType(post)
        ? t("translation:post:reply")
        : t("translation:post:rebuttal");
