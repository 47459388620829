/**
 *
 *
 * useLanguageSelect
 *
 *
 */
import { useRef, useState } from "react";

import { useIntuitionLanguagesList } from "../../services/teambuilder/endpoints/intuition/intuition";
import { LanguageEnumLanguage } from "../../services/teambuilder/schemas";

interface Props {
  setValue: (language: LanguageEnumLanguage | null) => void;
  placeholder?: string;
  defaultValue?: string;
  disabledItems?: LanguageEnumLanguage[];
}

export const useLanguageSelect = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: languages, isLoading } = useIntuitionLanguagesList({
    limit: 999,
    search: searchTerm.toLowerCase(),
  });

  const inputRef = useRef<HTMLInputElement>(null);
  return {
    inputRef,
    languages,
    isLoading,
    searchTerm,
    setSearchTerm,
    setValue: props.setValue,
    placeholder: props.placeholder,
    defaultValue: props.defaultValue,
    disabledItems: props.disabledItems,
  };
};
