/**
 *
 *
 * <ReactionNotification />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { Avatar } from "../../components/Avatar";
import { NotificationModel } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { EmojiKey, emojis } from "../../utils/emojis";

interface Props {
  message: NotificationModel;
}

export const ReactionNotification = ({ message }: Props) => {
  return (
    <div className="flex gap-2">
      <div>
        <Avatar user={message.sender} size="md" />
      </div>

      <div className="flex-grow text-sm">
        <div
          className={clsx(
            "mb-2 flex items-start justify-between gap-2",
            message.read ? "font-normal" : "font-medium"
          )}
        >
          <div className="text-zinc-900">
            {message.subject}{" "}
            {
              emojis[
                String(message.contentObject?.Reaction?.reaction) as EmojiKey
              ]
            }
          </div>
          <p className="text-xs leading-5 text-zinc-500">
            {dayjs(message.createdAt).fromNow()}
          </p>
        </div>

        <div>
          <Link
            to={`/?post=${message.contentObject?.Post?.id}`}
            className="font-medium text-zinc-500 underline"
          >
            {message.message}
          </Link>
        </div>
      </div>
    </div>
  );
};
