/**
 *
 *
 * <DepartmentsLocationsV3 />
 *
 *
 */
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Departments } from "../../containers/DepartmentsV3";
import { Locations } from "../../containers/LocationsV3";

export const DepartmentsLocationsV3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="border-b border-b-zinc-300 px-8 py-6 text-lg text-zinc-500">
        {t("translation:settings:departments_locations:title")}
      </div>
      <div className="border-b border-b-zinc-300 px-8 py-12 text-sm font-normal text-zinc-500">
        <p className="mb-2">
          {t("translation:settings:departments_locations:description_1")}
        </p>
        <p>
          <Trans
            i18nKey="translation:settings:departments_locations:description_2"
            components={{
              a: <Link to="/people" className="font-medium underline" />,
            }}
          />
        </p>
      </div>
      <Departments />
      <Locations />
    </>
  );
};
