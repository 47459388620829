/**
 *
 *
 * <CurrentChallenges />
 *
 *
 */
import React from "react";

import { ChallengeList } from "../../components/ChallengeListV3";
import { CreateChallenge } from "./CreateChallenge";
import { Header } from "./Header";
import { useChallenges } from "./useChallenges";

export const CompletedChallenges = (
  props: ReturnType<typeof useChallenges>
) => {
  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="px-2 sm:px-8">
        <Header section="ended" count={props.completedChallenges.length} />
      </div>
      <ChallengeList
        challenges={props.completedChallenges}
        isLoadingChallenges={props.isLoading}
        section="ended"
        isListMode={
          props.currentChallenges.length === 0 &&
          props.upcomingChallenges.length === 0
        }
        className="flex w-full flex-wrap justify-center gap-y-10"
      />
      <CreateChallenge {...props} />
    </div>
  );
};
