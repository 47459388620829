/**
 *
 *
 * <EmptyValues />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { IconBlankCanvas } from "../../components/IconBlankCanvas";

interface Props {
  onAddClick: () => void;
}

export const EmptyValues = ({ onAddClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="my-6 flex flex-col justify-center gap-y-6 text-center">
      <IconBlankCanvas className="mx-auto" />
      <h4 className="text-xl text-zinc-500">
        {t("translation:settings:values:no_values")}
      </h4>
      <h4 className="mb-4 text-xl text-zinc-500">
        {t("translation:settings:values:get_start_create_value")}
      </h4>
      <div className="flex justify-center">
        <Button variant="outlined" size="md" onClick={onAddClick}>
          <PlusIcon className="h-4 w-4" />{" "}
          {t("translation:settings:values:add_value")}
        </Button>
      </div>
    </div>
  );
};
