/**
 *
 *
 * <TeamBuilderLogo />
 *
 *
 */
import React from "react";

import teambuilderByIntuitionLogo from "./teambuilder-by-intuition-logo-dark.svg";
import teambuilderLogo from "./teambuilder-logo-dark.svg";

interface Props {
  className?: string;
  variant?: "by-intuition";
}

export const TeamBuilderLogo = (props: Props) => {
  return (
    <img
      src={
        props.variant === "by-intuition"
          ? teambuilderByIntuitionLogo
          : teambuilderLogo
      }
      alt="TeamBuilder logo"
      className={props.className}
    />
  );
};
