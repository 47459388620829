/**
 *
 *
 * useBirthdayForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  getUserguidesRetrieveQueryKey,
  useUserguidesPartialUpdate,
  useUserguidesRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  PatchedUserGuidesRequest,
  UserGuides,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const schema = z.object({
  birthDate: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;
interface Props {
  userGuideId?: number;
  className?: string;
}

export const useBirthdayForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  const { data: userGuide, isLoading: isLoadingUserGuide } =
    useUserguidesRetrieve(Number(props?.userGuideId), {
      query: { enabled: Boolean(props?.userGuideId) },
    });
  const queryKey = useMemo(
    () =>
      props.userGuideId ? getUserguidesRetrieveQueryKey(props.userGuideId) : [],
    [userGuide]
  );

  /**
   * Form
   */
  const getDefaultValues = () => {
    return {
      birthDate: userGuide?.birthDate || undefined,
    };
  };
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    form.reset(getDefaultValues());
  }, [userGuide?.birthDate]);

  const { mutate: updateUserGuide } = useUserguidesPartialUpdate(
    optimisticMutationOptions<
      UserGuides,
      UserGuides,
      { id: number; data: PatchedUserGuidesRequest }
    >({
      queryKey,
      optimisticUpdateFn: (UserGuide, requestVariables) => {
        return {
          ...UserGuide,
          ...requestVariables?.data,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:start_date_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:start_date_update_failed"),
          description: t(
            "translation:toast:start_date_updated_failed_description"
          ),
          type: "danger",
        });
      },
    })
  );

  const onSubmit = (data: FormData) => {
    updateUserGuide({
      id: Number(props?.userGuideId),
      data: {
        birthDate: data.birthDate || null,
      },
    });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    form,
    isLoading: !props.userGuideId || isLoadingUserGuide,
    className: props.className,
    onHandleSubmit,
  };
};
