/**
 *
 *
 * <MultiSelect />
 *
 *
 */
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { SlideOverFormGroup } from "../../components/SlideOverFormGroup";

interface Option {
  id?: number;
  name: string;
}

interface Props {
  name: string;
  label: string;
  icon?: React.ReactNode;
  onChange: (options: Option[]) => void;
  defaultValue: Option[];
  options: Option[];
  placeholder?: string;
  errorMessage?: string;
  className?: string;
}

export const MultiSelect = ({
  name,
  label,
  icon,
  options,
  defaultValue,
  onChange,
  errorMessage,
}: Props) => {
  const { t } = useTranslation();
  return (
    <SlideOverFormGroup
      label={label}
      htmlFor={name}
      errorMessage={errorMessage}
    >
      <Listbox
        name={name}
        defaultValue={defaultValue}
        multiple
        onChange={(value) => onChange(value)}
        by="id"
      >
        {({ open }) => (
          <div className="relative mt-1">
            <Listbox.Button
              type="button"
              className={clsx(
                "relative w-full cursor-default rounded-md border border-zinc-300",
                "bg-white py-2 pl-3 pr-10 text-left shadow-sm",
                "focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                "flex items-center gap-1 sm:text-sm"
              )}
            >
              {({ value }) => (
                <>
                  {icon && <span className="text-zinc-500">{icon}</span>}

                  <span className={clsx("truncate")}>
                    {value.length === 0 ? (
                      <span className="text-zinc-500">
                        {t("translation:common:select")}{" "}
                        <span className="lowercase">{label}</span>
                      </span>
                    ) : (
                      value.map((option: Option) => option.name).join(", ")
                    )}
                  </span>
                </>
              )}
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  "absolute z-10 mt-1 max-h-56 w-full",
                  "overflow-auto rounded-md bg-white",
                  "py-1 text-base shadow-lg",
                  "ring-1 ring-black ring-opacity-5",
                  "focus:outline-none sm:text-sm"
                )}
              >
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    className={clsx(
                      "text-zinc-500 ui-active:bg-blue-600 ui-active:text-white",
                      "cursor-default select-none py-2 pl-3 pr-9",
                      "flex items-center gap-2"
                    )}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(
                            "h-5 w-5",
                            active ? "text-white" : "text-indigo-600",
                            selected ? "visible" : "invisible"
                          )}
                        >
                          <CheckIcon className="h-4 w-4" />
                        </span>
                        <span>{option.name}</span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </SlideOverFormGroup>
  );
};
