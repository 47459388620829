/**
 *
 *
 * <BanterTopicForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ModalConfirmation } from "../../components/ModalConfirmationV3";
import { SlideOverFormInputGroup } from "../../components/SlideOverFormInputGroupV3";
import { SlideOverFormTextareaGroup } from "../../components/SlideOverFormTextareaGroupV3";
import { SlideOverForm } from "../../components/SlideOverFormV3";
import { SlideOver } from "../../components/SlideOverV3";
import { getTranslation } from "../../utils/translation";
import { useBanterTopicForm } from "./useBanterTopicForm";

export const BanterTopicForm = ({
  isOpen,
  topic,
  handleClose,
  onHandleSubmit,
  errors,
  register,
  minDate,
}: ReturnType<typeof useBanterTopicForm>) => {
  const { t } = useTranslation();
  return (
    <>
      <SlideOver
        isOpen={isOpen}
        onClose={handleClose}
        title={
          topic
            ? t("translation:settings:banter:edit_topic")
            : t("translation:settings:banter:create_topic")
        }
        subtitle={t("translation:settings:banter:topic_form_description")}
      >
        <SlideOverForm
          onSubmit={onHandleSubmit}
          footer={
            <>
              <Button
                variant="outlined"
                type="button"
                size="lg"
                onClick={handleClose}
                data-testid="cancel-button"
              >
                {t("translation:common:cancel")}
              </Button>
              <Button
                variant="contained"
                size="lg"
                type="submit"
                data-testid="submit-button"
                disabled={Boolean(errors.topic)}
              >
                {topic
                  ? t("translation:common:save")
                  : t("translation:common:create")}
              </Button>
            </>
          }
        >
          <SlideOverFormTextareaGroup
            id="topic"
            label={t("translation:settings:banter:topic")}
            errorMessage={getTranslation(t, errors.topic?.message)}
            {...register("topic")}
          />

          <SlideOverFormInputGroup
            id="postDate"
            label={t("translation:settings:banter:post_date")}
            className="text-sm"
            tooltip={
              <p>
                {t("translation:settings:banter:post_date_tooltip_left")}
                <br />{" "}
                {t("translation:settings:banter:post_date_tooltip_right")}
              </p>
            }
            type="date"
            {...register("postDate")}
            errorMessage={getTranslation(t, errors.postDate?.message)}
            min={minDate}
          />
        </SlideOverForm>
      </SlideOver>
    </>
  );
};
