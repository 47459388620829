import clsx from "clsx";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { ChallengeActivityListItem } from "../../components/ChallengeActivityListItemV3/ChallengeActivityListItem";
import { SearchParam } from "../../consts";
import { Activity, SimpleUserModel } from "../../services/teambuilder/schemas";
import { PostType } from "../../types";

type Props = {
  post: PostType;
  mentionUser?: SimpleUserModel;
  activity: Activity;
  className?: string;
};

export const ActivityCompleted = (props: Props) => {
  const mentionUser = props.post?.mentions?.[0]?.user;
  return (
    <ul className={clsx("space-y-4 rounded-lg bg-zinc-100", props.className)}>
      <p className="text-sm font-normal leading-5">
        {props.post.user?.id === 1 && mentionUser ? (
          <Trans
            i18nKey={`translation:challenge:nice_job_activity_completed_${(props.post.id! % 3) + 1}`}
            values={{
              firstName: mentionUser.firstName,
              lastName: mentionUser.lastName,
            }}
            components={{
              a: (
                <Link
                  to={`?${SearchParam.PROFILE}=${mentionUser?.id}`}
                  className="font-semibold text-zinc-500 underline hover:text-zinc-600"
                />
              ),
            }}
          />
        ) : (
          props.post.content
        )}
      </p>
      <ChallengeActivityListItem
        index={0}
        activity={props.activity}
        isComment
      />
    </ul>
  );
};
