/**
 *
 *
 * <CurrentChallenges />
 *
 *
 */
import React from "react";

import { ChallengeList } from "../../components/ChallengeListV3";
import { CreateChallenge } from "./CreateChallenge";
import { Header } from "./Header";
import { useChallenges } from "./useChallenges";

export const CurrentChallenges = (props: ReturnType<typeof useChallenges>) => {
  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="px-2 sm:px-8">
        <Header section="current" count={props.currentChallenges.length} />
      </div>
      <ChallengeList
        challenges={props.currentChallenges}
        isLoadingChallenges={props.isLoading}
        section="current"
        className="flex w-full flex-wrap justify-center gap-y-10"
        isListMode
      />
      <CreateChallenge {...props} />
    </div>
  );
};
