/**
 *
 *
 * <PostModal />
 *
 *
 */
import {
  ChevronRightIcon,
  LinkIcon,
  PhotoIcon,
} from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "../../components/AvatarV3";
import { Button } from "../../components/ButtonV3";
import { Modal } from "../../components/ModalV3";
import { getTranslation } from "../../utils/translation";
import { Editor } from "../EditorV3";
import { PostAttachmentModal } from "../PostAttachmentModal";
import { PostFeatureType } from "../PostAttachmentModal/PostAttachmentModal";
import { FeatureContent } from "./FeatureContent";
import { FormData, usePostModal } from "./usePostModal";

export const PostModal = (props: ReturnType<typeof usePostModal>) => {
  const { t } = useTranslation();
  const isAttachmentModal =
    props.isShoutoutVisible ||
    props.isImageEditorVisible ||
    props.isUrlInputVisible;
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      disableOutsideClick
      className={clsx(
        "w-screen rounded-xl bg-white",
        props.isChallengeComplete ? "max-w-md" : " max-w-prose"
      )}
      showCloseButton={false}
    >
      {!props.isChallengeComplete && (
        <div className="flex justify-end px-2 py-2 sm:px-3">
          <button
            type="button"
            className={clsx("text-white")}
            onClick={() => props.onClose()}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon
              className="h-4 w-4 rounded-md text-zinc-500 sm:h-6 sm:w-6"
              aria-hidden="true"
            />
          </button>
        </div>
      )}
      <div
        className={clsx(
          "flex flex-row gap-x-2 sm:gap-x-6",
          props.isChallengeComplete ? "p-4 sm:p-6" : "px-3 pb-3 sm:px-6 sm:pb-6"
        )}
      >
        <div className="flex-shrink-0">
          <Avatar loading={props.isLoading} user={props.me} size="lg" />
        </div>
        <div className="min-w-0 flex-1">
          <form onSubmit={props.onHandleSubmit} className="relative">
            {/*
             *
             * Post body
             *
             */}
            <div className={clsx("border-b-2 border-zinc-300")}>
              {!props.isLoading && (
                <div>
                  <label htmlFor="comment" className="sr-only">
                    {t("translation:post:post_content")}
                  </label>
                  <Editor
                    clear={props.clearEditor}
                    placeholder={t("translation:post:add_your_thoughts")}
                    onChange={(post) => props.setPostValue(post as FormData)}
                    className="h-36"
                    initialValue={props.defaultPostValue}
                    setEditor={props.setEditor}
                  />
                  {props.form.formState.errors.content && (
                    <p className="px-2 text-xs text-red-500">
                      {getTranslation(
                        t,
                        props.form.formState.errors.content.message
                      )}
                    </p>
                  )}
                </div>
              )}
            </div>

            {/**
             *
             * Show Feature Contents
             *
             */}
            <FeatureContent
              menuItems={props.menuItems}
              selectedRecipient={props.selectedRecipient}
              selectedValueId={props.selectedValueId}
              selectedValueLabel={props.selectedValueLabel}
              previewImageUrl={props.previewImageUrl}
              selectedURL={props.selectedURL}
              className="mt-3 sm:mt-6"
            />

            {/*
             *
             * Footer
             *
             */}
            <div className="mt-3 flex justify-between sm:mt-6">
              {/*
               *
               * Feature toggles
               *
               */}
              <div className="flex items-center gap-1 sm:gap-3">
                {!props.isChallengeComplete && (
                  <>
                    <Button
                      type="button"
                      variant="shoutout"
                      size="md"
                      onClick={props.toggleShoutout}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t(
                        "translation:comment:add_a_shoutout"
                      )}
                      data-tooltip-place="top"
                      disabled={
                        !!props.selectedRecipient ||
                        !!props.previewImageUrl ||
                        !!props.selectedURL
                      }
                    >
                      <MegaphoneIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      <p className="hidden text-sm text-white sm:block">
                        {t("translation:comment:shoutout")}
                      </p>
                    </Button>
                    <Button
                      type="button"
                      variant="image"
                      size="md"
                      onClick={props.toggleImage}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t(
                        "translation:comment:add_an_image"
                      )}
                      data-tooltip-place="top"
                      disabled={
                        !!props.selectedRecipient ||
                        !!props.previewImageUrl ||
                        !!props.selectedURL
                      }
                    >
                      <PhotoIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      <p className="hidden text-sm text-white sm:block">
                        {t("translation:post:photo")}
                      </p>
                    </Button>
                    <Button
                      type="button"
                      onClick={props.toggleUrl}
                      variant="link"
                      size="md"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t(
                        "translation:comment:add_link_website"
                      )}
                      data-tooltip-place="top"
                      disabled={
                        !!props.selectedRecipient ||
                        !!props.previewImageUrl ||
                        !!props.selectedURL
                      }
                    >
                      <LinkIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      <p className="hidden text-sm text-white sm:block">
                        {t("translation:post:link")}
                      </p>
                    </Button>
                  </>
                )}
              </div>

              {/*
               *
               * Submit button
               *
               */}
              <div className="flex flex-shrink-0 gap-x-2">
                {props.isChallengeComplete && (
                  <>
                    <Button
                      type="button"
                      variant="outlined"
                      size="md"
                      className="hidden sm:block"
                      onClick={() => props.onClose(true)}
                    >
                      {t("translation:common:cancel")}
                    </Button>
                    <Button
                      variant="outlined"
                      size="md"
                      className="block sm:hidden"
                      onClick={() => props.onClose(true)}
                    >
                      {t("translation:common:cancel")}
                    </Button>
                  </>
                )}
                <Button
                  type="submit"
                  variant={
                    props.selectedURL
                      ? "link"
                      : props.previewImageUrl
                        ? "image"
                        : props.selectedRecipient
                          ? "shoutout"
                          : "highlight"
                  }
                  size="md"
                  className="hidden sm:flex"
                >
                  {t(
                    props.isChallengeComplete
                      ? "translation:common:next"
                      : "translation:comment:post"
                  )}
                  {props.isChallengeComplete && (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                </Button>
                <Button
                  type="submit"
                  variant={
                    props.selectedURL
                      ? "link"
                      : props.previewImageUrl
                        ? "image"
                        : props.selectedRecipient
                          ? "shoutout"
                          : "highlight"
                  }
                  size="md"
                  className="block sm:hidden"
                >
                  {t(
                    props.isChallengeComplete
                      ? "translation:common:next"
                      : "translation:comment:post"
                  )}
                  {props.isChallengeComplete && (
                    <ChevronRightIcon className="h-5 w-5" />
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PostAttachmentModal
        mode={
          props.isShoutoutVisible
            ? PostFeatureType.SHOUTOUT
            : props.isImageEditorVisible
              ? PostFeatureType.IMAGE
              : PostFeatureType.LINK
        }
        isOpen={isAttachmentModal}
        isChallengeComplete={props.isChallengeComplete}
        onClose={props.onClose}
        onCancel={props.onCancel}
        previewImageUrl={props.previewImageUrl}
        recipient={props.selectedRecipient}
        valueId={props.selectedValueId}
        url={props.selectedURL}
        onSubmit={props.onSetFeature}
      />
    </Modal>
  );
};
