/**
 *
 *
 * <EmptyAvatar />
 *
 *
 */
import { UserCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";

interface Props {
  size?: "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl" | "post" | "commentor";
  className?: string;
  onClick?: () => void;
}

// These are the same sizes as AvatarStack
export const sizeMap = {
  sm: "h-1.5 w-1.5 md:h-3 md:w-3",
  md: "h-8 w-8",
  lg: "h-8 w-8 md:h-12 md:w-12",
  xl: "h-12 w-12 md:h-16 md:w-16",
  xxl: "h-28 w-28 md:h-32 md:w-32",
  xxxl: "h-32 w-32 md:h-44 md:w-44",
  post: "h-9 w-9",
  commentor: "w-6 h-6",
};

export const EmptyAvatar = ({ size = "md", className, onClick }: Props) => {
  return (
    <div
      className={clsx(
        sizeMap[size],
        className,
        "rounded-full border-4 border-dashed border-zinc-300",
        "flex items-center justify-center"
      )}
      onClick={() => onClick && onClick()}
    >
      <UserCircleIcon className="h-8 w-8 text-zinc-300" />
    </div>
  );
};
