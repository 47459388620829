/**
 *
 *
 * <SkeletonTopicListItem />
 *
 *
 */
import clsx from "clsx";
import React from "react";

interface Props {
  className?: string;
}

export const SkeletonTopicListItem = ({ className = "" }: Props) => {
  return (
    <li
      className={clsx(
        "flex animate-pulse flex-col gap-4 bg-white px-4 py-6 shadow sm:rounded-lg  sm:px-6",
        "mx-auto max-w-prose border border-zinc-300",
        className
      )}
    >
      <div className="h-8 w-full rounded-lg bg-zinc-300" />
    </li>
  );
};
