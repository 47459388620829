/**
 *
 *
 * useBioForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  getUserguidesRetrieveQueryKey,
  useUserguidesPartialUpdate,
  useUserguidesRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  PatchedUserGuidesRequest,
  UserGuides,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const schema = z.object({
  bio: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  userGuideId?: number;
  className?: string;
}

export const useBioForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  const { data: userGuide, isLoading: isLoadingUserGuide } =
    useUserguidesRetrieve(Number(props?.userGuideId), {
      query: { enabled: Boolean(props?.userGuideId) },
    });
  const queryKey = useMemo(
    () =>
      props.userGuideId ? getUserguidesRetrieveQueryKey(props.userGuideId) : [],
    [userGuide]
  );

  const { data: company, isLoading: isLoadingCompany } =
    useIntuitionCustomersCompaniesMeRetrieve();

  /**
   * Form
   */
  const getDefaultValues = () => {
    return {
      bio: userGuide?.bio || undefined,
    };
  };
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    form.reset(getDefaultValues());
  }, [userGuide?.bio]);

  const { mutate: updateUserGuide } = useUserguidesPartialUpdate(
    optimisticMutationOptions<
      UserGuides,
      UserGuides,
      { id: number; data: PatchedUserGuidesRequest }
    >({
      queryKey,
      optimisticUpdateFn: (UserGuide, requestVariables) => {
        return {
          ...UserGuide,
          ...requestVariables?.data,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:bio_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:bio_update_failed"),
          description: t("translation:toast:bio_updated_failed_description"),
          type: "danger",
        });
      },
    })
  );

  const onSubmit = (data: FormData) => {
    updateUserGuide({
      id: Number(props?.userGuideId),
      data: {
        bio: data.bio || null,
      },
    });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    form,
    onHandleSubmit,
    isLoading: !props.userGuideId || isLoadingUserGuide || isLoadingCompany,
    company,
    className: props.className,
  };
};
