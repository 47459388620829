/**
 *
 *
 * <PrimaryNav /> for <Sidebar />
 *
 *
 */
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { NavItem } from "../../components/NavItem";
import { NotificationsNavItem } from "../../components/NotificationsNavItem";
import { useNavItems } from "../../hooks/useNavItems";
import { CompanyModel } from "../../services/teambuilder/schemas";
import { AvatarMenu } from "../AvatarMenuV3";
import { Language } from "../LanguageV3";

interface Props {
  isCollapsed: boolean;
  onToggleSidebar: () => void;
  navItems: ReturnType<typeof useNavItems>;
  company?: CompanyModel;
  isLoadingCompany: boolean;
  isDrawerOpen: boolean;
}

export const PrimaryNav = (props: Props) => {
  return (
    <>
      {/* Drawer */}
      <div
        className={
          "fixed inset-0 z-[1000] transform overflow-hidden bg-gray-900 bg-opacity-25 ease-in-out " +
          (props.isDrawerOpen
            ? " translate-x-0 opacity-100 transition-opacity duration-500"
            : " translate-x-full opacity-0 transition-all delay-500")
        }
      >
        <section
          className={clsx(
            "delay-400 absolute left-0 h-full w-72 transform bg-white shadow-xl transition-all duration-500 ease-in-out",
            props.isDrawerOpen ? "translate-x-0" : "-translate-x-full"
          )}
        >
          <div
            className={clsx(
              "min-h-full flex-col justify-between p-6 lg:flex",
              "min-w-72"
            )}
          >
            <div>
              <div
                className={clsx(
                  "flex flex-shrink-0 flex-row items-center justify-between",
                  props.isCollapsed ? "" : "mb-6"
                )}
              >
                {props.isLoadingCompany ? (
                  <div className="h-12 w-[200px] animate-pulse rounded-lg bg-zinc-300" />
                ) : props.company?.logo ? (
                  <Link
                    reloadDocument
                    to="/"
                    className="mb-6 block h-12 rounded-lg"
                  >
                    <img
                      src={props.company?.logo.medium}
                      alt="logo"
                      className="h-full max-w-[12.25rem]"
                    />
                  </Link>
                ) : (
                  <Link
                    reloadDocument
                    to="/"
                    className={clsx(
                      "block text-2xl font-semibold tracking-tighter",
                      "mb-6 rounded-lg text-zinc-800",
                      "max-w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[25ch]"
                    )}
                  >
                    {props.company?.name}
                  </Link>
                )}
              </div>

              <div>
                <div
                  className={clsx(
                    "flex flex-col items-start gap-2.5",
                    "items-stretch"
                  )}
                >
                  {props.navItems.primary.map((item, i) => (
                    <NavItem
                      key={i}
                      item={{
                        ...item,
                        onClick: () => {
                          item.onClick && item.onClick();
                          props.onToggleSidebar();
                        },
                      }}
                      rounded
                    />
                  ))}
                </div>
              </div>

              <div className="my-6 w-full border-b" />

              <div className={clsx("flex flex-col gap-2.5", "items-stretch")}>
                <NotificationsNavItem onClick={props.onToggleSidebar} />

                <Language />
              </div>
            </div>

            <AvatarMenu onClick={props.onToggleSidebar} />
          </div>
        </section>
        <section
          className="z-[999] h-full w-screen cursor-pointer"
          onClick={props.onToggleSidebar}
        ></section>
      </div>

      <div
        className={clsx(
          "group relative hidden min-h-full flex-col justify-between border-r border-zinc-300 p-6 lg:flex",
          props.isCollapsed ? "w-32" : "min-w-72"
        )}
      >
        <div>
          <div
            className={clsx(
              "flex flex-shrink-0 flex-row items-center justify-between",
              props.isCollapsed ? "" : "mb-6"
            )}
          >
            {!props.isCollapsed && (
              <>
                {props.isLoadingCompany ? (
                  <div className="h-12 w-[200px] animate-pulse rounded-lg bg-zinc-300" />
                ) : props.company?.logo ? (
                  <Link reloadDocument to="/" className="block h-12 rounded-lg">
                    <img
                      src={props.company?.logo.medium}
                      alt="logo"
                      className="h-full max-w-[12.25rem]"
                    />
                  </Link>
                ) : (
                  <Link
                    reloadDocument
                    to="/"
                    className={clsx(
                      "block text-2xl font-semibold tracking-tighter",
                      "rounded-lg text-zinc-800",
                      "max-w-[15ch] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[25ch]"
                    )}
                  >
                    {props.company?.name}
                  </Link>
                )}
              </>
            )}
            <button
              className={clsx(
                "hidden bg-zinc-100 p-0.5 group-hover:flex",
                "absolute right-0 top-9 cursor-pointer rounded-l-md shadow",
                "bg-zinc-50 text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500"
              )}
              onClick={props.onToggleSidebar}
            >
              {props.isCollapsed ? (
                <ChevronDoubleRightIcon className="h-5 w-5" />
              ) : (
                <ChevronDoubleLeftIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          <div>
            <div
              className={clsx(
                "flex flex-col items-start gap-2.5",
                props.isCollapsed ? "items-start" : "items-stretch"
              )}
            >
              {props.navItems.primary.map((item, i) => (
                <NavItem
                  key={i}
                  item={item}
                  collapsed={props.isCollapsed}
                  rounded
                />
              ))}
            </div>
          </div>

          <div className="my-6 w-full border-b" />

          <div
            className={clsx(
              "flex flex-col gap-2.5",
              props.isCollapsed ? "items-start" : "items-stretch"
            )}
          >
            <NotificationsNavItem collapsed={props.isCollapsed} />

            <Language isCollapsed={props.isCollapsed} />
          </div>
        </div>

        <AvatarMenu isCollapsed={props.isCollapsed} />
      </div>
    </>
  );
};
