/**
 *
 *
 * useBilling
 *
 *
 */
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import {
  useBillingMeRetrieve,
  useBillingPortalCreate,
} from "../../services/teambuilder/endpoints/billing/billing";

export const useBilling = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { data: billingInfo, isLoading } = useBillingMeRetrieve({
    query: {
      // Refetch every minute. Billing management is offsite, so we don't know about changes.
      refetchInterval: 1000 * 60,
    },
  });

  // seatsPurchased - seatsInUse < 2
  const isPlanAlmostFull = (
    seatsPurchased: number | undefined,
    seatsInUse: number | undefined
  ) => {
    if (seatsPurchased !== undefined && seatsInUse !== undefined)
      return seatsPurchased - seatsInUse < 2;
  };

  // TODO: this endpoint disappeared from the spec.
  const { mutate: billingPortalCreate } = useBillingPortalCreate({
    mutation: {
      onSuccess: (data) => {
        if (data.url) {
          window.open(data.url, "_blank", "noreferrer");
        }
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:stripe_connect_failed"),
          type: "danger",
        });
      },
    },
  });

  const onManage = () => {
    billingPortalCreate({
      data: { returnUrl: window.location.href },
    });
  };

  return {
    isLoading,
    billingInfo,
    isPlanAlmostFull,
    onManage,
  };
};
