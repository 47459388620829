/**
 *
 *
 * useIntegrations
 *
 *
 */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  getIntegrationsListQueryKey,
  useIntegrationsDestroy,
  useIntegrationsList,
} from "../../services/teambuilder/endpoints/integrations/integrations";
import { useTeamsRetrieve } from "../../services/teambuilder/endpoints/teams/teams";
import { PaginatedIntegrationsList } from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import iconSlack from "./icon-slack.png";
import iconMSTeams from "./icon-teams.svg";
import { IntegrationItem } from "./types";

const integrations: IntegrationItem[] = [
  {
    id: 1,
    name: "Slack",
    key: "slack",
    description: "translation:settings:integrations:connect_slack",
    logo: iconSlack,
    connected: false,
  },
  {
    id: 2,
    name: "Microsoft Teams",
    key: "ms_teams",
    description: "translation:settings:integrations:connect_mst",
    logo: iconMSTeams,
    connected: false,
  },
];

export const useIntegrations = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const connectedTo = searchParams.get("connected_to");
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const { openToast } = useToast();

  const integrationsListQueryKey = getIntegrationsListQueryKey({});
  const {
    data: connectedIntegrations,
    isLoading,
    isError,
  } = useIntegrationsList(
    {},
    {
      query: {
        select: ({ data }) => data,
        onError: () => {
          openToast({
            title: "Error",
            description:
              "We were unable to load integrations. Please try again.",
            type: "danger",
          });
        },
      },
    }
  );

  /**
   * If an integration is included in the integrations data, set the connected flag to true.
   */
  const formattedIntegrationList = useMemo<IntegrationItem[]>(() => {
    if (!connectedIntegrations) return integrations;

    return integrations.reduce<IntegrationItem[]>((acc, integration) => {
      // Find the connected integration that matches the current integration.
      const connectedIntegration = connectedIntegrations?.find(
        (d) => d.provider === integration.key
      );
      // If there is a connected integration, attach additional props to the integration.
      return connectedIntegration
        ? [
            ...acc,
            {
              ...integration,
              ...connectedIntegration,
              connected: true,
              // TODO: what is this for?
              apiID: connectedIntegration.id,
            },
          ]
        : [...acc, integration];
    }, []);
  }, [connectedIntegrations]);

  const { mutate: destroyIntegration, isLoading: destroyInProgress } =
    useIntegrationsDestroy(
      optimisticMutationOptions<
        void,
        PaginatedIntegrationsList,
        { id: number }
      >({
        queryKey: integrationsListQueryKey,
        optimisticUpdateFn: (context, requestVariables) => ({
          ...context,
          data: context.data.filter((item) => item.id !== requestVariables?.id),
        }),
        onSuccess: async () => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:integration_disconnect_success"),
          });
        },
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:integration_disconnect_failed"),
            type: "danger",
          });
        },
      })
    );

  const [
    isDisconnectConfirmationModalOpen,
    setIsDisconnectConfirmationModalOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (connectedTo) {
      const channel = integrations.find((c) => c.key === connectedTo);
      if (channel)
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:channel_connected_success", {
            channel: channel.name,
          }),
        });
    }
  }, [connectedTo, openToast, t]);

  /**
   *
   * team
   *
   */
  const { data: team, isLoading: isLoadingTeam } = useTeamsRetrieve(
    TEAM_EVERYONE_ID,
    {
      query: {
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:load_team_failed"),
            type: "danger",
          });
        },
      },
    }
  );

  // If `error` is in the url OAuth has failed for some reason.
  useEffect(() => {
    const error = searchParams.get("error");
    if (error) {
      openToast({
        title: t("translation:toast:connection_failed"),
        description: decodeURIComponent(error).split("+").join(" "),
        type: "danger",
      });
    }
  }, [searchParams, openToast, t]);

  const handleDisconnect = (index: number, id: number) => {
    setLoadingIndex(index);
    destroyIntegration({ id });
  };

  return {
    isLoading: isLoading || isLoadingTeam,
    channelId: team?.connection?.channelId,
    isError,
    formattedIntegrationList,
    handleDisconnect,
    destroyInProgress,
    loadingIndex,
    isDisconnectConfirmationModalOpen,
    setIsDisconnectConfirmationModalOpen,
  };
};
