/**
 *
 *
 * useChallengeWizardTemplates
 *
 *
 */
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SearchParam, TEAM_EVERYONE_ID } from "../../consts";
import useDebounceValue from "../../hooks/useDebounceValue";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  useChallengesCreate,
  useChallengesTemplatesList,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import {
  Challenge,
  ChallengeRequest,
  Template,
} from "../../services/teambuilder/schemas";
import { NavItemV3 } from "../../types";

export const useChallengeWizardTemplates = () => {
  const { t } = useTranslation();
  const { isAdmin } = useRoles();
  const { openToast } = useToast();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get(SearchParam.SEARCH) || "";
  const selectedTemplateId = Number(
    searchParams.get(SearchParam.SELECTED_TEMPLATE)
  );
  const [template, setTemplate] = useState<Template>();
  const [isNextEnabled, setIsNextEnabled] = useState(
    Number.isInteger(selectedTemplateId)
  );

  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const [categories, setCategories] = useState<Record<string, NavItemV3>>({});

  const [searchTerm, setSearchTerm] = useState(search || "");
  const debouncedSearchTerm = useDebounceValue(search, 500) || "";

  /**
   * fetch templates
   */
  const { data: templates, isInitialLoading: isLoadingTemplates } =
    useChallengesTemplatesList(
      {
        limit: 999,
        search: debouncedSearchTerm,
      },
      {
        query: {
          select: ({ data }) => data,
        },
      }
    );

  useEffect(() => {
    if (templates) {
      let filteredTemplates = templates;
      if (filterBy && filterBy !== t("translation:common:show_all")) {
        filteredTemplates = filteredTemplates.filter(
          (template) =>
            template.categories &&
            template.categories?.filter(
              (category) => category && category.name === filterBy
            ).length > 0
        );
      }
      setFilteredTemplates(filteredTemplates);

      if (Object.keys(categories).length === 0) {
        const _categories = templates
          .map((template) => template.categories)
          .flat()
          .reduce(
            (acc, category) =>
              category ? { ...acc, [category.id!]: category } : acc,
            {}
          );
        setCategories(_categories);
      }
    }
  }, [templates, filterBy, categories, t]);

  useEffect(() => {
    if (debouncedSearchTerm === searchTerm || searchTerm === "") return;
    if (searchParams.has("search")) {
      searchParams.set("search", searchTerm);
    } else {
      searchParams.append("search", searchTerm);
    }
    setSearchParams(searchParams);
  }, [debouncedSearchTerm, searchTerm]);

  /**
   * create challenge
   */
  const { mutate: createChallenge, isLoading: isLoadingCreateChallenge } =
    useChallengesCreate({
      mutation: {
        onSuccess: ({ id }) => {
          // Forward use to the challenge detail page
          openToast({ title: t("translation:toast:challenge_create_success") });
          navigate(
            `/challenges/wizard/add-activities?${SearchParam.CHALLENGE}=${id}`
          );
        },
        onError: () => {
          openToast({
            title: t("translation:toast:challenge_create_failed"),
            type: "danger",
          });
        },
      },
    });

  /**
   * If a template has been selected in some previous step (like from the template modal)
   * then we need to set the template in the state.
   */
  useEffect(() => {
    if (selectedTemplateId && templates) {
      const selectedTemplate = templates.find(
        (template) => template.id === Number(selectedTemplateId)
      );
      if (selectedTemplate) {
        setTemplate(selectedTemplate);
        setIsNextEnabled(true);
      }
    }
  }, [selectedTemplateId, templates]);

  /**
   * Filter Items
   */
  const filterItems: NavItemV3[] = useMemo(
    () => [
      {
        name: t("translation:common:show_all"),
        onClick: () => onClickCategory(""),
      },
      ...Object.keys(categories)
        .map((key: string) => ({
          name: categories[key]?.name,
          onClick: () => onClickCategory(categories[key]?.name),
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    ],
    [categories]
  );

  const onClickCategory = (name: string | null) => {
    setFilterBy(name);
  };

  /**
   *
   * @param id template id
   * onTemplatePreview
   *
   */
  const onTemplatePreview = (id: number) => {
    if (searchParams.has(SearchParam.TEMPLATE_PREVIEW)) {
      searchParams.set(SearchParam.TEMPLATE_PREVIEW, id.toString());
    } else {
      searchParams.append(SearchParam.TEMPLATE_PREVIEW, id.toString());
    }
    setSearchParams(searchParams);
  };

  /**
   * select template
   * @param template Challenge | Template
   */
  const onSelectTemplate = (template: Challenge | Template) => {
    if (template?.id) {
      searchParams.set(SearchParam.SELECTED_TEMPLATE, template.id.toString());
      setSearchParams(searchParams);
    }
  };

  /**
   * goBack : click on Cancel Button
   */
  const goBack = () => {
    navigate("/challenges");
  };

  /**
   * next
   */
  const onNext = async () => {
    if (!template) return;
    const now = dayjs();
    const year = new Date().getFullYear();
    const defaultStart = now
      .add(1, "day")
      .set("hour", 8)
      .set("minute", 0)
      .set("second", 0);
    let startAt =
      Number.isInteger(template.startDay) &&
      Number.isInteger(template.startMonth)
        ? dayjs(`${year}-${template.startMonth}-${template.startDay}T08:00`)
        : defaultStart;
    if (startAt.isBefore(now)) startAt = defaultStart;
    const defaultEnd = now
      .add(14, "day")
      .set("hour", 17)
      .set("minute", 30)
      .set("second", 0);
    let endAt =
      Number.isInteger(template.endDay) && Number.isInteger(template.endMonth)
        ? dayjs(`${year}-${template.endMonth}-${template.endDay}T17:30`)
        : defaultEnd;
    if (endAt.isBefore(now)) endAt = defaultEnd;
    const data: ChallengeRequest = {
      template: template.id,
      name: template.name,
      team: TEAM_EVERYONE_ID,
      selectedActivityIds: template.activities?.map((activity) => activity.id!),
      isDraft: true,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
    };
    createChallenge({ data });
  };

  return {
    isAdmin,
    isNextEnabled,
    isLoadingCreateChallenge,

    goBack,
    onNext,

    template,
    templates: filteredTemplates,
    isLoadingTemplates,

    onTemplatePreview,
    onSelectTemplate,

    filterBy,
    filterItems,
    searchTerm,
    setSearchTerm,
  };
};
