/*
 *
 *
 * <LocationForm />
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Button } from "../../components/ButtonV3";
import { SlideOverFormInputGroup } from "../../components/SlideOverFormInputGroupV3";
import { SlideOverFormTextareaGroup } from "../../components/SlideOverFormTextareaGroupV3";
import { SlideOverForm } from "../../components/SlideOverFormV3";
import { SlideOver } from "../../components/SlideOverV3";
import { getTranslation } from "../../utils/translation";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  shallResetFormData?: boolean;
  onSubmit: SubmitHandler<FormData>;
  formData: FormData;
  isUpdate: boolean;
}

const schema = z.object({
  name: z
    .string()
    .min(1, { message: "translation:validation:required_question" })
    .max(250, { message: "translation:validation:less_than_250" }),
  description: z
    .string()
    .max(5000, { message: "translation:validation:less_than_5000" })
    .optional(),
});

export type FormData = z.infer<typeof schema>;

export const LocationForm = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  isUpdate,
  shallResetFormData,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: formData,
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset(formData);
  }, [formData, shallResetFormData]);

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title={
        isUpdate
          ? t("translation:settings:locations:edit_location")
          : t("translation:settings:locations:new_location")
      }
      subtitle={
        isUpdate
          ? t("translation:settings:locations:edit_location_descriptions")
          : t("translation:settings:locations:new_location_description")
      }
    >
      <SlideOverForm
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <Button
              variant="outlined"
              type="button"
              size="lg"
              onClick={onClose}
              data-testid="cancel-button"
            >
              {t("translation:common:cancel")}
            </Button>

            <Button
              variant="contained"
              size="lg"
              type="submit"
              data-testid="submit-button"
              disabled={Boolean(errors.name || errors.description)}
            >
              {isUpdate
                ? t("translation:common:update")
                : t("translation:common:create")}
            </Button>
          </>
        }
      >
        <SlideOverFormInputGroup
          id="name"
          label={t("translation:settings:locations:location_name")}
          errorMessage={getTranslation(t, errors.name?.message)}
          {...register("name")}
        />

        <SlideOverFormTextareaGroup
          id="description"
          label={t("translation:common:description")}
          errorMessage={getTranslation(t, errors.description?.message)}
          rows={5}
          {...register("description")}
        />
      </SlideOverForm>
    </SlideOver>
  );
};
