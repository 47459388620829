import clsx from "clsx";
import * as React from "react";

import { useCarousel } from "./hook";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CarouselContent = React.forwardRef<HTMLDivElement, Props>(
  ({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel();

    return (
      <div ref={carouselRef} className="flex-grow overflow-hidden px-5">
        <div
          ref={ref}
          className={clsx(
            "flex",
            orientation === "horizontal" ? "flex-row" : "flex-col",
            className
          )}
          {...props}
        />
      </div>
    );
  }
);
CarouselContent.displayName = "CarouselContent";

export { CarouselContent };
