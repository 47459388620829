/**
 *
 *
 * useDebounce
 *
 *
 */
import { useCallback, useRef } from "react";

export const useDebounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  wait = 200
) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: T) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => callback(...args), wait);
    },
    [callback, wait]
  );
};
