/**
 *
 *
 * useConfirmationModal
 *
 *
 */
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Props as ConfirmationModalProps } from "../components/ModalConfirmation/ModalConfirmation";

interface Context {
  confirmationModalContext: ConfirmationModalProps;
  setConfirmationModalContext: (
    newPageContext: Partial<ConfirmationModalProps>
  ) => void;
}

const ModalConfirmationContext = createContext<Context | undefined>(undefined);

export const ConfirmationModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t, i18n } = useTranslation();
  const [confirmationModalContext, _setConfirmationModalContext] =
    React.useState<ConfirmationModalProps>({
      isOpen: false,
      title: "",
      description: "",
      cancelButtonTitle: t("translation:common:cancel"),
      confirmButtonTitle: t("translation:common:confirm"),
      variant: "warn",
      onConfirm: () => {},
      onCancel: () => {},
      isLoading: false,
    });

  useEffect(() => {
    _setConfirmationModalContext({
      ...confirmationModalContext,
      cancelButtonTitle: t("translation:common:cancel"),
      confirmButtonTitle: t("translation:common:confirm"),
    });
  }, [i18n.language]);

  const setConfirmationModalContext = (
    newContext: Partial<ConfirmationModalProps>
  ) => {
    _setConfirmationModalContext((previous) => ({
      ...previous,
      ...newContext,
    }));
  };

  return (
    <ModalConfirmationContext.Provider
      value={{ confirmationModalContext, setConfirmationModalContext }}
    >
      {children}
    </ModalConfirmationContext.Provider>
  );
};
export const useConfirmationModal = () => {
  const context = React.useContext(ModalConfirmationContext);
  if (context === undefined) {
    throw new Error(
      "useConfirmationModal must be used within a ConfirmationModalProvider"
    );
  }

  const onCancel = () => {
    context.setConfirmationModalContext({
      isOpen: false,
    });
  };

  const openConfirmationModal = (
    newContext: Omit<Partial<ConfirmationModalProps>, "isOpen" | "onCancel">
  ) => {
    context.setConfirmationModalContext({
      ...newContext,
      isOpen: true,
      onCancel,
    });
  };

  const closeConfirmationModal = () => {
    context.setConfirmationModalContext({ isOpen: false });
  };

  return {
    ...context,
    openConfirmationModal,
    closeConfirmationModal,
  };
};
