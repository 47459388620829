import React from "react";

export const withHook =
  <C extends object, H>(
    Component: React.ComponentType<C>,
    hook: (props: H) => C
  ) =>
  // eslint-disable-next-line react/display-name
  (props: H) => {
    return <Component {...hook(props)} />;
  };
