/**
 *
 *
 * <IconMicro365 />
 *
 *
 */

interface IconMicro365Props {
  size?: number;
}
export const IconMicro365 = ({ size = 24 }: IconMicro365Props) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_446_4799)">
          <path d="M11.4062 11.4062H0V0H11.4062V11.4062Z" fill="#F1511B" />
          <path
            d="M23.9998 11.4062H12.5938V0H23.9998V11.4062Z"
            fill="#80CC28"
          />
          <path d="M11.4059 24.0002H0V12.594H11.4059V24.0002Z" fill="#00ADEF" />
          <path
            d="M23.9998 24.0002H12.5938V12.594H23.9998V24.0002Z"
            fill="#FBBC09"
          />
        </g>
        <defs>
          <clipPath id="clip0_446_4799">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
