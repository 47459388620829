/**
 *
 *
 * <ChallengeWizardActivities />
 *
 *
 */
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Reorder } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeCreateProgress } from "../../components/ChallengeCreateProgress";
import { useLayoutContext } from "../../components/LayoutProvider";
import { SkeletonChallengeActivities } from "../../components/SkeletonChallengeActivitiesV3";
import { AddActivityModal } from "../AddActivityModal";
import ReorderItem from "./ReorderItem";
import { useChallengeWizardActivities } from "./useChallengeWizardActivities";

export const ChallengeWizardActivities = (
  props: ReturnType<typeof useChallengeWizardActivities>
) => {
  const { t } = useTranslation();
  const { isMobileMode } = useLayoutContext();

  return (
    <div className="grid grid-rows-[auto_auto_auto_1fr_auto] overflow-hidden">
      <ChallengeCreateProgress
        title={t("translation:challenge:add_activities")}
        total={5}
        current={2}
      />
      {/**
       *
       * Challenge header
       *
       */}
      <div className={clsx("px-4", !isMobileMode && "sm:px-8")}>
        <div
          className={clsx(
            "relative grid pt-[9.5rem]",
            !isMobileMode && "sm:pl-20 sm:pt-0"
          )}
        >
          <div
            className={clsx(
              "w-full bg-white",
              "flex min-h-full flex-col gap-y-4 overflow-hidden",
              !isMobileMode && "sm:min-h-40 sm:py-4 sm:pl-4"
            )}
          >
            <div className="flex flex-grow flex-col gap-y-2">
              <span className="sr-only">Challenge Title</span>
              <div
                className={clsx(
                  "relative w-full rounded-lg bg-zinc-100 p-4",
                  !isMobileMode && "sm:py-2 sm:pl-20"
                )}
              >
                <h1
                  className={clsx(
                    "mb-1 truncate whitespace-nowrap text-center text-lg font-semibold leading-7 text-zinc-900",
                    !isMobileMode && "sm:text-left"
                  )}
                >
                  {props.template?.name}
                </h1>
                <div
                  className={clsx(
                    "text-center text-sm leading-5 text-zinc-600",
                    !isMobileMode && "sm:text-left"
                  )}
                >
                  {t("translation:challenge:template")}
                </div>
              </div>

              <span className="sr-only">Challenge Description</span>
              <p
                className={clsx(
                  "flex-grow whitespace-pre-line pl-0 text-center text-sm font-normal leading-5 text-zinc-900",
                  !isMobileMode && "sm:min-h-14 sm:pl-20 sm:text-left"
                )}
              >
                {props.template?.description}
              </p>
            </div>
          </div>

          {/**
           *
           * Badge
           *
           */}
          <div
            className={clsx(
              "absolute h-40 w-40",
              "left-1/2 top-0 -translate-x-1/2 ",
              "rounded-full bg-zinc-100",
              !isMobileMode &&
                "sm:left-0 sm:top-20 sm:-translate-y-1/2 sm:translate-x-0"
            )}
          >
            {props.template?.banner ? (
              <div
                className={clsx(
                  "h-full w-full bg-cover bg-center",
                  "rounded-full border-4 border-white shadow-md"
                )}
                style={{
                  backgroundImage: `url(${props.template?.banner.medium})`,
                }}
              />
            ) : (
              <div
                className={clsx(
                  "pointer-events-none h-full w-full rounded-full border-4 border-white shadow-md",
                  "bg-zinc-200"
                )}
              />
            )}
          </div>
        </div>
      </div>

      {/**
       *
       * Activities
       *
       */}
      <div
        className={clsx(
          "flex justify-end px-4 py-1.5",
          !isMobileMode && "sm:px-8"
        )}
      >
        <button
          className={clsx(
            "flex cursor-pointer items-center gap-x-1 px-4 text-xs font-semibold leading-4 text-zinc-500"
          )}
          onClick={props.onAddActivity}
        >
          <PlusIcon className="h-4 w-4 text-zinc-400" />
          {t("translation:challenge:add_activity")}
        </button>
      </div>
      <div
        className={clsx(
          "gird-rows-[auto_1fr] grid overflow-hidden px-4",
          !isMobileMode && "sm:grid-cols-[auto_1fr] sm:px-8"
        )}
      >
        {/**
         * Tab
         */}
        <div
          className={clsx(
            "h-fit w-full flex-shrink-0 p-4",
            "text-center text-sm text-zinc-600",
            "leading-4",
            !isMobileMode && "sm:w-44 sm:px-2 sm:text-sm"
          )}
        >
          <div className="mb-2 font-semibold">
            {t("translation:challenge:must_complete_all_activities")}
          </div>
          <div className="font-normal">
            {t("translation:challenge:click_drag_reorder")}
          </div>
        </div>
        {/**
         * List
         */}
        <Reorder.Group
          axis="y"
          className={clsx(
            "rounded-lg bg-zinc-100 p-4",
            "text-center text-base font-medium",
            "grid content-start gap-y-6 overflow-y-auto",
            !isMobileMode && "sm:bg-zinc-100 sm:p-6"
          )}
          values={props.activities}
          onReorder={props.onReorder}
        >
          {props.isLoading && <SkeletonChallengeActivities />}
          {!props.isLoading &&
            props.activities?.map((activity, i) => (
              <ReorderItem
                key={activity.id}
                index={i}
                activity={activity}
                onEditClick={() => props.onEditActivity(activity.id!)}
                onDeleteClick={() => props.onDeleteActivity(activity.id!)}
              />
            ))}
        </Reorder.Group>
      </div>
      <div className="flex flex-row items-center justify-end gap-x-4 bg-white px-4 py-4 lg:px-8">
        <Button
          variant="text"
          size="md"
          onClick={props.onCancel}
          disabled={props.isLoading}
        >
          {t("translation:common:cancel")}
        </Button>
        <Button
          variant="outlined"
          size="md"
          onClick={props.onBack}
          disabled={props.isCreating}
        >
          <ChevronLeftIcon className="h-5 text-zinc-900" />{" "}
          {t("translation:common:back")}
        </Button>
        <Button
          variant="challenge"
          size="md"
          onClick={props.onNext}
          disabled={!props.isNextEnabled || props.isCreating}
        >
          {t("translation:challenge:save_continue")}{" "}
          <ChevronRightIcon className="h-5 text-white" />
        </Button>
      </div>
      <AddActivityModal queryKey={props.queryKey} />
    </div>
  );
};
// Lazy load requires a default export
export default ChallengeWizardActivities;
