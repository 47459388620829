import clsx from "clsx";
import * as React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const CarouselItem = React.forwardRef<HTMLDivElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={clsx("min-w-0 shrink-0 grow-0", className)}
        {...props}
      />
    );
  }
);
CarouselItem.displayName = "CarouselItem";

export { CarouselItem };
