/**
 *
 *
 * <LanguageSelect />
 *
 *
 */
import { Combobox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Spinner } from "../../components/Spinner";
import { LanguageEnumLanguage } from "../../services/teambuilder/schemas";
import { useLanguageSelect } from "./useLanguageSelect";

export const LanguageSelect = (props: ReturnType<typeof useLanguageSelect>) => {
  const { t } = useTranslation();

  return (
    <>
      <Combobox
        name="language"
        defaultValue={props.defaultValue}
        onChange={(lang: LanguageEnumLanguage) => props.setValue(lang)}
        nullable
      >
        <Combobox.Label className="sr-only">
          {t("translation:common:user_search")}
        </Combobox.Label>
        <div className="z-1 relative">
          <Combobox.Input
            className={clsx(
              // Styles from InputV3
              "px-3 py-2",
              "block w-full rounded-md border-0",
              "ring-1 ring-inset ring-zinc-300",
              "focus:ring-2 focus:ring-inset focus:ring-zinc-300",
              "text-sm text-zinc-900 placeholder:text-zinc-500"
            )}
            displayValue={(code: string) =>
              props.languages?.data.find((lang) => lang.code === code)?.name ||
              ""
            }
            onChange={(e) => props.setSearchTerm(e.target.value)}
            placeholder={
              props.placeholder || t("translation:common:type_language")
            }
            ref={props.inputRef}
          />

          <button
            className="absolute right-2 top-2 text-zinc-400"
            type="button"
          >
            <span className="sr-only">{t("translation:common:clear")}</span>
            <XMarkIcon
              onClick={() => {
                if (props.inputRef.current) props.inputRef.current.value = "";
                props.setValue(null);
              }}
              className="h-5 w-5"
            />
          </button>
        </div>

        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options
            className={clsx(
              "absolute z-40 mt-1 max-h-56",
              "w-full overflow-auto rounded-md bg-white py-1",
              "border border-zinc-300 text-sm focus:outline-none"
            )}
          >
            {props.isLoading ? (
              <li className="flex justify-center p-2">
                <Spinner />
              </li>
            ) : props.languages?.data.length ? (
              props.languages?.data.map((language) => (
                <Combobox.Option
                  key={language.code}
                  value={language.code}
                  className={clsx(
                    "text-zinc-900 hover:bg-zinc-100 ui-active:bg-zinc-100",
                    "cursor-pointer p-2"
                  )}
                  disabled={
                    props.disabledItems &&
                    !!props.disabledItems.find((item) => item === language.code)
                  }
                >
                  {language.name}
                </Combobox.Option>
              ))
            ) : (
              <li className="p-2 text-zinc-600">
                🤔 {t("translation:common:no_language")}
              </li>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </>
  );
};
