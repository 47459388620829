/**
 *
 *
 * <Layout />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Outlet } from "react-router-dom";

import { ModalConfirmation } from "../../components/ModalConfirmation";
import { ChallengeTemplateModal } from "../ChallengeTemplateModalV3";
import { PostList } from "../PostListV3";
import { ProfileModal } from "../ProfileModal";
import { Sidebar } from "../Sidebar";
import { TopBar } from "../TopBarV3";
import { NotificationsModal } from "./NotificationsModal";
import { useLayout } from "./useLayout";

export const Layout = (props: ReturnType<typeof useLayout>) => {
  return (
    <>
      <div
        className={clsx(
          "relative bg-white",
          "h-screen min-h-screen overflow-hidden antialiased shadow",
          "grid w-screen grid-rows-[auto_1fr]"
        )}
      >
        <TopBar />

        <div
          className={clsx(
            "relative w-full sm:min-h-screen",
            "flex animate-crossfadein flex-row overflow-y-auto"
          )}
        >
          <Sidebar />

          <main
            className={clsx(
              "flex flex-auto flex-col overflow-y-auto sm:h-screen",
              "w-full transition-all duration-300 ease-in-out",
              props.isFocusMode ? "max-w-0" : "max-w-full"
            )}
          >
            <div className="flex-auto">
              <Outlet />
            </div>
          </main>

          <aside
            className={clsx(
              "w-full",
              "transition-all duration-300 ease-in-out",
              props.isFocusMode
                ? "max-w-full"
                : "max-w-sm border-l border-zinc-300",
              "hidden lg:flex"
            )}
          >
            <PostList />
          </aside>
        </div>
      </div>

      <NotificationsModal
        isOpen={props.isNotificationsModalOpen}
        onClose={props.closeNotificationsModal}
      />

      <ProfileModal />

      <ChallengeTemplateModal />

      <ModalConfirmation {...props.confirmationModalContext} />
    </>
  );
};
