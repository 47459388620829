/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */

export type PeopleListOrdering =
  (typeof PeopleListOrdering)[keyof typeof PeopleListOrdering];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleListOrdering = {
  "-created_at": "-created_at",
  "-date_joined": "-date_joined",
  "-email": "-email",
  "-first_name": "-first_name",
  "-id": "-id",
  "-last_name": "-last_name",
  "-roles__name": "-roles__name",
  "-updated_at": "-updated_at",
  "-user_guide__birth_date": "-user_guide__birth_date",
  "-user_guide__start_date": "-user_guide__start_date",
  created_at: "created_at",
  date_joined: "date_joined",
  email: "email",
  first_name: "first_name",
  id: "id",
  last_name: "last_name",
  roles__name: "roles__name",
  updated_at: "updated_at",
  user_guide__birth_date: "user_guide__birth_date",
  user_guide__start_date: "user_guide__start_date",
} as const;
