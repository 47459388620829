import { withHook } from "../../utils";
import { PrivacyAndSecurity as PrivacyAndSecurityComponent } from "./PrivacyAndSecurity";
import { usePrivacyAndSecurity } from "./usePrivacyAndSecurity";

export const PrivacyAndSecurity = withHook(
  PrivacyAndSecurityComponent,
  usePrivacyAndSecurity
);
// Lazy load requires a default export
export default PrivacyAndSecurity;
