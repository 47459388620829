/**
 *
 *
 * usePostList
 *
 *
 */
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import {
  getDepartmentsListQueryKey,
  useDepartmentsCreate,
  useDepartmentsDestroy,
  useDepartmentsList,
  useDepartmentsPartialUpdate,
} from "../../services/teambuilder/endpoints/departments/departments";
import { usePeopleMeRetrieve } from "../../services/teambuilder/endpoints/people/people";
import {
  DepartmentModel,
  DepartmentModelRequest,
  DepartmentsListParams,
  PaginatedDepartmentModelList,
  PatchedDepartmentModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { FormData } from "./DepartmentForm";

const emptyDepartment: FormData = {
  name: "",
  description: "",
};

export const useDepartments = () => {
  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  // Preserve the Department id for updates and deletes
  const [departmentId, setDepartmentId] = useState<number | undefined>();
  // formData populates the SlideOver form. The form will reset whenever formData changes.
  const [formData, setFormData] = useState<FormData>(emptyDepartment);
  const [shallResetFormData, setShallResetFormData] = useState(false);
  // Set the toast message before opening to prevent FOUC.
  const { openToast } = useToast();
  const showToast = (message: string) => {
    openToast({
      title: message,
    });
  };

  const params: DepartmentsListParams = {
    ordering: "name",
    limit: 999,
  };

  const queryKey = getDepartmentsListQueryKey(params);
  const { data: userData } = usePeopleMeRetrieve();
  const { data: departments = [], isLoading } = useDepartmentsList(params, {
    query: {
      select: ({ data }) => data,
    },
  });

  /**
   * Show the form and populate the form data.
   * If a department is received, assume this is an update.
   */
  const openSlideOver = (department?: DepartmentModel) => {
    // Set department ID for use in update requests.
    setShallResetFormData(false);
    setDepartmentId(department?.id);
    setFormData(
      department
        ? { name: department.name || "", description: department.description }
        : emptyDepartment
    );
    setIsFormOpen(true);
  };

  const closeSlideOver = () => {
    setIsFormOpen(false);
  };

  const handleDelete = (departmentId?: number) => {
    if (!departmentId)
      showToast(t("translation:toast:department_delete_failed"));
    setDepartmentId(departmentId);
    setIsConfirmationModalOpen(true);
  };

  /**
   *
   * Create
   *
   */
  // Define generics for <error, context>. Context is the return department of onMutate.
  // Context is passed from onMutate to onSuccess and onError.

  const { mutate: createDepartment } = useDepartmentsCreate(
    optimisticMutationOptions<
      DepartmentModel,
      PaginatedDepartmentModelList,
      { data: DepartmentModelRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        setShallResetFormData(true);
        // Reset the form
        setFormData(emptyDepartment);
        closeSlideOver();
        if (!requestVariable?.data) return context;
        return {
          meta: context.meta,
          data: [...context.data, requestVariable?.data].sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:department_add_success"));
      },
      onError: () => {
        showToast(t("translation:toast:location_add_failed"));
      },
    })
  );

  /**
   *
   * Update
   *
   */

  const { mutate: updateDepartment } = useDepartmentsPartialUpdate(
    optimisticMutationOptions<
      DepartmentModel,
      PaginatedDepartmentModelList,
      { id: number; data: PatchedDepartmentModelRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        setFormData(emptyDepartment);
        closeSlideOver();
        return {
          meta: context.meta,
          data: context.data
            .reduce<DepartmentModel[]>((acc, department) => {
              let v = department;
              if (department.id === requestVariable?.id) {
                v = { ...department, ...requestVariable?.data };
              }
              return [...acc, v];
            }, [])
            .sort((a, b) => a.name.localeCompare(b.name)),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:department_update_success"));
      },
      onError: () => {
        showToast(t("translation:toast:department_update_failed"));
      },
    })
  );

  /**
   *
   * handleSubmit
   *
   */
  const handleSubmit: SubmitHandler<FormData> = (department: FormData) => {
    if (departmentId && userData?.id) {
      updateDepartment({
        id: departmentId,
        data: {
          user: userData.id,
          ...department,
        },
      });
    } else if (userData?.id) {
      createDepartment({
        data: {
          user: userData.id,
          ...department,
        },
      });
    }
  };
  /**
   *
   * Destroy
   *
   */

  const { mutate: destroyDepartment } = useDepartmentsDestroy(
    optimisticMutationOptions<
      void,
      PaginatedDepartmentModelList,
      { id: number }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        return {
          ...context,
          data: context.data.filter((item) => item.id !== requestVariable?.id),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:department_delete_success"));
      },
      onError: () => {
        showToast(t("translation:toast:department_delete_failed"));
      },
    })
  );

  const modalOnConfirmation = () => {
    departmentId && destroyDepartment({ id: departmentId });
    setIsConfirmationModalOpen(false);
  };

  const onClose = () => {
    setIsConfirmationModalOpen(false);
    setDepartmentId(undefined);
  };

  return {
    isFormOpen,
    isConfirmationModalOpen,
    shallResetFormData,
    formData,
    departments,
    departmentId,
    isLoading,
    modalOnConfirmation,
    handleDelete,
    handleSubmit,
    onClose,
    openSlideOver,
    closeSlideOver,
  };
};
