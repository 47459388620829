/**
 *
 *
 * <AppLoader />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Trans } from "react-i18next";

import { redirectToAuth } from "../../utils/redirect-to-auth";
import { Button } from "../ButtonV3";
import { TeamBuilderLogo } from "../TeamBuilderLogoV3";

interface Props {
  error?: boolean;
}

export const AppLoader = ({ error }: Props) => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-white">
      <TeamBuilderLogo
        className={clsx("max-h-24 sm:block", error ? "" : "animate-pulse")}
        variant="by-intuition"
      />
      {error && (
        <p className="mt-8 text-zinc-900">
          <Trans
            i18nKey="translation:common:login_again"
            components={{
              a: (
                <button
                  className="font-medium text-zinc-500 underline hover:text-zinc-600"
                  onClick={() => redirectToAuth()}
                />
              ),
            }}
          />
          .
        </p>
      )}
    </div>
  );
};
