export enum ViewModeEnum {
  VIEW_MODE_LIST = "list",
  VIEW_MODE_GRID = "grid",
}

/**
 * An enum for identifying challenge creation steps
 */
export enum CreateChallengeStep {
  SELECT_TEMPLATE = 1,
  SELECT_ACTIVITIES,
  INVITE_PEOPLES,
  SELECT_DATES,
  PREVIEW,
}
