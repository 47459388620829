/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `created the department` - created the department
 * `created the location` - created the location
 * `created the team` - created the team
 * `renamed the department` - renamed the department
 * `renamed the location` - renamed the location
 * `renamed the team` - renamed the team
 * `joined the team` - joined the team
 * `shouted out to` - shouted out to
 * `banter` - banter
 * `started the challenge` - started the challenge
 * `ended the challenge` - ended the challenge
 * `completed a challenge activity` - completed a challenge activity
 * `` -
 */
export type PostAction = (typeof PostAction)[keyof typeof PostAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAction = {
  created_the_department: "created the department",
  created_the_location: "created the location",
  created_the_team: "created the team",
  renamed_the_department: "renamed the department",
  renamed_the_location: "renamed the location",
  renamed_the_team: "renamed the team",
  joined_the_team: "joined the team",
  shouted_out_to: "shouted out to",
  banter: "banter",
  started_the_challenge: "started the challenge",
  ended_the_challenge: "ended the challenge",
  completed_a_challenge_activity: "completed a challenge activity",
  "": "",
} as const;
