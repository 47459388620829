/**
 *
 *
 * Router
 *
 *
 */
import { Navigate, RouteObject } from "react-router-dom";

import { NotFound } from "../components/NotFound";
import { App } from "../containers/App";
import { loader as appLoader } from "../containers/App/loader";
import { Banter } from "../containers/BanterV3";
import { Billing } from "../containers/BillingV3";
import { ChallengeDetail } from "../containers/ChallengeDetailV3";
import { ChallengeWizard } from "../containers/ChallengeWizard";
import { ChallengeWizardActivities } from "../containers/ChallengeWizardActivities";
import { ChallengeWizardDates } from "../containers/ChallengeWizardDates";
import { ChallengeWizardInvitePeoples } from "../containers/ChallengeWizardInvitePeoples";
import { ChallengeWizardPreview } from "../containers/ChallengeWizardPreview";
import { ChallengeWizardTemplates } from "../containers/ChallengeWizardTemplates";
import { Challenges } from "../containers/ChallengesV3";
import { DepartmentsLocationsV3 } from "../containers/DepartmentsLocationsV3";
import { EditQuestions } from "../containers/EditQuestions";
import { GeneralSettings } from "../containers/GeneralSettings";
import { Home } from "../containers/Home";
import { People } from "../containers/PeopleV3";
import { PrivacyAndSecurity } from "../containers/PrivacyAndSecurityV3";
import { authorizationLoader } from "./authorizationLoader";

export const createRoutes = (/* _: QueryClient*/): RouteObject[] => [
  {
    path: "/",
    element: <App />,
    loader: appLoader(),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "challenges",
        children: [
          {
            index: true,
            element: <Challenges />,
          },
          {
            path: ":challengeId",
            element: <ChallengeDetail />,
          },
          {
            path: "wizard",
            element: <ChallengeWizard />,
            children: [
              {
                index: true,
                element: <ChallengeWizardTemplates />,
              },
              {
                path: "add-activities",
                element: <ChallengeWizardActivities />,
              },
              {
                path: "invite-people",
                element: <ChallengeWizardInvitePeoples />,
              },
              {
                path: "select-dates",
                element: <ChallengeWizardDates />,
              },
              {
                path: "publish",
                element: <ChallengeWizardPreview />,
              },
            ],
          },
        ],
      },
      {
        path: "people",
        element: <People />,
      },
      /**
       *
       *
       * Renamed to /company-settings.
       *
       *
       */
      {
        path: "company",
        loader: authorizationLoader(["HR", "IT"]),
        errorElement: <Navigate to="/" replace={true} />,
        element: <Navigate to="/company-settings" />,
      },
      /**
       *
       * /settings replaces /company endpoints.
       *
       * TODO: After launch, redirect all /company endpoints to /settings
       *
       */
      {
        path: "company-settings",
        loader: authorizationLoader(["HR", "IT"]),
        errorElement: <Navigate to="/" replace={true} />,

        children: [
          {
            index: true,
            element: <Navigate to="general" replace={true} />,
          },
          {
            path: "general",
            element: <GeneralSettings />,
          },
          {
            path: "billing",
            element: <Billing />,
          },
          {
            path: "banter",
            element: <Banter />,
          },
          {
            path: "departments-and-locations",
            element: <DepartmentsLocationsV3 />,
          },
          {
            path: "profile-settings",
            element: <EditQuestions />,
          },
        ],
      },
      {
        path: "privacy-and-security",
        element: <PrivacyAndSecurity />,
      },
    ],
  },

  // Not Found
  {
    path: "*",
    element: <NotFound />,
  },
];
