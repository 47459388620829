/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  AnswerBulkEditRequest,
  AnswerModel,
  AnswerModelRequest,
  AwardAndRecognition,
  AwardAndRecognitionRequest,
  CoffeeChatMatch,
  CoffeeChatMatchRequest,
  CoffeeChatTopic,
  CoffeeChatTopicRequest,
  MentorMatch,
  MentorMatchRequest,
  MentorTopic,
  MentorTopicRequest,
  PTDiSC,
  PTDiSCRequest,
  PTKolbe,
  PTKolbeRequest,
  PTPMeyersBriggs,
  PTPMeyersBriggsRequest,
  PTSixteenPF,
  PTSixteenPFRequest,
  PaginatedAnswerModelList,
  PaginatedAwardAndRecognitionList,
  PaginatedCoffeeChatMatchList,
  PaginatedCoffeeChatTopicList,
  PaginatedMentorMatchList,
  PaginatedMentorTopicList,
  PaginatedPTDiSCList,
  PaginatedPTKolbeList,
  PaginatedPTPMeyersBriggsList,
  PaginatedPTSixteenPFList,
  PaginatedPhoneNumberList,
  PaginatedQuestionModelList,
  PaginatedSchoolsList,
  PaginatedTrainingAndCertificationList,
  PaginatedUserGuidesList,
  PatchedAnswerModelRequest,
  PatchedAwardAndRecognitionRequest,
  PatchedCoffeeChatMatchRequest,
  PatchedCoffeeChatTopicRequest,
  PatchedMentorMatchRequest,
  PatchedMentorTopicRequest,
  PatchedPTDiSCRequest,
  PatchedPTKolbeRequest,
  PatchedPTPMeyersBriggsRequest,
  PatchedPTSixteenPFRequest,
  PatchedPhoneNumberRequest,
  PatchedQuestionModelRequest,
  PatchedSchoolsRequest,
  PatchedTrainingAndCertificationRequest,
  PatchedUserGuidesRequest,
  PhoneNumber,
  PhoneNumberBulkEditRequest,
  PhoneNumberRequest,
  QuestionModel,
  QuestionModelRequest,
  Schools,
  SchoolsRequest,
  TrainingAndCertification,
  TrainingAndCertificationRequest,
  UserGuideCoverImageUploadRequest,
  UserGuides,
  UserGuidesRequest,
  Userguides16pfListParams,
  UserguidesAnswersListParams,
  UserguidesAwardsListParams,
  UserguidesCoffeeChatsMatchesListParams,
  UserguidesCoffeeChatsTopicsListParams,
  UserguidesDiscListParams,
  UserguidesKolbeListParams,
  UserguidesListParams,
  UserguidesMbtiListParams,
  UserguidesMentorsMatchesListParams,
  UserguidesMentorsTopicsListParams,
  UserguidesPhonesListParams,
  UserguidesQuestionsListParams,
  UserguidesSchoolsListParams,
  UserguidesTrainingListParams,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # User Guides (About Me)

User Guides information for all users

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
## Ordering

The `start_date` and `birth_date` fields are specially handled to prioritize upcoming dates.
For instance, if the current date is March 29, the ordering will ensure that March 30 and onward are listed first,
treating any date before today's date as less immediate.
 */
export const userguidesList = (
  params?: UserguidesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedUserGuidesList>(
    { url: `/api/v1/userguides/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesListQueryKey = (params?: UserguidesListParams) => {
  return [`/api/v1/userguides/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserguidesListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userguidesList>>> = ({
    signal,
  }) => userguidesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesList>>
>;
export type UserguidesListQueryError = ErrorType<unknown>;

export const useUserguidesList = <
  TData = Awaited<ReturnType<typeof userguidesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # User Guides (About Me)

User Guides information for all users

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
## Ordering

The `start_date` and `birth_date` fields are specially handled to prioritize upcoming dates.
For instance, if the current date is March 29, the ordering will ensure that March 30 and onward are listed first,
treating any date before today's date as less immediate.
 */
export const userguidesCreate = (
  userGuidesRequest: UserGuidesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserGuides>(
    {
      url: `/api/v1/userguides/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userGuidesRequest,
    },
    options
  );
};

export const getUserguidesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCreate>>,
    TError,
    { data: UserGuidesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCreate>>,
  TError,
  { data: UserGuidesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCreate>>,
    { data: UserGuidesRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCreate>>
>;
export type UserguidesCreateMutationBody = UserGuidesRequest;
export type UserguidesCreateMutationError = ErrorType<unknown>;

export const useUserguidesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCreate>>,
    TError,
    { data: UserGuidesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # User Guides (About Me)

User Guides information for all users

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
## Ordering

The `start_date` and `birth_date` fields are specially handled to prioritize upcoming dates.
For instance, if the current date is March 29, the ordering will ensure that March 30 and onward are listed first,
treating any date before today's date as less immediate.
 */
export const userguidesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<UserGuides>(
    { url: `/api/v1/userguides/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/${id}/`] as const;
};

export const getUserguidesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserguidesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesRetrieve>>
  > = ({ signal }) => userguidesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesRetrieve>>
>;
export type UserguidesRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # User Guides (About Me)

User Guides information for all users

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
## Ordering

The `start_date` and `birth_date` fields are specially handled to prioritize upcoming dates.
For instance, if the current date is March 29, the ordering will ensure that March 30 and onward are listed first,
treating any date before today's date as less immediate.
 */
export const userguidesPartialUpdate = (
  id: number,
  patchedUserGuidesRequest: PatchedUserGuidesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserGuides>(
    {
      url: `/api/v1/userguides/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserGuidesRequest,
    },
    options
  );
};

export const getUserguidesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserGuidesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesPartialUpdate>>,
  TError,
  { id: number; data: PatchedUserGuidesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesPartialUpdate>>,
    { id: number; data: PatchedUserGuidesRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPartialUpdate>>
>;
export type UserguidesPartialUpdateMutationBody = PatchedUserGuidesRequest;
export type UserguidesPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserGuidesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # User Guides (About Me)

User Guides information for all users

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
## Ordering

The `start_date` and `birth_date` fields are specially handled to prioritize upcoming dates.
For instance, if the current date is March 29, the ordering will ensure that March 30 and onward are listed first,
treating any date before today's date as less immediate.
 */
export const userguidesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDestroy>>
>;

export type UserguidesDestroyMutationError = ErrorType<unknown>;

export const useUserguidesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * UserGuides cover image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own user guide
 * @summary UserGuides Cover Image Upload
 */
export const userguidesCoverImageUploadCreate = (
  id: number,
  userGuideCoverImageUploadRequest: UserGuideCoverImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (userGuideCoverImageUploadRequest.file !== null) {
    formData.append("file", userGuideCoverImageUploadRequest.file);
  }

  return axiosInstance<UserGuides | void>(
    {
      url: `/api/v1/userguides/${id}/cover_image_upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getUserguidesCoverImageUploadCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoverImageUploadCreate>>,
    TError,
    { id: number; data: UserGuideCoverImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoverImageUploadCreate>>,
  TError,
  { id: number; data: UserGuideCoverImageUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoverImageUploadCreate>>,
    { id: number; data: UserGuideCoverImageUploadRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesCoverImageUploadCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoverImageUploadCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoverImageUploadCreate>>
>;
export type UserguidesCoverImageUploadCreateMutationBody =
  UserGuideCoverImageUploadRequest;
export type UserguidesCoverImageUploadCreateMutationError = ErrorType<unknown>;

/**
 * @summary UserGuides Cover Image Upload
 */
export const useUserguidesCoverImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoverImageUploadCreate>>,
    TError,
    { id: number; data: UserGuideCoverImageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoverImageUploadCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * UserGuides cover image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own user guide
 * @summary UserGuides Cover Image Upload
 */
export const userguidesCoverImageUploadDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserGuides | void>(
    { url: `/api/v1/userguides/${id}/cover_image_upload/`, method: "DELETE" },
    options
  );
};

export const getUserguidesCoverImageUploadDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoverImageUploadDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoverImageUploadDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoverImageUploadDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesCoverImageUploadDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoverImageUploadDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoverImageUploadDestroy>>
>;

export type UserguidesCoverImageUploadDestroyMutationError = ErrorType<unknown>;

/**
 * @summary UserGuides Cover Image Upload
 */
export const useUserguidesCoverImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoverImageUploadDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoverImageUploadDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguides16pfList = (
  params?: Userguides16pfListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPTSixteenPFList>(
    { url: `/api/v1/userguides/16pf/`, method: "GET", params, signal },
    options
  );
};

export const getUserguides16pfListQueryKey = (
  params?: Userguides16pfListParams
) => {
  return [`/api/v1/userguides/16pf/`, ...(params ? [params] : [])] as const;
};

export const getUserguides16pfListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguides16pfList>>,
  TError = ErrorType<unknown>,
>(
  params?: Userguides16pfListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguides16pfList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguides16pfListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguides16pfList>>
  > = ({ signal }) => userguides16pfList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguides16pfList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Userguides16pfListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfList>>
>;
export type Userguides16pfListQueryError = ErrorType<unknown>;

export const useUserguides16pfList = <
  TData = Awaited<ReturnType<typeof userguides16pfList>>,
  TError = ErrorType<unknown>,
>(
  params?: Userguides16pfListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguides16pfList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguides16pfListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguides16pfCreate = (
  pTSixteenPFRequest: PTSixteenPFRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTSixteenPF>(
    {
      url: `/api/v1/userguides/16pf/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: pTSixteenPFRequest,
    },
    options
  );
};

export const getUserguides16pfCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfCreate>>,
    TError,
    { data: PTSixteenPFRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguides16pfCreate>>,
  TError,
  { data: PTSixteenPFRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguides16pfCreate>>,
    { data: PTSixteenPFRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguides16pfCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type Userguides16pfCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfCreate>>
>;
export type Userguides16pfCreateMutationBody = PTSixteenPFRequest;
export type Userguides16pfCreateMutationError = ErrorType<unknown>;

export const useUserguides16pfCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfCreate>>,
    TError,
    { data: PTSixteenPFRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguides16pfCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguides16pfRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTSixteenPF>(
    { url: `/api/v1/userguides/16pf/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguides16pfRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/16pf/${id}/`] as const;
};

export const getUserguides16pfRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguides16pfRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguides16pfRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguides16pfRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguides16pfRetrieve>>
  > = ({ signal }) => userguides16pfRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguides16pfRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Userguides16pfRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfRetrieve>>
>;
export type Userguides16pfRetrieveQueryError = ErrorType<unknown>;

export const useUserguides16pfRetrieve = <
  TData = Awaited<ReturnType<typeof userguides16pfRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguides16pfRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguides16pfRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguides16pfPartialUpdate = (
  id: number,
  patchedPTSixteenPFRequest: PatchedPTSixteenPFRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTSixteenPF>(
    {
      url: `/api/v1/userguides/16pf/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPTSixteenPFRequest,
    },
    options
  );
};

export const getUserguides16pfPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTSixteenPFRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguides16pfPartialUpdate>>,
  TError,
  { id: number; data: PatchedPTSixteenPFRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguides16pfPartialUpdate>>,
    { id: number; data: PatchedPTSixteenPFRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguides16pfPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type Userguides16pfPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfPartialUpdate>>
>;
export type Userguides16pfPartialUpdateMutationBody = PatchedPTSixteenPFRequest;
export type Userguides16pfPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguides16pfPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTSixteenPFRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguides16pfPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguides16pfDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/16pf/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguides16pfDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguides16pfDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguides16pfDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguides16pfDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type Userguides16pfDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfDestroy>>
>;

export type Userguides16pfDestroyMutationError = ErrorType<unknown>;

export const useUserguides16pfDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguides16pfDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguides16pfDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguides16pfMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTSixteenPF>(
    { url: `/api/v1/userguides/16pf/me/`, method: "GET", signal },
    options
  );
};

export const getUserguides16pfMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/16pf/me/`] as const;
};

export const getUserguides16pfMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguides16pfMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguides16pfMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguides16pfMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguides16pfMeRetrieve>>
  > = ({ signal }) => userguides16pfMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguides16pfMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type Userguides16pfMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguides16pfMeRetrieve>>
>;
export type Userguides16pfMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguides16pfMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguides16pfMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguides16pfMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguides16pfMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 *     # UserGuides Answers

    Users' Answers are stored keyed to the Question id.

    ## Permissions

    ```
    permission_classes = [
        lambda: iperm.OrPermission(
            iperm.AuthenticatedReadOnly,  # anyone can read answers
            iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
            iperm.HasRolePermission,  # required_roles can do anything
            iperm.IsAdminUser,  # as can the admin user
            iperm.AuthenticatedCreate,  # anyone can answer a question
        )
    ]
    required_roles = ["HR", "Manager"]
```
 */
export const userguidesAnswersList = (
  params?: UserguidesAnswersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedAnswerModelList>(
    { url: `/api/v1/userguides/answers/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesAnswersListQueryKey = (
  params?: UserguidesAnswersListParams
) => {
  return [`/api/v1/userguides/answers/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesAnswersListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAnswersList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesAnswersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAnswersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAnswersListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAnswersList>>
  > = ({ signal }) => userguidesAnswersList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAnswersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAnswersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersList>>
>;
export type UserguidesAnswersListQueryError = ErrorType<unknown>;

export const useUserguidesAnswersList = <
  TData = Awaited<ReturnType<typeof userguidesAnswersList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesAnswersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAnswersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAnswersListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new Answer.
 */
export const userguidesAnswersCreate = (
  answerModelRequest: AnswerModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AnswerModel>(
    {
      url: `/api/v1/userguides/answers/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: answerModelRequest,
    },
    options
  );
};

export const getUserguidesAnswersCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersCreate>>,
    TError,
    { data: AnswerModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAnswersCreate>>,
  TError,
  { data: AnswerModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAnswersCreate>>,
    { data: AnswerModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesAnswersCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAnswersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersCreate>>
>;
export type UserguidesAnswersCreateMutationBody = AnswerModelRequest;
export type UserguidesAnswersCreateMutationError = ErrorType<unknown>;

export const useUserguidesAnswersCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersCreate>>,
    TError,
    { data: AnswerModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesAnswersCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 *     # UserGuides Answers

    Users' Answers are stored keyed to the Question id.

    ## Permissions

    ```
    permission_classes = [
        lambda: iperm.OrPermission(
            iperm.AuthenticatedReadOnly,  # anyone can read answers
            iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
            iperm.HasRolePermission,  # required_roles can do anything
            iperm.IsAdminUser,  # as can the admin user
            iperm.AuthenticatedCreate,  # anyone can answer a question
        )
    ]
    required_roles = ["HR", "Manager"]
```
 */
export const userguidesAnswersRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AnswerModel>(
    { url: `/api/v1/userguides/answers/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesAnswersRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/answers/${id}/`] as const;
};

export const getUserguidesAnswersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAnswersRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAnswersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAnswersRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAnswersRetrieve>>
  > = ({ signal }) => userguidesAnswersRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAnswersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAnswersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersRetrieve>>
>;
export type UserguidesAnswersRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesAnswersRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesAnswersRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAnswersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAnswersRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 *     # UserGuides Answers

    Users' Answers are stored keyed to the Question id.

    ## Permissions

    ```
    permission_classes = [
        lambda: iperm.OrPermission(
            iperm.AuthenticatedReadOnly,  # anyone can read answers
            iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
            iperm.HasRolePermission,  # required_roles can do anything
            iperm.IsAdminUser,  # as can the admin user
            iperm.AuthenticatedCreate,  # anyone can answer a question
        )
    ]
    required_roles = ["HR", "Manager"]
```
 */
export const userguidesAnswersPartialUpdate = (
  id: number,
  patchedAnswerModelRequest: PatchedAnswerModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AnswerModel>(
    {
      url: `/api/v1/userguides/answers/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedAnswerModelRequest,
    },
    options
  );
};

export const getUserguidesAnswersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersPartialUpdate>>,
    TError,
    { id: number; data: PatchedAnswerModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAnswersPartialUpdate>>,
  TError,
  { id: number; data: PatchedAnswerModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAnswersPartialUpdate>>,
    { id: number; data: PatchedAnswerModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesAnswersPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAnswersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersPartialUpdate>>
>;
export type UserguidesAnswersPartialUpdateMutationBody =
  PatchedAnswerModelRequest;
export type UserguidesAnswersPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesAnswersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersPartialUpdate>>,
    TError,
    { id: number; data: PatchedAnswerModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesAnswersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 *     # UserGuides Answers

    Users' Answers are stored keyed to the Question id.

    ## Permissions

    ```
    permission_classes = [
        lambda: iperm.OrPermission(
            iperm.AuthenticatedReadOnly,  # anyone can read answers
            iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
            iperm.HasRolePermission,  # required_roles can do anything
            iperm.IsAdminUser,  # as can the admin user
            iperm.AuthenticatedCreate,  # anyone can answer a question
        )
    ]
    required_roles = ["HR", "Manager"]
```
 */
export const userguidesAnswersDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/answers/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesAnswersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAnswersDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAnswersDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesAnswersDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAnswersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersDestroy>>
>;

export type UserguidesAnswersDestroyMutationError = ErrorType<unknown>;

export const useUserguidesAnswersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesAnswersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Bulk edit answers according to the following logic:
 - If the object does not have an ID, create it
 - If the object does have an ID, update it
 - If the object has an ID and the answer field is empty, delete it
 - Question field is only required for Create
 * @summary userguides_answers_bulk_edit
 */
export const userguidesAnswersBulkEditCreate = (
  answerBulkEditRequest: AnswerBulkEditRequest[],
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/userguides/answers/bulk-edit/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: answerBulkEditRequest,
    },
    options
  );
};

export const getUserguidesAnswersBulkEditCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersBulkEditCreate>>,
    TError,
    { data: AnswerBulkEditRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAnswersBulkEditCreate>>,
  TError,
  { data: AnswerBulkEditRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAnswersBulkEditCreate>>,
    { data: AnswerBulkEditRequest[] }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesAnswersBulkEditCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAnswersBulkEditCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersBulkEditCreate>>
>;
export type UserguidesAnswersBulkEditCreateMutationBody =
  AnswerBulkEditRequest[];
export type UserguidesAnswersBulkEditCreateMutationError = ErrorType<unknown>;

/**
 * @summary userguides_answers_bulk_edit
 */
export const useUserguidesAnswersBulkEditCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAnswersBulkEditCreate>>,
    TError,
    { data: AnswerBulkEditRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesAnswersBulkEditCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesAnswersMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedAnswerModelList>(
    { url: `/api/v1/userguides/answers/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesAnswersMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/answers/me/`] as const;
};

export const getUserguidesAnswersMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAnswersMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>
  > = ({ signal }) => userguidesAnswersMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAnswersMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>
>;
export type UserguidesAnswersMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesAnswersMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAnswersMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAnswersMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesAwardsList = (
  params?: UserguidesAwardsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedAwardAndRecognitionList>(
    { url: `/api/v1/userguides/awards/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesAwardsListQueryKey = (
  params?: UserguidesAwardsListParams
) => {
  return [`/api/v1/userguides/awards/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesAwardsListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAwardsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesAwardsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAwardsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAwardsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAwardsList>>
  > = ({ signal }) => userguidesAwardsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAwardsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAwardsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsList>>
>;
export type UserguidesAwardsListQueryError = ErrorType<unknown>;

export const useUserguidesAwardsList = <
  TData = Awaited<ReturnType<typeof userguidesAwardsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesAwardsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAwardsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAwardsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesAwardsCreate = (
  awardAndRecognitionRequest: AwardAndRecognitionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AwardAndRecognition>(
    {
      url: `/api/v1/userguides/awards/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: awardAndRecognitionRequest,
    },
    options
  );
};

export const getUserguidesAwardsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsCreate>>,
    TError,
    { data: AwardAndRecognitionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAwardsCreate>>,
  TError,
  { data: AwardAndRecognitionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAwardsCreate>>,
    { data: AwardAndRecognitionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesAwardsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAwardsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsCreate>>
>;
export type UserguidesAwardsCreateMutationBody = AwardAndRecognitionRequest;
export type UserguidesAwardsCreateMutationError = ErrorType<unknown>;

export const useUserguidesAwardsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsCreate>>,
    TError,
    { data: AwardAndRecognitionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesAwardsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesAwardsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AwardAndRecognition>(
    { url: `/api/v1/userguides/awards/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesAwardsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/awards/${id}/`] as const;
};

export const getUserguidesAwardsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAwardsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAwardsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAwardsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAwardsRetrieve>>
  > = ({ signal }) => userguidesAwardsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAwardsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAwardsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsRetrieve>>
>;
export type UserguidesAwardsRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesAwardsRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesAwardsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesAwardsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAwardsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesAwardsPartialUpdate = (
  id: number,
  patchedAwardAndRecognitionRequest: PatchedAwardAndRecognitionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AwardAndRecognition>(
    {
      url: `/api/v1/userguides/awards/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedAwardAndRecognitionRequest,
    },
    options
  );
};

export const getUserguidesAwardsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAwardAndRecognitionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAwardsPartialUpdate>>,
  TError,
  { id: number; data: PatchedAwardAndRecognitionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAwardsPartialUpdate>>,
    { id: number; data: PatchedAwardAndRecognitionRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesAwardsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAwardsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsPartialUpdate>>
>;
export type UserguidesAwardsPartialUpdateMutationBody =
  PatchedAwardAndRecognitionRequest;
export type UserguidesAwardsPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesAwardsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAwardAndRecognitionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesAwardsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesAwardsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/awards/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesAwardsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesAwardsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesAwardsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesAwardsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesAwardsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsDestroy>>
>;

export type UserguidesAwardsDestroyMutationError = ErrorType<unknown>;

export const useUserguidesAwardsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesAwardsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesAwardsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesAwardsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AwardAndRecognition>(
    { url: `/api/v1/userguides/awards/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesAwardsMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/awards/me/`] as const;
};

export const getUserguidesAwardsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesAwardsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>
  > = ({ signal }) => userguidesAwardsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesAwardsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>
>;
export type UserguidesAwardsMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesAwardsMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesAwardsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesAwardsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsMatchesList = (
  params?: UserguidesCoffeeChatsMatchesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatMatchList>(
    {
      url: `/api/v1/userguides/coffee-chats-matches/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesListQueryKey = (
  params?: UserguidesCoffeeChatsMatchesListParams
) => {
  return [
    `/api/v1/userguides/coffee-chats-matches/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserguidesCoffeeChatsMatchesListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesCoffeeChatsMatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsMatchesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>
  > = ({ signal }) =>
    userguidesCoffeeChatsMatchesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsMatchesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>
>;
export type UserguidesCoffeeChatsMatchesListQueryError = ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesList = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesCoffeeChatsMatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesCoffeeChatsMatchesListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsMatchesCreate = (
  coffeeChatMatchRequest: CoffeeChatMatchRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatMatch>(
    {
      url: `/api/v1/userguides/coffee-chats-matches/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: coffeeChatMatchRequest,
    },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesCreate>>,
    TError,
    { data: CoffeeChatMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesCreate>>,
  TError,
  { data: CoffeeChatMatchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesCreate>>,
    { data: CoffeeChatMatchRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesCoffeeChatsMatchesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsMatchesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesCreate>>
>;
export type UserguidesCoffeeChatsMatchesCreateMutationBody =
  CoffeeChatMatchRequest;
export type UserguidesCoffeeChatsMatchesCreateMutationError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesCreate>>,
    TError,
    { data: CoffeeChatMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsMatchesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsMatchesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatMatch>(
    {
      url: `/api/v1/userguides/coffee-chats-matches/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/coffee-chats-matches/${id}/`] as const;
};

export const getUserguidesCoffeeChatsMatchesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsMatchesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>
  > = ({ signal }) =>
    userguidesCoffeeChatsMatchesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsMatchesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>
>;
export type UserguidesCoffeeChatsMatchesRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesCoffeeChatsMatchesRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsMatchesPartialUpdate = (
  id: number,
  patchedCoffeeChatMatchRequest: PatchedCoffeeChatMatchRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatMatch>(
    {
      url: `/api/v1/userguides/coffee-chats-matches/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCoffeeChatMatchRequest,
    },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesPartialUpdate>>,
  TError,
  { id: number; data: PatchedCoffeeChatMatchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesPartialUpdate>>,
    { id: number; data: PatchedCoffeeChatMatchRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesCoffeeChatsMatchesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsMatchesPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesPartialUpdate>>
  >;
export type UserguidesCoffeeChatsMatchesPartialUpdateMutationBody =
  PatchedCoffeeChatMatchRequest;
export type UserguidesCoffeeChatsMatchesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsMatchesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsMatchesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/coffee-chats-matches/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesCoffeeChatsMatchesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsMatchesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesDestroy>>
>;

export type UserguidesCoffeeChatsMatchesDestroyMutationError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsMatchesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesCoffeeChatsMatchesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatMatch>(
    {
      url: `/api/v1/userguides/coffee-chats-matches/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsMatchesMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/coffee-chats-matches/me/`] as const;
};

export const getUserguidesCoffeeChatsMatchesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsMatchesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>
  > = ({ signal }) =>
    userguidesCoffeeChatsMatchesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsMatchesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>
>;
export type UserguidesCoffeeChatsMatchesMeRetrieveQueryError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsMatchesMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsMatchesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getUserguidesCoffeeChatsMatchesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsTopicsList = (
  params?: UserguidesCoffeeChatsTopicsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatTopicList>(
    {
      url: `/api/v1/userguides/coffee-chats-topics/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsListQueryKey = (
  params?: UserguidesCoffeeChatsTopicsListParams
) => {
  return [
    `/api/v1/userguides/coffee-chats-topics/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserguidesCoffeeChatsTopicsListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesCoffeeChatsTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsTopicsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>
  > = ({ signal }) =>
    userguidesCoffeeChatsTopicsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsTopicsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>
>;
export type UserguidesCoffeeChatsTopicsListQueryError = ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsList = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesCoffeeChatsTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesCoffeeChatsTopicsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsTopicsCreate = (
  coffeeChatTopicRequest: CoffeeChatTopicRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatTopic>(
    {
      url: `/api/v1/userguides/coffee-chats-topics/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: coffeeChatTopicRequest,
    },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsCreate>>,
    TError,
    { data: CoffeeChatTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsCreate>>,
  TError,
  { data: CoffeeChatTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsCreate>>,
    { data: CoffeeChatTopicRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesCoffeeChatsTopicsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsTopicsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsCreate>>
>;
export type UserguidesCoffeeChatsTopicsCreateMutationBody =
  CoffeeChatTopicRequest;
export type UserguidesCoffeeChatsTopicsCreateMutationError = ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsCreate>>,
    TError,
    { data: CoffeeChatTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsTopicsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsTopicsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatTopic>(
    {
      url: `/api/v1/userguides/coffee-chats-topics/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/coffee-chats-topics/${id}/`] as const;
};

export const getUserguidesCoffeeChatsTopicsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsTopicsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>
  > = ({ signal }) =>
    userguidesCoffeeChatsTopicsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsTopicsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>
>;
export type UserguidesCoffeeChatsTopicsRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesCoffeeChatsTopicsRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsTopicsPartialUpdate = (
  id: number,
  patchedCoffeeChatTopicRequest: PatchedCoffeeChatTopicRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatTopic>(
    {
      url: `/api/v1/userguides/coffee-chats-topics/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCoffeeChatTopicRequest,
    },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsPartialUpdate>>,
  TError,
  { id: number; data: PatchedCoffeeChatTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsPartialUpdate>>,
    { id: number; data: PatchedCoffeeChatTopicRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesCoffeeChatsTopicsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsTopicsPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsPartialUpdate>>
  >;
export type UserguidesCoffeeChatsTopicsPartialUpdateMutationBody =
  PatchedCoffeeChatTopicRequest;
export type UserguidesCoffeeChatsTopicsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsTopicsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesCoffeeChatsTopicsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/coffee-chats-topics/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesCoffeeChatsTopicsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesCoffeeChatsTopicsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsDestroy>>
>;

export type UserguidesCoffeeChatsTopicsDestroyMutationError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesCoffeeChatsTopicsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesCoffeeChatsTopicsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatTopic>(
    {
      url: `/api/v1/userguides/coffee-chats-topics/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getUserguidesCoffeeChatsTopicsMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/coffee-chats-topics/me/`] as const;
};

export const getUserguidesCoffeeChatsTopicsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserguidesCoffeeChatsTopicsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>
  > = ({ signal }) =>
    userguidesCoffeeChatsTopicsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesCoffeeChatsTopicsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>
>;
export type UserguidesCoffeeChatsTopicsMeRetrieveQueryError =
  ErrorType<unknown>;

export const useUserguidesCoffeeChatsTopicsMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesCoffeeChatsTopicsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getUserguidesCoffeeChatsTopicsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesDiscList = (
  params?: UserguidesDiscListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPTDiSCList>(
    { url: `/api/v1/userguides/disc/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesDiscListQueryKey = (
  params?: UserguidesDiscListParams
) => {
  return [`/api/v1/userguides/disc/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesDiscListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesDiscList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesDiscListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesDiscList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesDiscListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesDiscList>>
  > = ({ signal }) => userguidesDiscList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesDiscList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesDiscListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscList>>
>;
export type UserguidesDiscListQueryError = ErrorType<unknown>;

export const useUserguidesDiscList = <
  TData = Awaited<ReturnType<typeof userguidesDiscList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesDiscListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesDiscList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesDiscListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesDiscCreate = (
  pTDiSCRequest: PTDiSCRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTDiSC>(
    {
      url: `/api/v1/userguides/disc/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: pTDiSCRequest,
    },
    options
  );
};

export const getUserguidesDiscCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscCreate>>,
    TError,
    { data: PTDiSCRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesDiscCreate>>,
  TError,
  { data: PTDiSCRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesDiscCreate>>,
    { data: PTDiSCRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesDiscCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesDiscCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscCreate>>
>;
export type UserguidesDiscCreateMutationBody = PTDiSCRequest;
export type UserguidesDiscCreateMutationError = ErrorType<unknown>;

export const useUserguidesDiscCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscCreate>>,
    TError,
    { data: PTDiSCRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesDiscCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesDiscRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTDiSC>(
    { url: `/api/v1/userguides/disc/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesDiscRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/disc/${id}/`] as const;
};

export const getUserguidesDiscRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesDiscRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesDiscRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesDiscRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesDiscRetrieve>>
  > = ({ signal }) => userguidesDiscRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesDiscRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesDiscRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscRetrieve>>
>;
export type UserguidesDiscRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesDiscRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesDiscRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesDiscRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesDiscRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesDiscPartialUpdate = (
  id: number,
  patchedPTDiSCRequest: PatchedPTDiSCRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTDiSC>(
    {
      url: `/api/v1/userguides/disc/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPTDiSCRequest,
    },
    options
  );
};

export const getUserguidesDiscPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTDiSCRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesDiscPartialUpdate>>,
  TError,
  { id: number; data: PatchedPTDiSCRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesDiscPartialUpdate>>,
    { id: number; data: PatchedPTDiSCRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesDiscPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesDiscPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscPartialUpdate>>
>;
export type UserguidesDiscPartialUpdateMutationBody = PatchedPTDiSCRequest;
export type UserguidesDiscPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesDiscPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTDiSCRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesDiscPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesDiscDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/disc/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesDiscDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesDiscDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesDiscDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesDiscDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesDiscDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscDestroy>>
>;

export type UserguidesDiscDestroyMutationError = ErrorType<unknown>;

export const useUserguidesDiscDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesDiscDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesDiscDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesDiscMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTDiSC>(
    { url: `/api/v1/userguides/disc/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesDiscMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/disc/me/`] as const;
};

export const getUserguidesDiscMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesDiscMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>
  > = ({ signal }) => userguidesDiscMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesDiscMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>
>;
export type UserguidesDiscMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesDiscMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesDiscMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesDiscMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesKolbeList = (
  params?: UserguidesKolbeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPTKolbeList>(
    { url: `/api/v1/userguides/kolbe/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesKolbeListQueryKey = (
  params?: UserguidesKolbeListParams
) => {
  return [`/api/v1/userguides/kolbe/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesKolbeListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesKolbeList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesKolbeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesKolbeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesKolbeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesKolbeList>>
  > = ({ signal }) => userguidesKolbeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesKolbeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesKolbeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbeList>>
>;
export type UserguidesKolbeListQueryError = ErrorType<unknown>;

export const useUserguidesKolbeList = <
  TData = Awaited<ReturnType<typeof userguidesKolbeList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesKolbeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesKolbeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesKolbeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesKolbeCreate = (
  pTKolbeRequest: PTKolbeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTKolbe>(
    {
      url: `/api/v1/userguides/kolbe/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: pTKolbeRequest,
    },
    options
  );
};

export const getUserguidesKolbeCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbeCreate>>,
    TError,
    { data: PTKolbeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesKolbeCreate>>,
  TError,
  { data: PTKolbeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesKolbeCreate>>,
    { data: PTKolbeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesKolbeCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesKolbeCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbeCreate>>
>;
export type UserguidesKolbeCreateMutationBody = PTKolbeRequest;
export type UserguidesKolbeCreateMutationError = ErrorType<unknown>;

export const useUserguidesKolbeCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbeCreate>>,
    TError,
    { data: PTKolbeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesKolbeCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesKolbeRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTKolbe>(
    { url: `/api/v1/userguides/kolbe/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesKolbeRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/kolbe/${id}/`] as const;
};

export const getUserguidesKolbeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesKolbeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesKolbeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesKolbeRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesKolbeRetrieve>>
  > = ({ signal }) => userguidesKolbeRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesKolbeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesKolbeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbeRetrieve>>
>;
export type UserguidesKolbeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesKolbeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesKolbeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesKolbeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesKolbeRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesKolbePartialUpdate = (
  id: number,
  patchedPTKolbeRequest: PatchedPTKolbeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTKolbe>(
    {
      url: `/api/v1/userguides/kolbe/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPTKolbeRequest,
    },
    options
  );
};

export const getUserguidesKolbePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbePartialUpdate>>,
    TError,
    { id: number; data: PatchedPTKolbeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesKolbePartialUpdate>>,
  TError,
  { id: number; data: PatchedPTKolbeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesKolbePartialUpdate>>,
    { id: number; data: PatchedPTKolbeRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesKolbePartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesKolbePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbePartialUpdate>>
>;
export type UserguidesKolbePartialUpdateMutationBody = PatchedPTKolbeRequest;
export type UserguidesKolbePartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesKolbePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbePartialUpdate>>,
    TError,
    { id: number; data: PatchedPTKolbeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesKolbePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesKolbeDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/kolbe/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesKolbeDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbeDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesKolbeDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesKolbeDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesKolbeDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesKolbeDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbeDestroy>>
>;

export type UserguidesKolbeDestroyMutationError = ErrorType<unknown>;

export const useUserguidesKolbeDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesKolbeDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesKolbeDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesKolbeMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTKolbe>(
    { url: `/api/v1/userguides/kolbe/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesKolbeMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/kolbe/me/`] as const;
};

export const getUserguidesKolbeMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesKolbeMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>
  > = ({ signal }) => userguidesKolbeMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesKolbeMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>
>;
export type UserguidesKolbeMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesKolbeMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesKolbeMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesKolbeMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMbtiList = (
  params?: UserguidesMbtiListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPTPMeyersBriggsList>(
    { url: `/api/v1/userguides/mbti/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesMbtiListQueryKey = (
  params?: UserguidesMbtiListParams
) => {
  return [`/api/v1/userguides/mbti/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesMbtiListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMbtiList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMbtiListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMbtiList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMbtiListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMbtiList>>
  > = ({ signal }) => userguidesMbtiList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMbtiList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMbtiListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiList>>
>;
export type UserguidesMbtiListQueryError = ErrorType<unknown>;

export const useUserguidesMbtiList = <
  TData = Awaited<ReturnType<typeof userguidesMbtiList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMbtiListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMbtiList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMbtiListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMbtiCreate = (
  pTPMeyersBriggsRequest: PTPMeyersBriggsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTPMeyersBriggs>(
    {
      url: `/api/v1/userguides/mbti/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: pTPMeyersBriggsRequest,
    },
    options
  );
};

export const getUserguidesMbtiCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiCreate>>,
    TError,
    { data: PTPMeyersBriggsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMbtiCreate>>,
  TError,
  { data: PTPMeyersBriggsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMbtiCreate>>,
    { data: PTPMeyersBriggsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesMbtiCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMbtiCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiCreate>>
>;
export type UserguidesMbtiCreateMutationBody = PTPMeyersBriggsRequest;
export type UserguidesMbtiCreateMutationError = ErrorType<unknown>;

export const useUserguidesMbtiCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiCreate>>,
    TError,
    { data: PTPMeyersBriggsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesMbtiCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMbtiRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTPMeyersBriggs>(
    { url: `/api/v1/userguides/mbti/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMbtiRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/mbti/${id}/`] as const;
};

export const getUserguidesMbtiRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMbtiRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMbtiRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMbtiRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMbtiRetrieve>>
  > = ({ signal }) => userguidesMbtiRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMbtiRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMbtiRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiRetrieve>>
>;
export type UserguidesMbtiRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMbtiRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMbtiRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMbtiRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMbtiRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMbtiPartialUpdate = (
  id: number,
  patchedPTPMeyersBriggsRequest: PatchedPTPMeyersBriggsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PTPMeyersBriggs>(
    {
      url: `/api/v1/userguides/mbti/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPTPMeyersBriggsRequest,
    },
    options
  );
};

export const getUserguidesMbtiPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTPMeyersBriggsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMbtiPartialUpdate>>,
  TError,
  { id: number; data: PatchedPTPMeyersBriggsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMbtiPartialUpdate>>,
    { id: number; data: PatchedPTPMeyersBriggsRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesMbtiPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMbtiPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiPartialUpdate>>
>;
export type UserguidesMbtiPartialUpdateMutationBody =
  PatchedPTPMeyersBriggsRequest;
export type UserguidesMbtiPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesMbtiPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiPartialUpdate>>,
    TError,
    { id: number; data: PatchedPTPMeyersBriggsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMbtiPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMbtiDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/mbti/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesMbtiDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMbtiDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMbtiDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesMbtiDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMbtiDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiDestroy>>
>;

export type UserguidesMbtiDestroyMutationError = ErrorType<unknown>;

export const useUserguidesMbtiDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMbtiDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesMbtiDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesMbtiMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PTPMeyersBriggs>(
    { url: `/api/v1/userguides/mbti/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMbtiMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/mbti/me/`] as const;
};

export const getUserguidesMbtiMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMbtiMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>
  > = ({ signal }) => userguidesMbtiMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMbtiMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>
>;
export type UserguidesMbtiMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMbtiMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMbtiMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMbtiMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<UserGuides>(
    { url: `/api/v1/userguides/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/me/`] as const;
};

export const getUserguidesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserguidesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMeRetrieve>>
  > = ({ signal }) => userguidesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMeRetrieve>>
>;
export type UserguidesMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsMatchesList = (
  params?: UserguidesMentorsMatchesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentorMatchList>(
    {
      url: `/api/v1/userguides/mentors-matches/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserguidesMentorsMatchesListQueryKey = (
  params?: UserguidesMentorsMatchesListParams
) => {
  return [
    `/api/v1/userguides/mentors-matches/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserguidesMentorsMatchesListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMentorsMatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsMatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsMatchesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesList>>
  > = ({ signal }) =>
    userguidesMentorsMatchesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsMatchesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesList>>
>;
export type UserguidesMentorsMatchesListQueryError = ErrorType<unknown>;

export const useUserguidesMentorsMatchesList = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMentorsMatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsMatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMentorsMatchesListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsMatchesCreate = (
  mentorMatchRequest: MentorMatchRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentorMatch>(
    {
      url: `/api/v1/userguides/mentors-matches/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentorMatchRequest,
    },
    options
  );
};

export const getUserguidesMentorsMatchesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesCreate>>,
    TError,
    { data: MentorMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsMatchesCreate>>,
  TError,
  { data: MentorMatchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesCreate>>,
    { data: MentorMatchRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesMentorsMatchesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsMatchesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesCreate>>
>;
export type UserguidesMentorsMatchesCreateMutationBody = MentorMatchRequest;
export type UserguidesMentorsMatchesCreateMutationError = ErrorType<unknown>;

export const useUserguidesMentorsMatchesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesCreate>>,
    TError,
    { data: MentorMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsMatchesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMentorsMatchesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MentorMatch>(
    { url: `/api/v1/userguides/mentors-matches/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMentorsMatchesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/mentors-matches/${id}/`] as const;
};

export const getUserguidesMentorsMatchesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsMatchesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>
  > = ({ signal }) =>
    userguidesMentorsMatchesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsMatchesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>
>;
export type UserguidesMentorsMatchesRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMentorsMatchesRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsMatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMentorsMatchesRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsMatchesPartialUpdate = (
  id: number,
  patchedMentorMatchRequest: PatchedMentorMatchRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentorMatch>(
    {
      url: `/api/v1/userguides/mentors-matches/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentorMatchRequest,
    },
    options
  );
};

export const getUserguidesMentorsMatchesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedMentorMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsMatchesPartialUpdate>>,
  TError,
  { id: number; data: PatchedMentorMatchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesPartialUpdate>>,
    { id: number; data: PatchedMentorMatchRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesMentorsMatchesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsMatchesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesPartialUpdate>>
>;
export type UserguidesMentorsMatchesPartialUpdateMutationBody =
  PatchedMentorMatchRequest;
export type UserguidesMentorsMatchesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useUserguidesMentorsMatchesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedMentorMatchRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsMatchesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMentorsMatchesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/mentors-matches/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesMentorsMatchesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsMatchesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesMentorsMatchesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsMatchesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesDestroy>>
>;

export type UserguidesMentorsMatchesDestroyMutationError = ErrorType<unknown>;

export const useUserguidesMentorsMatchesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsMatchesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesMentorsMatchesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MentorMatch>(
    { url: `/api/v1/userguides/mentors-matches/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMentorsMatchesMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/mentors-matches/me/`] as const;
};

export const getUserguidesMentorsMatchesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsMatchesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>
  > = ({ signal }) =>
    userguidesMentorsMatchesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsMatchesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>
>;
export type UserguidesMentorsMatchesMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMentorsMatchesMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsMatchesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getUserguidesMentorsMatchesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsTopicsList = (
  params?: UserguidesMentorsTopicsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedMentorTopicList>(
    {
      url: `/api/v1/userguides/mentors-topics/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserguidesMentorsTopicsListQueryKey = (
  params?: UserguidesMentorsTopicsListParams
) => {
  return [
    `/api/v1/userguides/mentors-topics/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserguidesMentorsTopicsListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMentorsTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsTopicsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsList>>
  > = ({ signal }) =>
    userguidesMentorsTopicsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsTopicsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsList>>
>;
export type UserguidesMentorsTopicsListQueryError = ErrorType<unknown>;

export const useUserguidesMentorsTopicsList = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesMentorsTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMentorsTopicsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsTopicsCreate = (
  mentorTopicRequest: MentorTopicRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentorTopic>(
    {
      url: `/api/v1/userguides/mentors-topics/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: mentorTopicRequest,
    },
    options
  );
};

export const getUserguidesMentorsTopicsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsCreate>>,
    TError,
    { data: MentorTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsTopicsCreate>>,
  TError,
  { data: MentorTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsCreate>>,
    { data: MentorTopicRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesMentorsTopicsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsTopicsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsCreate>>
>;
export type UserguidesMentorsTopicsCreateMutationBody = MentorTopicRequest;
export type UserguidesMentorsTopicsCreateMutationError = ErrorType<unknown>;

export const useUserguidesMentorsTopicsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsCreate>>,
    TError,
    { data: MentorTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsTopicsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMentorsTopicsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MentorTopic>(
    { url: `/api/v1/userguides/mentors-topics/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMentorsTopicsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/mentors-topics/${id}/`] as const;
};

export const getUserguidesMentorsTopicsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsTopicsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>
  > = ({ signal }) =>
    userguidesMentorsTopicsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsTopicsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>
>;
export type UserguidesMentorsTopicsRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMentorsTopicsRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesMentorsTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesMentorsTopicsRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesMentorsTopicsPartialUpdate = (
  id: number,
  patchedMentorTopicRequest: PatchedMentorTopicRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MentorTopic>(
    {
      url: `/api/v1/userguides/mentors-topics/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMentorTopicRequest,
    },
    options
  );
};

export const getUserguidesMentorsTopicsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedMentorTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsTopicsPartialUpdate>>,
  TError,
  { id: number; data: PatchedMentorTopicRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsPartialUpdate>>,
    { id: number; data: PatchedMentorTopicRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesMentorsTopicsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsTopicsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsPartialUpdate>>
>;
export type UserguidesMentorsTopicsPartialUpdateMutationBody =
  PatchedMentorTopicRequest;
export type UserguidesMentorsTopicsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useUserguidesMentorsTopicsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedMentorTopicRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsTopicsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesMentorsTopicsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/mentors-topics/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesMentorsTopicsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesMentorsTopicsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesMentorsTopicsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesMentorsTopicsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsDestroy>>
>;

export type UserguidesMentorsTopicsDestroyMutationError = ErrorType<unknown>;

export const useUserguidesMentorsTopicsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesMentorsTopicsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesMentorsTopicsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MentorTopic>(
    { url: `/api/v1/userguides/mentors-topics/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesMentorsTopicsMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/mentors-topics/me/`] as const;
};

export const getUserguidesMentorsTopicsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesMentorsTopicsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>
  > = ({ signal }) => userguidesMentorsTopicsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesMentorsTopicsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>
>;
export type UserguidesMentorsTopicsMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesMentorsTopicsMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesMentorsTopicsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getUserguidesMentorsTopicsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesPhonesList = (
  params?: UserguidesPhonesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPhoneNumberList>(
    { url: `/api/v1/userguides/phones/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesPhonesListQueryKey = (
  params?: UserguidesPhonesListParams
) => {
  return [`/api/v1/userguides/phones/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesPhonesListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesPhonesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesPhonesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesPhonesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesPhonesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesPhonesList>>
  > = ({ signal }) => userguidesPhonesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesPhonesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesPhonesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesList>>
>;
export type UserguidesPhonesListQueryError = ErrorType<unknown>;

export const useUserguidesPhonesList = <
  TData = Awaited<ReturnType<typeof userguidesPhonesList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesPhonesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesPhonesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesPhonesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesPhonesCreate = (
  phoneNumberRequest: PhoneNumberRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PhoneNumber>(
    {
      url: `/api/v1/userguides/phones/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: phoneNumberRequest,
    },
    options
  );
};

export const getUserguidesPhonesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesCreate>>,
    TError,
    { data: PhoneNumberRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesPhonesCreate>>,
  TError,
  { data: PhoneNumberRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesPhonesCreate>>,
    { data: PhoneNumberRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesPhonesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesPhonesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesCreate>>
>;
export type UserguidesPhonesCreateMutationBody = PhoneNumberRequest;
export type UserguidesPhonesCreateMutationError = ErrorType<unknown>;

export const useUserguidesPhonesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesCreate>>,
    TError,
    { data: PhoneNumberRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesPhonesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesPhonesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PhoneNumber>(
    { url: `/api/v1/userguides/phones/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesPhonesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/phones/${id}/`] as const;
};

export const getUserguidesPhonesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesPhonesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesPhonesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesPhonesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesPhonesRetrieve>>
  > = ({ signal }) => userguidesPhonesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesPhonesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesPhonesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesRetrieve>>
>;
export type UserguidesPhonesRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesPhonesRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesPhonesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesPhonesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesPhonesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesPhonesPartialUpdate = (
  id: number,
  patchedPhoneNumberRequest: PatchedPhoneNumberRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PhoneNumber>(
    {
      url: `/api/v1/userguides/phones/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPhoneNumberRequest,
    },
    options
  );
};

export const getUserguidesPhonesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesPartialUpdate>>,
    TError,
    { id: number; data: PatchedPhoneNumberRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesPhonesPartialUpdate>>,
  TError,
  { id: number; data: PatchedPhoneNumberRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesPhonesPartialUpdate>>,
    { id: number; data: PatchedPhoneNumberRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesPhonesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesPhonesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesPartialUpdate>>
>;
export type UserguidesPhonesPartialUpdateMutationBody =
  PatchedPhoneNumberRequest;
export type UserguidesPhonesPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesPhonesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesPartialUpdate>>,
    TError,
    { id: number; data: PatchedPhoneNumberRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesPhonesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesPhonesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/phones/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesPhonesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesPhonesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesPhonesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesPhonesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesPhonesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesDestroy>>
>;

export type UserguidesPhonesDestroyMutationError = ErrorType<unknown>;

export const useUserguidesPhonesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesPhonesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Bulk edit phone numbers according to the following logic:
 - If the object does not have an ID, create it
 - If the object does have an ID, update it
 - If the object has an ID and the phoneNumber is empty, delete it
 * @summary userguides_phones_bulk_edit
 */
export const userguidesPhonesBulkEditCreate = (
  phoneNumberBulkEditRequest: PhoneNumberBulkEditRequest[],
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/userguides/phones/bulk-edit/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: phoneNumberBulkEditRequest,
    },
    options
  );
};

export const getUserguidesPhonesBulkEditCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesBulkEditCreate>>,
    TError,
    { data: PhoneNumberBulkEditRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesPhonesBulkEditCreate>>,
  TError,
  { data: PhoneNumberBulkEditRequest[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesPhonesBulkEditCreate>>,
    { data: PhoneNumberBulkEditRequest[] }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesPhonesBulkEditCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesPhonesBulkEditCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesBulkEditCreate>>
>;
export type UserguidesPhonesBulkEditCreateMutationBody =
  PhoneNumberBulkEditRequest[];
export type UserguidesPhonesBulkEditCreateMutationError = ErrorType<unknown>;

/**
 * @summary userguides_phones_bulk_edit
 */
export const useUserguidesPhonesBulkEditCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesPhonesBulkEditCreate>>,
    TError,
    { data: PhoneNumberBulkEditRequest[] },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesPhonesBulkEditCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesPhonesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PhoneNumber>(
    { url: `/api/v1/userguides/phones/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesPhonesMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/phones/me/`] as const;
};

export const getUserguidesPhonesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesPhonesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>
  > = ({ signal }) => userguidesPhonesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesPhonesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>
>;
export type UserguidesPhonesMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesPhonesMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesPhonesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesPhonesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # UserGuides Questions

When new Users join a Team, they're asked these questions. Intuition curates a set of default
questions and links them to the Team, and a TeamOwner can customize them.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const userguidesQuestionsList = (
  params?: UserguidesQuestionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedQuestionModelList>(
    { url: `/api/v1/userguides/questions/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesQuestionsListQueryKey = (
  params?: UserguidesQuestionsListParams
) => {
  return [
    `/api/v1/userguides/questions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserguidesQuestionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesQuestionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesQuestionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesQuestionsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesQuestionsList>>
  > = ({ signal }) => userguidesQuestionsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesQuestionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsList>>
>;
export type UserguidesQuestionsListQueryError = ErrorType<unknown>;

export const useUserguidesQuestionsList = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesQuestionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesQuestionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesQuestionsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # UserGuides Questions

When new Users join a Team, they're asked these questions. Intuition curates a set of default
questions and links them to the Team, and a TeamOwner can customize them.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const userguidesQuestionsCreate = (
  questionModelRequest: QuestionModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<QuestionModel>(
    {
      url: `/api/v1/userguides/questions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: questionModelRequest,
    },
    options
  );
};

export const getUserguidesQuestionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsCreate>>,
    TError,
    { data: QuestionModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesQuestionsCreate>>,
  TError,
  { data: QuestionModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesQuestionsCreate>>,
    { data: QuestionModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesQuestionsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesQuestionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsCreate>>
>;
export type UserguidesQuestionsCreateMutationBody = QuestionModelRequest;
export type UserguidesQuestionsCreateMutationError = ErrorType<unknown>;

export const useUserguidesQuestionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsCreate>>,
    TError,
    { data: QuestionModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesQuestionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # UserGuides Questions

When new Users join a Team, they're asked these questions. Intuition curates a set of default
questions and links them to the Team, and a TeamOwner can customize them.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const userguidesQuestionsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<QuestionModel>(
    { url: `/api/v1/userguides/questions/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesQuestionsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/questions/${id}/`] as const;
};

export const getUserguidesQuestionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesQuestionsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>
  > = ({ signal }) => userguidesQuestionsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesQuestionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>
>;
export type UserguidesQuestionsRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesQuestionsRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesQuestionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesQuestionsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # UserGuides Questions

When new Users join a Team, they're asked these questions. Intuition curates a set of default
questions and links them to the Team, and a TeamOwner can customize them.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const userguidesQuestionsPartialUpdate = (
  id: number,
  patchedQuestionModelRequest: PatchedQuestionModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<QuestionModel>(
    {
      url: `/api/v1/userguides/questions/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedQuestionModelRequest,
    },
    options
  );
};

export const getUserguidesQuestionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsPartialUpdate>>,
    TError,
    { id: number; data: PatchedQuestionModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesQuestionsPartialUpdate>>,
  TError,
  { id: number; data: PatchedQuestionModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesQuestionsPartialUpdate>>,
    { id: number; data: PatchedQuestionModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesQuestionsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesQuestionsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsPartialUpdate>>
>;
export type UserguidesQuestionsPartialUpdateMutationBody =
  PatchedQuestionModelRequest;
export type UserguidesQuestionsPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesQuestionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsPartialUpdate>>,
    TError,
    { id: number; data: PatchedQuestionModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesQuestionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # UserGuides Questions

When new Users join a Team, they're asked these questions. Intuition curates a set of default
questions and links them to the Team, and a TeamOwner can customize them.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const userguidesQuestionsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/questions/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesQuestionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesQuestionsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesQuestionsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesQuestionsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesQuestionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsDestroy>>
>;

export type UserguidesQuestionsDestroyMutationError = ErrorType<unknown>;

export const useUserguidesQuestionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesQuestionsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesQuestionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesQuestionsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<QuestionModel>(
    { url: `/api/v1/userguides/questions/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesQuestionsMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/questions/me/`] as const;
};

export const getUserguidesQuestionsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesQuestionsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>
  > = ({ signal }) => userguidesQuestionsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesQuestionsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>
>;
export type UserguidesQuestionsMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesQuestionsMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesQuestionsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * All questions with my answers)
 */
export const userguidesQuestionsMyAnswersRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<QuestionModel>(
    { url: `/api/v1/userguides/questions/my_answers/`, method: "GET", signal },
    options
  );
};

export const getUserguidesQuestionsMyAnswersRetrieveQueryKey = () => {
  return [`/api/v1/userguides/questions/my_answers/`] as const;
};

export const getUserguidesQuestionsMyAnswersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesQuestionsMyAnswersRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>
  > = ({ signal }) =>
    userguidesQuestionsMyAnswersRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesQuestionsMyAnswersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>
>;
export type UserguidesQuestionsMyAnswersRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesQuestionsMyAnswersRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesQuestionsMyAnswersRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getUserguidesQuestionsMyAnswersRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesSchoolsList = (
  params?: UserguidesSchoolsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedSchoolsList>(
    { url: `/api/v1/userguides/schools/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesSchoolsListQueryKey = (
  params?: UserguidesSchoolsListParams
) => {
  return [`/api/v1/userguides/schools/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesSchoolsListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesSchoolsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesSchoolsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesSchoolsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesSchoolsList>>
  > = ({ signal }) => userguidesSchoolsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesSchoolsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesSchoolsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsList>>
>;
export type UserguidesSchoolsListQueryError = ErrorType<unknown>;

export const useUserguidesSchoolsList = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesSchoolsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesSchoolsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesSchoolsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesSchoolsCreate = (
  schoolsRequest: SchoolsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Schools>(
    {
      url: `/api/v1/userguides/schools/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: schoolsRequest,
    },
    options
  );
};

export const getUserguidesSchoolsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsCreate>>,
    TError,
    { data: SchoolsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesSchoolsCreate>>,
  TError,
  { data: SchoolsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesSchoolsCreate>>,
    { data: SchoolsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesSchoolsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesSchoolsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsCreate>>
>;
export type UserguidesSchoolsCreateMutationBody = SchoolsRequest;
export type UserguidesSchoolsCreateMutationError = ErrorType<unknown>;

export const useUserguidesSchoolsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsCreate>>,
    TError,
    { data: SchoolsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesSchoolsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesSchoolsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Schools>(
    { url: `/api/v1/userguides/schools/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesSchoolsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/schools/${id}/`] as const;
};

export const getUserguidesSchoolsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesSchoolsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>
  > = ({ signal }) => userguidesSchoolsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesSchoolsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>
>;
export type UserguidesSchoolsRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesSchoolsRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesSchoolsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesSchoolsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesSchoolsPartialUpdate = (
  id: number,
  patchedSchoolsRequest: PatchedSchoolsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Schools>(
    {
      url: `/api/v1/userguides/schools/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedSchoolsRequest,
    },
    options
  );
};

export const getUserguidesSchoolsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsPartialUpdate>>,
    TError,
    { id: number; data: PatchedSchoolsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesSchoolsPartialUpdate>>,
  TError,
  { id: number; data: PatchedSchoolsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesSchoolsPartialUpdate>>,
    { id: number; data: PatchedSchoolsRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesSchoolsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesSchoolsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsPartialUpdate>>
>;
export type UserguidesSchoolsPartialUpdateMutationBody = PatchedSchoolsRequest;
export type UserguidesSchoolsPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesSchoolsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsPartialUpdate>>,
    TError,
    { id: number; data: PatchedSchoolsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesSchoolsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesSchoolsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/schools/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesSchoolsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesSchoolsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesSchoolsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesSchoolsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesSchoolsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsDestroy>>
>;

export type UserguidesSchoolsDestroyMutationError = ErrorType<unknown>;

export const useUserguidesSchoolsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesSchoolsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesSchoolsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesSchoolsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Schools>(
    { url: `/api/v1/userguides/schools/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesSchoolsMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/schools/me/`] as const;
};

export const getUserguidesSchoolsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesSchoolsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>
  > = ({ signal }) => userguidesSchoolsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesSchoolsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>
>;
export type UserguidesSchoolsMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesSchoolsMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesSchoolsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesSchoolsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesTrainingList = (
  params?: UserguidesTrainingListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTrainingAndCertificationList>(
    { url: `/api/v1/userguides/training/`, method: "GET", params, signal },
    options
  );
};

export const getUserguidesTrainingListQueryKey = (
  params?: UserguidesTrainingListParams
) => {
  return [`/api/v1/userguides/training/`, ...(params ? [params] : [])] as const;
};

export const getUserguidesTrainingListQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesTrainingList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesTrainingListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesTrainingList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesTrainingListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesTrainingList>>
  > = ({ signal }) => userguidesTrainingList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesTrainingList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesTrainingListQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingList>>
>;
export type UserguidesTrainingListQueryError = ErrorType<unknown>;

export const useUserguidesTrainingList = <
  TData = Awaited<ReturnType<typeof userguidesTrainingList>>,
  TError = ErrorType<unknown>,
>(
  params?: UserguidesTrainingListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesTrainingList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesTrainingListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesTrainingCreate = (
  trainingAndCertificationRequest: TrainingAndCertificationRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TrainingAndCertification>(
    {
      url: `/api/v1/userguides/training/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: trainingAndCertificationRequest,
    },
    options
  );
};

export const getUserguidesTrainingCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingCreate>>,
    TError,
    { data: TrainingAndCertificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesTrainingCreate>>,
  TError,
  { data: TrainingAndCertificationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesTrainingCreate>>,
    { data: TrainingAndCertificationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userguidesTrainingCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesTrainingCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingCreate>>
>;
export type UserguidesTrainingCreateMutationBody =
  TrainingAndCertificationRequest;
export type UserguidesTrainingCreateMutationError = ErrorType<unknown>;

export const useUserguidesTrainingCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingCreate>>,
    TError,
    { data: TrainingAndCertificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesTrainingCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesTrainingRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TrainingAndCertification>(
    { url: `/api/v1/userguides/training/${id}/`, method: "GET", signal },
    options
  );
};

export const getUserguidesTrainingRetrieveQueryKey = (id: number) => {
  return [`/api/v1/userguides/training/${id}/`] as const;
};

export const getUserguidesTrainingRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesTrainingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesTrainingRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesTrainingRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesTrainingRetrieve>>
  > = ({ signal }) => userguidesTrainingRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesTrainingRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesTrainingRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingRetrieve>>
>;
export type UserguidesTrainingRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesTrainingRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesTrainingRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userguidesTrainingRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesTrainingRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mixin to log requests
 */
export const userguidesTrainingPartialUpdate = (
  id: number,
  patchedTrainingAndCertificationRequest: PatchedTrainingAndCertificationRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TrainingAndCertification>(
    {
      url: `/api/v1/userguides/training/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedTrainingAndCertificationRequest,
    },
    options
  );
};

export const getUserguidesTrainingPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingPartialUpdate>>,
    TError,
    { id: number; data: PatchedTrainingAndCertificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesTrainingPartialUpdate>>,
  TError,
  { id: number; data: PatchedTrainingAndCertificationRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesTrainingPartialUpdate>>,
    { id: number; data: PatchedTrainingAndCertificationRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return userguidesTrainingPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesTrainingPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingPartialUpdate>>
>;
export type UserguidesTrainingPartialUpdateMutationBody =
  PatchedTrainingAndCertificationRequest;
export type UserguidesTrainingPartialUpdateMutationError = ErrorType<unknown>;

export const useUserguidesTrainingPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingPartialUpdate>>,
    TError,
    { id: number; data: PatchedTrainingAndCertificationRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getUserguidesTrainingPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mixin to log requests
 */
export const userguidesTrainingDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/userguides/training/${id}/`, method: "DELETE" },
    options
  );
};

export const getUserguidesTrainingDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userguidesTrainingDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userguidesTrainingDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return userguidesTrainingDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserguidesTrainingDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingDestroy>>
>;

export type UserguidesTrainingDestroyMutationError = ErrorType<unknown>;

export const useUserguidesTrainingDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userguidesTrainingDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getUserguidesTrainingDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const userguidesTrainingMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TrainingAndCertification>(
    { url: `/api/v1/userguides/training/me/`, method: "GET", signal },
    options
  );
};

export const getUserguidesTrainingMeRetrieveQueryKey = () => {
  return [`/api/v1/userguides/training/me/`] as const;
};

export const getUserguidesTrainingMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserguidesTrainingMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>
  > = ({ signal }) => userguidesTrainingMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserguidesTrainingMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>
>;
export type UserguidesTrainingMeRetrieveQueryError = ErrorType<unknown>;

export const useUserguidesTrainingMeRetrieve = <
  TData = Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userguidesTrainingMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUserguidesTrainingMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
