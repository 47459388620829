/**
 *
 *
 * useReactions
 *
 *
 */
import {
  BugAntIcon,
  CakeIcon,
  FaceSmileIcon,
  FlagIcon,
  GlobeAmericasIcon,
  HeartIcon,
  MagnifyingGlassIcon,
  MusicalNoteIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDebounce } from "../../hooks/useDebounce";
import { useMe } from "../../hooks/useMe";
import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import {
  useSocialPostsCommentsReactionsCreate,
  useSocialPostsCommentsReactionsDestroy,
  useSocialPostsCommentsReactionsPartialUpdate,
  useSocialPostsCommentsRepliesReactionsCreate,
  useSocialPostsCommentsRepliesReactionsDestroy,
  useSocialPostsCommentsRepliesReactionsPartialUpdate,
  useSocialPostsCommentsRepliesRebuttalsReactionsCreate,
  useSocialPostsCommentsRepliesRebuttalsReactionsDestroy,
  useSocialPostsCommentsRepliesRebuttalsReactionsPartialUpdate,
  useSocialPostsReactionsCreate,
  useSocialPostsReactionsDestroy,
  useSocialPostsReactionsPartialUpdate,
} from "../../services/teambuilder/endpoints/social/social";
import {
  PatchedReactionRequest,
  Post,
  Reaction,
  ReactionRequest,
  SimpleUserModel,
} from "../../services/teambuilder/schemas";
import { HeroIcon, PaginatedPostLikeList, PostType } from "../../types";
import {
  Emoji,
  EmojiCategory,
  EmojiKey,
  emojiCategories,
  emojiList,
  emojis,
} from "../../utils/emojis";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { getPostAncestorPks } from "../../utils/post-utils";
import {
  isCommentType,
  isPostType,
  isRebuttalType,
  isReplyType,
} from "../../utils/type-guards";
import { fullName } from "../../utils/username";

interface Props {
  // Parent post-like object, may be a Post, Comment, Reply, or Rebuttal.
  post: PostType;
  // Query key passed to optimistic updates.
  listQueryKey: readonly unknown[];
  // In the special case of a post detail page, optimistic updates work on the Post object
  // instead of the PaginatedPostList object.
  detailQueryKey?: readonly unknown[];
}

export const useReactions = (props: Props) => {
  const { t } = useTranslation();
  const { me } = useMe();
  const { openToast } = useToast();
  const searchRef = useRef<HTMLInputElement>(null);
  const errorToast = (description: string) => () =>
    openToast({
      title: t("translation:common:error"),
      description,
      type: "danger",
    });

  /**
   * Posts contain the IDs of their ancestors.
   */
  const { postsPk, commentsPk, repliesPk, rebuttalsPk } = getPostAncestorPks(
    props.post
  );

  const categoryIcons: Record<EmojiCategory, HeroIcon> = {
    search: MagnifyingGlassIcon,
    people: FaceSmileIcon,
    nature: BugAntIcon,
    food: CakeIcon,
    travel: GlobeAmericasIcon,
    activities: TrophyIcon,
    objects: HeartIcon,
    symbols: MusicalNoteIcon,
    flags: FlagIcon,
  };

  const categoryRefs = {
    search: useRef<HTMLDivElement>(null),
    people: useRef<HTMLLIElement>(null),
    nature: useRef<HTMLLIElement>(null),
    food: useRef<HTMLLIElement>(null),
    travel: useRef<HTMLLIElement>(null),
    activities: useRef<HTMLLIElement>(null),
    objects: useRef<HTMLLIElement>(null),
    symbols: useRef<HTMLLIElement>(null),
    flags: useRef<HTMLLIElement>(null),
  };

  const [activeCategory, setActiveCategory] = useState<EmojiCategory>("search");
  const categories: {
    key: EmojiCategory;
    emojis: Emoji[];
    icon: HeroIcon;
    isActive: boolean;
  }[] = (Object.keys(emojiCategories) as EmojiCategory[]).map((key) => ({
    key,
    emojis: emojiCategories[key],
    icon: categoryIcons[key],
    isActive: activeCategory === key,
  }));

  /**
   * Group existing reactions by emoji key.
   */
  const currentReactions = useMemo(
    () =>
      props.post.reactions?.reduce<
        Record<
          string,
          {
            key: EmojiKey;
            value: string;
            count: number;
            names: string[];
          }
        >
      >((acc, value) => {
        const reaction = value.reaction as EmojiKey;
        if (acc[reaction]) {
          acc[reaction] = {
            ...acc[reaction],
            count: acc[reaction].count + 1,
            names: [...acc[reaction].names, fullName(value.user)],
          };
        } else {
          acc[reaction] = {
            key: reaction,
            count: 1,
            value: emojis[reaction],
            names: [fullName(value.user)],
          };
        }
        return acc;
      }, {}) || {},
    [props.post.reactions]
  );
  const NAME_LIMIT = 15;
  const truncateNameList = (names: string[]) =>
    names.length > NAME_LIMIT
      ? [
          ...names.slice(0, NAME_LIMIT),
          `and ${names.length - NAME_LIMIT} more...`,
        ]
      : names;

  /**
   * Find the current user's existing reaction in the parent.
   */
  const findReaction = useCallback((): Reaction | undefined => {
    return props.post?.reactions?.find((r) => r.user?.id === me?.id);
  }, [props.post]);

  /**
   *
   *
   * Create reaction requests
   *
   *
   */
  interface CreateReactionRequestVariables {
    postsPk: number;
    data: ReactionRequest;
  }

  const createReactionErrorToast = errorToast(
    t("translation:toast:reaction_add_failed")
  );

  /**
   * Create mutation options for post detail pages.
   */
  const createReactionOptionsDetail = optimisticMutationOptions<
    Reaction,
    Post,
    CreateReactionRequestVariables
  >({
    queryKey: props.detailQueryKey || [],
    optimisticUpdateFn: (post, requestData) => ({
      ...post,
      reactions: [
        ...(post?.reactions || []),
        {
          user: me as SimpleUserModel,
          reaction: requestData?.data.reaction as string,
        },
      ],
    }),
    onError: createReactionErrorToast,
    onSettled: () => {
      // Only need the list to be refetched here, detail is refetched by default.
      queryClient.invalidateQueries(props.listQueryKey);
    },
  });

  /**
   * Create mutation options for post list pages and all other post types which have no detail
   * pages.
   */
  const createReactionOptionsList = optimisticMutationOptions<
    Reaction,
    PaginatedPostLikeList,
    CreateReactionRequestVariables
  >({
    queryKey: props.listQueryKey,
    optimisticUpdateFn: (context, requestData) => ({
      meta: context?.meta,
      data: context.data.map((listItem) => {
        return listItem.id === Number(props.post?.id)
          ? {
              ...listItem,
              reactions: [
                ...(props.post?.reactions || []),
                {
                  id: 0,
                  user: me as SimpleUserModel,
                  reaction: requestData?.data.reaction as string,
                },
              ],
            }
          : listItem;
      }),
    }),
    onError: createReactionErrorToast,
  });

  /**
   * Create reaction mutations
   */
  const createPostReactionDetail = useSocialPostsReactionsCreate(
    createReactionOptionsDetail
  );
  const createPostReactionList = useSocialPostsReactionsCreate(
    createReactionOptionsList
  );
  const createCommentReaction = useSocialPostsCommentsReactionsCreate(
    createReactionOptionsList
  );
  const createReplyReaction = useSocialPostsCommentsRepliesReactionsCreate(
    createReactionOptionsList
  );
  const createRebuttalReaction =
    useSocialPostsCommentsRepliesRebuttalsReactionsCreate(
      createReactionOptionsList
    );
  const isLoadingCreateMutation =
    createPostReactionDetail.isLoading ||
    createPostReactionList.isLoading ||
    createCommentReaction.isLoading ||
    createReplyReaction.isLoading ||
    createRebuttalReaction.isLoading;

  /**
   * Narrow createReaction based on post type.
   */
  const createReaction = (emojiKey: EmojiKey) => {
    return isPostType(props.post)
      ? props.detailQueryKey
        ? createPostReactionDetail.mutate({
            postsPk,
            data: { reaction: emojiKey },
          })
        : createPostReactionList.mutate({
            postsPk,
            data: { reaction: emojiKey },
          })
      : isCommentType(props.post)
        ? createCommentReaction.mutate({
            postsPk,
            commentsPk,
            data: { reaction: emojiKey },
          })
        : isReplyType(props.post)
          ? createReplyReaction.mutate({
              postsPk,
              commentsPk,
              repliesPk,
              data: { reaction: emojiKey },
            })
          : isRebuttalType(props.post)
            ? createRebuttalReaction.mutate({
                postsPk,
                commentsPk,
                repliesPk,
                rebuttalsPk,
                data: { reaction: emojiKey },
              })
            : undefined;
  };

  /**
   *
   *
   * Update reaction request
   *
   *
   */
  interface UpdateReactionRequestVariables {
    id: number;
    postsPk: number;
    data: PatchedReactionRequest;
  }

  const updateReactionErrorToast = errorToast(
    t("translation:toast:reaction_update_failed")
  );

  /**
   * Update mutation options for post detail pages.
   */
  const updateReactionOptionsDetail = optimisticMutationOptions<
    Reaction,
    Post,
    UpdateReactionRequestVariables
  >({
    queryKey: props.detailQueryKey || [],
    optimisticUpdateFn: (context, requestData) => ({
      ...context,
      reactions: context.reactions?.map((r) =>
        r.id === (requestData?.id as number)
          ? {
              ...r,
              ...requestData?.data,
            }
          : r
      ),
    }),
    onError: updateReactionErrorToast,
    onSettled: () => {
      queryClient.invalidateQueries(props.listQueryKey);
    },
  });

  /**
   * Update mutation options for post list pages and all other post types which have no detail
   * pages.
   */
  const updateReactionOptionsList = optimisticMutationOptions<
    Reaction,
    PaginatedPostLikeList,
    UpdateReactionRequestVariables
  >({
    queryKey: props.listQueryKey,
    optimisticUpdateFn: (context, requestData) => ({
      meta: context?.meta,
      data: context.data.map((listItem) => {
        return listItem.id === Number(props.post.id)
          ? {
              ...listItem,
              reactions: listItem.reactions?.map((r) =>
                r.id === (requestData?.id as number)
                  ? {
                      ...r,
                      ...requestData?.data,
                    }
                  : r
              ),
            }
          : listItem;
      }),
    }),
    onError: updateReactionErrorToast,
  });

  /**
   * Update reaction mutations
   */
  const updatePostReactionDetail = useSocialPostsReactionsPartialUpdate(
    updateReactionOptionsDetail
  );
  const updatePostReactionList = useSocialPostsReactionsPartialUpdate(
    updateReactionOptionsList
  );
  const updateCommentReaction = useSocialPostsCommentsReactionsPartialUpdate(
    updateReactionOptionsList
  );
  const updateReplyReaction =
    useSocialPostsCommentsRepliesReactionsPartialUpdate(
      updateReactionOptionsList
    );
  const updateRebuttalReaction =
    useSocialPostsCommentsRepliesRebuttalsReactionsPartialUpdate(
      updateReactionOptionsList
    );
  const isLoadingUpdateMutation =
    updatePostReactionDetail.isLoading ||
    updatePostReactionList.isLoading ||
    updateCommentReaction.isLoading ||
    updateReplyReaction.isLoading ||
    updateRebuttalReaction.isLoading;

  /**
   * Narrow updateReaction based on post type.
   */
  const updateReaction = (emojiKey: EmojiKey) => {
    const reactionId = Number(findReaction()?.id);

    return isPostType(props.post)
      ? props.detailQueryKey
        ? updatePostReactionDetail.mutate({
            postsPk,
            id: reactionId,
            data: { reaction: emojiKey },
          })
        : updatePostReactionList.mutate({
            postsPk,
            id: reactionId,
            data: { reaction: emojiKey },
          })
      : isCommentType(props.post)
        ? updateCommentReaction.mutate({
            postsPk,
            commentsPk,
            id: reactionId,
            data: { reaction: emojiKey },
          })
        : isReplyType(props.post)
          ? updateReplyReaction.mutate({
              postsPk,
              commentsPk,
              repliesPk,
              id: reactionId,
              data: { reaction: emojiKey },
            })
          : isRebuttalType(props.post)
            ? updateRebuttalReaction.mutate({
                postsPk,
                commentsPk,
                repliesPk,
                rebuttalsPk,
                id: reactionId,
                data: { reaction: emojiKey },
              })
            : undefined;
  };

  /**
   *
   *
   * Delete reaction requests
   *
   *
   */
  interface DeleteReactionRequestVariables {
    id: number;
  }

  const deleteReactionErrorToast = errorToast(
    t("translation:toast:reaction_remove_failed")
  );

  /**
   * Delete mutation options for post detail pages.
   */
  const deleteReactionOptionsDetail = optimisticMutationOptions<
    void,
    Post,
    DeleteReactionRequestVariables
  >({
    queryKey: props.detailQueryKey || [],
    optimisticUpdateFn: (post, requestData) => ({
      ...post,
      reactions: post.reactions?.filter((r) => r.id !== requestData?.id),
    }),
    onError: deleteReactionErrorToast,
    onSettled: () => {
      queryClient.invalidateQueries(props.listQueryKey);
    },
  });

  /**
   * Delete mutation options for post list pages and all other post types which have no detail
   * pages.
   */
  const deleteReactionOptionsList = optimisticMutationOptions<
    void,
    PaginatedPostLikeList,
    DeleteReactionRequestVariables
  >({
    queryKey: props.listQueryKey,
    optimisticUpdateFn: (context, requestData) => ({
      meta: context?.meta,
      data: context.data.map((listItem) => {
        return listItem.id === Number(props.post?.id)
          ? {
              ...listItem,
              reactions: props.post.reactions?.filter(
                (r) => r.id !== requestData?.id
              ),
            }
          : listItem;
      }),
    }),
    onError: deleteReactionErrorToast,
  });

  /**
   * Delete reaction mutations
   */
  const deleteReactionDetail = useSocialPostsReactionsDestroy(
    deleteReactionOptionsDetail
  );
  const deleteReactionList = useSocialPostsReactionsDestroy(
    deleteReactionOptionsList
  );
  const deleteCommentReaction = useSocialPostsCommentsReactionsDestroy(
    deleteReactionOptionsList
  );
  const deleteReplyReaction = useSocialPostsCommentsRepliesReactionsDestroy(
    deleteReactionOptionsList
  );
  const deleteRebuttalReaction =
    useSocialPostsCommentsRepliesRebuttalsReactionsDestroy(
      deleteReactionOptionsList
    );
  const isLoadingDeleteMutation =
    deleteReactionDetail.isLoading ||
    deleteReactionList.isLoading ||
    deleteCommentReaction.isLoading ||
    deleteReplyReaction.isLoading ||
    deleteRebuttalReaction.isLoading;

  /**
   * Narrow deleteReaction based on post type.
   */
  const deleteReaction = () => {
    const reactionId = Number(findReaction()?.id);

    return isPostType(props.post)
      ? props.detailQueryKey
        ? deleteReactionDetail.mutate({
            postsPk,
            id: reactionId,
          })
        : deleteReactionList.mutate({
            postsPk,
            id: reactionId,
          })
      : isCommentType(props.post)
        ? deleteCommentReaction.mutate({
            postsPk,
            commentsPk,
            id: reactionId,
          })
        : isReplyType(props.post)
          ? deleteReplyReaction.mutate({
              postsPk,
              commentsPk,
              repliesPk,
              id: reactionId,
            })
          : isRebuttalType(props.post)
            ? deleteRebuttalReaction.mutate({
                postsPk,
                commentsPk,
                repliesPk,
                rebuttalsPk,
                id: reactionId,
              })
            : undefined;
  };

  const toggleReaction = (emojiKey: EmojiKey) => {
    const existingReaction = findReaction();

    if (existingReaction) {
      if (existingReaction.reaction === emojiKey) {
        deleteReaction();
      } else {
        updateReaction(emojiKey);
      }
    } else {
      createReaction(emojiKey);
    }
  };

  /**
   * onSearchEmoji
   */
  const [filteredEmojis, setFilteredEmojis] = useState<Array<Emoji> | null>(
    null
  );
  const onSearchEmoji = useDebounce((value: string) => {
    if (value.trim() === "") {
      setFilteredEmojis(null);
      return;
    }
    setFilteredEmojis(
      emojiList.filter((emoji: Emoji) => emoji.key.indexOf(value) > -1)
    );
  }, 500);

  return {
    existingReactions: currentReactions,
    truncateNameList,
    toggleReaction,
    categories,
    activeCategory,
    setActiveCategory,
    categoryRefs,
    searchRef,
    filteredEmojis,
    setFilteredEmojis,
    onSearchEmoji,
    isLoading:
      isLoadingCreateMutation ||
      isLoadingUpdateMutation ||
      isLoadingDeleteMutation,
    t,
  };
};
