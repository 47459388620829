/**
 *
 *
 * useBanter
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  getTeamsBanterConfigListQueryKey,
  getTeamsRetrieveQueryKey,
  useTeamsBanterConfigList,
  useTeamsBanterConfigPartialUpdate,
  useTeamsPartialUpdate,
  useTeamsRetrieve,
} from "../../services/teambuilder/endpoints/teams/teams";
import {
  BanterConfigModel,
  BanterConfigModelDaysOfWeekItem,
  PaginatedBanterConfigModelList,
  PatchedBanterConfigModelRequest,
  PatchedBanterConfigModelRequestDaysOfWeekItem,
  PatchedTeamModelRequest,
  TeamModel,
  TimezoneEnumRequestTimezone,
} from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

const schema = z.object({
  isOn: z.boolean(),
  time: z.string().min(1, { message: "translation:validation:required_time" }),
  daysOfWeek: z.coerce
    .boolean()
    .array()
    .min(1, { message: "translation:validation:select_day" }),
  useGlobal: z.boolean(),
  useAi: z.boolean(),
  timezone: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;

export const useBanterSettings = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const days = [
    t("translation:common:sunday"),
    t("translation:common:monday"),
    t("translation:common:tuesday"),
    t("translation:common:wednesday"),
    t("translation:common:thursday"),
    t("translation:common:friday"),
    t("translation:common:saturday"),
  ];
  const teamId = TEAM_EVERYONE_ID;
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has("banter_settings");

  const { register, handleSubmit, reset, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const coerceConfigResponse = (data?: BanterConfigModel): FormData => {
    return {
      isOn: data?.isOn || false,
      time: dayjs()
        .hour(data?.hour || 8)
        .minute(data?.minute || 15)
        .format("HH:mm"),
      // hook-form uses booleans to check boxes
      daysOfWeek: Array(7)
        .fill(false)
        .map((_, i): boolean => {
          return data?.daysOfWeek
            ? data?.daysOfWeek.includes(
                i as PatchedBanterConfigModelRequestDaysOfWeekItem
              )
            : false;
        }),
      useGlobal: data?.useGlobal || false,
      useAi: data?.useAi || false,
    };
  };

  /**
   * Get config request
   */
  const configParams = useMemo(() => ({ limit: 1 }), [teamId]);
  const configQueryKey = getTeamsBanterConfigListQueryKey(teamId, configParams);
  const { data: config, isLoading: isLoadingConfig } = useTeamsBanterConfigList(
    teamId,
    configParams,
    {
      query: {
        select: ({ data }) => data[0],
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:load_banter_config"),
            type: "danger",
          });
        },
      },
    }
  );

  useEffect(() => {
    if (config) reset(coerceConfigResponse(config));
  }, [config]);

  const close = () => {
    setSearchParams({});
    if (config) reset(coerceConfigResponse(config));
  };

  /**
   * Team request
   */
  const teamQueryKey = getTeamsRetrieveQueryKey(teamId);
  const { data: team, isLoading: isLoadingTeam } = useTeamsRetrieve(teamId, {
    query: {
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:load_team_failed"),
          type: "danger",
        });
      },
    },
  });

  /**
   * Update config request
   */
  const { mutate: updateConfig } = useTeamsBanterConfigPartialUpdate(
    optimisticMutationOptions<
      BanterConfigModel,
      PaginatedBanterConfigModelList,
      { teamsPk: number; id: number; data: PatchedBanterConfigModelRequest }
    >({
      queryKey: configQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        close();
        return {
          ...context,
          data: context.data.reduce<BanterConfigModel[]>((acc, value) => {
            let v = value;
            if (value.id === requestVariables?.id) {
              v = { ...value, ...requestVariables?.data };
            }
            return [...acc, v];
          }, []),
        };
      },
      onSuccess: async () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:banter_settings_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:banter_settings_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const onSubmit = (formData: FormData) => {
    const [hour, minute] = formData.time.split(":");
    if (team) {
      updateConfig({
        id: config?.id as number,
        teamsPk: teamId,
        data: {
          ...formData,
          hour: Number(hour),
          minute: Number(minute),
          // Earlier we populated the daysOfWeek array with booleans, so we need to change those into numbers.
          daysOfWeek: formData.daysOfWeek.reduce(
            (
              acc: BanterConfigModelDaysOfWeekItem[],
              value: boolean,
              index: number
            ) => {
              if (value) {
                return [
                  ...acc,
                  index as PatchedBanterConfigModelRequestDaysOfWeekItem,
                ];
              }
              return acc;
            },
            []
          ),
        },
      });
      updateTeam({
        id: teamId,
        data: {
          timezone: {
            timezone: formData.timezone as TimezoneEnumRequestTimezone,
          },
        },
      });
    }
  };

  const onHandleSubmit = handleSubmit(onSubmit);

  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  const { mutate: updateTeam } = useTeamsPartialUpdate(
    optimisticMutationOptions<
      TeamModel,
      TeamModel,
      { id: number; data: PatchedTeamModelRequest }
    >({
      queryKey: teamQueryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        ...context,
        ...requestVariables,
      }),
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:channel_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:channel_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const updateChannel = (channelId: string | null) =>
    updateTeam({ id: teamId, data: { channelId } });

  return {
    isOpen,
    close,
    setValue,
    isLoading: isLoadingConfig || isLoadingTeam,
    days,
    team,
    timezone: team?.timezone?.timezone,
    channel: team?.connection?.channelName || undefined,
    config,
    register,
    onHandleSubmit,
    cancelButtonRef,
    updateChannel,
  };
};
