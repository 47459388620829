/**
 *
 *
 * <PeoplesList />
 *
 *
 */
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { SimpleUserModel } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";
import { People } from "./People";
import { SkeletonPeople } from "./SkeletonPeople";

interface Props {
  className?: string;
  isLoading?: boolean;
  disableActions?: boolean;
  allPeoples?: number;
  peoples: SimpleUserModel[];
  onClickAll: () => void;
  onClickPeople: (people: SimpleUserModel) => void;
  search?: string;
  setSearch: (search: string) => void;
  isFilterBySearch?: boolean;
  isAdd?: boolean;
  loadMore?: () => void;
  hasMore?: boolean;
}

export const PeoplesList = (props: Props) => {
  const { t } = useTranslation();
  const data = props.isFilterBySearch
    ? props.peoples.filter(
        (people) =>
          !props.search ||
          (props.search &&
            fullName(people).toLowerCase().indexOf(props.search.toLowerCase()) >
              -1)
      )
    : props.peoples;

  return (
    <div
      className={clsx(
        "grid grid-rows-[auto_1fr] overflow-y-auto rounded-xl",
        "border border-zinc-300",
        "h-full max-h-[40rem] min-w-[22rem] max-w-[22rem] sm:max-h-full"
      )}
    >
      <div className="space-y-2 border-b border-zinc-200 bg-zinc-100 px-4 py-3">
        <div className="flex flex-row items-center justify-between">
          <p className="text-sm font-medium leading-6 text-zinc-900">
            {props.allPeoples || props.peoples.length}{" "}
            {t(
              props.isAdd
                ? "translation:people:people"
                : "translation:people:invites"
            )}
          </p>
          <span
            className={clsx(
              "text-sm font-medium text-zinc-500 underline",
              props.disableActions
                ? "cursor-not-allowed opacity-80"
                : "cursor-pointer"
            )}
            onClick={() => !props.disableActions && props.onClickAll()}
          >
            {t(
              props.isAdd
                ? "translation:people:add_all"
                : "translation:people:remove_all"
            )}
          </span>
        </div>
        <div className="relative w-full sm:max-w-sm">
          <input
            type="text"
            className={clsx(
              "w-full rounded-full border-0 text-sm ring-1 ring-inset ring-zinc-300 focus:ring-zinc-400",
              props.disableActions && "cursor-not-allowed opacity-80"
            )}
            placeholder={t("translation:common:search")}
            value={props.search}
            disabled={props.disableActions}
            onChange={(e) => props.setSearch(e.target.value)}
          />
          <MagnifyingGlassIcon className="absolute right-1.5 top-1.5 h-6 w-6 text-zinc-300" />
        </div>
      </div>
      <div className="h-full overflow-y-auto" id="peoplesDiv">
        {props.isAdd ? (
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={props.loadMore!}
            hasMore={!!props.hasMore}
            loader={<></>}
            endMessage={<></>}
            style={{ overflow: "visible" }}
            scrollableTarget="peoplesDiv"
          >
            <ul role="list overflow-y-auto">
              {data.map((people) => (
                <People
                  key={people.id}
                  people={people}
                  isAdd={props.isAdd}
                  disableActions={props.disableActions}
                  onClick={() => props.onClickPeople(people)}
                />
              ))}
            </ul>
          </InfiniteScroll>
        ) : (
          data.map((people) => (
            <People
              key={people.id}
              people={people}
              isAdd={props.isAdd}
              disableActions={props.disableActions}
              onClick={() => props.onClickPeople(people)}
            />
          ))
        )}
        {props.isLoading &&
          new Array(3)
            .fill(1)
            .map((_, index) => <SkeletonPeople key={index} />)}
      </div>
    </div>
  );
};
