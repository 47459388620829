/**
 *
 *
 * <PostDetail />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { PostType } from "types";

import { Modal } from "../../components/ModalV3";
import { SkeletonPostListItem } from "../../components/SkeletonPostListItem";
import { Post as PostV3 } from "../PostV3";
import { usePostDetail } from "./usePostDetail";

export const PostDetail = (props: ReturnType<typeof usePostDetail>) => {
  const cancelButtonRef = React.useRef(null);
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      ref={cancelButtonRef}
      className={clsx("min-w-xs w-screen max-w-xl rounded-lg bg-white")}
      disableOutsideClick
    >
      <div className="h-16 border-b border-zinc-300"></div>
      <div className="rounded-lg">
        <ul className="mt-4">
          {props.isLoadingPost ? (
            <SkeletonPostListItem />
          ) : props.isPostError ? (
            <li className="text-center text-slate-500">
              {t("translation:post:post_load_failed")}
            </li>
          ) : props.post ? (
            <PostV3
              post={props.post as PostType}
              listQueryKey={props.listQueryKey}
              detailQueryKey={props.detailQueryKey}
              onSettledDelete={props.onClose}
              isModal
            />
          ) : null}
        </ul>
      </div>
    </Modal>
  );
};
