/**
 *
 *
 * <ChallengeDetailContent />
 *
 *
 */
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ChallengeActivityListItem } from "../../components/ChallengeActivityListItemV3/ChallengeActivityListItem";
import { Modal } from "../../components/Modal";
import { SkeletonChallengeActivities } from "../../components/SkeletonChallengeActivitiesV3/SkeletonChallengeActivities";
import { SkeletonChallengeDetailHeader } from "../../components/SkeletonChallengeDetailHeaderV3";
import { pluralize } from "../../utils/pluralize";
import { useChallengeTemplateModal } from "./useChallengeTemplateModal";

export const ChallengeTemplateModal = (
  props: ReturnType<typeof useChallengeTemplateModal>
) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={props.onCloseTemplatePreview}
      isOpen={props.isTemplatePreview}
      className={clsx(
        "w-full max-w-3xl bg-white",
        "grid grid-rows-[auto_auto] overflow-hidden"
      )}
    >
      <div className="p-8">
        {props.isLoadingTemplate && <SkeletonChallengeDetailHeader />}
        {!props.isLoadingTemplate && (
          <div className="relative pl-32 pt-0">
            <div
              className={clsx(
                "h-60 w-full bg-white py-4 pl-4",
                "flex flex-col gap-y-4 overflow-hidden"
              )}
            >
              <div className="flex flex-grow flex-col gap-y-4 p-4 sm:p-0">
                <span className="sr-only">Challenge Title</span>
                <div className="w-full rounded-lg bg-zinc-100 p-2 py-4 sm:py-2 sm:pl-32">
                  <h1 className="mb-1 truncate whitespace-nowrap text-center text-xl font-medium leading-7 text-zinc-900 sm:text-left">
                    {props.template?.name}
                  </h1>
                  <div className="text-sm text-zinc-600">
                    {t("translation:challenge:template_preview")}
                  </div>
                </div>

                <span className="sr-only">Challenge Description</span>
                <p className="line-clamp-3 flex-grow truncate whitespace-break-spaces pl-0 text-sm text-zinc-900 sm:pl-32">
                  {props.template?.description}
                </p>
              </div>
            </div>

            <div
              className={clsx(
                "absolute h-40 w-40 sm:h-64 sm:w-64",
                "left-1/2 -translate-x-1/2 sm:left-0 sm:translate-x-0",
                "top-0 sm:top-1/2 sm:-translate-y-1/2"
              )}
            >
              {props.template?.banner ? (
                <div
                  className={clsx(
                    "h-full w-full bg-cover bg-center",
                    "shadow-m rounded-full border-4 border-white bg-zinc-100"
                  )}
                  style={{
                    backgroundImage: `url(${props.template?.banner.medium})`,
                  }}
                />
              ) : (
                <div
                  className={clsx(
                    "pointer-events-none h-full w-full rounded-full border-4 border-white shadow-md",
                    "bg-gradient-to-br from-amber-200 to-amber-500"
                  )}
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-8 flex flex-row">
          <div className="h-fit w-64 flex-shrink-0 rounded-l-lg bg-zinc-100 px-2 py-6 text-center text-base font-medium text-zinc-600 shadow-md">
            {props.templateActivities?.length}{" "}
            {t(
              `translation:challenge:${pluralize("action", "actions", props.templateActivities?.length || 0)}`
            )}
          </div>
          <div
            className={clsx(
              "flex-grow rounded-r-lg rounded-bl-lg bg-zinc-100 p-6",
              "text-center text-base font-medium"
            )}
          >
            <div className="max-h-96 space-y-6 overflow-hidden hover:overflow-y-auto">
              {props.isLoadingTemplateActivities && (
                <SkeletonChallengeActivities />
              )}
              {!props.isLoadingTemplateActivities &&
                props.templateActivities?.map((activity, i) => (
                  <ChallengeActivityListItem
                    index={i}
                    key={i}
                    activity={activity}
                    isTemplate
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="mt-6 flex flex-row justify-center gap-x-4 sm:justify-end">
          <Button
            variant="outlined"
            size="md"
            onClick={props.onCloseTemplatePreview}
            disabled={props.isLoadingCreateChallenge}
          >
            {t("translation:common:cancel")}
          </Button>
          <Button
            variant="challenge"
            size="md"
            onClick={props.onSelectTemplate}
            className="gap-1 bg-challenge"
            disabled={props.isLoadingCreateChallenge}
          >
            {t("translation:common:select")}
            <ChevronRightIcon className="w-5" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
