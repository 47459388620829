/**
 *
 *
 * useCompanySettings
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  getIntuitionCustomersCompaniesMeRetrieveQueryKey,
  useIntuitionCustomersCompaniesMeRetrieve,
  useIntuitionCustomersCompaniesPartialUpdate,
  useIntuitionPrefsCompanyMeLogoCreate,
  useIntuitionPrefsCompanyMeLogoDestroy,
} from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  CompanyModel,
  CompanyPreferenceModel,
  PatchedCompanyModelRequest,
} from "../../services/teambuilder/schemas";
import { convertHeic } from "../../utils/heic-to-jpg";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

const companyNameSchema = z.object({
  company_name: z
    .string()
    .min(1, { message: "translation:validation:required_company_name" })
    .max(250, { message: "translation:validation:less_than_250" }),
});

export type FormData = z.infer<typeof companyNameSchema>;

export const useGeneralSettings = () => {
  const { t } = useTranslation();
  const { openToast } = useToast();

  /**
   * Form config
   */
  const form = useForm<FormData>({
    resolver: zodResolver(companyNameSchema),
    // Trigger validation on all events.
    mode: "all",
  });

  /**
   *
   * File input
   *
   */
  const [previewImage, setPreviewImage] = useState<Blob>();
  const [isUpdating, setIsUpdating] = useState(false);

  const [isHeicToJpg, setIsHeicToJpg] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onClickInput = () => {
    inputFileRef.current?.click();
  };
  const onFileAdded = async (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0] as File;
    if (file.name.match(/.*\.heic$/i)) {
      setIsHeicToJpg(true);
      file = await convertHeic(URL.createObjectURL(file));
      setIsHeicToJpg(false);
    }
    setPreviewImage(file);
    setIsUpdating(true);
    if (inputFileRef.current) inputFileRef.current.value = "";
  };

  const onSaveLogo = async () => {
    if (!previewImage) return;
    const file = new File([previewImage], "coverimage.png");
    if (file) {
      uploadFile({ data: { file } });
    }
  };

  const onCancel = () => {
    setIsUpdating(false);
  };

  /**
   * Company request
   */
  const {
    data: company,
    isLoading: isLoadingCompany,
    refetch: refetchCompany,
  } = useIntuitionCustomersCompaniesMeRetrieve();

  /**
   * Company onSuccess
   */
  useEffect(() => {
    if (company) {
      form.reset({ company_name: company.name });
    }
  }, [company]);

  /**
   * Upload file request
   */
  const { mutate: uploadFile, isLoading: isLoadingUploadFile } =
    useIntuitionPrefsCompanyMeLogoCreate<AxiosError>({
      mutation: {
        onSuccess: async () => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:logo_save_success"),
          });
        },
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:logo_save_failed"),
            type: "danger",
          });
        },
        onSettled: () => refetchCompany(),
      },
    });

  /**
   * Update company request
   */
  const queryKey = getIntuitionCustomersCompaniesMeRetrieveQueryKey();
  const { mutate: updateCompany } = useIntuitionCustomersCompaniesPartialUpdate(
    optimisticMutationOptions<
      CompanyModel,
      CompanyPreferenceModel,
      {
        id: number;
        data: PatchedCompanyModelRequest;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestData) => {
        return {
          ...context,
          name: requestData?.data.name,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:company_name_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:company_name_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const onSaveCompanyName: SubmitHandler<FormData> = (e) => {
    if (company && company.id) {
      try {
        updateCompany({
          id: company.id,
          data: { name: e.company_name },
        });
      } catch (e) {
        // Validation failed
      }
    }
  };
  const onHandleSubmitCompanyName = form.handleSubmit(onSaveCompanyName);

  /**
   * Delete avatar request
   */
  const { mutate: deleteAvatar } = useIntuitionPrefsCompanyMeLogoDestroy(
    optimisticMutationOptions<
      void | CompanyPreferenceModel,
      CompanyPreferenceModel,
      void
    >({
      queryKey,
      optimisticUpdateFn: (context) => ({
        ...context,
        logo: null,
      }),
      onSuccess: async () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:logo_delete_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:logo_delete_failed"),
          type: "danger",
        });
      },
    })
  );

  const onDeleteAvatar = () => {
    deleteAvatar();
  };

  return {
    form,
    onHandleSubmitCompanyName,
    company,
    isLoadingCompany,
    isUpdating,
    companyUrl: `https://${window.location.hostname}`,
    isLoadingUploadFile,
    isHeicToJpg,
    inputFileRef,
    onClickInput,
    onFileAdded,
    onDeleteAvatar,
    previewImage,
    onSaveLogo,
    onCancel,
  };
};
