/**
 *
 *
 * <Spinner />
 *
 *
 */
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";

interface Props {
  className?: string;
}

export const Spinner = (props: Props) => {
  return (
    <ArrowPathIcon
      className={clsx("h-5 w-5 animate-spin text-zinc-500", props.className)}
    />
  );
};
