/**
 *
 *
 * ProfileInfo
 *
 *
 */
import {
  BuildingOfficeIcon,
  CakeIcon,
  ClockIcon,
  GlobeAltIcon,
  MapPinIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { PowerIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  LanguageChoices,
  PeopleRetrieve,
  UserGuides,
} from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { fullName } from "../../utils/username";
import { Bio } from "./Bio";
import { EditButton } from "./EditButton";
import { InfoListItem } from "./InfoListItem";

interface Props {
  showEditButton: boolean;
  openProfileInfoForm: () => void;
  user?: PeopleRetrieve;
  userIsMe: boolean;
  userGuide?: UserGuides;
  allLanguages?: LanguageChoices[];
  className?: string;
}

export const ProfileInfo = (props: Props) => {
  const { t } = useTranslation();
  const languages = props.userGuide?.languages
    ?.map(
      (code) =>
        props.allLanguages?.find((lang) => lang.code === code.language)?.name
    )
    .join(", ");
  const pronouns = {
    HE_HIM: "He/Him",
    SHE_HER: "She/Her",
    THEY_THEM: "They/Them",
    ZE_HIR: "Ze/Hir",
    EY_EM: "Ey/Em",
    PER_PER: "Per/Per",
    VE_VER: "Ve/Ver",
    OTHER: props.userGuide?.customPronouns,
  }[props.userGuide?.pronouns || "OTHER"];

  return (
    <div className={clsx("space-y-6", props.className)}>
      <div className="flex gap-4">
        <div className="grow text-center sm:text-left">
          <h1
            className={clsx(
              "text-2xl font-light",
              "leading-normal tracking-tight text-zinc-900"
            )}
          >
            {fullName(props.user)}
          </h1>

          {props.user?.jobTitle && (
            <div className="text-base tracking-tight text-zinc-500 sm:block sm:justify-start">
              {props.user.jobTitle}
            </div>
          )}
        </div>

        {props.userIsMe && (
          <div className="hidden sm:block">
            <EditButton size="sm" onClick={props.openProfileInfoForm} />
          </div>
        )}
      </div>

      <ul className="list-none space-y-4 text-sm text-zinc-900 sm:space-y-3">
        {pronouns && (
          <InfoListItem
            name={t("translation:user_guide:pronouns")}
            description={pronouns}
            icon={UserIcon}
          />
        )}

        {props.userGuide?.birthDate && (
          <InfoListItem
            name={t("translation:user_guide:birthday")}
            description={dayjs(props.userGuide?.birthDate).format("MMM D")}
            icon={CakeIcon}
          />
        )}

        {props.userGuide?.startDate && (
          <InfoListItem
            name={t("translation:user_guide:start_date")}
            description={dayjs(props.userGuide?.startDate).format(
              "MMM D, YYYY"
            )}
            icon={PowerIcon}
          />
        )}

        {props.user?.timezone && (
          <InfoListItem
            name={t("translation:common:timezone")}
            description={props.user?.timezone.timezone}
            icon={ClockIcon}
          />
        )}

        {languages && (
          <InfoListItem
            name={t("translation:user_guide:languages")}
            description={languages}
            icon={GlobeAltIcon}
          />
        )}

        {props.user?.departments && props.user?.departments?.length > 0 && (
          <InfoListItem
            name={t("translation:common:departments")}
            description={props.user.departments.map((d) => d.name).join(", ")}
            icon={BuildingOfficeIcon}
          />
        )}

        {props.user?.locations && props.user?.locations?.length > 0 && (
          <InfoListItem
            name={t("translation:common:locations")}
            description={props.user.locations.map((l) => l.name).join(", ")}
            icon={MapPinIcon}
          />
        )}
      </ul>

      {/*
       *
       * Bio
       *
       */}

      {props.userGuide && props.user ? (
        <Bio
          openEditProfileInfoForm={props.openProfileInfoForm}
          userGuide={props.userGuide}
          user={props.user}
          userIsMe={props.userIsMe}
        />
      ) : (
        <div className="h-12 w-full animate-pulse rounded-md bg-zinc-300" />
      )}

      {props.showEditButton && (
        <EditButton
          onClick={props.openProfileInfoForm}
          className="w-full sm:hidden"
        />
      )}
    </div>
  );
};
