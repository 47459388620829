/**
 *
 *
 * <SkeletonChallengeActivities />
 *
 *
 */
import React from "react";

export const SkeletonChallengeActivities = () => {
  return (
    <div className="space-y-6">
      <div className="flex animate-pulse flex-row items-center gap-x-2 rounded-lg border-2 border-zinc-300 p-3">
        <div className="h-9 w-9 rounded-full bg-zinc-300" />
        <div className="flex flex-grow flex-col gap-y-2">
          <div className="h-4 rounded-lg bg-zinc-300" />
          <div className="h-4 rounded-lg bg-zinc-300" />
        </div>
      </div>
      <div className="flex animate-pulse flex-row items-center gap-x-2 rounded-lg border-2 border-zinc-300 p-3">
        <div className="h-9 w-9 rounded-full bg-zinc-300" />
        <div className="flex flex-grow flex-col gap-y-2">
          <div className="h-4 rounded-lg bg-zinc-300" />
          <div className="h-4 rounded-lg bg-zinc-300" />
        </div>
      </div>
      <div className="flex animate-pulse flex-row items-center gap-x-2 rounded-lg border-2 border-zinc-300 p-3">
        <div className="h-9 w-9 rounded-full bg-zinc-300" />
        <div className="flex flex-grow flex-col gap-y-2">
          <div className="h-4 rounded-lg bg-zinc-300" />
          <div className="h-4 rounded-lg bg-zinc-300" />
        </div>
      </div>
    </div>
  );
};
