/**
 *
 *
 * classNames - Join an array of strings into a space-separated string.
 *
 *
 */
export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};
