/**
 *
 *
 * useChallenges
 *
 *
 */
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { usePageContext } from "../../components/PageProvider";
import { SearchParam } from "../../consts";
import { useCreateChallenge } from "../../hooks/useCreateChallenge";
import { useRoles } from "../../hooks/useRoles";
import {
  useChallengesList,
  useChallengesMeList,
  useChallengesTemplatesList,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import {
  Challenge,
  ChallengesListParams,
} from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { TabKey } from "./types";

type ChallengeSectionKey = Exclude<TabKey, "templates">;
const SHOW_TEMPLATES = 6;

interface Props {
  className?: string;
}

export const useChallenges = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { challengeId: _challengeId } = useParams();
  const { isAdmin } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { onCreateDraftChallenge } = useCreateChallenge();

  const { setPageContext } = usePageContext();
  useEffect(() => {
    setPageContext({
      title: t("translation:challenge:challenges"),
      subtitle: t("translation:challenge:browse_challenges"),
      learnMoreUrl: "https://intuition.click/teambuilder-challenges-hc",
    });
    // Adding `setPageContext` causes "Maximum update depth exceeded" error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, t]);

  const challengesListParams = useMemo<
    Record<ChallengeSectionKey, ChallengesListParams>
  >(() => {
    const now = dayjs().toISOString();
    const paramsMap: Record<ChallengeSectionKey, ChallengesListParams> = {
      upcoming: {
        start_at__gte: now,
        ordering: "start_at",
        is_draft: false,
      },
      ended: {
        end_at__lte: now,
        ordering: "end_at",
      },
      // `current` is unused. Current challenges are returned by useChallengesMeList.
      current: {
        start_at__lte: now,
        end_at__gte: now,
        ordering: "end_at",
        is_draft: false,
      },
    };
    return paramsMap;
  }, []);

  /**
   * upcoming challenges
   */
  const {
    data: upcomingChallenges,
    isInitialLoading: isLoadingUpcomingChallenges,
  } = useChallengesList<Challenge[]>(
    { ...challengesListParams.upcoming, limit: 999 },
    {
      query: {
        select: ({ data }) => data,
        staleTime: 30000,
      },
    }
  );

  /**
   * completed challenges
   */
  const {
    data: completedChallenges,
    isInitialLoading: isLoadingCompletedChallenges,
  } = useChallengesList<Challenge[]>(
    { ...challengesListParams.ended, limit: 999 },
    {
      query: {
        select: ({ data }) => data,
        staleTime: 30000,
      },
    }
  );

  /**
   * current challenges
   */
  const { data: currentChallenges, isInitialLoading: isLoadingChallengesMe } =
    useChallengesMeList<Challenge[]>(
      { limit: 999, status: "running", is_draft: false },
      {
        query: {
          select: ({ data }) => data,
          staleTime: 30000,
        },
      }
    );

  /**
   * template challenges
   */
  const { data: templates, isInitialLoading: isLoadingTemplates } =
    useChallengesTemplatesList(
      {
        limit: SHOW_TEMPLATES,
      },
      {
        query: {
          select: ({ data }) => data,
        },
      }
    );

  const onTemplatePreview = (id: number) => {
    if (searchParams.has(SearchParam.TEMPLATE_PREVIEW)) {
      searchParams.set(SearchParam.TEMPLATE_PREVIEW, id.toString());
    } else {
      searchParams.append(SearchParam.TEMPLATE_PREVIEW, id.toString());
    }
    setSearchParams(searchParams);
  };

  const onCreateChallenge = () => {
    navigate("/challenges/wizard");
  };

  return {
    isAdmin,
    currentChallenges: currentChallenges || [],
    upcomingChallenges: upcomingChallenges || [],
    completedChallenges: completedChallenges || [],
    templates,
    challengeId: _challengeId,
    isEmpty:
      currentChallenges?.length === 0 &&
      upcomingChallenges?.length === 0 &&
      completedChallenges?.length === 0,
    isLoading:
      isLoadingUpcomingChallenges ||
      isLoadingTemplates ||
      isLoadingChallengesMe ||
      isLoadingCompletedChallenges,
    onTemplatePreview,
    onCreateChallenge,
    onSelectTemplate: onCreateDraftChallenge,
    className: props.className,
  };
};
