/**
 *
 *
 * <IconStar />
 *
 *
 */

interface IconStarProps {
  size?: number;
}
export const IconStar = ({ size = 96 }: IconStarProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
      >
        <g filter="url(#filter0_d_3578_35473)">
          <path
            d="M48 0L54.6473 6.30156L63.0489 2.65353L67.14 10.8485L76.2827 10.294L77.3242 19.3941L86.1051 22L83.9714 30.9075L91.3315 36.3595L86.28 44L91.3315 51.6405L83.9714 57.0925L86.1051 66L77.3242 68.6059L76.2827 77.706L67.14 77.1515L63.0489 85.3465L54.6473 81.6984L48 88L41.3527 81.6984L32.9511 85.3465L28.86 77.1515L19.7173 77.706L18.6758 68.6059L9.89488 66L12.0286 57.0925L4.66846 51.6405L9.72 44L4.66846 36.3595L12.0286 30.9075L9.89488 22L18.6758 19.3941L19.7173 10.294L28.86 10.8485L32.9511 2.65353L41.3527 6.30156L48 0Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_3578_35473"
            x="0.668457"
            y="0"
            width="94.6631"
            height="96"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3578_35473"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3578_35473"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};
