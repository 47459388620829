/**
 *
 *
 * useTimezoneForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  usePeopleMeRetrieve,
  usePeoplePartialUpdate,
} from "../../services/teambuilder/endpoints/people/people";
import { TimezoneEnumRequestTimezone } from "../../services/teambuilder/schemas";

export const schema = z.object({
  timezone: z.string().min(1, "translation:user_guide:required_timezone"),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  userGuideId?: number;
  className?: string;
}

export const useTimezoneForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  /**
   *
   *
   * Requests
   *
   */
  const {
    data: me,
    isLoading,
    refetch: refetchMe,
  } = usePeopleMeRetrieve({
    query: {
      onSuccess: (me) => {
        form.reset({ timezone: me.timezone?.timezone });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:profile_load_failed"),
          type: "danger",
        });
      },
    },
  });

  /**
   * Form
   */
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { timezone: me ? me.timezone?.timezone : undefined },
  });
  const timezone = form.watch("timezone");

  /**
   *
   * Create
   *
   */
  const { mutate: updateUserProfile } = usePeoplePartialUpdate<
    AxiosError<
      Record<string, string[]>,
      {
        name?: string[];
        slug?: string[];
      }
    >
  >({
    mutation: {
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:timezone_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:timezone_update_failed"),
          type: "danger",
        });
      },
      onSettled: () => refetchMe(),
    },
  });

  const onSubmit: SubmitHandler<FormData> = (formData: FormData) => {
    const { timezone: _timezone } = formData;
    const timezone = _timezone
      ? { timezone: _timezone as TimezoneEnumRequestTimezone }
      : undefined;
    if (me?.id)
      updateUserProfile({
        id: me.id,
        data: { timezone },
      });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    timezone,
    me,
    form,
    onHandleSubmit,
    isLoading: !props.userGuideId || !me || isLoading,
    className: props.className,
  };
};
