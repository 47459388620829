/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  Badge,
  BadgeRequest,
  BadgesEarned,
  BadgesEarnedRequest,
  Intersection,
  PaginatedBadgeList,
  PaginatedBadgesEarnedList,
  PaginatedShoutoutList,
  PatchedBadgeRequest,
  PatchedBadgesEarnedRequest,
  PatchedShoutoutCreateRequest,
  RecognitionBadgesEarnedListParams,
  RecognitionBadgesListParams,
  RecognitionIntersectionListParams,
  RecognitionShoutoutsListParams,
  Shoutout,
  ShoutoutCreate,
  ShoutoutCreateRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Badges for various things (challenges, shoutouts, engagement, etc) that users earn for doing those things
 */
export const recognitionBadgesList = (
  params?: RecognitionBadgesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedBadgeList>(
    { url: `/api/v1/recognition/badges/`, method: "GET", params, signal },
    options
  );
};

export const getRecognitionBadgesListQueryKey = (
  params?: RecognitionBadgesListParams
) => {
  return [`/api/v1/recognition/badges/`, ...(params ? [params] : [])] as const;
};

export const getRecognitionBadgesListQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionBadgesList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionBadgesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionBadgesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionBadgesList>>
  > = ({ signal }) => recognitionBadgesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionBadgesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionBadgesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesList>>
>;
export type RecognitionBadgesListQueryError = ErrorType<unknown>;

export const useRecognitionBadgesList = <
  TData = Awaited<ReturnType<typeof recognitionBadgesList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionBadgesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionBadgesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Badges for various things (challenges, shoutouts, engagement, etc) that users earn for doing those things
 */
export const recognitionBadgesCreate = (
  badgeRequest: BadgeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Badge>(
    {
      url: `/api/v1/recognition/badges/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: badgeRequest,
    },
    options
  );
};

export const getRecognitionBadgesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesCreate>>,
    TError,
    { data: BadgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesCreate>>,
  TError,
  { data: BadgeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesCreate>>,
    { data: BadgeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return recognitionBadgesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesCreate>>
>;
export type RecognitionBadgesCreateMutationBody = BadgeRequest;
export type RecognitionBadgesCreateMutationError = ErrorType<unknown>;

export const useRecognitionBadgesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesCreate>>,
    TError,
    { data: BadgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getRecognitionBadgesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Badges for various things (challenges, shoutouts, engagement, etc) that users earn for doing those things
 */
export const recognitionBadgesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Badge>(
    { url: `/api/v1/recognition/badges/${id}/`, method: "GET", signal },
    options
  );
};

export const getRecognitionBadgesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/recognition/badges/${id}/`] as const;
};

export const getRecognitionBadgesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionBadgesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionBadgesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionBadgesRetrieve>>
  > = ({ signal }) => recognitionBadgesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionBadgesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionBadgesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesRetrieve>>
>;
export type RecognitionBadgesRetrieveQueryError = ErrorType<unknown>;

export const useRecognitionBadgesRetrieve = <
  TData = Awaited<ReturnType<typeof recognitionBadgesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionBadgesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Badges for various things (challenges, shoutouts, engagement, etc) that users earn for doing those things
 */
export const recognitionBadgesPartialUpdate = (
  id: number,
  patchedBadgeRequest: PatchedBadgeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Badge>(
    {
      url: `/api/v1/recognition/badges/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedBadgeRequest,
    },
    options
  );
};

export const getRecognitionBadgesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesPartialUpdate>>,
    TError,
    { id: number; data: PatchedBadgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesPartialUpdate>>,
  TError,
  { id: number; data: PatchedBadgeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesPartialUpdate>>,
    { id: number; data: PatchedBadgeRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return recognitionBadgesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesPartialUpdate>>
>;
export type RecognitionBadgesPartialUpdateMutationBody = PatchedBadgeRequest;
export type RecognitionBadgesPartialUpdateMutationError = ErrorType<unknown>;

export const useRecognitionBadgesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesPartialUpdate>>,
    TError,
    { id: number; data: PatchedBadgeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionBadgesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Badges for various things (challenges, shoutouts, engagement, etc) that users earn for doing those things
 */
export const recognitionBadgesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/recognition/badges/${id}/`, method: "DELETE" },
    options
  );
};

export const getRecognitionBadgesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return recognitionBadgesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesDestroy>>
>;

export type RecognitionBadgesDestroyMutationError = ErrorType<unknown>;

export const useRecognitionBadgesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getRecognitionBadgesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Badges that users have earned
 */
export const recognitionBadgesEarnedList = (
  params?: RecognitionBadgesEarnedListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedBadgesEarnedList>(
    {
      url: `/api/v1/recognition/badges_earned/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getRecognitionBadgesEarnedListQueryKey = (
  params?: RecognitionBadgesEarnedListParams
) => {
  return [
    `/api/v1/recognition/badges_earned/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRecognitionBadgesEarnedListQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionBadgesEarnedList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionBadgesEarnedListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesEarnedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionBadgesEarnedListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionBadgesEarnedList>>
  > = ({ signal }) =>
    recognitionBadgesEarnedList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionBadgesEarnedListQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesEarnedList>>
>;
export type RecognitionBadgesEarnedListQueryError = ErrorType<unknown>;

export const useRecognitionBadgesEarnedList = <
  TData = Awaited<ReturnType<typeof recognitionBadgesEarnedList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionBadgesEarnedListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesEarnedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionBadgesEarnedListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Badges that users have earned
 */
export const recognitionBadgesEarnedCreate = (
  badgesEarnedRequest: BadgesEarnedRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BadgesEarned>(
    {
      url: `/api/v1/recognition/badges_earned/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: badgesEarnedRequest,
    },
    options
  );
};

export const getRecognitionBadgesEarnedCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedCreate>>,
    TError,
    { data: BadgesEarnedRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesEarnedCreate>>,
  TError,
  { data: BadgesEarnedRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesEarnedCreate>>,
    { data: BadgesEarnedRequest }
  > = (props) => {
    const { data } = props ?? {};

    return recognitionBadgesEarnedCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesEarnedCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesEarnedCreate>>
>;
export type RecognitionBadgesEarnedCreateMutationBody = BadgesEarnedRequest;
export type RecognitionBadgesEarnedCreateMutationError = ErrorType<unknown>;

export const useRecognitionBadgesEarnedCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedCreate>>,
    TError,
    { data: BadgesEarnedRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionBadgesEarnedCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Badges that users have earned
 */
export const recognitionBadgesEarnedRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BadgesEarned>(
    { url: `/api/v1/recognition/badges_earned/${id}/`, method: "GET", signal },
    options
  );
};

export const getRecognitionBadgesEarnedRetrieveQueryKey = (id: number) => {
  return [`/api/v1/recognition/badges_earned/${id}/`] as const;
};

export const getRecognitionBadgesEarnedRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionBadgesEarnedRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>
  > = ({ signal }) =>
    recognitionBadgesEarnedRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionBadgesEarnedRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>
>;
export type RecognitionBadgesEarnedRetrieveQueryError = ErrorType<unknown>;

export const useRecognitionBadgesEarnedRetrieve = <
  TData = Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionBadgesEarnedRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionBadgesEarnedRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Badges that users have earned
 */
export const recognitionBadgesEarnedPartialUpdate = (
  id: number,
  patchedBadgesEarnedRequest: PatchedBadgesEarnedRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BadgesEarned>(
    {
      url: `/api/v1/recognition/badges_earned/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedBadgesEarnedRequest,
    },
    options
  );
};

export const getRecognitionBadgesEarnedPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedPartialUpdate>>,
    TError,
    { id: number; data: PatchedBadgesEarnedRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesEarnedPartialUpdate>>,
  TError,
  { id: number; data: PatchedBadgesEarnedRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesEarnedPartialUpdate>>,
    { id: number; data: PatchedBadgesEarnedRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return recognitionBadgesEarnedPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesEarnedPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesEarnedPartialUpdate>>
>;
export type RecognitionBadgesEarnedPartialUpdateMutationBody =
  PatchedBadgesEarnedRequest;
export type RecognitionBadgesEarnedPartialUpdateMutationError =
  ErrorType<unknown>;

export const useRecognitionBadgesEarnedPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedPartialUpdate>>,
    TError,
    { id: number; data: PatchedBadgesEarnedRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionBadgesEarnedPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Badges that users have earned
 */
export const recognitionBadgesEarnedDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/recognition/badges_earned/${id}/`, method: "DELETE" },
    options
  );
};

export const getRecognitionBadgesEarnedDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionBadgesEarnedDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionBadgesEarnedDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return recognitionBadgesEarnedDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionBadgesEarnedDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionBadgesEarnedDestroy>>
>;

export type RecognitionBadgesEarnedDestroyMutationError = ErrorType<unknown>;

export const useRecognitionBadgesEarnedDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionBadgesEarnedDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionBadgesEarnedDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * The intersection of the user's & recipient's teams, departments, and locations
 */
export const recognitionIntersectionList = (
  params?: RecognitionIntersectionListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Intersection[]>(
    { url: `/api/v1/recognition/intersection/`, method: "GET", params, signal },
    options
  );
};

export const getRecognitionIntersectionListQueryKey = (
  params?: RecognitionIntersectionListParams
) => {
  return [
    `/api/v1/recognition/intersection/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRecognitionIntersectionListQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionIntersectionList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionIntersectionListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionIntersectionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionIntersectionListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionIntersectionList>>
  > = ({ signal }) =>
    recognitionIntersectionList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionIntersectionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionIntersectionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionIntersectionList>>
>;
export type RecognitionIntersectionListQueryError = ErrorType<unknown>;

export const useRecognitionIntersectionList = <
  TData = Awaited<ReturnType<typeof recognitionIntersectionList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionIntersectionListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionIntersectionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionIntersectionListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Shoutouts

Users can shout out other Users on their Team(s), selecting a corporate value if they choose.

## Permissions

```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read shoutouts
        iperm.ObjectOwnerPermissions,  # the writer of an shoutout can update/delete
        iperm.HasRolePermission,  # HR can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can create a shoutout
    )
]
required_roles = ["HR"]
```
 */
export const recognitionShoutoutsList = (
  params?: RecognitionShoutoutsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedShoutoutList>(
    { url: `/api/v1/recognition/shoutouts/`, method: "GET", params, signal },
    options
  );
};

export const getRecognitionShoutoutsListQueryKey = (
  params?: RecognitionShoutoutsListParams
) => {
  return [
    `/api/v1/recognition/shoutouts/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getRecognitionShoutoutsListQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionShoutoutsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionShoutoutsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionShoutoutsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsList>>
  > = ({ signal }) => recognitionShoutoutsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionShoutoutsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsList>>
>;
export type RecognitionShoutoutsListQueryError = ErrorType<unknown>;

export const useRecognitionShoutoutsList = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsList>>,
  TError = ErrorType<unknown>,
>(
  params?: RecognitionShoutoutsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionShoutoutsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionShoutoutsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Shoutouts

Users can shout out other Users on their Team(s), selecting a corporate value if they choose.

## Permissions

```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read shoutouts
        iperm.ObjectOwnerPermissions,  # the writer of an shoutout can update/delete
        iperm.HasRolePermission,  # HR can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can create a shoutout
    )
]
required_roles = ["HR"]
```
 */
export const recognitionShoutoutsCreate = (
  shoutoutCreateRequest: ShoutoutCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<ShoutoutCreate>(
    {
      url: `/api/v1/recognition/shoutouts/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: shoutoutCreateRequest,
    },
    options
  );
};

export const getRecognitionShoutoutsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsCreate>>,
    TError,
    { data: ShoutoutCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionShoutoutsCreate>>,
  TError,
  { data: ShoutoutCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsCreate>>,
    { data: ShoutoutCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return recognitionShoutoutsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionShoutoutsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsCreate>>
>;
export type RecognitionShoutoutsCreateMutationBody = ShoutoutCreateRequest;
export type RecognitionShoutoutsCreateMutationError = ErrorType<unknown>;

export const useRecognitionShoutoutsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsCreate>>,
    TError,
    { data: ShoutoutCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getRecognitionShoutoutsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Shoutouts

Users can shout out other Users on their Team(s), selecting a corporate value if they choose.

## Permissions

```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read shoutouts
        iperm.ObjectOwnerPermissions,  # the writer of an shoutout can update/delete
        iperm.HasRolePermission,  # HR can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can create a shoutout
    )
]
required_roles = ["HR"]
```
 */
export const recognitionShoutoutsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Shoutout>(
    { url: `/api/v1/recognition/shoutouts/${id}/`, method: "GET", signal },
    options
  );
};

export const getRecognitionShoutoutsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/recognition/shoutouts/${id}/`] as const;
};

export const getRecognitionShoutoutsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionShoutoutsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>
  > = ({ signal }) => recognitionShoutoutsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionShoutoutsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>
>;
export type RecognitionShoutoutsRetrieveQueryError = ErrorType<unknown>;

export const useRecognitionShoutoutsRetrieve = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recognitionShoutoutsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionShoutoutsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Shoutouts

Users can shout out other Users on their Team(s), selecting a corporate value if they choose.

## Permissions

```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read shoutouts
        iperm.ObjectOwnerPermissions,  # the writer of an shoutout can update/delete
        iperm.HasRolePermission,  # HR can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can create a shoutout
    )
]
required_roles = ["HR"]
```
 */
export const recognitionShoutoutsPartialUpdate = (
  id: number,
  patchedShoutoutCreateRequest: PatchedShoutoutCreateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<ShoutoutCreate>(
    {
      url: `/api/v1/recognition/shoutouts/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedShoutoutCreateRequest,
    },
    options
  );
};

export const getRecognitionShoutoutsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsPartialUpdate>>,
    TError,
    { id: number; data: PatchedShoutoutCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionShoutoutsPartialUpdate>>,
  TError,
  { id: number; data: PatchedShoutoutCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsPartialUpdate>>,
    { id: number; data: PatchedShoutoutCreateRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return recognitionShoutoutsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionShoutoutsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsPartialUpdate>>
>;
export type RecognitionShoutoutsPartialUpdateMutationBody =
  PatchedShoutoutCreateRequest;
export type RecognitionShoutoutsPartialUpdateMutationError = ErrorType<unknown>;

export const useRecognitionShoutoutsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsPartialUpdate>>,
    TError,
    { id: number; data: PatchedShoutoutCreateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionShoutoutsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Shoutouts

Users can shout out other Users on their Team(s), selecting a corporate value if they choose.

## Permissions

```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read shoutouts
        iperm.ObjectOwnerPermissions,  # the writer of an shoutout can update/delete
        iperm.HasRolePermission,  # HR can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can create a shoutout
    )
]
required_roles = ["HR"]
```
 */
export const recognitionShoutoutsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/recognition/shoutouts/${id}/`, method: "DELETE" },
    options
  );
};

export const getRecognitionShoutoutsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recognitionShoutoutsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return recognitionShoutoutsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecognitionShoutoutsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsDestroy>>
>;

export type RecognitionShoutoutsDestroyMutationError = ErrorType<unknown>;

export const useRecognitionShoutoutsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getRecognitionShoutoutsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Shoutouts I've received
 */
export const recognitionShoutoutsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Shoutout>(
    { url: `/api/v1/recognition/shoutouts/me/`, method: "GET", signal },
    options
  );
};

export const getRecognitionShoutoutsMeRetrieveQueryKey = () => {
  return [`/api/v1/recognition/shoutouts/me/`] as const;
};

export const getRecognitionShoutoutsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRecognitionShoutoutsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>
  > = ({ signal }) => recognitionShoutoutsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecognitionShoutoutsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>
>;
export type RecognitionShoutoutsMeRetrieveQueryError = ErrorType<unknown>;

export const useRecognitionShoutoutsMeRetrieve = <
  TData = Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof recognitionShoutoutsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecognitionShoutoutsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
