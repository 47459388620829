/**
 *
 *
 * useNotifications
 *
 *
 */
import { useEffect, useMemo, useState } from "react";

import { queryClient } from "../../query-client";
import {
  getNotificationsMeListQueryKey,
  useNotificationsMeList,
  useNotificationsPartialUpdate,
  useNotificationsReadAllRetrieve,
} from "../../services/teambuilder/endpoints/notifications/notifications";
import {
  NotificationModel,
  NotificationsMeListParams,
  PaginatedNotificationModelList,
  PatchedNotificationModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  isCollapsed?: boolean;
}

export const useNotifications = (props: Props) => {
  const [markAllAsRead, setMarkAllAsRead] = useState(false);

  // params should match those in useLayout.ts
  const params: NotificationsMeListParams = useMemo(
    () => ({
      ordering: "-created_at",
    }),
    []
  );

  const {
    data: messages,
    isError,
    isLoading,
  } = useNotificationsMeList(params, {
    query: {
      select: ({ data }) => data,
      // useLayout polls every 15 seconds. We should not have to poll here.
    },
  });

  useEffect(() => {
    setMarkAllAsRead(false);
  }, [messages?.length]);

  const queryKey = getNotificationsMeListQueryKey(params);

  /**
   * mark all as read
   */
  useNotificationsReadAllRetrieve({
    query: {
      enabled: markAllAsRead,
    },
  });

  /**
   * update one notification
   */
  const { mutate: updateNotification } = useNotificationsPartialUpdate(
    optimisticMutationOptions<
      NotificationModel,
      PaginatedNotificationModelList,
      {
        id: number;
        data: PatchedNotificationModelRequest;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => ({
        ...context,
        data: context.data.reduce<NotificationModel[]>((acc, value) => {
          let v: NotificationModel = value;
          if (value.id === requestVariable?.id) {
            v = { ...value, read: true };
          }
          return [...acc, v];
        }, []),
      }),
    })
  );

  const unreadMessages = messages?.filter((m) => !m.read) || [];
  const isUnreadMessage = Boolean(unreadMessages?.length);

  useEffect(() => {
    const title = document.title;
    if (unreadMessages.length > 0) {
      document.title = title.match(/\(\d+\)/)
        ? title.replace(/\(\d+\)/, `(${unreadMessages.length})`)
        : `(${unreadMessages.length}) ${title}`;
    } else {
      document.title = title.replace(/\(\d+\)/, "");
    }
  }, [unreadMessages.length]);

  const onReadMessage = (msg: NotificationModel) => {
    if (!msg.read) {
      updateNotification({
        id: msg.id as number,
        data: { ...msg, read: true },
      });
    }
  };

  const onMarkAllAsRead = async () => {
    setMarkAllAsRead(true);
    await queryClient.cancelQueries(queryKey);
    const originalResponse =
      queryClient.getQueryData<PaginatedNotificationModelList>(queryKey);
    queryClient.setQueryData(queryKey, {
      meta: originalResponse?.meta,
      data: originalResponse?.data.map<NotificationModel>((notification) => ({
        ...notification,
        read: true,
      })),
    });
  };

  return {
    isUnreadMessage,
    isError,
    isLoading,
    messages,
    unreadMessages: unreadMessages || [],
    onReadMessage,
    onMarkAllAsRead,
    isCollapsed: props.isCollapsed,
  };
};
