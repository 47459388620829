/**
 *
 *
 * <EditQuestions />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { QuestionDeleteConfirmationModal } from "../../components/QuestionDeleteConfirmationModal";
import { SettingsCard } from "../../components/SettingsCardV3";
import { QuestionModal } from "../../containers/QuestionModal";
import { Skeleton } from "./Skeleton";
import { useEditQuestions } from "./useEditQuestions";

export const EditQuestions = (props: ReturnType<typeof useEditQuestions>) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="animate-crossfadein">
        <div className="mb-16 px-8 py-12">
          <p className="mb-4 text-sm font-medium leading-6 text-zinc-900">
            {t("translation:settings:ice_breakers:profile_questions")}
          </p>
          <p className="mb-4 text-sm font-normal leading-6 text-zinc-500">
            <Trans
              i18nKey={
                "translation:settings:ice_breakers:profile_questions_descrition"
              }
              components={{
                a: <span className="font-medium underline" />,
              }}
            />
          </p>
          {props.isLoading ? (
            <Skeleton />
          ) : (
            <>
              {props.questions && props.questions?.length > 0 && (
                <ul
                  data-testid="EditQuestions-list"
                  className="w-full space-y-5"
                >
                  {props.questions.map((question) => (
                    <SettingsCard
                      key={question.id}
                      data={{ id: question.id, name: question.question }}
                      onEditClick={() => props.openQuestionModal(question)}
                      onDeleteClick={() => props.handleDelete(question.id)}
                      data-testid={`question-card-${question.id}`}
                    />
                  ))}
                </ul>
              )}
              <Button
                className="mt-4 px-0 sm:px-4"
                type="button"
                variant="contained"
                onClick={() => props.openQuestionModal()}
                size="md"
              >
                <PlusIcon className="h-5 w-5" />
                {t("translation:settings:ice_breakers:create_question")}
              </Button>
            </>
          )}
        </div>
      </div>
      <QuestionModal queryKey={props.queryKey} />
      <QuestionDeleteConfirmationModal
        isOpen={props.isConfirmationModalOpen}
        onCancel={props.onClose}
        onConfirm={props.modalOnConfirmation}
      />
    </>
  );
};
