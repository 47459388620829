/**
 *
 *
 * <People />
 *
 *
 */
import clsx from "clsx";

import { Avatar } from "../../components/AvatarV3";
import { SimpleUserModel } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";

interface Props {
  className?: string;
  people: SimpleUserModel;
  isAdd?: boolean;
  disableActions?: boolean;
  onClick: (people: SimpleUserModel) => void;
}

export const People = (props: Props) => {
  return (
    <div
      className={clsx(
        "grid w-full grid-cols-[auto_1fr_auto] items-center space-x-3 px-4 py-2",
        "border-b border-zinc-200"
      )}
    >
      <Avatar user={props.people} size="md" />
      <div className="truncate">
        <p className="truncate text-sm font-medium leading-5 text-zinc-900">
          {fullName(props.people)}
        </p>
        <p className="truncate text-sm font-normal leading-5 text-zinc-500">
          {props.people.email}
        </p>
      </div>
      <span
        onClick={() => !props.disableActions && props.onClick(props.people)}
        className={clsx(
          "leadning-4 text-xs font-medium underline",
          props.isAdd ? "text-challenge" : "text-zinc-500",
          props.disableActions
            ? "cursor-not-allowed opacity-80"
            : "cursor-pointer"
        )}
      >
        {props.isAdd ? "Add" : "Remove"}
      </span>
    </div>
  );
};
