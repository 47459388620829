/**
 *
 *
 * <BanterSettings />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Integrations } from "../../containers/IntegrationsV3";
import { TimezoneSelect } from "../../containers/TimezoneSelectV3";
import { useBanterSettings } from "./useBanterSettings";

export const BanterSettings = (props: ReturnType<typeof useBanterSettings>) => {
  const { t } = useTranslation();
  return (
    <form
      onSubmit={props.onHandleSubmit}
      className="relative border-b border-b-zinc-300 px-8 py-12"
    >
      <div className="mb-8">
        <div className="mb-1">
          <input
            id="isOn"
            type="checkbox"
            className="h-4 w-4 rounded border-zinc-300 text-zinc-500 focus:ring-zinc-300"
            disabled={props.isLoading}
            {...props.register("isOn")}
          />
          <label
            htmlFor="isOn"
            className="ml-2 text-sm font-medium text-zinc-900"
          >
            {props.isLoading ? "" : props.config?.isOn ? "Enabled" : "Enable"}
          </label>
        </div>
        <p className="ml-6 text-sm font-normal text-zinc-500">
          {t("translation:settings:banter:banter_settings_description_v3")}
        </p>
      </div>

      <div className="mb-8 flex flex-col gap-1 text-sm text-zinc-500">
        <label htmlFor="channel" className="text-sm font-medium text-zinc-900">
          {t("translation:settings:banter:select_channels")}
        </label>
        <div className="relative">
          <Integrations />
        </div>
      </div>

      <div className="flex flex-col gap-y-8">
        <div>
          <label
            htmlFor="day"
            className="text-sm font-medium leading-6 text-zinc-900"
          >
            {t("translation:settings:banter:days")}
          </label>
          <p className="text-sm text-zinc-500">
            {t("translation:settings:banter:days_description_1")}
          </p>
          <p className="mb-2 text-sm italic text-zinc-500 sm:mb-1">
            {t("translation:settings:banter:days_description_2")}
          </p>

          <div className="flex flex-col gap-x-4 gap-y-2 sm:flex-row">
            {props.isLoading ? (
              <div className="h-6 w-full animate-pulse rounded-lg bg-zinc-300" />
            ) : (
              props.days.map((d, i) => (
                <div key={i}>
                  <input
                    id={`day-${i}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-zinc-300 text-zinc-500 focus:ring-zinc-300"
                    {...props.register(`daysOfWeek.${i}`, {
                      value: true,
                    })}
                  />
                  <label
                    htmlFor={`day-${i}`}
                    className="ml-2 text-sm text-zinc-500"
                  >
                    {d}
                  </label>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Time */}
        <div className="flex flex-col gap-y-4 sm:flex-row sm:items-center sm:gap-x-4">
          <div>
            <label
              htmlFor="time"
              className="text-sm font-medium leading-6 text-zinc-900"
            >
              {t("translation:settings:banter:time")}
            </label>

            {props.isLoading ? (
              <div className="h-9 w-32 animate-pulse rounded-lg bg-zinc-300" />
            ) : (
              <input
                id="time"
                className={clsx(
                  "block rounded-lg border-0 px-3 py-1.5 text-zinc-500",
                  "shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400",
                  "focus:ring-2 focus:ring-inset focus:ring-zinc-300 sm:text-sm sm:leading-6"
                )}
                type="time"
                {...props.register("time")}
              />
            )}
          </div>
          <div className="relative">
            <label
              htmlFor="timezone"
              className="text-sm font-medium leading-6 text-zinc-900"
            >
              {t("translation:common:timezone")}
            </label>
            <input
              type="hidden"
              id="timezone"
              {...props.register("timezone")}
            />
            {props.isLoading ? (
              <div className="h-9 w-32 animate-pulse rounded-lg bg-zinc-300" />
            ) : (
              <TimezoneSelect
                setValue={(tz) => {
                  props.setValue("timezone", tz || "", {
                    shouldValidate: true,
                  });
                }}
                defaultValue={props.timezone}
                className="mt-0 w-60 text-zinc-500"
              />
            )}
          </div>
        </div>

        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            {props.isLoading ? (
              <div className="h-4 w-4 animate-pulse rounded border border-zinc-300 bg-zinc-100" />
            ) : (
              <input
                id="useGlobal"
                type="checkbox"
                className="h-4 w-4 rounded border-zinc-300 text-zinc-500 focus:ring-zinc-300"
                {...props.register("useGlobal", { value: true })}
              />
            )}
          </div>
          <div className="ml-3 max-w-prose text-sm leading-6">
            <label htmlFor="useGlobal" className="font-medium text-zinc-900">
              {t("translation:settings:banter:enable_intuition_curated_topics")}
            </label>
            <p className="text-zinc-500">
              {t("translation:settings:banter:provide_steady_stream_topics")}
            </p>
          </div>
        </div>

        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            {props.isLoading ? (
              <div className="h-4 w-4 animate-pulse rounded border border-zinc-300 bg-zinc-100" />
            ) : (
              <input
                id="useAi"
                type="checkbox"
                className="h-4 w-4 rounded border-zinc-300 text-zinc-500 focus:ring-zinc-300"
                {...props.register("useAi", { value: true })}
              />
            )}
          </div>
          <div className="ml-3 max-w-prose text-sm leading-6">
            <label htmlFor="useAi" className="font-medium text-zinc-900">
              {t("translation:settings:banter:enable_ai_generated_topics")}
            </label>
            <p className="text-zinc-500">
              {t("translation:settings:banter:generate_ai_topic")}
            </p>
          </div>
        </div>

        <div>
          <Button
            type="submit"
            variant="contained"
            disabled={props.isLoading}
            size="lg"
          >
            {t("translation:common:save")}
          </Button>
        </div>
      </div>
    </form>
  );
};
