/**
 *
 *
 * <Textarea />
 *
 *
 */
import clsx from "clsx";
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  showLabel?: boolean;
  description?: React.ReactNode;
  value?: string;
  className?: string;
  errorMessage?: string;
  footnote?: string;
  small?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      label,
      className,
      errorMessage,
      footnote,
      small,
      description,
      showLabel,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <div className="flex">
          <label
            htmlFor={id}
            className={clsx(
              "block text-sm font-medium text-zinc-900",
              !showLabel && "sr-only"
            )}
          >
            {label}
          </label>
        </div>

        {description && (
          <p className="text-sm leading-6 tracking-wide text-zinc-500">
            {description}
          </p>
        )}

        <textarea
          className={clsx(
            "rounded-lg shadow-sm",
            "ring-1 ring-inset ring-zinc-300",
            "focus:ring-2 focus:ring-inset focus:ring-zinc-500",
            "sm:text-sm sm:leading-6",
            "placeholder:text-zinc-400",
            "block w-full border-0",
            "mt-1",
            "tracking-wide"
          )}
          ref={ref}
          {...props}
        />

        <p
          className={clsx(
            "mt-1 tracking-wide",
            small ? "text-xs" : "text-sm",
            errorMessage ? "text-red-500" : "text-zinc-500"
          )}
        >
          {errorMessage ? errorMessage : footnote || ""}
        </p>
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export { Textarea };
