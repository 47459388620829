/**
 *
 *
 * <Modal />
 *
 *
 */
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment, useRef } from "react";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface Props {
  isOpen?: boolean;
  showCloseButton?: boolean;
  onClose: () => void;
  className?: string;
  opaque?: boolean;
  children: React.ReactNode;
  disableOutsideClick?: boolean;
}

/**
 * Empty modal container.
 *
 * @param isOpen - Open and close the modal.
 * @param onClose - Close handler.
 * @param children - A Dialog.Panel containing at least one button.
 * @param ref - If you would like an element to have initial focus, pass a ref. See Example.
 *
 * @example
 * const [isOpen, setIsOpen] = useState(false)
 * const onClose = () => setIsOpen(false)
 * const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
 * return (
 *   <Modal isOpen={isOpen} onClose={onClose} ref={cancelButtonRef} ...>
 *     <Dialog.Panel ...>
 *       <Dialog.Title>Some Title</Dialog.Title>
 *       <button ref={cancelButtonRef}>Cancel</button>
 *     <Dialog.Panel>
 *   <Modal>
 * );
 */
const Modal = React.forwardRef<HTMLElement, Props>(
  (
    {
      isOpen,
      showCloseButton = true,
      onClose,
      className = "",
      opaque = false,
      children,
      disableOutsideClick = false,
    },
    initialFocusRef
  ) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => !disableOutsideClick && onClose());

    return (
      <Transition.Root appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          ref={ref}
          className="relative z-40 bg-opacity-25"
          initialFocus={initialFocusRef as React.MutableRefObject<HTMLElement>}
          onClose={disableOutsideClick ? () => {} : onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                "fixed inset-0",
                opaque
                  ? "bg-slate-200 bg-opacity-100"
                  : "bg-slate-500 bg-opacity-75"
              )}
            />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            data-testid="modal-transition-wrapper"
          >
            <div className="flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={clsx("relative rounded-3xl", className)}
                >
                  {children}
                  {showCloseButton && (
                    <div className="absolute right-4 top-5">
                      <button
                        type="button"
                        className={clsx("text-white")}
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon
                          className="h-4 w-4 stroke-[5px] text-slate-400"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
);

Modal.displayName = "Modal";

export { Modal };
