/**
 *
 *
 * <GeneralSettings />
 *
 *
 */
import {
  ArrowPathIcon,
  CameraIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { getTranslation } from "../../utils/translation";
import { CompanyPrefsForm } from "../CompanyPrefsFormV3";
import { useGeneralSettings } from "./useGeneralSettings";

export const GeneralSettings = (
  props: ReturnType<typeof useGeneralSettings>
) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="border-b border-b-zinc-300 px-8 py-6 text-lg text-zinc-500">
        {t("translation:settings:company:general_settings")}
      </div>
      <input
        className="hidden"
        type="file"
        onChange={props.onFileAdded}
        ref={props.inputFileRef}
        accept="image/jpeg,image/png,image/webp,image/tiff,image/tif,image/gif,image/heic,image/heif,application/pdf"
      />
      <span className="sr-only">Company name update</span>
      <div className="animate-crossfadein border-b border-b-zinc-300">
        <form onSubmit={props.onHandleSubmitCompanyName} className="px-8 py-12">
          <div className="mb-4 flex max-w-md flex-1 flex-col">
            <Input
              label={t("translation:settings:company:company_name")}
              type="text"
              id="company_name"
              error={getTranslation(
                t,
                props.form.formState.errors.company_name?.message
              )}
              loading={props.isLoadingCompany}
              {...props.form.register("company_name")}
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:gap-8">
            <Button
              variant="contained"
              size="md"
              type="submit"
              data-testid="submit-button"
            >
              {t("translation:common:update")}
            </Button>
          </div>
        </form>
      </div>

      <span className="sr-only">Company logo update</span>
      <div className="animate-crossfadein border-b border-b-zinc-300">
        <div className="px-8 py-12">
          <div className="mb-2 block text-sm font-medium text-black">
            {t("translation:settings:company:logo")}
          </div>
          {props.isLoadingCompany ? (
            <div className="h-24 animate-pulse rounded bg-slate-300 sm:w-44" />
          ) : (
            <>
              <div className="mb-4">
                {props.isLoadingUploadFile ||
                props.isHeicToJpg ||
                props.isUpdating ? (
                  <div
                    className={clsx(
                      "relative",
                      "sm:w-80",
                      "overflow-hidden rounded-md bg-zinc-300"
                    )}
                  >
                    {props.previewImage && (
                      <img
                        src={
                          props.previewImage
                            ? URL.createObjectURL(props.previewImage)
                            : ""
                        }
                        alt="avatar"
                        className={clsx(
                          "block w-full rounded-md object-cover object-center"
                        )}
                      />
                    )}
                    {(!props.isUpdating || props.isLoadingUploadFile) && (
                      <div
                        className={clsx(
                          "h-full w-full",
                          "full absolute left-0 top-0 rounded-md",
                          "flex flex-col items-center justify-center text-center",
                          "opacity-60",
                          "bg-white"
                        )}
                      >
                        <ArrowPathIcon className="block h-8 w-8 animate-spin" />{" "}
                        {!props.isHeicToJpg &&
                          t("translation:user_guide:uploading_file")}
                      </div>
                    )}
                  </div>
                ) : props.company?.logo?.medium ? (
                  <div
                    className={clsx(
                      "rounded-md sm:w-80",
                      "relative",
                      !props.company?.logo?.medium
                    )}
                  >
                    <img
                      src={props.company?.logo?.medium}
                      alt="avatar"
                      className={clsx(
                        "block w-full rounded-md object-cover object-center"
                      )}
                    />
                  </div>
                ) : (
                  <div
                    className={clsx(
                      "h-40 border border-dashed border-zinc-300 sm:w-80",
                      "relative flex items-center justify-center"
                    )}
                    onClick={props.onClickInput}
                  >
                    <PhotoIcon className="h-8 w-8 text-zinc-400" />
                  </div>
                )}
              </div>
              {props.isHeicToJpg || props.isUpdating ? (
                <div className="flex flex-col space-y-4 sm:flex-row sm:gap-4 sm:space-y-0">
                  <Button
                    type="button"
                    variant="contained"
                    size="md"
                    className="ring-2 ring-zinc-400 ring-offset-2"
                    onClick={props.onSaveLogo}
                    disabled={props.isHeicToJpg || props.isLoadingUploadFile}
                  >
                    {t("translation:common:save")}
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    size="md"
                    onClick={props.onCancel}
                    disabled={props.isHeicToJpg || props.isLoadingUploadFile}
                  >
                    {t("translation:common:cancel")}
                  </Button>
                </div>
              ) : props.company?.logo?.medium ? (
                <div className="flex flex-col space-y-4 sm:flex-row sm:gap-4 sm:space-y-0">
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => props.onClickInput()}
                    size="md"
                  >
                    {t("translation:common:change")}
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={() => props.onDeleteAvatar()}
                    size="md"
                  >
                    {t("translation:settings:company:delete_avatar")}
                  </Button>
                </div>
              ) : (
                <div className="mt-5">
                  <Button
                    type="button"
                    variant="contained"
                    size="md"
                    onClick={props.onClickInput}
                    disabled={props.isLoadingCompany}
                  >
                    <CameraIcon className="h-5 w-5" />
                    {t("translation:common:choose_file")}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* TODO: uncomment when rss parsing is done by the backend. */}
      <CompanyPrefsForm />
    </>
  );
};
