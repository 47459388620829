/**
 *
 *
 * <ChallengeDeleteConfirmationModal />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../ButtonV3";
import { Modal } from "../Modal";

export interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ChallengeDeleteConfirmationModal = (props: Props) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onCancel}
      ref={cancelButtonRef}
      showCloseButton={false}
    >
      <Dialog.Panel className="sm:min-w-md relative w-full transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-[28rem] sm:p-6">
        <div className="flex flex-col items-center">
          <span className="mb-3 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          </span>
          <p className="mb-2 text-base font-semibold leading-7 text-zinc-900">
            {t("translation:challenge:challenge_delete_title")}
          </p>
          <p className="text-center text-sm font-normal leading-5 text-zinc-500">
            {t("translation:challenge:challenge_delete_description_1")}
          </p>
          <p className="mb-b text-center text-sm font-normal leading-5 text-zinc-500">
            {t("translation:challenge:challenge_delete_description_2")}
          </p>
        </div>
        <div className="mt-6 flex flex-row justify-center gap-x-4 sm:justify-start">
          <Button
            type="button"
            variant="outlined"
            size="md"
            onClick={props.onCancel}
            ref={cancelButtonRef}
            className="flex-auto"
          >
            {t("translation:common:cancel")}
          </Button>
          <Button
            variant="challenge"
            size="md"
            onClick={props.onConfirm}
            className="flex-auto"
          >
            {t("translation:challenge:delete_challenge")}
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
