/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  LocationModel,
  LocationModelRequest,
  LocationsListParams,
  LocationsMeListParams,
  LocationsMembersListParams,
  LocationsMembershipsListParams,
  PaginatedLocationModelList,
  PatchedLocationModelRequest,
  PatchedMemberCreateModelRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Location

Everything needed to manage a location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsList = (
  params?: LocationsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedLocationModelList>(
    { url: `/api/v1/locations/`, method: "GET", params, signal },
    options
  );
};

export const getLocationsListQueryKey = (params?: LocationsListParams) => {
  return [`/api/v1/locations/`, ...(params ? [params] : [])] as const;
};

export const getLocationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof locationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLocationsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof locationsList>>> = ({
    signal,
  }) => locationsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationsList>>
>;
export type LocationsListQueryError = ErrorType<unknown>;

export const useLocationsList = <
  TData = Awaited<ReturnType<typeof locationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Location

Everything needed to manage a location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsCreate = (
  locationModelRequest: LocationModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<LocationModel>(
    {
      url: `/api/v1/locations/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: locationModelRequest,
    },
    options
  );
};

export const getLocationsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsCreate>>,
    TError,
    { data: LocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsCreate>>,
  TError,
  { data: LocationModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsCreate>>,
    { data: LocationModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return locationsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsCreate>>
>;
export type LocationsCreateMutationBody = LocationModelRequest;
export type LocationsCreateMutationError = ErrorType<unknown>;

export const useLocationsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsCreate>>,
    TError,
    { data: LocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Location Members

List all the members of the specified location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsMembersList = (
  locationsPk: number,
  params?: LocationsMembersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedLocationModelList>(
    {
      url: `/api/v1/locations/${locationsPk}/members/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getLocationsMembersListQueryKey = (
  locationsPk: number,
  params?: LocationsMembersListParams
) => {
  return [
    `/api/v1/locations/${locationsPk}/members/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getLocationsMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof locationsMembersList>>,
  TError = ErrorType<unknown>,
>(
  locationsPk: number,
  params?: LocationsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLocationsMembersListQueryKey(locationsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationsMembersList>>
  > = ({ signal }) =>
    locationsMembersList(locationsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!locationsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof locationsMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationsMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationsMembersList>>
>;
export type LocationsMembersListQueryError = ErrorType<unknown>;

export const useLocationsMembersList = <
  TData = Awaited<ReturnType<typeof locationsMembersList>>,
  TError = ErrorType<unknown>,
>(
  locationsPk: number,
  params?: LocationsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationsMembersListQueryOptions(
    locationsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Location Members

List all the members of the specified location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsMembersPartialUpdate = (
  locationsPk: number,
  id: number,
  patchedMemberCreateModelRequest: PatchedMemberCreateModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<LocationModel>(
    {
      url: `/api/v1/locations/${locationsPk}/members/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMemberCreateModelRequest,
    },
    options
  );
};

export const getLocationsMembersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsMembersPartialUpdate>>,
    TError,
    { locationsPk: number; id: number; data: PatchedMemberCreateModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsMembersPartialUpdate>>,
  TError,
  { locationsPk: number; id: number; data: PatchedMemberCreateModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsMembersPartialUpdate>>,
    { locationsPk: number; id: number; data: PatchedMemberCreateModelRequest }
  > = (props) => {
    const { locationsPk, id, data } = props ?? {};

    return locationsMembersPartialUpdate(locationsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsMembersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsMembersPartialUpdate>>
>;
export type LocationsMembersPartialUpdateMutationBody =
  PatchedMemberCreateModelRequest;
export type LocationsMembersPartialUpdateMutationError = ErrorType<unknown>;

export const useLocationsMembersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsMembersPartialUpdate>>,
    TError,
    { locationsPk: number; id: number; data: PatchedMemberCreateModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getLocationsMembersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove a membership.
 */
export const locationsMembersDestroy = (
  locationsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/locations/${locationsPk}/members/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getLocationsMembersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsMembersDestroy>>,
    TError,
    { locationsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsMembersDestroy>>,
  TError,
  { locationsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsMembersDestroy>>,
    { locationsPk: number; id: number }
  > = (props) => {
    const { locationsPk, id } = props ?? {};

    return locationsMembersDestroy(locationsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsMembersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsMembersDestroy>>
>;

export type LocationsMembersDestroyMutationError = ErrorType<unknown>;

export const useLocationsMembersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsMembersDestroy>>,
    TError,
    { locationsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsMembersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Location

Everything needed to manage a location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<LocationModel>(
    { url: `/api/v1/locations/${id}/`, method: "GET", signal },
    options
  );
};

export const getLocationsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/locations/${id}/`] as const;
};

export const getLocationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof locationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLocationsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationsRetrieve>>
  > = ({ signal }) => locationsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof locationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationsRetrieve>>
>;
export type LocationsRetrieveQueryError = ErrorType<unknown>;

export const useLocationsRetrieve = <
  TData = Awaited<ReturnType<typeof locationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Location

Everything needed to manage a location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsPartialUpdate = (
  id: number,
  patchedLocationModelRequest: PatchedLocationModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<LocationModel>(
    {
      url: `/api/v1/locations/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedLocationModelRequest,
    },
    options
  );
};

export const getLocationsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsPartialUpdate>>,
    TError,
    { id: number; data: PatchedLocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsPartialUpdate>>,
  TError,
  { id: number; data: PatchedLocationModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsPartialUpdate>>,
    { id: number; data: PatchedLocationModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return locationsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsPartialUpdate>>
>;
export type LocationsPartialUpdateMutationBody = PatchedLocationModelRequest;
export type LocationsPartialUpdateMutationError = ErrorType<unknown>;

export const useLocationsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsPartialUpdate>>,
    TError,
    { id: number; data: PatchedLocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Location

Everything needed to manage a location.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const locationsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/locations/${id}/`, method: "DELETE" },
    options
  );
};

export const getLocationsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return locationsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsDestroy>>
>;

export type LocationsDestroyMutationError = ErrorType<unknown>;

export const useLocationsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add myself as a member if isOpen to new members
 */
export const locationsJoinCreate = (
  id: number,
  locationModelRequest: LocationModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<LocationModel>(
    {
      url: `/api/v1/locations/${id}/join/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: locationModelRequest,
    },
    options
  );
};

export const getLocationsJoinCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsJoinCreate>>,
    TError,
    { id: number; data: LocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsJoinCreate>>,
  TError,
  { id: number; data: LocationModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsJoinCreate>>,
    { id: number; data: LocationModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return locationsJoinCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsJoinCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsJoinCreate>>
>;
export type LocationsJoinCreateMutationBody = LocationModelRequest;
export type LocationsJoinCreateMutationError = ErrorType<unknown>;

export const useLocationsJoinCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsJoinCreate>>,
    TError,
    { id: number; data: LocationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsJoinCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove myself as a member
 */
export const locationsLeaveDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/locations/${id}/leave/`, method: "DELETE" },
    options
  );
};

export const getLocationsLeaveDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof locationsLeaveDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof locationsLeaveDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return locationsLeaveDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LocationsLeaveDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof locationsLeaveDestroy>>
>;

export type LocationsLeaveDestroyMutationError = ErrorType<unknown>;

export const useLocationsLeaveDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof locationsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getLocationsLeaveDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List of Locations that I manage (distinct from my memberships).
 */
export const locationsMeList = (
  params?: LocationsMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedLocationModelList>(
    { url: `/api/v1/locations/me/`, method: "GET", params, signal },
    options
  );
};

export const getLocationsMeListQueryKey = (params?: LocationsMeListParams) => {
  return [`/api/v1/locations/me/`, ...(params ? [params] : [])] as const;
};

export const getLocationsMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof locationsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLocationsMeListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof locationsMeList>>> = ({
    signal,
  }) => locationsMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationsMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationsMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationsMeList>>
>;
export type LocationsMeListQueryError = ErrorType<unknown>;

export const useLocationsMeList = <
  TData = Awaited<ReturnType<typeof locationsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationsMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List of Locations to which I belong (distinct from /me that I manage).
 */
export const locationsMembershipsList = (
  params?: LocationsMembershipsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedLocationModelList>(
    { url: `/api/v1/locations/memberships/`, method: "GET", params, signal },
    options
  );
};

export const getLocationsMembershipsListQueryKey = (
  params?: LocationsMembershipsListParams
) => {
  return [
    `/api/v1/locations/memberships/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getLocationsMembershipsListQueryOptions = <
  TData = Awaited<ReturnType<typeof locationsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLocationsMembershipsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof locationsMembershipsList>>
  > = ({ signal }) => locationsMembershipsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof locationsMembershipsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LocationsMembershipsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof locationsMembershipsList>>
>;
export type LocationsMembershipsListQueryError = ErrorType<unknown>;

export const useLocationsMembershipsList = <
  TData = Awaited<ReturnType<typeof locationsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: LocationsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof locationsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLocationsMembershipsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
