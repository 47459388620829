/**
 *
 *
 * <SkeletonChallengeActivityContainer />
 *
 *
 */
import clsx from "clsx";

import { useLayoutContext } from "../LayoutProvider";

export const SkeletonChallengeActivityContainer = () => {
  const { isMobileMode } = useLayoutContext();
  return (
    <div
      className={clsx(
        "mt-8 flex w-full animate-pulse flex-row",
        isMobileMode && "sm:flex-col"
      )}
    >
      <div
        className={clsx(
          "h-[104px] w-64 flex-shrink-0 rounded-l-lg bg-zinc-100",
          isMobileMode && "sm:h-8 sm:w-full sm:rounded-lg"
        )}
      />
      <div
        className={clsx(
          "h-[456px] w-full flex-1 rounded-r-lg rounded-bl-lg bg-zinc-100",
          isMobileMode && "sm:mt-4 sm:flex-auto sm:rounded-lg"
        )}
      />
    </div>
  );
};
