/**
 *
 *
 * <ChallengeCompleteModal />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { Button } from "../ButtonV3";
import { Modal } from "../Modal";
import { StarBurst } from "./StarBurst";

export type Variant = "success" | "danger" | "warn";

export interface Props {
  isOpen: boolean;
  badge: string;
  onCancel: () => void;
}

export const ChallengeCompleteModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onCancel}
      showCloseButton={false}
    >
      <Dialog.Panel className="h-[30rem] w-full max-w-md overflow-hidden rounded-lg border border-zinc-300 bg-white p-6 sm:w-[30rem]">
        <div className="flex h-full flex-col justify-between">
          <div className="z-10">
            <p className="mb-2 text-center text-base font-semibold text-zinc-900">
              {t("translation:challenge:challenge_complete")}
            </p>
            <div className="flex flex-col text-center text-sm font-normal text-black">
              <p>
                {t("translation:challenge:challenge_complete_description_1")}
              </p>
              <p>
                {t("translation:challenge:challenge_complete_description_2")}
              </p>
            </div>
          </div>
          <div className="relative my-6 flex flex-auto items-center justify-center">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="z-10 h-64 w-64 rounded-full border-4 border-white bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${props.badge})` }}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 1 }}
              className="absolute left-1/2 top-2/3 z-0 -translate-x-1/2 -translate-y-1/2"
            >
              <StarBurst />
            </motion.div>
          </div>
          <div className="z-10 mb-2 flex flex-row justify-center">
            <Button variant="outlined" size="md" onClick={props.onCancel}>
              {t("translation:common:close")}
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
