import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { IconCoffeeCup } from "../../components/IconCoffeeCup";
import { useCoffeeChat } from "./useCoffeeChat";

export const CoffeeChat = (props: ReturnType<typeof useCoffeeChat>) => {
  const { t } = useTranslation();
  const { className, onYes, onCancel } = props;
  return (
    <li className={className}>
      <div className="-z-1 relative p-6">
        <div className="absolute bottom-0 right-0 h-full">
          <IconCoffeeCup />
        </div>
        <div className="z-1 relative">
          <div className="mb-6 text-sm">
            <span className="font-medium text-black">
              {t("translation:coffeechat:hey_name", {
                name: props.me?.firstName,
              })}
            </span>{" "}
            {t("translation:coffeechat:how_about_coffeechat")}
          </div>
          <div className="mb-4 text-sm font-normal">
            {t("translation:coffeechat:share_some_your_interest")}
          </div>
          <div className="flex flex-row items-center justify-center gap-4">
            <Button variant="outlined" size="md" onClick={onCancel}>
              {t("translation:coffeechat:no_thanks")}
            </Button>
            <Button variant="highlight" size="md" onClick={onYes}>
              <span className="w-4">
                <IconCoffeeCup />
              </span>
              {t("translation:coffeechat:yes_please")}
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};
