/**
 *
 *
 * <PinnedPostsMenu />
 *
 *
 */
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ArrowTopRightOnSquareIcon,
  MegaphoneIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Avatar } from "../../components/Avatar";
import { DropdownMenu } from "../../components/DropdownMenuV3";
import { IconThumbtack } from "../../components/IconThumbtack";
import { Post } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";
import { PostContent } from "../PostV3/PostContent";

interface Props {
  items: Post[];
  onClick: (post: Post) => void;
}

export const PinnedPostsMenu = (props: Props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu
      items={props.items.map((item) => ({
        name: `${item.id}`,
        content: () => (
          <div
            key={item.id}
            className="flex w-full max-w-sm flex-row items-start gap-2 bg-transparent px-3 py-2"
          >
            <Avatar key={item?.user?.id} size="md" user={item.user} />
            <div className="flex-grow truncate">
              <div className="mb-2 flex flex-row items-center justify-between">
                <span className="text-sm font-medium text-zinc-900">
                  {fullName(item.user)}
                </span>
                <p className="text-slate-500">
                  {dayjs(item.createdAt).fromNow()}
                </p>
              </div>
              <div>
                {item.action === "started the challenge" ? (
                  <>
                    <p className="mb-1.5 text-left text-sm font-normal leading-5 text-zinc-500">
                      🎉 {t("translation:challenge:challenge_started")} 🎉
                    </p>
                    {item.challenge?.badge && item.challenge?.name && (
                      <div className="relative px-1 py-1">
                        <div className="ml-6 truncate rounded-lg bg-white p-1 shadow">
                          <div
                            className={clsx(
                              "h-8 rounded-lg bg-zinc-100 pl-6 pr-2",
                              "text-sm font-medium leading-5 text-zinc-900",
                              "flex items-center"
                            )}
                          >
                            <p className="truncate">{item.challenge.name}</p>
                          </div>
                        </div>
                        <img
                          src={item.challenge?.badge?.image.medium}
                          className="absolute left-0 top-0 h-12 w-12 rounded-full border-2 border-white"
                        />
                      </div>
                    )}
                  </>
                ) : item.action === "shouted out to" ? (
                  <>
                    {item.content && (
                      <p className="mb-1.5 truncate text-left text-sm font-normal text-zinc-500">
                        <PostContent post={item} className="line-clamp-1" />
                      </p>
                    )}
                    <div className="relative px-1 py-1">
                      <div className="rounded-lg bg-white px-3 py-2.5 shadow">
                        <div className="flex flex-row items-center gap-x-4">
                          <div
                            className={clsx(
                              "h-9 w-9 rounded-full sm:h-10 sm:w-10",
                              "flex flex-shrink-0 items-center justify-center",
                              "text-white",
                              "bg-fuchsia-950 hover:shadow disabled:opacity-60"
                            )}
                          >
                            <MegaphoneIcon className="h-6 w-6" />
                          </div>
                          <div className="whitespace-break-spaces text-left text-sm font-normal text-zinc-900">
                            <Trans
                              i18nKey={
                                item.actionDetail
                                  ? "translation:post:shoutout_to_value"
                                  : "translation:post:shotout_to"
                              }
                              values={{
                                name: fullName(item.recipient),
                                value: item.actionDetail,
                              }}
                              components={{
                                a: (
                                  <span className="font-medium text-fuchsia-950 underline" />
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : !item.url && item.image ? (
                  <div className="flex w-full justify-center">
                    {item.content && (
                      <p className="mb-1.5 truncate text-left text-sm font-normal text-zinc-500">
                        <PostContent post={item} className="line-clamp-1" />
                      </p>
                    )}
                    <img
                      src={item.image.medium}
                      alt="post"
                      className="block max-h-96 rounded-lg"
                    />
                  </div>
                ) : item.url ? (
                  <div>
                    {item.content && (
                      <p className="mb-1.5 truncate text-left text-sm font-normal text-zinc-500">
                        <PostContent post={item} className="line-clamp-1" />
                      </p>
                    )}
                    <div className="relative px-1 py-1">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                        className="block overflow-hidden rounded-lg bg-white shadow"
                      >
                        {item.image && (
                          <div className="flex w-full justify-center">
                            <img
                              src={item.image.medium as string}
                              alt="post"
                              className="block max-h-96"
                            />
                          </div>
                        )}
                        <div
                          className={clsx(
                            "px-3 py-2 text-left text-sm font-normal text-sky-500 hover:text-sky-600"
                          )}
                        >
                          {item.url}{" "}
                          <ArrowTopRightOnSquareIcon className="inline-block h-5 w-5" />
                        </div>
                      </a>
                    </div>
                  </div>
                ) : (
                  <p className="mb-1.5 truncate text-left text-sm font-normal text-zinc-500">
                    <PostContent post={item} className="line-clamp-1" />
                  </p>
                )}
              </div>
            </div>
          </div>
        ),
        onClick: () => props.onClick(item),
      }))}
      menuButtonContent={(open) => (
        <>
          <div className="h-5 w-5 px-1 py-0.5">
            <IconThumbtack
              className={clsx(
                "h-4 w-3 group-hover/pinned-posts-menu-button:text-zinc-400",
                open ? "text-zinc-400" : "text-zinc-300"
              )}
            />
          </div>

          <div
            className={clsx(
              "text-sm font-medium hover:text-zinc-600",
              open ? "text-zinc-600" : "text-zinc-500"
            )}
          >
            {props.items.length} {t("translation:post:pinned_posts")}
          </div>
          <ChevronDownIcon
            className={clsx(
              "h-5 w-5 group-hover/pinned-posts-menu-button:text-zinc-400",
              open ? "text-zinc-400" : "text-zinc-300"
            )}
          />
        </>
      )}
      menuButtonClassNames={(open) =>
        clsx(
          "group/pinned-posts-menu-button flex gap-1 rounded-md p-0.5 hover:bg-zinc-100",
          open && "bg-zinc-100"
        )
      }
      menuClassNames={
        "left-0 top-5 mt-2 origin-top-left divide-y divide-zinc-300 max-h-[80vh] overflow-auto max-w-[22rem]"
      }
    />
  );
};
