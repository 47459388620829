/**
 *
 *
 * <AddActivityModal />
 *
 *
 */
import {
  ChatBubbleLeftEllipsisIcon,
  LinkIcon,
} from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Checkbox } from "../../components/CheckboxV3";
import { Modal } from "../../components/ModalV3";
import { Textarea } from "../../components/TextareaV3";
import { getTranslation } from "../../utils/translation";
import { ProofItem } from "./ProofItem";
import { useAddActivityModal } from "./useAddActivityModal";

export const AddActivityModal = (
  props: ReturnType<typeof useAddActivityModal>
) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onCancel}
        disableOutsideClick
        className={clsx(
          "w-screen max-w-md overflow-hidden rounded-xl bg-white"
        )}
        showCloseButton={false}
      >
        <form className="divide-y divide-zinc-300" onSubmit={props.onCreate}>
          <span className="sr-only">Modal title</span>
          <h2
            className={clsx(
              "py-6 text-center text-base font-semibold leading-7 text-zinc-900"
            )}
          >
            {props.isEdit
              ? t("translation:challenge:edit_activity")
              : t("translation:challenge:add_activity")}
          </h2>
          <span className="sr-only">Activity name</span>
          <div className="p-6">
            <h3 className="flex items-center gap-x-1 text-sm font-medium leading-6 text-zinc-900">
              {t("translation:challenge:add_activity_name_label")}
              <div
                data-tooltip-html={renderToStaticMarkup(
                  <div>
                    <p>
                      {t("translation:challenge:add_activity_name_tooltip_1")}
                    </p>
                    <p className="italic">
                      {t("translation:challenge:add_activity_name_tooltip_2")}
                    </p>
                  </div>
                )}
                data-tooltip-id="tooltip"
              >
                <InformationCircleIcon className="h-6 w-6 text-zinc-400" />
              </div>
            </h3>
            <Textarea
              label=""
              rows={4}
              {...props.form.register("name")}
              placeholder={t(
                "translation:challenge:add_activity_name_placeholder"
              )}
              errorMessage={getTranslation(
                t,
                props.form.formState.errors.name?.message
              )}
            />
          </div>
          <span className="sr-only">Activity description</span>
          <div className="p-6">
            <h3 className="flex items-center gap-x-1 text-sm font-medium leading-6 text-zinc-900">
              {t("translation:challenge:add_activity_description_label")}
              <div
                data-tooltip-content={t(
                  "translation:challenge:add_activity_description_tooltip"
                )}
                data-tooltip-id="tooltip"
              >
                <InformationCircleIcon className="h-6 w-6 text-zinc-400" />
              </div>
            </h3>
            <Textarea
              label=""
              rows={4}
              {...props.form.register("description")}
              placeholder={t(
                "translation:challenge:add_activity_description_placeholder"
              )}
            />
          </div>
          <span className="sr-only">Require proof</span>
          <div className="p-6">
            <div className="mb-6">
              <Checkbox
                id="requireProof"
                {...props.form.register("requireProof")}
              />
              <label
                htmlFor="requireProof"
                className="ml-2 inline-flex items-center gap-x-1 text-sm font-medium leading-6 text-zinc-900"
              >
                {t("translation:challenge:require_proof")}
                <div
                  data-tooltip-content={t(
                    "translation:challenge:require_proof_tooltip"
                  )}
                  data-tooltip-id="tooltip"
                >
                  <InformationCircleIcon className="h-6 w-6 text-zinc-400" />
                </div>
              </label>
            </div>
            <span className="sr-only">Proof Types</span>
            <div className="space-y-4">
              <ProofItem
                id="text"
                {...props.form.register("proofType")}
                className="border-amber-700"
                title={t("translation:challenge:proof_text")}
                circleBackground="bg-amber-700"
                icon={
                  <ChatBubbleLeftEllipsisIcon className="h-16 w-16 text-white" />
                }
                description={t("translation:challenge:proof_text_description")}
                isDisabled={!props.isRequireProof}
              />
              {/* <ProofItem
                id="shoutout"
                {...props.form.register("proofType")}
                className="border-fuchsia-950"
                title={t("translation:challenge:proof_shoutout")}
                circleBackground="bg-fuchsia-950"
                icon={<MegaphoneIcon className="h-16 w-16 text-white" />}
                description={t(
                  "translation:challenge:proof_shoutout_description"
                )}
                isDisabled={!props.isRequireProof}
              />
              <ProofItem
                id="image"
                {...props.form.register("proofType")}
                className="border-green-950"
                title={t("translation:challenge:proof_image")}
                circleBackground="bg-green-950"
                icon={<PhotoIcon className="h-16 w-16 text-white" />}
                description={t("translation:challenge:proof_image_description")}
                isDisabled={!props.isRequireProof}
              /> */}
              <ProofItem
                id="link"
                {...props.form.register("proofType")}
                className="border-blue-950"
                title={t("translation:challenge:proof_link")}
                circleBackground="bg-blue-950"
                icon={<LinkIcon className="h-16 w-16 text-white" />}
                description={t("translation:challenge:proof_link_description")}
                isDisabled={!props.isRequireProof}
              />
            </div>
            <p className="mt-1 text-sm tracking-wide text-red-500">
              {getTranslation(
                t,
                props.form.formState.errors.proofType?.message
              )}
            </p>
          </div>

          <div className="flex flex-col gap-x-4 p-6 sm:flex-row">
            <Button
              type="button"
              variant="outlined"
              size="md"
              onClick={props.onCancel}
              className="block w-full"
              disabled={props.isCreating || props.isUpdating || props.isLoading}
            >
              {t("translation:common:cancel")}
            </Button>
            <Button
              variant="contained"
              size="md"
              className="block w-full"
              type="submit"
              disabled={props.isCreating || props.isUpdating || props.isLoading}
            >
              {props.isEdit
                ? t("translation:common:update")
                : t("translation:common:create")}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};
