/**
 *
 *
 * Username utils
 *
 *
 */

interface User {
  firstName?: string;
  lastName?: string;
  email?: string;
}

/**
 * Return first and last name or email as a fallback.
 */
export const fullName = (user?: User) =>
  !user
    ? ""
    : user.firstName
      ? `${user.firstName} ${user.lastName}`
      : user.email
        ? user.email
        : "";

export const firstName = (user?: User) =>
  !user ? "" : user.firstName ? user.firstName : user.email ? user.email : "";
