/**
 *
 *
 * <Home />
 *
 *
 */
import React from "react";

import { Badges } from "../Badges";
import { HomeChallenge } from "../HomeChallenge";
import { UpcomingAnniversaries } from "../UpcomingAnniversaries";
import { UpcomingBirthdays } from "../UpcomingBirthdays";
import { Values } from "../ValuesV3";
import { useHome } from "./useHome";

export const Home = (props: ReturnType<typeof useHome>) => {
  return (
    <div>
      <HomeChallenge />
      <div className="[&>*:nth-child(even)]:bg-zinc-100">
        <UpcomingBirthdays />
        <UpcomingAnniversaries />
        {props.me && <Badges user={props.me} />}
        <Values />
      </div>
    </div>
  );
};
