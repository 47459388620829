/**
 *
 *
 * <HomeChallenge />
 *
 *
 */
import { TabKey } from "containers/ChallengeTabs";

import { ChallengeList } from "../../components/ChallengeListV3";
import { CreateChallengeButton } from "../../components/CreateChallengeButton";
import { Header } from "./Header";
import { useHomeChallenge } from "./useHomeChallenge";

export const HomeChallenge = (props: ReturnType<typeof useHomeChallenge>) => {
  return (
    <div className="bg-zinc-200">
      <div className="animate-crossfadein space-y-6 py-8">
        {!props.isLoading && !!props.section && (
          <div className="px-4 sm:px-12">
            <Header
              section={props.section}
              name={props.me?.firstName || ""}
              count={props.challenges?.length || 0}
            />
          </div>
        )}
        {!props.isEmpty && (
          <ChallengeList
            challenges={props.challenges}
            isLoadingChallenges={props.isLoading}
            section={props.section as TabKey}
            className="flex w-full flex-wrap justify-center gap-y-10"
            onTemplatePreview={
              props.section === "templates"
                ? props.onTemplatePreview
                : undefined
            }
            onSelect={
              props.section === "templates" ? props.onSelectTemplate : undefined
            }
            isListMode={props.section !== "templates"}
          />
        )}
        {props.isAdmin && (
          <div className="flex flex-row justify-end px-10">
            <CreateChallengeButton />
          </div>
        )}
      </div>
    </div>
  );
};
