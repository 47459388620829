/**
 *
 *
 * <IconCoffeeCup />
 *
 *
 */
import React from "react";

interface Props {
  className?: string;
}

export const IconCoffeeCup = ({ className = "" }: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 0C2.83437 0 2.5 0.334375 2.5 0.75C2.5 1.96563 3.23125 2.60625 3.72187 3.03437L3.75625 3.06562C4.26562 3.50937 4.5 3.74688 4.5 4.25C4.5 4.66563 4.83437 5 5.25 5C5.66563 5 6 4.66563 6 4.25C6 3.03437 5.26875 2.39375 4.77812 1.96563L4.74375 1.93438C4.23438 1.49063 4 1.25313 4 0.75C4 0.334375 3.66563 0 3.25 0ZM1.5 6C0.946875 6 0.5 6.44688 0.5 7V13C0.5 14.6562 1.84375 16 3.5 16H9.5C11.1562 16 12.5 14.6562 12.5 13H13C14.9344 13 16.5 11.4344 16.5 9.5C16.5 7.56563 14.9344 6 13 6H11.5H1.5ZM12.5 8H13C13.8281 8 14.5 8.67188 14.5 9.5C14.5 10.3281 13.8281 11 13 11H12.5V8ZM7.5 0.75C7.5 0.334375 7.16563 0 6.75 0C6.33437 0 6 0.334375 6 0.75C6 1.96563 6.73125 2.60625 7.22188 3.03437L7.25625 3.06562C7.76562 3.50937 8 3.74688 8 4.25C8 4.66563 8.33437 5 8.75 5C9.16563 5 9.5 4.66563 9.5 4.25C9.5 3.03437 8.76875 2.39375 8.27812 1.96563L8.24375 1.93438C7.73438 1.49063 7.5 1.25313 7.5 0.75Z"
        fill="white"
      />
    </svg>
  );
};
