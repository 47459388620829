/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  AvailableIntegrations,
  ConnectionsRequest,
  IntegrationConfirmSyncRequest,
  Integrations,
  IntegrationsChannelsListParams,
  IntegrationsConnectionsListParams,
  IntegrationsListParams,
  IntegrationsPendingListParams,
  PaginatedChannelsList,
  PaginatedIntegrationsList,
  PaginatedPendingIntegrationsList,
  PatchedConnectionsRequest,
  PendingIntegrations,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Integrations

To connect an integration to a team, visit https://acme.teambuilder.getintuition.dev/teams/2 and select Edit.
There, you can select from a dropdown list of {slack, teams, gchat, discord} and press [Connect]. This will
make the frontend call

GET /api/v1/integrations/connect/?provider=slack&team_id=2&return_path=/teams/2/members?settings

The endpoint will respond with a redirection url to start the OAuth process. The FE should redirect the
user to that url. The user visits the endpoint to (re-)authorize the app. Once the user has authorized
the app, the provider will redirect back to the backend, which will grab the team_id and schema_name
from the session and create the integration record, linking this team to the social token. The user
will then be redirected to the frontend to return_path

Once back on the team settings page, the FE can query GET /api/v1/integrations/?team={id} to get a list
of integrations for this team. The FE can then query GET /api/v1/integrations/{id}/channels to get a list
of channels to populate a dropdown. The user selects a channel and clicks [Save]. The FE then calls PATCH
to save the channel information for the team on this integration.

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const integrationsList = (
  params?: IntegrationsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedIntegrationsList>(
    { url: `/api/v1/integrations/`, method: "GET", params, signal },
    options
  );
};

export const getIntegrationsListQueryKey = (
  params?: IntegrationsListParams
) => {
  return [`/api/v1/integrations/`, ...(params ? [params] : [])] as const;
};

export const getIntegrationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntegrationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntegrationsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsList>>
  > = ({ signal }) => integrationsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsList>>
>;
export type IntegrationsListQueryError = ErrorType<unknown>;

export const useIntegrationsList = <
  TData = Awaited<ReturnType<typeof integrationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntegrationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Channels available to the integration
 */
export const integrationsChannelsList = (
  integrationsPk: number,
  params?: IntegrationsChannelsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedChannelsList>(
    {
      url: `/api/v1/integrations/${integrationsPk}/channels/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntegrationsChannelsListQueryKey = (
  integrationsPk: number,
  params?: IntegrationsChannelsListParams
) => {
  return [
    `/api/v1/integrations/${integrationsPk}/channels/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntegrationsChannelsListQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsChannelsList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsChannelsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsChannelsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntegrationsChannelsListQueryKey(integrationsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsChannelsList>>
  > = ({ signal }) =>
    integrationsChannelsList(integrationsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!integrationsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsChannelsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsChannelsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsChannelsList>>
>;
export type IntegrationsChannelsListQueryError = ErrorType<unknown>;

export const useIntegrationsChannelsList = <
  TData = Awaited<ReturnType<typeof integrationsChannelsList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsChannelsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsChannelsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsChannelsListQueryOptions(
    integrationsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Connections for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const integrationsConnectionsList = (
  integrationsPk: number,
  params?: IntegrationsConnectionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedIntegrationsList>(
    {
      url: `/api/v1/integrations/${integrationsPk}/connections/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntegrationsConnectionsListQueryKey = (
  integrationsPk: number,
  params?: IntegrationsConnectionsListParams
) => {
  return [
    `/api/v1/integrations/${integrationsPk}/connections/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntegrationsConnectionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsConnectionsList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsConnectionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsConnectionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntegrationsConnectionsListQueryKey(integrationsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsConnectionsList>>
  > = ({ signal }) =>
    integrationsConnectionsList(integrationsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!integrationsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsConnectionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsConnectionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConnectionsList>>
>;
export type IntegrationsConnectionsListQueryError = ErrorType<unknown>;

export const useIntegrationsConnectionsList = <
  TData = Awaited<ReturnType<typeof integrationsConnectionsList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsConnectionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsConnectionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsConnectionsListQueryOptions(
    integrationsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Connections for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const integrationsConnectionsCreate = (
  integrationsPk: number,
  connectionsRequest: ConnectionsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Integrations>(
    {
      url: `/api/v1/integrations/${integrationsPk}/connections/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: connectionsRequest,
    },
    options
  );
};

export const getIntegrationsConnectionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsCreate>>,
    TError,
    { integrationsPk: number; data: ConnectionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof integrationsConnectionsCreate>>,
  TError,
  { integrationsPk: number; data: ConnectionsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof integrationsConnectionsCreate>>,
    { integrationsPk: number; data: ConnectionsRequest }
  > = (props) => {
    const { integrationsPk, data } = props ?? {};

    return integrationsConnectionsCreate(integrationsPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntegrationsConnectionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConnectionsCreate>>
>;
export type IntegrationsConnectionsCreateMutationBody = ConnectionsRequest;
export type IntegrationsConnectionsCreateMutationError = ErrorType<unknown>;

export const useIntegrationsConnectionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsCreate>>,
    TError,
    { integrationsPk: number; data: ConnectionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntegrationsConnectionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Connections for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const integrationsConnectionsRetrieve = (
  integrationsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Integrations>(
    {
      url: `/api/v1/integrations/${integrationsPk}/connections/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getIntegrationsConnectionsRetrieveQueryKey = (
  integrationsPk: number,
  id: number
) => {
  return [`/api/v1/integrations/${integrationsPk}/connections/${id}/`] as const;
};

export const getIntegrationsConnectionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntegrationsConnectionsRetrieveQueryKey(integrationsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>
  > = ({ signal }) =>
    integrationsConnectionsRetrieve(integrationsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(integrationsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsConnectionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>
>;
export type IntegrationsConnectionsRetrieveQueryError = ErrorType<unknown>;

export const useIntegrationsConnectionsRetrieve = <
  TData = Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsConnectionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsConnectionsRetrieveQueryOptions(
    integrationsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Connections for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const integrationsConnectionsPartialUpdate = (
  integrationsPk: number,
  id: number,
  patchedConnectionsRequest: PatchedConnectionsRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Integrations>(
    {
      url: `/api/v1/integrations/${integrationsPk}/connections/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedConnectionsRequest,
    },
    options
  );
};

export const getIntegrationsConnectionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsPartialUpdate>>,
    TError,
    { integrationsPk: number; id: number; data: PatchedConnectionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof integrationsConnectionsPartialUpdate>>,
  TError,
  { integrationsPk: number; id: number; data: PatchedConnectionsRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof integrationsConnectionsPartialUpdate>>,
    { integrationsPk: number; id: number; data: PatchedConnectionsRequest }
  > = (props) => {
    const { integrationsPk, id, data } = props ?? {};

    return integrationsConnectionsPartialUpdate(
      integrationsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type IntegrationsConnectionsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConnectionsPartialUpdate>>
>;
export type IntegrationsConnectionsPartialUpdateMutationBody =
  PatchedConnectionsRequest;
export type IntegrationsConnectionsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useIntegrationsConnectionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsPartialUpdate>>,
    TError,
    { integrationsPk: number; id: number; data: PatchedConnectionsRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntegrationsConnectionsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Connections for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const integrationsConnectionsDestroy = (
  integrationsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/integrations/${integrationsPk}/connections/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getIntegrationsConnectionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsDestroy>>,
    TError,
    { integrationsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof integrationsConnectionsDestroy>>,
  TError,
  { integrationsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof integrationsConnectionsDestroy>>,
    { integrationsPk: number; id: number }
  > = (props) => {
    const { integrationsPk, id } = props ?? {};

    return integrationsConnectionsDestroy(integrationsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntegrationsConnectionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConnectionsDestroy>>
>;

export type IntegrationsConnectionsDestroyMutationError = ErrorType<unknown>;

export const useIntegrationsConnectionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConnectionsDestroy>>,
    TError,
    { integrationsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntegrationsConnectionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # PendingIntegrations for the Integration

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const integrationsPendingList = (
  integrationsPk: number,
  params?: IntegrationsPendingListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPendingIntegrationsList>(
    {
      url: `/api/v1/integrations/${integrationsPk}/pending/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntegrationsPendingListQueryKey = (
  integrationsPk: number,
  params?: IntegrationsPendingListParams
) => {
  return [
    `/api/v1/integrations/${integrationsPk}/pending/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntegrationsPendingListQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsPendingList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsPendingListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsPendingList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntegrationsPendingListQueryKey(integrationsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsPendingList>>
  > = ({ signal }) =>
    integrationsPendingList(integrationsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!integrationsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsPendingList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsPendingListQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsPendingList>>
>;
export type IntegrationsPendingListQueryError = ErrorType<unknown>;

export const useIntegrationsPendingList = <
  TData = Awaited<ReturnType<typeof integrationsPendingList>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  params?: IntegrationsPendingListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsPendingList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsPendingListQueryOptions(
    integrationsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const integrationsPendingMeRetrieve = (
  integrationsPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PendingIntegrations>(
    {
      url: `/api/v1/integrations/${integrationsPk}/pending/me/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getIntegrationsPendingMeRetrieveQueryKey = (
  integrationsPk: number
) => {
  return [`/api/v1/integrations/${integrationsPk}/pending/me/`] as const;
};

export const getIntegrationsPendingMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntegrationsPendingMeRetrieveQueryKey(integrationsPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>
  > = ({ signal }) =>
    integrationsPendingMeRetrieve(integrationsPk, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!integrationsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsPendingMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>
>;
export type IntegrationsPendingMeRetrieveQueryError = ErrorType<unknown>;

export const useIntegrationsPendingMeRetrieve = <
  TData = Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>,
  TError = ErrorType<unknown>,
>(
  integrationsPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsPendingMeRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsPendingMeRetrieveQueryOptions(
    integrationsPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Integrations

To connect an integration to a team, visit https://acme.teambuilder.getintuition.dev/teams/2 and select Edit.
There, you can select from a dropdown list of {slack, teams, gchat, discord} and press [Connect]. This will
make the frontend call

GET /api/v1/integrations/connect/?provider=slack&team_id=2&return_path=/teams/2/members?settings

The endpoint will respond with a redirection url to start the OAuth process. The FE should redirect the
user to that url. The user visits the endpoint to (re-)authorize the app. Once the user has authorized
the app, the provider will redirect back to the backend, which will grab the team_id and schema_name
from the session and create the integration record, linking this team to the social token. The user
will then be redirected to the frontend to return_path

Once back on the team settings page, the FE can query GET /api/v1/integrations/?team={id} to get a list
of integrations for this team. The FE can then query GET /api/v1/integrations/{id}/channels to get a list
of channels to populate a dropdown. The user selects a channel and clicks [Save]. The FE then calls PATCH
to save the channel information for the team on this integration.

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const integrationsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Integrations>(
    { url: `/api/v1/integrations/${id}/`, method: "GET", signal },
    options
  );
};

export const getIntegrationsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/integrations/${id}/`] as const;
};

export const getIntegrationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntegrationsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsRetrieve>>
  > = ({ signal }) => integrationsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsRetrieve>>
>;
export type IntegrationsRetrieveQueryError = ErrorType<unknown>;

export const useIntegrationsRetrieve = <
  TData = Awaited<ReturnType<typeof integrationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof integrationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Integrations

To connect an integration to a team, visit https://acme.teambuilder.getintuition.dev/teams/2 and select Edit.
There, you can select from a dropdown list of {slack, teams, gchat, discord} and press [Connect]. This will
make the frontend call

GET /api/v1/integrations/connect/?provider=slack&team_id=2&return_path=/teams/2/members?settings

The endpoint will respond with a redirection url to start the OAuth process. The FE should redirect the
user to that url. The user visits the endpoint to (re-)authorize the app. Once the user has authorized
the app, the provider will redirect back to the backend, which will grab the team_id and schema_name
from the session and create the integration record, linking this team to the social token. The user
will then be redirected to the frontend to return_path

Once back on the team settings page, the FE can query GET /api/v1/integrations/?team={id} to get a list
of integrations for this team. The FE can then query GET /api/v1/integrations/{id}/channels to get a list
of channels to populate a dropdown. The user selects a channel and clicks [Save]. The FE then calls PATCH
to save the channel information for the team on this integration.

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const integrationsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/integrations/${id}/`, method: "DELETE" },
    options
  );
};

export const getIntegrationsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof integrationsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof integrationsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return integrationsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntegrationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof integrationsDestroy>>
>;

export type IntegrationsDestroyMutationError = ErrorType<unknown>;

export const useIntegrationsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getIntegrationsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Confirm sync of 100+ users via integration
 */
export const integrationsConfirmSyncCreate = (
  id: number,
  integrationConfirmSyncRequest: IntegrationConfirmSyncRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/integrations/${id}/confirm_sync/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: integrationConfirmSyncRequest,
    },
    options
  );
};

export const getIntegrationsConfirmSyncCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConfirmSyncCreate>>,
    TError,
    { id: number; data: IntegrationConfirmSyncRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof integrationsConfirmSyncCreate>>,
  TError,
  { id: number; data: IntegrationConfirmSyncRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof integrationsConfirmSyncCreate>>,
    { id: number; data: IntegrationConfirmSyncRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return integrationsConfirmSyncCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntegrationsConfirmSyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof integrationsConfirmSyncCreate>>
>;
export type IntegrationsConfirmSyncCreateMutationBody =
  IntegrationConfirmSyncRequest;
export type IntegrationsConfirmSyncCreateMutationError = ErrorType<unknown>;

export const useIntegrationsConfirmSyncCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof integrationsConfirmSyncCreate>>,
    TError,
    { id: number; data: IntegrationConfirmSyncRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntegrationsConfirmSyncCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Start the OAuth process.
 */
export const integrationsAuthorizeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Integrations>(
    { url: `/api/v1/integrations/authorize/`, method: "GET", signal },
    options
  );
};

export const getIntegrationsAuthorizeRetrieveQueryKey = () => {
  return [`/api/v1/integrations/authorize/`] as const;
};

export const getIntegrationsAuthorizeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntegrationsAuthorizeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>
  > = ({ signal }) => integrationsAuthorizeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsAuthorizeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>
>;
export type IntegrationsAuthorizeRetrieveQueryError = ErrorType<unknown>;

export const useIntegrationsAuthorizeRetrieve = <
  TData = Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAuthorizeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsAuthorizeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List of available integrations
 */
export const integrationsAvailableRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AvailableIntegrations>(
    { url: `/api/v1/integrations/available/`, method: "GET", signal },
    options
  );
};

export const getIntegrationsAvailableRetrieveQueryKey = () => {
  return [`/api/v1/integrations/available/`] as const;
};

export const getIntegrationsAvailableRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof integrationsAvailableRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAvailableRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntegrationsAvailableRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof integrationsAvailableRetrieve>>
  > = ({ signal }) => integrationsAvailableRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAvailableRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntegrationsAvailableRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof integrationsAvailableRetrieve>>
>;
export type IntegrationsAvailableRetrieveQueryError = ErrorType<unknown>;

export const useIntegrationsAvailableRetrieve = <
  TData = Awaited<ReturnType<typeof integrationsAvailableRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof integrationsAvailableRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntegrationsAvailableRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
