/**
 *
 *
 * useLanguageList
 *
 *
 */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import {
  getUserguidesRetrieveQueryKey,
  useUserguidesPartialUpdate,
  useUserguidesRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  LanguageEnumRequestLanguage,
  PatchedUserGuidesRequest,
  UserGuides,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  userGuideId?: number;
  className?: string;
}

type LanguageWithKey = {
  key: number;
  value: string;
};

export const useLanguageList = (props: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const [languages, setLanguages] = useState<LanguageWithKey[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const { data: userGuide, isLoading: isLoadingUserGuide } =
    useUserguidesRetrieve(Number(props.userGuideId), {
      query: {
        enabled: Boolean(props.userGuideId),
      },
    });
  const queryKey = useMemo(
    () =>
      props.userGuideId ? getUserguidesRetrieveQueryKey(props.userGuideId) : [],
    [userGuide]
  );

  const { mutate: updateUserGuide } = useUserguidesPartialUpdate(
    optimisticMutationOptions<
      UserGuides,
      UserGuides,
      { id: number; data: PatchedUserGuidesRequest }
    >({
      queryKey,
      optimisticUpdateFn: (UserGuide, requestVariables) => {
        return {
          ...UserGuide,
          ...requestVariables?.data,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:languages_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:languages_update_failed"),
          description: t(
            "translation:toast:languages_update_failed_description"
          ),
          type: "danger",
        });
      },
    })
  );

  useEffect(() => {
    setLanguages(
      userGuide?.languages?.map(({ language }, index) => ({
        key: index,
        value: language,
      })) || []
    );
  }, [userGuide?.languages]);

  const onAddLanguage = () => {
    const maxKey = [...languages].sort((a, b) => b.key - a.key)[0]?.key;
    setLanguages([
      ...languages,
      {
        key: Number.isInteger(maxKey) ? maxKey + 1 : 0,
        value: "",
      },
    ]);
  };

  const onRemoveLanguage = (key: number) => {
    const removeLanguage = languages.find((lang) => lang.key === key);
    if (removeLanguage) {
      if (
        removeLanguage.value &&
        userGuide?.languages?.find(
          (lang) => lang.language === removeLanguage.value
        )
      ) {
        setIsUpdated(true);
      }
      setLanguages(languages.filter((lang) => lang.key !== key));
    }
  };

  const updateLanguage = (key: number, language: string) => {
    const prevLanguage = languages.find((lang) => lang.key === key);
    if (prevLanguage?.value !== language) setIsUpdated(true);
    setLanguages(
      languages.map((lang) =>
        lang.key === key ? { ...lang, value: language } : lang
      )
    );
  };

  const onSave = () => {
    updateUserGuide({
      id: Number(props.userGuideId),
      data: {
        languages: languages
          .filter((lang) => !!lang.value)
          .map((lang) => ({
            language: lang.value as LanguageEnumRequestLanguage,
          })),
      },
    });
  };

  return {
    isUpdated,
    languages,
    isLoading: isLoadingUserGuide,
    className: props.className,
    onAddLanguage,
    onRemoveLanguage,
    updateLanguage,
    onSave,
  };
};
