/**
 *
 *
 * useChallengeDetail
 *
 *
 */
import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { MenuItem } from "../../components/DropdownMenu/DropdownMenu";
import { useChallengesLeaderboardListParams } from "../../hooks/useChallengesLeaderboardList";
import { useCountUp } from "../../hooks/useCountUp";
import { usePostFeedListParams } from "../../hooks/usePostFeedList";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  getChallengesActivitiesListQueryKey,
  getChallengesRetrieveQueryKey,
  useChallengesActivitiesCompletionsCreate,
  useChallengesActivitiesCountList,
  useChallengesActivitiesList,
  useChallengesPartialUpdate,
  useChallengesRetrieve,
  useChallengesTemplatesActivitiesList,
  useChallengesTemplatesRetrieve,
} from "../../services/teambuilder/endpoints/challenges/challenges";
import {
  useSocialPostsCommentsCreate,
  useSocialPostsRetrieve,
} from "../../services/teambuilder/endpoints/social/social";
import {
  Activity,
  ActivityProofType,
  Challenge,
  ChallengesActivitiesListParams,
  Completion,
  CompletionRequest,
  PaginatedActivityList,
  PatchedChallengeRequest,
  PostWrite,
  PostWriteRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { sleep } from "../../utils/sleep";
import { isChallengeResponse } from "../../utils/type-guards";

export const useChallengeDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useRoles();
  const { openToast } = useToast();
  const [searchParams] = useSearchParams();
  const { challengeId: _challengeId } = useParams();
  const challengeId = Number(_challengeId);
  const isTemplate = searchParams.get("template") === "true";
  const [isCreateConfirmationModalOpen, setIsCreateConfirmationModalOpen] =
    useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const feedParams = usePostFeedListParams();
  const challengesLeaderboardListParam =
    useChallengesLeaderboardListParams(challengeId);
  /**
   * Challenge requests
   */
  const {
    data: challenge,
    refetch,
    isInitialLoading: isLoadingChallenge,
  } = useChallengesRetrieve(
    challengeId || 0,
    {},
    {
      query: {
        enabled: Boolean(challengeId) && !isTemplate,
        select: (data) => data,
      },
    }
  );

  useEffect(() => {
    if (challenge && challenge.status === "running" && !challenge.startPost) {
      const timeout = setTimeout(refetch, 2000);
      return () => clearTimeout(timeout);
    }
  }, [challenge]);

  const { data: challengeCountList } = useChallengesActivitiesCountList(
    challenge?.id || 0,
    {
      limit: 999,
    },
    {
      query: {
        enabled:
          !!challenge && !!challenge.startPost && challenge.startPost > 0,
        refetchInterval: 60000,
      },
    }
  );

  const activitiesParams: ChallengesActivitiesListParams = {
    limit: 999,
    ordering: "position",
  };
  const queryKey = getChallengesActivitiesListQueryKey(
    challengeId,
    activitiesParams
  );
  const partialUpdateQueryKey = getChallengesRetrieveQueryKey(challengeId);
  const { data: activities, isInitialLoading: isLoadingActivities } =
    useChallengesActivitiesList(challengeId, activitiesParams, {
      query: {
        enabled: Boolean(challengeId) && !isTemplate,
        select: ({ data }) => data,
      },
    });

  /**
   * Template requests
   */
  const { data: template, isInitialLoading: isLoadingTemplate } =
    useChallengesTemplatesRetrieve(challengeId || 0, {
      query: {
        enabled: Boolean(challengeId) && isTemplate,
        select: (data) => data,
      },
    });

  const {
    data: templateActivities,
    isInitialLoading: isLoadingTemplateActivities,
  } = useChallengesTemplatesActivitiesList(challengeId, activitiesParams, {
    query: {
      enabled: Boolean(challengeId) && isTemplate,
      select: ({ data }) => data,
    },
  });

  const completedActions = useMemo(
    () => activities?.filter((activity) => activity.isCompleted).length,
    [activities]
  );

  const completedActivities = async () => {
    await sleep(1500);
    setShowConfetti(true);
    await sleep(2000);
    setShowCompleteModal(true);
  };

  /**
   * Complete activity request
   */
  const { mutate: createActivityCompletion } =
    useChallengesActivitiesCompletionsCreate(
      optimisticMutationOptions<
        Completion,
        PaginatedActivityList,
        { challengesPk: number; activitiesPk: number; data: CompletionRequest }
      >({
        queryKey,
        optimisticUpdateFn: (context, requestVariables) => {
          const data = context.data.map((activity) =>
            activity.id === requestVariables?.activitiesPk
              ? { ...activity, isCompleted: true }
              : activity
          );
          const isAllActivitiesCompleted = data.every(
            (activity) => activity.isCompleted
          );
          if (isAllActivitiesCompleted) {
            completedActivities();
          }
          return {
            ...context,
            data,
          };
        },
        onSuccess: () => {
          openToast({
            title: t("translation:toast:challenge_update_success"),
          });
          feedParams.refetch();
          challengesLeaderboardListParam.refetch();
        },
        onError: () => {
          openToast({
            title: t("translation:toast:activity_not_completed"),
            type: "danger",
          });
        },
      })
    );

  const { mutate: createSocialPostsComments } = useSocialPostsCommentsCreate(
    optimisticMutationOptions<
      PostWrite,
      PaginatedActivityList,
      { postsPk: number; data: PostWriteRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        const data = context.data.map((activity) =>
          activity.id === requestVariables?.data.actionId
            ? { ...activity, isCompleted: true }
            : activity
        );
        const isAllActivitiesCompleted = data.every(
          (activity) => activity.isCompleted
        );
        if (isAllActivitiesCompleted) {
          completedActivities();
        }
        return {
          ...context,
          data,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:challenge_update_success"),
        });
        feedParams.refetch();
        challengesLeaderboardListParam.refetch();
      },
      onError: () => {
        openToast({
          title: t("translation:toast:activity_not_completed"),
          type: "danger",
        });
      },
    })
  );

  const completeActivity = (
    activityId: number,
    data?: PostWriteRequest,
    proofType?: ActivityProofType
  ) => {
    if (
      proofType === ActivityProofType.not_required ||
      proofType == ActivityProofType.shoutout ||
      proofType == ActivityProofType.image
    ) {
      createActivityCompletion({
        challengesPk: challengeId,
        activitiesPk: activityId,
        data: data?.content
          ? {
              content: data?.content,
            }
          : {},
      });
      return;
    }
    if (!challenge?.startPost) return;
    createSocialPostsComments({
      postsPk: challenge?.startPost,
      data: {
        ...data,
        action: "completed a challenge activity",
        actionId: activityId,
      },
    });
  };

  const { mutate: updateChallenge } = useChallengesPartialUpdate(
    optimisticMutationOptions<
      Challenge,
      PatchedChallengeRequest,
      { id: number; data: PatchedChallengeRequest }
    >({
      queryKey: partialUpdateQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        return {
          ...context,
          isFeatured: requestVariables?.data.isFeatured,
        };
      },
      onSuccess: (
        data: Challenge | undefined,
        requestVariables?: { id: number; data: PatchedChallengeRequest }
      ) => {
        openToast({
          title: t("translation:toast:challenge_update_success"),
        });
        if (requestVariables?.data.isDraft) {
          navigate("/challenges");
        }
      },
      onError: () => {
        openToast({
          title: t("translation:toast:challenge_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const templatePoints = templateActivities
    ?.map((a) => a.maxPoints)
    .reduce((acc, curr) => (acc || 0) + (curr || 0));

  const userPoints = useCountUp(
    activities
      ?.filter((a: Activity) => !!a.isCompleted)
      .reduce((acc, curr) => acc + (curr.points || 0), 0) || 0
  );

  // if template, set to all possible points
  // otherwise, set to completed points
  const points = isTemplate ? templatePoints : userPoints;

  const allowScoring =
    challenge &&
    isChallengeResponse(challenge) &&
    challenge.status === "running";

  const onToggleFeatured = () => {
    updateChallenge({
      id: challengeId,
      data: {
        isFeatured: !challenge?.isFeatured,
      },
    });
  };

  const isRunning =
    challenge &&
    isChallengeResponse(challenge) &&
    challenge.status === "running";
  const isUpcoming =
    challenge &&
    isChallengeResponse(challenge) &&
    challenge.status === "upcoming";
  const isCompleted =
    challenge && isChallengeResponse(challenge) && challenge.status === "ended";

  const onCloseCompleteModal = () => {
    setShowConfetti(false);
    setShowCompleteModal(false);
  };

  /**
   * Delete Challenge (set isDraft to true)
   */
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const onShowDeleteConfirmation = () => {
    setShowDeleteConfirmationModal(true);
  };
  const onCancelDeleteConfirmation = () => {
    setShowDeleteConfirmationModal(false);
  };
  const onDelete = () => {
    updateChallenge({
      id: challengeId,
      data: {
        isDraft: true,
      },
    });
    onCancelDeleteConfirmation();
  };

  /**
   * Dropdown menu items
   */
  const menuItems: MenuItem[] = [
    {
      name: t("translation:common:delete"),
      icon: TrashIcon,
      onClick: onShowDeleteConfirmation,
    },
  ];

  return {
    isTemplate,
    isRunning,
    isUpcoming,
    isCompleted,
    isFeatured: challenge?.isFeatured,
    isChallengeResponse,
    allowScoring,
    challenge: isTemplate ? template : challenge,
    isLoadingChallenge: isLoadingChallenge || isLoadingTemplate,
    activities: isTemplate ? templateActivities : activities,
    isLoadingActivities: isLoadingActivities || isLoadingTemplateActivities,
    completeActivity,
    points,
    isCreateConfirmationModalOpen,
    setIsCreateConfirmationModalOpen,
    isAdmin,
    completedActions: completedActions || 0,
    onToggleFeatured,
    showConfetti,
    showCompleteModal,
    onCloseCompleteModal,
    challengeId,
    menuItems,
    showDeleteConfirmationModal,
    onCancelDeleteConfirmation,
    onDelete,
    completions: challengeCountList?.data.reduce(
      (prev, activity) => prev + (activity.completions || 0),
      0
    ),
    startPostId: challenge?.startPost,
  };
};
