/**
 *
 *
 * useAvatarMenu
 *
 *
 *
 */
import { useMe } from "../../hooks/useMe";
import { useNavItems } from "../../hooks/useNavItems";

interface Props {
  isCollapsed?: boolean;
  onClick?: () => void;
}

export const useAvatarMenu = (props: Props) => {
  const { me, loading } = useMe();
  const navItems = useNavItems();

  return {
    isLoadingMe: loading,
    me,
    navItems,
    isCollapsed: props.isCollapsed,
    onClick: props.onClick,
  };
};
