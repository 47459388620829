/**
 *
 *
 * useDomainForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import {
  useIntuitionCustomersDomainsCreate,
  useIntuitionCustomersDomainsDestroy,
  useIntuitionCustomersDomainsPartialUpdate,
} from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  AllowedDomainModel,
  AllowedDomainModelRequest,
  PaginatedAllowedDomainModelList,
  PatchedAllowedDomainModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const schema = z.object({
  domain: z
    .string()
    .regex(/^([^.]+\.[^.]+)+$/g, "translation:validation:invalid_domain"),
  // domain must include one dot
  // domain must not end with dot
  // domain must not start with dot
});
export type FormData = z.infer<typeof schema>;

interface Props {
  domain: AllowedDomainModel;
  queryKey: readonly unknown[];
}

export const useDomainForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  /**
   * Form
   */
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (props.domain) {
      form.reset({
        domain: props.domain.domain,
      });
    }
  }, [props.domain]);

  const { mutate: createDomain, isLoading: isCreating } =
    useIntuitionCustomersDomainsCreate(
      optimisticMutationOptions<
        AllowedDomainModel,
        PaginatedAllowedDomainModelList,
        {
          data: AllowedDomainModelRequest;
        }
      >({
        queryKey: props.queryKey,
        optimisticUpdateFn: (context, requestData) => ({
          ...context,
          data: [
            ...context.data,
            {
              id: 0,
              createdAt: "",
              updatedAt: "",
              domain: requestData?.data.domain || "",
              company: 0,
            },
          ],
        }),
        onSuccess: () =>
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:company_prefs_update_success"),
          }),
        onError: () =>
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:company_prefs_update_failed"),
            type: "danger",
          }),
      })
    );

  const { mutate: updateDomain } = useIntuitionCustomersDomainsPartialUpdate(
    optimisticMutationOptions<
      AllowedDomainModel,
      PaginatedAllowedDomainModelList,
      {
        id: number;
        data: PatchedAllowedDomainModelRequest;
      }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestData) => ({
        ...context,
        data: [
          ...context.data,
          {
            id: 0,
            createdAt: "",
            updatedAt: "",
            domain: requestData?.data.domain || "",
            company: 0,
          },
        ],
      }),
      onSuccess: () =>
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:company_prefs_update_success"),
        }),
      onError: () =>
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:company_prefs_update_failed"),
          type: "danger",
        }),
    })
  );

  const { mutate: deleteDomain, isLoading: isDeleting } =
    useIntuitionCustomersDomainsDestroy(
      optimisticMutationOptions<
        void,
        PaginatedAllowedDomainModelList,
        {
          id: number;
        }
      >({
        queryKey: props.queryKey,
        optimisticUpdateFn: (context, requestData) => ({
          ...context,
          data: context.data.filter((domain) => domain.id !== requestData?.id),
        }),
        onSuccess: () =>
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:domain_delete_success"),
          }),

        onError: () =>
          form.control.setError("domain", {
            message: t("translation:toast:domain_delete_failed"),
          }),
      })
    );

  /**
   * Create new domain or update existing domain.
   */
  const onSaveDomain: SubmitHandler<FormData> = (formData: FormData) => {
    if (!props.domain.id) throw new Error("Domain ID is missing");

    // Fractional IDs are generated by Math.random() and have not been saved yet.
    if (props.domain.id < 1) {
      createDomain({
        data: {
          domain: formData.domain,
        },
      });
    } else {
      updateDomain({
        id: props.domain.id,
        data: {
          domain: formData.domain,
        },
      });
    }
  };

  /**
   * Delete saved domains or remove unsaved domains from the query cache.
   */
  const onDeleteDomain = async () => {
    if (!props.domain.id) throw new Error("Domain ID is missing");

    // IDs > 1 are saved domains.
    // Fractional IDs were generated with Math.random() and haven't been saved yet.
    if (props.domain.id > 1) {
      deleteDomain({
        id: props.domain.id,
      });
    } else {
      // Remove the unsaved domain from the cache.
      queryClient.setQueryData(
        props.queryKey,
        (oldData: PaginatedAllowedDomainModelList | undefined) => {
          if (!oldData) return;
          return {
            meta: oldData.meta,
            data: oldData.data.filter(
              (domain) => domain.id !== props.domain.id
            ),
          };
        }
      );
    }
  };

  const onHandleSubmit = form.handleSubmit(onSaveDomain);

  return {
    form,
    isLoading: false,
    onHandleSubmit,
    onDeleteDomain,
    isCreating,
    isDeleting,
    isSaving: false,
  };
};
