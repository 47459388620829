/**
 *
 *
 * <BanterToolbox />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ModalConfirmation } from "../../components/ModalConfirmationV3";
import { BanterTopicForm } from "../BanterTopicFormV3";
import { SkeletonTopicListItem } from "./SkeletonTopicListItem";
import { TopicListItem } from "./TopicListItem";
import { useBanterToolbox } from "./useBanterToolbox";

export const BanterToolbox = ({
  isLoadingTopics,
  topics,
  isTopicFormOpen,
  openTopicForm,
  closeTopicForm,
  selectedTopic,
  topicsListQueryKey,
  team,
  isDeleteConfirmationModalOpen,
  setIsDeleteConfirmationModalOpen,
  destroyTopic,
  topic,
  setTopic,
}: ReturnType<typeof useBanterToolbox>) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-8 py-12">
        <p className="mb-1 text-sm font-medium text-zinc-900">
          {t("translation:settings:banter:custom_topics")}
        </p>
        <p className="mb-3 text-sm font-normal text-zinc-500">
          {t("translation:settings:banter:custom_topics_description")}
        </p>
        <ul className="space-y-2 py-4 sm:space-y-4">
          {isLoadingTopics ? (
            <SkeletonTopicListItem />
          ) : (
            topics?.data &&
            topics?.data?.length > 0 &&
            topics.data.map((t, i) => (
              <TopicListItem
                key={i}
                topic={t}
                onEditClick={() => openTopicForm(t)}
                onDeleteClick={() => {
                  setTopic(t);
                  setIsDeleteConfirmationModalOpen(true);
                }}
              />
            ))
          )}
        </ul>
        <div>
          <Button
            type="submit"
            variant="contained"
            size="lg"
            onClick={() => openTopicForm()}
          >
            <PlusIcon className="h-5 w-5" />
            {t("translation:settings:banter:add_topic")}
          </Button>
        </div>
        {team && (
          <BanterTopicForm
            topic={selectedTopic}
            teamId={team.id as number}
            isOpen={isTopicFormOpen}
            onClose={closeTopicForm}
            topicsListQueryKey={topicsListQueryKey}
          />
        )}
      </div>
      {topic && (
        <ModalConfirmation
          isOpen={isDeleteConfirmationModalOpen}
          title={t("translation:settings:banter:delete_topic")}
          description={t(
            "translation:settings:banter:delete_topic_description"
          )}
          cancelButtonTitle={t("translation:common:cancel")}
          confirmButtonTitle={t("translation:common:delete")}
          onCancel={() => setIsDeleteConfirmationModalOpen(false)}
          onConfirm={() => destroyTopic({ id: topic?.id as number })}
        />
      )}
    </>
  );
};
