/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  AllowedDomainModel,
  AllowedDomainModelRequest,
  CompanyIconUploadRequest,
  CompanyLogoUploadRequest,
  CompanyModel,
  CompanyModelRequest,
  CompanyPreferenceModel,
  CompanyPreferenceModelRequest,
  CompanyRegistrationStatus,
  IntuitionCustomersCompaniesEligibilityRetrieveParams,
  IntuitionCustomersCompaniesListParams,
  IntuitionCustomersCompaniesRegistrationStatusRetrieveParams,
  IntuitionCustomersDomainsListParams,
  IntuitionLanguagesListParams,
  IntuitionPrefsCompanyListParams,
  IntuitionUsersListParams,
  IntuitionUsersPrefsListParams,
  IntuitionUsersRolesListParams,
  PaginatedAllowedDomainModelList,
  PaginatedCompanyModelList,
  PaginatedCompanyPreferenceModelList,
  PaginatedLanguageChoicesList,
  PaginatedRoleList,
  PaginatedUserPreferencesList,
  PaginatedUserResponseList,
  PatchedAllowedDomainModelRequest,
  PatchedCompanyModelRequest,
  PatchedCompanyPreferenceModelRequest,
  PatchedUserPreferencesRequest,
  PatchedUserRequest,
  TenantEligibilityResponse,
  TenantJoinRequestRequest,
  TenantJoinedResponse,
  TimezonesListResponse,
  User,
  UserAvatarUploadRequest,
  UserOtpToken,
  UserOtpTokenRequest,
  UserPreferences,
  UserRequest,
  UserToken,
  UserTokenRequest,
  _UserResponse,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const intuitionCustomersCompaniesList = (
  params?: IntuitionCustomersCompaniesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCompanyModelList>(
    {
      url: `/api/v1/intuition/customers/companies/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesListQueryKey = (
  params?: IntuitionCustomersCompaniesListParams
) => {
  return [
    `/api/v1/intuition/customers/companies/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionCustomersCompaniesListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersCompaniesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntuitionCustomersCompaniesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>
  > = ({ signal }) =>
    intuitionCustomersCompaniesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersCompaniesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>
>;
export type IntuitionCustomersCompaniesListQueryError = ErrorType<unknown>;

export const useIntuitionCustomersCompaniesList = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersCompaniesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersCompaniesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionCustomersCompaniesListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersCompaniesCreate = (
  companyModelRequest: CompanyModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyModel>(
    {
      url: `/api/v1/intuition/customers/companies/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyModelRequest,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesCreate>>,
    TError,
    { data: CompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesCreate>>,
  TError,
  { data: CompanyModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesCreate>>,
    { data: CompanyModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionCustomersCompaniesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersCompaniesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesCreate>>
>;
export type IntuitionCustomersCompaniesCreateMutationBody = CompanyModelRequest;
export type IntuitionCustomersCompaniesCreateMutationError = ErrorType<unknown>;

export const useIntuitionCustomersCompaniesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesCreate>>,
    TError,
    { data: CompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersCompaniesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersCompaniesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompanyModel>(
    {
      url: `/api/v1/intuition/customers/companies/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/intuition/customers/companies/${id}/`] as const;
};

export const getIntuitionCustomersCompaniesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntuitionCustomersCompaniesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>
  > = ({ signal }) =>
    intuitionCustomersCompaniesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersCompaniesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>
>;
export type IntuitionCustomersCompaniesRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionCustomersCompaniesRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersCompaniesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionCustomersCompaniesRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersCompaniesPartialUpdate = (
  id: number,
  patchedCompanyModelRequest: PatchedCompanyModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyModel>(
    {
      url: `/api/v1/intuition/customers/companies/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCompanyModelRequest,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesPartialUpdate>>,
  TError,
  { id: number; data: PatchedCompanyModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesPartialUpdate>>,
    { id: number; data: PatchedCompanyModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionCustomersCompaniesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersCompaniesPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesPartialUpdate>>
  >;
export type IntuitionCustomersCompaniesPartialUpdateMutationBody =
  PatchedCompanyModelRequest;
export type IntuitionCustomersCompaniesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersCompaniesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersCompaniesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/intuition/customers/companies/${id}/`, method: "DELETE" },
    options
  );
};

export const getIntuitionCustomersCompaniesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return intuitionCustomersCompaniesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersCompaniesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesDestroy>>
>;

export type IntuitionCustomersCompaniesDestroyMutationError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersCompaniesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersCompaniesAsyncCreate = (
  companyModelRequest: CompanyModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyRegistrationStatus>(
    {
      url: `/api/v1/intuition/customers/companies/async/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyModelRequest,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesAsyncCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesAsyncCreate>>,
    TError,
    { data: CompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesAsyncCreate>>,
  TError,
  { data: CompanyModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesAsyncCreate>>,
    { data: CompanyModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionCustomersCompaniesAsyncCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersCompaniesAsyncCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesAsyncCreate>>
>;
export type IntuitionCustomersCompaniesAsyncCreateMutationBody =
  CompanyModelRequest;
export type IntuitionCustomersCompaniesAsyncCreateMutationError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesAsyncCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesAsyncCreate>>,
    TError,
    { data: CompanyModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersCompaniesAsyncCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersCompaniesEligibilityRetrieve = (
  params?: IntuitionCustomersCompaniesEligibilityRetrieveParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantEligibilityResponse>(
    {
      url: `/api/v1/intuition/customers/companies/eligibility/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesEligibilityRetrieveQueryKey = (
  params?: IntuitionCustomersCompaniesEligibilityRetrieveParams
) => {
  return [
    `/api/v1/intuition/customers/companies/eligibility/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionCustomersCompaniesEligibilityRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersCompaniesEligibilityRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntuitionCustomersCompaniesEligibilityRetrieveQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>>
  > = ({ signal }) =>
    intuitionCustomersCompaniesEligibilityRetrieve(
      params,
      requestOptions,
      signal
    );

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersCompaniesEligibilityRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>>
  >;
export type IntuitionCustomersCompaniesEligibilityRetrieveQueryError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesEligibilityRetrieve = <
  TData = Awaited<
    ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>
  >,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersCompaniesEligibilityRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof intuitionCustomersCompaniesEligibilityRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getIntuitionCustomersCompaniesEligibilityRetrieveQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersCompaniesJoinCreate = (
  tenantJoinRequestRequest: TenantJoinRequestRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TenantJoinedResponse>(
    {
      url: `/api/v1/intuition/customers/companies/join/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: tenantJoinRequestRequest,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesJoinCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesJoinCreate>>,
    TError,
    { data: TenantJoinRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesJoinCreate>>,
  TError,
  { data: TenantJoinRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesJoinCreate>>,
    { data: TenantJoinRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionCustomersCompaniesJoinCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersCompaniesJoinCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesJoinCreate>>
>;
export type IntuitionCustomersCompaniesJoinCreateMutationBody =
  TenantJoinRequestRequest;
export type IntuitionCustomersCompaniesJoinCreateMutationError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesJoinCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesJoinCreate>>,
    TError,
    { data: TenantJoinRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersCompaniesJoinCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const intuitionCustomersCompaniesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompanyModel>(
    { url: `/api/v1/intuition/customers/companies/me/`, method: "GET", signal },
    options
  );
};

export const getIntuitionCustomersCompaniesMeRetrieveQueryKey = () => {
  return [`/api/v1/intuition/customers/companies/me/`] as const;
};

export const getIntuitionCustomersCompaniesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getIntuitionCustomersCompaniesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>
  > = ({ signal }) =>
    intuitionCustomersCompaniesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersCompaniesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>
>;
export type IntuitionCustomersCompaniesMeRetrieveQueryError =
  ErrorType<unknown>;

export const useIntuitionCustomersCompaniesMeRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersCompaniesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getIntuitionCustomersCompaniesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersCompaniesRegistrationStatusRetrieve = (
  params: IntuitionCustomersCompaniesRegistrationStatusRetrieveParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompanyModel>(
    {
      url: `/api/v1/intuition/customers/companies/registration/status/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntuitionCustomersCompaniesRegistrationStatusRetrieveQueryKey =
  (params: IntuitionCustomersCompaniesRegistrationStatusRetrieveParams) => {
    return [
      `/api/v1/intuition/customers/companies/registration/status/`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getIntuitionCustomersCompaniesRegistrationStatusRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
    >,
    TError = ErrorType<void>,
  >(
    params: IntuitionCustomersCompaniesRegistrationStatusRetrieveParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof intuitionCustomersCompaniesRegistrationStatusRetrieve
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof axiosInstance>;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getIntuitionCustomersCompaniesRegistrationStatusRetrieveQueryKey(params);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
      >
    > = ({ signal }) =>
      intuitionCustomersCompaniesRegistrationStatusRetrieve(
        params,
        requestOptions,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type IntuitionCustomersCompaniesRegistrationStatusRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
    >
  >;
export type IntuitionCustomersCompaniesRegistrationStatusRetrieveQueryError =
  ErrorType<void>;

export const useIntuitionCustomersCompaniesRegistrationStatusRetrieve = <
  TData = Awaited<
    ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
  >,
  TError = ErrorType<void>,
>(
  params: IntuitionCustomersCompaniesRegistrationStatusRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof intuitionCustomersCompaniesRegistrationStatusRetrieve>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getIntuitionCustomersCompaniesRegistrationStatusRetrieveQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersDomainsList = (
  params?: IntuitionCustomersDomainsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedAllowedDomainModelList>(
    {
      url: `/api/v1/intuition/customers/domains/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getIntuitionCustomersDomainsListQueryKey = (
  params?: IntuitionCustomersDomainsListParams
) => {
  return [
    `/api/v1/intuition/customers/domains/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionCustomersDomainsListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersDomainsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersDomainsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersDomainsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionCustomersDomainsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersDomainsList>>
  > = ({ signal }) =>
    intuitionCustomersDomainsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersDomainsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersDomainsList>>
>;
export type IntuitionCustomersDomainsListQueryError = ErrorType<unknown>;

export const useIntuitionCustomersDomainsList = <
  TData = Awaited<ReturnType<typeof intuitionCustomersDomainsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionCustomersDomainsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersDomainsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionCustomersDomainsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersDomainsCreate = (
  allowedDomainModelRequest: AllowedDomainModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AllowedDomainModel>(
    {
      url: `/api/v1/intuition/customers/domains/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: allowedDomainModelRequest,
    },
    options
  );
};

export const getIntuitionCustomersDomainsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsCreate>>,
    TError,
    { data: AllowedDomainModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersDomainsCreate>>,
  TError,
  { data: AllowedDomainModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersDomainsCreate>>,
    { data: AllowedDomainModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionCustomersDomainsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersDomainsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersDomainsCreate>>
>;
export type IntuitionCustomersDomainsCreateMutationBody =
  AllowedDomainModelRequest;
export type IntuitionCustomersDomainsCreateMutationError = ErrorType<unknown>;

export const useIntuitionCustomersDomainsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsCreate>>,
    TError,
    { data: AllowedDomainModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersDomainsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersDomainsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AllowedDomainModel>(
    {
      url: `/api/v1/intuition/customers/domains/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getIntuitionCustomersDomainsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/intuition/customers/domains/${id}/`] as const;
};

export const getIntuitionCustomersDomainsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionCustomersDomainsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>
  > = ({ signal }) =>
    intuitionCustomersDomainsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersDomainsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>
>;
export type IntuitionCustomersDomainsRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionCustomersDomainsRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionCustomersDomainsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionCustomersDomainsRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionCustomersDomainsPartialUpdate = (
  id: number,
  patchedAllowedDomainModelRequest: PatchedAllowedDomainModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<AllowedDomainModel>(
    {
      url: `/api/v1/intuition/customers/domains/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedAllowedDomainModelRequest,
    },
    options
  );
};

export const getIntuitionCustomersDomainsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAllowedDomainModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersDomainsPartialUpdate>>,
  TError,
  { id: number; data: PatchedAllowedDomainModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersDomainsPartialUpdate>>,
    { id: number; data: PatchedAllowedDomainModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionCustomersDomainsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersDomainsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersDomainsPartialUpdate>>
>;
export type IntuitionCustomersDomainsPartialUpdateMutationBody =
  PatchedAllowedDomainModelRequest;
export type IntuitionCustomersDomainsPartialUpdateMutationError =
  ErrorType<unknown>;

export const useIntuitionCustomersDomainsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsPartialUpdate>>,
    TError,
    { id: number; data: PatchedAllowedDomainModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersDomainsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersDomainsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/intuition/customers/domains/${id}/`, method: "DELETE" },
    options
  );
};

export const getIntuitionCustomersDomainsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionCustomersDomainsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionCustomersDomainsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return intuitionCustomersDomainsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionCustomersDomainsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersDomainsDestroy>>
>;

export type IntuitionCustomersDomainsDestroyMutationError = ErrorType<unknown>;

export const useIntuitionCustomersDomainsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionCustomersDomainsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionCustomersDomainsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionCustomersTimezonesRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TimezonesListResponse>(
    { url: `/api/v1/intuition/customers/timezones/`, method: "GET", signal },
    options
  );
};

export const getIntuitionCustomersTimezonesRetrieveQueryKey = () => {
  return [`/api/v1/intuition/customers/timezones/`] as const;
};

export const getIntuitionCustomersTimezonesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionCustomersTimezonesRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>
  > = ({ signal }) =>
    intuitionCustomersTimezonesRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionCustomersTimezonesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>
>;
export type IntuitionCustomersTimezonesRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionCustomersTimezonesRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionCustomersTimezonesRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getIntuitionCustomersTimezonesRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionLanguagesList = (
  params?: IntuitionLanguagesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedLanguageChoicesList>(
    { url: `/api/v1/intuition/languages/`, method: "GET", params, signal },
    options
  );
};

export const getIntuitionLanguagesListQueryKey = (
  params?: IntuitionLanguagesListParams
) => {
  return [`/api/v1/intuition/languages/`, ...(params ? [params] : [])] as const;
};

export const getIntuitionLanguagesListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionLanguagesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionLanguagesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionLanguagesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionLanguagesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionLanguagesList>>
  > = ({ signal }) => intuitionLanguagesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionLanguagesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionLanguagesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionLanguagesList>>
>;
export type IntuitionLanguagesListQueryError = ErrorType<unknown>;

export const useIntuitionLanguagesList = <
  TData = Awaited<ReturnType<typeof intuitionLanguagesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionLanguagesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionLanguagesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionLanguagesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Models in shared apps should return tenant-specific results
 */
export const intuitionPrefsCompanyList = (
  params?: IntuitionPrefsCompanyListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCompanyPreferenceModelList>(
    { url: `/api/v1/intuition/prefs/company/`, method: "GET", params, signal },
    options
  );
};

export const getIntuitionPrefsCompanyListQueryKey = (
  params?: IntuitionPrefsCompanyListParams
) => {
  return [
    `/api/v1/intuition/prefs/company/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionPrefsCompanyListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionPrefsCompanyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionPrefsCompanyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionPrefsCompanyListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyList>>
  > = ({ signal }) => intuitionPrefsCompanyList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionPrefsCompanyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyList>>
>;
export type IntuitionPrefsCompanyListQueryError = ErrorType<unknown>;

export const useIntuitionPrefsCompanyList = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionPrefsCompanyListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionPrefsCompanyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionPrefsCompanyListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Models in shared apps should return tenant-specific results
 */
export const intuitionPrefsCompanyCreate = (
  companyPreferenceModelRequest: CompanyPreferenceModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyPreferenceModel>(
    {
      url: `/api/v1/intuition/prefs/company/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: companyPreferenceModelRequest,
    },
    options
  );
};

export const getIntuitionPrefsCompanyCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyCreate>>,
    TError,
    { data: CompanyPreferenceModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyCreate>>,
  TError,
  { data: CompanyPreferenceModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyCreate>>,
    { data: CompanyPreferenceModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionPrefsCompanyCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyCreate>>
>;
export type IntuitionPrefsCompanyCreateMutationBody =
  CompanyPreferenceModelRequest;
export type IntuitionPrefsCompanyCreateMutationError = ErrorType<unknown>;

export const useIntuitionPrefsCompanyCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyCreate>>,
    TError,
    { data: CompanyPreferenceModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Models in shared apps should return tenant-specific results
 */
export const intuitionPrefsCompanyRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompanyPreferenceModel>(
    { url: `/api/v1/intuition/prefs/company/${id}/`, method: "GET", signal },
    options
  );
};

export const getIntuitionPrefsCompanyRetrieveQueryKey = (id: number) => {
  return [`/api/v1/intuition/prefs/company/${id}/`] as const;
};

export const getIntuitionPrefsCompanyRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionPrefsCompanyRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>
  > = ({ signal }) => intuitionPrefsCompanyRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionPrefsCompanyRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>
>;
export type IntuitionPrefsCompanyRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionPrefsCompanyRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionPrefsCompanyRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionPrefsCompanyRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Models in shared apps should return tenant-specific results
 */
export const intuitionPrefsCompanyPartialUpdate = (
  id: number,
  patchedCompanyPreferenceModelRequest: PatchedCompanyPreferenceModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyPreferenceModel>(
    {
      url: `/api/v1/intuition/prefs/company/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCompanyPreferenceModelRequest,
    },
    options
  );
};

export const getIntuitionPrefsCompanyPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyPartialUpdate>>,
    TError,
    { id: number; data: PatchedCompanyPreferenceModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyPartialUpdate>>,
  TError,
  { id: number; data: PatchedCompanyPreferenceModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyPartialUpdate>>,
    { id: number; data: PatchedCompanyPreferenceModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionPrefsCompanyPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyPartialUpdate>>
>;
export type IntuitionPrefsCompanyPartialUpdateMutationBody =
  PatchedCompanyPreferenceModelRequest;
export type IntuitionPrefsCompanyPartialUpdateMutationError =
  ErrorType<unknown>;

export const useIntuitionPrefsCompanyPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyPartialUpdate>>,
    TError,
    { id: number; data: PatchedCompanyPreferenceModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Models in shared apps should return tenant-specific results
 */
export const intuitionPrefsCompanyDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/intuition/prefs/company/${id}/`, method: "DELETE" },
    options
  );
};

export const getIntuitionPrefsCompanyDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return intuitionPrefsCompanyDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyDestroy>>
>;

export type IntuitionPrefsCompanyDestroyMutationError = ErrorType<unknown>;

export const useIntuitionPrefsCompanyDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const intuitionPrefsCompanyMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CompanyPreferenceModel>(
    { url: `/api/v1/intuition/prefs/company/me/`, method: "GET", signal },
    options
  );
};

export const getIntuitionPrefsCompanyMeRetrieveQueryKey = () => {
  return [`/api/v1/intuition/prefs/company/me/`] as const;
};

export const getIntuitionPrefsCompanyMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionPrefsCompanyMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>
  > = ({ signal }) => intuitionPrefsCompanyMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionPrefsCompanyMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>
>;
export type IntuitionPrefsCompanyMeRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionPrefsCompanyMeRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionPrefsCompanyMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Upload a company icon image. Can take a PNG or JPEG.

## Permissions:
Has role **IT** or **HR**.
 * @summary Company Icon Upload
 */
export const intuitionPrefsCompanyMeIconCreate = (
  companyIconUploadRequest: CompanyIconUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  formData.append("file", companyIconUploadRequest.file);

  return axiosInstance<CompanyPreferenceModel | void>(
    {
      url: `/api/v1/intuition/prefs/company/me/icon/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getIntuitionPrefsCompanyMeIconCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconCreate>>,
    TError,
    { data: CompanyIconUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconCreate>>,
  TError,
  { data: CompanyIconUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconCreate>>,
    { data: CompanyIconUploadRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionPrefsCompanyMeIconCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyMeIconCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconCreate>>
>;
export type IntuitionPrefsCompanyMeIconCreateMutationBody =
  CompanyIconUploadRequest;
export type IntuitionPrefsCompanyMeIconCreateMutationError = ErrorType<unknown>;

/**
 * @summary Company Icon Upload
 */
export const useIntuitionPrefsCompanyMeIconCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconCreate>>,
    TError,
    { data: CompanyIconUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyMeIconCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload a company icon image. Can take a PNG or JPEG.

## Permissions:
Has role **IT** or **HR**.
 * @summary Company Icon Upload
 */
export const intuitionPrefsCompanyMeIconDestroy = (
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyPreferenceModel | void>(
    { url: `/api/v1/intuition/prefs/company/me/icon/`, method: "DELETE" },
    options
  );
};

export const getIntuitionPrefsCompanyMeIconDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconDestroy>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconDestroy>>,
    void
  > = () => {
    return intuitionPrefsCompanyMeIconDestroy(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyMeIconDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconDestroy>>
>;

export type IntuitionPrefsCompanyMeIconDestroyMutationError =
  ErrorType<unknown>;

/**
 * @summary Company Icon Upload
 */
export const useIntuitionPrefsCompanyMeIconDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeIconDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyMeIconDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload a company logo image. Can take a PNG or JPEG.

## Permissions:
Has role **IT** or **HR**.
 * @summary Company Logo Upload
 */
export const intuitionPrefsCompanyMeLogoCreate = (
  companyLogoUploadRequest: CompanyLogoUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  formData.append("file", companyLogoUploadRequest.file);

  return axiosInstance<CompanyPreferenceModel | void>(
    {
      url: `/api/v1/intuition/prefs/company/me/logo/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getIntuitionPrefsCompanyMeLogoCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoCreate>>,
    TError,
    { data: CompanyLogoUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoCreate>>,
  TError,
  { data: CompanyLogoUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoCreate>>,
    { data: CompanyLogoUploadRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionPrefsCompanyMeLogoCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyMeLogoCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoCreate>>
>;
export type IntuitionPrefsCompanyMeLogoCreateMutationBody =
  CompanyLogoUploadRequest;
export type IntuitionPrefsCompanyMeLogoCreateMutationError = ErrorType<unknown>;

/**
 * @summary Company Logo Upload
 */
export const useIntuitionPrefsCompanyMeLogoCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoCreate>>,
    TError,
    { data: CompanyLogoUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyMeLogoCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload a company logo image. Can take a PNG or JPEG.

## Permissions:
Has role **IT** or **HR**.
 * @summary Company Logo Upload
 */
export const intuitionPrefsCompanyMeLogoDestroy = (
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CompanyPreferenceModel | void>(
    { url: `/api/v1/intuition/prefs/company/me/logo/`, method: "DELETE" },
    options
  );
};

export const getIntuitionPrefsCompanyMeLogoDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoDestroy>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoDestroy>>,
    void
  > = () => {
    return intuitionPrefsCompanyMeLogoDestroy(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionPrefsCompanyMeLogoDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoDestroy>>
>;

export type IntuitionPrefsCompanyMeLogoDestroyMutationError =
  ErrorType<unknown>;

/**
 * @summary Company Logo Upload
 */
export const useIntuitionPrefsCompanyMeLogoDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionPrefsCompanyMeLogoDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionPrefsCompanyMeLogoDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List all users in the company.         <br><br> <b><i>Permissions:</i></b> AuthenticatedReadOnly.         <br><br> <b><i>Search:</b></i> ["first_name", "last_name"]
 * @summary List Users
 */
export const intuitionUsersList = (
  params?: IntuitionUsersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedUserResponseList>(
    { url: `/api/v1/intuition/users/`, method: "GET", params, signal },
    options
  );
};

export const getIntuitionUsersListQueryKey = (
  params?: IntuitionUsersListParams
) => {
  return [`/api/v1/intuition/users/`, ...(params ? [params] : [])] as const;
};

export const getIntuitionUsersListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersList>>
  > = ({ signal }) => intuitionUsersList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersList>>
>;
export type IntuitionUsersListQueryError = ErrorType<unknown>;

/**
 * @summary List Users
 */
export const useIntuitionUsersList = <
  TData = Awaited<ReturnType<typeof intuitionUsersList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a user.         <br><br> <b><i>Permissions:</i></b> Admin | Intuition API key | Has role <b>HR</b> or <b>IT</b>.
 * @summary Create User
 */
export const intuitionUsersCreate = (
  userRequest: UserRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<_UserResponse>(
    {
      url: `/api/v1/intuition/users/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userRequest,
    },
    options
  );
};

export const getIntuitionUsersCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersCreate>>,
    TError,
    { data: UserRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersCreate>>,
  TError,
  { data: UserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersCreate>>,
    { data: UserRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionUsersCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersCreate>>
>;
export type IntuitionUsersCreateMutationBody = UserRequest;
export type IntuitionUsersCreateMutationError = ErrorType<unknown>;

/**
 * @summary Create User
 */
export const useIntuitionUsersCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersCreate>>,
    TError,
    { data: UserRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getIntuitionUsersCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a single user.         <br><br> <b><i>Permissions:</i></b> Admin | Intuition API key | Has role <b>HR</b> or <b>IT</b>.
 * @summary Get User
 */
export const intuitionUsersRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<_UserResponse>(
    { url: `/api/v1/intuition/users/${id}/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersRetrieveQueryKey = (id: number) => {
  return [`/api/v1/intuition/users/${id}/`] as const;
};

export const getIntuitionUsersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersRetrieve>>
  > = ({ signal }) => intuitionUsersRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersRetrieve>>
>;
export type IntuitionUsersRetrieveQueryError = ErrorType<unknown>;

/**
 * @summary Get User
 */
export const useIntuitionUsersRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a single user.         <br><br> <b><i>Permissions:</i></b> Admin | Intuition API key | Has role <b>HR</b> or <b>IT</b>.
 * @summary Update User
 */
export const intuitionUsersPartialUpdate = (
  id: number,
  patchedUserRequest: PatchedUserRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<_UserResponse>(
    {
      url: `/api/v1/intuition/users/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserRequest,
    },
    options
  );
};

export const getIntuitionUsersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersPartialUpdate>>,
  TError,
  { id: number; data: PatchedUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersPartialUpdate>>,
    { id: number; data: PatchedUserRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionUsersPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPartialUpdate>>
>;
export type IntuitionUsersPartialUpdateMutationBody = PatchedUserRequest;
export type IntuitionUsersPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary Update User
 */
export const useIntuitionUsersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a user from all tenants.         <br><br> <b><i>Permissions:</i></b> Admin | Intuition API key | Has role <b>HR</b> or <b>IT</b>.
 * @summary Delete User
 */
export const intuitionUsersDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/intuition/users/${id}/`, method: "DELETE" },
    options
  );
};

export const getIntuitionUsersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return intuitionUsersDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersDestroy>>
>;

export type IntuitionUsersDestroyMutationError = ErrorType<unknown>;

/**
 * @summary Delete User
 */
export const useIntuitionUsersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getIntuitionUsersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Log a user into the product via token auth.         <br><br> <b><i>Permissions:</i></b> Allow any.
 * @summary User Login
 */
export const intuitionUsersLoginCreate = (
  userTokenRequest: UserTokenRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserToken>(
    {
      url: `/api/v1/intuition/users/login/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userTokenRequest,
    },
    options
  );
};

export const getIntuitionUsersLoginCreateMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersLoginCreate>>,
    TError,
    { data: UserTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersLoginCreate>>,
  TError,
  { data: UserTokenRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersLoginCreate>>,
    { data: UserTokenRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionUsersLoginCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersLoginCreate>>
>;
export type IntuitionUsersLoginCreateMutationBody = UserTokenRequest;
export type IntuitionUsersLoginCreateMutationError = ErrorType<void>;

/**
 * @summary User Login
 */
export const useIntuitionUsersLoginCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersLoginCreate>>,
    TError,
    { data: UserTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getIntuitionUsersLoginCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary User Logout
 */
export const intuitionUsersLogoutRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<User>(
    { url: `/api/v1/intuition/users/logout/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersLogoutRetrieveQueryKey = () => {
  return [`/api/v1/intuition/users/logout/`] as const;
};

export const getIntuitionUsersLogoutRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersLogoutRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>
  > = ({ signal }) => intuitionUsersLogoutRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersLogoutRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>
>;
export type IntuitionUsersLogoutRetrieveQueryError = ErrorType<unknown>;

/**
 * @summary User Logout
 */
export const useIntuitionUsersLogoutRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersLogoutRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersLogoutRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the user currently logged in.         <br><br> <b><i>Permissions:</i></b> Authenticated user.
 * @summary Current User
 */
export const intuitionUsersMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<_UserResponse>(
    { url: `/api/v1/intuition/users/me/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersMeRetrieveQueryKey = () => {
  return [`/api/v1/intuition/users/me/`] as const;
};

export const getIntuitionUsersMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>
  > = ({ signal }) => intuitionUsersMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>
>;
export type IntuitionUsersMeRetrieveQueryError = ErrorType<unknown>;

/**
 * @summary Current User
 */
export const useIntuitionUsersMeRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * User avatar image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user
 * @summary User Avatar Upload
 */
export const intuitionUsersMeAvatarCreate = (
  userAvatarUploadRequest: UserAvatarUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  formData.append("file", userAvatarUploadRequest.file);

  return axiosInstance<_UserResponse | void>(
    {
      url: `/api/v1/intuition/users/me/avatar/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getIntuitionUsersMeAvatarCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarCreate>>,
    TError,
    { data: UserAvatarUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersMeAvatarCreate>>,
  TError,
  { data: UserAvatarUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarCreate>>,
    { data: UserAvatarUploadRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionUsersMeAvatarCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersMeAvatarCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersMeAvatarCreate>>
>;
export type IntuitionUsersMeAvatarCreateMutationBody = UserAvatarUploadRequest;
export type IntuitionUsersMeAvatarCreateMutationError = ErrorType<unknown>;

/**
 * @summary User Avatar Upload
 */
export const useIntuitionUsersMeAvatarCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarCreate>>,
    TError,
    { data: UserAvatarUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersMeAvatarCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * User avatar image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user
 * @summary User Avatar Upload
 */
export const intuitionUsersMeAvatarDestroy = (
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<_UserResponse | void>(
    { url: `/api/v1/intuition/users/me/avatar/`, method: "DELETE" },
    options
  );
};

export const getIntuitionUsersMeAvatarDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersMeAvatarDestroy>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarDestroy>>,
    void
  > = () => {
    return intuitionUsersMeAvatarDestroy(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersMeAvatarDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersMeAvatarDestroy>>
>;

export type IntuitionUsersMeAvatarDestroyMutationError = ErrorType<unknown>;

/**
 * @summary User Avatar Upload
 */
export const useIntuitionUsersMeAvatarDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersMeAvatarDestroy>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersMeAvatarDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionUsersPrefsList = (
  params?: IntuitionUsersPrefsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedUserPreferencesList>(
    { url: `/api/v1/intuition/users/prefs/`, method: "GET", params, signal },
    options
  );
};

export const getIntuitionUsersPrefsListQueryKey = (
  params?: IntuitionUsersPrefsListParams
) => {
  return [
    `/api/v1/intuition/users/prefs/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionUsersPrefsListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersPrefsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersPrefsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersPrefsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsList>>
  > = ({ signal }) => intuitionUsersPrefsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersPrefsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsList>>
>;
export type IntuitionUsersPrefsListQueryError = ErrorType<unknown>;

export const useIntuitionUsersPrefsList = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersPrefsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersPrefsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersPrefsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionUsersPrefsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<UserPreferences>(
    { url: `/api/v1/intuition/users/prefs/${id}/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersPrefsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/intuition/users/prefs/${id}/`] as const;
};

export const getIntuitionUsersPrefsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersPrefsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>
  > = ({ signal }) => intuitionUsersPrefsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersPrefsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>
>;
export type IntuitionUsersPrefsRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionUsersPrefsRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersPrefsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersPrefsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionUsersPrefsPartialUpdate = (
  id: number,
  patchedUserPreferencesRequest: PatchedUserPreferencesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserPreferences>(
    {
      url: `/api/v1/intuition/users/prefs/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserPreferencesRequest,
    },
    options
  );
};

export const getIntuitionUsersPrefsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserPreferencesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersPrefsPartialUpdate>>,
  TError,
  { id: number; data: PatchedUserPreferencesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsPartialUpdate>>,
    { id: number; data: PatchedUserPreferencesRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionUsersPrefsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersPrefsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsPartialUpdate>>
>;
export type IntuitionUsersPrefsPartialUpdateMutationBody =
  PatchedUserPreferencesRequest;
export type IntuitionUsersPrefsPartialUpdateMutationError = ErrorType<unknown>;

export const useIntuitionUsersPrefsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsPartialUpdate>>,
    TError,
    { id: number; data: PatchedUserPreferencesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersPrefsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Verify the otp token for user

:param request:
:param pk:
:return:
 * @summary Otp Token Verification
 */
export const intuitionUsersPrefsVerifyOtpCreate = (
  id: number,
  userOtpTokenRequest: UserOtpTokenRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserOtpToken>(
    {
      url: `/api/v1/intuition/users/prefs/${id}/verify-otp/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userOtpTokenRequest,
    },
    options
  );
};

export const getIntuitionUsersPrefsVerifyOtpCreateMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsVerifyOtpCreate>>,
    TError,
    { id: number; data: UserOtpTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersPrefsVerifyOtpCreate>>,
  TError,
  { id: number; data: UserOtpTokenRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsVerifyOtpCreate>>,
    { id: number; data: UserOtpTokenRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return intuitionUsersPrefsVerifyOtpCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersPrefsVerifyOtpCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsVerifyOtpCreate>>
>;
export type IntuitionUsersPrefsVerifyOtpCreateMutationBody =
  UserOtpTokenRequest;
export type IntuitionUsersPrefsVerifyOtpCreateMutationError = ErrorType<void>;

/**
 * @summary Otp Token Verification
 */
export const useIntuitionUsersPrefsVerifyOtpCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsVerifyOtpCreate>>,
    TError,
    { id: number; data: UserOtpTokenRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersPrefsVerifyOtpCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * GET/PATCH current user's prefs             <br><br> <b><i>Permissions:</i></b> Authenticated user.
 */
export const intuitionUsersPrefsMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<UserPreferences>(
    { url: `/api/v1/intuition/users/prefs/me/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersPrefsMeRetrieveQueryKey = () => {
  return [`/api/v1/intuition/users/prefs/me/`] as const;
};

export const getIntuitionUsersPrefsMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersPrefsMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>
  > = ({ signal }) => intuitionUsersPrefsMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersPrefsMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>
>;
export type IntuitionUsersPrefsMeRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionUsersPrefsMeRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersPrefsMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * GET/PATCH current user's prefs             <br><br> <b><i>Permissions:</i></b> Authenticated user.
 */
export const intuitionUsersPrefsMePartialUpdate = (
  patchedUserPreferencesRequest: PatchedUserPreferencesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserPreferences>(
    {
      url: `/api/v1/intuition/users/prefs/me/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserPreferencesRequest,
    },
    options
  );
};

export const getIntuitionUsersPrefsMePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsMePartialUpdate>>,
    TError,
    { data: PatchedUserPreferencesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof intuitionUsersPrefsMePartialUpdate>>,
  TError,
  { data: PatchedUserPreferencesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof intuitionUsersPrefsMePartialUpdate>>,
    { data: PatchedUserPreferencesRequest }
  > = (props) => {
    const { data } = props ?? {};

    return intuitionUsersPrefsMePartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IntuitionUsersPrefsMePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersPrefsMePartialUpdate>>
>;
export type IntuitionUsersPrefsMePartialUpdateMutationBody =
  PatchedUserPreferencesRequest;
export type IntuitionUsersPrefsMePartialUpdateMutationError =
  ErrorType<unknown>;

export const useIntuitionUsersPrefsMePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof intuitionUsersPrefsMePartialUpdate>>,
    TError,
    { data: PatchedUserPreferencesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getIntuitionUsersPrefsMePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const intuitionUsersRolesList = (
  params?: IntuitionUsersRolesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedRoleList>(
    { url: `/api/v1/intuition/users/roles`, method: "GET", params, signal },
    options
  );
};

export const getIntuitionUsersRolesListQueryKey = (
  params?: IntuitionUsersRolesListParams
) => {
  return [
    `/api/v1/intuition/users/roles`,
    ...(params ? [params] : []),
  ] as const;
};

export const getIntuitionUsersRolesListQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersRolesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersRolesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersRolesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersRolesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersRolesList>>
  > = ({ signal }) => intuitionUsersRolesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersRolesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersRolesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersRolesList>>
>;
export type IntuitionUsersRolesListQueryError = ErrorType<unknown>;

export const useIntuitionUsersRolesList = <
  TData = Awaited<ReturnType<typeof intuitionUsersRolesList>>,
  TError = ErrorType<unknown>,
>(
  params?: IntuitionUsersRolesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof intuitionUsersRolesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersRolesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const intuitionUsersTenantsRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<User>(
    { url: `/api/v1/intuition/users/tenants/`, method: "GET", signal },
    options
  );
};

export const getIntuitionUsersTenantsRetrieveQueryKey = () => {
  return [`/api/v1/intuition/users/tenants/`] as const;
};

export const getIntuitionUsersTenantsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getIntuitionUsersTenantsRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>
  > = ({ signal }) => intuitionUsersTenantsRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IntuitionUsersTenantsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>
>;
export type IntuitionUsersTenantsRetrieveQueryError = ErrorType<unknown>;

export const useIntuitionUsersTenantsRetrieve = <
  TData = Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof intuitionUsersTenantsRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIntuitionUsersTenantsRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
