import {
  SimpleMentionRequest,
  _PeopleResponse,
} from "../services/teambuilder/schemas";

export type ChildNode = {
  type?: string;
  text?: string;
  person?: Pick<_PeopleResponse, "id" | "firstName" | "lastName" | "email">;
  children?: ChildNode[];
};

type MentionRequest = SimpleMentionRequest & {
  user: _PeopleResponse["id"];
};

export const slateToContent = (children: ChildNode[]) => {
  const post: {
    content: string;
    mentions: MentionRequest[];
  } = {
    content: "",
    mentions: [],
  };
  for (const value of children) {
    if (value.type !== "paragraph") continue;
    const addChildToContent = (child: ChildNode) => {
      if (!child.type && child.text) {
        post.content += child.text;
        return;
      }
      if (child.type === "mention") {
        if (!child.person || !child.person.id) return;
        const contentText = `@${child.person.firstName} ${child.person.lastName}`;
        const contentOffset = post.content.length;
        post.content += contentText;
        post.mentions.push({
          user: child.person.id as number,
          contentText,
          contentOffset,
        });
        return;
      }
      if (child.children && child.children.length > 0) {
        child.children.forEach(addChildToContent);
      }
    };
    if (value.children) value.children.forEach(addChildToContent);
    post.content += "\n";
  }
  post.content = post.content.trimEnd();
  return post;
};
