import { useEffect, useState } from "react";

const sixtyFPS = 1000 / 60;

export const useCountUp = (to: number, durationMs = 750) => {
  const [increment, setIncrement] = useState(0);
  const [value, setValue] = useState(0);
  const frames = durationMs / sixtyFPS;

  useEffect(() => {
    if (to !== value) {
      const newIncrement = (to - value) / frames;
      setIncrement(newIncrement);
      setValue(Math.ceil(value + newIncrement));
    }
  }, [to]);

  useEffect(() => {
    // last frame or prevent the overshoot
    if (Math.abs(to - value) <= Math.abs(increment)) {
      setValue(to);
      return;
    }
    if (to !== value) {
      const timer = setTimeout(() => {
        setValue(Math.ceil(value + increment));
      }, Math.floor(sixtyFPS));

      return () => clearTimeout(timer);
    }
  }, [value]);

  return value;
};
