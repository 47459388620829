/**
 *
 *
 * PostsDropdown
 *
 *
 */
import { Popover, Transition } from "@headlessui/react";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment } from "react";

import { PostList } from "../PostListV3";

export const PostsDropdown = () => {
  return (
    <Popover as="nav" className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              "group/mobile-nav-toggle border border-zinc-300 p-2 hover:bg-zinc-100",
              "rounded-md",
              open ? "bg-zinc-100" : "bg-white"
            )}
          >
            <ChatBubbleLeftRightIcon className="h-6 w-6 text-zinc-500 group-hover/mobile-nav-toggle:text-zinc-500" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel
              className={clsx(
                "absolute z-10 mt-2 w-screen max-w-xl",
                "bg-white shadow-lg focus:outline-none",
                "-right-2.5 top-12 origin-top-right"
              )}
              style={{ maxHeight: "calc(100vh - 66px)" }}
            >
              <PostList className="max-w-screen w-full" />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
