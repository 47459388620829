/**
 *
 *
 * <PhoneNumberForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { Button } from "../../components/ButtonV3";
import { SelectDropdown } from "../../components/SelectDropdownV3";
import { PhoneNumberLabel } from "../../services/teambuilder/schemas";
import { usePhoneNumberForm } from "./usePhoneNumberForm";

export const PhoneNumberForm = (
  props: ReturnType<typeof usePhoneNumberForm>
) => {
  const { t } = useTranslation();

  const PHONE_LABELS: Record<PhoneNumberLabel, string> = {
    [PhoneNumberLabel.HOME]: t("translation:user_guide:home"),
    [PhoneNumberLabel.OFFICE]: t("translation:user_guide:office"),
    [PhoneNumberLabel.MOBILE]: t("translation:user_guide:mobile"),
    [PhoneNumberLabel.OTHER]: t("translation:user_guide:other"),
  };

  return (
    <div className={props.className}>
      <form onSubmit={props.onHandleSubmit}>
        <div className="rounded-md border border-zinc-300 px-2 pb-2">
          <div className="flex flex-col items-start gap-1">
            <div className="item-start flex w-full flex-row gap-2">
              <div className="w-full sm:max-w-max">
                <SelectDropdown
                  name="label"
                  isLoading={false}
                  options={Object.keys(PHONE_LABELS).map((key) => ({
                    id: key,
                    name: PHONE_LABELS[key as PhoneNumberLabel],
                  }))}
                  setValue={(id) => {
                    props.form.setValue(
                      "label",
                      PHONE_LABELS[id as PhoneNumberLabel]
                    );
                  }}
                  defaultValue={
                    props.data.label
                      ? {
                          id: props.data.label,
                          name: PHONE_LABELS[
                            props.data.label as PhoneNumberLabel
                          ],
                        }
                      : undefined
                  }
                  error={props.form.formState.errors.label?.message}
                />
              </div>
              <div className="mt-2 flex-grow">
                <PhoneInput
                  defaultCountry="us"
                  value={props.data.phoneNumber}
                  style={
                    {
                      "--react-international-phone-flag-width": "1.125rem",
                      "--react-international-phone-height": "2.5rem",
                      // rounded-md
                      "--react-international-phone-border-radius": "0.375rem",
                      // zinc-300
                      "--react-international-phone-border-color": "#d4d4d8",
                      // zinc-900
                      "--react-international-text-color": "#18181b",
                      // text-sm
                      "--react-international-phone-font-size": "0.875rem",
                    } as React.CSSProperties
                  }
                  onChange={(phone) =>
                    props.form.setValue("phoneNumber", phone)
                  }
                  className="w-full"
                />
                {props.form.formState.errors.phoneNumber?.message && (
                  <p className="mt-2 text-xs text-red-500">
                    {props.form.formState.errors.phoneNumber?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex w-full flex-row justify-end gap-2">
              <Button
                variant="contained"
                size="md"
                className="mt-1 h-full py-1 sm:py-2.5"
                type="submit"
                disabled={
                  props.isCreating ||
                  props.isDeleting ||
                  props.isSaving ||
                  (props.label === props.data.label &&
                    props.phoneNumber === props.data.phoneNumber)
                }
              >
                {t("translation:common:save")}
              </Button>
              <Button
                variant="ghost"
                size="md"
                className="mt-1 h-full py-1 sm:py-2.5"
                onClick={(e) => {
                  e.preventDefault();
                  props.onDeletePhoneNumber();
                }}
                disabled={
                  props.isCreating || props.isDeleting || props.isSaving
                }
              >
                {t("translation:common:remove")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
