import { useCallback, useMemo, useState } from "react";

import { queryClient } from "../query-client";
import {
  getAsdsListQueryKey,
  useAsdsList,
} from "../services/teambuilder/endpoints/asds/asds";
import {
  getSocialPostsListQueryKey,
  useSocialPostsList,
} from "../services/teambuilder/endpoints/social/social";
import {
  AdsListParams,
  SocialPostsListParams,
} from "../services/teambuilder/schemas";

const POSTS_LIMIT = 10;
const ADS_LIMIT = 4;

export const usePostFeedListParams = () => {
  const [postLimit, setPostLimit] = useState(POSTS_LIMIT);
  const postsListParams: SocialPostsListParams = useMemo(
    () => ({
      limit: postLimit,
      offset: 0,
      ordering: "-created_at",
    }),
    [postLimit]
  );
  const postsListQueryKey = useMemo(
    () => getSocialPostsListQueryKey(postsListParams),
    [postsListParams]
  );

  const [adsLimit, setAdsLimit] = useState(ADS_LIMIT);
  const adsListParams: AdsListParams = useMemo(
    () => ({
      limit: adsLimit,
      offset: 0,
      "content_types[]": "coffeechatrsvp",
    }),
    [adsLimit]
  );
  const adsListQueryKey = useMemo(
    () => getAsdsListQueryKey(adsListParams),
    [adsListParams]
  );

  const loadMore = useCallback(() => {
    setPostLimit((prev) => prev + POSTS_LIMIT);
    setAdsLimit((prev) => prev + ADS_LIMIT);
  }, [setPostLimit, setAdsLimit]);

  const refetch = () => {
    queryClient.invalidateQueries(postsListQueryKey);
    queryClient.invalidateQueries(adsListQueryKey);
  };

  return {
    postsListParams,
    adsListParams,
    postsListQueryKey,
    adsListQueryKey,
    loadMore,
    refetch,
  };
};

/**
 * Requests for posts and ads.
 *
 * You might be tempted to instantiate the usePostFeedListParams hook here,
 * but that would be a mistake. The usePostFeedListParams hook must be instantiated
 * in a component and passed to usePostFeedList to avoid a stale closure.
 * See: https://dmitripavlutin.com/react-hooks-stale-closures/
 */
export const usePostFeedList = (
  postsListParams: SocialPostsListParams,
  adsListParams: AdsListParams
) => {
  /**
   *
   * Posts request
   *
   */
  const { data: postsListResponse, isLoading: isLoadingPostsList } =
    useSocialPostsList(postsListParams, {
      query: {
        keepPreviousData: true,
      },
    });
  /**
   *
   * Ads request
   *
   */
  const { data: adsListResponse, isLoading: isLoadingAdsList } = useAsdsList(
    adsListParams,
    {
      query: {
        keepPreviousData: true,
      },
    }
  );

  return {
    postsListResponse,
    isLoadingPostsList,
    adsListResponse,
    isLoadingAdsList,
  };
};
