/**
 *
 *
 * <Input />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { InputError } from "../InputError";
import { Label } from "../LabelV3";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  label: string;
  labelClassName?: string;
  description?: React.ReactNode;
  error?: string;
  className?: string;
  loading?: boolean;
}

// Use forwardRef to avoid "Function components cannot be given refs" error.
const Input = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      labelClassName,
      description,
      loading,
      label,
      className,
      type,
      error,
      ...rest
    } = props;

    return (
      <div className={className}>
        <Label htmlFor={props.id} className={labelClassName}>
          {label}
        </Label>

        {description && (
          <p className="text-sm leading-6 text-zinc-500">{description}</p>
        )}

        <div className="relative mt-2 rounded-md">
          {loading ? (
            <div className="h-12 animate-pulse rounded-md bg-zinc-400" />
          ) : (
            <input
              type={type || "text"}
              className={clsx(
                "px-3 py-2",
                "block w-full rounded-md border-0",
                "ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-500",
                "focus:ring-2 focus:ring-inset focus:ring-zinc-300",
                "text-sm",
                error ? "ring-red-500 focus:ring-red-500" : "ring-zinc-300",
                props.disabled ? "bg-zinc-100 text-zinc-500" : "text-zinc-900"
              )}
              aria-invalid={props.error ? "true" : "false"}
              aria-describedby={`${props.id}-error`}
              {...rest}
              ref={ref}
            />
          )}
        </div>

        {props.error && <InputError>{props.error}</InputError>}
      </div>
    );
  }
);

// Avoid "Component definition is missing display name" error.
Input.displayName = "Input";

export { Input };
