/**
 *
 *
 * buttonStyles
 *
 *
 */
import clsx from "clsx";

export type Variant =
  | "contained"
  | "highlight"
  | "outlined"
  | "inline"
  | "text"
  | "text-danger"
  | "success"
  | "danger"
  | "warn"
  | "skeleton";

export type Size = "xl" | "lg" | "md" | "sm" | "xs";

interface Options {
  variant?: Variant;
  size?: Size;
  dark?: boolean;
}

export const buttonStyles = ({
  variant = "contained",
  size = "lg",
  dark,
}: Options) => {
  const paddingStyles = {
    xs: "py-1 px-2",
    sm: "py-1 px-2",
    md: "py-2 px-3",
    lg: "py-2 px-3",
    xl: "px-2.5 py-3.5",
  };

  const textStyles: Record<Size, string> = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-sm",
    lg: "text-md",
    xl: "text-lg",
  };

  const roundedStyles: Record<Size, string> = {
    xs: "rounded-md",
    sm: "rounded-md",
    md: "rounded-lg",
    lg: "rounded-lg",
    xl: "rounded-lg",
  };

  const sharedStyles = clsx(
    "group flex items-center justify-center gap-2",
    "focus:outline-none",
    "font-medium",
    "tracking-wide",
    paddingStyles[size],
    textStyles[size],
    roundedStyles[size]
  );

  const variantStyles: Record<Variant, string> = {
    contained: clsx(
      sharedStyles,
      "shadow-sm",
      "text-white",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      dark
        ? "bg-slate-700 hover:bg-slate-800 disabled:bg-slate-500/40"
        : "bg-white/10 hover:bg-white/20 disabled:bg-white/30 disabled:bg-white/50"
    ),
    highlight: clsx(
      sharedStyles,
      "shadow-sm",
      "text-white",
      dark
        ? "bg-gradient-to-br from-amber-400 to-amber-700 disabled:opacity-50"
        : "bg-white/10 hover:bg-white/20 disabled:bg-white/50",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    outlined: clsx(
      sharedStyles,
      "shadow-sm",
      dark
        ? "border border-slate-300 text-slate-600 shadow hover:bg-slate-50 disabled:border-slate-200 disabled:text-slate-400"
        : "border border-white/60 text-white hover:bg-white/20",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    inline: clsx(
      textStyles[size],
      "text-amber-500 hover:text-amber-600",
      "disabled:cursor-not-allowed disabled:text-slate-400"
    ),
    text: clsx(
      sharedStyles,
      "bg-transparent",
      dark
        ? "text-slate-500 hover:text-slate-600"
        : "text-white hover:text-white/80",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    "text-danger": clsx(
      sharedStyles,
      "bg-transparent",
      "text-red-500 hover:text-red-600",
      "disabled:cursor-not-allowed disabled:text-slate-400"
    ),
    success: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-green-500 text-white hover:bg-green-600 focus:ring-green-500 disabled:bg-green-500",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500",
      "disabled:cursor-not-allowed"
    ),
    danger: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-red-500 text-white hover:bg-red-600 focus:ring-red-500 disabled:bg-red-500",
      "focus-visible:outline-error-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
      "disabled:cursor-not-allowed"
    ),
    warn: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-amber-400 text-white hover:bg-amber-500 focus:ring-amber-500 disabled:bg-amber-500",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500",
      "disabled:cursor-not-allowed"
    ),
    skeleton: clsx(
      sharedStyles,
      "bg-slate-300",
      // Hide the text but preserve the space it would take up.
      "text-slate-300",
      "animate-pulse",
      "disabled:cursor-not-allowed"
    ),
  };

  return variantStyles[variant];
};

export const buttonStylesV3 = ({
  variant = "contained",
  size = "lg",
  dark,
}: Options) => {
  const paddingStyles = {
    xs: "py-1 px-2",
    sm: "py-1 px-2",
    md: "py-2 px-3",
    lg: "py-2 px-3",
    xl: "px-2.5 py-3.5",
  };

  const textStyles: Record<Size, string> = {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-sm",
    lg: "text-md",
    xl: "text-lg",
  };

  const roundedStyles: Record<Size, string> = {
    xs: "rounded-md",
    sm: "rounded-md",
    md: "rounded-lg",
    lg: "rounded-lg",
    xl: "rounded-lg",
  };

  const sharedStyles = clsx(
    "group flex items-center justify-center gap-2",
    "focus:outline-none",
    "font-medium",
    "tracking-wide",
    paddingStyles[size],
    textStyles[size],
    roundedStyles[size]
  );

  const variantStyles: Record<Variant, string> = {
    contained: clsx(
      sharedStyles,
      "shadow-sm",
      "text-white",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      dark
        ? "bg-zinc-500 hover:bg-zinc-600 disabled:bg-zinc-300/40"
        : "bg-white/10 hover:bg-white/20 disabled:bg-white/30 disabled:bg-white/50"
    ),
    highlight: clsx(
      sharedStyles,
      "shadow-sm",
      "text-white",
      dark
        ? "bg-amber-700 hover:bg-amber-800 disabled:bg-amber-500/40"
        : "bg-white/10 hover:bg-white/20 disabled:bg-white/50",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    outlined: clsx(
      sharedStyles,
      "shadow-sm",
      dark
        ? "border border-zinc-300 text-zinc-900 shadow hover:bg-zinc-100 disabled:text-zinc-300"
        : "border border-white/60 text-white hover:bg-white/20",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    inline: clsx(
      textStyles[size],
      "text-amber-500 hover:text-amber-600",
      "disabled:cursor-not-allowed disabled:text-slate-400"
    ),
    text: clsx(
      sharedStyles,
      "bg-transparent",
      dark
        ? "text-slate-500 hover:text-slate-600"
        : "text-white hover:text-white/80",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white/10",
      "disabled:cursor-not-allowed"
    ),
    "text-danger": clsx(
      sharedStyles,
      "bg-transparent",
      "text-red-500 hover:text-red-600",
      "disabled:cursor-not-allowed disabled:text-slate-400"
    ),
    success: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-green-500 text-white hover:bg-green-600 focus:ring-green-500 disabled:bg-green-500",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500",
      "disabled:cursor-not-allowed"
    ),
    danger: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-red-700 text-white hover:bg-red-800 disabled:bg-red-500",
      "focus-visible:outline-error-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
      "disabled:cursor-not-allowed"
    ),
    warn: clsx(
      sharedStyles,
      "shadow-sm",
      "bg-yellow-600 text-white hover:bg-yellow-800 focus:ring-yellow-600 disabled:bg-yellow-500",
      "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500",
      "disabled:cursor-not-allowed"
    ),
    skeleton: clsx(
      sharedStyles,
      "bg-zinc-400",
      // Hide the text but preserve the space it would take up.
      "text-zinc-300",
      "animate-pulse",
      "disabled:cursor-not-allowed"
    ),
  };

  return variantStyles[variant];
};
