/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */

export type UserguidesListOrdering =
  (typeof UserguidesListOrdering)[keyof typeof UserguidesListOrdering];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserguidesListOrdering = {
  "-birth_date": "-birth_date",
  "-created_at": "-created_at",
  "-id": "-id",
  "-start_date": "-start_date",
  "-updated_at": "-updated_at",
  "-user": "-user",
  "-user__first_name": "-user__first_name",
  "-user__last_name": "-user__last_name",
  birth_date: "birth_date",
  created_at: "created_at",
  id: "id",
  start_date: "start_date",
  updated_at: "updated_at",
  user: "user",
  user__first_name: "user__first_name",
  user__last_name: "user__last_name",
} as const;
