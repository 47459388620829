/**
 *
 *
 * usePostList
 *
 *
 */
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import {
  getLocationsListQueryKey,
  useLocationsCreate,
  useLocationsDestroy,
  useLocationsList,
  useLocationsPartialUpdate,
} from "../../services/teambuilder/endpoints/locations/locations";
import { usePeopleMeRetrieve } from "../../services/teambuilder/endpoints/people/people";
import {
  LocationModel,
  LocationModelRequest,
  LocationsListParams,
  PaginatedLocationModelList,
  PatchedLocationModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { FormData } from "./LocationForm";

const emptyLocation: FormData = {
  name: "",
  description: "",
};

export const useLocations = () => {
  const { t } = useTranslation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [shallResetFormData, setShallResetFormData] = useState(false);
  // Preserve the Location id for updates and deletes
  const [locationId, setLocationId] = useState<number | undefined>();
  // formData populates the SlideOver form. The form will reset whenever formData changes.
  const [formData, setFormData] = useState<FormData>(emptyLocation);

  // Set the toast message before opening to prevent FOUC.
  const { openToast } = useToast();
  const showToast = (message: string) => {
    openToast({
      title: message,
    });
  };

  const params: LocationsListParams = {
    ordering: "name",
    limit: 999,
  };

  const queryKey = getLocationsListQueryKey(params);

  const { data: userData } = usePeopleMeRetrieve({
    query: {
      onError: () => {
        showToast(t("translation:toast:user_load_error"));
      },
    },
  });

  const { data: locations = [], isLoading } = useLocationsList(params, {
    query: {
      select: ({ data }) => data,
      onError: () => {
        showToast(t("translation:toast:location_load_error"));
      },
    },
  });

  /**
   * Show the form and populate the form data.
   * If a location is received, assume this is an update.
   */
  const openSlideOver = (location?: LocationModel) => {
    setShallResetFormData(false);
    // Set location ID for use in update requests.
    setLocationId(location?.id);
    setFormData(
      location
        ? { name: location.name || "", description: location.description }
        : emptyLocation
    );
    setIsFormOpen(true);
  };

  const closeSlideOver = () => {
    setIsFormOpen(false);
  };

  /**
   *
   * Create
   *
   */
  // Define generics for <error, context>. Context is the return location of onMutate.
  // Context is passed from onMutate to onSuccess and onError.
  const { mutate: createLocation } = useLocationsCreate(
    optimisticMutationOptions<
      LocationModel,
      PaginatedLocationModelList,
      { data: LocationModelRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        setShallResetFormData(true);
        // Reset the form
        setFormData(emptyLocation);
        closeSlideOver();
        if (!requestVariable?.data) return context;
        return {
          meta: context.meta,
          data: [...context.data, requestVariable?.data].sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:location_add_success"));
      },
      onError: () => {
        showToast(t("translation:toast:location_add_failed"));
      },
    })
  );

  /**
   *
   * Update
   *
   */
  const { mutate: updateLocation } = useLocationsPartialUpdate(
    optimisticMutationOptions<
      LocationModel,
      PaginatedLocationModelList,
      { id: number; data: PatchedLocationModelRequest }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        setFormData(emptyLocation);
        closeSlideOver();
        return {
          meta: context.meta,
          data: context.data
            .reduce<LocationModel[]>((acc, department) => {
              let v = department;
              if (department.id === requestVariable?.id) {
                v = { ...department, ...requestVariable?.data };
              }
              return [...acc, v];
            }, [])
            .sort((a, b) => a.name.localeCompare(b.name)),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:location_update_success"));
      },
      onError: () => {
        showToast(t("translation:toast:location_update_failed"));
      },
    })
  );

  /**
   *
   * handleSubmit
   *
   */
  const handleSubmit: SubmitHandler<FormData> = (location: FormData) => {
    if (locationId) {
      updateLocation({ id: locationId, data: location });
    } else if (userData?.id) {
      createLocation({
        data: {
          user: userData.id,
          ...location,
        },
      });
    }
  };

  const handleDelete = (locationId?: number) => {
    if (!locationId) showToast(t("translation:toast:location_delete_failed"));
    setLocationId(locationId);
    setIsConfirmationModalOpen(true);
  };

  /**
   *
   * Destroy
   *
   */
  const { mutate: destroyLocation } = useLocationsDestroy(
    optimisticMutationOptions<void, PaginatedLocationModelList, { id: number }>(
      {
        queryKey,
        optimisticUpdateFn: (context, requestVariable) => {
          setIsConfirmationModalOpen(false);
          return {
            meta: context.meta,
            data: context.data.filter(
              (item) => item.id !== requestVariable?.id
            ),
          };
        },
        onSuccess: () => {
          showToast(t("translation:toast:location_delete_success"));
        },
        onError: () => {
          showToast(t("translation:toast:location_delete_failed"));
        },
      }
    )
  );

  const modalOnConfirmation = () => {
    locationId && destroyLocation({ id: locationId });
    setIsConfirmationModalOpen(false);
  };

  const onClose = () => {
    setIsConfirmationModalOpen(false);
    setLocationId(undefined);
  };

  return {
    isFormOpen,
    isConfirmationModalOpen,
    shallResetFormData,
    formData,
    locations,
    locationId,
    isLoading,
    modalOnConfirmation,
    handleDelete,
    handleSubmit,
    onClose,
    openSlideOver,
    closeSlideOver,
  };
};
