/**
 *
 *
 * <LinkedInUrlForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { getTranslation } from "../../utils/translation";
import { useLinkedInUrlForm } from "./useLinkedInUrlForm";

export const LinkedInUrlForm = (
  props: ReturnType<typeof useLinkedInUrlForm>
) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:user_guide:add_linkedin_url");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="space-y-6">
          <SkeletonInputGroup />
          <Button variant="skeleton" size="md">
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <form onSubmit={props.onHandleSubmit}>
          <div className="space-y-6">
            <Input
              id="linkedinUrl"
              label={t("translation:user_guide:linkedin_url")}
              labelClassName="sr-only"
              type="text"
              placeholder="https://www.linkedin.com/in/yourname"
              error={getTranslation(
                t,
                props.form.formState.errors.linkedinUrl?.message
              )}
              {...props.form.register("linkedinUrl")}
            />

            <Button
              size="md"
              variant="contained"
              type="submit"
              disabled={!props.form.formState.isDirty}
            >
              {submitButtonText}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
