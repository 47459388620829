/**
 *
 *
 * <UpcomingBirthdays />
 *
 *
 */
import { ChatBubbleLeftIcon } from "@heroicons/react/20/solid";
import { CakeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/Carousel";
import { UserGuides } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";
import { useUpcomingBirthdays } from "./useUpcomingBirthdays";

export const UpcomingBirthdays = (
  props: ReturnType<typeof useUpcomingBirthdays>
) => {
  const { t } = useTranslation();
  if (props.members?.data?.length === 0) return null;

  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="px-12">
        <p className="mb-0.5 text-lg font-bold leading-7 text-zinc-900">
          {t("translation:people:upcoming_birthdays")}
        </p>
        <p className="text-sm font-normal leading-5 text-zinc-500">
          {t("translation:people:celebrate_birthdays")}
        </p>
      </div>
      <Carousel className="w-full">
        <CarouselPrevious className="flex w-8 items-center justify-center bg-zinc-100" />
        <CarouselContent className="w-full items-center gap-x-6 py-4">
          {props.isLoadingMembers
            ? [...Array(3).keys()].map((i) => (
                <CarouselItem
                  key={i}
                  className="h-24 w-48 animate-pulse rounded-lg bg-zinc-200"
                />
              ))
            : props.members?.data?.map((member: UserGuides) => (
                <CarouselItem
                  key={member.id}
                  className={clsx(
                    "w-fit overflow-hidden rounded-lg bg-white shadow-lg"
                  )}
                >
                  <div className="flex items-center gap-x-3 px-3 py-2">
                    <img
                      src={member.user?.avatar?.thumbnail}
                      alt=""
                      className="h-12 w-12 rounded-full"
                    />
                    <div className="space-y-1.5">
                      <p
                        className="cursor-pointer whitespace-nowrap text-sm font-medium text-zinc-800 underline hover:text-zinc-900"
                        onClick={() => props.openProfileModal(member.id!)}
                      >
                        {fullName(member.user)}
                      </p>
                      <div className="flex flex-row items-center gap-x-1 text-sm text-zinc-900">
                        <CakeIcon className="h-5 w-5 text-zinc-400" />
                        {dayjs(member.birthDate).format("MMM D")}
                      </div>
                    </div>
                  </div>
                  <button
                    className="group flex w-full justify-center border-t border-zinc-300  py-2 hover:bg-zinc-200"
                    onClick={() => props.onPost(member.id!)}
                  >
                    <ChatBubbleLeftIcon className="mr-1 h-4 w-4 text-zinc-400 group-hover:text-zinc-500" />
                    <span className="text-xs font-semibold text-zinc-500 group-hover:text-zinc-600">
                      {t("translation:people:say_happybirthday")}
                    </span>
                  </button>
                </CarouselItem>
              ))}
        </CarouselContent>
        <CarouselNext className="flex w-8 items-center justify-center bg-zinc-100" />
      </Carousel>
    </div>
  );
};
