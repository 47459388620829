/**
 *
 *
 * <FormGroup />
 *
 *
 */
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { classNames } from "../../utils";

interface Props {
  label?: string;
  htmlFor: string;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  errorMessage?: string;
  className?: string;
  size?: string;
}

/**
 * FormGroup form input group with the label to the left in the
 * style
 *
 *
 * @label Text label visible in the form.
 * @htmlFor ID of the input component.
 * @children Form element like <input />, <textarea />
 * @errorMessage Message to display with the input fails validation.
 * @className CSS classes.
 */
export const FormGroup = ({
  label,
  htmlFor,
  tooltip,
  children,
  errorMessage,
  className = "",
  size = "sm",
}: Props) => {
  return (
    <div
      className={classNames(
        "grid items-center space-y-1 sm:gap-4 sm:space-y-0",
        className,
        size === "lg" ? "grid-cols-2" : "grid-cols-6"
      )}
    >
      {label && (
        <div className="flex">
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-zinc-900"
          >
            {label}
          </label>
          {tooltip && (
            <>
              <QuestionMarkCircleIcon
                className="ml-1 h-4 w-4 text-zinc-500"
                data-tooltip-id="tooltip"
                data-tooltip-content={tooltip}
              />
            </>
          )}
        </div>
      )}

      <div className={label ? "col-span-5" : "col-span-6"}>
        <div className="rounded-md">
          {children}

          {errorMessage && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id="form-group-error">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};
