/**
 *
 *
 * <ProofContent />
 *
 *
 */
import {
  ArrowTopRightOnSquareIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { Trans } from "react-i18next";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { ActivityProofType } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";
import { useChallengeConfirmationWithProofModal } from "./useChallengeConfirmationWithProofModal";

export const ProofContent = (
  props: ReturnType<typeof useChallengeConfirmationWithProofModal>
) => {
  if (
    (!props.selectedURL &&
      props.activity.proofType === ActivityProofType.link) ||
    // (!props.previewImageUrl &&
    //   props.activity.proofType === ActivityProofType.image) ||
    (!props.content && props.activity.proofType === ActivityProofType.text) ||
    props.activity.proofType === ActivityProofType.not_required ||
    props.activity.proofType === ActivityProofType.shoutout ||
    props.activity.proofType === ActivityProofType.image
  )
    return null;

  return (
    <div className="mt-3 sm:mt-6">
      <div className="rounded-lg bg-white px-3 py-2.5 shadow-lg">
        <div className="flex justify-end">
          <DropdownMenu
            items={props.menuItems}
            menuButtonContent={(open) => (
              <EllipsisHorizontalIcon
                className={clsx(
                  "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                  open ? "bg-zinc-200 text-zinc-600" : "text-zinc-500"
                )}
              />
            )}
            menuClassNames={clsx("right-0 mt-6 origin-top-right")}
          />
        </div>
        <div className="space-y-3">
          {props.content && (
            <p className="text-sm font-normal leading-5 text-black">
              {props.content}
            </p>
          )}
          {/* {props.selectedRecipient &&
            props.activity.proofType === ActivityProofType.shoutout && (
              <div className="flex flex-row gap-x-4">
                <div
                  className={clsx(
                    "h-9 w-9 rounded-full sm:h-10 sm:w-10",
                    "flex flex-shrink-0 items-center justify-center",
                    "text-white",
                    "bg-fuchsia-950 hover:shadow disabled:opacity-60"
                  )}
                >
                  <MegaphoneIcon className="h-6 w-6" />
                </div>
                <div className="flex-1 text-sm font-normal text-zinc-900">
                  <Trans
                    i18nKey={
                      props.selectedValueId
                        ? "translation:post:shoutout_to_value"
                        : "translation:post:shoutout_to"
                    }
                    values={{
                      name: fullName(props.selectedRecipient),
                      value: props.selectedValueLabel,
                    }}
                    components={{
                      a: (
                        <span className="font-medium text-fuchsia-950 underline" />
                      ),
                    }}
                  />
                </div>
              </div>
            )} */}
          {props.selectedURL &&
            props.activity.proofType === ActivityProofType.link && (
              <div className="flex flex-row gap-x-4">
                <a
                  href={props.selectedURL}
                  className="flex flex-1 flex-row items-center gap-x-1 break-all text-sm font-medium text-blue-950"
                  target="blank"
                >
                  {props.selectedURL}
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 flex-shrink-0" />
                </a>
              </div>
            )}
          {/* {props.previewImageUrl &&
            props.activity.proofType === ActivityProofType.image && (
              <div className="flex flex-row gap-x-4">
                <div className="relative overflow-hidden rounded-lg">
                  <img src={props.previewImageUrl} alt="" aria-hidden="true" />
                </div>
              </div>
            )} */}
        </div>
      </div>
    </div>
  );
};
