/**
 *
 *
 * <LayoutProvider />
 *
 *
 */
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useWindowSize } from "react-use";

interface Context {
  isNavCollapsed: boolean;
  toggleIsNavCollapsed: () => void;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: (count: number) => void;
  // Posts take the width of the main and right sidebar.
  isFocusMode: boolean;
  toggleFocusMode: () => void;
  setNoFocusMode: () => void;
  isMobileMode: boolean;
  width: number;
}

const LayoutContext = createContext<Context | undefined>(undefined);

export const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);

  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const { width } = useWindowSize();
  // Left sidebar collapsed state
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const toggleIsNavCollapsed = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  // Notifications alerts count
  const [unreadNotificationsCount, _setUnreadNotificationsCount] = useState(0);
  const setUnreadNotificationsCount = (count: number) => {
    _setUnreadNotificationsCount(count);
  };

  // Focus mode
  const [isFocusMode, setIsFocusMode] = useState(false);
  const toggleFocusMode = () => {
    setIsFocusMode(!isFocusMode);
  };
  const setNoFocusMode = () => {
    setIsFocusMode(false);
  };

  const isMobileMode = useMemo(() => {
    return (
      (width >= 1024 && width < 1200) ||
      (!isNavCollapsed && width >= 1200 && width < 1400) ||
      width < 768
    );
  }, [width, isNavCollapsed]);

  useEffect(() => {
    if (width < 1400 && !isNavCollapsed) {
      setIsNavCollapsed(true);
    }
    if (width >= 1400 && isNavCollapsed) {
      setIsNavCollapsed(false);
    }
  }, [width]);

  return (
    <LayoutContext.Provider
      value={{
        isNavCollapsed,
        toggleIsNavCollapsed,
        unreadNotificationsCount,
        setUnreadNotificationsCount,
        isFocusMode,
        toggleFocusMode,
        setNoFocusMode,
        isMobileMode,
        width,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
