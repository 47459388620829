/**
 *
 *
 * useBanterTopicForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import {
  getBanterTopicsListQueryKey,
  useBanterTopicsCreate,
  useBanterTopicsPartialUpdate,
} from "../../services/teambuilder/endpoints/banter/banter";
import { useTeamsRetrieve } from "../../services/teambuilder/endpoints/teams/teams";
import {
  PaginatedTopicsModelList,
  PatchedTopicsModelRequest,
  TopicsModel,
  TopicsModelRequest,
} from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

const schema = z.object({
  topic: z
    .string()
    .min(1, { message: "translation:validation:required_topic" })
    .max(5000, { message: "translation:validation:topic_255_characters" }),
  postDate: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;

const emptyTopic = {
  topic: "",
  postDate: "",
};

interface Props {
  topic?: TopicsModel;
  teamId: number;
  isOpen: boolean;
  onClose: () => void;
  topicsListQueryKey: ReturnType<typeof getBanterTopicsListQueryKey>;
}

export const useBanterTopicForm = ({
  topic,
  teamId,
  isOpen,
  onClose,
  topicsListQueryKey: queryKey,
}: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      topic: topic?.topic || "",
      postDate: topic?.postDate || "",
    },
    resolver: zodResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset(emptyTopic);
  };

  useEffect(() => {
    reset({
      topic: topic?.topic || "",
      postDate: topic?.postDate || "",
    });
  }, [topic]);

  /**
   * Team request
   */
  const { data: team } = useTeamsRetrieve(teamId, {
    query: {
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:load_team_failed"),
        });
      },
    },
  });

  const { mutate: createTopic } = useBanterTopicsCreate(
    optimisticMutationOptions<
      TopicsModel,
      PaginatedTopicsModelList,
      { data: TopicsModelRequest }
    >({
      queryKey: queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        handleClose();
        let data: TopicsModel[] = [...context.data];
        if (requestVariables?.data) {
          data = [
            ...data,
            { ...requestVariables?.data, team, id: 0 } as TopicsModel,
          ];
        }
        return {
          ...context,
          data,
        };
      },
      onSuccess: async () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:topic_add_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:topic_add_failed"),
          type: "danger",
        });
      },
    })
  );

  const { mutate: updateTopic } = useBanterTopicsPartialUpdate(
    optimisticMutationOptions<
      TopicsModel,
      PaginatedTopicsModelList,
      { id: number; data: PatchedTopicsModelRequest }
    >({
      queryKey: queryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        handleClose();
        return {
          ...context,
          data: context.data.reduce<TopicsModel[]>((acc, value) => {
            let v = value;
            if (value.id === requestVariables?.id) {
              v = { ...value, ...requestVariables?.data, team: value.team };
            }
            return [...acc, v];
          }, []),
        };
      },
      onSuccess: async () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:topic_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:topic_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const onSubmit = (formData: FormData) => {
    if (topic) {
      updateTopic({
        id: topic.id as number,
        data: {
          ...formData,
          postDate: formData.postDate || null,
        },
      });
    } else {
      createTopic({
        data: {
          ...formData,
          team: teamId,
          postDate: formData.postDate || null,
        },
      });
    }
  };

  const onHandleSubmit = handleSubmit(onSubmit);

  const minDate = dayjs().add(1, "day").format("YYYY-MM-DD");

  return {
    isOpen,
    topic,
    handleClose,
    onHandleSubmit,
    errors,
    register,
    minDate,
  };
};
