/**
 *
 *
 * <SkeletonMembersListItem />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { Avatar } from "../../components/AvatarV3";

export const SkeletonMembersListItem = () => {
  return (
    <li
      className={clsx(
        "animate-pulse",
        "relative h-fit rounded-xl bg-white p-4 text-center shadow",
        "mb-4"
      )}
    >
      <div className="flex flex-1 flex-row items-center justify-start">
        <div className="flex flex-grow-0 justify-center">
          <Avatar loading={true} size="xl" />
        </div>
        <div className="ml-4 w-full text-left">
          <div className="h-5 rounded bg-zinc-300" />
          <div className="mt-2 h-5 rounded bg-zinc-300" />
        </div>
      </div>
    </li>
  );
};
