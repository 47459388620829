/**
 *
 *
 * <Sidebar />
 *
 *
 */
import React from "react";

import { CompanySettingsNav } from "./CompanySettingsNav";
import { PrimaryNav } from "./PrimaryNav";
import { useSidebar } from "./useSidebar";

export const Sidebar = (props: ReturnType<typeof useSidebar>) => {
  return props.showCompanySettingsNav ? (
    <CompanySettingsNav navItems={props.navItems} />
  ) : (
    <PrimaryNav {...props} />
  );
};
