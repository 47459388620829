/**
 *
 *
 * usePrivacyAndSecurity
 *
 *
 */
import { usePeopleMeRetrieve } from "../../services/teambuilder/endpoints/people/people";

export const usePrivacyAndSecurity = () => {
  const { data: me } = usePeopleMeRetrieve();

  return { me };
};
