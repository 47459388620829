import { XMarkIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/ButtonV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { Textarea } from "../../components/TextareaV3";
import { useQAndAForm } from "./useQAndAForm";

export const QAndAForm = (props: ReturnType<typeof useQAndAForm>) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "rounded-md text-sm",
        "grow overflow-hidden bg-zinc-100 p-4 sm:mr-40"
      )}
    >
      <div className="flex items-center justify-between">
        <div>
          <h2 className="grow text-2xl font-light tracking-tight text-zinc-900">
            {t("translation:user_guide:edit_answers")}
          </h2>
          <h3 className="text-sm font-normal leading-5 text-zinc-500">
            <Trans
              i18nKey="translation:user_guide:q_and_a_description"
              components={{
                a: (
                  <Link
                    to="/company-settings/profile-settings"
                    className="font-semibold underline"
                  />
                ),
              }}
            />
          </h3>
        </div>

        <Button variant="outlined" onClick={props.onCancel} size="md">
          <div className="hidden sm:block">{t("translation:common:close")}</div>
          <XMarkIcon
            className={clsx(
              "h-5 w-5 text-zinc-500 group-hover/cancel-button:text-zinc-600"
            )}
          />
        </Button>
      </div>
      {props.isLoading ? (
        <div className="mt-10 space-y-6">
          <SkeletonInputGroup />
          <div className="flex justify-end">
            <Button variant="skeleton" size="md">
              {t("translation:common:save")}
            </Button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={props.onHandleSubmit}
          className="space-y-12 divide-y divide-zinc-300"
        >
          <ul className="space-y-12 divide-y divide-zinc-300">
            {props.fields.map((field, index) => (
              <li key={field.questionId}>
                <p
                  className={clsx(
                    "mt-10 py-2 text-sm leading-6 tracking-wide text-zinc-500"
                  )}
                >
                  {field.question}
                </p>

                <Textarea
                  label=""
                  rows={3}
                  {...props.form.register(`qas.${index}.answer`)}
                />
              </li>
            ))}
          </ul>
          <div
            className={clsx(
              "flex justify-end gap-4 pt-6",
              "rounded-b-xl bg-zinc-100"
            )}
          >
            <Button
              variant="outlined"
              onClick={props.onCancel}
              size="md"
              className="w-full sm:w-auto"
            >
              {t("translation:common:close")}
              <XMarkIcon
                className={clsx(
                  "h-5 w-5 text-zinc-500 group-hover/cancel-button:text-zinc-600"
                )}
              />
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="md"
              disabled={!props.form.formState.isDirty}
            >
              {t("translation:common:save")}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
