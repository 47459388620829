/**
 *
 *
 * <AvatarInitials />
 *
 *
 */
import React from "react";

import { classNames } from "../../utils";

type Size =
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "xxxl"
  | "post"
  | "commentor"
  | "leaderboard";

interface Props {
  char1: string;
  char2?: string;
  size?: Size;
  isLoading?: boolean;
  className?: string;
}

// Same sizes as AvatarStack and Avatar, with the addition of xl and xxl.
const sizeMap: Record<Size, string> = {
  sm: "h-1.5 w-1.5 md:h-3 md:w-3 text-[10px]",
  md: "h-8 w-8 text-xs",
  lg: "h-8 w-8 md:h-12 md:w-12 text-sm",
  xl: "h-12 w-12 md:h-16 md:w-16 text-3xl",
  xxl: "h-28 w-28 md:h-32 md:w-32 text-4xl",
  xxxl: "h-36 w-36 md:h-44 md:w-44 text-4xl",
  post: "h-9 w-9",
  leaderboard: "h-16 w-16",
  commentor: "w-6 h-6",
};

/**
 * A round avatar created from the first letter of `character`. Useful as
 * a placeholder when an image is unavailable.
 */
export const AvatarInitials = ({
  char1,
  char2 = "",
  size = "md",
  isLoading,
  className = "",
}: Props) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded-full text-white",
        isLoading ? "animate-pulse bg-zinc-300" : "bg-zinc-400",
        sizeMap[size],
        className
      )}
    >
      {isLoading
        ? null
        : `${String(char1.substring(0, 1).toUpperCase())}${String(
            char2.substring(0, 1).toUpperCase()
          )}`}
    </div>
  );
};
