/**
 *
 *
 * splits
 *
 * Define splits here for local development.
 */

// Use the same names as the splits in Split.io in a union type, e.g.: "MySplit | MyOtherSplit".
export type Split = "v3";

export const SplitMockData: Record<Split, string> = {
  v3: "on",
};
