import { usePeopleRetrieve } from "../../services/teambuilder/endpoints/people/people";

/**
 *
 *
 * useContactInfoForm
 *
 *
 */
interface Props {
  userId: number;
  onCancel: () => void;
}

export const useContactInfoForm = (props: Props) => {
  const { data: user } = usePeopleRetrieve(props.userId, {
    query: {
      enabled: Boolean(props.userId),
    },
  });

  return {
    user,
    userId: props.userId,
    onCancel: props.onCancel,
  };
};
