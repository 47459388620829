/**
 *
 *
 * <CreateChallenge />
 *
 *
 */
import { CreateChallengeButton } from "../../components/CreateChallengeButton";
import { useChallenges } from "./useChallenges";

export const CreateChallenge = (props: ReturnType<typeof useChallenges>) => {
  if (!props.isAdmin) return null;
  return (
    <div className="flex flex-row justify-end px-10">
      <CreateChallengeButton />
    </div>
  );
};
