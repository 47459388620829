/**
 *
 *
 * <SlideOverFormInputGroup />
 *
 *
 */
import React from "react";

import { classNames } from "../../utils";
import { SlideOverFormGroup } from "../SlideOverFormGroupV3";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  label: string;
  tooltip?: React.ReactNode;
  errorMessage?: string;
}

/**
 * An input with a label to the left.
 * Useful for including in SlideOverForm.
 *
 * @example
 * <SlideOver ... >
 *   <SlideOverHeader ... />
 *   <SlideOverForm ... >
 *     <SlideOverFormInputGroup ... />
 *   </SlideOverForm>
 * </SlideOver>
 */
const SlideOverFormInputGroup = React.forwardRef<HTMLInputElement, Props>(
  (
    { id, label, tooltip, errorMessage, className = "", ...props }: Props,
    ref
  ) => {
    return (
      <SlideOverFormGroup
        label={label}
        htmlFor={id}
        tooltip={tooltip}
        className={classNames("items-center", className)}
        errorMessage={errorMessage}
      >
        <input
          type="text"
          id={id}
          ref={ref}
          className="block h-10 w-full rounded-md border border-zinc-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          {...props}
        />
      </SlideOverFormGroup>
    );
  }
);

SlideOverFormInputGroup.displayName = "SlideOverFormInputGroupV3";

export { SlideOverFormInputGroup };
