/**
 *
 *
 * <PostModal />
 *
 *
 */
import {
  ChevronRightIcon,
  LinkIcon,
  PhotoIcon,
} from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Modal } from "../../components/ModalV3";
import { _PeopleResponse } from "../../services/teambuilder/schemas";
import { getTranslation } from "../../utils/translation";
import { SelectUser } from "../SelectUser";
import { usePostAttachmentModal } from "./usePostAttachmentModal";

export enum PostFeatureType {
  SHOUTOUT = "shoutout",
  IMAGE = "image",
  LINK = "link",
}

export const PostAttachmentModal = (
  props: ReturnType<typeof usePostAttachmentModal>
) => {
  const { t } = useTranslation();
  return (
    <Modal
      isTop
      isOpen={props.isOpen}
      onClose={props.onClose}
      disableOutsideClick
      className={clsx(
        "w-screen rounded-xl bg-white",
        props.isChallengeComplete ? "max-w-md" : " max-w-prose"
      )}
      showCloseButton={false}
    >
      <input
        className="hidden"
        type="file"
        onChange={props.onFileAdded}
        ref={props.inputFileRef}
        accept="image/jpeg,image/png,image/webp,image/tiff,image/tif,image/gif,image/heic,image/heif,application/pdf"
      />
      <form onSubmit={props.onHandleSubmit}>
        <div
          className={clsx(
            "flex flex-row gap-x-2 sm:gap-x-6",
            props.isChallengeComplete ? "p-4 sm:p-6" : "p-3 sm:p-6"
          )}
        >
          <div className="min-w-0 flex-1">
            <div className="relative">
              <div className="mb-6 sm:mb-4">
                {/*
                 * ShoutOut
                 */}
                {props.mode === PostFeatureType.SHOUTOUT && (
                  <div className="space-y-5 sm:space-y-4">
                    <div className="flex flex-row gap-x-2 sm:gap-x-4">
                      <div
                        className={clsx(
                          "h-7 w-7 rounded-full sm:h-10 sm:w-10",
                          "flex flex-shrink-0 items-center justify-center",
                          "text-white",
                          "bg-fuchsia-950 hover:shadow disabled:opacity-60"
                        )}
                      >
                        <MegaphoneIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      </div>
                      <div className="text-sm text-zinc-500">
                        <div className="mb-1 text-base font-semibold text-fuchsia-950">
                          {t("translation:comment:shoutout")}
                        </div>
                        {t("translation:post:shoutout_celebrate_message_v3")}
                      </div>
                    </div>
                    <div className="w-full space-y-4 pl-0 sm:pl-14">
                      <div>
                        <label
                          htmlFor="recipient"
                          className="mb-2 block text-sm font-medium leading-6"
                        >
                          {t("translation:post:search_shoutout_recipients")}
                        </label>
                        <SelectUser
                          setValue={(user: _PeopleResponse) => {
                            props.setSelectedRecipient({
                              ...user,
                              email: user.email as string,
                            });
                          }}
                          excludeIds={props.me?.id ? [props.me.id] : []}
                          placeholder={t(
                            "translation:post:search_your_colleagues"
                          )}
                        />
                      </div>

                      <div className="w-full">
                        <label
                          htmlFor="value"
                          className="mb-2 block text-sm font-medium leading-6"
                        >
                          {t("translation:post:select_company_value")}
                        </label>
                        <select
                          id="value"
                          className={clsx(
                            "block w-full rounded-md border-0 py-1.5 pl-3 pr-10 sm:py-2",
                            "ring-1 ring-inset ring-zinc-300 focus:ring-2 focus:ring-zinc-300",
                            "disabled:cursor-not-allowed sm:text-sm sm:leading-6"
                          )}
                          disabled={!props.values?.length}
                          onChange={(e) => {
                            props.setSelectedValueId(Number(e.target.value));
                          }}
                          value={props.selectedValueId}
                        >
                          <option value={0}></option>
                          {props.values?.map((value) => (
                            <option key={Math.random()} value={value.id}>
                              {value.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {/*
                 * Image editor
                 */}
                {props.mode === PostFeatureType.IMAGE &&
                  (props.previewImageUrl || props.isHeicToJpg) && (
                    <div className="space-y-5 sm:space-y-4">
                      <div className="flex flex-row gap-x-2 sm:gap-x-4">
                        <div
                          className={clsx(
                            "h-7 w-7 rounded-full sm:h-10 sm:w-10",
                            "flex flex-shrink-0 items-center justify-center",
                            "text-white",
                            "bg-green-950 hover:shadow disabled:opacity-60"
                          )}
                        >
                          <PhotoIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                        </div>
                        <div className="text-sm text-zinc-500">
                          <div className="mb-1 text-base font-semibold text-green-950">
                            {t("translation:post:image")}
                          </div>
                          {t("translation:post:attach_image")}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="relative mt-4 overflow-hidden rounded-lg">
                          {props.previewImageUrl ? (
                            <img
                              src={props.previewImageUrl}
                              alt=""
                              aria-hidden="true"
                            />
                          ) : (
                            <div
                              className={clsx(
                                "h-32 w-full cursor-pointer rounded-lg",
                                "border border-dashed border-zinc-300",
                                "flex items-center justify-center"
                              )}
                            >
                              <PhotoIcon className="h-6 w-6 text-zinc-300" />
                            </div>
                          )}
                          {props.isHeicToJpg && (
                            <div
                              className={clsx(
                                "absolute left-0 top-0",
                                "h-full w-full",
                                "full rounded-md",
                                "flex flex-col items-center justify-center text-center",
                                "opacity-60",
                                "bg-white"
                              )}
                            >
                              <ArrowPathIcon className="block h-8 w-8 animate-spin" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {/*
                 * URL Preview
                 */}
                {props.mode === PostFeatureType.LINK && (
                  <div className="space-y-5 sm:space-y-4">
                    <div className="flex flex-row gap-x-2 sm:gap-x-4">
                      <div
                        className={clsx(
                          "h-7 w-7 rounded-full sm:h-10 sm:w-10",
                          "flex flex-shrink-0 items-center justify-center",
                          "text-white",
                          "bg-blue-950 hover:shadow disabled:opacity-60"
                        )}
                      >
                        <LinkIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      </div>
                      <div className="w-full">
                        <div className="text-sm text-zinc-500">
                          <div className="mb-1 text-base font-semibold text-blue-950">
                            {t("translation:post:link")}
                          </div>
                          {t("translation:post:attach_url")}
                        </div>
                        <div className="mt-4 w-full">
                          <label
                            htmlFor="url"
                            className={clsx(
                              "flex flex-shrink-0 items-center text-sm font-medium leading-6 text-zinc-900",
                              "mb-2"
                            )}
                          >
                            {t("translation:post:url")}
                          </label>
                          <input
                            id="url"
                            type="text"
                            placeholder="https://example.com"
                            className={clsx(
                              "block w-full rounded-md border-0 py-1.5",
                              "text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-500",
                              "focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6"
                            )}
                            {...props.form.register("url")}
                          />
                          {props.form.formState.errors.url && (
                            <p className={clsx("mt-2 text-sm text-red-500")}>
                              {getTranslation(
                                t,
                                props.form.formState.errors.url.message
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/*
               *
               * Footer
               *
               */}
              <div className="flex flex-col-reverse justify-end gap-x-4 gap-y-3 sm:flex-row">
                <Button
                  variant="outlined"
                  size="md"
                  disabled={props.isHeicToJpg}
                  onClick={props.onCancel}
                  className="hidden sm:block"
                >
                  {t("translation:common:cancel")}
                </Button>
                <Button
                  variant="outlined"
                  size="md"
                  disabled={props.isHeicToJpg}
                  onClick={props.onCancel}
                  className="block sm:hidden"
                >
                  {t("translation:common:cancel")}
                </Button>
                <Button
                  variant={props.mode}
                  type="submit"
                  size="md"
                  disabled={props.isNextDisabled}
                >
                  {t("translation:common:next")}
                  <ChevronRightIcon className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
