/**
 *
 *
 * <Pagination />
 *
 *
 */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import React, { useEffect } from "react";

import { DOTS, usePagination } from "../../hooks/usePagination";

interface Props {
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (page: number | string) => void;
}

export const Pagination = ({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  onPageChange,
}: Props) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  // If pagination range length are less than 2 we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6">
      <div>
        <nav
          className="isolate inline-flex h-8 -space-x-px rounded-md text-slate-500 shadow-sm"
          aria-label="Pagination"
        >
          {/* Left navigation arrow */}
          <button
            className={clsx(
              "relative inline-flex w-11 items-center rounded-l-md",
              "border border-slate-300 px-2 py-2 text-sm font-medium hover:bg-slate-50 focus:z-20",
              currentPage === 1
                ? "pointer-events-none disabled:opacity-25"
                : "cursor-pointer"
            )}
            onClick={onPrevious}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>

          {paginationRange.map((pageNumber, index) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <span
                  key={`dot-${index}`}
                  className={clsx(
                    "relative inline-flex w-8 items-end border border-slate-300",
                    "px-2 py-2 text-sm font-medium hover:bg-slate-50 focus:z-20"
                  )}
                >
                  &#8230;
                </span>
              );
            }

            // Render our Page Pills
            return (
              <button
                key={`pagination-item-${pageNumber}`}
                className={clsx(
                  "relative inline-flex w-8 items-center justify-center",
                  "border border-slate-300 px-4 py-2 text-sm font-medium hover:bg-slate-50 focus:z-20",
                  currentPage === pageNumber
                    ? "pointer-events-none z-10 border border-slate-300 bg-slate-100"
                    : "cursor-pointer"
                )}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            );
          })}
          {/*  Right Navigation arrow */}
          <button
            className={clsx(
              "relative inline-flex w-11 items-center",
              "rounded-r-md border border-slate-300 px-2 py-2",
              "justify-end text-sm font-medium hover:bg-slate-50 focus:z-20",
              currentPage === lastPage
                ? "pointer-events-none disabled:opacity-25"
                : "cursor-pointer"
            )}
            onClick={onNext}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
};
