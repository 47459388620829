/**
 *
 *
 * <ChallengeList />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { SkeletonChallengeHorizontalListItem } from "../../components/SkeletonChallengeHorizontalListItem";
import { TabKey } from "../../containers/ChallengeTabs";
import { Challenge, Template } from "../../services/teambuilder/schemas";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../Carousel";
import { SkeletonChallengeVerticalListItem } from "../SkeletonChallengeVerticalListItem";
import { ChallengeHorizontalListItem } from "./ChallengeHorizontalListItem";
import { ChallengeVerticalListItem } from "./ChallengeVerticalListItem";

interface Props {
  challenges?: Challenge[] | Template[];
  isLoadingChallenges?: boolean;
  isChallengesError?: boolean;
  section?: TabKey;
  className?: string;
  onTemplatePreview?: (id: number) => void;
  onSelect?: (challenge: Challenge | Template) => void;
  selectedId?: number;
  isListMode?: boolean;
  allowCarousel?: boolean;
}

export const ChallengeList = ({
  challenges,
  isLoadingChallenges,
  isChallengesError,
  section,
  className,
  onTemplatePreview,
  onSelect,
  selectedId,
  isListMode,
  allowCarousel = true,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ul
      role="list"
      className={clsx(
        "gap-4",
        className,
        isLoadingChallenges && !isListMode && "px-4"
      )}
    >
      {isLoadingChallenges ? (
        isListMode ? (
          <SkeletonChallengeHorizontalListItem />
        ) : (
          <>
            <SkeletonChallengeVerticalListItem />
            <SkeletonChallengeVerticalListItem />
            <SkeletonChallengeVerticalListItem />
          </>
        )
      ) : isChallengesError ? (
        <div>{t("translation:challenge:loading_issue")}</div>
      ) : !challenges?.length ? (
        <p className="text-center text-lg text-zinc-500">
          {section === "current" || section === undefined
            ? t("translation:challenge:completed_challenges")
            : section === "upcoming"
              ? t("translation:challenge:no_upcoming_challenges")
              : section === "ended"
                ? t("translation:challenge:fill_up_soon")
                : section === "templates"
                  ? t("translation:challenge:no_templates_found_v3")
                  : undefined}
        </p>
      ) : challenges?.length === 1 ? (
        <ChallengeHorizontalListItem
          section={section}
          key={0}
          challenge={challenges[0]}
          onTemplatePreview={onTemplatePreview}
          onSelect={onSelect}
          selectedId={selectedId}
        />
      ) : challenges?.length === 2 ? (
        challenges?.map((challenge, i) => (
          <ChallengeVerticalListItem
            onTemplatePreview={onTemplatePreview}
            onSelect={onSelect}
            selectedId={selectedId}
            section={section}
            key={i}
            challenge={challenge}
          />
        ))
      ) : allowCarousel ? (
        <Carousel className="w-full">
          <CarouselPrevious className="flex w-8 items-center justify-center bg-zinc-100" />
          <CarouselContent className="w-full items-center gap-x-6 py-4">
            {challenges?.map((challenge, i) => (
              <CarouselItem
                key={challenge.id}
                className={clsx("w-fit overflow-hidden rounded-lg")}
              >
                <ChallengeVerticalListItem
                  onTemplatePreview={onTemplatePreview}
                  onSelect={onSelect}
                  selectedId={selectedId}
                  section={section}
                  key={i}
                  challenge={challenge}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext className="flex w-8 items-center justify-center bg-zinc-100" />
        </Carousel>
      ) : (
        challenges?.map((challenge, i) => (
          <ChallengeVerticalListItem
            onTemplatePreview={onTemplatePreview}
            onSelect={onSelect}
            selectedId={selectedId}
            section={section}
            key={i}
            challenge={challenge}
          />
        ))
      )}
    </ul>
  );
};
