/**
 *
 *
 * <ImagePreviewModal />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import React from "react";

import { Modal } from "../Modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  image: string;
}

export const ImagePreviewModal = (props: Props) => {
  const cancelButtonRef = React.useRef(null);

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} ref={cancelButtonRef}>
      <Dialog.Panel>
        <img src={props.image} alt="Preview" className="" />
      </Dialog.Panel>
    </Modal>
  );
};
