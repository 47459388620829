/**
 *
 *
 * <PhoneNumberList />
 *
 *
 */
import { PhoneIcon, PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { SkeletonPhoneNumber } from "../../components/SkeletonPhoneNumberV3";
import { PhoneNumberForm } from "../PhoneNumberFormV3";
import { usePhoneNumberList } from "./usePhoneNumberList";

export const PhoneNumberList = (
  props: ReturnType<typeof usePhoneNumberList>
) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:user_guide:phone_numbers");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="space-y-6">
          <SkeletonPhoneNumber />
          <Button variant="skeleton" size="md">
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <>
          <div className="space-y-2">
            {props.phoneNumbers.map((phone) => (
              <PhoneNumberForm
                key={phone.key}
                data={phone}
                onSaveSuccess={props.onSaveSuccess}
                onDeleteSuccess={props.onDeleteSuccess}
              />
            ))}
          </div>
          <div className="mt-4">
            <Button
              size="md"
              variant="outlined"
              className="mt-2 h-full py-1 sm:py-2.5"
              onClick={(e) => {
                e.preventDefault();
                props.onAddPhoneNumber();
              }}
            >
              <PlusIcon className="h-5 w-5 text-zinc-500" />
              {t("translation:user_guide:add_new_phone_number")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
