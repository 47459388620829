/**
 *
 *
 * Upper case the first letter of a string.
 *
 *
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
