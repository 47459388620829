/**
 *
 *
 * <LanguageList />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageEnumLanguage } from "services/teambuilder/schemas";

import { Button } from "../../components/ButtonV3";
import { SkeletonPhoneNumber } from "../../components/SkeletonPhoneNumberV3";
import { LanguageSelect } from "../LanguageSelectV3";
import { useLanguageList } from "./useLanguageList";

export const LanguageList = (props: ReturnType<typeof useLanguageList>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:user_guide:add_new_language");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="space-y-6">
          <SkeletonPhoneNumber />
          <Button variant="skeleton" size="md">
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <>
          <ul className="mt-2 list-none space-y-4">
            {props.languages?.map((language) => (
              <li key={language.key} className="relative mt-2 flex gap-4">
                <div className="relative grow">
                  <LanguageSelect
                    setValue={(lang) =>
                      props.updateLanguage(language.key, lang || "")
                    }
                    disabledItems={
                      props.languages.map(
                        (lang) => lang.value
                      ) as LanguageEnumLanguage[]
                    }
                    defaultValue={language.value}
                  />
                </div>

                <Button
                  variant="ghost"
                  size="md"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onRemoveLanguage(language.key);
                  }}
                >
                  {t("translation:common:delete")}
                </Button>
              </li>
            ))}
          </ul>

          <div className="mt-4 flex flex-row gap-4">
            <Button
              size="md"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                props.onSave();
              }}
              disabled={!props.isUpdated}
            >
              {t("translation:common:save")}
            </Button>

            <Button
              size="md"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                props.onAddLanguage();
              }}
            >
              <PlusIcon className="h-5 w-5 text-zinc-400" />
              {t("translation:user_guide:add_new_language")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
