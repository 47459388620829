import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";

import { FallbackComponent } from "../FallbackComponent";

type Props = {
  children?: React.ReactNode;
  location?: string;
};

export const ErrorBoundarySuspense = (props: Props) => {
  return (
    <Sentry.ErrorBoundary
      onError={(error: Error, componentStack: string, eventId: string) => {
        Sentry.captureException(error, {
          extra: { location: props.location, componentStack, eventId },
        });
      }}
      fallback={<FallbackComponent />}
    >
      <Suspense>{props.children}</Suspense>
    </Sentry.ErrorBoundary>
  );
};
