/**
 *
 *
 * useCommentForm
 *
 *
 */
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactEditor } from "slate-react";
import { z } from "zod";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useMe } from "../../hooks/useMe";
import { usePostFeedListParams } from "../../hooks/usePostFeedList";
import { usePostModalContent } from "../../hooks/usePostModalContent";
import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import {
  useSocialPostsCommentsCreate,
  useSocialPostsCommentsRepliesCreate,
  useSocialPostsCommentsRepliesRebuttalsCreate,
  useSocialPostsCreate,
} from "../../services/teambuilder/endpoints/social/social";
import {
  Comment,
  CommentRequest,
  PostRequest,
  PostWrite,
  Rebuttal,
  RebuttalRequest,
  Reply,
  ReplyRequest,
  SimpleUserModel,
} from "../../services/teambuilder/schemas";
import {
  NavItemV3,
  OptimisticUpdateFn,
  PaginatedPostLikeList,
  PostType,
} from "../../types";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import {
  _isComment,
  _isPost,
  _isReply,
  isCommentType,
  isRebuttalType,
  isReplyType,
} from "../../utils/type-guards";
import { PostFeatureType } from "../PostAttachmentModal/PostAttachmentModal";

interface Props {
  post?: PostType;
  queryKey: readonly unknown[];
  onSubmit?: () => void;
  showToolbar?: boolean;
  scrollIntoView?: boolean;
  className?: string;
  // refetch post when create new comment
  detailQueryKey?: readonly unknown[];
}

export const schema = z.object({
  content: z
    .string()
    .min(1, { message: "translation:validation:type_little_something" })
    .max(5000, { message: "translation:validation:less_than_5000" }),
  url: z.union([z.string().url().nullish(), z.literal("")]),
  mentions: z.array(
    z.object({
      user: z.number(),
      contentText: z
        .string()
        .max(5000, { message: "translation:validation:less_than_5000" }),
      contentOffset: z.number(),
    })
  ),
});

export type FormData = z.infer<typeof schema>;

const defaultValues: FormData = {
  content: "",
  mentions: [],
};

export const useCommentForm = (props: Props) => {
  const { t } = useTranslation();
  const feedParams = usePostFeedListParams();
  const { openToast } = useToast();
  const { me, loading: isLoadingMe } = useMe();
  const [editor, setEditor] = useState<ReactEditor>();

  const isPost = !props.post;
  const isComments = props.post && _isPost(props.post);
  const isReplies = props.post && _isComment(props.post);
  const isRebuttals = props.post && _isReply(props.post);
  const postsPk = !props.post
    ? 0
    : isCommentType(props.post) ||
        isReplyType(props.post) ||
        isRebuttalType(props.post)
      ? (props.post.post as number)
      : (props.post.id as number);
  const commentsPk = !props.post
    ? 0
    : isCommentType(props.post)
      ? (props.post.id as number)
      : isReplyType(props.post) || isRebuttalType(props.post)
        ? (props.post.comment as number)
        : 0;
  const repliesPk = !props.post
    ? 0
    : isReplyType(props.post)
      ? (props.post.id as number)
      : isRebuttalType(props.post)
        ? (props.post.reply as number)
        : 0;
  const placeholderText = isComments
    ? t("translation:comment:add_your_comment")
    : isReplies
      ? t("translation:comment:add_your_reply")
      : isRebuttals
        ? t("translation:comment:add_your_reply")
        : t("translation:comment:add_your_thought");

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { values } = usePostModalContent();

  /**
   *
   *
   * Create requests
   *
   *
   */
  const optimisticUpdateFn: (options: {
    isPost?: boolean;
    // Newer posts at the top
    desc?: boolean;
  }) => OptimisticUpdateFn<PaginatedPostLikeList, { data: object }> =
    ({ isPost, desc }) =>
    (context, requestVariables) => ({
      meta: context.meta,
      data: [
        ...(!desc ? context.data : []),
        {
          id: Math.random(),
          user: me as SimpleUserModel,
          ...requestVariables?.data,
          // commentsCount prop existence is used in type guards
          ...(isPost && { commentsCount: 0 }),
        },
        ...(desc ? context.data : []),
      ],
    });

  const [clearEditor, setClearEditor] = useState(false);

  const onMutate = () => {
    props.onSubmit?.();
    reset();
    setClearEditor(true);
  };
  const showSuccessToast = (description: string) => () => {
    openToast({ title: t("translation:common:success"), description });
  };
  const showErrorToast = (description: string) => () => {
    openToast({
      title: t("translation:common:error"),
      description,
      type: "danger",
    });
  };

  /**
   * Create post request
   */
  const { mutate: createPost } = useSocialPostsCreate(
    optimisticMutationOptions<
      PostWrite,
      PaginatedPostLikeList,
      {
        data: PostRequest;
      }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: optimisticUpdateFn({ isPost: true, desc: true }),
      onMutate,
      onSuccess: showSuccessToast(t("translation:toast:post_add_success")),
      onError: showErrorToast(t("translation:toast:post_add_failed")),
    })
  );

  /**
   * Create comment request
   */
  const { mutate: createComment } = useSocialPostsCommentsCreate(
    optimisticMutationOptions<
      Comment,
      PaginatedPostLikeList,
      {
        postsPk: number;
        data: CommentRequest;
      }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: optimisticUpdateFn({ isPost: false, desc: true }),
      onMutate,
      onSuccess: showSuccessToast(t("translation:toast:comment_add_success")),
      onError: showErrorToast(t("translation:toast:comment_add_failed")),
      onSettled: () => {
        feedParams.refetch();
        // refech post when create new comment
        if (props.detailQueryKey) {
          queryClient.invalidateQueries(props.detailQueryKey);
        }
      },
    })
  );

  /**
   * Create reply request
   */
  const { mutate: createReply } = useSocialPostsCommentsRepliesCreate(
    optimisticMutationOptions<
      Reply,
      PaginatedPostLikeList,
      {
        postsPk: number;
        commentsPk: number;
        data: ReplyRequest;
      }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: optimisticUpdateFn({ isPost: false, desc: false }),
      onMutate,
      onSuccess: showSuccessToast(t("translation:toast:reply_add_success")),
      onError: showErrorToast(t("translation:toast:reply_add_failed")),
    })
  );

  /**
   * Create rebuttal request
   */
  const { mutate: createRebuttal } =
    useSocialPostsCommentsRepliesRebuttalsCreate(
      optimisticMutationOptions<
        Rebuttal,
        PaginatedPostLikeList,
        {
          postsPk: number;
          commentsPk: number;
          repliesPk: number;
          data: RebuttalRequest;
        }
      >({
        queryKey: props.queryKey,
        optimisticUpdateFn: optimisticUpdateFn({ isPost: false, desc: false }),
        onMutate,
        onSuccess: showSuccessToast(
          t("translation:toast:rebuttal_add_success")
        ),
        onError: showErrorToast(t("translation:toast:rebuttal_add_failed")),
      })
    );

  const onSubmit = (data: FormData) => {
    if (isPost) {
      createPost({ data: { ...data, team: TEAM_EVERYONE_ID } });
    } else if (isComments) {
      createComment({ postsPk, data });
    } else if (isReplies) {
      createReply({ postsPk, commentsPk, data });
    } else {
      createRebuttal({ postsPk, commentsPk, repliesPk, data });
    }
  };

  const onHandleSubmit = handleSubmit(onSubmit);

  const setPostValue = (data: FormData) => {
    setValue("content", data.content);
    setValue("mentions", data.mentions);
    setClearEditor(false);
  };

  const [featureMode, setFeatureMode] = useState<PostFeatureType>();
  const onOpenPostFeatureModal = (type: PostFeatureType) => {
    setFeatureMode(type);
  };

  const onCancel = () => setFeatureMode(undefined);

  const [previewImageUrl, setPreviewImageUrl] = useState<string>();
  const [selectedRecipient, setSelectedRecipient] = useState<SimpleUserModel>();
  const [selectedValueId, setSelectedValueId] = useState<number>();
  const [selectedValueLabel, setSelectedValueLabel] = useState<string>();
  const [selectedURL, setSelectedURL] = useState<string>();

  const onSetFeature = (data: {
    previewImage?: Blob;
    previewImageUrl?: string;
    recipient?: SimpleUserModel;
    valueId?: number;
    url?: string;
  }) => {
    if (featureMode === PostFeatureType.IMAGE) {
      if (data.previewImage) {
        setPreviewImageUrl(data.previewImageUrl);
      }
    }
    if (featureMode === PostFeatureType.SHOUTOUT) {
      setSelectedRecipient(data.recipient);
      setSelectedValueId(data.valueId);
      setSelectedValueLabel(
        values?.find((value) => value.id === selectedValueId)?.name
      );
    }
    if (featureMode === PostFeatureType.LINK) {
      setSelectedURL(data.url);
    }
    setFeatureMode(undefined);
    if (editor) {
      setTimeout(() => {
        ReactEditor.focus(editor);
      }, 1000);
    }
  };

  const onEditClick = () => {
    if (selectedRecipient) {
      setFeatureMode(PostFeatureType.SHOUTOUT);
      return;
    }
    if (selectedURL) {
      setFeatureMode(PostFeatureType.LINK);
      return;
    }
    if (previewImageUrl) {
      setFeatureMode(PostFeatureType.IMAGE);
      return;
    }
  };
  const onDeleteClick = (fromCancel: boolean = false) => {
    if (!fromCancel) {
      setPreviewImageUrl(undefined);
      setSelectedRecipient(undefined);
      setSelectedValueId(undefined);
      setSelectedURL(undefined);
    }
  };

  const menuItems: NavItemV3[] = [
    {
      name: t("translation:common:edit"),
      icon: PencilSquareIcon,
      onClick: onEditClick,
    },
    {
      name: t("translation:common:remove"),
      icon: XCircleIcon,
      onClick: () => onDeleteClick(false),
    },
  ];

  const hasFeature =
    !!previewImageUrl ||
    !!selectedRecipient ||
    !!selectedValueId ||
    !!selectedURL;

  return {
    me,
    isLoadingMe,
    register,
    errors,
    onHandleSubmit,
    showToolbar: props.showToolbar,
    isPost,
    isComments,
    isReplies,
    isRebuttals,
    className: props.className,
    setPostValue,
    clearEditor,
    placeholderText,
    scrollIntoView: props.scrollIntoView,

    onOpenPostFeatureModal,
    featureMode,
    onCancel,
    previewImageUrl,
    selectedRecipient,
    selectedValueId,
    selectedValueLabel,
    selectedURL,
    onSetFeature,
    menuItems,
    hasFeature,
    setEditor,
  };
};
