/**
 *
 *
 * <ChallengesLeaderboardList />
 *
 *
 */
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Avatar } from "../../components/AvatarV3";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/Carousel";
import { IconStar } from "../../components/IconStar";
import { SearchParam } from "../../consts";
import { ParticipantUserModel } from "../../services/teambuilder/schemas";
import { pluralize } from "../../utils/pluralize";
import { useChallengesLeaderboardList } from "./useChallengesLeaderboardList";

export const ChallengesLeaderboardList = (
  props: ReturnType<typeof useChallengesLeaderboardList>
) => {
  const { t } = useTranslation();
  if (!props.leaderboards?.meta.pagination.total) return null;

  return (
    <div className="animate-crossfadein bg-white">
      <div className="flex flex-row gap-x-4">
        <div className="flex h-9 w-fit items-center justify-center rounded-t-lg bg-zinc-100 px-4 font-medium text-zinc-500 sm:w-60">
          {t(
            `translation:challenge:${props.leaderboards?.meta.pagination.total > 1 ? "ns_participant" : "n_participant"}`,
            {
              n: props.leaderboards?.meta.pagination.total,
            }
          )}
        </div>
        {props.completions > 0 && (
          <Link
            to={`/challenges/${props.challengeId}?${SearchParam.SHOW_POST_DETAIL_MODAL}=${props.startPostId}`}
            className={clsx(
              "flex flex-row items-center gap-x-1",
              props.bounceAnim && "animate-pulse"
            )}
          >
            <ChatBubbleLeftRightIcon className="h-7 w-7 text-zinc-400" />
            <p className="text-base font-medium leading-7 text-zinc-600">
              {props.completions || 0}{" "}
              {t(
                `translation:challenge:${pluralize("completion", "completions", props.completions)}`
              )}
            </p>
          </Link>
        )}
      </div>
      <div className="overflow-hidden rounded-b-lg rounded-tr-lg bg-zinc-100">
        <Carousel className="w-full">
          <CarouselPrevious className="flex w-8 items-center justify-center bg-zinc-100" />
          <CarouselContent className="min-h-32 w-full items-center gap-x-6 py-6">
            {props.leaderboards?.data?.map((user: ParticipantUserModel) => (
              <CarouselItem
                key={user.id}
                className={clsx(
                  "flex flex-shrink-0 flex-col items-center gap-x-3",
                  "relative cursor-pointer"
                )}
                onClick={() => props.openProfileModal(user.id!)}
              >
                <div className="group relative">
                  <div className="relative h-24 w-24">
                    {user.completedActivities === props.activities && (
                      <IconStar />
                    )}
                    <div
                      className={clsx(
                        "h-[4.75rem] w-[4.75rem] rounded-full bg-white",
                        "z-1 group-hover:z-[3]",
                        "absolute left-1/2 top-11 -translate-x-1/2 -translate-y-1/2",
                        user.completedActivities !== props.activities &&
                          "shadow-lg"
                      )}
                    />
                  </div>
                  <div className="absolute left-1/2 top-3 h-16 w-16 -translate-x-1/2 group-hover:z-[4]">
                    <Avatar
                      user={user}
                      size="leaderboard"
                      className="relative group-hover:text-zinc-400"
                    />
                    <span
                      className={clsx(
                        "text-base font-semibold text-white group-hover:block",
                        "absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2"
                      )}
                    >
                      {user.completedActivities}/{props.activities}
                    </span>
                  </div>
                </div>
                <div
                  className={clsx(
                    "relative z-[2] -mt-5 h-5 rounded-full",
                    "border-2 border-white bg-zinc-200",
                    "flex w-full items-center"
                  )}
                >
                  <div
                    className={clsx(
                      "h-full p-1 text-xs font-bold uppercase text-white",
                      "rounded-full bg-zinc-600",
                      "flex items-center justify-center"
                    )}
                    style={{
                      width: `${((user.completedActivities || 0) / props.activities) * 100}%`,
                    }}
                  >
                    {user.completedActivities === props.activities &&
                      t("translation:challenge:completed")}
                  </div>
                </div>
              </CarouselItem>
            ))}
            <div ref={props.observerTarget} className="load-more" />
            {props.isLoading && (
              <div className="relative animate-pulse">
                <div className="relative h-24 w-24">
                  <div className="absolute left-1/2 top-11 h-[4.75rem] w-[4.75rem] -translate-x-1/2 -translate-y-1/2 rounded-full bg-zinc-200" />
                </div>
                <div
                  className={clsx(
                    "relative z-10 -mt-5 h-5 rounded-full",
                    "bg-zinc-200",
                    "flex items-center"
                  )}
                />
              </div>
            )}
          </CarouselContent>
          <CarouselNext className="flex w-8 items-center justify-center bg-zinc-100" />
        </Carousel>
      </div>
    </div>
  );
};
