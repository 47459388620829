/**
 *
 *
 * useLanguage
 *
 *
 */
import { useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

import { LANGUAGE_EN, LANGUAGE_ES } from "../../consts";
import { useMe } from "../../hooks/useMe";
import { useToast } from "../../hooks/useToast";
import {
  getPeopleMeRetrieveQueryKey,
  usePeoplePartialUpdate,
} from "../../services/teambuilder/endpoints/people/people";
import {
  PatchedPeopleRequestRequest,
  PatchedPeopleRequestRequestLanguage,
  _PeopleResponse,
} from "../../services/teambuilder/schemas";
import { NavItemV3 } from "../../types";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

type Props = {
  isCollapsed?: boolean;
};

export const useLanguage = (props: Props) => {
  const { openToast } = useToast();
  const { t, i18n } = useTranslation();
  const { me } = useMe();
  const queryKey = getPeopleMeRetrieveQueryKey();

  const languages = useMemo(
    () => [
      {
        language: LANGUAGE_EN,
        countryCode: "US",
        name: t("translation:language:english"),
      },
      {
        language: LANGUAGE_ES,
        countryCode: "ES",
        name: t("translation:language:spanish"),
      },
    ],
    [i18n.language]
  );
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  useEffect(() => {
    if (
      me?.language &&
      languages.find((lang) => lang.language === me.language?.language)
    ) {
      i18n.changeLanguage(me?.language.language);
      setSelectedLanguage(
        languages.filter((lang) => lang.language === me?.language?.language)[0]
      );
      return;
    }
    const language = navigator.language.match(/^es/)
      ? LANGUAGE_ES
      : LANGUAGE_EN;
    i18n.changeLanguage(language);
    setSelectedLanguage(
      languages.filter((lang) => lang.language === language)[0]
    );
  }, [me?.language, languages]);

  /**
   *
   *
   * Create requests
   *
   *
   */
  const onSuccess = () => {
    openToast({
      title: t("translation:toast:language_update_success"),
      type: "success",
    });
  };
  const onError = () => {
    openToast({
      title: t("translation:common:error"),
      description: t("translation:toast:language_update_failed"),
      type: "danger",
    });
  };

  const { mutate: updateUserProfile } = usePeoplePartialUpdate(
    optimisticMutationOptions<
      _PeopleResponse,
      _PeopleResponse,
      {
        id: number;
        data: PatchedPeopleRequestRequest;
      }
    >({
      queryKey,
      optimisticUpdateFn: (context, requestVariables) => ({
        ...context,
        language: requestVariables?.data.language,
      }),
      onSuccess,
      onError,
    })
  );

  const onUpdate = (language: string) => {
    if (me?.id) {
      if (me.language?.language === language) return;
      updateUserProfile({
        id: me.id,
        data: { language: { language } as PatchedPeopleRequestRequestLanguage },
      });
    }
  };

  const languageNavItems: NavItemV3[] = useMemo(
    () =>
      languages.map((language) => {
        return {
          name: language.name,
          onClick: () => onUpdate(language.language),
          isActive: language.language === me?.language?.language,
          icon: () => (
            <ReactCountryFlag
              countryCode={language.countryCode}
              aria-label={language.name}
              className="mr-2 h-5 w-5"
              style={{ fontSize: "1.2rem" }}
            />
          ),
        };
      }),
    [me?.language, languages]
  );

  return {
    me,
    languages,
    selectedLanguage,
    onUpdate,
    isCollapsed: props.isCollapsed,
    languageNavItems,
  };
};
