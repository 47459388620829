/**
 *
 *
 * Axios instance for the teambuilder service
 *
 *
 */
import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { redirectToAuth } from "../../utils/redirect-to-auth";

export interface CancellablePromise<T> extends Promise<T> {
  cancel: (reason: string) => void;
}

export const AXIOS_INSTANCE = axios.create({
  baseURL: import.meta.env.VITE_TEAMBUILDER_API_DOMAIN,
});

export const axiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): CancellablePromise<T> => {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);

  // If the `token` search param is set, we were redirected from the auth service after login.
  // in this case, we don't want to send the drfToken, since we received an auth service token.
  const drfToken =
    pathname === "/auth" && searchParams.has("token")
      ? null
      : localStorage.getItem(import.meta.env.VITE_LOCALSTORAGE_AUTH_TOKEN_KEY);

  /**
   *
   * Set headers
   *
   */
  config.headers = config.headers || {};
  config.headers["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (drfToken) {
    // drfToken uses `Token` auth
    config.headers["Authorization"] = `Token ${drfToken}`;
  }
  const optionsHeaders = options?.headers || {};
  // Options overrides config completely. We want to override headers one at a time.
  if (options && optionsHeaders) delete options.headers;
  config.headers = { ...config.headers, ...optionsHeaders };

  const controller = new AbortController();

  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    signal: controller.signal,
  })
    .then(({ data }) => data)
    .catch((err) => {
      if (err.response?.status === 401) {
        redirectToAuth();
      }
      throw err;
    }) as CancellablePromise<T>;

  promise.cancel = (reason = "Query was cancelled") => {
    controller.abort(reason);
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
