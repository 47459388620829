/**
 *
 *
 * PrimaryPostBody
 *
 *
 *
 */
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ChallengeVerticalListItem } from "../../components/ChallengeListV3/ChallengeVerticalListItem";
import { SearchParam } from "../../consts";
import { Challenge, Post } from "../../services/teambuilder/schemas";
import { capitalize } from "../../utils/capitalize";
import { isSystemPostWithActionDetail } from "../../utils/type-guards";
import { fullName } from "../../utils/username";
import { PostContent } from "./PostContent";

interface Props {
  post: Post;
  openImagePreview?: () => void;
}

export const PrimaryPostBody = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="space-y-2 text-sm leading-relaxed">
      {props.post.action === "shouted out to" ? (
        <>
          🎉{" "}
          <Trans
            i18nKey={
              props.post.actionDetail
                ? "translation:post:shoutout_exhibiting"
                : "translation:post:shoutout_to"
            }
            values={{
              name: fullName(props.post.recipient),
              action: props.post.actionDetail || "",
            }}
            components={{
              a: (
                <Link
                  to={`/people?${SearchParam.PROFILE}=${props.post.recipient?.id}`}
                  className="font-semibold text-zinc-500 underline hover:text-zinc-600"
                />
              ),
              b: <span className="font-medium" />,
            }}
          />{" "}
          🎉
        </>
      ) : props.post.action === "started the challenge" ? (
        <>
          <p className="mb-6 text-sm font-normal text-zinc-900">
            🎉 {t("translation:challenge:challenge_started")} 🎉
          </p>
          <p className="mb-4 text-sm font-normal text-zinc-900">
            {t("translation:challenge:get_badge")}
          </p>
          <p className="mb-4 text-sm font-normal italic text-zinc-500">
            {t("translation:challenge:get_badge_description")}
          </p>
          <ul>
            <ChallengeVerticalListItem
              isPost
              section={props.post.challenge?.isEnded ? "ended" : "current"}
              challenge={props.post.challenge as Challenge}
            />
          </ul>
        </>
      ) : props.post.action === "created the team" ? (
        <>
          {t("translation:post:created_team", {
            name: fullName(props.post.user),
          })}{" "}
          <span className="font-medium">{props.post.content}</span>.
        </>
      ) : props.post.action === "created the department" ? (
        <>
          {t("translation:post:created_department", {
            name: fullName(props.post.user),
          })}{" "}
          <span className="font-medium">{props.post.content}</span>.
        </>
      ) : props.post.action === "created the location" ? (
        <>
          {t("translation:post:created_location", {
            name: fullName(props.post.user),
          })}{" "}
          <span className="font-medium">{props.post.content}</span>.
        </>
      ) : props.post.action === "completed a challenge activity" ? (
        <>
          {t("translation:post:completed_challenge", {
            name: fullName(props.post.user),
          })}{" "}
          <span className="font-medium">{props.post.content}</span>.
        </>
      ) : isSystemPostWithActionDetail(props.post.action) ? (
        <>
          {props.post.action ? capitalize(props.post.action) : null}{" "}
          <span className="font-medium">{props.post.actionDetail}</span>
        </>
      ) : null}

      {props.post.content && isSystemPostWithActionDetail(props.post.action)
        ? null
        : props.post.content && <PostContent post={props.post} isPrimary />}

      {props.post.image && (
        <div className="group flex flex-col justify-center overflow-hidden rounded-lg border border-zinc-100 hover:cursor-pointer hover:border-zinc-300">
          {props.post.url ? (
            <a
              href={props.post.url}
              target="_blank"
              rel="noreferrer"
              className="block break-all"
            >
              <div className="flex w-full justify-center">
                <img
                  src={props.post.image.medium}
                  alt="post"
                  className="block max-h-96"
                />
              </div>
              <div
                className={clsx(
                  "bg-zinc-100 p-4 text-sm font-medium text-sky-500",
                  "group-hover:underline group-hover:shadow"
                )}
              >
                {props.post.url}{" "}
                <ArrowTopRightOnSquareIcon className="inline-block h-5 w-5" />
              </div>
            </a>
          ) : (
            <div
              className="group relative flex w-full"
              onClick={() =>
                props.post.image &&
                props.openImagePreview &&
                props.openImagePreview()
              }
            >
              <img
                src={props.post.image.medium}
                alt="post"
                className="block max-h-96"
              />
              <ArrowsPointingOutIcon
                className={clsx(
                  "absolute right-2 top-2 h-6 w-6 text-zinc-300",
                  "opacity-0 group-hover:opacity-100"
                )}
              />
            </div>
          )}
        </div>
      )}

      {props.post.video && (
        <div className="flex w-full justify-center rounded-lg">
          <video
            controls={true}
            data-testid="video"
            className="max-h-96 w-full rounded-lg"
          >
            <source src={props.post.video || ""} type="video/mp4" />
          </video>
        </div>
      )}

      {props.post.url && !props.post.image && (
        <a
          href={props.post.url}
          target="_blank"
          rel="noreferrer"
          className="break-all text-sm text-sky-500 hover:text-sky-600"
        >
          {props.post.url}{" "}
          <ArrowTopRightOnSquareIcon className="inline-block h-5 w-5" />
        </a>
      )}
    </div>
  );
};
