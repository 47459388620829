/**
 *
 *
 * <DomainList />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { SkeletonDomain } from "../../components/SkeletonDomain";
import { DomainForm } from "../DomainFormV3";
import { useDomainList } from "./useDomainList";

export const DomainList = (props: ReturnType<typeof useDomainList>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:settings:company:add_new_domain");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="mt-4 space-y-6">
          <SkeletonDomain />
          <Button variant="skeleton" size="md">
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <div className="mt-2">
          <div className="space-y-2">
            {props.domains?.map((domain) => (
              <DomainForm
                key={domain.id}
                domain={domain}
                queryKey={props.domainsQueryKey}
              />
            ))}
          </div>
          <div className="mb-8 mt-4 flex flex-col sm:flex-row">
            <Button
              variant="contained"
              size="md"
              type="button"
              onClick={props.addUnsavedDomain}
            >
              <PlusIcon className="h-6 w-6" /> {submitButtonText}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
