/**
 *
 *
 * <TRow />
 *
 *
 */
import React from "react";

interface Props {
  children?: React.ReactNode;
}

export const Quote = ({ children }: Props) => {
  return (
    <div className="pointer-events-none mt-2 select-none border-l-4 border-slate-300 pl-2">
      {children}
    </div>
  );
};
