/**
 *
 *
 * useProfile
 *
 *
 */
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { SearchParam, TEAM_EVERYONE_ID } from "../../consts";
import { useMe } from "../../hooks/useMe";
import {
  useIntuitionCustomersCompaniesMeRetrieve,
  useIntuitionLanguagesList,
} from "../../services/teambuilder/endpoints/intuition/intuition";
import { usePeopleRetrieve } from "../../services/teambuilder/endpoints/people/people";
import {
  getUserguidesAnswersListQueryKey,
  getUserguidesQuestionsListQueryKey, // getUserguidesRetrieveQueryKey,
  useUserguidesAnswersList, // useUserguidesPartialUpdate,
  useUserguidesPhonesList,
  useUserguidesQuestionsList,
  useUserguidesRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  QuestionModel, // UserGuides,
  UserguidesQuestionsListParams,
} from "../../services/teambuilder/schemas";

export const useProfileModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Modal visibility
   */
  const isOpen = searchParams.has(SearchParam.PROFILE);
  const close = () => {
    searchParams.delete(SearchParam.PROFILE);
    setSearchParams(searchParams);
  };

  /**
   * Form toggles
   */
  const openForm = (param: SearchParam) => () => {
    searchParams.set(param, "1");
    setSearchParams(searchParams);
  };
  const closeForm = (param: SearchParam) => () => {
    searchParams.delete(param);
    setSearchParams(searchParams);
  };

  const isProfileFormOpen = searchParams.has(SearchParam.SHOW_PROFILE_FORM);
  const openProfileForm = openForm(SearchParam.SHOW_PROFILE_FORM);
  const closeProfileForm = closeForm(SearchParam.SHOW_PROFILE_FORM);

  const isContactFormOpen = searchParams.has(SearchParam.SHOW_CONTACT_FORM);
  const openContactForm = openForm(SearchParam.SHOW_CONTACT_FORM);
  const closeContactForm = closeForm(SearchParam.SHOW_CONTACT_FORM);

  const isAnswersFormOpen = searchParams.has(SearchParam.SHOW_ANSWERS_FORM);
  const openAnswersForm = openForm(SearchParam.SHOW_ANSWERS_FORM);
  const closeAnswersForm = closeForm(SearchParam.SHOW_ANSWERS_FORM);

  /**
   * User requests
   */
  const { me, loading: isLoadingMe } = useMe();

  const _userId = searchParams.get(SearchParam.PROFILE);
  const userId = useMemo(() => {
    return _userId === "me" && me ? me.id || 0 : Number(_userId);
  }, [me, _userId]);

  const { data: user, isLoading: isLoadingUser } = usePeopleRetrieve(userId);

  const userIsMe = useMemo(
    () => Number(me?.id) === Number(user?.id),
    [me, user]
  );

  /**
   * Company request
   */
  const { data: company } = useIntuitionCustomersCompaniesMeRetrieve();

  /**
   * Languages request
   */
  const { data: allLanguages, isLoading: isLanguagesLoading } =
    useIntuitionLanguagesList({
      limit: 999,
    });

  /**
   * phones
   */
  const { data: phones, isLoading: isLoadingPhoneNumbers } =
    useUserguidesPhonesList(
      {
        limit: 999,
        user: userId,
      },
      {
        query: {
          enabled: Boolean(userId),
        },
      }
    );

  /**
   * Do not show forms if the user is not me.
   */
  useEffect(() => {
    if (user && me && !userIsMe) {
      searchParams.delete(SearchParam.SHOW_PROFILE_FORM);
      searchParams.delete(SearchParam.SHOW_CONTACT_FORM);
      searchParams.delete(SearchParam.SHOW_ANSWERS_FORM);
      setSearchParams(searchParams);
    }
  }, [userIsMe, user, me]);

  /**
   *
   * User Guide request
   *
   */
  const {
    data: userGuide,
    isLoading: isLoadingUserGuide,
    // refetch: _refetchUserGuide,
  } = useUserguidesRetrieve(Number(user?.userGuideId), {
    query: {
      enabled: Boolean(user?.userGuideId),
    },
  });

  /**
   *
   * Questions and Answers
   *
   */
  const questionsListParams: UserguidesQuestionsListParams = {
    team: TEAM_EVERYONE_ID,
    ordering: "-created_at",
    limit: 999,
  };
  const questionsListQueryKey =
    getUserguidesQuestionsListQueryKey(questionsListParams);
  const { data: questions, isLoading: isLoadingQuestions } =
    useUserguidesQuestionsList(questionsListParams, {
      query: {
        select: ({ data }) => data,
      },
    });

  const answersListQueryKey = useMemo(
    () => getUserguidesAnswersListQueryKey({ user: userId }),
    [userId]
  );
  const { data: answers, isLoading: isLoadingAnswers } =
    useUserguidesAnswersList(
      { user: userId },
      {
        query: {
          select: ({ data }) => data,
          enabled: userId > 0,
        },
      }
    );

  /**
   * All questions including answers by the current user.
   */
  const questionsAndAnswers = useMemo<QuestionModel[]>(() => {
    if (!questions) return [];

    return questions?.map((question) => {
      return {
        ...question,
        answers: answers?.filter((answer) => answer.question === question.id),
      };
    });
  }, [questions, answers]);

  /**
   * Only questions that have been answered by the current user.
   */
  const answeredQuestions =
    useMemo(
      () =>
        questionsAndAnswers.filter(
          (question) => Number(question.answers?.length) > 0
        ),
      [questionsAndAnswers]
    ) || [];

  /**
   * Patterns are hero patterns, saved as SVGs in tailwind.config.cjs.
   * See https://heropatterns.com/
   */
  const coverImagePattern = useMemo(() => {
    const patterns = [
      "bg-leaf-pattern",
      "bg-bubbles-pattern",
      "bg-volcano-lamp-pattern",
      "bg-autumn-pattern",
      "bg-random-shapes-pattern",
    ];
    return patterns[Number(user?.id) % patterns.length];
  }, [user]);

  return {
    isOpen,
    close,
    allLanguages: allLanguages?.data || [],
    isLoadingUser:
      isLoadingUser || isLoadingMe || isLanguagesLoading || isLoadingUserGuide,
    user,
    userIsMe,
    coverImagePattern,
    company,
    userGuide,
    isLoadingQA: isLoadingQuestions || isLoadingAnswers,
    questions,
    questionsAndAnswers,
    answeredQuestions,
    questionsListQueryKey,
    answersListQueryKey,
    phones: phones?.data,
    isLoadingPhoneNumbers,
    isProfileFormOpen,
    openProfileForm,
    closeProfileForm,
    isContactFormOpen,
    openContactForm,
    closeContactForm,
    isAnswersFormOpen,
    openAnswersForm,
    closeAnswersForm,
  };
};
