/**
 *
 *
 * <BirthdayForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { useBirthdayForm } from "./useBirthdayForm";

export const BirthdayForm = (props: ReturnType<typeof useBirthdayForm>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:common:save");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div>
          <SkeletonInputGroup />
          <Button variant="skeleton" size="md" className="mt-6" disabled>
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <>
          <form onSubmit={props.onHandleSubmit}>
            <div className="space-y-6">
              <Input
                id="startDate"
                label=""
                labelClassName="sr-only"
                type="date"
                min={new Date("1940/1/1").toJSON().slice(0, 10)}
                error={props.form.formState.errors.birthDate?.message}
                {...props.form.register("birthDate")}
              />
              <Button
                size="md"
                variant="contained"
                type="submit"
                disabled={!props.form.formState.isDirty}
                className="w-full sm:w-auto"
              >
                {submitButtonText}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
