/**
 *
 *
 * <ChallengeNotification />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { NotificationModel } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";

interface Props {
  message: NotificationModel;
}

export const ChallengeNotification = ({ message }: Props) => {
  return (
    <div className="text-sm">
      <div
        className={clsx(
          "mb-1 flex items-start gap-4",
          message.read ? "font-normal" : "font-medium"
        )}
      >
        <div className="flex-grow text-zinc-900">{message.subject}</div>
        <p className="text-xs leading-5 text-zinc-500">
          {dayjs(message.createdAt).fromNow()}
        </p>
      </div>

      <Link
        to={`/challenges/${message.contentObject?.Challenge?.id}`}
        className="font-medium text-zinc-500 underline hover:text-amber-700"
      >
        {message.message}
      </Link>
    </div>
  );
};
