/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  NotificationModel,
  NotificationModelRequest,
  NotificationPreference,
  NotificationsListParams,
  NotificationsMeListParams,
  NotificationsReadAllResponse,
  PaginatedNotificationModelList,
  PatchedNotificationModelRequest,
  PatchedNotificationPreferenceRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Notifications

Notifications can be delivered via app, email, SMS, and connect (e.g., slack/teams).

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const notificationsList = (
  params?: NotificationsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedNotificationModelList>(
    { url: `/api/v1/notifications/`, method: "GET", params, signal },
    options
  );
};

export const getNotificationsListQueryKey = (
  params?: NotificationsListParams
) => {
  return [`/api/v1/notifications/`, ...(params ? [params] : [])] as const;
};

export const getNotificationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsList>>
  > = ({ signal }) => notificationsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsList>>
>;
export type NotificationsListQueryError = ErrorType<unknown>;

export const useNotificationsList = <
  TData = Awaited<ReturnType<typeof notificationsList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Notifications

Notifications can be delivered via app, email, SMS, and connect (e.g., slack/teams).

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const notificationsCreate = (
  notificationModelRequest: NotificationModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<NotificationModel>(
    {
      url: `/api/v1/notifications/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: notificationModelRequest,
    },
    options
  );
};

export const getNotificationsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsCreate>>,
    TError,
    { data: NotificationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsCreate>>,
  TError,
  { data: NotificationModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsCreate>>,
    { data: NotificationModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return notificationsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsCreate>>
>;
export type NotificationsCreateMutationBody = NotificationModelRequest;
export type NotificationsCreateMutationError = ErrorType<unknown>;

export const useNotificationsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsCreate>>,
    TError,
    { data: NotificationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getNotificationsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Notifications

Notifications can be delivered via app, email, SMS, and connect (e.g., slack/teams).

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const notificationsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationModel>(
    { url: `/api/v1/notifications/${id}/`, method: "GET", signal },
    options
  );
};

export const getNotificationsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/notifications/${id}/`] as const;
};

export const getNotificationsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsRetrieve>>
  > = ({ signal }) => notificationsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsRetrieve>>
>;
export type NotificationsRetrieveQueryError = ErrorType<unknown>;

export const useNotificationsRetrieve = <
  TData = Awaited<ReturnType<typeof notificationsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Notifications

Notifications can be delivered via app, email, SMS, and connect (e.g., slack/teams).

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const notificationsPartialUpdate = (
  id: number,
  patchedNotificationModelRequest: PatchedNotificationModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<NotificationModel>(
    {
      url: `/api/v1/notifications/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedNotificationModelRequest,
    },
    options
  );
};

export const getNotificationsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPartialUpdate>>,
    TError,
    { id: number; data: PatchedNotificationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsPartialUpdate>>,
  TError,
  { id: number; data: PatchedNotificationModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsPartialUpdate>>,
    { id: number; data: PatchedNotificationModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return notificationsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPartialUpdate>>
>;
export type NotificationsPartialUpdateMutationBody =
  PatchedNotificationModelRequest;
export type NotificationsPartialUpdateMutationError = ErrorType<unknown>;

export const useNotificationsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPartialUpdate>>,
    TError,
    { id: number; data: PatchedNotificationModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getNotificationsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Notifications

Notifications can be delivered via app, email, SMS, and connect (e.g., slack/teams).

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const notificationsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/notifications/${id}/`, method: "DELETE" },
    options
  );
};

export const getNotificationsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return notificationsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsDestroy>>
>;

export type NotificationsDestroyMutationError = ErrorType<unknown>;

export const useNotificationsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getNotificationsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const notificationsMeList = (
  params?: NotificationsMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedNotificationModelList>(
    { url: `/api/v1/notifications/me/`, method: "GET", params, signal },
    options
  );
};

export const getNotificationsMeListQueryKey = (
  params?: NotificationsMeListParams
) => {
  return [`/api/v1/notifications/me/`, ...(params ? [params] : [])] as const;
};

export const getNotificationsMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsMeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsMeList>>
  > = ({ signal }) => notificationsMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsMeList>>
>;
export type NotificationsMeListQueryError = ErrorType<unknown>;

export const useNotificationsMeList = <
  TData = Awaited<ReturnType<typeof notificationsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Notification Preferences

Notification Preferences for users map type to method. For example, a user can choose to receive emails for Pulse
and Web Application for Social Activity.

## Types

```
class NotificationMethod(models.IntegerChoices):
    EMAIL = 1, "Email"
    SMS = 2, "SMS"
    PUSH = 3, "Push"  # when we have a mobile app
    BROWSER = 4, "Browser"  # browser notifications
    WEB_APP = 5, "Web App"  # web application notifications
    SLACK = 6, "Slack"
    MSTEAMS = 7, "Microsoft Teams"


class NotificationType(models.IntegerChoices):
    UNKNOWN = 0, "Unknown"
    ANNOUNCEMENTS = 1, "Announcements"
    BIRTHDAYS = 2, "Birthdays"
    WORK_ANNIVERSARIES = 3, "Work Anniversaries"
    NEWS = 4, "News"
    CHALLENGES = 5, "Challenges"
    BANTER = 6, "Banter"
    PEOPLE = 7, "People"
    PULSE = 8, "Pulse"
    SOCIAL_DAILY_SUMMARY = 9, "Social Daily Summary"
    SOCIAL_ACTIVITY = 10, "Social Activity"
    SYSTEM = 11, "System"
```
 */
export const notificationsPreferencesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationPreference>(
    { url: `/api/v1/notifications/preferences/${id}/`, method: "GET", signal },
    options
  );
};

export const getNotificationsPreferencesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/notifications/preferences/${id}/`] as const;
};

export const getNotificationsPreferencesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsPreferencesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>
  > = ({ signal }) =>
    notificationsPreferencesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsPreferencesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>
>;
export type NotificationsPreferencesRetrieveQueryError = ErrorType<unknown>;

export const useNotificationsPreferencesRetrieve = <
  TData = Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof notificationsPreferencesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsPreferencesRetrieveQueryOptions(
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Notification Preferences

Notification Preferences for users map type to method. For example, a user can choose to receive emails for Pulse
and Web Application for Social Activity.

## Types

```
class NotificationMethod(models.IntegerChoices):
    EMAIL = 1, "Email"
    SMS = 2, "SMS"
    PUSH = 3, "Push"  # when we have a mobile app
    BROWSER = 4, "Browser"  # browser notifications
    WEB_APP = 5, "Web App"  # web application notifications
    SLACK = 6, "Slack"
    MSTEAMS = 7, "Microsoft Teams"


class NotificationType(models.IntegerChoices):
    UNKNOWN = 0, "Unknown"
    ANNOUNCEMENTS = 1, "Announcements"
    BIRTHDAYS = 2, "Birthdays"
    WORK_ANNIVERSARIES = 3, "Work Anniversaries"
    NEWS = 4, "News"
    CHALLENGES = 5, "Challenges"
    BANTER = 6, "Banter"
    PEOPLE = 7, "People"
    PULSE = 8, "Pulse"
    SOCIAL_DAILY_SUMMARY = 9, "Social Daily Summary"
    SOCIAL_ACTIVITY = 10, "Social Activity"
    SYSTEM = 11, "System"
```
 */
export const notificationsPreferencesPartialUpdate = (
  id: number,
  patchedNotificationPreferenceRequest: PatchedNotificationPreferenceRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<NotificationPreference>(
    {
      url: `/api/v1/notifications/preferences/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedNotificationPreferenceRequest,
    },
    options
  );
};

export const getNotificationsPreferencesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPreferencesPartialUpdate>>,
    TError,
    { id: number; data: PatchedNotificationPreferenceRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsPreferencesPartialUpdate>>,
  TError,
  { id: number; data: PatchedNotificationPreferenceRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsPreferencesPartialUpdate>>,
    { id: number; data: PatchedNotificationPreferenceRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return notificationsPreferencesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsPreferencesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPreferencesPartialUpdate>>
>;
export type NotificationsPreferencesPartialUpdateMutationBody =
  PatchedNotificationPreferenceRequest;
export type NotificationsPreferencesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useNotificationsPreferencesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsPreferencesPartialUpdate>>,
    TError,
    { id: number; data: PatchedNotificationPreferenceRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getNotificationsPreferencesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const notificationsPreferencesMeRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationPreference>(
    { url: `/api/v1/notifications/preferences/me/`, method: "GET", signal },
    options
  );
};

export const getNotificationsPreferencesMeRetrieveQueryKey = () => {
  return [`/api/v1/notifications/preferences/me/`] as const;
};

export const getNotificationsPreferencesMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsPreferencesMeRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>
  > = ({ signal }) =>
    notificationsPreferencesMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsPreferencesMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>
>;
export type NotificationsPreferencesMeRetrieveQueryError = ErrorType<unknown>;

export const useNotificationsPreferencesMeRetrieve = <
  TData = Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationsPreferencesMeRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationsPreferencesMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Mark all of a user's notifications as read
 */
export const notificationsReadAllRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<NotificationsReadAllResponse>(
    { url: `/api/v1/notifications/read-all/`, method: "GET", signal },
    options
  );
};

export const getNotificationsReadAllRetrieveQueryKey = () => {
  return [`/api/v1/notifications/read-all/`] as const;
};

export const getNotificationsReadAllRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationsReadAllRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationsReadAllRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationsReadAllRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsReadAllRetrieve>>
  > = ({ signal }) => notificationsReadAllRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsReadAllRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationsReadAllRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationsReadAllRetrieve>>
>;
export type NotificationsReadAllRetrieveQueryError = ErrorType<unknown>;

export const useNotificationsReadAllRetrieve = <
  TData = Awaited<ReturnType<typeof notificationsReadAllRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof notificationsReadAllRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationsReadAllRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
