/**
 *
 *
 * usePeople
 *
 *
 */
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import useDebounceValue from "../../hooks/useDebounceValue";
import { useRoles } from "../../hooks/useRoles";
import { useToast } from "../../hooks/useToast";
import {
  usePeopleDestroy,
  usePeopleList,
  usePeopleSendInvitationsCreate,
} from "../../services/teambuilder/endpoints/people/people";
import {
  PeopleListOrdering,
  _PeopleResponse,
} from "../../services/teambuilder/schemas";
import { ViewModeEnum } from "../../utils/enums";

const ROW_LIMIT = 12;

export const usePeople = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get("page")) || 1;
  const searchText = searchParams.get("search") || "";
  const viewMode = searchParams.get("view_mode") || ViewModeEnum.VIEW_MODE_GRID;
  const prevSearchText = useRef<string>("");

  const toast = useToast();
  const setViewMode = (viewMode: ViewModeEnum) => {
    if (searchParams.has("view_mode")) {
      searchParams.set("view_mode", viewMode);
    } else {
      searchParams.append("view_mode", viewMode);
    }
    setSearchParams(searchParams);
  };

  const openProfileModal = (id: number) => {
    if (searchParams.has(SearchParam.PROFILE)) {
      searchParams.set(SearchParam.PROFILE, id.toString());
    } else {
      searchParams.append(SearchParam.PROFILE, id.toString());
    }
    setSearchParams(searchParams);
  };

  const roles = useRoles();

  useEffect(() => {
    setSearchTerm(searchParams.get("search") || "");
  }, []);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounceValue(searchTerm, 500) || "";

  const peopleListRequestParams = {
    limit: ROW_LIMIT,
    offset: currentPage > 1 ? (currentPage - 1) * ROW_LIMIT : 0,
    ordering: PeopleListOrdering.first_name,
    search: searchText,
  };

  useEffect(() => {
    if (
      searchText &&
      debouncedSearchTerm === "" &&
      prevSearchText.current === ""
    ) {
      prevSearchText.current = debouncedSearchTerm;
      return;
    }
    if (searchText === debouncedSearchTerm) return;
    prevSearchText.current = debouncedSearchTerm;

    if (searchParams.has("search")) {
      searchParams.set("search", debouncedSearchTerm);
    } else {
      searchParams.append("search", debouncedSearchTerm);
    }
    setSearchParams(searchParams);
  }, [debouncedSearchTerm, searchText]);

  const {
    data: members,
    refetch: refetchPeople,
    isLoading: isLoadingMembers,
    isError: isMembersError,
  } = usePeopleList(peopleListRequestParams);

  const { mutate: destroyPeople } = usePeopleDestroy({
    mutation: {
      onSuccess: () => {
        toast.openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:person_deactivate_success"),
        });
        refetchPeople();
      },
    },
  });

  const { mutate: sendEmail } = usePeopleSendInvitationsCreate({
    mutation: {
      onSuccess: () => {
        toast.openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:person_invite_success"),
        });
      },
    },
  });

  const openPeopleForm = (userId?: string) => {
    searchParams.append("people_form", userId || "");
    setSearchParams(searchParams);
  };

  const [isDeactivateUserOpen, setIsDeactivateUserOpen] =
    useState<boolean>(false);
  const [selectedPerson, setSelectedPerson] = useState<_PeopleResponse>();

  const onClickDeactivateUser = (person: _PeopleResponse) => {
    setSelectedPerson(person);
    setIsDeactivateUserOpen(true);
  };

  const onDeactivateConfirm = () => {
    setIsDeactivateUserOpen(false);
    if (selectedPerson?.id) destroyPeople({ id: selectedPerson?.id });
  };

  const [isEmailPersonOpen, setIsEmailPersonOpen] = useState<boolean>(false);

  const onClickEmailInvite = (person: _PeopleResponse) => {
    setSelectedPerson(person);
    setIsEmailPersonOpen(true);
  };
  const onEmailConfirm = () => {
    setIsEmailPersonOpen(false);
    if (selectedPerson?.id) {
      sendEmail({
        data: {
          userIds: [selectedPerson?.id],
        },
      });
    }
  };

  const onPageChange = (value: string | number) => {
    const page = typeof value === "string" ? value : value.toString();
    if (searchParams.has("page")) {
      searchParams.set("page", page);
    } else {
      searchParams.append("page", page);
    }
    setSearchParams(searchParams);
  };

  const [isImportUsers, setIsImportUsers] = useState<boolean>(false);

  return {
    members,
    toast,
    isLoadingMembers,
    isMembersError,
    openPeopleForm,
    roles,
    searchTerm,
    setSearchTerm,
    refetchPeople,
    ROW_LIMIT,
    currentPage,
    setSearchParams,
    selectedPerson,
    isDeactivateUserOpen,
    setIsDeactivateUserOpen,
    onClickDeactivateUser,
    onDeactivateConfirm,
    isEmailPersonOpen,
    setIsEmailPersonOpen,
    onClickEmailInvite,
    onEmailConfirm,
    viewMode,
    setViewMode,
    searchText,
    onPageChange,
    isImportUsers,
    setIsImportUsers,
    openProfileModal,
  };
};
