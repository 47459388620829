/**
 *
 *
 * <LoadingStar />
 *
 *
 */
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";

interface Props {
  className?: string;
}

export const LoadingStar = ({ className = "" }: Props) => {
  return <ArrowPathIcon className={clsx("h-4 w-4 animate-ping", className)} />;
};
