/**
 *
 *
 * useCoffeeChat
 *
 *
 */
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useToast } from "../../hooks/useToast";
import { useCoffeechatsPartialUpdate } from "../../services/teambuilder/endpoints/coffeechats/coffeechats";
import { usePeopleMeRetrieve } from "../../services/teambuilder/endpoints/people/people";
import {
  CoffeeChat,
  PaginatedAdsList,
  PatchedCoffeeChatRSVPRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  id?: number;
  className?: string;
  listQueryKey: readonly unknown[];
}

export const useCoffeeChat = ({ id, listQueryKey, className = "" }: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();
  const [_, setSearchParams] = useSearchParams();
  const { data: me } = usePeopleMeRetrieve();

  /**
   * update coffeechat
   */
  const { mutate: updateCoffeeChat } = useCoffeechatsPartialUpdate(
    optimisticMutationOptions<
      CoffeeChat,
      PaginatedAdsList,
      {
        id: number;
        data: PatchedCoffeeChatRSVPRequest;
      }
    >({
      queryKey: listQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        return {
          ...context,
          data: context.data.map((coffeeChat) =>
            coffeeChat.objectId !== requestVariables?.id
              ? coffeeChat
              : { ...coffeeChat, objectId: -1 }
          ),
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:coffechat_update_success"),
        });
        close();
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:coffeechat_update_failed"),
          type: "danger",
        });
      },
    })
  );

  const onYes = () => {
    if (id === undefined) return;
    setSearchParams({ coffeechat: `${id}` });
  };

  const onCancel = () => {
    if (id === undefined) return;
    updateCoffeeChat({
      id,
      data: {
        participating: false,
      },
    });
  };

  return {
    me,
    className,
    onCancel,
    onYes,
  };
};
