/**
 *
 *
 * <CommentForm />
 *
 *
 */
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { PostAttachmentModal } from "../../containers/PostAttachmentModal";
import { FeatureContent } from "../../containers/PostModal/FeatureContent";
import { getTranslation } from "../../utils/translation";
import { Editor } from "../EditorV3";
import { FormData, useCommentForm } from "./useCommentForm";

export const CommentForm = (props: ReturnType<typeof useCommentForm>) => {
  const { t } = useTranslation();
  const scrollToRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.scrollIntoView) {
      scrollToRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);

  return (
    <>
      <div
        className={clsx("flex items-start gap-2", props.className)}
        ref={scrollToRef}
      >
        <div className="min-w-0 flex-1">
          <form onSubmit={props.onHandleSubmit} className="relative">
            <div
              className={clsx(
                "overflow-hidden rounded-lg bg-zinc-100 shadow-sm",
                "ring-1 ring-inset ring-zinc-300"
              )}
            >
              <div>
                <label htmlFor="comment" className="sr-only">
                  {t("translation:comment:post_content")}
                </label>
                <Editor
                  clear={props.clearEditor}
                  placeholder={props.placeholderText}
                  onChange={(post) => props.setPostValue(post as FormData)}
                  paddingLeft="8px"
                  paddingRight="8px"
                  minHeight="1rem"
                  setEditor={props.setEditor}
                />
                {props.errors.content && (
                  <p className="px-2 text-xs text-red-500">
                    {getTranslation(t, props.errors.content.message)}
                  </p>
                )}
              </div>
              {/**
               *
               * Show Feature Contents
               *
               */}
              {props.hasFeature && (
                <div className="my-4 px-2">
                  <FeatureContent
                    menuItems={props.menuItems}
                    selectedRecipient={props.selectedRecipient}
                    selectedValueId={props.selectedValueId}
                    selectedValueLabel={props.selectedValueLabel}
                    previewImageUrl={props.previewImageUrl}
                    selectedURL={props.selectedURL}
                  />
                </div>
              )}

              <div className={clsx("flex justify-end px-2 py-2")}>
                {/* <div className="flex h-full items-center gap-1">
                  <button
                    type="button"
                    onClick={() =>
                      props.onOpenPostFeatureModal(PostFeatureType.SHOUTOUT)
                    }
                    className={clsx(
                      "rounded-full p-1",
                      "shadow hover:shadow-none",
                      "text-white",
                      "bg-fuchsia-950",
                      "tour-shoutout",
                      "disabled:bg-fuchsia-950/80"
                    )}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={t(
                      "translation:post:shoutout_colleague"
                    )}
                    data-tooltip-place="bottom"
                    disabled={props.hasFeature}
                  >
                    <MegaphoneIcon className="h-4 w-4" />
                  </button>

                  <button
                    type="button"
                    onClick={() =>
                      props.onOpenPostFeatureModal(PostFeatureType.IMAGE)
                    }
                    className={clsx(
                      "rounded-full p-1",
                      "text-white",
                      "shadow hover:shadow-none",
                      "bg-green-950",
                      "disabled:bg-green-950/80"
                    )}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={t("translation:post:post_image")}
                    data-tooltip-place="bottom"
                    disabled={props.hasFeature}
                  >
                    <PhotoIcon className="h-4 w-4" />
                  </button>

                  <button
                    type="button"
                    onClick={() =>
                      props.onOpenPostFeatureModal(PostFeatureType.LINK)
                    }
                    className={clsx(
                      "rounded-full p-1",
                      "text-white",
                      "shadow hover:shadow-none",
                      "bg-blue-950",
                      "disabled:bg-blue-950/80"
                    )}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={t(
                      "translation:post:post_link_website"
                    )}
                    data-tooltip-place="bottom"
                    disabled={props.hasFeature}
                  >
                    <LinkIcon className="h-4 w-4" />
                  </button>
                </div> */}

                <Button
                  type="submit"
                  variant="contained"
                  size={props.isPost ? "sm" : "xs"}
                  className="capitalize"
                >
                  {t("translation:post:post")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PostAttachmentModal
        mode={props.featureMode}
        isOpen={!!props.featureMode}
        onClose={props.onCancel}
        onCancel={props.onCancel}
        previewImageUrl={props.previewImageUrl}
        recipient={props.selectedRecipient}
        valueId={props.selectedValueId}
        url={props.selectedURL}
        onSubmit={props.onSetFeature}
      />
    </>
  );
};
