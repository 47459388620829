/**
 *
 *
 * <SkeletonChallengeDetailHeader />
 *
 *
 */
import clsx from "clsx";

import { useLayoutContext } from "../LayoutProvider";

export const SkeletonChallengeDetailHeader = () => {
  const { isMobileMode } = useLayoutContext();
  return (
    <div className="w-full animate-pulse">
      <div
        className={clsx(
          "relative pt-[9.5rem]",
          !isMobileMode && "sm:pl-20 sm:pt-0"
        )}
      >
        <div
          className={clsx(
            "h-40 w-full sm:py-2",
            "flex flex-col gap-y-4 overflow-hidden"
          )}
        >
          <div className="flex flex-col items-end gap-y-4 p-0">
            <div className="h-[70px] w-full rounded-lg bg-zinc-100 px-2 py-2 sm:pl-20" />
            <div className="h-14 w-full rounded-lg bg-zinc-100 pl-20" />
            <div
              className={clsx(
                "absolute h-40 w-40",
                "left-1/2 top-5 -translate-x-1/2 ",
                "rounded-full border-4 border-white bg-zinc-100",
                !isMobileMode &&
                  "sm:left-0 sm:top-20 sm:-translate-y-1/2 sm:translate-x-0"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
