/**
 *
 *
 * usePersonalInfoForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  getPeopleMeRetrieveQueryKey,
  getPeopleRetrieveQueryKey,
  usePeoplePartialUpdate,
  usePeopleRetrieve,
} from "../../services/teambuilder/endpoints/people/people";
import {
  PatchedPeopleRequestRequest,
  _PeopleResponse,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";
import { isDomainLike, isEmail } from "../../utils/validation";

export const schema = z.object({
  firstName: z
    .string()
    .min(1, "translation:validation:required_first_name")
    .refine(
      (name) => {
        // Firstname should not be an email or a domain
        return !isEmail(name) && !isDomainLike(name);
      },
      {
        message: "translation:validation:invalid_firstname",
      }
    ),
  lastName: z
    .string()
    .min(1, "translation:validation:required_last_name")
    .refine(
      (name) => {
        // Lastname should not be an email or a domain
        return !isEmail(name) && !isDomainLike(name);
      },
      {
        message: "translation:validation:invalid_lastname",
      }
    ),
  jobTitle: z.string().optional(),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  userId: number;
  className?: string;
}

export const usePersonalInfoForm = (props: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  // Currently only the user can edit their own profile, but in the future
  // admins will be able to edit other users' profiles, so we're not
  // using the /me endpoint here or in optimistic updates.
  const { data: user, isLoading: isLoadingUser } = usePeopleRetrieve(
    props.userId
  );
  const queryKey = useMemo(
    () => getPeopleRetrieveQueryKey(props.userId),
    [props.userId]
  );

  const { data: company } = useIntuitionCustomersCompaniesMeRetrieve();

  /**
   * Form
   */
  const getDefaultValues = () => {
    return {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      jobTitle: user?.jobTitle || "",
    };
  };
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    form.reset(getDefaultValues());
  }, [user]);

  const { mutate: updateUser } = usePeoplePartialUpdate(
    optimisticMutationOptions<
      _PeopleResponse,
      _PeopleResponse,
      {
        id: number;
        data: Omit<
          PatchedPeopleRequestRequest,
          "departments" | "locations" | "teams" | "roles" | "timezone"
        >;
      }
    >({
      queryKey,
      optimisticUpdateFn: (peopleRetrieveResponse, requestVariables) => {
        return {
          ...peopleRetrieveResponse,
          ...requestVariables?.data,
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:toast:personal_info_update_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:toast:personal_info_update_failed"),
          description: t(
            "translation:toast:personal_info_update_failed_description"
          ),
          type: "danger",
        });
      },
      onSettled: () => {
        // Assume the user is me and invalidate the /me endpoint.
        queryClient.invalidateQueries(getPeopleMeRetrieveQueryKey());
      },
    })
  );

  const onSubmit = (data: FormData) => {
    updateUser({
      id: props.userId,
      data,
    });
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    company,
    form,
    onHandleSubmit,
    isLoading: isLoadingUser,
    className: props.className,
  };
};
