/**
 *
 *
 * Loader for <App />
 *
 *
 */
import dayjs from "dayjs";
import { LoaderFunction } from "react-router-dom";

import { intuitionUsersLoginCreate } from "../../services/teambuilder/endpoints/intuition/intuition";
import { getCookieValue } from "../../utils/cookies";
import { redirectToAuth } from "../../utils/redirect-to-auth";

const LOCAL_STORAGE_AUTH_KEY = import.meta.env.VITE_LOCALSTORAGE_AUTH_TOKEN_KEY;
const TENANT_COOKIE = import.meta.env.VITE_TENANT_COOKIE;

/**
 * Store the tenant name in a cookie.
 * Should work on all teambuilder subdomains.
 */
const setTenantCookie = (tenant: string, domain: string) => {
  document.cookie = `${TENANT_COOKIE}=${tenant};path=/;domain=${domain};expires=${dayjs()
    .add(1, "year")
    .utc()
    .toISOString()};Secure`;
};

export const loader = (): LoaderFunction => async () => {
  const url = new URL(window.location.href);
  const [tenant, product, ...hostnameParts] = url.hostname.split(".");

  /**
   * Only tenants allowed!
   * If the url contains a tenant (i.e. https://sometenant.teambuilder.intuition.us), store it.
   */
  if (product === "teambuilder") {
    setTenantCookie(tenant, [product, ...hostnameParts].join("."));
  } else if (tenant === "teambuilder") {
    /**
     * Otherwise if this is the base teambuilder url (i.e. https://teambuilder.intuition.us)
     * attempt to forward the user to the stored tenant.
     */
    const cookieValue = getCookieValue(TENANT_COOKIE);
    if (cookieValue) {
      url.host = `${cookieValue}.${url.host}`;
      window.location.replace(url);
    } else {
      /**
       * If we don't have the tenant in localStorage give up and redirect to auth.
       * Auth will redirect eligible users to the correct tenant.
       */
      redirectToAuth();
    }
  }

  /**
   * If the url has a `token` (i.e. drfToken) search param we have just been redirected here
   * from auth. Set the token in localStorage for use in request headers.
   */
  if (url.searchParams.has("token")) {
    // prevent existing token from being used
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);

    // Wait to render until login is complete.
    return await intuitionUsersLoginCreate({
      token: url.searchParams.get("token") as string,
    })
      .then(({ token: drfToken }) => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, drfToken);
        // Remove the token and reload.
        url.searchParams.delete("token");
        window.location.replace(url);
        return null;
      })
      .catch(() => {
        redirectToAuth(true);
        return null;
      });
  }

  // Loaders must return a value or null.
  return null;
};
