/**
 *
 *
 * <Post />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { ImagePreviewModal } from "../../components/ImagePreviewModal";
import { _isRebuttal, isPostType } from "../../utils/type-guards";
import { CommentList } from "../CommentListV3";
import { Comment } from "./Comment";
import { PrimaryPost } from "./PrimaryPost";
import { usePost } from "./usePost";

export const Post = (props: ReturnType<typeof usePost>) => {
  const { className, ...rest } = props;
  return (
    <>
      <div className={className}>
        {isPostType(props.post) ? (
          <PrimaryPost {...rest} isDetail={Boolean(props.detailQueryKey)} />
        ) : (
          <Comment {...rest} />
        )}

        {props.isCommentsListVisible && !_isRebuttal(props.post) ? (
          <CommentList
            post={props.post}
            className={clsx(isPostType(props.post) ? "px-4" : "pl-6 md:pl-12")}
            detail={props.isDetail}
            isCommentFormVisible={props.isCommentFormVisible}
            onSubmit={() => !isPostType(props.post) && props.hideCommentForm()}
            setCommentsQueryKey={props.setCommentsQueryKey}
            detailQueryKey={props.detailQueryKey}
          />
        ) : null}
      </div>

      {isPostType(props.post) && props.post.image && !props.post.url && (
        <ImagePreviewModal
          isOpen={props.isImagePreviewVisible}
          onClose={props.closeImagePreview}
          image={props.post.image.large as string}
        />
      )}
    </>
  );
};
