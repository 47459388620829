/**
 *
 *
 * DropdownMenu
 *
 *
 */
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

import { NavItemV3 } from "../../types";
import { MenuItem } from "./MenuItem";

interface Props {
  items: NavItemV3[];
  classNames?: string;
  header?: React.ReactElement;
  menuButtonContent: (open: boolean) => React.ReactElement;
  menuButtonClassNames?: (open: boolean) => string;
  // If provided, menuClassNames overrides default menu positioning,
  // which is: "left-0 top-10 mb-2 origin-top-left"
  menuClassNames?: string;
}

export const DropdownMenu = (props: Props) => {
  return (
    <Menu as="div" className={clsx("relative flex", props.classNames)}>
      {({ open }) => (
        <>
          <Menu.Button
            type="button"
            className={props.menuButtonClassNames?.(open)}
          >
            {({ open }) => props.menuButtonContent(open)}
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                "absolute z-10 mt-2 w-max rounded-md",
                "bg-white shadow-lg ring-1 ring-zinc-300 focus:outline-none",
                props.menuClassNames
                  ? props.menuClassNames
                  : "left-0 top-10 mb-2 origin-top-left"
              )}
            >
              {props.header || null}
              {props.items.map((item) => (
                <MenuItem key={item.name} {...item} />
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
