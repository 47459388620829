/**
 *
 *
 * <Button />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { Size, Variant, buttonStyles } from "../../utils/button-styles";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: Variant;
  size?: Size;
  dark?: boolean;
}

/**
 *
 * Button component
 *
 * @param variant
 *  "contained" - Solid blue background, white text.
 *  "outlined" - White background, gray border.
 *  "inline" - No padding, no background, no border. Displays inline.
 *  "text" - Transparent background, blue text, no border.
 *  "danger" - Red background, white text.
 *  "text-danger" - Red text, transparent background, no border.
 *
 * @param size Change font size. Corresponds to tailwind text sizes "text-[size]".
 *  "xl" - 20px, for Edit buttons in tables
 *  "lg" - 18px, for primary buttons like Give ShoutOut
 *  "md" - 16px, same height as form input for inline submit buttons
 *  "sm" - 14px, for modal form buttons
 *  "xs" - For card ctas
 */
const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = "contained",
      size = "lg",
      dark,
      className = "",
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        className={clsx(buttonStyles({ variant, size, dark }), className)}
      >
        {children && <>{children}</>}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button };
