import clsx from "clsx";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PostType } from "../../types";

type Props = {
  post: PostType;
  className?: string;
  isPrimary?: boolean;
};

export const PostContent = (props: Props) => {
  const { t } = useTranslation();
  const [isFullContent, setIsFullContent] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const renderedPostContent = useMemo(() => {
    if (!props.post.content) return null;
    if (isFullContent) return props.post.content;
    const maxLength = props.isPrimary ? 1200 : 550;
    const hasMore = props.post.content.length > maxLength;
    setHasMore(hasMore);
    return hasMore
      ? props.post.content.slice(0, maxLength) + "..."
      : props.post.content;
  }, [isFullContent, props.post.content]);

  const splitByLineBreak = (szContent: string): ReactNode[] => {
    return szContent
      .replace(/\n{2,}/g, "\n")
      .split(/\n/g)
      .map((content, index, arr) => (
        <>
          {index > 0 && index < arr.length && <br />}
          {content}
        </>
      ));
  };

  const postContentParts: ReactNode[] | null = useMemo(() => {
    if (!renderedPostContent) return null;
    let postContent = renderedPostContent;
    let renderedPostContentParts: ReactNode[] = [];
    if (props.post.mentions?.length) {
      let slicedLength = 0;
      const k = "contentOffset"; // shorthand for sort fn and nil check
      props.post.mentions
        .sort((a, m) => (!a[k] || !m[k] ? 0 : a[k] - m[k]))
        .forEach((mention) => {
          if (mention[k] === undefined || mention[k] === null) return;
          if (!mention.contentText || !mention.user) return;
          const mentionOffset = mention.contentOffset - slicedLength;
          const mentionText = mention.contentText;
          const contentAtOffset = postContent.slice(
            mentionOffset,
            mentionOffset + mentionText.length
          );
          if (contentAtOffset !== mentionText) return;
          const leftContents = splitByLineBreak(
            postContent.slice(0, mentionOffset)
          );
          renderedPostContentParts = [
            ...renderedPostContentParts,
            ...leftContents,
          ];
          renderedPostContentParts.push(
            <>
              <Link
                to={`?profile=${mention.user.id}`}
                className="font-semibold text-zinc-500 underline hover:text-zinc-600"
              >
                {mentionText}
              </Link>{" "}
            </>
          );
          postContent = postContent.slice(mentionOffset + mentionText.length);
          slicedLength += mentionOffset + mentionText.length;
        });
    }
    const lastContents = splitByLineBreak(postContent);
    renderedPostContentParts = [...renderedPostContentParts, ...lastContents];
    return renderedPostContentParts;
  }, [renderedPostContent]);

  if (!renderedPostContent || !postContentParts) return null;

  return (
    <div className={clsx("whitespace-pre-line break-words", props.className)}>
      {postContentParts.map((part, k) => (
        <Fragment key={k}>
          <span key={k}>{part}</span>
          {k === postContentParts.length - 1 && <span> </span>}
        </Fragment>
      ))}
      {hasMore && (
        <span
          className="cursor-pointer font-medium text-zinc-500 underline hover:text-zinc-600"
          onClick={() => setIsFullContent(!isFullContent)}
        >
          {!isFullContent
            ? t("translation:post:continue_reading")
            : t("translation:post:show_less")}
        </span>
      )}
    </div>
  );
};
