/**
 *
 *
 * Authorization Loader
 *
 *
 *
 */
import { peopleMeRetrieve } from "../services/teambuilder/endpoints/people/people";

type RoleName = "Manager" | "HR" | "IT"; // Member is the lack of assigned roles

/**
 * The use must have one of the required roles to access the route.
 */
export const authorizationLoader = (requiredRoles: RoleName[]) => async () => {
  const roles = await peopleMeRetrieve().then((me) =>
    me.roles.map((role) => role.name as RoleName)
  );

  // If the current user's roles do not match the required roles, throw and let
  // the errorElement redirect the user to the dashboard.
  for (const role of roles) {
    if (requiredRoles.includes(role)) {
      return null;
    }
  }
  throw new Error("Unauthorized");
};
