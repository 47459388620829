/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  CoffeeChat,
  CoffeeChatRSVP,
  CoffeechatsBatchesListParams,
  CoffeechatsBatchesRsvpsListParams,
  CoffeechatsListParams,
  CoffeechatsMeListParams,
  PaginatedCoffeeChatList,
  PaginatedCoffeeChatRSVPList,
  PatchedCoffeeChatRSVPRequest,
  PatchedCoffeeChatRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # CoffeeChat RSVP

Indicates whether an employee is participating and what topics are of interest for this chat.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsList = (
  params?: CoffeechatsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatRSVPList>(
    { url: `/api/v1/coffeechats/`, method: "GET", params, signal },
    options
  );
};

export const getCoffeechatsListQueryKey = (params?: CoffeechatsListParams) => {
  return [`/api/v1/coffeechats/`, ...(params ? [params] : [])] as const;
};

export const getCoffeechatsListQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoffeechatsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof coffeechatsList>>> = ({
    signal,
  }) => coffeechatsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsList>>
>;
export type CoffeechatsListQueryError = ErrorType<unknown>;

export const useCoffeechatsList = <
  TData = Awaited<ReturnType<typeof coffeechatsList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat RSVP

Indicates whether an employee is participating and what topics are of interest for this chat.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatRSVP>(
    { url: `/api/v1/coffeechats/${id}/`, method: "GET", signal },
    options
  );
};

export const getCoffeechatsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/coffeechats/${id}/`] as const;
};

export const getCoffeechatsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoffeechatsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coffeechatsRetrieve>>
  > = ({ signal }) => coffeechatsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsRetrieve>>
>;
export type CoffeechatsRetrieveQueryError = ErrorType<unknown>;

export const useCoffeechatsRetrieve = <
  TData = Awaited<ReturnType<typeof coffeechatsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat RSVP

Indicates whether an employee is participating and what topics are of interest for this chat.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsPartialUpdate = (
  id: number,
  patchedCoffeeChatRSVPRequest: PatchedCoffeeChatRSVPRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatRSVP>(
    {
      url: `/api/v1/coffeechats/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCoffeeChatRSVPRequest,
    },
    options
  );
};

export const getCoffeechatsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coffeechatsPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatRSVPRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coffeechatsPartialUpdate>>,
  TError,
  { id: number; data: PatchedCoffeeChatRSVPRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coffeechatsPartialUpdate>>,
    { id: number; data: PatchedCoffeeChatRSVPRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return coffeechatsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoffeechatsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsPartialUpdate>>
>;
export type CoffeechatsPartialUpdateMutationBody = PatchedCoffeeChatRSVPRequest;
export type CoffeechatsPartialUpdateMutationError = ErrorType<unknown>;

export const useCoffeechatsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coffeechatsPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatRSVPRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getCoffeechatsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # CoffeeChat

Created for each round of Coffee Chats to group the participants.

## Permissions

```
permission_classes = [iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsBatchesList = (
  params?: CoffeechatsBatchesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatList>(
    { url: `/api/v1/coffeechats/batches/`, method: "GET", params, signal },
    options
  );
};

export const getCoffeechatsBatchesListQueryKey = (
  params?: CoffeechatsBatchesListParams
) => {
  return [`/api/v1/coffeechats/batches/`, ...(params ? [params] : [])] as const;
};

export const getCoffeechatsBatchesListQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsBatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCoffeechatsBatchesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coffeechatsBatchesList>>
  > = ({ signal }) => coffeechatsBatchesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsBatchesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsBatchesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsBatchesList>>
>;
export type CoffeechatsBatchesListQueryError = ErrorType<unknown>;

export const useCoffeechatsBatchesList = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsBatchesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsBatchesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat

Created for each round of Coffee Chats to group the participants.

## Permissions

```
permission_classes = [iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsBatchesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChat>(
    { url: `/api/v1/coffeechats/batches/${id}/`, method: "GET", signal },
    options
  );
};

export const getCoffeechatsBatchesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/coffeechats/batches/${id}/`] as const;
};

export const getCoffeechatsBatchesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCoffeechatsBatchesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>
  > = ({ signal }) => coffeechatsBatchesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsBatchesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>
>;
export type CoffeechatsBatchesRetrieveQueryError = ErrorType<unknown>;

export const useCoffeechatsBatchesRetrieve = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsBatchesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat

Created for each round of Coffee Chats to group the participants.

## Permissions

```
permission_classes = [iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser]
required_roles = ["HR", "Manager"]
```
 */
export const coffeechatsBatchesPartialUpdate = (
  id: number,
  patchedCoffeeChatRequest: PatchedCoffeeChatRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChat>(
    {
      url: `/api/v1/coffeechats/batches/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCoffeeChatRequest,
    },
    options
  );
};

export const getCoffeechatsBatchesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coffeechatsBatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof coffeechatsBatchesPartialUpdate>>,
  TError,
  { id: number; data: PatchedCoffeeChatRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coffeechatsBatchesPartialUpdate>>,
    { id: number; data: PatchedCoffeeChatRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return coffeechatsBatchesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CoffeechatsBatchesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsBatchesPartialUpdate>>
>;
export type CoffeechatsBatchesPartialUpdateMutationBody =
  PatchedCoffeeChatRequest;
export type CoffeechatsBatchesPartialUpdateMutationError = ErrorType<unknown>;

export const useCoffeechatsBatchesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coffeechatsBatchesPartialUpdate>>,
    TError,
    { id: number; data: PatchedCoffeeChatRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getCoffeechatsBatchesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Completed RSVPs in this coffeechat, regardless of whether or not participating
 */
export const coffeechatsBatchesRsvpsList = (
  id: number,
  params?: CoffeechatsBatchesRsvpsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatRSVPList>(
    {
      url: `/api/v1/coffeechats/batches/${id}/rsvps/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getCoffeechatsBatchesRsvpsListQueryKey = (
  id: number,
  params?: CoffeechatsBatchesRsvpsListParams
) => {
  return [
    `/api/v1/coffeechats/batches/${id}/rsvps/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getCoffeechatsBatchesRsvpsListQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: CoffeechatsBatchesRsvpsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCoffeechatsBatchesRsvpsListQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>
  > = ({ signal }) =>
    coffeechatsBatchesRsvpsList(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsBatchesRsvpsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>
>;
export type CoffeechatsBatchesRsvpsListQueryError = ErrorType<unknown>;

export const useCoffeechatsBatchesRsvpsList = <
  TData = Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: CoffeechatsBatchesRsvpsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsBatchesRsvpsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsBatchesRsvpsListQueryOptions(
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Expose a /me route that always returns just records owned by the requesting user or tenant
 */
export const coffeechatsMeList = (
  params?: CoffeechatsMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatRSVPList>(
    { url: `/api/v1/coffeechats/me/`, method: "GET", params, signal },
    options
  );
};

export const getCoffeechatsMeListQueryKey = (
  params?: CoffeechatsMeListParams
) => {
  return [`/api/v1/coffeechats/me/`, ...(params ? [params] : [])] as const;
};

export const getCoffeechatsMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof coffeechatsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCoffeechatsMeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof coffeechatsMeList>>
  > = ({ signal }) => coffeechatsMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof coffeechatsMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CoffeechatsMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof coffeechatsMeList>>
>;
export type CoffeechatsMeListQueryError = ErrorType<unknown>;

export const useCoffeechatsMeList = <
  TData = Awaited<ReturnType<typeof coffeechatsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: CoffeechatsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coffeechatsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCoffeechatsMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
