/**
 *
 *
 * <CurrentChallenges />
 *
 *
 */
import React from "react";

import { ChallengeList } from "../../components/ChallengeListV3";
import { CreateChallenge } from "./CreateChallenge";
import { Header } from "./Header";
import { useChallenges } from "./useChallenges";

export const ChallengeTemplates = (props: ReturnType<typeof useChallenges>) => {
  return (
    <div className="animate-crossfadein space-y-6 py-8">
      <div className="relative w-full px-2 sm:px-8">
        <Header
          section={
            props.currentChallenges.length === 0 &&
            props.upcomingChallenges.length === 0 &&
            props.completedChallenges.length === 0
              ? "templates"
              : "templates_normal"
          }
          count={props.templates?.length || 0}
          isTemplate
        />
      </div>
      <ChallengeList
        challenges={props.templates}
        isLoadingChallenges={props.isLoading}
        section="templates"
        className="flex w-full flex-wrap justify-center gap-y-10"
        onTemplatePreview={props.onTemplatePreview}
        onSelect={props.onSelectTemplate}
      />
      <CreateChallenge {...props} />
    </div>
  );
};
