/**
 *
 *
 * <ContactInfo />
 *
 *
 */
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { CopyButton } from "../../components/CopyButton";
import { IconLinkedIn } from "../../components/IconLinkedIn";
import {
  PeopleRetrieve,
  PhoneNumber,
  UserGuides,
} from "../../services/teambuilder/schemas";
import { ContactInfoForm } from "../ContactInfoForm";
import { Section } from "./Section";

interface Props {
  user?: PeopleRetrieve;
  userGuide?: UserGuides;
  phones?: PhoneNumber[];
  userIsMe: boolean;
  isContactFormOpen: boolean;
  closeContactForm: () => void;
  openContactInfoForm: () => void;
}

export const ContactInfo = (props: Props) => {
  const { t } = useTranslation();
  const rowStyle = clsx("flex gap-2 px-4 py-2 odd:bg-white even:bg-zinc-50");
  return (
    <Section
      title={t("translation:user_guide:contact_info")}
      onEditClick={props.openContactInfoForm}
      showEditButton={props.userIsMe}
    >
      {props.isContactFormOpen && props.user?.id ? (
        <ContactInfoForm
          userId={props.user.id}
          onCancel={props.closeContactForm}
        />
      ) : (
        <ul
          className={clsx(
            "list-none rounded-md border border-zinc-300 text-sm",
            "grow overflow-hidden"
          )}
        >
          {props.user?.email && (
            <li
              className={rowStyle}
              title={props.user?.email}
              data-tooltip-id="email"
              data-tooltip-content={props.user?.email}
              data-tooltip-place="bottom"
              data-tooltip-delay-show={500}
            >
              <span className="sr-only">{t("translation:common:email")}</span>
              <EnvelopeIcon className="h-5 w-5 shrink-0 text-zinc-400" />{" "}
              <div className="grow truncate text-zinc-900">
                {props.user.email}
              </div>
              <CopyButton value={props.user.email}>
                <DocumentDuplicateIcon className="h-5 w-5 text-zinc-500" />
              </CopyButton>
            </li>
          )}

          {props.userGuide?.linkedinUrl && (
            <li className={rowStyle}>
              <span className="sr-only">LinkedIn URL</span>
              <IconLinkedIn className="h-5 w-5 shrink-0 fill-zinc-400" />{" "}
              <a
                href={props.userGuide?.linkedinUrl}
                target="_blank"
                rel="noreferrer"
                className="grow truncate font-medium text-zinc-900 underline hover:text-zinc-500"
                title={props.userGuide.linkedinUrl}
              >
                LinkedIn
              </a>
              <CopyButton value={props.userGuide.linkedinUrl}>
                <DocumentDuplicateIcon className="h-5 w-5 text-zinc-500" />
              </CopyButton>
            </li>
          )}

          {props.phones &&
            props.phones.length > 0 &&
            props.phones.map((phone, i) => (
              <li key={i} className={rowStyle}>
                <span className="sr-only">LinkedIn URL</span>

                <PhoneIcon className="h-5 w-5 shrink-0 text-zinc-400" />

                <div className="flex grow flex-col gap-2 text-zinc-900 sm:flex-row sm:gap-1">
                  <div className="font-medium capitalize">
                    {phone.label?.toLowerCase()}
                  </div>
                  <div>{phone.phoneNumber}</div>
                </div>

                <CopyButton value={phone.phoneNumber}>
                  <DocumentDuplicateIcon className="h-5 w-5 text-zinc-500" />
                </CopyButton>
              </li>
            ))}
        </ul>
      )}
    </Section>
  );
};
