import {
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  UserMinusIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { _PeopleResponse } from "../../services/teambuilder/schemas";

interface Props {
  member: _PeopleResponse;
  onEdit: () => void;
  onRemove: () => void;
  onEmailInvite: () => void;
}

export const UserDropdown = (props: Props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu
      items={[
        {
          name: t("translation:people:edit", {
            firstName: props.member.firstName,
            lastName: props.member.lastName,
          }),
          icon: PencilSquareIcon,
          onClick: props.onEdit,
        },
        ...(!props.member.dateJoined
          ? [
              {
                name: t("translation:people:invite", {
                  firstName: props.member.firstName,
                }),
                icon: EnvelopeIcon,
                onClick: props.onEmailInvite,
              },
            ]
          : []),

        {
          name: t("translation:people:deactivate", {
            firstName: props.member.firstName,
          }),
          icon: UserMinusIcon,
          onClick: props.onRemove,
        },
      ]}
      menuButtonContent={(open) => (
        <EllipsisHorizontalIcon
          className={clsx(
            "h-5 w-5 group-hover/person-menu:text-zinc-600",
            open ? "text-zinc-600" : "text-zinc-500"
          )}
          aria-hidden="true"
        />
      )}
      menuButtonClassNames={(open) =>
        clsx(
          "group/person-menu relative rounded-md hover:bg-zinc-100",
          open && "bg-zinc-100"
        )
      }
      menuClassNames="right-0 top-4 mt-2 origin-top-right"
    />
  );
};
