/**
 *
 *
 * useQuestionModal
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { SearchParam, TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  useUserguidesQuestionsCreate,
  useUserguidesQuestionsPartialUpdate,
  useUserguidesQuestionsRetrieve,
} from "../../services/teambuilder/endpoints/userguides/userguides";
import {
  PaginatedQuestionModelList,
  PatchedQuestionModelRequest,
  QuestionModel,
  QuestionModelRequest,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

type Props = {
  queryKey: readonly unknown[];
};

const schema = z.object({
  question: z.string().min(1, "translation:validation:required_question"),
});

export type FormData = z.infer<typeof schema>;

export const useQuestionModal = (props: Props) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has(SearchParam.SHOW_QUESTION_MODAL);
  const questionId = Number(searchParams.get(SearchParam.SHOW_QUESTION_MODAL));

  const { openToast } = useToast();
  const showToast = (message: string) => {
    openToast({
      title: message,
    });
  };

  const { data: question } = useUserguidesQuestionsRetrieve(questionId, {
    query: {
      enabled: questionId > 0,
    },
  });

  /**
   * Form
   */
  const { handleSubmit, ...form } = useForm<FormData>({
    defaultValues: {
      question: undefined,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    form.reset({});
  }, [isOpen]);

  useEffect(() => {
    if (question) {
      form.reset({ question: question.question });
    }
  }, [question]);

  const onSubmit = handleSubmit((formData: FormData) => {
    const { question } = formData;
    if (!question) return;
    if (questionId > 0) {
      updateQuestion({ id: questionId, data: { question } });
    } else {
      createQuestion({
        data: {
          question,
          team: TEAM_EVERYONE_ID,
        },
      });
    }
  });

  /**
   * onClose
   */
  const onClose = () => {
    searchParams.delete(SearchParam.SHOW_QUESTION_MODAL);
    setSearchParams(searchParams);
  };

  /**
   *
   * Create
   *
   */
  // Define generics for <error, context>. Context is the return location of onMutate.
  // Context is passed from onMutate to onSuccess and onError.
  const { mutate: createQuestion } = useUserguidesQuestionsCreate(
    optimisticMutationOptions<
      QuestionModel,
      PaginatedQuestionModelList,
      { data: QuestionModelRequest }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        // Reset the form
        if (!requestVariable?.data) return context;
        onClose();
        return {
          meta: context.meta,
          data: [requestVariable?.data, ...context.data] as QuestionModel[],
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:question_added_success"));
      },
      onError: () => {
        showToast(t("translation:toast:question_add_failed"));
      },
    })
  );

  /**
   *
   * Update
   *
   */
  const { mutate: updateQuestion } = useUserguidesQuestionsPartialUpdate(
    optimisticMutationOptions<
      QuestionModel,
      PaginatedQuestionModelList,
      { id: number; data: PatchedQuestionModelRequest }
    >({
      queryKey: props.queryKey,
      optimisticUpdateFn: (context, requestVariable) => {
        onClose();
        return {
          meta: context.meta,
          data: context.data.reduce<QuestionModel[]>((acc, question) => {
            let v = question;
            if (question.id === requestVariable?.id) {
              v = { ...question, ...requestVariable?.data } as QuestionModel;
            }
            return [...acc, v];
          }, []),
        };
      },
      onSuccess: () => {
        showToast(t("translation:toast:question_update_success"));
      },
      onError: () => {
        showToast(t("translation:toast:question_update_failed"));
      },
    })
  );

  return {
    isOpen,
    form,
    questionId,
    question,
    onSubmit,
    onClose,
  };
};
