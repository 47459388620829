/**
 *
 *
 * Language
 *
 *
 */
import clsx from "clsx";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { MenuButtonContent } from "./MenuButtonContent";
import { useLanguage } from "./useLanguage";

export const Language = (props: ReturnType<typeof useLanguage>) => {
  return (
    <>
      {props.me && (
        <DropdownMenu
          items={props.languageNavItems}
          menuButtonContent={(open) => (
            <MenuButtonContent
              open={open}
              isCollapsed={props.isCollapsed}
              currentLanguage={props.selectedLanguage?.name}
            />
          )}
          menuButtonClassNames={(open) =>
            clsx(
              "flex w-full flex-row rounded-md px-3 py-2 text-zinc-900 hover:bg-zinc-100",
              "group/nav-item",
              props.isCollapsed
                ? "items-start justify-center gap-0"
                : "items-stretch gap-3",
              open && "bg-zinc-100"
            )
          }
          menuClassNames={clsx(
            "left-0 mt-2 origin-top-left",
            props.isCollapsed ? "top-12" : "top-9"
          )}
        />
      )}
    </>
  );
};
