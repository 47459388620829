/**
 *
 *
 * <MenuItem />
 *
 *
 */
import { Menu } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

import { NavItemV3 } from "../../types";
import {
  isButtonNavItem,
  isCustomNavItem,
  isExternalNavItem,
  isInternalNavItem,
} from "../../utils/type-guards";

export const MenuItem = (props: NavItemV3) => {
  const itemStyles = clsx(
    "group/menu-item flex gap-2 px-2 py-2",
    "w-full text-sm text-zinc-900 hover:bg-zinc-100",
    "focus:outline-amber-700",
    props.isActive && "bg-zinc-100"
  );
  const iconStyles = (isActive?: boolean) =>
    clsx(
      "h-5 w-5 group-hover/menu-item:text-zinc-500",
      isActive || props.isActive ? "text-zinc-500" : "text-zinc-300"
    );

  return (
    <Menu.Item>
      {isInternalNavItem(props) ? (
        <NavLink
          to={props.to}
          className={({ isActive }) =>
            clsx(itemStyles, isActive && "bg-zinc-100")
          }
          onClick={props.onClick}
        >
          {({ isActive }) => (
            <>
              {props.icon && <props.icon className={iconStyles(isActive)} />}
              {props.name}
            </>
          )}
        </NavLink>
      ) : isExternalNavItem(props) ? (
        <a
          href={props.href}
          className={itemStyles}
          target="_blank"
          rel="noreferrer"
          onClick={props.onClick}
        >
          {props.icon && <props.icon className={iconStyles()} />}
          {props.name}
        </a>
      ) : isCustomNavItem(props) ? (
        <button
          type="button"
          onClick={props.onClick}
          className={clsx(itemStyles, "px-0 py-0")}
        >
          {props.icon && <props.icon className={iconStyles()} />}
          {props.content && <props.content />}
        </button>
      ) : isButtonNavItem(props) ? (
        <button type="button" onClick={props.onClick} className={itemStyles}>
          {props.icon && <props.icon className={iconStyles()} />}
          {props.name}
        </button>
      ) : null}
    </Menu.Item>
  );
};
