/**
 *
 *
 * <CoverImageUpload />
 *
 *
 */
import {
  ArrowPathIcon,
  CameraIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { HTMLProps, RefCallback } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import ReactSlider from "react-slider";

import { Button } from "../../components/ButtonV3";
import { useCoverImageUpload } from "./useCoverImageUpload";

export interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
  ref: RefCallback<T>;
}

export const CoverImageUpload = (
  props: ReturnType<typeof useCoverImageUpload>
) => {
  const { t } = useTranslation();
  return (
    <div className={props.className}>
      <input
        className="hidden"
        type="file"
        onChange={props.onFileAdded}
        ref={props.inputFileRef}
        accept="image/jpeg,image/png,image/webp,image/tiff,image/tif,image/gif,image/heic,image/heif,application/pdf"
      />
      <div className={clsx("flex flex-col items-center justify-center")}>
        {props.isLoading || props.isHeicToJpg ? (
          <div
            className={clsx(
              "relative",
              "h-24 w-full",
              "overflow-hidden rounded-md bg-zinc-300",
              "animate-pulse"
            )}
          >
            {props.previewImage && (
              <img
                src={
                  props.previewImage
                    ? URL.createObjectURL(props.previewImage)
                    : ""
                }
                alt="avatar"
                className={clsx(
                  "block h-24 w-full rounded-md object-cover object-center"
                )}
              />
            )}
            <div
              className={clsx(
                "h-24 w-full",
                "full absolute left-0 top-0 rounded-md",
                "flex flex-col items-center justify-center text-center",
                "opacity-60",
                "bg-white"
              )}
            >
              <ArrowPathIcon className="block h-8 w-8 animate-spin" />{" "}
              {!props.isHeicToJpg && t("translation:user_guide:uploading_file")}
            </div>
          </div>
        ) : props.isCropping && props.previewImageUrl ? (
          <>
            <div className="relative h-64 w-full overflow-hidden rounded-t-md">
              <Cropper
                image={props.previewImageUrl}
                crop={props.crop}
                zoom={props.zoom}
                aspect={3}
                objectFit="cover"
                cropShape="rect"
                showGrid={false}
                onCropChange={props.onCropChange}
                onCropComplete={props.onCropComplete}
                onZoomChange={props.onZoomChange}
              />
            </div>
            <div className="w-full rounded-b-md bg-white px-4 py-6">
              <ReactSlider
                min={0}
                max={100}
                value={(props.zoom - 1) * 50}
                className="react-slider -mt-1 bg-zinc-400"
                thumbClassName="h-4 w-4 rounded-full bg-zinc-600 -mt-1"
                trackClassName="rounded-full bg-zinc-200 h-2"
                renderThumb={(
                  props: HTMLPropsWithRefCallback<HTMLDivElement>
                ) => <div {...props} />}
                onChange={(v: number) => props.onZoomChange(v / 50 + 1)}
              />
            </div>
          </>
        ) : props.userGuide?.coverImage ? (
          <div
            className={clsx(
              "h-24 w-full rounded-md",
              "relative",
              !props.userGuide?.coverImage
            )}
            style={
              props.userGuide?.coverImage?.large
                ? {
                    background: `url('${props.userGuide?.coverImage?.large}') center/cover no-repeat`,
                  }
                : {}
            }
          />
        ) : (
          <div
            className={clsx(
              "h-24 w-full border-4 border-dashed border-zinc-300",
              "relative flex items-center justify-center"
            )}
            onClick={props.onClickInput}
          >
            <PhotoIcon className="h-8 w-8 text-zinc-400" />
          </div>
        )}

        {/**
         *
         * CTA Buttons
         *
         **/}

        {props.isCropping ? (
          <div className="mt-5 grid w-full grid-cols-2 gap-4">
            <Button
              type="button"
              variant="contained"
              size="md"
              onClick={props.onCropImage}
              disabled={props.isLoading}
              className="col-span-1"
            >
              {t("translation:common:save")}
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="md"
              onClick={props.onCropCancel}
              disabled={props.isLoading}
              className="col-span-1"
            >
              {t("translation:common:cancel")}
            </Button>
          </div>
        ) : props.userGuide?.coverImage ? (
          <div className="mt-5 grid w-full grid-cols-2 gap-4">
            <Button
              type="button"
              variant="contained"
              size="md"
              onClick={props.onClickInput}
              disabled={props.isLoading}
              className="col-span-1"
            >
              <CameraIcon className="h-5 w-5" />
              {t("translation:common:change")}
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="md"
              onClick={props.onDelete}
              disabled={props.isLoading}
              className="col-span-1"
            >
              <XMarkIcon className="h-5 w-5" />
              {t("translation:common:remove")}
            </Button>
          </div>
        ) : (
          <div className="mt-5 flex justify-center">
            <Button
              type="button"
              variant="contained"
              size="md"
              onClick={props.onClickInput}
              disabled={props.isLoading}
            >
              <CameraIcon className="h-5 w-5" />
              {t("translation:common:choose_file")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
