/**
 *
 *
 * <Header />
 *
 *
 */
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  section: string;
  count: number;
  isTemplate?: boolean;
}

export const Header = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <p
        className={clsx(
          "mb-0.5 font-bold text-zinc-900",
          props.section === "current"
            ? "text-2xl leading-8"
            : "text-lg leading-7"
        )}
      >
        {t(
          `translation:challenge:${props.isTemplate ? "templates" : props.section}_challenges_capital${props.count > 1 ? "s" : ""}`
        )}
      </p>
      <p className={clsx("text-sm font-normal leading-5 text-zinc-500")}>
        {t(
          `translation:challenge:${props.section}_challenges_description${props.count > 1 ? (props.isTemplate ? "" : "s") : ""}`
        )}
      </p>
    </div>
  );
};
