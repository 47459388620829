/**
 *
 *
 * <PageProvider />
 *
 *
 */
import React, { createContext, useState } from "react";

interface Props {
  children?: React.ReactNode;
}

interface PageValues {
  title?: string;
  subtitle?: React.ReactNode;
  learnMoreUrl?: string;
  variant?: "oneColumn" | "freeform";
}

interface Context {
  pageContext: PageValues;
  setPageContext: (newPageContext: PageValues) => void;
}

const PageContext = createContext<Context | undefined>(undefined);

export const usePageContext = () => {
  const context = React.useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePage must be used within a PageProvider");
  }
  return context;
};

export const PageProvider = ({ children }: Props) => {
  const [pageContext, set] = useState<PageValues>({
    title: "",
    subtitle: "",
    learnMoreUrl: "",
    variant: "oneColumn",
  });

  const setPageContext = (newPageContext: PageValues) => {
    const { variant } = newPageContext;
    set(variant ? newPageContext : { ...newPageContext, variant: "oneColumn" });
  };

  return (
    <PageContext.Provider
      value={{
        pageContext,
        setPageContext,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
