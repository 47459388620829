/**
 *
 *
 * useMobileNav
 *
 *
 */
import { useMatches } from "react-router-dom";

import { useLayoutContext } from "../../components/LayoutProvider";
import { useNavItems } from "../../hooks/useNavItems";

export const useMobileNav = () => {
  const navItems = useNavItems();

  const matches = useMatches();
  const showCompanySettingsNav = matches.some(
    (match) => match.pathname === "/company-settings"
  );

  const { unreadNotificationsCount } = useLayoutContext();

  return {
    navItems,
    showCompanySettingsNav,
    unreadNotificationsCount,
  };
};
