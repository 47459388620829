import { Avatar } from "../../components/Avatar";

/**
 *
 *
 * <Skeleton />
 *
 *
 */
export const SkeletonNotificationListItem = () => {
  return (
    <li className="relative flex animate-pulse items-start space-x-3 p-4">
      <Avatar loading size="md" />
      <div className="min-w-0 flex-1">
        <div className="h-20 rounded bg-zinc-300" />
      </div>
    </li>
  );
};
