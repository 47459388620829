/**
 *
 *
 * <Card />
 *
 *
 */
import { TrashIcon } from "@heroicons/react/20/solid";
import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavItemV3 } from "types";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { FormData, ValueForm } from "./ValueForm";

interface Props {
  data: {
    id?: number;
    name: string;
    description?: string;
  };
  onDeleteClick?: () => void;
  isCrudEnabled?: boolean;
  dataTestID?: string;
  isEditMode?: boolean;
  toggleEditMode: (id?: number) => void;
  shallResetFormData?: boolean;
  onSubmit: SubmitHandler<FormData>;
}

export const Card = ({
  data,
  onDeleteClick,
  isCrudEnabled,
  dataTestID,
  isEditMode,
  toggleEditMode,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const menuItems: NavItemV3[] = [
    {
      name: t("translation:common:edit"),
      icon: PencilSquareIcon,
      onClick: () => toggleEditMode(data.id),
    },
    {
      name: t("translation:common:delete"),
      icon: TrashIcon,
      onClick: onDeleteClick,
    },
  ];

  return (
    <li
      className="mx-auto max-w-[33rem] rounded-b-lg shadow-md"
      data-testid={dataTestID}
    >
      {!isEditMode && (
        <>
          <div className="flex flex-row items-center justify-between bg-zinc-100 px-6 py-4">
            <h2 className="text-lg font-semibold text-zinc-900">{data.name}</h2>
            {isCrudEnabled && (
              <DropdownMenu
                items={menuItems}
                menuButtonContent={(open) => (
                  <EllipsisHorizontalIcon
                    className={clsx(
                      "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                      open
                        ? "bg-white text-zinc-600"
                        : "bg-zinc-100 text-zinc-500"
                    )}
                  />
                )}
                menuClassNames={clsx("right-0 mt-6 origin-top-right")}
              />
            )}
          </div>
          <div className="rounded-b-lg bg-white px-6 py-4">
            <p className="text-sm text-zinc-500">{data.description}</p>
          </div>
        </>
      )}
      {isEditMode && (
        <ValueForm
          onClose={() => toggleEditMode(data.id)}
          onSubmit={props.onSubmit}
          formData={{
            id: data.id,
            name: data.name,
            description: data.description,
          }}
          isUpdate
        />
      )}
    </li>
  );
};
