/**
 *
 *
 * <TopicCard />
 *
 *
 */
import {
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavItemV3 } from "types";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { TopicsModel } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";

interface Props {
  topic?: TopicsModel;
  className?: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export const TopicListItem = ({
  topic,
  onEditClick,
  onDeleteClick,
  className = "",
}: Props) => {
  const { t } = useTranslation();

  const menuItems: NavItemV3[] = [
    {
      name: t("translation:common:edit"),
      icon: PencilSquareIcon,
      onClick: onEditClick,
    },
    {
      name: t("translation:common:delete"),
      icon: TrashIcon,
      onClick: onDeleteClick,
    },
  ];

  return (
    <li
      className={clsx(
        "flex flex-row items-center justify-between rounded-md bg-zinc-100 px-3 py-2",
        "w-full border border-zinc-300",
        className
      )}
    >
      <div className="flex w-full flex-row gap-1 text-sm">
        <h3 className="flex-grow text-zinc-900">{topic?.topic}</h3>
        <div className="flex flex-row items-center gap-x-2">
          {topic?.postDate && (
            <p className="flex items-center gap-1 whitespace-nowrap text-sm font-normal text-zinc-500">
              {dayjs(topic?.postDate).format("MMM D, YYYY")}
            </p>
          )}
          <DropdownMenu
            items={menuItems}
            menuButtonContent={(open) => (
              <EllipsisHorizontalIcon
                className={clsx(
                  "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                  open
                    ? "bg-zinc-200 text-zinc-600"
                    : "bg-zinc-100 text-zinc-500"
                )}
              />
            )}
            menuClassNames={clsx("right-0 mt-6 origin-top-right")}
          />
        </div>
      </div>
    </li>
  );
};
