/**
 *
 *
 * <NotificationsModal />
 *
 *
 */
import { XMarkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

import { Modal } from "../../components/ModalV3";
import { Notifications } from "../NotificationsV3";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const NotificationsModal = (props: Props) => {
  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      showCloseButton={false}
      className={clsx(
        "max-h-screen w-screen max-w-prose overflow-hidden sm:rounded-lg",
        "bg-white"
      )}
    >
      <div className="flex w-full justify-end px-2 pt-2">
        <button
          onClick={props.onClose}
          className="rounded-md border border-zinc-300 focus:outline-zinc-500"
        >
          <XMarkIcon className="h-6 w-6 text-zinc-300 hover:text-zinc-500" />
        </button>
      </div>

      <Notifications />
    </Modal>
  );
};
