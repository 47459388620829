import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import { LANGUAGE_EN, LANGUAGE_ES } from "../consts";
import * as en from "./en";
import * as es from "./es";

const resources: Resource = {
  [LANGUAGE_EN]: {
    ...en,
  },
  [LANGUAGE_ES]: {
    ...es,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE_EN,
  fallbackLng: {
    [LANGUAGE_EN]: [LANGUAGE_EN],
    default: [LANGUAGE_ES],
  },
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
