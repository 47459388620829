/**
 *
 *
 * useAvatarUpload
 *
 *
 */
import { ChangeEvent, useRef, useState } from "react";
import { Area, Point } from "react-easy-crop";
import { useTranslation } from "react-i18next";

import { useToast } from "../../hooks/useToast";
import {
  useIntuitionUsersMeAvatarCreate,
  useIntuitionUsersMeAvatarDestroy,
} from "../../services/teambuilder/endpoints/intuition/intuition";
import {
  getPeopleRetrieveQueryKey,
  usePeopleRetrieve,
} from "../../services/teambuilder/endpoints/people/people";
import {
  UserAvatarUploadRequest,
  _UserResponse,
} from "../../services/teambuilder/schemas";
import getCroppedImg from "../../utils/crop-image";
import { convertHeic } from "../../utils/heic-to-jpg";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

interface Props {
  userId: number;
  className?: string;
}

export const useAvatarUpload = ({ userId, className }: Props) => {
  const { openToast } = useToast();
  const { t } = useTranslation();

  const { data: user, isLoading: isLoadingUser } = usePeopleRetrieve(userId);
  const queryKey = getPeopleRetrieveQueryKey(userId);

  const [previewImage, setPreviewImage] = useState<Blob>();
  const [previewImageUrl, setPreviewImageUrl] = useState<string>();

  /**
   *
   * File input
   *
   */
  const [isHeicToJpg, setIsHeicToJpg] = useState(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const onClickInput = () => {
    inputFileRef.current?.click();
  };
  const onFileAdded = async (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0] as File;
    if (file.name.match(/.*\.heic$/i)) {
      setIsHeicToJpg(true);
      file = await convertHeic(URL.createObjectURL(file));
      setIsHeicToJpg(false);
    }
    setPreviewImage(file);
    setIsCropping(true);
    if (file) {
      setPreviewImageUrl(URL.createObjectURL(file));
    }
    if (inputFileRef.current) inputFileRef.current.value = "";
  };

  /**
   *
   * Crop
   *
   */
  const [isCropping, setIsCropping] = useState(false);
  const [crop, onCropChange] = useState<Point>({
    x: 0,
    y: 0,
  });
  const [zoom, onZoomChange] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropStart = () => {
    if (previewImage) {
      setIsCropping(true);
    }
  };
  const onCropCancel = () => setIsCropping(false);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCropImage = async () => {
    if (!previewImageUrl || !croppedAreaPixels) return;
    const croppedImage = await getCroppedImg(
      previewImageUrl,
      croppedAreaPixels
    );
    if (!croppedImage) return;
    setPreviewImage(croppedImage);
    const file = new File([croppedImage], "avatar.png");
    createAvatar({ data: { file } });
  };

  /**
   *
   * Create avatar
   *
   */
  const { mutate: createAvatar, isLoading: isLoadingCreateAvatar } =
    useIntuitionUsersMeAvatarCreate(
      optimisticMutationOptions<
        void | _UserResponse,
        _UserResponse,
        { data: UserAvatarUploadRequest }
      >({
        queryKey,
        optimisticUpdateFn: (context, requestVariable) => {
          if (!requestVariable?.data.file) return context;
          setIsCropping(false);
          const imageUrl = URL.createObjectURL(requestVariable?.data.file);
          return {
            ...context,
            avatar: {
              thumbnail: imageUrl,
              medium: imageUrl,
              large: imageUrl,
            },
          };
        },
        onSuccess: () => {
          openToast({
            title: "",
            description: t("translation:toast:avatar_save_success"),
          });
        },
        onError: (_context, _requestVariable, error) => {
          if (error?.response?.status === 413) {
            openToast({
              title: t("translation:common:error"),
              description: t("translation:toast:avatar_save_filesize_failed"),
              type: "danger",
            });
          } else {
            openToast({
              title: t("translation:common:error"),
              description: t("translation:toast:avatar_save_failed"),
              type: "danger",
            });
          }
        },
      })
    );

  /**
   *
   *
   * Delete avatar
   *
   */
  const { mutate: deleteAvatar } = useIntuitionUsersMeAvatarDestroy(
    optimisticMutationOptions<void | _UserResponse, _UserResponse, void>({
      queryKey,
      optimisticUpdateFn: (context) => {
        return { ...context, avatar: null };
      },
      onSuccess: () => {
        openToast({
          title: "",
          description: t("translation:toast:avatar_delete_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:avatar_delete_failed"),
          type: "danger",
        });
      },
    })
  );

  const onDelete = () => {
    deleteAvatar();
  };

  return {
    className,
    user,
    isLoadingUser,

    inputFileRef,
    onClickInput,
    onFileAdded,

    isLoadingCreateAvatar,
    previewImage,
    isHeicToJpg,
    onDelete,
    isCropping,
    crop,
    onCropStart,
    onCropCancel,
    onCropComplete,
    onCropImage,
    onCropChange,
    zoom,
    onZoomChange,
    previewImageUrl,
  };
};
