/**
 *
 *
 * <NavItem />
 *
 *
 */
import clsx from "clsx";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import { NavItemV3 } from "../../types";
import {
  isButtonNavItem,
  isCustomNavItem,
  isExternalNavItem,
  isInternalNavItem,
} from "../../utils/type-guards";
import { CountBadge } from "../CountBadge";
import { Icon } from "./Icon";

interface Props {
  item: NavItemV3;
  collapsed?: boolean;
  rounded?: boolean;
}

export const NavItem = (props: Props) => {
  const linkStyles = useMemo(
    () =>
      clsx(
        "px-3 py-2",
        "relative flex",
        "text-sm font-medium text-zinc-900",
        "hover:bg-zinc-100",
        "group/nav-item",
        props.collapsed ? "gap-0" : "w-full gap-3",
        props.rounded && "rounded-md"
      ),
    [props.collapsed]
  );

  return isInternalNavItem(props.item) ? (
    <NavLink
      to={props.item.to}
      className={({ isActive }) =>
        clsx(linkStyles, isActive ? "bg-zinc-100" : "bg-transparent")
      }
      onClick={props.item.onClick}
    >
      {({ isActive }) => (
        <>
          <Icon
            item={props.item}
            isCollapsed={props.collapsed}
            isActive={isActive}
          />
          {!props.collapsed && (
            <div className="grow text-left">{props.item.name}</div>
          )}
          {props.item.alertCount ? (
            <CountBadge
              count={props.item.alertCount}
              isCollapsed={props.collapsed}
            />
          ) : null}
        </>
      )}
    </NavLink>
  ) : isExternalNavItem(props.item) ? (
    <a
      href={props.item.href}
      className={linkStyles}
      target="_blank"
      rel="noreferrer"
      onClick={props.item.onClick}
    >
      <Icon item={props.item} isCollapsed={props.collapsed} />
      {!props.collapsed && (
        <div className="grow text-left">{props.item.name}</div>
      )}
      {props.item.alertCount ? (
        <CountBadge
          count={props.item.alertCount}
          isCollapsed={props.collapsed}
        />
      ) : null}
    </a>
  ) : isCustomNavItem(props.item) ? (
    <div className={linkStyles} onClick={props.item.onClick}>
      <Icon item={props.item} isCollapsed={props.collapsed} />
      {props.item.alertCount ? (
        <CountBadge
          count={props.item.alertCount}
          isCollapsed={props.collapsed}
        />
      ) : null}
    </div>
  ) : isButtonNavItem(props.item) ? (
    <button className={linkStyles} onClick={props.item.onClick}>
      <Icon item={props.item} isCollapsed={props.collapsed} />
      {!props.collapsed && (
        <div className="grow text-left">{props.item.name}</div>
      )}
      {props.item.alertCount ? (
        <CountBadge
          count={props.item.alertCount}
          isCollapsed={props.collapsed}
        />
      ) : null}
    </button>
  ) : null;
};
