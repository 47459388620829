/**
 *
 *
 * <People />
 *
 *
 */

export const SkeletonPeople = () => {
  return (
    <div className="grid h-14 w-full animate-pulse grid-cols-[auto_1fr_auto] items-center space-x-3 px-4 py-2">
      <span className="h-8 w-8 rounded-full bg-zinc-200" />
      <div className="space-y-1">
        <div className="h-4 w-20 rounded-lg bg-zinc-200" />
        <div className="h-4 w-20 rounded-lg bg-zinc-200" />
      </div>
      <span className="h-4 w-10 rounded-lg bg-zinc-200" />
    </div>
  );
};
