/**
 *
 *
 * <ModalImportUsers />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { IconG } from "../../components/IconG";
import { IconMicro365 } from "../../components/IconMicro365";
import { Button } from "../Button";
import { Modal } from "../Modal";

export type Variant = "success" | "danger" | "warn";

export interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onImportFromGoogle: () => void;
  onImportFromMicrosoft: () => void;
}

export const ModalImportUsers = (props: Props) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onCancel}
      ref={cancelButtonRef}
      showCloseButton
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-sm sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="text-center sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-lg font-semibold leading-6 text-zinc-900"
            >
              {t("translation:people:import_users")}
            </Dialog.Title>

            <div className="mt-2 space-y-4 text-sm text-zinc-300">
              <p>{t("translation:people:import_users_decription_1")}</p>
              <p>{t("translation:people:import_users_decription_2")}</p>
              <p className="italic">
                {t("translation:people:import_users_decription_3")}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 flex flex-col justify-center gap-3 sm:mt-4 sm:justify-start">
          <Button
            variant="outlined"
            size="md"
            onClick={props.onImportFromGoogle}
            className="justify-start"
            dark
          >
            <div className="flex w-full items-center gap-2">
              <IconG />
              {t("translation:people:import_from_google")}
            </div>
          </Button>

          <Button
            variant="outlined"
            size="md"
            onClick={props.onImportFromMicrosoft}
            className="justify-start"
            ref={cancelButtonRef}
            dark
          >
            <div className="flex w-full items-center gap-2">
              <IconMicro365 />
              {t("translation:people:import_from_microsoft")}
            </div>
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
