/**
 *
 *
 * Log the user out.
 *
 *
 */
export const logout = () => {
  localStorage.removeItem(import.meta.env.VITE_LOCALSTORAGE_AUTH_TOKEN_KEY);
  window.location.href = "/api/v1/intuition/users/logout/";
};
