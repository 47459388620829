/**
 *
 *
 * <MobileNav />
 *
 *
 */
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment } from "react";

import { CountBadge } from "../../components/CountBadge";
import { CompanySettingsNav } from "./CompanySettingsNav";
import { PrimaryNav } from "./PrimaryNav";
import { useMobileNav } from "./useMobileNav";

export const MobileNav = (props: ReturnType<typeof useMobileNav>) => {
  return (
    <Menu as="nav" className="relative">
      {({ open, close }) => (
        <>
          <Menu.Button
            className={clsx(
              "group/mobile-nav-toggle border border-zinc-300 p-2 hover:bg-zinc-100",
              "relative rounded-md",
              open ? "bg-zinc-100" : "bg-white"
            )}
          >
            <Bars3Icon className="h-6 w-6 text-zinc-500 group-hover/mobile-nav-toggle:text-zinc-500" />

            {props.unreadNotificationsCount > 0 && (
              <CountBadge count={props.unreadNotificationsCount} isCollapsed />
            )}
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                "absolute z-10 mt-2",
                "bg-white shadow-lg focus:outline-none",
                "-left-2.5 top-12 origin-top-left",
                "h-max w-max min-w-72 overflow-y-auto"
              )}
              style={{ maxHeight: "calc(100vh - 66px)" }}
            >
              {props.showCompanySettingsNav ? (
                <CompanySettingsNav navItems={props.navItems} close={close} />
              ) : (
                <PrimaryNav navItems={props.navItems} close={close} />
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
