/**
 *
 *
 * <Breadcrumbs />
 *
 *
 */
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

type Path = {
  name: string;
  to: string;
};

interface Props {
  paths: Path[];
}

export const Breadcrumbs = (props: Props) => {
  return (
    <div className="flex flex-shrink-0 flex-row items-center gap-2 px-4 py-4 text-sm sm:px-8 md:text-base lg:text-base">
      {props.paths.map((path, index) =>
        index === props.paths.length - 1 ? (
          <span key={path.to} className="text-zinc-600">
            {path.name}
          </span>
        ) : (
          <Fragment key={path.to}>
            <Link
              className="font-medium text-zinc-400 underline hover:text-zinc-500"
              to={path.to}
            >
              {path.name}
            </Link>
            <ChevronRightIcon className="h-7 w-7 text-zinc-400" />
          </Fragment>
        )
      )}
    </div>
  );
};
