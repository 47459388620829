/**
 *
 *
 * <CompanySettingsNav />
 *
 *
 */
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { NavItem } from "../../components/NavItem";
import { useNavItems } from "../../hooks/useNavItems";

interface Props {
  navItems: ReturnType<typeof useNavItems>;
  close: () => void;
}

export const CompanySettingsNav = (props: Props) => {
  return (
    <>
      <Link
        to="/"
        className="group/back-home flex grow-0 items-center gap-2 px-2 py-5 text-lg text-zinc-900"
      >
        <ChevronLeftIcon
          className={clsx(
            "h-7 w-7",
            "group-hover/back-home:bg-zinc-100 group-hover/back-home:text-zinc-500",
            "rounded-md text-zinc-300"
          )}
        />{" "}
        Company Settings
      </Link>

      <div className="flex flex-col gap-2.5">
        {props.navItems.companySettings.map((item, i) => (
          <NavItem key={i} item={{ ...item, onClick: props.close }} />
        ))}
      </div>
    </>
  );
};
