/**
 *
 *
 * <CompanyPrefsForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../components/CheckboxV3";
import { DomainList } from "../DomainListV3";
import { useCompanyPrefsForm } from "./useCompanyPrefsForm";

// translation
export const CompanyPrefsForm = (
  props: ReturnType<typeof useCompanyPrefsForm>
) => {
  const { t } = useTranslation();
  return (
    <div className="animate-crossfadein">
      <div className="px-8 py-12">
        <div className="mb-6">
          <div className="flex w-full items-center gap-2">
            <Checkbox
              id="isOpen"
              {...props.form.register("isOpen")}
              onChange={props.onChangeIsOpen}
            />
            <div>
              <label
                htmlFor="isOpen"
                className="block text-sm font-medium leading-6 text-zinc-900"
              >
                {t("translation:settings:company:enable_open_enrollment")}
              </label>
            </div>
          </div>
          <div className="ml-6 text-sm font-normal leading-6 text-zinc-500">
            {t("translation:settings:company:open_enrollment_description_1_v3")}
          </div>
        </div>
        {props.prefs?.isOpen && (
          <div className="flex-1">
            <p className="block text-sm font-medium leading-6 text-zinc-900">
              {t("translation:settings:company:allowed_domains")}
            </p>
            <p className="text-sm leading-6 text-zinc-500">
              {t(
                "translation:settings:company:allowed_domains_description_1_v3"
              )}
            </p>
            <p className="text-sm italic leading-6 text-zinc-500">
              {t(
                "translation:settings:company:allowed_domains_description_2_v3"
              )}
            </p>
            <DomainList />
          </div>
        )}
      </div>
    </div>
  );
};
