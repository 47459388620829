/**
 *
 *
 * ViewMode <ViewMode />
 *
 *
 *
 */
import { Bars3Icon, Squares2X2Icon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ViewModeEnum } from "../../utils/enums";

interface Props {
  viewMode: string;
  setViewMode: (value: ViewModeEnum) => void;
}

export const ViewMode = ({ viewMode, setViewMode }: Props) => {
  const { t } = useTranslation();
  const VIEW_MODES = [
    {
      value: ViewModeEnum.VIEW_MODE_LIST,
      label: t("translation:common:list"),
      icon: Bars3Icon,
    },
    {
      value: ViewModeEnum.VIEW_MODE_GRID,
      label: t("translation:common:grid"),
      icon: Squares2X2Icon,
    },
  ];
  return (
    <div className="flex flex-row">
      {VIEW_MODES.map(({ icon: Icon, value, label }, index) => (
        <div
          key={value}
          className={clsx(
            "flex max-w-7xl cursor-pointer items-center p-1",
            index === 0
              ? "rounded-l-lg"
              : index === VIEW_MODES.length - 1
                ? "rounded-r-lg border-l-0"
                : "border-l-0",
            "border border-slate-300",
            viewMode === value ? "bg-zinc-200" : "bg-white"
          )}
          onClick={() => setViewMode(value)}
          data-tooltip-id="tooltip"
          data-tooltip-content={label}
          data-tooltip-place="bottom"
        >
          <Icon className={clsx("h-4 w-4")} />
        </div>
      ))}
    </div>
  );
};
