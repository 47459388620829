/**
 *
 *
 * <SlideOverForm />
 *
 *
 */
import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  footer: React.ReactNode;
}

/**
 * Form styled for inclusion in SlideOver.
 *
 * @example
 * <SlideOver ... >
 *   <SlideOverHeader ... />
 *   <SlideOverForm ... >
 *     <SlideOverFormInputGroup ... />
 *   </SlideOverForm>
 * </SlideOver>
 */
export const SlideOverForm = ({ footer, children, ...props }: Props) => {
  return (
    <form
      className="relative flex h-full flex-col overflow-y-scroll border-t border-zinc-300 bg-white shadow-xl"
      {...props}
    >
      <div className="flex-1">
        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-zinc-300 sm:py-0">
          {children}
        </div>
      </div>

      <div className="flex-shrink-0 border-t border-zinc-300 bg-zinc-100 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">{footer}</div>
      </div>
    </form>
  );
};
