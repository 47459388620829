/**
 *
 *
 * useDebounceValue.ts
 *
 *
 */
import { useEffect, useState } from "react";

/**
 * Returns a state value that updates after a delay. If the value changes before
 * the timer runs out it is cancelled and the timer is restarted.
 */
const useDebounceValue = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  let timer: NodeJS.Timeout;

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounceValue;
