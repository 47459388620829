/**
 *
 *
 * <InfoListItem />
 *
 */
import React from "react";

import { HeroIcon } from "../../types";

interface Props {
  name: string;
  description: string;
  icon: HeroIcon;
}

export const InfoListItem = (props: Props) => {
  return (
    <li className="flex flex-col gap-1 sm:flex-row">
      <div className="flex gap-2">
        <props.icon className="h-5 w-5 text-zinc-400" />
        <div className="font-medium text-zinc-500">
          {props.name}
          <span className="hidden sm:inline">:</span>
        </div>
      </div>
      <div className="pl-8 text-zinc-900 sm:pl-0">{props.description}</div>
    </li>
  );
};
