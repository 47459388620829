/**
 *
 *
 * useBadges
 *
 *
 */
import { useRecognitionBadgesEarnedList } from "../../services/teambuilder/endpoints/recognition/recognition";
import { PeopleRetrieve } from "../../services/teambuilder/schemas";

interface Props {
  user: PeopleRetrieve;
}

export const useBadges = (props: Props) => {
  /**
   * badges
   */
  const { data: badges, isInitialLoading: isLoadingBadges } =
    useRecognitionBadgesEarnedList(
      { limit: 999, user: props.user.id },
      { query: { refetchOnMount: "always" } }
    );

  return {
    isLoading: isLoadingBadges,
    badges,
  };
};
