/**
 *
 * useCreateChallenge
 *
 */
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ChallengeRequest, Template } from "services/teambuilder/schemas";

import { SearchParam, TEAM_EVERYONE_ID } from "../consts";
import { useChallengesCreate } from "../services/teambuilder/endpoints/challenges/challenges";

export const useCreateChallenge = () => {
  const navigate = useNavigate();

  /**
   * create challenge
   */
  const { mutate: createChallenge, isLoading: isCreatingChallenge } =
    useChallengesCreate({
      mutation: {
        onSuccess: ({ id }) => {
          // Forward use to the challenge wizard activities page
          navigate(
            `/challenges/wizard/add-activities?${SearchParam.CHALLENGE}=${id}`
          );
        },
      },
    });

  const onCreateDraftChallenge = (template?: Template) => {
    if (!template) return;
    const now = dayjs();
    const year = new Date().getFullYear();
    const defaultStart = now
      .add(1, "day")
      .set("hour", 8)
      .set("minute", 0)
      .set("second", 0);
    let startAt =
      Number.isInteger(template.startDay) &&
      Number.isInteger(template.startMonth)
        ? dayjs(`${year}-${template.startMonth}-${template.startDay}T08:00`)
        : defaultStart;
    if (startAt.isBefore(now)) startAt = defaultStart;
    const defaultEnd = now
      .add(14, "day")
      .set("hour", 17)
      .set("minute", 30)
      .set("second", 0);
    let endAt =
      Number.isInteger(template.endDay) && Number.isInteger(template.endMonth)
        ? dayjs(`${year}-${template.endMonth}-${template.endDay}T17:30`)
        : defaultEnd;
    if (endAt.isBefore(now)) endAt = defaultEnd;
    const data: ChallengeRequest = {
      template: template.id,
      name: template.name,
      team: TEAM_EVERYONE_ID,
      selectedActivityIds: template.activities?.map((activity) => activity.id!),
      isDraft: true,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
    };
    createChallenge({ data });
  };

  return {
    isCreatingChallenge,
    onCreateDraftChallenge,
  };
};
