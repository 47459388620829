/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  BanterTopicsListParams,
  PaginatedTopicsModelList,
  PatchedTopicsModelRequest,
  TopicsModel,
  TopicsModelRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Team BanterConfig Topics

On a periodic (e.g., daily) basis, these topics are posted to Slack or MSTeams to encourage discussion.
Intuition curates a set of default topics and links them to the Team, and a TeamOwner can add their
own to use in addition to or instead of the Intuition-curated topics.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const banterTopicsList = (
  params?: BanterTopicsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTopicsModelList>(
    { url: `/api/v1/banter/topics/`, method: "GET", params, signal },
    options
  );
};

export const getBanterTopicsListQueryKey = (
  params?: BanterTopicsListParams
) => {
  return [`/api/v1/banter/topics/`, ...(params ? [params] : [])] as const;
};

export const getBanterTopicsListQueryOptions = <
  TData = Awaited<ReturnType<typeof banterTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: BanterTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banterTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBanterTopicsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banterTopicsList>>
  > = ({ signal }) => banterTopicsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof banterTopicsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanterTopicsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof banterTopicsList>>
>;
export type BanterTopicsListQueryError = ErrorType<unknown>;

export const useBanterTopicsList = <
  TData = Awaited<ReturnType<typeof banterTopicsList>>,
  TError = ErrorType<unknown>,
>(
  params?: BanterTopicsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banterTopicsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanterTopicsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team BanterConfig Topics

On a periodic (e.g., daily) basis, these topics are posted to Slack or MSTeams to encourage discussion.
Intuition curates a set of default topics and links them to the Team, and a TeamOwner can add their
own to use in addition to or instead of the Intuition-curated topics.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const banterTopicsCreate = (
  topicsModelRequest: TopicsModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TopicsModel>(
    {
      url: `/api/v1/banter/topics/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: topicsModelRequest,
    },
    options
  );
};

export const getBanterTopicsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsCreate>>,
    TError,
    { data: TopicsModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof banterTopicsCreate>>,
  TError,
  { data: TopicsModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banterTopicsCreate>>,
    { data: TopicsModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return banterTopicsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BanterTopicsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banterTopicsCreate>>
>;
export type BanterTopicsCreateMutationBody = TopicsModelRequest;
export type BanterTopicsCreateMutationError = ErrorType<unknown>;

export const useBanterTopicsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsCreate>>,
    TError,
    { data: TopicsModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBanterTopicsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team BanterConfig Topics

On a periodic (e.g., daily) basis, these topics are posted to Slack or MSTeams to encourage discussion.
Intuition curates a set of default topics and links them to the Team, and a TeamOwner can add their
own to use in addition to or instead of the Intuition-curated topics.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const banterTopicsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TopicsModel>(
    { url: `/api/v1/banter/topics/${id}/`, method: "GET", signal },
    options
  );
};

export const getBanterTopicsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/banter/topics/${id}/`] as const;
};

export const getBanterTopicsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof banterTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banterTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBanterTopicsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banterTopicsRetrieve>>
  > = ({ signal }) => banterTopicsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof banterTopicsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanterTopicsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof banterTopicsRetrieve>>
>;
export type BanterTopicsRetrieveQueryError = ErrorType<unknown>;

export const useBanterTopicsRetrieve = <
  TData = Awaited<ReturnType<typeof banterTopicsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banterTopicsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanterTopicsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team BanterConfig Topics

On a periodic (e.g., daily) basis, these topics are posted to Slack or MSTeams to encourage discussion.
Intuition curates a set of default topics and links them to the Team, and a TeamOwner can add their
own to use in addition to or instead of the Intuition-curated topics.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const banterTopicsPartialUpdate = (
  id: number,
  patchedTopicsModelRequest: PatchedTopicsModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TopicsModel>(
    {
      url: `/api/v1/banter/topics/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedTopicsModelRequest,
    },
    options
  );
};

export const getBanterTopicsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedTopicsModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof banterTopicsPartialUpdate>>,
  TError,
  { id: number; data: PatchedTopicsModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banterTopicsPartialUpdate>>,
    { id: number; data: PatchedTopicsModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return banterTopicsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BanterTopicsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof banterTopicsPartialUpdate>>
>;
export type BanterTopicsPartialUpdateMutationBody = PatchedTopicsModelRequest;
export type BanterTopicsPartialUpdateMutationError = ErrorType<unknown>;

export const useBanterTopicsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsPartialUpdate>>,
    TError,
    { id: number; data: PatchedTopicsModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBanterTopicsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team BanterConfig Topics

On a periodic (e.g., daily) basis, these topics are posted to Slack or MSTeams to encourage discussion.
Intuition curates a set of default topics and links them to the Team, and a TeamOwner can add their
own to use in addition to or instead of the Intuition-curated topics.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const banterTopicsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/banter/topics/${id}/`, method: "DELETE" },
    options
  );
};

export const getBanterTopicsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof banterTopicsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banterTopicsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return banterTopicsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BanterTopicsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof banterTopicsDestroy>>
>;

export type BanterTopicsDestroyMutationError = ErrorType<unknown>;

export const useBanterTopicsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banterTopicsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getBanterTopicsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
