/**
 *
 *
 * <ChallengeConfirmationWithProofModal />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Modal } from "../../components/Modal";
import { ActivityProofType } from "../../services/teambuilder/schemas";
import { ProofCheck } from "./ProofCheck";
import { ProofContent } from "./ProofContent";
import { useChallengeConfirmationWithProofModal } from "./useChallengeConfirmationWithProofModal";

export type Variant = "success" | "danger" | "warn";

export const ChallengeConfirmationWithProofModal = (
  props: ReturnType<typeof useChallengeConfirmationWithProofModal>
) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onCancel}
      ref={cancelButtonRef}
      showCloseButton={false}
      disableOutsideClick
    >
      <Dialog.Panel className="sm:min-w-md relative w-full transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-[28rem] sm:p-6">
        <div className="my-3 text-center sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-center text-base font-semibold leading-7 text-slate-700"
          >
            {props.title}
          </Dialog.Title>

          {props.description && (
            <div className="mt-2 text-center text-sm text-slate-500">
              {props.description}
            </div>
          )}
        </div>
        <div className="space-y-6">
          <div>{props.children}</div>
          <div className="text-sm font-normal leading-5 text-zinc-500">
            <pre className="whitespace-break-spaces break-words">
              {props.activity.description}
            </pre>
          </div>
          {props.activity.proofType &&
            props.activity.proofType !== ActivityProofType.not_required &&
            props.activity.proofType !== ActivityProofType.shoutout &&
            props.activity.proofType !== ActivityProofType.image && (
              <div onClick={() => props.openPostModal()}>
                <ProofCheck
                  isChecked={props.isFilled}
                  proofType={props.activity.proofType}
                />
              </div>
            )}
          <ProofContent {...props} />
          <div className="flex flex-row justify-center gap-x-4 sm:justify-start">
            <Button
              type="button"
              variant="outlined"
              size="md"
              onClick={props.onCancel}
              ref={cancelButtonRef}
              className="flex-auto"
            >
              {props.cancelButtonTitle}
            </Button>
            <Button
              variant={
                props.activity.proofType === ActivityProofType.text
                  ? "highlight"
                  : props.activity.proofType === ActivityProofType.link
                    ? "link"
                    : props.activity.proofType === ActivityProofType.image
                      ? "image"
                      : props.activity.proofType === ActivityProofType.shoutout
                        ? "shoutout"
                        : "challenge"
              }
              size="md"
              type="submit"
              className="flex-auto"
              onClick={props.onSubmit}
              disabled={
                !props.isFilled &&
                props.activity.proofType !== ActivityProofType.not_required &&
                props.activity.proofType !== ActivityProofType.shoutout &&
                props.activity.proofType !== ActivityProofType.image
              }
            >
              {props.confirmButtonTitle}
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
