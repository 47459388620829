/**
 *
 *
 * <SkeletonInputGroup />
 *
 *
 */
import React from "react";

export const SkeletonInputGroup = () => {
  return (
    <div className="animate-pulse">
      <div className="h-6 w-24 rounded bg-zinc-300" />
      <div className="mt-2 h-12 rounded-md bg-zinc-300" />
    </div>
  );
};
