/**
 *
 *
 * <Empty />
 *
 *
 */
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
}

export const EmptySection = (props: Props) => {
  return (
    <div
      className={clsx(
        "border-4 border-dashed border-zinc-300",
        "rounded-md p-4 text-center text-sm text-zinc-500",
        "flex flex-col items-center justify-center",
        "w-full gap-4"
      )}
    >
      {props.children}
    </div>
  );
};
