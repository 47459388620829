/**
 *
 *
 * useChallengesLeaderboardList
 *
 *
 */
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SearchParam } from "../../consts";
import {
  useChallengesLeaderboard,
  useChallengesLeaderboardListParams,
} from "../../hooks/useChallengesLeaderboardList";
import { useToast } from "../../hooks/useToast";

type Props = {
  activities: number;
  challengeId: number;
  completions: number;
  startPostId: number;
};

export const useChallengesLeaderboardList = (props: Props) => {
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams();

  const openProfileModal = (id: number) => {
    if (searchParams.has(SearchParam.PROFILE)) {
      searchParams.set(SearchParam.PROFILE, id.toString());
    } else {
      searchParams.append(SearchParam.PROFILE, id.toString());
    }
    setSearchParams(searchParams);
  };
  const challengesLeaderboardListParam = useChallengesLeaderboardListParams(
    props.challengeId
  );
  const challengesLeaderboardList = useChallengesLeaderboard(
    challengesLeaderboardListParam.challengeId,
    challengesLeaderboardListParam.challengesLeaderboardListParams
  );

  /**
   * Infinite scroll
   */
  const observerTarget = useRef(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (challengesLeaderboardList.isLoadingChallengesLeaderboardList) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            !intersecting &&
            challengesLeaderboardList.challengesLeaderboardListResponse?.meta
              .pagination.hasMore
          ) {
            challengesLeaderboardListParam.loadMore();
            setIntersecting(true);
          }
        } else {
          setIntersecting(false);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [
    observerTarget,
    intersecting,
    challengesLeaderboardList,
    challengesLeaderboardListParam,
  ]);

  /**
   * Determine bouncing on completions count
   * Should be lastCompletions < newCompletions
   */
  const [bounceAnim, setBounceAnim] = useState(false);
  const [lastCompletions, setLastCompletions] = useState(0);
  useEffect(() => {
    if (lastCompletions < props.completions) {
      setLastCompletions(lastCompletions);
      setBounceAnim(true);
      setTimeout(() => {
        setBounceAnim(false);
      }, 3500);
    }
  }, [props.completions, lastCompletions]);

  return {
    leaderboards: challengesLeaderboardList.challengesLeaderboardListResponse,
    toast,
    isLoading: challengesLeaderboardList.isLoadingChallengesLeaderboardList,
    openProfileModal,
    activities: props.activities,
    loadMore: challengesLeaderboardListParam.loadMore,
    observerTarget,
    completions: props.completions,
    bounceAnim,
    startPostId: props.startPostId,
    challengeId: props.challengeId,
  };
};
