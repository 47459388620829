import clsx from "clsx";
import React from "react";

import { EditButton } from "./EditButton";

interface Props {
  title: string;
  showEditButton: boolean;
  onEditClick: () => void;
  children: React.ReactNode;
}

export const Section = (props: Props) => {
  return (
    <section
      className={clsx(
        "flex flex-col sm:flex-row",
        "gap-4 border-b border-zinc-300",
        "px-4 py-16 sm:px-8"
      )}
    >
      <h2 className="w-48 shrink-0 text-lg font-light leading-9 text-zinc-900">
        {props.title}

        {props.showEditButton && (
          <div className="mt-2 hidden sm:block">
            <EditButton onClick={props.onEditClick} size="sm" />
          </div>
        )}
      </h2>

      {props.children}

      {props.showEditButton && (
        <EditButton onClick={props.onEditClick} className="w-full sm:hidden" />
      )}
    </section>
  );
};
