/*
 *
 *
 * PrivacyPolicy
 *
 *
 */
import { KeyIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonAnchor } from "../../components/ButtonAnchorV3";

export const PrivacyAndSecurity = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="border-b border-b-zinc-300 px-8 py-6 text-lg text-zinc-500">
        {t("translation:header:menu:privacy_and_security")}
      </div>
      <div className="px-8 py-12">
        <h2 className="text-sm font-medium text-zinc-900">
          {t("translation:profile_settings:reset_passwords")}
        </h2>
        <p className="mb-4 text-sm font-normal text-zinc-500">
          {t("translation:privacy_and_security:change_password_description")}
        </p>

        <ButtonAnchor
          href={`${import.meta.env.VITE_AUTH_API_DOMAIN}/forgot-password`}
          variant="contained"
          className="flex w-fit items-center gap-10"
          size="md"
        >
          <KeyIcon className="h-5 w-5" />
          {t("translation:profile_settings:reset_passwords")}...
        </ButtonAnchor>
      </div>
    </>
  );
};
