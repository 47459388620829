/**
 *
 *
 * <ChallengeCreateProgress />
 *
 *
 */
import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { Fragment } from "react";

interface Props {
  className?: string;
  total: number;
  current: number;
  title: string;
  description?: string;
}

export const ChallengeCreateProgress = (props: Props) => {
  return (
    <div className={props.className}>
      <div className="flex w-full flex-row items-center px-4 lg:px-8">
        {new Array(props.total).fill(1).map((_, index) => (
          <Fragment key={index}>
            <div
              className={clsx(
                "relative h-8 w-8 rounded-full border-2",
                index < props.current - 1
                  ? "border-challenge"
                  : index === props.current - 1
                    ? "border-challenge"
                    : "border-zinc-300",
                index < props.current - 1 && "bg-challenge"
              )}
            >
              {index < props.current - 1 ? (
                <CheckIcon className="absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2 text-white" />
              ) : (
                <span
                  className={clsx(
                    "absolute left-1/2 top-1/2 h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 rounded-full",
                    index < props.current ? "bg-challenge" : "bg-zinc-300"
                  )}
                />
              )}
            </div>
            {index < props.total - 1 && (
              <span
                className={clsx(
                  "h-1 flex-grow",
                  index < props.current ? "bg-challenge" : "bg-zinc-300"
                )}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className="px-4 py-2 lg:px-8 lg:text-2xl">
        <h2 className="text-xl font-bold leading-8 text-zinc-900">
          {props.title}
        </h2>
        {props.description && (
          <h3 className="text-sm font-normal leading-5 text-zinc-500">
            {props.description}
          </h3>
        )}
      </div>
    </div>
  );
};
