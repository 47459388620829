/**
 *
 *
 * <PostModalContentProvider />
 *
 *
 */
import React, { createContext, useState } from "react";

import { useValuesList } from "../services/teambuilder/endpoints/values/values";
import { SimpleUserModel, ValuesModel } from "../services/teambuilder/schemas";

export enum PostModalContentItem {
  PreviewImage = "previewImage",
  PreviewImageUrl = "previewImageUrl",
  SelectedRecipient = "selectedRecipient",
  SelectedValueId = "selectedValueId",
  SelectedURL = "selectedURL",
  SelectedValueLabel = "selectedValueLabel",
  Content = "content",
}

interface Context {
  previewImage?: Blob;
  previewImageUrl?: string;
  selectedRecipient?: SimpleUserModel;
  selectedValueId?: number;
  selectedURL?: string;
  selectedValueLabel?: string;
  content?: string;
  values?: ValuesModel[];
  reset: () => void;
  onContextUpdate: (itemType: PostModalContentItem, data: unknown) => void;
}

const PostModalContentContext = createContext<Context | undefined>(undefined);

export const usePostModalContent = () => {
  const context = React.useContext(PostModalContentContext);

  if (context === undefined) {
    throw new Error(
      "usePostModalContent must be used within a PostModalContentProvider"
    );
  }
  return context;
};

export const PostModalContentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [previewImage, setPreviewImage] = useState<Blob>();
  const [previewImageUrl, setPreviewImageUrl] = useState<string>();
  const [selectedRecipient, setSelectedRecipient] = useState<SimpleUserModel>();
  const [selectedValueId, setSelectedValueId] = useState<number>();
  const [selectedURL, setSelectedURL] = useState<string>();
  const [selectedValueLabel, setSelectedValueLabel] = useState<string>();
  const [content, setContent] = useState<string>();

  const { data: values } = useValuesList(
    {
      limit: 999,
      offset: 0,
    },
    {
      query: {
        select: ({ data }) => data,
      },
    }
  );

  const reset = () => {
    setPreviewImage(undefined);
    setPreviewImageUrl(undefined);
    setSelectedRecipient(undefined);
    setSelectedValueId(undefined);
    setSelectedURL(undefined);
    setSelectedValueLabel(undefined);
    setContent(undefined);
  };

  const onContextUpdate = (itemType: PostModalContentItem, data: unknown) => {
    switch (itemType) {
      case PostModalContentItem.PreviewImage:
        setPreviewImage(data as Blob);
        break;
      case PostModalContentItem.PreviewImageUrl:
        setPreviewImageUrl(data as string);
        break;
      case PostModalContentItem.SelectedRecipient:
        setSelectedRecipient(data as SimpleUserModel);
        break;
      case PostModalContentItem.SelectedValueId:
        setSelectedValueId(data as number);
        setSelectedValueLabel(values?.find((value) => value.id === data)?.name);
        break;
      case PostModalContentItem.SelectedURL:
        setSelectedURL(data as string);
        break;
      case PostModalContentItem.SelectedValueLabel:
        setSelectedValueLabel(data as string);
        break;
      case PostModalContentItem.Content:
        setContent(data as string);
        break;
    }
  };

  return (
    <PostModalContentContext.Provider
      value={{
        previewImage,
        previewImageUrl,
        selectedRecipient,
        selectedValueId,
        selectedURL,
        selectedValueLabel,
        content,
        reset,
        onContextUpdate,
        values,
      }}
    >
      {children}
    </PostModalContentContext.Provider>
  );
};
