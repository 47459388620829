export const Skeleton = () => {
  return (
    <div
      role="status"
      className="grid animate-pulse grid-cols-[repeat(auto-fill,minmax(300px,_360px))] gap-10 divide-y rounded xl:gap-x-10"
    >
      <div className="mb-4 flex items-center justify-between rounded-lg border border-zinc-300 bg-white p-4 shadow">
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="mb-2.5 h-6 w-48 rounded-full bg-zinc-300"></div>
            <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
            <div className="mb-4 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
          </div>
        </div>
        <div className="flex self-end">
          <div className="ml-8 h-8 w-20 rounded-full bg-zinc-300"></div>
        </div>
      </div>
      <div className="mb-4 flex items-center justify-between rounded-lg border border-zinc-300 bg-white p-4 shadow">
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="mb-2.5 h-6 w-48 rounded-full bg-zinc-300"></div>
            <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
            <div className="mb-4 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
          </div>
        </div>
        <div className="flex self-end">
          <div className="ml-8 h-8 w-20 rounded-full bg-zinc-300"></div>
        </div>
      </div>
      <div className="mb-4 flex items-center justify-between rounded-lg border border-zinc-300 bg-white p-4 shadow">
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="mb-2.5 h-6 w-48 rounded-full bg-zinc-300"></div>
            <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
            <div className="mb-4 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
          </div>
        </div>
        <div className="flex self-end">
          <div className="ml-8 h-8 w-20 rounded-full bg-zinc-300"></div>
        </div>
      </div>
      <div className="mb-4 flex items-center justify-between rounded-lg border border-zinc-300 bg-white p-4 shadow">
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="mb-2.5 h-6 w-48 rounded-full bg-zinc-300"></div>
            <div className="mb-2 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
            <div className="mb-4 h-4 w-full rounded-lg bg-zinc-300 text-lg font-medium" />
          </div>
        </div>
        <div className="flex self-end">
          <div className="ml-8 h-8 w-20 rounded-full bg-zinc-300"></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
