/**
 *
 *
 * useTopBarV3
 *
 *
 */
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";

interface Props {
  className?: string;
}

export const useTopBar = (props: Props) => {
  const { data: company, isLoading: isLoadingCompany } =
    useIntuitionCustomersCompaniesMeRetrieve();
  return {
    className: props.className,
    company,
    isLoadingCompany,
  };
};
