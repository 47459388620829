/**
 *
 *
 * <ProofCheck />
 *
 *
 */
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { Trans } from "react-i18next";

import { ActivityProofType } from "../../services/teambuilder/schemas";

type Props = {
  isChecked: boolean;
  proofType: ActivityProofType;
};

export const ProofCheck = (props: Props) => {
  return (
    <div className="flex w-full cursor-pointer items-center gap-1">
      {props.isChecked && (
        <CheckCircleIcon
          className={clsx("h-6 w-6", {
            "text-green-950": props.proofType === ActivityProofType.image,
            "text-blue-950": props.proofType === ActivityProofType.link,
            "text-amber-700": props.proofType === ActivityProofType.text,
            "text-fuchsia-950": props.proofType === ActivityProofType.shoutout,
          })}
        />
      )}
      {!props.isChecked && (
        <span
          className={clsx("h-5 w-5 rounded-full border-2", {
            "border-amber-700": props.proofType === ActivityProofType.text,
            "border-blue-950": props.proofType === ActivityProofType.link,
            "border-green-950": props.proofType === ActivityProofType.image,
            "border-fuchsia-950":
              props.proofType === ActivityProofType.shoutout,
          })}
        />
      )}
      <p className="text-sm font-normal leading-6">
        <Trans
          i18nKey={`translation:challenge:${
            props.proofType === ActivityProofType.text
              ? "complete_proof_text"
              : props.proofType === ActivityProofType.link
                ? "complete_proof_link"
                : props.proofType === ActivityProofType.image
                  ? "complete_proof_image"
                  : "complete_proof_shoutout"
          }`}
          components={{
            a: (
              <span
                className={clsx("font-semibold", {
                  "text-amber-700": props.proofType === ActivityProofType.text,
                  "text-blue-950": props.proofType === ActivityProofType.link,
                  "text-green-950": props.proofType === ActivityProofType.image,
                  "text-fuchsia-950":
                    props.proofType === ActivityProofType.shoutout,
                })}
              />
            ),
          }}
        />
      </p>
    </div>
  );
};
