/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  PaginatedValuesModelList,
  PatchedValuesModelRequest,
  PatchedValuesReorderRequestRequest,
  ValuesListParams,
  ValuesModel,
  ValuesModelRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Corporate Values API
 */
export const valuesList = (
  params?: ValuesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedValuesModelList>(
    { url: `/api/v1/values/`, method: "GET", params, signal },
    options
  );
};

export const getValuesListQueryKey = (params?: ValuesListParams) => {
  return [`/api/v1/values/`, ...(params ? [params] : [])] as const;
};

export const getValuesListQueryOptions = <
  TData = Awaited<ReturnType<typeof valuesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ValuesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof valuesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getValuesListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof valuesList>>> = ({
    signal,
  }) => valuesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof valuesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ValuesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof valuesList>>
>;
export type ValuesListQueryError = ErrorType<unknown>;

export const useValuesList = <
  TData = Awaited<ReturnType<typeof valuesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ValuesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof valuesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getValuesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Corporate Values API
 */
export const valuesCreate = (
  valuesModelRequest: ValuesModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<ValuesModel>(
    {
      url: `/api/v1/values/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: valuesModelRequest,
    },
    options
  );
};

export const getValuesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesCreate>>,
    TError,
    { data: ValuesModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof valuesCreate>>,
  TError,
  { data: ValuesModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof valuesCreate>>,
    { data: ValuesModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return valuesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValuesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof valuesCreate>>
>;
export type ValuesCreateMutationBody = ValuesModelRequest;
export type ValuesCreateMutationError = ErrorType<unknown>;

export const useValuesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesCreate>>,
    TError,
    { data: ValuesModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getValuesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Corporate Values API
 */
export const valuesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ValuesModel>(
    { url: `/api/v1/values/${id}/`, method: "GET", signal },
    options
  );
};

export const getValuesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/values/${id}/`] as const;
};

export const getValuesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof valuesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof valuesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getValuesRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof valuesRetrieve>>> = ({
    signal,
  }) => valuesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof valuesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ValuesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof valuesRetrieve>>
>;
export type ValuesRetrieveQueryError = ErrorType<unknown>;

export const useValuesRetrieve = <
  TData = Awaited<ReturnType<typeof valuesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof valuesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getValuesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Corporate Values API
 */
export const valuesPartialUpdate = (
  id: number,
  patchedValuesModelRequest: PatchedValuesModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<ValuesModel>(
    {
      url: `/api/v1/values/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedValuesModelRequest,
    },
    options
  );
};

export const getValuesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesPartialUpdate>>,
    TError,
    { id: number; data: PatchedValuesModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof valuesPartialUpdate>>,
  TError,
  { id: number; data: PatchedValuesModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof valuesPartialUpdate>>,
    { id: number; data: PatchedValuesModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return valuesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValuesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof valuesPartialUpdate>>
>;
export type ValuesPartialUpdateMutationBody = PatchedValuesModelRequest;
export type ValuesPartialUpdateMutationError = ErrorType<unknown>;

export const useValuesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesPartialUpdate>>,
    TError,
    { id: number; data: PatchedValuesModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getValuesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Corporate Values API
 */
export const valuesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/values/${id}/`, method: "DELETE" },
    options
  );
};

export const getValuesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof valuesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof valuesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return valuesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValuesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof valuesDestroy>>
>;

export type ValuesDestroyMutationError = ErrorType<unknown>;

export const useValuesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getValuesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Provide a list of pks in the order of position
 */
export const valuesReorderPartialUpdate = (
  patchedValuesReorderRequestRequest: PatchedValuesReorderRequestRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/values/reorder/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedValuesReorderRequestRequest,
    },
    options
  );
};

export const getValuesReorderPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesReorderPartialUpdate>>,
    TError,
    { data: PatchedValuesReorderRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof valuesReorderPartialUpdate>>,
  TError,
  { data: PatchedValuesReorderRequestRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof valuesReorderPartialUpdate>>,
    { data: PatchedValuesReorderRequestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return valuesReorderPartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValuesReorderPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof valuesReorderPartialUpdate>>
>;
export type ValuesReorderPartialUpdateMutationBody =
  PatchedValuesReorderRequestRequest;
export type ValuesReorderPartialUpdateMutationError = ErrorType<unknown>;

export const useValuesReorderPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valuesReorderPartialUpdate>>,
    TError,
    { data: PatchedValuesReorderRequestRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getValuesReorderPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
