/**
 *
 *
 * useSidebar
 *
 *
 */
import { useState } from "react";
import { useMatches } from "react-router-dom";

import { useLayoutContext } from "../../components/LayoutProvider";
import { useNavItems } from "../../hooks/useNavItems";
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";

export const useSidebar = ({ className }: { className?: string }) => {
  const { data: company, isLoading: isLoadingCompany } =
    useIntuitionCustomersCompaniesMeRetrieve();
  const navItems = useNavItems();
  const layoutContext = useLayoutContext();

  const matches = useMatches();
  const showCompanySettingsNav = matches.some(
    (match) => match.pathname === "/company-settings"
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onToggleSidebar = () => {
    if (layoutContext.width < 1400 && layoutContext.width > 1024) {
      setIsDrawerOpen(!isDrawerOpen);
    } else {
      layoutContext.toggleIsNavCollapsed();
    }
  };

  return {
    className,
    navItems,
    company,
    isLoadingCompany,
    isCollapsed: layoutContext.isNavCollapsed,
    onToggleSidebar,
    showCompanySettingsNav,
    isDrawerOpen,
  };
};
