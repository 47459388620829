/**
 *
 *
 * <PersonalInfoForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { getTranslation } from "../../utils/translation";
import { usePersonalInfoForm } from "./usePersonalInfoForm";

export const PersonalInfoForm = (
  props: ReturnType<typeof usePersonalInfoForm>
) => {
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div className="space-y-6">
          <SkeletonInputGroup />
          <SkeletonInputGroup />
          <SkeletonInputGroup />
          <SkeletonInputGroup />

          <Button
            variant="skeleton"
            size="md"
            disabled
            className="w-full sm:w-auto"
          >
            {t("translation:common:save")}
          </Button>
        </div>
      ) : (
        <form onSubmit={props.onHandleSubmit}>
          <div className="space-y-6">
            <Input
              id="firstName"
              label={t("translation:common:first_name")}
              error={getTranslation(
                t,
                props.form.formState.errors.firstName?.message
              )}
              {...props.form.register("firstName")}
            />

            <Input
              id="lastName"
              label={t("translation:common:last_name")}
              error={getTranslation(
                t,
                props.form.formState.errors.lastName?.message
              )}
              {...props.form.register("lastName")}
            />

            <Input
              id="jobTitle"
              label={t("translation:common:job_title")}
              error={props.form.formState.errors.jobTitle?.message}
              {...props.form.register("jobTitle")}
            />

            <Button
              size="md"
              variant="contained"
              type="submit"
              disabled={!props.form.formState.isDirty}
              className="w-full sm:w-auto"
            >
              {t("translation:common:save")}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
