/**
 *
 *
 * <IconG />
 *
 *
 */
import React from "react";

interface IconGProps {
  size?: number;
}

export const IconG = ({ size = 24 }: IconGProps) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_446_4823)">
          <path
            d="M23.983 12.2244C23.983 11.2412 23.9013 10.5236 23.7247 9.77954H12.2383V14.2175H18.9805C18.8447 15.3204 18.1106 16.9814 16.4794 18.0975L16.4565 18.246L20.0883 20.9962L20.3399 21.0208C22.6508 18.9346 23.983 15.8652 23.983 12.2244Z"
            fill="#4285F4"
          />
          <path
            d="M12.2397 23.9176C15.5428 23.9176 18.3158 22.8545 20.3413 21.0209L16.4808 18.0976C15.4477 18.8018 14.0611 19.2934 12.2397 19.2934C9.00446 19.2934 6.25861 17.2074 5.27979 14.324L5.13632 14.3359L1.35993 17.1927L1.31055 17.3269C3.32235 21.2334 7.45475 23.9176 12.2397 23.9176Z"
            fill="#34A853"
          />
          <path
            d="M5.27809 14.324C5.01982 13.58 4.87035 12.7826 4.87035 11.9589C4.87035 11.135 5.01982 10.3378 5.2645 9.59366L5.25766 9.43519L1.43394 6.53247L1.30884 6.59064C0.47968 8.21174 0.00390625 10.0322 0.00390625 11.9589C0.00390625 13.8855 0.47968 15.7059 1.30884 17.327L5.27809 14.324Z"
            fill="#FBBC05"
          />
          <path
            d="M12.2397 4.62403C14.5369 4.62403 16.0865 5.59402 16.9701 6.40461L20.4228 3.10928C18.3023 1.1826 15.5428 0 12.2397 0C7.45475 0 3.32235 2.68406 1.31055 6.59056L5.26621 9.59359C6.25861 6.7102 9.00445 4.62403 12.2397 4.62403Z"
            fill="#EB4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_446_4823">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
