export const Skeleton = () => {
  return (
    <div role="status" className="animate-pulse space-y-3 divide-y">
      <div className="h-6 w-full rounded-md bg-zinc-100" />
      <div className="h-6 w-full rounded-md bg-zinc-100" />
      <div className="h-6 w-full rounded-md bg-zinc-100" />
      <div className="h-6 w-full rounded-md bg-zinc-100" />
      <div className="h-6 w-full rounded-md bg-zinc-100" />
      <div className="h-6 w-full rounded-md bg-zinc-100" />
    </div>
  );
};
