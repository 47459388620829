/**
 *
 *
 * <Toast />
 *
 *
 */
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { Fragment, useEffect } from "react";

export interface ToastMessage {
  title: React.ReactNode;
  description?: React.ReactNode;
  type?: "success" | "danger" | "warning" | "celebration";
}

export interface Props {
  isOpen: boolean;
  message?: ToastMessage;
  onClose: () => void;
  className?: string;
  duration?: number;
  isAutoClose?: boolean;
}

export const Toast = ({
  isOpen = false,
  message,
  onClose,
  className = "",
  duration = 5000,
  isAutoClose = true,
}: Props) => {
  useEffect(() => {
    if (isAutoClose && isOpen)
      setTimeout(() => {
        onClose();
      }, duration);
  }, [isAutoClose, duration, isOpen]);

  const isDanger = message?.type === "danger";
  const isWarning = message?.type === "warning";
  const isCelebration = message?.type === "celebration";

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      data-testid="toast"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white",
              "shadow-lg ring-1 ring-black ring-opacity-5",
              className
            )}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {isDanger ? (
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  ) : isWarning ? (
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-amber-400"
                      aria-hidden="true"
                    />
                  ) : isCelebration ? (
                    <div className="text-xl">🎉</div>
                  ) : (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-slate-900">
                    {message?.title}
                  </p>
                  {message?.description && (
                    <p className="mt-1 text-sm text-slate-500">
                      {message?.description}
                    </p>
                  )}
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => onClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};
