/**
 *
 *
 * <Locations />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/20/solid";
import { MapPinIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ModalConfirmation } from "../../components/ModalConfirmationV3";
import { SettingsCard } from "../../components/SettingsCardV3";
import { EmptyLocations } from "./EmptyLocations";
import { LocationForm } from "./LocationForm";
import { Skeleton } from "./Skeleton";
import { useLocations } from "./useLocations";

export const Locations = (props: ReturnType<typeof useLocations>) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="animate-crossfadein">
        <div className="mb-16 px-8 py-12">
          <p className="mb-4 text-sm font-medium text-zinc-900">
            {t("translation:settings:menu:locations")}
          </p>
          {props.isLoading ? (
            <Skeleton />
          ) : (
            <>
              {props.locations && props.locations?.length > 0 ? (
                <>
                  <ul data-testid="locations-list" className="w-full space-y-5">
                    {props.locations.map((location) => (
                      <SettingsCard
                        key={location.id}
                        data={location}
                        icon={
                          <MapPinIcon className="h-5 w-5 flex-shrink-0 text-zinc-400" />
                        }
                        onEditClick={() => props.openSlideOver(location)}
                        onDeleteClick={() => props.handleDelete(location.id)}
                        data-testid={`location-card-${location.id}`}
                      />
                    ))}
                  </ul>
                  <Button
                    className="mt-4 px-0 sm:px-4"
                    type="button"
                    variant="contained"
                    onClick={() => props.openSlideOver()}
                    size="md"
                  >
                    <PlusIcon className="h-5 w-5" />
                    {t("translation:settings:locations:add_location")}
                  </Button>
                </>
              ) : (
                <EmptyLocations onAddClick={() => props.openSlideOver()} />
              )}
            </>
          )}
        </div>
      </div>

      <LocationForm
        isOpen={props.isFormOpen}
        shallResetFormData={props.shallResetFormData}
        onClose={props.closeSlideOver}
        onSubmit={props.handleSubmit}
        formData={props.formData}
        isUpdate={props.locationId !== undefined}
      />

      <ModalConfirmation
        isOpen={props.isConfirmationModalOpen}
        title={t("translation:common:cancel")}
        description={t(
          "translation:settings:locations:delete_location_description"
        )}
        cancelButtonTitle={t("translation:common:cancel")}
        confirmButtonTitle={t("translation:common:delete")}
        onCancel={props.onClose}
        onConfirm={props.modalOnConfirmation}
        data-testid="confirm-delete"
      />
    </>
  );
};
