import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { FormTextAreaGroup } from "../../components/FormTextAreaGroupV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { useBioForm } from "./useBioForm";

export const BioForm = (props: ReturnType<typeof useBioForm>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:common:save");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div>
          <SkeletonInputGroup />
          <Button variant="skeleton" size="md" className="mt-6" disabled>
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <form onSubmit={props.onHandleSubmit}>
          <div className="space-y-6">
            <FormTextAreaGroup
              id="bio"
              rows={5}
              errorMessage={props.form.formState.errors.bio?.message}
              {...props.form.register("bio")}
            />

            <Button
              size="md"
              variant="contained"
              type="submit"
              disabled={!props.form.formState.isDirty}
              className="w-full sm:w-auto"
            >
              {submitButtonText}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
