/**
 *
 *
 * useShoutout
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useMe } from "../../hooks/useMe";
import { useToast } from "../../hooks/useToast";
import { queryClient } from "../../query-client";
import { useRecognitionShoutoutsCreate } from "../../services/teambuilder/endpoints/recognition/recognition";
import { useValuesList } from "../../services/teambuilder/endpoints/values/values";
import { PaginatedPostList } from "../../services/teambuilder/schemas";

const schema = z.object({
  recipient: z
    .number()
    .min(1, { message: "translation:validation:required_recipient" }),
  message: z
    .string()
    .min(1, { message: "translation:validation:required_message" })
    .max(5000, { message: "translation:validation:less_than_5000" }),
  team: z.coerce.number().optional(),
  value: z.coerce.number().optional(),
  isPrivate: z.boolean(),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  queryKey: readonly unknown[];
}

export const useShoutout = ({ queryKey }: Props) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has("shoutout");
  const message = searchParams.get("shoutout") || "";
  const close = () => setSearchParams({});
  const defaultValues: FormData = {
    recipient: 0,
    team: TEAM_EVERYONE_ID,
    value: 0,
    message: "",
    isPrivate: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (message) {
      reset({
        ...defaultValues,
        message: decodeURIComponent(message),
      });
    }
  }, [message]);

  const {
    data: values,
    isError: isValuesError,
    isLoading: isLoadingValues,
  } = useValuesList(
    { ordering: "name" },
    {
      query: {
        select: ({ data }) => data,
      },
    }
  );

  const { mutate: createShoutout, isLoading: isLoadingCreateShoutout } =
    useRecognitionShoutoutsCreate({
      mutation: {
        onMutate: async ({ data }) => {
          /**
           * Optimistic update
           */
          // Cancel in flight values queries
          await queryClient.cancelQueries(queryKey);
          // Get the list of values from the query cache
          const originalResponse =
            queryClient.getQueryData<PaginatedPostList>(queryKey);
          // This shouldn't happen.
          if (!originalResponse?.meta || !originalResponse?.data) return;
          // Optimistically add the value to the results
          queryClient.setQueryData<PaginatedPostList>(queryKey, {
            meta: originalResponse.meta,
            data: [
              {
                ...data,
                content: data.message,
                team: {
                  name: t("translation:common:team_everyone"),
                },
                user: {
                  ...me,
                  email: me?.email || "",
                },
                recipient: undefined,
                action: "shouted out to",
                // Set mentions to empty array, as the request data doesn't
                // include the full user in mentions
                mentions: [],
              },
              ...originalResponse.data,
            ],
          });
          // Return the original list in case the other methods need it.
          return originalResponse;
        },
        onSuccess: () => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:shoutout_send_success"),
          });
          close();
          reset(defaultValues);
        },
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:shoutout_send_failed"),
            type: "danger",
          });
        },
        onSettled: () => queryClient.invalidateQueries(queryKey),
      },
    });

  const { me } = useMe();

  const onSubmit = (formData: FormData) => {
    if (me) {
      createShoutout({
        data: {
          ...formData,
          team: TEAM_EVERYONE_ID,
          value: formData.value || undefined,
        },
      });
    }
  };

  const onHandleSubmit = handleSubmit(onSubmit);

  return {
    isOpen,
    close,
    isLoadingValues,
    isValuesError,
    values,
    onHandleSubmit,
    isLoadingCreateShoutout,
    register,
    setValue,
    errors,
  };
};
