/**
 *
 *
 * <ChallengeVerticalListItem />
 *
 *
 */
import React from "react";
import { Link } from "react-router-dom";

import { Challenge, Template } from "../../services/teambuilder/schemas";
import { isChallengeResponse } from "../../utils/type-guards";

interface Props {
  challenge: Challenge | Template;
  children: React.ReactNode;
}

export const WrapComponent = ({ challenge, children }: Props) => {
  const isChallengeType = isChallengeResponse(challenge);

  return isChallengeType ? (
    <Link
      to={`/challenges/${challenge.id}`}
      className="block h-full cursor-pointer"
    >
      {children}
    </Link>
  ) : (
    <div className="h-full">{children}</div>
  );
};
