/**
 *
 *
 * <Radio />
 *
 *
 */
import React, { forwardRef } from "react";

import { classNames } from "../../utils";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  label?: string;
}

const Radio = forwardRef<HTMLInputElement, Props>(
  ({ className = "", ...props }, ref) => {
    return (
      <>
        <input
          type="radio"
          className={classNames(
            "h-4 w-4 rounded-full border-none outline outline-zinc-300",
            "checked:bg-zinc-600 hover:bg-zinc-300 focus:bg-zinc-500",
            "text-zinc-600 focus:ring-zinc-500",
            className
          )}
          ref={ref}
          value={props.label?.toLowerCase()}
          {...props}
        />
        <label
          htmlFor={props.id}
          className="ml-2 text-sm font-medium leading-6 text-zinc-900"
        >
          {props.label}
        </label>
      </>
    );
  }
);

Radio.displayName = "Radio";

export { Radio };
