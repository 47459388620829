/**
 *
 *
 * <ChallengeHorizontalListItem />
 *
 *
 */
import { CalendarIcon, ClockIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightIcon,
  ArrowsPointingOutIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SearchParam } from "../../consts";
import { TabKey } from "../../containers/ChallengeTabs";
import { Challenge, Template } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { isChallengeResponse } from "../../utils/type-guards";
import { Button } from "../ButtonV3";
import { useLayoutContext } from "../LayoutProvider";

interface Props {
  section?: TabKey;
  challenge: Challenge | Template;
  onTemplatePreview?: (id: number) => void;
  onSelect?: (template: Challenge | Template) => void;
  selectedId?: number;
}

export const ChallengeHorizontalListItem = (props: Props) => {
  const { t } = useTranslation();
  const completions = isChallengeResponse(props.challenge)
    ? props.challenge?.activities?.filter((activity) => activity.isCompleted)
        .length || 0
    : 0;

  // Horizontal Challenge Card should be switched to mobile mode under the following conditions
  // 1024 <= window width < 1200 or width < 768
  // window width < 1400 and Sidebar is expanded
  const { isMobileMode } = useLayoutContext();

  return (
    <li
      className={clsx(
        "w-full min-w-60 max-w-80",
        !isMobileMode && "sm:max-w-2xl"
      )}
    >
      <Link
        to={
          isChallengeResponse(props.challenge)
            ? `/challenges/${props.challenge.id}`
            : `?${SearchParam.TEMPLATE_PREVIEW}=${props.challenge.id}`
        }
        className="block cursor-pointer"
      >
        <div
          className={clsx(
            "relative pt-14",
            !isMobileMode && "sm:pl-32 sm:pt-0"
          )}
        >
          <div
            className={clsx(
              "w-full p-0 pt-24 shadow-md",
              !isMobileMode && "sm:h-60 sm:p-4 sm:pt-4",
              "rounded-lg bg-white",
              "flex flex-col gap-y-4 overflow-hidden"
            )}
          >
            <div
              className={clsx(
                "flex flex-grow flex-col gap-y-4 p-4 pt-0",
                !isMobileMode && "sm:p-0"
              )}
            >
              <span className="sr-only">Challenge Title</span>
              <div
                className={clsx(
                  "w-full rounded-lg bg-zinc-100 p-2 py-4",
                  !isMobileMode && "sm:py-2 sm:pl-32"
                )}
              >
                <h1
                  className={clsx(
                    "mb-1 truncate whitespace-nowrap text-center text-xl font-medium leading-7 text-zinc-900",
                    !isMobileMode && "sm:text-left"
                  )}
                >
                  {props.challenge.name}
                </h1>
                <div>
                  {isChallengeResponse(props.challenge) ? (
                    <>
                      <span className="sr-only">Dates</span>
                      <div
                        className={clsx(
                          "flex flex-col items-center gap-x-4 text-zinc-500",
                          !isMobileMode && "sm:flex-row"
                        )}
                      >
                        {(props.section === "upcoming" ||
                          props.section === "ended") && (
                          <div
                            className={clsx(
                              "flex flex-col items-center gap-x-2 text-zinc-500",
                              !isMobileMode && "sm:flex-row"
                            )}
                          >
                            <div className="flex items-center gap-x-1 text-sm">
                              <CalendarIcon className="h-5 w-5 text-zinc-400" />
                              {props.challenge.startAt &&
                                dayjs(props.challenge.startAt).format(
                                  "MMM D, h:mm a"
                                )}
                            </div>
                            <ArrowRightIcon className="h-4 w-4" />
                            <div className="flex items-center gap-x-1 text-sm">
                              <CalendarIcon className="h-5 w-5 text-zinc-400" />
                              {props.challenge.endAt &&
                                dayjs(props.challenge.endAt).format(
                                  "MMM D, h:mm a"
                                )}
                            </div>
                          </div>
                        )}
                        {props.section === "current" && (
                          <div
                            className={clsx(
                              "flex flex-col items-center gap-x-4 text-zinc-500",
                              !isMobileMode && "sm:flex-row"
                            )}
                          >
                            <div className="flex items-center gap-x-1 text-sm">
                              <ClockIcon className="h-5 w-5 text-zinc-400" />
                              {t("translation:challenge:end_in", {
                                time:
                                  props.challenge.endAt &&
                                  dayjs(props.challenge.endAt).toNow(true),
                              })}
                            </div>
                          </div>
                        )}
                        {/**
                         *
                         * Hide participants until challenge groups is implemented.
                         *
                         * Currently, the whole team is invited to all challenges.
                         *
                         */}
                        {/* <span className="hidden h-4 w-px bg-zinc-500 sm:block" />*/}
                        {/* <div className="flex items-center gap-x-1">*/}
                        {/*  <UserGroupIcon className="inline h-5 w-5 min-w-[1rem]" />*/}
                        {/*  {t(*/}
                        {/*    `translation:challenge:${pluralize("n_participant", "ns_participant", 5)}`,*/}
                        {/*    { n: 5 }*/}
                        {/*  )}*/}
                        {/* </div>*/}
                      </div>
                    </>
                  ) : (
                    <div className="text-sm font-normal leading-5 text-zinc-500">
                      {t("translation:challenge:template")}
                    </div>
                  )}
                </div>
              </div>

              <span className="sr-only">Challenge Description</span>
              <p
                className={clsx(
                  "line-clamp-2 whitespace-pre-line pl-0 text-base text-zinc-900",
                  !isMobileMode && "sm:pl-32"
                )}
              >
                {props.challenge.description}
              </p>
            </div>

            <span className="sr-only">Actions Completed</span>
            {props.section !== "upcoming" && props.challenge.activities && (
              <div
                className={clsx(
                  "w-full rounded-none bg-zinc-100 px-4 py-3",
                  !isMobileMode && "sm:rounded-lg sm:bg-white sm:p-0 sm:pl-32"
                )}
              >
                {props.section === "templates" ? (
                  <div className="flex w-full flex-row justify-between gap-x-4 gap-y-2">
                    <Button
                      className="flex w-full flex-row gap-x-2 text-sm font-semibold"
                      variant="outlined"
                      size="md"
                      onClick={() =>
                        props.onTemplatePreview &&
                        props.onTemplatePreview(props.challenge.id!)
                      }
                    >
                      <ArrowsPointingOutIcon className="h-5 w-5" />
                      {t("translation:common:preview")}
                    </Button>

                    <Button
                      className={clsx(
                        "w-full",
                        props.selectedId === props.challenge.id
                          ? "bg-challenge"
                          : "bg-zinc-500"
                      )}
                      size="md"
                      variant="challenge"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onSelect && props.onSelect(props.challenge);
                      }}
                    >
                      {t("translation:common:select")}
                      {props.selectedId === props.challenge.id && (
                        <CheckCircleIcon className="h-5" />
                      )}
                    </Button>
                  </div>
                ) : (
                  <>
                    <p className="mb-1 text-sm font-medium leading-5 text-zinc-900">
                      {t("translation:challenge:actions_completed")}
                    </p>
                    <div className="h-5 overflow-hidden rounded-full bg-zinc-200">
                      <div
                        className={clsx(
                          "relative h-5 overflow-hidden rounded-full",
                          props.section === "current"
                            ? "bg-challenge"
                            : props.section === "ended"
                              ? "bg-zinc-600"
                              : ""
                        )}
                        style={{
                          width: `${Math.ceil((completions / props.challenge.activities.length) * 100)}%`,
                        }}
                      >
                        <p className="absolute right-2 top-1/2 -translate-y-1/2 text-sm font-bold text-white">
                          {completions}/{props.challenge.activities.length}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div
            className={clsx(
              "absolute h-40 w-40",
              "left-1/2 top-0 -translate-x-1/2",
              !isMobileMode &&
                "sm:left-0 sm:top-1/2 sm:h-64 sm:w-64 sm:-translate-y-1/2 sm:translate-x-0"
            )}
          >
            {props.challenge.banner ? (
              <div
                className={clsx(
                  "h-full w-full bg-cover bg-center",
                  "shadow-m rounded-full border-4 border-white"
                )}
                style={{
                  backgroundImage: `url(${props.challenge.banner.medium})`,
                }}
              />
            ) : (
              <div
                className={clsx(
                  "pointer-events-none h-full w-full rounded-full border-4 border-white shadow-md",
                  "bg-zinc-100"
                )}
              />
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};
