/**
 *
 *
 * <Departments />
 *
 *
 */
import { BuildingOfficeIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { ModalConfirmation } from "../../components/ModalConfirmation";
import { SettingsCard } from "../../components/SettingsCardV3";
import { DepartmentForm } from "./DepartmentForm";
import { EmptyDepartments } from "./EmptyDepartments";
import { Skeleton } from "./Skeleton";
import { useDepartments } from "./useDepartments";

export const Departments = (props: ReturnType<typeof useDepartments>) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="animate-crossfadein">
        <div className="border-b border-b-zinc-300 px-8 py-12">
          <p className="mb-4 text-sm font-medium text-zinc-900">
            {t("translation:settings:menu:departments")}
          </p>
          {props.isLoading ? (
            <Skeleton />
          ) : (
            <>
              {props.departments?.length ? (
                <>
                  <ul
                    data-testid="departments-list"
                    className="w-full space-y-4"
                  >
                    {props.departments?.map((department) => (
                      <SettingsCard
                        key={department.id}
                        data={department}
                        icon={
                          <BuildingOfficeIcon className="h-5 w-5 text-zinc-400" />
                        }
                        onEditClick={() => props.openSlideOver(department)}
                        onDeleteClick={() => props.handleDelete(department.id)}
                        data-testid={`department-card-${department.id}`}
                      />
                    ))}
                  </ul>
                  <Button
                    className="mt-4 px-0 sm:px-4"
                    type="button"
                    variant="contained"
                    onClick={() => props.openSlideOver()}
                    size="md"
                  >
                    <PlusIcon className="h-5 w-5" />
                    {t("translation:settings:departments:add_department")}
                  </Button>
                </>
              ) : (
                <EmptyDepartments onAddClick={() => props.openSlideOver()} />
              )}
            </>
          )}
        </div>
      </div>

      <DepartmentForm
        isOpen={props.isFormOpen}
        onClose={props.closeSlideOver}
        onSubmit={props.handleSubmit}
        formData={props.formData}
        shallResetFormData={props.shallResetFormData}
        isUpdate={props.departmentId !== undefined}
      />

      <ModalConfirmation
        isOpen={props.isConfirmationModalOpen}
        title={t("translation:settings:departments:delete_department")}
        description={t(
          "translation:settings:departments:delete_department_description"
        )}
        cancelButtonTitle={t("translation:common:cancel")}
        confirmButtonTitle={t("translation:common:delete")}
        onCancel={props.onClose}
        onConfirm={() => props.modalOnConfirmation()}
        data-testid="confirm-delete"
      />
    </>
  );
};
