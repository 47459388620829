/*
 *
 *
 * <Values />
 *
 *
 */
import { MegaphoneIcon, PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "../../components/ButtonV3";
import { ModalConfirmation } from "../../components/ModalConfirmationV3";
import { Card } from "./Card";
import { EmptyValues } from "./EmptyValues";
import { Skeleton } from "./Skeleton";
import { ValueForm } from "./ValueForm";
import { useValues } from "./useValues";

export const Values = (props: ReturnType<typeof useValues>) => {
  const { t } = useTranslation();

  if (props.isError)
    return <div>{t("translation:toast:load_value_failed")}</div>;

  return (
    <div className="px-12 py-8">
      <div className="mb-0.5 flex flex-row items-center justify-between">
        <p className="text-lg font-bold leading-7 text-zinc-900">
          {t("translation:settings:values:title")}
        </p>
        <div className="flex items-center gap-x-2">
          {props.isCrudEnabled && !props.isAddValue && (
            <Button
              variant="outlined"
              size="md"
              onClick={() => props.setIsAddValue(true)}
            >
              <PlusIcon className="h-4 w-4" />{" "}
              {t("translation:settings:values:add_value")}
            </Button>
          )}
          <Button
            variant="highlight"
            className="hidden bg-fuchsia-950"
            size="md"
            onClick={() => props.setIsAddValue(true)}
          >
            <MegaphoneIcon className="h-4 w-4" />{" "}
            {t("translation:comment:shoutout")}
          </Button>
        </div>
      </div>
      <p className="mb-8 text-sm font-normal leading-5 text-zinc-500">
        <Trans
          i18nKey="translation:settings:values:description_values_v3"
          components={{
            a: (
              <span
                className="cursor-pointer font-medium underline"
                onClick={props.openPostModal}
              />
            ),
          }}
        />
      </p>
      <div className="animate-crossfadein">
        {props.isLoading ? (
          <Skeleton />
        ) : (
          <>
            {props.isAddValue && (
              <div className="mx-auto mb-5 max-w-[33rem] rounded-b-lg shadow-md">
                <ValueForm
                  onClose={() => props.setIsAddValue(false)}
                  onSubmit={props.handleSubmit}
                  formData={{
                    name: "",
                    description: "",
                  }}
                />
              </div>
            )}

            {props.values?.length ? (
              <ul className="w-full space-y-5">
                {props.values?.map((value) => (
                  <Card
                    key={value.id}
                    data={value}
                    onDeleteClick={() => props.handleDelete(value.id)}
                    isCrudEnabled={props.isCrudEnabled}
                    data-testid={`value-card-${value.id}`}
                    onSubmit={props.handleSubmit}
                    isEditMode={
                      !!props.editValueIds.find((id) => id === value.id)
                    }
                    toggleEditMode={props.toggleEditMode}
                  />
                ))}
              </ul>
            ) : (
              <>
                {!props.isAddValue && (
                  <EmptyValues onAddClick={() => props.setIsAddValue(true)} />
                )}
              </>
            )}
          </>
        )}

        <ModalConfirmation
          isOpen={props.isConfirmationModalOpen}
          title={t("translation:settings:values:delete_value")}
          description={t(
            "translation:settings:values:delete_value_description"
          )}
          variant="warn"
          cancelButtonTitle={t("translation:common:cancel")}
          confirmButtonTitle={t("translation:common:delete")}
          onCancel={() => {
            props.setIsConfirmationModalOpen(false);
            props.setValueId(undefined);
          }}
          onConfirm={() =>
            props.valueId && props.destroyValue({ id: props.valueId })
          }
          data-testid="confirm-delete"
        />
      </div>
    </div>
  );
};
