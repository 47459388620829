/**
 *
 *
 * <StartDateForm />
 *
 *
 */
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Input } from "../../components/InputV3";
import { SkeletonInputGroup } from "../../components/SkeletonInputGroupV3";
import { useStartDateForm } from "./useStartDateForm";

export const StartDateForm = (props: ReturnType<typeof useStartDateForm>) => {
  const { t } = useTranslation();
  const submitButtonText = t("translation:user_guide:save_start_date");

  return (
    <div className={props.className}>
      {props.isLoading ? (
        <div>
          <SkeletonInputGroup />
          <Button variant="skeleton" size="md" className="mt-6" disabled>
            {submitButtonText}
          </Button>
        </div>
      ) : (
        <form onSubmit={props.onHandleSubmit}>
          <div className="space-y-6">
            <Input
              id="startDate"
              label={t("translation:user_guide:start_date")}
              labelClassName="sr-only"
              type="date"
              min={new Date("1950/1/1").toJSON().slice(0, 10)}
              error={props.form.formState.errors.startDate?.message}
              {...props.form.register("startDate")}
            />

            <Button
              size="md"
              variant="contained"
              type="submit"
              disabled={!props.form.formState.isDirty}
              className="w-full sm:w-auto"
            >
              {submitButtonText}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
