import { TFunction } from "i18next";

export const getTranslation = (
  t: TFunction<"translation", undefined>,
  message: string | undefined
) => {
  if (message === undefined) return undefined;
  if (message.match(/translation:/)) {
    return t(message);
  }
  return message;
};
