/**
 *
 *
 * useProfileForm
 *
 *
 */
import { useIntuitionCustomersCompaniesMeRetrieve } from "../../services/teambuilder/endpoints/intuition/intuition";
import { usePeopleRetrieve } from "../../services/teambuilder/endpoints/people/people";

interface Props {
  userId: number;
  onCancel: () => void;
}

export const useProfileForm = (props: Props) => {
  const { data: user } = usePeopleRetrieve(props.userId, {
    query: {
      enabled: Boolean(props.userId),
    },
  });

  const { data: company } = useIntuitionCustomersCompaniesMeRetrieve();

  return {
    user,
    userId: props.userId,
    company,
    onCancel: props.onCancel,
  };
};
