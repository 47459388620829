/**
 *
 *
 * <QuestionModal />
 *
 *
 */
import { Dialog } from "@headlessui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { Modal } from "../../components/Modal";
import { Textarea } from "../../components/TextareaV3";
import { useQuestionModal } from "./useQuestionModal";

export type Variant = "success" | "danger" | "warn";

export const QuestionModal = (props: ReturnType<typeof useQuestionModal>) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      ref={cancelButtonRef}
      showCloseButton={false}
    >
      <Dialog.Panel className="sm:min-w-md relative w-full transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-[28rem] sm:p-6">
        <div className="mb-6 mt-3 text-center sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-center text-base font-semibold leading-7 text-zinc-900"
          >
            {t(
              props.questionId > 0
                ? "translation:settings:ice_breakers:edit_question"
                : "translation:settings:ice_breakers:create_question"
            )}
          </Dialog.Title>
        </div>
        <form onSubmit={props.onSubmit}>
          <Textarea
            showLabel
            label={t("translation:settings:ice_breakers:question")}
            rows={3}
            {...props.form.register("question")}
          />
          <div className="mt-6 flex flex-row justify-center gap-x-4 sm:justify-start">
            <Button
              type="button"
              variant="outlined"
              size="md"
              onClick={props.onClose}
              ref={cancelButtonRef}
              className="flex-auto"
            >
              {t("translation:common:cancel")}
            </Button>
            <Button
              variant="contained"
              size="md"
              type="submit"
              className="flex-auto"
            >
              {t("translation:common:save")}
            </Button>
          </div>
        </form>
      </Dialog.Panel>
    </Modal>
  );
};
