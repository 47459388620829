/**
 *
 *
 * <ChallengeDetail />
 *
 *
 */
import { CalendarIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import ReactConfetti from "react-confetti";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ChallengeActivityListItem } from "../../components/ChallengeActivityListItemV3/ChallengeActivityListItem";
import { ChallengeCompleteModal } from "../../components/ChallengeCompleteModalV3";
import { ChallengeDeleteConfirmationModal } from "../../components/ChallengeDeleteConfirmationModal";
import { DropdownMenu } from "../../components/DropdownMenuV3";
import { useLayoutContext } from "../../components/LayoutProvider";
import { SkeletonChallengeActivityContainer } from "../../components/SkeletonChallengeActivityContainerV3";
import { SkeletonChallengeDetailHeader } from "../../components/SkeletonChallengeDetailHeaderV3";
import { Activity, PostWriteRequest } from "../../services/teambuilder/schemas";
import { dayjs } from "../../utils/days";
import { ChallengesLeaderboardList } from "../ChallengesLeaderboardList";
import { useChallengeDetail } from "./useChallengeDetail";

export const ChallengeDetail = (
  props: ReturnType<typeof useChallengeDetail>
) => {
  const { t } = useTranslation();
  const DURATION = 1.5;
  const { width, height } = useWindowSize();
  const { isMobileMode } = useLayoutContext();

  return (
    <>
      <div
        className={clsx(
          "block min-h-[56rem]",
          !isMobileMode &&
            "sm:grid sm:grid-rows-[auto_1fr] sm:overflow-hidden sm:overflow-y-auto sm:pb-0"
        )}
        style={{ height: width < 1024 ? "calc(100vh - 62px)" : "100vh" }}
      >
        <Breadcrumbs
          paths={[
            { name: t("translation:challenge:challenges"), to: "/challenges" },
            {
              name:
                props.challenge?.name ||
                (props.isLoadingChallenge
                  ? ""
                  : t("translation:challenge:not_found")),
              to: "",
            },
          ]}
        />
        <div
          className={clsx(
            "block overflow-y-auto",
            !isMobileMode &&
              "sm:grid sm:grid-rows-[1fr_auto] sm:overflow-y-hidden"
          )}
        >
          <div
            className={clsx(
              "grid grid-rows-[auto_1fr] overflow-y-auto px-4 pb-6",
              !isMobileMode && "sm:px-8"
            )}
          >
            {(props.isLoadingChallenge || props.isLoadingActivities) && (
              <SkeletonChallengeDetailHeader />
            )}
            {!props.isLoadingChallenge &&
              !props.isLoadingActivities &&
              (props.challenge ? (
                <>
                  <div
                    className={clsx(
                      "relative grid pt-[9.5rem]",
                      !isMobileMode && "sm:pl-20 sm:pt-0"
                    )}
                  >
                    <div
                      className={clsx(
                        "w-full bg-white",
                        "flex min-h-full max-w-full flex-col gap-y-4",
                        !isMobileMode && "sm:min-h-40 sm:py-2 sm:pl-4 sm:pr-2"
                      )}
                    >
                      <div className="flex flex-grow flex-col gap-y-2">
                        <span className="sr-only">Challenge Title</span>
                        <div
                          className={clsx(
                            "relative w-full rounded-lg bg-zinc-100 p-2",
                            !isMobileMode && "sm:py-2 sm:pl-20"
                          )}
                        >
                          {/**
                           *
                           * Title
                           *
                           */}
                          <motion.h1
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: DURATION, duration: DURATION }}
                            className={clsx(
                              "mb-1 truncate whitespace-nowrap text-center text-lg font-medium leading-7 text-zinc-900",
                              !isMobileMode && "sm:text-left"
                            )}
                          >
                            {props.challenge?.name}
                          </motion.h1>

                          {/**
                           *
                           * Date
                           *
                           */}
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: DURATION, duration: DURATION }}
                          >
                            {props.challenge &&
                              props.isChallengeResponse(props.challenge) && (
                                <>
                                  <span className="sr-only">Dates</span>
                                  <div
                                    className={clsx(
                                      "flex flex-row items-center justify-center gap-x-1 text-zinc-500",
                                      !isMobileMode && "sm:justify-start"
                                    )}
                                  >
                                    <div className="flex items-center gap-x-1 text-sm">
                                      <CalendarIcon
                                        className={clsx(
                                          "hidden h-5 w-5 text-zinc-400",
                                          !isMobileMode && "sm:block"
                                        )}
                                      />
                                      {props.challenge.startAt &&
                                        dayjs(props.challenge.startAt).format(
                                          "MMM D, h:mm a"
                                        )}
                                    </div>
                                    <ArrowRightIcon className="h-4 w-4" />
                                    <div className="flex items-center gap-x-1 text-sm">
                                      <CalendarIcon
                                        className={clsx(
                                          "hidden h-5 w-5 text-zinc-400",
                                          !isMobileMode && "sm:block"
                                        )}
                                      />
                                      {props.challenge.endAt &&
                                        dayjs(props.challenge.endAt).format(
                                          "MMM D, h:mm a"
                                        )}
                                    </div>
                                  </div>
                                </>
                              )}
                          </motion.div>
                          {props.isAdmin && (
                            <span className="absolute right-2 top-2 hidden">
                              <DropdownMenu
                                items={props.menuItems}
                                menuButtonContent={(open) => (
                                  <EllipsisHorizontalIcon
                                    className={clsx(
                                      "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                                      open
                                        ? "bg-white text-zinc-600"
                                        : "bg-zinc-100 text-zinc-500"
                                    )}
                                  />
                                )}
                                menuClassNames={clsx(
                                  "right-0 mt-6 origin-top-right"
                                )}
                              />
                            </span>
                          )}
                        </div>

                        {/**
                         *
                         * Description
                         *
                         */}
                        <span className="sr-only">Challenge Description</span>
                        {!!props.challenge?.description && (
                          <div
                            className={clsx(
                              "group relative pl-0",
                              !isMobileMode && "sm:min-h-14 sm:pl-20"
                            )}
                          >
                            <motion.p
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{
                                delay: DURATION,
                                duration: DURATION,
                              }}
                              className={clsx(
                                "line-clamp-3 whitespace-pre-line text-sm text-zinc-900"
                              )}
                            >
                              {props.challenge?.description}
                            </motion.p>
                            <p className="absolute top-0 z-[100] hidden whitespace-pre-line rounded-lg bg-white pl-0 text-sm text-zinc-900 shadow-md group-hover:block">
                              {props.challenge.description}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {/**
                     *
                     * Badge
                     *
                     */}
                    <div
                      className={clsx(
                        "absolute h-40 w-40",
                        "left-1/2 top-0 -translate-x-1/2 ",
                        "rounded-full bg-zinc-100",
                        "shadow-md",
                        !isMobileMode &&
                          "sm:left-0 sm:top-20 sm:-translate-y-1/2 sm:translate-x-0"
                      )}
                    >
                      {props.challenge?.banner && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: DURATION, duration: DURATION }}
                          className={clsx(
                            "h-full w-full bg-cover bg-center",
                            "rounded-full"
                          )}
                          style={{
                            backgroundImage: `url(${props.challenge?.banner.medium})`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <p className="max-w-md text-sm font-normal leading-5 text-zinc-500">
                  <Trans
                    i18nKey="translation:challenge:challenge_not_found"
                    components={{
                      a: (
                        <Link
                          className="font-semibold underline"
                          to="/challenges"
                        />
                      ),
                    }}
                  />
                </p>
              ))}

            {/**
             *
             * Activity List
             *
             */}
            {props.isLoadingActivities && (
              <SkeletonChallengeActivityContainer />
            )}
            {!props.isLoadingChallenge &&
              !props.isLoadingActivities &&
              props.challenge &&
              props.isChallengeResponse(props.challenge) && (
                <div
                  className={clsx(
                    "mt-6 flex min-h-64 flex-col overflow-hidden",
                    !isMobileMode && "sm:flex-row sm:gap-x-4 sm:shadow-none"
                  )}
                >
                  <div
                    className={clsx(
                      "mb-6 h-fit w-full flex-shrink-0 rounded-lg",
                      "text-center text-sm font-medium text-zinc-600",
                      "leading-loose",
                      !isMobileMode && "sm:w-44 sm:rounded-lg sm:text-base"
                    )}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: DURATION, duration: DURATION }}
                    >
                      {props.isRunning && (
                        <>
                          {/**
                           *
                           * Progress bar
                           *
                           */}
                          <span className="sr-only">Actions Completed</span>
                          {props.challenge && props.challenge.activities && (
                            <div
                              className={clsx(
                                "w-full rounded-none",
                                !isMobileMode && "sm:rounded-lg sm:p-0"
                              )}
                            >
                              <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                  delay: DURATION,
                                  duration: DURATION,
                                }}
                                className="mb-1 text-left text-sm font-medium leading-5 text-zinc-600"
                              >
                                {t("translation:challenge:actions_completed")}
                              </motion.p>

                              <div className="h-7 overflow-hidden rounded-full bg-zinc-100">
                                <motion.div
                                  initial={{ translateX: "-100%" }}
                                  animate={{
                                    translateX: `-${100 - Math.ceil((props.completedActions / props.challenge.activities.length) * 100)}%`,
                                  }}
                                  transition={{
                                    type: "easeIn",
                                    duration: DURATION,
                                    delay: DURATION,
                                  }}
                                  className={clsx(
                                    "relative h-7 overflow-hidden rounded-full",
                                    props.isRunning
                                      ? "bg-challenge"
                                      : props.isCompleted
                                        ? "bg-zinc-600"
                                        : ""
                                  )}
                                >
                                  <p className="absolute right-2 top-1/2 -translate-y-1/2 text-sm font-bold text-white">
                                    {props.completedActions}/
                                    {props.challenge.activities.length}
                                  </p>
                                </motion.div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {props.isUpcoming && (
                        <>
                          {t("translation:challenge:get_ready")}
                          <br />
                          {t("translation:challenge:challenge_starts_in")}{" "}
                          <strong>
                            {dayjs(props.challenge.startAt).toNow(true)}
                          </strong>
                        </>
                      )}
                      {props.isCompleted && (
                        <>{t("translation:challenge:challenge_ended")}</>
                      )}
                    </motion.div>
                  </div>
                  <div
                    className={clsx(
                      "flex flex-auto flex-col gap-y-6 bg-white",
                      "h-fit max-h-full overflow-y-auto text-center text-base font-medium",
                      !isMobileMode && "p-6 sm:rounded-lg sm:bg-zinc-100"
                    )}
                  >
                    {props.activities?.map((activity, i) => (
                      <ChallengeActivityListItem
                        index={i}
                        delay={DURATION * 2.25 + i * 0.1}
                        key={i}
                        activity={activity}
                        isRunning={
                          props.isRunning &&
                          !!props.challenge &&
                          props.isChallengeResponse(props.challenge) &&
                          !!props.challenge?.startPost
                        }
                        isCompleted={props.isCompleted}
                        onComplete={
                          props.allowScoring
                            ? (data?: PostWriteRequest) =>
                                props.completeActivity(
                                  activity.id as number,
                                  data,
                                  (activity as Activity).proofType
                                )
                            : undefined
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
          </div>
          <div>
            <ChallengesLeaderboardList
              activities={props.activities?.length || 0}
              challengeId={props.challengeId}
              completions={props.completions || 0}
              startPostId={props.startPostId!}
            />
          </div>
        </div>
      </div>
      {(props.showConfetti || props.showCompleteModal) && (
        <ReactConfetti
          width={width}
          height={height}
          colors={[
            "#b45309", // amber-700 (highlight)
            "#052e16", // green-950 (image)
            "#172554", // blue-950 (link)
            "#be123c", // rose-700 (video)
            "#431407", // orange-950 (coffee chat)
            "#4a044e", // fuchsia-950 (shoutout)
            "#D62800", // challenge
            "#b91c1c", // red-700 (danger)
            "#ca8a04", // yellow-600 (warn)
          ]}
        />
      )}
      {props.showCompleteModal && props.challenge?.banner?.large && (
        <ChallengeCompleteModal
          onCancel={props.onCloseCompleteModal}
          isOpen
          badge={props.challenge?.banner?.large}
        />
      )}
      <ChallengeDeleteConfirmationModal
        isOpen={props.showDeleteConfirmationModal}
        onCancel={props.onCancelDeleteConfirmation}
        onConfirm={props.onDelete}
      />
    </>
  );
};
