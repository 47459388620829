/**
 *
 *
 * Optimistic update
 *
 *
 */
import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

import { queryClient } from "../query-client";
import { ErrorType } from "../services/teambuilder/axios-instance";
import { OptimisticUpdateFn } from "../types";

/**
 * Creates mutation options that can be passed to a service.
 *
 * @type Data - Response object of the mutation request, e.g. Post if I'm mutating a Post and optimistically updating PaginatedPostList.
 * @type Context - Target of the optimistic update, e.g. PaginatedPostList if I'm mutating a Post.
 * @type RequestVariables - Request variables of the mutation request, e.g. { id: number; data: PatchedPostRequest } if I'm mutating a Post.
 * @param args - onMutate, onSuccess, onError, onSettled are Callbacks that are triggered at different stages of the mutation.
 */
export const optimisticMutationOptions = <
  Data extends object | void,
  Context extends object,
  RequestVariables extends object | void,
>(args: {
  queryKey: readonly unknown[];
  optimisticUpdateFn: OptimisticUpdateFn<Context, RequestVariables>;
  onMutate?: () => void;
  onSuccess?: (
    data?: Data,
    requestVariable?: RequestVariables,
    context?: Context
  ) => void;
  onError?: (
    context?: Context,
    requestVariable?: RequestVariables,
    error?: ErrorType<unknown>
  ) => void;
  // If onSettled returns true, it prevents to refetch query and keep cached data
  onSettled?: (data?: Data) => void | boolean;
  requestConfig?: AxiosRequestConfig;
}): {
  mutation: UseMutationOptions<
    Data,
    ErrorType<unknown>,
    RequestVariables,
    Context
  >;
  request?: AxiosRequestConfig;
} => {
  return {
    request: args.requestConfig,
    mutation: {
      onMutate: async (requestData) => {
        // Cancel in flight values queries.
        await queryClient.cancelQueries(args.queryKey);
        // Get the list of values from the query cache.
        const originalResponse = queryClient.getQueryData<Context>(
          args.queryKey
        );
        // This shouldn't happen.
        if (!originalResponse) return;
        // Optimistically replace the current value.
        queryClient.setQueryData(
          args.queryKey,
          args.optimisticUpdateFn(originalResponse, requestData)
        );
        args.onMutate && args.onMutate();
        // Return the original list in case the other methods need it.
        return originalResponse;
      },
      onSuccess: (
        data?: Data,
        request?: RequestVariables,
        context?: Context
      ) => {
        args.onSuccess && args.onSuccess(data, request, context);
      },
      onError: (
        error: ErrorType<unknown>,
        requestVariable: RequestVariables,
        context: Context | undefined
      ) => {
        queryClient.setQueryData(args.queryKey, context);
        args.onError && args.onError(context, requestVariable, error);
      },
      onSettled: (data?: Data) => {
        if (args.onSettled) {
          const res = args.onSettled(data);
          // There are some cases to not allow to query to the latest update
          // If `onSettled` callback returns true, prevent to refetch the latest update by default
          // This is for keeping cached data
          if (res) return;
        }
        return queryClient.invalidateQueries(args.queryKey);
      },
    },
  };
};
