import React, {
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import {
  BaseText,
  Descendant,
  Range,
  Editor as SlateEditor,
  Transforms,
  createEditor,
} from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useFocused,
  useSelected,
  withReact,
} from "slate-react";

import useDebounceValue from "../../hooks/useDebounceValue";
import { useMe } from "../../hooks/useMe";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { usePeopleList } from "../../services/teambuilder/endpoints/people/people";
import {
  PaginatedPeopleResponseListMeta,
  PeopleListOrdering,
  PostRequest,
  _PeopleResponse,
} from "../../services/teambuilder/schemas";
import { slateToContent } from "../../utils/slate-to-content";

type CustomText = BaseText & {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  underline?: boolean;
  text: string;
};

type TruncatedPerson = Pick<
  _PeopleResponse,
  "id" | "firstName" | "lastName" | "email"
>;

type MentionElement = {
  type: "mention";
  person: TruncatedPerson;
  children: CustomText[];
};

const Portal = ({ children }: PropsWithChildren) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

const SEARCH_LIMIT = 10;

export const Editor = ({
  clear = false,
  placeholder = "Enter some text...",
  onChange,
  className,
  initialValue = INITIAL_VALUE,
  paddingLeft = "0px",
  paddingRight = "0px",
  minHeight = "5rem",
  setEditor,
}: {
  clear: boolean;
  placeholder?: string;
  onChange: (value: Partial<PostRequest>) => void;
  className?: string;
  initialValue?: Descendant[];
  paddingLeft?: string;
  paddingRight?: string;
  minHeight?: string;
  setEditor?: (editor: ReactEditor) => void;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [debouncing, setDebouncing] = useState(false);
  const debouncedSearch = useDebounceValue(search, 500);
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );

  const targetRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(targetRef, () => setTarget(undefined));

  const initialPeopleParams = {
    limit: SEARCH_LIMIT,
    offset: 0,
    ordering: "first_name" as PeopleListOrdering,
    search: debouncedSearch,
    date_joined_after: 0,
  };

  const [peopleParams, setPeopleParams] = useState(initialPeopleParams);

  useEffect(() => {
    setDebouncing(true);
  }, [search]);

  useEffect(() => {
    if (clear) {
      setPeopleParams(initialPeopleParams);
      setSearch("");
      setDebouncing(false);
      setTarget(undefined);
      Transforms.delete(editor, {
        at: {
          anchor: SlateEditor.start(editor, []),
          focus: SlateEditor.end(editor, []),
        },
      });
    }
  }, [clear]);

  useEffect(() => {
    setPeopleParams((oldPeopleParams) => ({
      ...oldPeopleParams,
      search: debouncedSearch,
    }));
    setDebouncing(false);
  }, [debouncedSearch]);

  const { me } = useMe();

  const { data, isLoading: loadingPeople } = usePeopleList<{
    meta: PaginatedPeopleResponseListMeta;
    data: TruncatedPerson[];
  }>(peopleParams, {
    query: {
      enabled: Boolean(me),
      select: ({ data, meta }) => ({
        meta,
        data: data
          .filter(({ id }) => !me || id != me.id)
          .map(({ id, firstName, lastName, email }) => ({
            id,
            firstName,
            lastName,
            email,
          })),
      }),
    },
  });

  const chars = data?.data || [];

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            setIndex(index >= chars.length - 1 ? 0 : index + 1);
            break;
          case "ArrowUp":
            event.preventDefault();
            setIndex(index <= 0 ? chars.length - 1 : index - 1);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(undefined);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(undefined);
            break;
        }
      }
    },
    [chars, editor, index, target]
  );

  useEffect(() => {
    if (target && chars.length > 0 && ref.current) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor as ReactEditor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  useEffect(() => {
    // NOTE: Transforms.select works with the post popup, but not with comments,
    // and the ReactEditor.focus works with the comments, but not with the post popup
    // ¯\_(ツ)_/¯
    Transforms.select(editor, { offset: 0, path: [0, 0] });
    ReactEditor.focus(editor as ReactEditor);
    setEditor && setEditor(editor as ReactEditor);
  }, []);

  return (
    <Slate
      editor={editor as ReactEditor}
      initialValue={initialValue}
      onChange={() => {
        const { selection, children } = editor;
        onChange(slateToContent(children));

        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = SlateEditor.before(editor, start, {
            unit: "word",
          });
          const before = (wordBefore &&
            SlateEditor.before(editor, wordBefore)) || {
            offset: 0,
            path: [0, 0],
          };
          const beforeRange =
            before && SlateEditor.range(editor, before, start);
          const beforeText =
            beforeRange && SlateEditor.string(editor, beforeRange);

          let range;
          const lastSpacePos = beforeText.lastIndexOf(" ");
          if (beforeText.lastIndexOf(" ") !== -1 || beforeText === "@") {
            range = {
              anchor: {
                offset: lastSpacePos !== -1 ? lastSpacePos : 0,
                path: beforeRange.focus.path,
              },
              focus: {
                path: beforeRange.focus.path,
                offset: lastSpacePos !== -1 ? beforeText.length : 1,
              },
            };
          } else {
            range = beforeRange;
          }
          const beforeMatch =
            beforeText &&
            beforeText
              .slice(beforeText.lastIndexOf(" ") + 1)
              .match(/^@(\w*?)$/);

          if (beforeMatch) {
            setTarget(range);
            setSearch(beforeMatch[1] || "");
            setIndex(0);
            return;
          }
        }

        setTarget(undefined);
      }}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        style={{
          outline: "none",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft,
          paddingRight,
          fontSize: "14px",
          overflowX: "hidden",
          overflowY: "auto",
          height: "100%",
          minHeight,
          maxHeight: "20rem",
        }}
        className={className}
        placeholder={placeholder}
        renderPlaceholder={({ children, attributes }) => (
          <span {...attributes}>
            <span className="relative left-0.5 top-2">{children}</span>
          </span>
        )}
      />
      {target && (
        <Portal>
          <div
            ref={ref}
            style={{
              top: "-9999px",
              left: "-9999px",
              position: "absolute",
              zIndex: 60,
              padding: "3px",
              background: "white",
              borderRadius: "4px",
              boxShadow: "0 1px 5px rgba(0,0,0,.2)",
            }}
            data-cy="mentions-portal"
          >
            {debouncing || loadingPeople ? (
              <div role="status" className="w-[300px] animate-pulse">
                <div className="flex h-6 w-full items-center justify-center rounded-lg bg-zinc-300 text-center text-sm font-medium text-white">
                  {t("translation:post:searching_people")}
                </div>
              </div>
            ) : chars.length > 0 ? (
              chars.map((char, i) => (
                <div
                  key={char.email}
                  onClick={() => {
                    Transforms.select(editor, target);
                    insertMention(editor, char);
                    setTarget(undefined);
                  }}
                  onMouseEnter={() => setIndex(i)}
                  style={{
                    cursor: "pointer",
                    padding: "1px 3px",
                    borderRadius: "3px",
                    background: i === index ? "#B4D5FF" : "transparent",
                  }}
                >
                  {char.firstName} {char.lastName} ({char.email})
                </div>
              ))
            ) : (
              <div ref={targetRef} role="status" className="w-[300px]">
                <div className="flex h-fit w-full items-center justify-center rounded-lg bg-zinc-300 py-1 text-center text-sm font-medium text-white">
                  🤔 {t("translation:post:no_people_found")}
                </div>
              </div>
            )}
          </div>
        </Portal>
      )}
    </Slate>
  );
};

const withMentions = (editor: SlateEditor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return (element as MentionElement).type === "mention"
      ? true
      : isInline(element);
  };

  editor.isVoid = (element) => {
    return (element as MentionElement).type === "mention"
      ? true
      : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return (
      (element as MentionElement).type === "mention" || markableVoid(element)
    );
  };

  return editor;
};

const insertMention = (editor: SlateEditor, person: TruncatedPerson) => {
  const mention: MentionElement = {
    type: "mention",
    person,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.

type LeafProps = RenderLeafProps & {
  leaf: CustomText;
};

const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch ((element as MentionElement).type) {
    case "mention":
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Mention = ({ attributes, children, element }: RenderElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const style: React.CSSProperties = {
    verticalAlign: "baseline",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
  };
  // See if our empty text child has any styling marks applied and apply those
  if ((element.children[0] as CustomText).bold) {
    style.fontWeight = "bold";
  }
  if ((element.children[0] as CustomText).italic) {
    style.fontStyle = "italic";
  }
  const mention = element as MentionElement;
  return (
    <span
      className="inline-block text-sm font-semibold text-zinc-600 underline hover:text-zinc-700"
      {...attributes}
      contentEditable={false}
      style={style}
    >
      @{mention.person.firstName} {mention.person.lastName}
      {children}
    </span>
  );
};

const INITIAL_VALUE = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];
