/**
 *
 *
 * <EditButton />
 *
 *
 */
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";

interface Props {
  className?: string;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
}

export const EditButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.onClick}
      size={props.size || "md"}
      className={clsx("group/edit-button", props.className)}
      variant="outlined"
    >
      <PencilSquareIcon className="h-5 w-5 text-zinc-400 group-hover/edit-button:text-zinc-500" />
      {t("translation:common:edit")}
    </Button>
  );
};
