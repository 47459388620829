/**
 *
 *
 * <Wrapper />
 *
 *
 */
import clsx from "clsx";
import { motion } from "framer-motion";

import { Activity } from "../../services/teambuilder/schemas";
import { CompletedBadge } from "./CompletedBadge";

interface Props {
  activity: Partial<Activity>;
  onClick?: () => void;
  isTemplate?: boolean;
  isSystemActivity?: boolean;
  children: React.ReactNode[];
  borderColor: string;
  badgeColor: string;
  index: number;
  delay: number;
  isRunning?: boolean;
  isCompleted?: boolean;
  isComment?: boolean;
  isDragable?: boolean;
}

export const Wrapper = (props: Props) => {
  const className = clsx(
    "block items-center gap-x-2 rounded-lg border-2 bg-white",
    !props.isComment &&
      ((props.activity.isCompleted || !props.isRunning) && !props.isCompleted
        ? "opacity-60"
        : !props.isCompleted && "cursor-pointer shadow-md hover:shadow-none"),
    props.isDragable && "cursor-grab active:cursor-grabbing",
    props.borderColor
  );
  const innerClassName = "flex flex-row items-center p-3 gap-x-2";

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        type: "spring",
        duration: 0.1,
        stiffness: 300,
        damping: 24,
        delay: props.delay + props.index * 0.05,
      }}
      className="block w-full"
    >
      {props.isSystemActivity ? null : (
        <div className={className} onClick={() => props.onClick?.()}>
          <CompletedBadge
            activity={props.activity}
            badgeColor={props.badgeColor}
            isComment={props.isComment}
          />
          <div className={clsx(innerClassName)}>{props.children}</div>
        </div>
      )}
    </motion.div>
  );
};
