export const Skeleton = () => {
  return (
    <div>
      <div className="space-y-1">
        <div className="h-4 w-full animate-pulse rounded-lg bg-zinc-300 sm:max-w-xs" />
        <div className="h-4 w-full animate-pulse rounded-lg bg-zinc-300 sm:max-w-xs" />
        <div className="h-4 w-full animate-pulse rounded-lg bg-zinc-300 sm:max-w-xs" />
        <div className="h-4 w-full animate-pulse rounded-lg bg-zinc-300 sm:max-w-xs" />
      </div>
      <div className="mt-4 flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <div className="h-9 w-full animate-pulse rounded-lg bg-zinc-300 sm:w-36" />
      </div>
    </div>
  );
};
