/**
 *
 *
 * <QAndAEmpty />
 *
 *
 */
import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/ButtonV3";
import { EmptySection } from "./EmptySection";

interface Props {
  openAnswersForm: () => void;
  className?: string;
}

export const QAndAEmpty = (props: Props) => {
  const { t } = useTranslation();

  return (
    <EmptySection>
      <div className="text-center text-sm leading-loose text-zinc-500">
        {t("translation:user_guide:about_me_no_answer_description_1")}
        <br />
        {t("translation:user_guide:about_me_no_answer_description_2")}
      </div>

      <Button size="md" variant="highlight" onClick={props.openAnswersForm}>
        <PlusIcon className="h-5 w-5 text-white" />
        {t("translation:user_guide:answer_questions")}
      </Button>
    </EmptySection>
  );
};
