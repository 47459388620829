/**
 *
 *
 * <AuthorLink />
 *
 *
 */
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { SearchParam } from "../../consts";
import { SimpleUserModel } from "../../services/teambuilder/schemas";
import { fullName } from "../../utils/username";

interface Props {
  user?: SimpleUserModel;
  className?: string;
}

export const AuthorLink = (props: Props) => {
  // Ada's user ID is 0, she doesn't have a profile (yet)
  return Number(props.user?.id) > 1 ? (
    <Link
      to={`?${SearchParam.PROFILE}=${props.user?.id}`}
      className={clsx(
        "text-sm font-medium text-zinc-900 hover:underline",
        props.className
      )}
    >
      {fullName(props.user)}
    </Link>
  ) : (
    <div className={clsx("text-sm font-medium", props.className)}>
      {fullName(props.user)}
    </div>
  );
};
