/**
 *
 *
 * <TopBarV3 />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import { MobileNav } from "../MobileNav";
import { CompanyName } from "./CompanyName";
import { PostsDropdown } from "./PostsDropdown";
import { useTopBar } from "./useTopBar";

export const TopBar = (props: ReturnType<typeof useTopBar>) => {
  return (
    <div
      className={clsx(
        "relative z-10 w-full bg-white p-2.5 shadow-md lg:hidden",
        "flex items-center gap-4",
        props.className
      )}
    >
      <MobileNav />

      <CompanyName
        company={props.company}
        isLoadingCompany={props.isLoadingCompany}
      />

      <PostsDropdown />
    </div>
  );
};
