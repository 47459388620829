/**
 *
 *
 * Split.io
 *
 *
 */
import {
  IBrowserSettings,
  MockedFeaturesMap,
} from "@splitsoftware/splitio/types/splitio";
import { getAnonymousId } from "rudder-sdk-js";

import { SplitMockData } from "../splits";

const { VITE_SPLIT_SDK_API_KEY } = import.meta.env;

const createSplitConfig = (
  trafficType: string,
  key: string
): IBrowserSettings => {
  return {
    core: {
      authorizationKey: VITE_SPLIT_SDK_API_KEY || "localhost",
      key,
      trafficType,
    },
  };
};

export const getTestingSplitConfig = (
  splits?: MockedFeaturesMap
): IBrowserSettings => {
  return {
    core: {
      authorizationKey: "localhost",
      key: "testing",
    },
    features: splits || SplitMockData,
  };
};

export const getSplitConfig = (
  trafficType?: "company" | "user",
  key?: number
): IBrowserSettings => {
  if (VITE_SPLIT_SDK_API_KEY) {
    if (trafficType && key) {
      return createSplitConfig(trafficType, key.toString());
    } else {
      return createSplitConfig("anonymous", getAnonymousId());
    }
  } else {
    return getTestingSplitConfig();
  }
};
