/**
 *
 *
 * <ButtonLink />
 *
 * A react-router Link masquerading as a button.
 */
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { Size, Variant, buttonStyles } from "../../utils/button-styles-v3";

interface Props {
  to: string;
  className?: string;
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
}

/**
 *
 * ButtonLink component
 *
 * @param variant
 *  "contained" - Solid blue background, white text.
 *  "outlined" - White background, gray border.
 *  "inline" - No padding, no background, no border. Displays inline.
 *  "text" - Transparent background, blue text, no border.
 *  "danger" - Red background, white text.
 *  "text-danger" - Red text, transparent background, no border.
 *
 * @param size Change font size. Corresponds to tailwind text sizes "text-[size]".
 *  "xl" - 20px, for Edit buttons in tables
 *  "lg" - 18px, for primary buttons like Give ShoutOut
 *  "sm" - 14px, for modal form buttons
 *  "xs" - For card ctas
 *
 *  @param dark - If true, use dark theme colors
 */
export const ButtonLink = ({
  to,
  className,
  variant = "contained",
  size = "lg",
  children,
}: Props) => {
  return (
    <Link
      to={to}
      className={clsx(
        buttonStyles({
          variant,
          size,
        }),
        className
      )}
    >
      {children}
    </Link>
  );
};
