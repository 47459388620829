/**
 *
 *
 * <Avatar />
 *
 *
 */
import clsx from "clsx";
import React from "react";

import {
  Creator,
  PeopleRetrieve,
  SimpleUserModel,
  _PeopleResponse,
} from "../../services/teambuilder/schemas";
import { AvatarInitials } from "../AvatarInitialsV3";

type User = SimpleUserModel | _PeopleResponse | Creator | PeopleRetrieve;

interface Props {
  loading?: boolean;
  user?: User;
  size?:
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "xxl"
    | "xxxl"
    | "post"
    | "commentor"
    | "leaderboard";
  className?: string;
}

// These are the same sizes as AvatarStack
export const sizeMap = {
  sm: "h-1.5 w-1.5 md:h-3 md:w-3",
  md: "h-8 w-8",
  lg: "h-8 w-8 md:h-12 md:w-12",
  xl: "h-12 w-12 md:h-16 md:w-16",
  xxl: "h-28 w-28 md:h-32 md:w-32",
  xxxl: "h-32 w-32 md:h-44 md:w-44",
  post: "h-9 w-9",
  leaderboard: "h-16 w-16",
  commentor: "w-6 h-6",
};

export const Avatar = ({ loading, user, size = "md", className }: Props) => {
  const isCreator = (user: User): user is Creator =>
    Object.hasOwn(user, "name");

  return loading ? (
    <div
      className={clsx("animate-pulse rounded-full bg-zinc-300", sizeMap[size])}
    />
  ) : user?.avatar ? (
    <div className={clsx(sizeMap[size])}>
      <img
        src={
          size === "xxl"
            ? user.avatar.medium
            : size === "xxxl"
              ? user.avatar.medium
              : user.avatar.thumbnail
        }
        alt=""
        aria-hidden="true"
        className={clsx(
          "h-full w-full rounded-full bg-zinc-400 object-cover object-center",
          className
        )}
      />
    </div>
  ) : user ? (
    <AvatarInitials
      char1={isCreator(user) ? user.name : user.firstName || user.email || ""}
      char2={isCreator(user) ? user.name : user?.lastName ? user?.lastName : ""}
      size={size}
      className={className}
    />
  ) : null;
};
