/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  Activity,
  ActivityRequest,
  Challenge,
  ChallengeCount,
  ChallengeMeSummary,
  ChallengeRequest,
  ChallengesActivitiesCompletionsListParams,
  ChallengesActivitiesCountListParams,
  ChallengesActivitiesListParams,
  ChallengesGroupsListParams,
  ChallengesGroupsMembersListParams,
  ChallengesLeaderboardListParams,
  ChallengesListParams,
  ChallengesMeListParams,
  ChallengesRetrieveParams,
  ChallengesTemplatesActivitiesListParams,
  ChallengesTemplatesListParams,
  Completion,
  CompletionImageUploadRequest,
  CompletionRequest,
  Group,
  GroupRequest,
  MemberCreateModel,
  PaginatedActivityCountList,
  PaginatedActivityList,
  PaginatedChallengeList,
  PaginatedCompletionList,
  PaginatedGroupList,
  PaginatedParticipantUserModelList,
  PaginatedSimpleUserModelList,
  PaginatedTemplateActivitiesList,
  PaginatedTemplateList,
  PatchedActivityRequest,
  PatchedChallengeRequest,
  PatchedGroupRequest,
  PatchedMemberCreateModelRequest,
  PatchedTemplateActivitiesRequest,
  PatchedTemplateRequest,
  Template,
  TemplateActivities,
  TemplateActivitiesRequest,
  TemplateRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * List all challenges for the current team. Status is one of `running`, `upcoming`, or `ended`
 */
export const challengesList = (
  params?: ChallengesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedChallengeList>(
    { url: `/api/v1/challenges/`, method: "GET", params, signal },
    options
  );
};

export const getChallengesListQueryKey = (params?: ChallengesListParams) => {
  return [`/api/v1/challenges/`, ...(params ? [params] : [])] as const;
};

export const getChallengesListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChallengesListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof challengesList>>> = ({
    signal,
  }) => challengesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesList>>
>;
export type ChallengesListQueryError = ErrorType<unknown>;

export const useChallengesList = <
  TData = Awaited<ReturnType<typeof challengesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new Challenge.
 * @summary Create a Challenge
 */
export const challengesCreate = (
  challengeRequest: ChallengeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Challenge>(
    {
      url: `/api/v1/challenges/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: challengeRequest,
    },
    options
  );
};

export const getChallengesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesCreate>>,
    TError,
    { data: ChallengeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesCreate>>,
  TError,
  { data: ChallengeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesCreate>>,
    { data: ChallengeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return challengesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesCreate>>
>;
export type ChallengesCreateMutationBody = ChallengeRequest;
export type ChallengesCreateMutationError = ErrorType<unknown>;

/**
 * @summary Create a Challenge
 */
export const useChallengesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesCreate>>,
    TError,
    { data: ChallengeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenge Activities

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesActivitiesList = (
  challengesPk: number,
  params?: ChallengesActivitiesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedActivityList>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesActivitiesListQueryKey = (
  challengesPk: number,
  params?: ChallengesActivitiesListParams
) => {
  return [
    `/api/v1/challenges/${challengesPk}/activities/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesActivitiesListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesActivitiesList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesActivitiesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesActivitiesListQueryKey(challengesPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesActivitiesList>>
  > = ({ signal }) =>
    challengesActivitiesList(challengesPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!challengesPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesActivitiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesActivitiesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesList>>
>;
export type ChallengesActivitiesListQueryError = ErrorType<unknown>;

export const useChallengesActivitiesList = <
  TData = Awaited<ReturnType<typeof challengesActivitiesList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesActivitiesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesActivitiesListQueryOptions(
    challengesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Activities

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesActivitiesCreate = (
  challengesPk: number,
  activityRequest: ActivityRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Activity>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: activityRequest,
    },
    options
  );
};

export const getChallengesActivitiesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCreate>>,
    TError,
    { challengesPk: number; data: ActivityRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesActivitiesCreate>>,
  TError,
  { challengesPk: number; data: ActivityRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesActivitiesCreate>>,
    { challengesPk: number; data: ActivityRequest }
  > = (props) => {
    const { challengesPk, data } = props ?? {};

    return challengesActivitiesCreate(challengesPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesActivitiesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCreate>>
>;
export type ChallengesActivitiesCreateMutationBody = ActivityRequest;
export type ChallengesActivitiesCreateMutationError = ErrorType<unknown>;

export const useChallengesActivitiesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCreate>>,
    TError,
    { challengesPk: number; data: ActivityRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesActivitiesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Activity Completions

## Permissions
```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read answers
        iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
        iperm.HasRolePermission,  # HR & Managers can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can answer a question
    )
]
required_roles = ["HR"]

```
 */
export const challengesActivitiesCompletionsList = (
  challengesPk: number,
  activitiesPk: number,
  params?: ChallengesActivitiesCompletionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCompletionList>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesActivitiesCompletionsListQueryKey = (
  challengesPk: number,
  activitiesPk: number,
  params?: ChallengesActivitiesCompletionsListParams
) => {
  return [
    `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesActivitiesCompletionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  activitiesPk: number,
  params?: ChallengesActivitiesCompletionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesActivitiesCompletionsListQueryKey(
      challengesPk,
      activitiesPk,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>
  > = ({ signal }) =>
    challengesActivitiesCompletionsList(
      challengesPk,
      activitiesPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(challengesPk && activitiesPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesActivitiesCompletionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>
>;
export type ChallengesActivitiesCompletionsListQueryError = ErrorType<unknown>;

export const useChallengesActivitiesCompletionsList = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  activitiesPk: number,
  params?: ChallengesActivitiesCompletionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCompletionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesActivitiesCompletionsListQueryOptions(
    challengesPk,
    activitiesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Complete an Activity
 */
export const challengesActivitiesCompletionsCreate = (
  challengesPk: number,
  activitiesPk: number,
  completionRequest: CompletionRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Completion>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: completionRequest,
    },
    options
  );
};

export const getChallengesActivitiesCompletionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsCreate>>,
    TError,
    { challengesPk: number; activitiesPk: number; data: CompletionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsCreate>>,
  TError,
  { challengesPk: number; activitiesPk: number; data: CompletionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsCreate>>,
    { challengesPk: number; activitiesPk: number; data: CompletionRequest }
  > = (props) => {
    const { challengesPk, activitiesPk, data } = props ?? {};

    return challengesActivitiesCompletionsCreate(
      challengesPk,
      activitiesPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesActivitiesCompletionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsCreate>>
>;
export type ChallengesActivitiesCompletionsCreateMutationBody =
  CompletionRequest;
export type ChallengesActivitiesCompletionsCreateMutationError =
  ErrorType<unknown>;

export const useChallengesActivitiesCompletionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsCreate>>,
    TError,
    { challengesPk: number; activitiesPk: number; data: CompletionRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesCompletionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Activity Completions

## Permissions
```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read answers
        iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
        iperm.HasRolePermission,  # HR & Managers can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can answer a question
    )
]
required_roles = ["HR"]

```
 */
export const challengesActivitiesCompletionsRetrieve = (
  challengesPk: number,
  activitiesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Completion>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getChallengesActivitiesCompletionsRetrieveQueryKey = (
  challengesPk: number,
  activitiesPk: number,
  id: number
) => {
  return [
    `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/${id}/`,
  ] as const;
};

export const getChallengesActivitiesCompletionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  activitiesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesActivitiesCompletionsRetrieveQueryKey(
      challengesPk,
      activitiesPk,
      id
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>
  > = ({ signal }) =>
    challengesActivitiesCompletionsRetrieve(
      challengesPk,
      activitiesPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(challengesPk && activitiesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesActivitiesCompletionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>
>;
export type ChallengesActivitiesCompletionsRetrieveQueryError =
  ErrorType<unknown>;

export const useChallengesActivitiesCompletionsRetrieve = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  activitiesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCompletionsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesActivitiesCompletionsRetrieveQueryOptions(
    challengesPk,
    activitiesPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Activity Completions

## Permissions
```
permission_classes = [
    lambda: iperm.OrPermission(
        iperm.AuthenticatedReadOnly,  # anyone can read answers
        iperm.ObjectOwnerPermissions,  # the writer of an answer can update/delete
        iperm.HasRolePermission,  # HR & Managers can do anything
        iperm.IsAdminUser,  # as can the admin user
        iperm.AuthenticatedCreate,  # anyone can answer a question
    )
]
required_roles = ["HR"]

```
 */
export const challengesActivitiesCompletionsDestroy = (
  challengesPk: number,
  activitiesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesActivitiesCompletionsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsDestroy>>,
    TError,
    { challengesPk: number; activitiesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsDestroy>>,
  TError,
  { challengesPk: number; activitiesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsDestroy>>,
    { challengesPk: number; activitiesPk: number; id: number }
  > = (props) => {
    const { challengesPk, activitiesPk, id } = props ?? {};

    return challengesActivitiesCompletionsDestroy(
      challengesPk,
      activitiesPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesActivitiesCompletionsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCompletionsDestroy>>
>;

export type ChallengesActivitiesCompletionsDestroyMutationError =
  ErrorType<unknown>;

export const useChallengesActivitiesCompletionsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsDestroy>>,
    TError,
    { challengesPk: number; activitiesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesCompletionsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Completion image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own Completion, or
HR/IT role for other's Completions
 * @summary Completion Image Upload
 */
export const challengesActivitiesCompletionsImageUploadCreate = (
  challengesPk: number,
  activitiesPk: number,
  id: number,
  completionImageUploadRequest: CompletionImageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  const formData = new FormData();
  if (completionImageUploadRequest.file !== null) {
    formData.append("file", completionImageUploadRequest.file);
  }

  return axiosInstance<Completion | void>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/${id}/image-upload/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  );
};

export const getChallengesActivitiesCompletionsImageUploadCreateMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof challengesActivitiesCompletionsImageUploadCreate>
      >,
      TError,
      {
        challengesPk: number;
        activitiesPk: number;
        id: number;
        data: CompletionImageUploadRequest;
      },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof challengesActivitiesCompletionsImageUploadCreate>
    >,
    TError,
    {
      challengesPk: number;
      activitiesPk: number;
      id: number;
      data: CompletionImageUploadRequest;
    },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof challengesActivitiesCompletionsImageUploadCreate>
      >,
      {
        challengesPk: number;
        activitiesPk: number;
        id: number;
        data: CompletionImageUploadRequest;
      }
    > = (props) => {
      const { challengesPk, activitiesPk, id, data } = props ?? {};

      return challengesActivitiesCompletionsImageUploadCreate(
        challengesPk,
        activitiesPk,
        id,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChallengesActivitiesCompletionsImageUploadCreateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof challengesActivitiesCompletionsImageUploadCreate>>
  >;
export type ChallengesActivitiesCompletionsImageUploadCreateMutationBody =
  CompletionImageUploadRequest;
export type ChallengesActivitiesCompletionsImageUploadCreateMutationError =
  ErrorType<unknown>;

/**
 * @summary Completion Image Upload
 */
export const useChallengesActivitiesCompletionsImageUploadCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof challengesActivitiesCompletionsImageUploadCreate>
    >,
    TError,
    {
      challengesPk: number;
      activitiesPk: number;
      id: number;
      data: CompletionImageUploadRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesCompletionsImageUploadCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Completion image upload. Can take a PNG or JPEG

### Permissions:
Authenticated user for their own Completion, or
HR/IT role for other's Completions
 * @summary Completion Image Upload
 */
export const challengesActivitiesCompletionsImageUploadDestroy = (
  challengesPk: number,
  activitiesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Completion | void>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${activitiesPk}/completions/${id}/image-upload/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesActivitiesCompletionsImageUploadDestroyMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof challengesActivitiesCompletionsImageUploadDestroy>
      >,
      TError,
      { challengesPk: number; activitiesPk: number; id: number },
      TContext
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof challengesActivitiesCompletionsImageUploadDestroy>
    >,
    TError,
    { challengesPk: number; activitiesPk: number; id: number },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof challengesActivitiesCompletionsImageUploadDestroy>
      >,
      { challengesPk: number; activitiesPk: number; id: number }
    > = (props) => {
      const { challengesPk, activitiesPk, id } = props ?? {};

      return challengesActivitiesCompletionsImageUploadDestroy(
        challengesPk,
        activitiesPk,
        id,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type ChallengesActivitiesCompletionsImageUploadDestroyMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof challengesActivitiesCompletionsImageUploadDestroy>
    >
  >;

export type ChallengesActivitiesCompletionsImageUploadDestroyMutationError =
  ErrorType<unknown>;

/**
 * @summary Completion Image Upload
 */
export const useChallengesActivitiesCompletionsImageUploadDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof challengesActivitiesCompletionsImageUploadDestroy>
    >,
    TError,
    { challengesPk: number; activitiesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesCompletionsImageUploadDestroyMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * # Challenge Activities

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesActivitiesRetrieve = (
  challengesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Activity>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getChallengesActivitiesRetrieveQueryKey = (
  challengesPk: number,
  id: number
) => {
  return [`/api/v1/challenges/${challengesPk}/activities/${id}/`] as const;
};

export const getChallengesActivitiesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesActivitiesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesActivitiesRetrieveQueryKey(challengesPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesActivitiesRetrieve>>
  > = ({ signal }) =>
    challengesActivitiesRetrieve(challengesPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(challengesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesActivitiesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesActivitiesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesRetrieve>>
>;
export type ChallengesActivitiesRetrieveQueryError = ErrorType<unknown>;

export const useChallengesActivitiesRetrieve = <
  TData = Awaited<ReturnType<typeof challengesActivitiesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesActivitiesRetrieveQueryOptions(
    challengesPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Activities

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesActivitiesPartialUpdate = (
  challengesPk: number,
  id: number,
  patchedActivityRequest: PatchedActivityRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Activity>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedActivityRequest,
    },
    options
  );
};

export const getChallengesActivitiesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesPartialUpdate>>,
    TError,
    { challengesPk: number; id: number; data: PatchedActivityRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesActivitiesPartialUpdate>>,
  TError,
  { challengesPk: number; id: number; data: PatchedActivityRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesActivitiesPartialUpdate>>,
    { challengesPk: number; id: number; data: PatchedActivityRequest }
  > = (props) => {
    const { challengesPk, id, data } = props ?? {};

    return challengesActivitiesPartialUpdate(
      challengesPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesActivitiesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesPartialUpdate>>
>;
export type ChallengesActivitiesPartialUpdateMutationBody =
  PatchedActivityRequest;
export type ChallengesActivitiesPartialUpdateMutationError = ErrorType<unknown>;

export const useChallengesActivitiesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesPartialUpdate>>,
    TError,
    { challengesPk: number; id: number; data: PatchedActivityRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenge Activities

## Permissions
```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesActivitiesDestroy = (
  challengesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesActivitiesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesActivitiesDestroy>>,
  TError,
  { challengesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesActivitiesDestroy>>,
    { challengesPk: number; id: number }
  > = (props) => {
    const { challengesPk, id } = props ?? {};

    return challengesActivitiesDestroy(challengesPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesActivitiesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesDestroy>>
>;

export type ChallengesActivitiesDestroyMutationError = ErrorType<unknown>;

export const useChallengesActivitiesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesActivitiesDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesActivitiesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns stats about challenge activities
 */
export const challengesActivitiesCountList = (
  challengesPk: number,
  params?: ChallengesActivitiesCountListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedActivityCountList>(
    {
      url: `/api/v1/challenges/${challengesPk}/activities/count/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesActivitiesCountListQueryKey = (
  challengesPk: number,
  params?: ChallengesActivitiesCountListParams
) => {
  return [
    `/api/v1/challenges/${challengesPk}/activities/count/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesActivitiesCountListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCountList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesActivitiesCountListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCountList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesActivitiesCountListQueryKey(challengesPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesActivitiesCountList>>
  > = ({ signal }) =>
    challengesActivitiesCountList(challengesPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!challengesPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesActivitiesCountList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesActivitiesCountListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesActivitiesCountList>>
>;
export type ChallengesActivitiesCountListQueryError = ErrorType<unknown>;

export const useChallengesActivitiesCountList = <
  TData = Awaited<ReturnType<typeof challengesActivitiesCountList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesActivitiesCountListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesActivitiesCountList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesActivitiesCountListQueryOptions(
    challengesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Allow the addition and removal of members to a department, location, team, or group
 */
export const challengesGroupsList = (
  challengesPk: number,
  params?: ChallengesGroupsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedGroupList>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesGroupsListQueryKey = (
  challengesPk: number,
  params?: ChallengesGroupsListParams
) => {
  return [
    `/api/v1/challenges/${challengesPk}/groups/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesGroupsListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesGroupsList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesGroupsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesGroupsListQueryKey(challengesPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesGroupsList>>
  > = ({ signal }) =>
    challengesGroupsList(challengesPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!challengesPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesGroupsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesGroupsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsList>>
>;
export type ChallengesGroupsListQueryError = ErrorType<unknown>;

export const useChallengesGroupsList = <
  TData = Awaited<ReturnType<typeof challengesGroupsList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  params?: ChallengesGroupsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesGroupsListQueryOptions(
    challengesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Allow the addition and removal of members to a department, location, team, or group
 */
export const challengesGroupsCreate = (
  challengesPk: number,
  groupRequest: GroupRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Group>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: groupRequest,
    },
    options
  );
};

export const getChallengesGroupsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsCreate>>,
    TError,
    { challengesPk: number; data: GroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsCreate>>,
  TError,
  { challengesPk: number; data: GroupRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsCreate>>,
    { challengesPk: number; data: GroupRequest }
  > = (props) => {
    const { challengesPk, data } = props ?? {};

    return challengesGroupsCreate(challengesPk, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsCreate>>
>;
export type ChallengesGroupsCreateMutationBody = GroupRequest;
export type ChallengesGroupsCreateMutationError = ErrorType<unknown>;

export const useChallengesGroupsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsCreate>>,
    TError,
    { challengesPk: number; data: GroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesGroupsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Group Members

List all the members of the specified challenge group.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const challengesGroupsMembersList = (
  challengesPk: number,
  groupsPk: number,
  params?: ChallengesGroupsMembersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedSimpleUserModelList>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${groupsPk}/members/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesGroupsMembersListQueryKey = (
  challengesPk: number,
  groupsPk: number,
  params?: ChallengesGroupsMembersListParams
) => {
  return [
    `/api/v1/challenges/${challengesPk}/groups/${groupsPk}/members/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesGroupsMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesGroupsMembersList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  groupsPk: number,
  params?: ChallengesGroupsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesGroupsMembersListQueryKey(challengesPk, groupsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesGroupsMembersList>>
  > = ({ signal }) =>
    challengesGroupsMembersList(
      challengesPk,
      groupsPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(challengesPk && groupsPk),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesGroupsMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesGroupsMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsMembersList>>
>;
export type ChallengesGroupsMembersListQueryError = ErrorType<unknown>;

export const useChallengesGroupsMembersList = <
  TData = Awaited<ReturnType<typeof challengesGroupsMembersList>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  groupsPk: number,
  params?: ChallengesGroupsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesGroupsMembersListQueryOptions(
    challengesPk,
    groupsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Group Members

List all the members of the specified challenge group.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const challengesGroupsMembersPartialUpdate = (
  challengesPk: number,
  groupsPk: number,
  id: number,
  patchedMemberCreateModelRequest: PatchedMemberCreateModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MemberCreateModel>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${groupsPk}/members/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMemberCreateModelRequest,
    },
    options
  );
};

export const getChallengesGroupsMembersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsMembersPartialUpdate>>,
    TError,
    {
      challengesPk: number;
      groupsPk: number;
      id: number;
      data: PatchedMemberCreateModelRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsMembersPartialUpdate>>,
  TError,
  {
    challengesPk: number;
    groupsPk: number;
    id: number;
    data: PatchedMemberCreateModelRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsMembersPartialUpdate>>,
    {
      challengesPk: number;
      groupsPk: number;
      id: number;
      data: PatchedMemberCreateModelRequest;
    }
  > = (props) => {
    const { challengesPk, groupsPk, id, data } = props ?? {};

    return challengesGroupsMembersPartialUpdate(
      challengesPk,
      groupsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsMembersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsMembersPartialUpdate>>
>;
export type ChallengesGroupsMembersPartialUpdateMutationBody =
  PatchedMemberCreateModelRequest;
export type ChallengesGroupsMembersPartialUpdateMutationError =
  ErrorType<unknown>;

export const useChallengesGroupsMembersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsMembersPartialUpdate>>,
    TError,
    {
      challengesPk: number;
      groupsPk: number;
      id: number;
      data: PatchedMemberCreateModelRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesGroupsMembersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove a membership.
 */
export const challengesGroupsMembersDestroy = (
  challengesPk: number,
  groupsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${groupsPk}/members/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesGroupsMembersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsMembersDestroy>>,
    TError,
    { challengesPk: number; groupsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsMembersDestroy>>,
  TError,
  { challengesPk: number; groupsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsMembersDestroy>>,
    { challengesPk: number; groupsPk: number; id: number }
  > = (props) => {
    const { challengesPk, groupsPk, id } = props ?? {};

    return challengesGroupsMembersDestroy(
      challengesPk,
      groupsPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsMembersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsMembersDestroy>>
>;

export type ChallengesGroupsMembersDestroyMutationError = ErrorType<unknown>;

export const useChallengesGroupsMembersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsMembersDestroy>>,
    TError,
    { challengesPk: number; groupsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesGroupsMembersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allow the addition and removal of members to a department, location, team, or group
 */
export const challengesGroupsRetrieve = (
  challengesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Group>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getChallengesGroupsRetrieveQueryKey = (
  challengesPk: number,
  id: number
) => {
  return [`/api/v1/challenges/${challengesPk}/groups/${id}/`] as const;
};

export const getChallengesGroupsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesGroupsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesGroupsRetrieveQueryKey(challengesPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesGroupsRetrieve>>
  > = ({ signal }) =>
    challengesGroupsRetrieve(challengesPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(challengesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesGroupsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesGroupsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsRetrieve>>
>;
export type ChallengesGroupsRetrieveQueryError = ErrorType<unknown>;

export const useChallengesGroupsRetrieve = <
  TData = Awaited<ReturnType<typeof challengesGroupsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesGroupsRetrieveQueryOptions(
    challengesPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Allow the addition and removal of members to a department, location, team, or group
 */
export const challengesGroupsPartialUpdate = (
  challengesPk: number,
  id: number,
  patchedGroupRequest: PatchedGroupRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Group>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedGroupRequest,
    },
    options
  );
};

export const getChallengesGroupsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsPartialUpdate>>,
    TError,
    { challengesPk: number; id: number; data: PatchedGroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsPartialUpdate>>,
  TError,
  { challengesPk: number; id: number; data: PatchedGroupRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsPartialUpdate>>,
    { challengesPk: number; id: number; data: PatchedGroupRequest }
  > = (props) => {
    const { challengesPk, id, data } = props ?? {};

    return challengesGroupsPartialUpdate(
      challengesPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsPartialUpdate>>
>;
export type ChallengesGroupsPartialUpdateMutationBody = PatchedGroupRequest;
export type ChallengesGroupsPartialUpdateMutationError = ErrorType<unknown>;

export const useChallengesGroupsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsPartialUpdate>>,
    TError,
    { challengesPk: number; id: number; data: PatchedGroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesGroupsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Allow the addition and removal of members to a department, location, team, or group
 */
export const challengesGroupsDestroy = (
  challengesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesGroupsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsDestroy>>,
  TError,
  { challengesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsDestroy>>,
    { challengesPk: number; id: number }
  > = (props) => {
    const { challengesPk, id } = props ?? {};

    return challengesGroupsDestroy(challengesPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsDestroy>>
>;

export type ChallengesGroupsDestroyMutationError = ErrorType<unknown>;

export const useChallengesGroupsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesGroupsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add myself as a member if isOpen to new members
 */
export const challengesGroupsJoinCreate = (
  challengesPk: number,
  id: number,
  groupRequest: GroupRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Group>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${id}/join/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: groupRequest,
    },
    options
  );
};

export const getChallengesGroupsJoinCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsJoinCreate>>,
    TError,
    { challengesPk: number; id: number; data: GroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsJoinCreate>>,
  TError,
  { challengesPk: number; id: number; data: GroupRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsJoinCreate>>,
    { challengesPk: number; id: number; data: GroupRequest }
  > = (props) => {
    const { challengesPk, id, data } = props ?? {};

    return challengesGroupsJoinCreate(challengesPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsJoinCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsJoinCreate>>
>;
export type ChallengesGroupsJoinCreateMutationBody = GroupRequest;
export type ChallengesGroupsJoinCreateMutationError = ErrorType<unknown>;

export const useChallengesGroupsJoinCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsJoinCreate>>,
    TError,
    { challengesPk: number; id: number; data: GroupRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesGroupsJoinCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove myself as a member
 */
export const challengesGroupsLeaveDestroy = (
  challengesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/${id}/leave/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesGroupsLeaveDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsLeaveDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesGroupsLeaveDestroy>>,
  TError,
  { challengesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesGroupsLeaveDestroy>>,
    { challengesPk: number; id: number }
  > = (props) => {
    const { challengesPk, id } = props ?? {};

    return challengesGroupsLeaveDestroy(challengesPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesGroupsLeaveDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsLeaveDestroy>>
>;

export type ChallengesGroupsLeaveDestroyMutationError = ErrorType<unknown>;

export const useChallengesGroupsLeaveDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesGroupsLeaveDestroy>>,
    TError,
    { challengesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesGroupsLeaveDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * My memberships (distinct from things I manage at /me)
 */
export const challengesGroupsMembershipsRetrieve = (
  challengesPk: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Group>(
    {
      url: `/api/v1/challenges/${challengesPk}/groups/memberships/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getChallengesGroupsMembershipsRetrieveQueryKey = (
  challengesPk: number
) => {
  return [`/api/v1/challenges/${challengesPk}/groups/memberships/`] as const;
};

export const getChallengesGroupsMembershipsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesGroupsMembershipsRetrieveQueryKey(challengesPk);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>
  > = ({ signal }) =>
    challengesGroupsMembershipsRetrieve(challengesPk, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!challengesPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesGroupsMembershipsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>
>;
export type ChallengesGroupsMembershipsRetrieveQueryError = ErrorType<unknown>;

export const useChallengesGroupsMembershipsRetrieve = <
  TData = Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  challengesPk: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesGroupsMembershipsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesGroupsMembershipsRetrieveQueryOptions(
    challengesPk,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenges
Everything needed to manage Challenges.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesRetrieve = (
  id: number,
  params?: ChallengesRetrieveParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Challenge>(
    { url: `/api/v1/challenges/${id}/`, method: "GET", params, signal },
    options
  );
};

export const getChallengesRetrieveQueryKey = (
  id: number,
  params?: ChallengesRetrieveParams
) => {
  return [`/api/v1/challenges/${id}/`, ...(params ? [params] : [])] as const;
};

export const getChallengesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ChallengesRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesRetrieve>>
  > = ({ signal }) => challengesRetrieve(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesRetrieve>>
>;
export type ChallengesRetrieveQueryError = ErrorType<unknown>;

export const useChallengesRetrieve = <
  TData = Awaited<ReturnType<typeof challengesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ChallengesRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenges
Everything needed to manage Challenges.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesPartialUpdate = (
  id: number,
  patchedChallengeRequest: PatchedChallengeRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Challenge>(
    {
      url: `/api/v1/challenges/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedChallengeRequest,
    },
    options
  );
};

export const getChallengesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesPartialUpdate>>,
    TError,
    { id: number; data: PatchedChallengeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesPartialUpdate>>,
  TError,
  { id: number; data: PatchedChallengeRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesPartialUpdate>>,
    { id: number; data: PatchedChallengeRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return challengesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesPartialUpdate>>
>;
export type ChallengesPartialUpdateMutationBody = PatchedChallengeRequest;
export type ChallengesPartialUpdateMutationError = ErrorType<unknown>;

export const useChallengesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesPartialUpdate>>,
    TError,
    { id: number; data: PatchedChallengeRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenges
Everything needed to manage Challenges.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const challengesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/challenges/${id}/`, method: "DELETE" },
    options
  );
};

export const getChallengesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return challengesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesDestroy>>
>;

export type ChallengesDestroyMutationError = ErrorType<unknown>;

export const useChallengesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns a list of participants for the specified challenge, ordered by the number of activities completed
and the earliest completion time.
 */
export const challengesLeaderboardList = (
  id: number,
  params?: ChallengesLeaderboardListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedParticipantUserModelList>(
    {
      url: `/api/v1/challenges/${id}/leaderboard/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesLeaderboardListQueryKey = (
  id: number,
  params?: ChallengesLeaderboardListParams
) => {
  return [
    `/api/v1/challenges/${id}/leaderboard/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesLeaderboardListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesLeaderboardList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ChallengesLeaderboardListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesLeaderboardList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesLeaderboardListQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesLeaderboardList>>
  > = ({ signal }) =>
    challengesLeaderboardList(id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesLeaderboardList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesLeaderboardListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesLeaderboardList>>
>;
export type ChallengesLeaderboardListQueryError = ErrorType<unknown>;

export const useChallengesLeaderboardList = <
  TData = Awaited<ReturnType<typeof challengesLeaderboardList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: ChallengesLeaderboardListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesLeaderboardList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesLeaderboardListQueryOptions(
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns stats about challenges
 */
export const challengesCountRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ChallengeCount>(
    { url: `/api/v1/challenges/count/`, method: "GET", signal },
    options
  );
};

export const getChallengesCountRetrieveQueryKey = () => {
  return [`/api/v1/challenges/count/`] as const;
};

export const getChallengesCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof challengesCountRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesCountRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesCountRetrieve>>
  > = ({ signal }) => challengesCountRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesCountRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesCountRetrieve>>
>;
export type ChallengesCountRetrieveQueryError = ErrorType<unknown>;

export const useChallengesCountRetrieve = <
  TData = Awaited<ReturnType<typeof challengesCountRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof challengesCountRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesCountRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Current user's challenges
 */
export const challengesMeList = (
  params?: ChallengesMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedChallengeList>(
    { url: `/api/v1/challenges/me/`, method: "GET", params, signal },
    options
  );
};

export const getChallengesMeListQueryKey = (
  params?: ChallengesMeListParams
) => {
  return [`/api/v1/challenges/me/`, ...(params ? [params] : [])] as const;
};

export const getChallengesMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesMeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesMeList>>
  > = ({ signal }) => challengesMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesMeList>>
>;
export type ChallengesMeListQueryError = ErrorType<unknown>;

export const useChallengesMeList = <
  TData = Awaited<ReturnType<typeof challengesMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Current user challenge stats
 */
export const challengesMeSummaryRetrieve = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ChallengeMeSummary>(
    { url: `/api/v1/challenges/me_summary/`, method: "GET", signal },
    options
  );
};

export const getChallengesMeSummaryRetrieveQueryKey = () => {
  return [`/api/v1/challenges/me_summary/`] as const;
};

export const getChallengesMeSummaryRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesMeSummaryRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>
  > = ({ signal }) => challengesMeSummaryRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesMeSummaryRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>
>;
export type ChallengesMeSummaryRetrieveQueryError = ErrorType<unknown>;

export const useChallengesMeSummaryRetrieve = <
  TData = Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof challengesMeSummaryRetrieve>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesMeSummaryRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Templates

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager]
```
 */
export const challengesTemplatesList = (
  params?: ChallengesTemplatesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTemplateList>(
    { url: `/api/v1/challenges/templates/`, method: "GET", params, signal },
    options
  );
};

export const getChallengesTemplatesListQueryKey = (
  params?: ChallengesTemplatesListParams
) => {
  return [
    `/api/v1/challenges/templates/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesTemplatesListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesTemplatesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesTemplatesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesTemplatesListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesTemplatesList>>
  > = ({ signal }) => challengesTemplatesList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesTemplatesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesTemplatesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesList>>
>;
export type ChallengesTemplatesListQueryError = ErrorType<unknown>;

export const useChallengesTemplatesList = <
  TData = Awaited<ReturnType<typeof challengesTemplatesList>>,
  TError = ErrorType<unknown>,
>(
  params?: ChallengesTemplatesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesTemplatesListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Templates

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager]
```
 */
export const challengesTemplatesCreate = (
  templateRequest: TemplateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Template>(
    {
      url: `/api/v1/challenges/templates/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: templateRequest,
    },
    options
  );
};

export const getChallengesTemplatesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesCreate>>,
    TError,
    { data: TemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesCreate>>,
  TError,
  { data: TemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesCreate>>,
    { data: TemplateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return challengesTemplatesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesCreate>>
>;
export type ChallengesTemplatesCreateMutationBody = TemplateRequest;
export type ChallengesTemplatesCreateMutationError = ErrorType<unknown>;

export const useChallengesTemplatesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesCreate>>,
    TError,
    { data: TemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesTemplatesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenge Templates

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager]
```
 */
export const challengesTemplatesRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Template>(
    { url: `/api/v1/challenges/templates/${id}/`, method: "GET", signal },
    options
  );
};

export const getChallengesTemplatesRetrieveQueryKey = (id: number) => {
  return [`/api/v1/challenges/templates/${id}/`] as const;
};

export const getChallengesTemplatesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesTemplatesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChallengesTemplatesRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesTemplatesRetrieve>>
  > = ({ signal }) => challengesTemplatesRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesTemplatesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesTemplatesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesRetrieve>>
>;
export type ChallengesTemplatesRetrieveQueryError = ErrorType<unknown>;

export const useChallengesTemplatesRetrieve = <
  TData = Awaited<ReturnType<typeof challengesTemplatesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesTemplatesRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Templates

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager]
```
 */
export const challengesTemplatesPartialUpdate = (
  id: number,
  patchedTemplateRequest: PatchedTemplateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Template>(
    {
      url: `/api/v1/challenges/templates/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedTemplateRequest,
    },
    options
  );
};

export const getChallengesTemplatesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesPartialUpdate>>,
    TError,
    { id: number; data: PatchedTemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesPartialUpdate>>,
  TError,
  { id: number; data: PatchedTemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesPartialUpdate>>,
    { id: number; data: PatchedTemplateRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return challengesTemplatesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesPartialUpdate>>
>;
export type ChallengesTemplatesPartialUpdateMutationBody =
  PatchedTemplateRequest;
export type ChallengesTemplatesPartialUpdateMutationError = ErrorType<unknown>;

export const useChallengesTemplatesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesPartialUpdate>>,
    TError,
    { id: number; data: PatchedTemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesTemplatesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenge Templates

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager]
```
 */
export const challengesTemplatesDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/challenges/templates/${id}/`, method: "DELETE" },
    options
  );
};

export const getChallengesTemplatesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return challengesTemplatesDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesDestroy>>
>;

export type ChallengesTemplatesDestroyMutationError = ErrorType<unknown>;

export const useChallengesTemplatesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getChallengesTemplatesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Template Activities

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const challengesTemplatesActivitiesList = (
  templatesPk: number,
  params?: ChallengesTemplatesActivitiesListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTemplateActivitiesList>(
    {
      url: `/api/v1/challenges/templates/${templatesPk}/activities/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getChallengesTemplatesActivitiesListQueryKey = (
  templatesPk: number,
  params?: ChallengesTemplatesActivitiesListParams
) => {
  return [
    `/api/v1/challenges/templates/${templatesPk}/activities/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChallengesTemplatesActivitiesListQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>,
  TError = ErrorType<unknown>,
>(
  templatesPk: number,
  params?: ChallengesTemplatesActivitiesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesTemplatesActivitiesListQueryKey(templatesPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>
  > = ({ signal }) =>
    challengesTemplatesActivitiesList(
      templatesPk,
      params,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!templatesPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesTemplatesActivitiesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>
>;
export type ChallengesTemplatesActivitiesListQueryError = ErrorType<unknown>;

export const useChallengesTemplatesActivitiesList = <
  TData = Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>,
  TError = ErrorType<unknown>,
>(
  templatesPk: number,
  params?: ChallengesTemplatesActivitiesListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesActivitiesList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesTemplatesActivitiesListQueryOptions(
    templatesPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Template Activities

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const challengesTemplatesActivitiesCreate = (
  templatesPk: number,
  templateActivitiesRequest: TemplateActivitiesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TemplateActivities>(
    {
      url: `/api/v1/challenges/templates/${templatesPk}/activities/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: templateActivitiesRequest,
    },
    options
  );
};

export const getChallengesTemplatesActivitiesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesCreate>>,
    TError,
    { templatesPk: number; data: TemplateActivitiesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesCreate>>,
  TError,
  { templatesPk: number; data: TemplateActivitiesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesCreate>>,
    { templatesPk: number; data: TemplateActivitiesRequest }
  > = (props) => {
    const { templatesPk, data } = props ?? {};

    return challengesTemplatesActivitiesCreate(
      templatesPk,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesActivitiesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesCreate>>
>;
export type ChallengesTemplatesActivitiesCreateMutationBody =
  TemplateActivitiesRequest;
export type ChallengesTemplatesActivitiesCreateMutationError =
  ErrorType<unknown>;

export const useChallengesTemplatesActivitiesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesCreate>>,
    TError,
    { templatesPk: number; data: TemplateActivitiesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesTemplatesActivitiesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Template Activities

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const challengesTemplatesActivitiesRetrieve = (
  templatesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TemplateActivities>(
    {
      url: `/api/v1/challenges/templates/${templatesPk}/activities/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getChallengesTemplatesActivitiesRetrieveQueryKey = (
  templatesPk: number,
  id: number
) => {
  return [
    `/api/v1/challenges/templates/${templatesPk}/activities/${id}/`,
  ] as const;
};

export const getChallengesTemplatesActivitiesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  templatesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChallengesTemplatesActivitiesRetrieveQueryKey(templatesPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>
  > = ({ signal }) =>
    challengesTemplatesActivitiesRetrieve(
      templatesPk,
      id,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(templatesPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChallengesTemplatesActivitiesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>
>;
export type ChallengesTemplatesActivitiesRetrieveQueryError =
  ErrorType<unknown>;

export const useChallengesTemplatesActivitiesRetrieve = <
  TData = Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  templatesPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof challengesTemplatesActivitiesRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChallengesTemplatesActivitiesRetrieveQueryOptions(
    templatesPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Template Activities

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const challengesTemplatesActivitiesPartialUpdate = (
  templatesPk: number,
  id: number,
  patchedTemplateActivitiesRequest: PatchedTemplateActivitiesRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TemplateActivities>(
    {
      url: `/api/v1/challenges/templates/${templatesPk}/activities/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedTemplateActivitiesRequest,
    },
    options
  );
};

export const getChallengesTemplatesActivitiesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesPartialUpdate>>,
    TError,
    { templatesPk: number; id: number; data: PatchedTemplateActivitiesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesPartialUpdate>>,
  TError,
  { templatesPk: number; id: number; data: PatchedTemplateActivitiesRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesPartialUpdate>>,
    { templatesPk: number; id: number; data: PatchedTemplateActivitiesRequest }
  > = (props) => {
    const { templatesPk, id, data } = props ?? {};

    return challengesTemplatesActivitiesPartialUpdate(
      templatesPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesActivitiesPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesPartialUpdate>>
  >;
export type ChallengesTemplatesActivitiesPartialUpdateMutationBody =
  PatchedTemplateActivitiesRequest;
export type ChallengesTemplatesActivitiesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useChallengesTemplatesActivitiesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesPartialUpdate>>,
    TError,
    { templatesPk: number; id: number; data: PatchedTemplateActivitiesRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesTemplatesActivitiesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Template Activities

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const challengesTemplatesActivitiesDestroy = (
  templatesPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/challenges/templates/${templatesPk}/activities/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getChallengesTemplatesActivitiesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesDestroy>>,
    TError,
    { templatesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesDestroy>>,
  TError,
  { templatesPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesDestroy>>,
    { templatesPk: number; id: number }
  > = (props) => {
    const { templatesPk, id } = props ?? {};

    return challengesTemplatesActivitiesDestroy(
      templatesPk,
      id,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChallengesTemplatesActivitiesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof challengesTemplatesActivitiesDestroy>>
>;

export type ChallengesTemplatesActivitiesDestroyMutationError =
  ErrorType<unknown>;

export const useChallengesTemplatesActivitiesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof challengesTemplatesActivitiesDestroy>>,
    TError,
    { templatesPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getChallengesTemplatesActivitiesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
