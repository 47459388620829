/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  DepartmentModel,
  DepartmentModelRequest,
  DepartmentsListParams,
  DepartmentsMeListParams,
  DepartmentsMembersListParams,
  DepartmentsMembershipsListParams,
  PaginatedDepartmentModelList,
  PatchedDepartmentModelRequest,
  PatchedMemberCreateModelRequest,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Department

Everything needed to manage a department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsList = (
  params?: DepartmentsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedDepartmentModelList>(
    { url: `/api/v1/departments/`, method: "GET", params, signal },
    options
  );
};

export const getDepartmentsListQueryKey = (params?: DepartmentsListParams) => {
  return [`/api/v1/departments/`, ...(params ? [params] : [])] as const;
};

export const getDepartmentsListQueryOptions = <
  TData = Awaited<ReturnType<typeof departmentsList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDepartmentsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof departmentsList>>> = ({
    signal,
  }) => departmentsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof departmentsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DepartmentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof departmentsList>>
>;
export type DepartmentsListQueryError = ErrorType<unknown>;

export const useDepartmentsList = <
  TData = Awaited<ReturnType<typeof departmentsList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDepartmentsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Department

Everything needed to manage a department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsCreate = (
  departmentModelRequest: DepartmentModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DepartmentModel>(
    {
      url: `/api/v1/departments/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: departmentModelRequest,
    },
    options
  );
};

export const getDepartmentsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsCreate>>,
    TError,
    { data: DepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsCreate>>,
  TError,
  { data: DepartmentModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsCreate>>,
    { data: DepartmentModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return departmentsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsCreate>>
>;
export type DepartmentsCreateMutationBody = DepartmentModelRequest;
export type DepartmentsCreateMutationError = ErrorType<unknown>;

export const useDepartmentsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsCreate>>,
    TError,
    { data: DepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Department Members

List all the members of the specified department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsMembersList = (
  departmentsPk: number,
  params?: DepartmentsMembersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedDepartmentModelList>(
    {
      url: `/api/v1/departments/${departmentsPk}/members/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getDepartmentsMembersListQueryKey = (
  departmentsPk: number,
  params?: DepartmentsMembersListParams
) => {
  return [
    `/api/v1/departments/${departmentsPk}/members/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDepartmentsMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof departmentsMembersList>>,
  TError = ErrorType<unknown>,
>(
  departmentsPk: number,
  params?: DepartmentsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDepartmentsMembersListQueryKey(departmentsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof departmentsMembersList>>
  > = ({ signal }) =>
    departmentsMembersList(departmentsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!departmentsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof departmentsMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DepartmentsMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof departmentsMembersList>>
>;
export type DepartmentsMembersListQueryError = ErrorType<unknown>;

export const useDepartmentsMembersList = <
  TData = Awaited<ReturnType<typeof departmentsMembersList>>,
  TError = ErrorType<unknown>,
>(
  departmentsPk: number,
  params?: DepartmentsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDepartmentsMembersListQueryOptions(
    departmentsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Department Members

List all the members of the specified department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsMembersPartialUpdate = (
  departmentsPk: number,
  id: number,
  patchedMemberCreateModelRequest: PatchedMemberCreateModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DepartmentModel>(
    {
      url: `/api/v1/departments/${departmentsPk}/members/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMemberCreateModelRequest,
    },
    options
  );
};

export const getDepartmentsMembersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsMembersPartialUpdate>>,
    TError,
    {
      departmentsPk: number;
      id: number;
      data: PatchedMemberCreateModelRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsMembersPartialUpdate>>,
  TError,
  { departmentsPk: number; id: number; data: PatchedMemberCreateModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsMembersPartialUpdate>>,
    { departmentsPk: number; id: number; data: PatchedMemberCreateModelRequest }
  > = (props) => {
    const { departmentsPk, id, data } = props ?? {};

    return departmentsMembersPartialUpdate(
      departmentsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsMembersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsMembersPartialUpdate>>
>;
export type DepartmentsMembersPartialUpdateMutationBody =
  PatchedMemberCreateModelRequest;
export type DepartmentsMembersPartialUpdateMutationError = ErrorType<unknown>;

export const useDepartmentsMembersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsMembersPartialUpdate>>,
    TError,
    {
      departmentsPk: number;
      id: number;
      data: PatchedMemberCreateModelRequest;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getDepartmentsMembersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove a membership.
 */
export const departmentsMembersDestroy = (
  departmentsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/departments/${departmentsPk}/members/${id}/`,
      method: "DELETE",
    },
    options
  );
};

export const getDepartmentsMembersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsMembersDestroy>>,
    TError,
    { departmentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsMembersDestroy>>,
  TError,
  { departmentsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsMembersDestroy>>,
    { departmentsPk: number; id: number }
  > = (props) => {
    const { departmentsPk, id } = props ?? {};

    return departmentsMembersDestroy(departmentsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsMembersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsMembersDestroy>>
>;

export type DepartmentsMembersDestroyMutationError = ErrorType<unknown>;

export const useDepartmentsMembersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsMembersDestroy>>,
    TError,
    { departmentsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsMembersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Department

Everything needed to manage a department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<DepartmentModel>(
    { url: `/api/v1/departments/${id}/`, method: "GET", signal },
    options
  );
};

export const getDepartmentsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/departments/${id}/`] as const;
};

export const getDepartmentsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof departmentsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDepartmentsRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof departmentsRetrieve>>
  > = ({ signal }) => departmentsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof departmentsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DepartmentsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof departmentsRetrieve>>
>;
export type DepartmentsRetrieveQueryError = ErrorType<unknown>;

export const useDepartmentsRetrieve = <
  TData = Awaited<ReturnType<typeof departmentsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDepartmentsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Department

Everything needed to manage a department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsPartialUpdate = (
  id: number,
  patchedDepartmentModelRequest: PatchedDepartmentModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DepartmentModel>(
    {
      url: `/api/v1/departments/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedDepartmentModelRequest,
    },
    options
  );
};

export const getDepartmentsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsPartialUpdate>>,
    TError,
    { id: number; data: PatchedDepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsPartialUpdate>>,
  TError,
  { id: number; data: PatchedDepartmentModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsPartialUpdate>>,
    { id: number; data: PatchedDepartmentModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return departmentsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsPartialUpdate>>
>;
export type DepartmentsPartialUpdateMutationBody =
  PatchedDepartmentModelRequest;
export type DepartmentsPartialUpdateMutationError = ErrorType<unknown>;

export const useDepartmentsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsPartialUpdate>>,
    TError,
    { id: number; data: PatchedDepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Department

Everything needed to manage a department.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR"]
```
 */
export const departmentsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/departments/${id}/`, method: "DELETE" },
    options
  );
};

export const getDepartmentsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return departmentsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsDestroy>>
>;

export type DepartmentsDestroyMutationError = ErrorType<unknown>;

export const useDepartmentsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add myself as a member if isOpen to new members
 */
export const departmentsJoinCreate = (
  id: number,
  departmentModelRequest: DepartmentModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<DepartmentModel>(
    {
      url: `/api/v1/departments/${id}/join/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: departmentModelRequest,
    },
    options
  );
};

export const getDepartmentsJoinCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsJoinCreate>>,
    TError,
    { id: number; data: DepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsJoinCreate>>,
  TError,
  { id: number; data: DepartmentModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsJoinCreate>>,
    { id: number; data: DepartmentModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return departmentsJoinCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsJoinCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsJoinCreate>>
>;
export type DepartmentsJoinCreateMutationBody = DepartmentModelRequest;
export type DepartmentsJoinCreateMutationError = ErrorType<unknown>;

export const useDepartmentsJoinCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsJoinCreate>>,
    TError,
    { id: number; data: DepartmentModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsJoinCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove myself as a member
 */
export const departmentsLeaveDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/departments/${id}/leave/`, method: "DELETE" },
    options
  );
};

export const getDepartmentsLeaveDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof departmentsLeaveDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof departmentsLeaveDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return departmentsLeaveDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepartmentsLeaveDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof departmentsLeaveDestroy>>
>;

export type DepartmentsLeaveDestroyMutationError = ErrorType<unknown>;

export const useDepartmentsLeaveDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof departmentsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getDepartmentsLeaveDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List of Departments that I manage (distinct from my memberships).
 */
export const departmentsMeList = (
  params?: DepartmentsMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedDepartmentModelList>(
    { url: `/api/v1/departments/me/`, method: "GET", params, signal },
    options
  );
};

export const getDepartmentsMeListQueryKey = (
  params?: DepartmentsMeListParams
) => {
  return [`/api/v1/departments/me/`, ...(params ? [params] : [])] as const;
};

export const getDepartmentsMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof departmentsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDepartmentsMeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof departmentsMeList>>
  > = ({ signal }) => departmentsMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof departmentsMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DepartmentsMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof departmentsMeList>>
>;
export type DepartmentsMeListQueryError = ErrorType<unknown>;

export const useDepartmentsMeList = <
  TData = Awaited<ReturnType<typeof departmentsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDepartmentsMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List of Departments to which I belong (distinct from /me that I manage).
 */
export const departmentsMembershipsList = (
  params?: DepartmentsMembershipsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedDepartmentModelList>(
    { url: `/api/v1/departments/memberships/`, method: "GET", params, signal },
    options
  );
};

export const getDepartmentsMembershipsListQueryKey = (
  params?: DepartmentsMembershipsListParams
) => {
  return [
    `/api/v1/departments/memberships/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getDepartmentsMembershipsListQueryOptions = <
  TData = Awaited<ReturnType<typeof departmentsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDepartmentsMembershipsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof departmentsMembershipsList>>
  > = ({ signal }) =>
    departmentsMembershipsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof departmentsMembershipsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DepartmentsMembershipsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof departmentsMembershipsList>>
>;
export type DepartmentsMembershipsListQueryError = ErrorType<unknown>;

export const useDepartmentsMembershipsList = <
  TData = Awaited<ReturnType<typeof departmentsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: DepartmentsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof departmentsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDepartmentsMembershipsListQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
