import { useCallback, useMemo, useState } from "react";

import { queryClient } from "../query-client";
import {
  getChallengesLeaderboardListQueryKey,
  useChallengesLeaderboardList,
} from "../services/teambuilder/endpoints/challenges/challenges";
import { ChallengesLeaderboardListParams } from "../services/teambuilder/schemas";

const LIMIT = 20;

export const useChallengesLeaderboardListParams = (id: number) => {
  const [limit, setLimit] = useState(LIMIT);
  const challengesLeaderboardListParams: ChallengesLeaderboardListParams =
    useMemo(
      () => ({
        limit,
        offset: 0,
      }),
      [limit]
    );
  const challengesLeaderboardListQueryKey = useMemo(
    () =>
      getChallengesLeaderboardListQueryKey(id, challengesLeaderboardListParams),
    [id, challengesLeaderboardListParams]
  );

  const loadMore = useCallback(() => {
    setLimit((prev) => prev + LIMIT);
  }, [setLimit]);

  const refetch = useCallback(() => {
    queryClient.invalidateQueries(challengesLeaderboardListQueryKey);
  }, [challengesLeaderboardListQueryKey]);

  return {
    challengeId: id,
    challengesLeaderboardListParams,
    challengesLeaderboardListQueryKey,
    loadMore,
    refetch,
  };
};

export const useChallengesLeaderboard = (
  id: number,
  challengesLeaderboardListParams: ChallengesLeaderboardListParams
) => {
  /**
   *
   * Challenges Leaderboard request
   *
   */
  const {
    data: challengesLeaderboardListResponse,
    isLoading: isLoadingChallengesLeaderboardList,
  } = useChallengesLeaderboardList(id, challengesLeaderboardListParams, {
    query: {
      keepPreviousData: true,
    },
  });

  return {
    challengesLeaderboardListResponse,
    isLoadingChallengesLeaderboardList,
  };
};
