/**
 *
 *
 * useLocalStorageSettings
 *
 *
 */
import { useState } from "react";

import { ViewModeEnum } from "../utils/enums";

interface LocalStorageSettings {
  isFirstTime: boolean;
  viewModePeoplePage: ViewModeEnum;
}

const { VITE_LOCALSTORAGE_SETTINGS_KEY } = import.meta.env;

const defaultSettings: LocalStorageSettings = {
  isFirstTime: true,
  viewModePeoplePage: ViewModeEnum.VIEW_MODE_GRID,
};

export const useLocalStorageSettings = () => {
  const [settings, settingsSetter] = useState<LocalStorageSettings>(() => {
    const storedSettings = localStorage.getItem(VITE_LOCALSTORAGE_SETTINGS_KEY);

    if (storedSettings) {
      return JSON.parse(storedSettings);
    } else {
      localStorage.setItem(
        VITE_LOCALSTORAGE_SETTINGS_KEY,
        JSON.stringify(defaultSettings)
      );
      return defaultSettings;
    }
  });

  const setSettings = (settings: LocalStorageSettings) => {
    settingsSetter(settings);
    localStorage.setItem(
      VITE_LOCALSTORAGE_SETTINGS_KEY,
      JSON.stringify(settings)
    );
  };

  return {
    settings,
    setSettings,
  };
};
