/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * teambuilder API
 * Documentation of API teambuilder endpoints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { axiosInstance } from "../../axios-instance";
import type { ErrorType } from "../../axios-instance";
import type {
  BanterConfigModel,
  ChallengeConfig,
  CoffeeChatConfig,
  IcebreakersConfig,
  MemberCreateModel,
  OneOnOneConfig,
  PaginatedBanterConfigModelList,
  PaginatedChallengeConfigList,
  PaginatedCoffeeChatConfigList,
  PaginatedIcebreakersConfigList,
  PaginatedOneOnOneConfigList,
  PaginatedPulseConfigList,
  PaginatedPulseDefaultQuestionsList,
  PaginatedRecognitionList,
  PaginatedSimpleUserModelList,
  PaginatedSocialConfigList,
  PaginatedTeamModelList,
  PaginatedUserGuidesConfigList,
  PaginatedVideosConfigList,
  PatchedBanterConfigModelRequest,
  PatchedCoffeeChatConfigRequest,
  PatchedMemberCreateModelRequest,
  PatchedOneOnOneConfigRequest,
  PatchedPulseConfigRequest,
  PatchedTeamModelRequest,
  PatchedUserGuidesConfigRequest,
  PulseConfig,
  Recognition,
  SocialConfig,
  TeamModel,
  TeamModelRequest,
  TeamsBanterConfigListParams,
  TeamsChallengesConfigListParams,
  TeamsCoffeechatsConfigListParams,
  TeamsIcebreakersConfigListParams,
  TeamsListParams,
  TeamsMeListParams,
  TeamsMembersListParams,
  TeamsMembershipsListParams,
  TeamsOneononeConfigListParams,
  TeamsPulsesConfigListParams,
  TeamsPulsesConfigQuestionsListParams,
  TeamsRecognitionConfigListParams,
  TeamsSocialConfigListParams,
  TeamsUserguidesConfigListParams,
  TeamsVideosConfigListParams,
  UserGuidesConfig,
  VideosConfig,
} from "../../schemas";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * # Team

Everything needed to manage a team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsList = (
  params?: TeamsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTeamModelList>(
    { url: `/api/v1/teams/`, method: "GET", params, signal },
    options
  );
};

export const getTeamsListQueryKey = (params?: TeamsListParams) => {
  return [`/api/v1/teams/`, ...(params ? [params] : [])] as const;
};

export const getTeamsListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsList>>> = ({
    signal,
  }) => teamsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsList>>
>;
export type TeamsListQueryError = ErrorType<unknown>;

export const useTeamsList = <
  TData = Awaited<ReturnType<typeof teamsList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team

Everything needed to manage a team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsCreate = (
  teamModelRequest: TeamModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamModel>(
    {
      url: `/api/v1/teams/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: teamModelRequest,
    },
    options
  );
};

export const getTeamsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsCreate>>,
    TError,
    { data: TeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsCreate>>,
  TError,
  { data: TeamModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsCreate>>,
    { data: TeamModelRequest }
  > = (props) => {
    const { data } = props ?? {};

    return teamsCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsCreate>>
>;
export type TeamsCreateMutationBody = TeamModelRequest;
export type TeamsCreateMutationError = ErrorType<unknown>;

export const useTeamsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsCreate>>,
    TError,
    { data: TeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team

Everything needed to manage a team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsRetrieve = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TeamModel>(
    { url: `/api/v1/teams/${id}/`, method: "GET", signal },
    options
  );
};

export const getTeamsRetrieveQueryKey = (id: number) => {
  return [`/api/v1/teams/${id}/`] as const;
};

export const getTeamsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsRetrieve>>> = ({
    signal,
  }) => teamsRetrieve(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsRetrieve>>
>;
export type TeamsRetrieveQueryError = ErrorType<unknown>;

export const useTeamsRetrieve = <
  TData = Awaited<ReturnType<typeof teamsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team

Everything needed to manage a team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsPartialUpdate = (
  id: number,
  patchedTeamModelRequest: PatchedTeamModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamModel>(
    {
      url: `/api/v1/teams/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedTeamModelRequest,
    },
    options
  );
};

export const getTeamsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPartialUpdate>>,
    TError,
    { id: number; data: PatchedTeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsPartialUpdate>>,
  TError,
  { id: number; data: PatchedTeamModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsPartialUpdate>>,
    { id: number; data: PatchedTeamModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return teamsPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsPartialUpdate>>
>;
export type TeamsPartialUpdateMutationBody = PatchedTeamModelRequest;
export type TeamsPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPartialUpdate>>,
    TError,
    { id: number; data: PatchedTeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team

Everything needed to manage a team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/teams/${id}/`, method: "DELETE" },
    options
  );
};

export const getTeamsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return teamsDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsDestroy>>
>;

export type TeamsDestroyMutationError = ErrorType<unknown>;

export const useTeamsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add myself as a member if isOpen to new members
 */
export const teamsJoinCreate = (
  id: number,
  teamModelRequest: TeamModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<TeamModel>(
    {
      url: `/api/v1/teams/${id}/join/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: teamModelRequest,
    },
    options
  );
};

export const getTeamsJoinCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsJoinCreate>>,
    TError,
    { id: number; data: TeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsJoinCreate>>,
  TError,
  { id: number; data: TeamModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsJoinCreate>>,
    { id: number; data: TeamModelRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return teamsJoinCreate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsJoinCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsJoinCreate>>
>;
export type TeamsJoinCreateMutationBody = TeamModelRequest;
export type TeamsJoinCreateMutationError = ErrorType<unknown>;

export const useTeamsJoinCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsJoinCreate>>,
    TError,
    { id: number; data: TeamModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsJoinCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove myself as a member
 */
export const teamsLeaveDestroy = (
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/teams/${id}/leave/`, method: "DELETE" },
    options
  );
};

export const getTeamsLeaveDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsLeaveDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsLeaveDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return teamsLeaveDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsLeaveDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsLeaveDestroy>>
>;

export type TeamsLeaveDestroyMutationError = ErrorType<unknown>;

export const useTeamsLeaveDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsLeaveDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsLeaveDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team BanterConfig Configuration

Configuration settings for the banter app. There is one configuration object per team.

The days_of_week, hour, and minute control when the banter is sent. The hour is in 24 hour time.
This information is combined with team.timezone to make sure that banter fires at the correct time.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsBanterConfigList = (
  teamsPk: number,
  params?: TeamsBanterConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedBanterConfigModelList>(
    {
      url: `/api/v1/teams/${teamsPk}/banter/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsBanterConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsBanterConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/banter/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsBanterConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsBanterConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsBanterConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsBanterConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsBanterConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsBanterConfigList>>
  > = ({ signal }) =>
    teamsBanterConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsBanterConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsBanterConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsBanterConfigList>>
>;
export type TeamsBanterConfigListQueryError = ErrorType<unknown>;

export const useTeamsBanterConfigList = <
  TData = Awaited<ReturnType<typeof teamsBanterConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsBanterConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsBanterConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsBanterConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team BanterConfig Configuration

Configuration settings for the banter app. There is one configuration object per team.

The days_of_week, hour, and minute control when the banter is sent. The hour is in 24 hour time.
This information is combined with team.timezone to make sure that banter fires at the correct time.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsBanterConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BanterConfigModel>(
    {
      url: `/api/v1/teams/${teamsPk}/banter/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsBanterConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/banter/config/${id}/`] as const;
};

export const getTeamsBanterConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsBanterConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>
  > = ({ signal }) =>
    teamsBanterConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsBanterConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>
>;
export type TeamsBanterConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsBanterConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsBanterConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsBanterConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team BanterConfig Configuration

Configuration settings for the banter app. There is one configuration object per team.

The days_of_week, hour, and minute control when the banter is sent. The hour is in 24 hour time.
This information is combined with team.timezone to make sure that banter fires at the correct time.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsBanterConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedBanterConfigModelRequest: PatchedBanterConfigModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BanterConfigModel>(
    {
      url: `/api/v1/teams/${teamsPk}/banter/config/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedBanterConfigModelRequest,
    },
    options
  );
};

export const getTeamsBanterConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsBanterConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedBanterConfigModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsBanterConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedBanterConfigModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsBanterConfigPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedBanterConfigModelRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsBanterConfigPartialUpdate(teamsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsBanterConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsBanterConfigPartialUpdate>>
>;
export type TeamsBanterConfigPartialUpdateMutationBody =
  PatchedBanterConfigModelRequest;
export type TeamsBanterConfigPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsBanterConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsBanterConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedBanterConfigModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsBanterConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Challenge Configuration

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const teamsChallengesConfigList = (
  teamsPk: number,
  params?: TeamsChallengesConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedChallengeConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/challenges/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsChallengesConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsChallengesConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/challenges/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsChallengesConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsChallengesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsChallengesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsChallengesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsChallengesConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsChallengesConfigList>>
  > = ({ signal }) =>
    teamsChallengesConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsChallengesConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsChallengesConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsChallengesConfigList>>
>;
export type TeamsChallengesConfigListQueryError = ErrorType<unknown>;

export const useTeamsChallengesConfigList = <
  TData = Awaited<ReturnType<typeof teamsChallengesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsChallengesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsChallengesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsChallengesConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Challenge Configuration

## Permissions
```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]

```
 */
export const teamsChallengesConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ChallengeConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/challenges/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsChallengesConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/challenges/config/${id}/`] as const;
};

export const getTeamsChallengesConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsChallengesConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>
  > = ({ signal }) =>
    teamsChallengesConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsChallengesConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>
>;
export type TeamsChallengesConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsChallengesConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsChallengesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsChallengesConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat Configuration

Configuration settings for the CoffeeChat app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsCoffeechatsConfigList = (
  teamsPk: number,
  params?: TeamsCoffeechatsConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedCoffeeChatConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/coffeechats/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsCoffeechatsConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsCoffeechatsConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/coffeechats/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsCoffeechatsConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsCoffeechatsConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsCoffeechatsConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>
  > = ({ signal }) =>
    teamsCoffeechatsConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsCoffeechatsConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>
>;
export type TeamsCoffeechatsConfigListQueryError = ErrorType<unknown>;

export const useTeamsCoffeechatsConfigList = <
  TData = Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsCoffeechatsConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsCoffeechatsConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsCoffeechatsConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat Configuration

Configuration settings for the CoffeeChat app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsCoffeechatsConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CoffeeChatConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/coffeechats/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsCoffeechatsConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/coffeechats/config/${id}/`] as const;
};

export const getTeamsCoffeechatsConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsCoffeechatsConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>
  > = ({ signal }) =>
    teamsCoffeechatsConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsCoffeechatsConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>
>;
export type TeamsCoffeechatsConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsCoffeechatsConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsCoffeechatsConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsCoffeechatsConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # CoffeeChat Configuration

Configuration settings for the CoffeeChat app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsCoffeechatsConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedCoffeeChatConfigRequest: PatchedCoffeeChatConfigRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CoffeeChatConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/coffeechats/config/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedCoffeeChatConfigRequest,
    },
    options
  );
};

export const getTeamsCoffeechatsConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedCoffeeChatConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsCoffeechatsConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedCoffeeChatConfigRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedCoffeeChatConfigRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsCoffeechatsConfigPartialUpdate(
      teamsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsCoffeechatsConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsCoffeechatsConfigPartialUpdate>>
>;
export type TeamsCoffeechatsConfigPartialUpdateMutationBody =
  PatchedCoffeeChatConfigRequest;
export type TeamsCoffeechatsConfigPartialUpdateMutationError =
  ErrorType<unknown>;

export const useTeamsCoffeechatsConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsCoffeechatsConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedCoffeeChatConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsCoffeechatsConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # IcebreakerConfig Configuration

Configuration settings for the IceBreakers app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsIcebreakersConfigList = (
  teamsPk: number,
  params?: TeamsIcebreakersConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedIcebreakersConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/icebreakers/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsIcebreakersConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsIcebreakersConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/icebreakers/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsIcebreakersConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsIcebreakersConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsIcebreakersConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsIcebreakersConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsIcebreakersConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsIcebreakersConfigList>>
  > = ({ signal }) =>
    teamsIcebreakersConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsIcebreakersConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsIcebreakersConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsIcebreakersConfigList>>
>;
export type TeamsIcebreakersConfigListQueryError = ErrorType<unknown>;

export const useTeamsIcebreakersConfigList = <
  TData = Awaited<ReturnType<typeof teamsIcebreakersConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsIcebreakersConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsIcebreakersConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsIcebreakersConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # IcebreakerConfig Configuration

Configuration settings for the IceBreakers app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsIcebreakersConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<IcebreakersConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/icebreakers/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsIcebreakersConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/icebreakers/config/${id}/`] as const;
};

export const getTeamsIcebreakersConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsIcebreakersConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>
  > = ({ signal }) =>
    teamsIcebreakersConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsIcebreakersConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>
>;
export type TeamsIcebreakersConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsIcebreakersConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsIcebreakersConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsIcebreakersConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # IcebreakerConfig Configuration

Configuration settings for the IceBreakers app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsIcebreakersConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<IcebreakersConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/icebreakers/config/${id}/`,
      method: "PATCH",
    },
    options
  );
};

export const getTeamsIcebreakersConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsIcebreakersConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsIcebreakersConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsIcebreakersConfigPartialUpdate>>,
    { teamsPk: number; id: number }
  > = (props) => {
    const { teamsPk, id } = props ?? {};

    return teamsIcebreakersConfigPartialUpdate(teamsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsIcebreakersConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsIcebreakersConfigPartialUpdate>>
>;

export type TeamsIcebreakersConfigPartialUpdateMutationError =
  ErrorType<unknown>;

export const useTeamsIcebreakersConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsIcebreakersConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsIcebreakersConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Members

List all the members of the specified team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsMembersList = (
  teamsPk: number,
  params?: TeamsMembersListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedSimpleUserModelList>(
    { url: `/api/v1/teams/${teamsPk}/members/`, method: "GET", params, signal },
    options
  );
};

export const getTeamsMembersListQueryKey = (
  teamsPk: number,
  params?: TeamsMembersListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/members/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsMembersList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsMembersListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsMembersList>>
  > = ({ signal }) => teamsMembersList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsMembersList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsMembersList>>
>;
export type TeamsMembersListQueryError = ErrorType<unknown>;

export const useTeamsMembersList = <
  TData = Awaited<ReturnType<typeof teamsMembersList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsMembersListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMembersList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsMembersListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Members

List all the members of the specified team.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "Manager"]
```
 */
export const teamsMembersPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedMemberCreateModelRequest: PatchedMemberCreateModelRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<MemberCreateModel>(
    {
      url: `/api/v1/teams/${teamsPk}/members/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedMemberCreateModelRequest,
    },
    options
  );
};

export const getTeamsMembersPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsMembersPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedMemberCreateModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsMembersPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedMemberCreateModelRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsMembersPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedMemberCreateModelRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsMembersPartialUpdate(teamsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsMembersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsMembersPartialUpdate>>
>;
export type TeamsMembersPartialUpdateMutationBody =
  PatchedMemberCreateModelRequest;
export type TeamsMembersPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsMembersPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsMembersPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedMemberCreateModelRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsMembersPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove a membership.
 */
export const teamsMembersDestroy = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/v1/teams/${teamsPk}/members/${id}/`, method: "DELETE" },
    options
  );
};

export const getTeamsMembersDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsMembersDestroy>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsMembersDestroy>>,
  TError,
  { teamsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsMembersDestroy>>,
    { teamsPk: number; id: number }
  > = (props) => {
    const { teamsPk, id } = props ?? {};

    return teamsMembersDestroy(teamsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsMembersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsMembersDestroy>>
>;

export type TeamsMembersDestroyMutationError = ErrorType<unknown>;

export const useTeamsMembersDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsMembersDestroy>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions = getTeamsMembersDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # OneOnOne Configuration

Configuration settings for the OneOnOne app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsOneononeConfigList = (
  teamsPk: number,
  params?: TeamsOneononeConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedOneOnOneConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/oneonone/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsOneononeConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsOneononeConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/oneonone/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsOneononeConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsOneononeConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsOneononeConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsOneononeConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsOneononeConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsOneononeConfigList>>
  > = ({ signal }) =>
    teamsOneononeConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsOneononeConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsOneononeConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsOneononeConfigList>>
>;
export type TeamsOneononeConfigListQueryError = ErrorType<unknown>;

export const useTeamsOneononeConfigList = <
  TData = Awaited<ReturnType<typeof teamsOneononeConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsOneononeConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsOneononeConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsOneononeConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # OneOnOne Configuration

Configuration settings for the OneOnOne app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsOneononeConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<OneOnOneConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/oneonone/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsOneononeConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/oneonone/config/${id}/`] as const;
};

export const getTeamsOneononeConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsOneononeConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>
  > = ({ signal }) =>
    teamsOneononeConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsOneononeConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>
>;
export type TeamsOneononeConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsOneononeConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsOneononeConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsOneononeConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # OneOnOne Configuration

Configuration settings for the OneOnOne app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsOneononeConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedOneOnOneConfigRequest: PatchedOneOnOneConfigRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<OneOnOneConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/oneonone/config/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedOneOnOneConfigRequest,
    },
    options
  );
};

export const getTeamsOneononeConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsOneononeConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedOneOnOneConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsOneononeConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedOneOnOneConfigRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsOneononeConfigPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedOneOnOneConfigRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsOneononeConfigPartialUpdate(teamsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsOneononeConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsOneononeConfigPartialUpdate>>
>;
export type TeamsOneononeConfigPartialUpdateMutationBody =
  PatchedOneOnOneConfigRequest;
export type TeamsOneononeConfigPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsOneononeConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsOneononeConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedOneOnOneConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsOneononeConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Pulse Configuration

Configuration settings for the Pulse app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsPulsesConfigList = (
  teamsPk: number,
  params?: TeamsPulsesConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPulseConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/pulses/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsPulsesConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsPulsesConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/pulses/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsPulsesConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsPulsesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsPulsesConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsPulsesConfigList>>
  > = ({ signal }) =>
    teamsPulsesConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsPulsesConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsPulsesConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsPulsesConfigList>>
>;
export type TeamsPulsesConfigListQueryError = ErrorType<unknown>;

export const useTeamsPulsesConfigList = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsPulsesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsPulsesConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Pulse Configuration

Configuration settings for the Pulse app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsPulsesConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PulseConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/pulses/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsPulsesConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/pulses/config/${id}/`] as const;
};

export const getTeamsPulsesConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsPulsesConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>
  > = ({ signal }) =>
    teamsPulsesConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsPulsesConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>
>;
export type TeamsPulsesConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsPulsesConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsPulsesConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Pulse Configuration

Configuration settings for the Pulse app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsPulsesConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedPulseConfigRequest: PatchedPulseConfigRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<PulseConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/pulses/config/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedPulseConfigRequest,
    },
    options
  );
};

export const getTeamsPulsesConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPulsesConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedPulseConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsPulsesConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedPulseConfigRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsPulsesConfigPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedPulseConfigRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsPulsesConfigPartialUpdate(teamsPk, id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsPulsesConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsPulsesConfigPartialUpdate>>
>;
export type TeamsPulsesConfigPartialUpdateMutationBody =
  PatchedPulseConfigRequest;
export type TeamsPulsesConfigPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsPulsesConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsPulsesConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedPulseConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsPulsesConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List of pulse available questions
 */
export const teamsPulsesConfigQuestionsList = (
  teamsPk: number,
  params?: TeamsPulsesConfigQuestionsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedPulseDefaultQuestionsList>(
    {
      url: `/api/v1/teams/${teamsPk}/pulses/config/questions/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsPulsesConfigQuestionsListQueryKey = (
  teamsPk: number,
  params?: TeamsPulsesConfigQuestionsListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/pulses/config/questions/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsPulsesConfigQuestionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsPulsesConfigQuestionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsPulsesConfigQuestionsListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>
  > = ({ signal }) =>
    teamsPulsesConfigQuestionsList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsPulsesConfigQuestionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>
>;
export type TeamsPulsesConfigQuestionsListQueryError = ErrorType<unknown>;

export const useTeamsPulsesConfigQuestionsList = <
  TData = Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsPulsesConfigQuestionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsPulsesConfigQuestionsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsPulsesConfigQuestionsListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Recognition Configuration

Configuration settings for the shoutout app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsRecognitionConfigList = (
  teamsPk: number,
  params?: TeamsRecognitionConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedRecognitionList>(
    {
      url: `/api/v1/teams/${teamsPk}/recognition/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsRecognitionConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsRecognitionConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/recognition/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsRecognitionConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsRecognitionConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsRecognitionConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRecognitionConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsRecognitionConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsRecognitionConfigList>>
  > = ({ signal }) =>
    teamsRecognitionConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsRecognitionConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsRecognitionConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsRecognitionConfigList>>
>;
export type TeamsRecognitionConfigListQueryError = ErrorType<unknown>;

export const useTeamsRecognitionConfigList = <
  TData = Awaited<ReturnType<typeof teamsRecognitionConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsRecognitionConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRecognitionConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsRecognitionConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Recognition Configuration

Configuration settings for the shoutout app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsRecognitionConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<Recognition>(
    {
      url: `/api/v1/teams/${teamsPk}/recognition/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsRecognitionConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/recognition/config/${id}/`] as const;
};

export const getTeamsRecognitionConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsRecognitionConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>
  > = ({ signal }) =>
    teamsRecognitionConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsRecognitionConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>
>;
export type TeamsRecognitionConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsRecognitionConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsRecognitionConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsRecognitionConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Recognition Configuration

Configuration settings for the shoutout app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsRecognitionConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<Recognition>(
    {
      url: `/api/v1/teams/${teamsPk}/recognition/config/${id}/`,
      method: "PATCH",
    },
    options
  );
};

export const getTeamsRecognitionConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsRecognitionConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsRecognitionConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsRecognitionConfigPartialUpdate>>,
    { teamsPk: number; id: number }
  > = (props) => {
    const { teamsPk, id } = props ?? {};

    return teamsRecognitionConfigPartialUpdate(teamsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsRecognitionConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsRecognitionConfigPartialUpdate>>
>;

export type TeamsRecognitionConfigPartialUpdateMutationError =
  ErrorType<unknown>;

export const useTeamsRecognitionConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsRecognitionConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsRecognitionConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Team Social Configuration

Configuration settings for the social app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsSocialConfigList = (
  teamsPk: number,
  params?: TeamsSocialConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedSocialConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/social/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsSocialConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsSocialConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/social/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsSocialConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsSocialConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsSocialConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsSocialConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsSocialConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsSocialConfigList>>
  > = ({ signal }) =>
    teamsSocialConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsSocialConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsSocialConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsSocialConfigList>>
>;
export type TeamsSocialConfigListQueryError = ErrorType<unknown>;

export const useTeamsSocialConfigList = <
  TData = Awaited<ReturnType<typeof teamsSocialConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsSocialConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsSocialConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsSocialConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Social Configuration

Configuration settings for the social app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsSocialConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SocialConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/social/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsSocialConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/social/config/${id}/`] as const;
};

export const getTeamsSocialConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsSocialConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>
  > = ({ signal }) =>
    teamsSocialConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsSocialConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>
>;
export type TeamsSocialConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsSocialConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsSocialConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsSocialConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Team Social Configuration

Configuration settings for the social app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsSocialConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<SocialConfig>(
    { url: `/api/v1/teams/${teamsPk}/social/config/${id}/`, method: "PATCH" },
    options
  );
};

export const getTeamsSocialConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsSocialConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsSocialConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsSocialConfigPartialUpdate>>,
    { teamsPk: number; id: number }
  > = (props) => {
    const { teamsPk, id } = props ?? {};

    return teamsSocialConfigPartialUpdate(teamsPk, id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsSocialConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsSocialConfigPartialUpdate>>
>;

export type TeamsSocialConfigPartialUpdateMutationError = ErrorType<unknown>;

export const useTeamsSocialConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsSocialConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsSocialConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # UserGuidesConfig Configuration

Configuration settings for the UserGuides app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsUserguidesConfigList = (
  teamsPk: number,
  params?: TeamsUserguidesConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedUserGuidesConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/userguides/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsUserguidesConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsUserguidesConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/userguides/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsUserguidesConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsUserguidesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsUserguidesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsUserguidesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsUserguidesConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsUserguidesConfigList>>
  > = ({ signal }) =>
    teamsUserguidesConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsUserguidesConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsUserguidesConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsUserguidesConfigList>>
>;
export type TeamsUserguidesConfigListQueryError = ErrorType<unknown>;

export const useTeamsUserguidesConfigList = <
  TData = Awaited<ReturnType<typeof teamsUserguidesConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsUserguidesConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsUserguidesConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsUserguidesConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # UserGuidesConfig Configuration

Configuration settings for the UserGuides app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsUserguidesConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<UserGuidesConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/userguides/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsUserguidesConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/userguides/config/${id}/`] as const;
};

export const getTeamsUserguidesConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTeamsUserguidesConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>
  > = ({ signal }) =>
    teamsUserguidesConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsUserguidesConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>
>;
export type TeamsUserguidesConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsUserguidesConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsUserguidesConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsUserguidesConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # UserGuidesConfig Configuration

Configuration settings for the UserGuides app. There is one configuration object per team.

## Permissions

```
permission_classes = [lambda: iperm.OrPermission(
    # Not SiteWidePermissions, because not everyone can read these preferences
    iperm.ObjectOwnerPermissions, iperm.HasRolePermission, iperm.IsAdminUser,
)]
required_roles = ["HR"]
```
 */
export const teamsUserguidesConfigPartialUpdate = (
  teamsPk: number,
  id: number,
  patchedUserGuidesConfigRequest: PatchedUserGuidesConfigRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<UserGuidesConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/userguides/config/${id}/`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchedUserGuidesConfigRequest,
    },
    options
  );
};

export const getTeamsUserguidesConfigPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsUserguidesConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedUserGuidesConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamsUserguidesConfigPartialUpdate>>,
  TError,
  { teamsPk: number; id: number; data: PatchedUserGuidesConfigRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamsUserguidesConfigPartialUpdate>>,
    { teamsPk: number; id: number; data: PatchedUserGuidesConfigRequest }
  > = (props) => {
    const { teamsPk, id, data } = props ?? {};

    return teamsUserguidesConfigPartialUpdate(
      teamsPk,
      id,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type TeamsUserguidesConfigPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamsUserguidesConfigPartialUpdate>>
>;
export type TeamsUserguidesConfigPartialUpdateMutationBody =
  PatchedUserGuidesConfigRequest;
export type TeamsUserguidesConfigPartialUpdateMutationError =
  ErrorType<unknown>;

export const useTeamsUserguidesConfigPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamsUserguidesConfigPartialUpdate>>,
    TError,
    { teamsPk: number; id: number; data: PatchedUserGuidesConfigRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationOptions =
    getTeamsUserguidesConfigPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * # Video Config

Configuration settings for the video app. There is one configuration per company.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const teamsVideosConfigList = (
  teamsPk: number,
  params?: TeamsVideosConfigListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedVideosConfigList>(
    {
      url: `/api/v1/teams/${teamsPk}/videos/config/`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getTeamsVideosConfigListQueryKey = (
  teamsPk: number,
  params?: TeamsVideosConfigListParams
) => {
  return [
    `/api/v1/teams/${teamsPk}/videos/config/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getTeamsVideosConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsVideosConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsVideosConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsVideosConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsVideosConfigListQueryKey(teamsPk, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsVideosConfigList>>
  > = ({ signal }) =>
    teamsVideosConfigList(teamsPk, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!teamsPk,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsVideosConfigList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsVideosConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsVideosConfigList>>
>;
export type TeamsVideosConfigListQueryError = ErrorType<unknown>;

export const useTeamsVideosConfigList = <
  TData = Awaited<ReturnType<typeof teamsVideosConfigList>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  params?: TeamsVideosConfigListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsVideosConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsVideosConfigListQueryOptions(
    teamsPk,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * # Video Config

Configuration settings for the video app. There is one configuration per company.

## Permissions

```
permission_classes = [lambda: iperm.SiteWidePermissions]
required_roles = ["HR", "IT"]
```
 */
export const teamsVideosConfigRetrieve = (
  teamsPk: number,
  id: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<VideosConfig>(
    {
      url: `/api/v1/teams/${teamsPk}/videos/config/${id}/`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getTeamsVideosConfigRetrieveQueryKey = (
  teamsPk: number,
  id: number
) => {
  return [`/api/v1/teams/${teamsPk}/videos/config/${id}/`] as const;
};

export const getTeamsVideosConfigRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsVideosConfigRetrieveQueryKey(teamsPk, id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>
  > = ({ signal }) =>
    teamsVideosConfigRetrieve(teamsPk, id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(teamsPk && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsVideosConfigRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>
>;
export type TeamsVideosConfigRetrieveQueryError = ErrorType<unknown>;

export const useTeamsVideosConfigRetrieve = <
  TData = Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>,
  TError = ErrorType<unknown>,
>(
  teamsPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsVideosConfigRetrieve>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsVideosConfigRetrieveQueryOptions(
    teamsPk,
    id,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List of Teams that I manage (distinct from my memberships).
 */
export const teamsMeList = (
  params?: TeamsMeListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTeamModelList>(
    { url: `/api/v1/teams/me/`, method: "GET", params, signal },
    options
  );
};

export const getTeamsMeListQueryKey = (params?: TeamsMeListParams) => {
  return [`/api/v1/teams/me/`, ...(params ? [params] : [])] as const;
};

export const getTeamsMeListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeamsMeListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof teamsMeList>>> = ({
    signal,
  }) => teamsMeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsMeList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsMeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsMeList>>
>;
export type TeamsMeListQueryError = ErrorType<unknown>;

export const useTeamsMeList = <
  TData = Awaited<ReturnType<typeof teamsMeList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsMeListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsMeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List of Teams to which I belong (distinct from /me that I manage).
 */
export const teamsMembershipsList = (
  params?: TeamsMembershipsListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PaginatedTeamModelList>(
    { url: `/api/v1/teams/memberships/`, method: "GET", params, signal },
    options
  );
};

export const getTeamsMembershipsListQueryKey = (
  params?: TeamsMembershipsListParams
) => {
  return [`/api/v1/teams/memberships/`, ...(params ? [params] : [])] as const;
};

export const getTeamsMembershipsListQueryOptions = <
  TData = Awaited<ReturnType<typeof teamsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeamsMembershipsListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teamsMembershipsList>>
  > = ({ signal }) => teamsMembershipsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof teamsMembershipsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TeamsMembershipsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof teamsMembershipsList>>
>;
export type TeamsMembershipsListQueryError = ErrorType<unknown>;

export const useTeamsMembershipsList = <
  TData = Awaited<ReturnType<typeof teamsMembershipsList>>,
  TError = ErrorType<unknown>,
>(
  params?: TeamsMembershipsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teamsMembershipsList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTeamsMembershipsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
