/**
 *
 *
 * usePeopleForm
 *
 *
 */
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useToast } from "../../hooks/useToast";
import { useDepartmentsList } from "../../services/teambuilder/endpoints/departments/departments";
import { useIntuitionUsersRolesList } from "../../services/teambuilder/endpoints/intuition/intuition";
import { useLocationsList } from "../../services/teambuilder/endpoints/locations/locations";
import {
  usePeopleInvitationsCreate,
  usePeoplePartialUpdate,
  usePeopleRetrieve,
} from "../../services/teambuilder/endpoints/people/people";
import {
  LocationsListParams,
  PeopleRetrieve,
} from "../../services/teambuilder/schemas";
import { isDomainLike, isEmail } from "../../utils/validation";

const schema = z.object({
  firstName: z
    .string()
    .min(1, { message: "translation:validation:required_first_name" })
    .max(250, { message: "translation:validation:less_than_250" })
    .refine(
      (name) => {
        // Firstname should not be an email or a domain
        return !isEmail(name) && !isDomainLike(name);
      },
      {
        message: "translation:validation:invalid_firstname",
      }
    ),
  lastName: z
    .string()
    .min(1, { message: "translation:validation:required_last_name" })
    .max(250, { message: "translation:validation:less_than_250" })
    .refine(
      (name) => {
        // Lastname should not be an email or a domain
        return !isEmail(name) && !isDomainLike(name);
      },
      {
        message: "translation:validation:invalid_lastname",
      }
    ),
  email: z
    .string()
    .min(5, { message: "translation:validation:required_email" })
    .email({ message: "translation:validation:invalid_email" }),
  jobTitle: z
    .string()
    .max(250, { message: "translation:validation:less_than_250" })
    .optional(),
  locations: z.number().array(),
  departments: z.number().array(),
  // roles: z.number().array(),
  isHR: z.boolean().optional(),
  inviteViaEmail: z.boolean().optional(),
});

export type FormData = z.infer<typeof schema>;

interface Props {
  onSuccess: () => void;
}

export const usePeopleForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.has("people_form");
  const userId = Number(searchParams.get("people_form"));
  const { openToast } = useToast();
  const getId = (x: { id?: number }) => x.id as number;

  const mapPerson = (person?: PeopleRetrieve): FormData => ({
    firstName: person?.firstName || "",
    lastName: person?.lastName || "",
    email: person?.email || "",
    jobTitle: person?.jobTitle || "",
    locations: person?.locations
      ? person.locations.map((item) => Number(item.id))
      : [],
    departments: person?.departments
      ? person.departments.map((item) => Number(item.id))
      : [],
    // roles: person?.roles.map(getId) || [],
    isHR: Boolean(person?.roles.find((role) => role.name === "HR")),
    inviteViaEmail: true,
  });

  const form = useForm<FormData>({
    defaultValues: mapPerson(),
    resolver: zodResolver(schema),
  });

  const close = () => {
    searchParams.delete("people_form");
    setSearchParams(searchParams);
    form.reset(mapPerson());
  };

  const { data: person, isFetching: isLoadingPerson } = usePeopleRetrieve(
    userId || 0,
    {
      query: {
        enabled: Boolean(userId),
        staleTime: 0,
        select: (data) => data,
      },
    }
  );

  useEffect(() => {
    form.reset(mapPerson(person));
  }, [form, person]);

  const { mutate: createUser, isLoading: isLoadingCreateUser } =
    usePeopleInvitationsCreate({
      mutation: {
        onSuccess: () => {
          close();
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:person_add_success"),
          });
          form.reset(mapPerson());
          onSuccess();
        },
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:person_add_failed"),
            type: "danger",
          });
        },
      },
    });

  const { mutate: updateUser, isLoading: isLoadingUpdateUser } =
    usePeoplePartialUpdate({
      mutation: {
        onSuccess: () => {
          close();
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:person_update_success"),
          });
          form.reset(mapPerson());
          onSuccess();
        },
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:update_not_saved"),
            type: "danger",
          });
        },
      },
    });

  /**
   *  Fetch user roles, departments, locations
   * */
  const selectParams: LocationsListParams = {
    ordering: "name",
  };
  const { data: roles, isLoading: isRolesLoading } = useIntuitionUsersRolesList(
    selectParams,
    {
      query: {
        select: ({ data }) => data,
        onError: () => {
          openToast({
            title: t("translation:common:success"),
            description: t("translation:toast:user_role_load_failed"),
            type: "danger",
          });
        },
      },
    }
  );
  const hrRole = useMemo(() => {
    return roles?.find((role) => role.name === "HR");
  }, [roles]);

  const { data: allLocations, isLoading: isLocationsLoading } =
    useLocationsList(selectParams, {
      query: {
        select: ({ data }) => data,
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:location_load_failed"),
            type: "danger",
          });
        },
      },
    });

  const { data: allDepartments, isLoading: isDepartmentsLoading } =
    useDepartmentsList(selectParams, {
      query: {
        select: ({ data }) => data,
        onError: () => {
          openToast({
            title: t("translation:common:error"),
            description: t("translation:toast:department_load_failed"),
            type: "danger",
          });
        },
      },
    });

  const isLoading =
    isLoadingPerson ||
    isRolesLoading ||
    isLocationsLoading ||
    isDepartmentsLoading;

  /**
   *
   * handleSubmit for create & edit
   *
   */
  const onSubmit: SubmitHandler<FormData> = (formData) => {
    const hrId = hrRole?.id as number;
    // Update
    if (userId && person) {
      // inviteViaEmail is not a valid field for update
      delete formData.inviteViaEmail;
      const currentRoleIds = person.roles.map(getId);
      const roles: number[] = formData.isHR
        ? currentRoleIds.includes(hrId)
          ? currentRoleIds
          : [...currentRoleIds, hrId]
        : currentRoleIds.filter((id) => id !== hrId);
      delete formData.isHR;
      updateUser({ id: Number(person?.id), data: { ...formData, roles } });
    } else {
      // Create
      const roles: number[] = formData.isHR ? [hrId] : [];
      delete formData.isHR;
      createUser({
        data: {
          ...formData,
          roles,
        },
      });
    }
  };

  const onHandleSubmit = form.handleSubmit(onSubmit);

  return {
    isUpdate: userId,
    person,
    isLoadingCreateUser,
    isLoadingUpdateUser,
    isOpen,
    isLoading,
    emptyPerson: mapPerson(),
    allLocations,
    allDepartments,
    close,
    form,
    onHandleSubmit,
    onSuccess,
  };
};
