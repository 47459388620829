/**
 *
 *
 * MenuButtonContent
 *
 *
 */
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";

import { Avatar } from "../../components/AvatarV3";
import { PeopleMe } from "../../services/teambuilder/schemas";

interface Props {
  open?: boolean;
  isCollapsed?: boolean;
  me?: PeopleMe;
  isLoadingMe: boolean;
}

export const MenuButtonContent = (props: Props) => (
  <>
    <span className="sr-only">Open user menu</span>

    <div className="flex-shrink-0">
      <Avatar user={props.me} loading={props.isLoadingMe} size="md" />
    </div>

    {!props.isCollapsed && (
      <div className="flex-auto">
        {props.isLoadingMe ? (
          <div className="animate-pulse">
            <div className="mb-1 h-4 w-20 rounded bg-zinc-300" />
            <div className="h-3.5 w-20 rounded bg-zinc-300" />
          </div>
        ) : (
          <div className="text-left text-sm">
            <div className={clsx("font-medium", "text-zinc-900")}>
              {props.me?.firstName} {props.me?.lastName}
            </div>
            <div className="text-zinc-500">{props.me?.jobTitle}</div>
          </div>
        )}
      </div>
    )}

    <ChevronDownIcon
      className={clsx(
        "flex-shrink-0 text-zinc-300 group-hover/nav-item:text-zinc-500",
        props.isCollapsed ? "h-8 w-8" : "h-5 w-5",
        props.open && "text-zinc-500"
      )}
    />
  </>
);
