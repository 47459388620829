/**
 *
 *
 * <ChallengeActivityListItem />
 *
 *
 */
import {
  ChevronRightIcon,
  LinkIcon,
  PhotoIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowTopRightOnSquareIcon,
  Bars2Icon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleBottomCenterTextIcon,
  MegaphoneIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { DragControls } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DropdownMenu } from "../../components/DropdownMenuV3";
import { ChallengeConfirmationWithProofModal } from "../../containers/ChallengeConfirmationWithProofModal";
import { usePostModalContent } from "../../hooks/usePostModalContent";
import {
  Activity,
  ActivityProofType,
  PostWriteRequest,
} from "../../services/teambuilder/schemas";
import { Wrapper } from "./Wrapper";

interface Props {
  activity: Partial<Activity>;
  // Template activities cannot be completed
  onComplete?: (data?: PostWriteRequest) => void;
  isTemplate?: boolean;
  index: number;
  delay?: number;
  isRunning?: boolean;
  isCompleted?: boolean;
  isComment?: boolean;
  dragControls?: DragControls;
  isDraft?: boolean;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export const ChallengeActivityListItem = (props: Props) => {
  const { t } = useTranslation();
  const borderColor = props.isCompleted
    ? "border-zinc-600"
    : props.activity.pointsType === "MANUAL"
      ? props.activity.proofType === ActivityProofType.link
        ? "border-blue-950"
        : props.activity.proofType === ActivityProofType.image
          ? "border-green-950"
          : props.activity.proofType === ActivityProofType.text
            ? "border-amber-700"
            : props.activity.proofType === ActivityProofType.shoutout
              ? "border-fuchsia-950"
              : "border-challenge"
      : "border-challenge";

  const badgeColor = props.isCompleted
    ? "bg-zinc-600"
    : props.activity.pointsType === "MANUAL"
      ? props.activity.proofType === ActivityProofType.link
        ? "bg-blue-950"
        : props.activity.proofType === ActivityProofType.image
          ? "bg-green-950"
          : props.activity.proofType === ActivityProofType.text
            ? "bg-amber-700"
            : props.activity.proofType === ActivityProofType.shoutout
              ? "bg-fuchsia-950"
              : "bg-challenge"
      : "bg-challenge";

  const textColor =
    props.activity.pointsType === "MANUAL"
      ? props.activity.proofType === ActivityProofType.link
        ? "text-blue-950"
        : props.activity.proofType === ActivityProofType.image
          ? "text-green-950"
          : props.activity.proofType === ActivityProofType.text
            ? "text-amber-700"
            : props.activity.proofType === ActivityProofType.shoutout
              ? "text-fuchsia-950"
              : "text-challenge"
      : "text-challenge";

  const bgColor = props.isCompleted
    ? "bg-zinc-600"
    : props.activity.pointsType === "MANUAL"
      ? props.activity.proofType === ActivityProofType.link
        ? "bg-blue-950"
        : props.activity.proofType === ActivityProofType.image
          ? "bg-green-950"
          : props.activity.proofType === ActivityProofType.text
            ? "bg-amber-700"
            : props.activity.proofType === ActivityProofType.shoutout
              ? "bg-fuchsia-950"
              : "bg-challenge"
      : "bg-challenge";

  const Icon =
    props.activity.proofType === ActivityProofType.link
      ? LinkIcon
      : props.activity.proofType === ActivityProofType.image
        ? PhotoIcon
        : props.activity.proofType === ActivityProofType.text
          ? ChatBubbleBottomCenterTextIcon
          : props.activity.proofType === ActivityProofType.shoutout
            ? MegaphoneIcon
            : null;

  const isSystemActivity =
    props.activity.pointsType === "SYSTEM" && props.activity.linkUrl;
  const [isConfirmation, setIsConfirmation] = useState(false);
  const { reset } = usePostModalContent();

  return (
    <>
      <Wrapper
        index={props.index}
        delay={props.delay || 0}
        isTemplate={props.isTemplate}
        isSystemActivity={!!isSystemActivity}
        activity={props.activity}
        onClick={
          props.isRunning && !props.activity.isCompleted
            ? () => {
                setIsConfirmation(true);
                reset();
              }
            : undefined
        }
        borderColor={borderColor}
        badgeColor={badgeColor}
        isRunning={props.isRunning}
        isCompleted={props.isCompleted}
        isComment={props.isComment}
        isDragable={!!props.dragControls}
      >
        {props.dragControls && (
          <Bars2Icon
            className="h-5 w-5 flex-shrink-0 text-zinc-500"
            onPointerDown={(e) => props.dragControls?.start(e)}
          />
        )}
        <div
          className={clsx(
            "h-10 w-10 flex-shrink-0 rounded-full",
            "flex items-center justify-center",
            bgColor
          )}
        >
          {Icon ? (
            <Icon className="h-6 w-6 text-white" />
          ) : (
            <span className="h-5 w-5 rounded-full border-2 border-white" />
          )}
        </div>
        <div className="flex-grow text-left text-sm font-normal leading-5 text-zinc-900">
          <p className="select-none">{props.activity.name}</p>
          {props.activity.linkUrl && !props.isDraft && (
            <a
              href={props.activity.linkUrl as string}
              target="_blank"
              rel="noreferrer"
              className="flex w-fit items-center gap-x-1 text-sm font-semibold text-blue-950 underline"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="select-none leading-5">
                {t("translation:post:link")}
              </span>
              <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </a>
          )}
        </div>

        {!props.isTemplate &&
          props.isRunning &&
          !props.activity.isCompleted &&
          (isSystemActivity ? null : (
            <ChevronRightIcon
              className={clsx("h-5 w-5 flex-shrink-0", textColor)}
            />
          ))}
        {props.isDraft && (
          <DropdownMenu
            items={[
              {
                name: t("translation:common:edit"),
                icon: PencilSquareIcon,
                onClick: props.onEditClick,
              },
              {
                name: t("translation:common:delete"),
                icon: TrashIcon,
                onClick: props.onDeleteClick,
              },
            ]}
            menuButtonContent={(open) => (
              <EllipsisHorizontalIcon
                className={clsx(
                  "h-5 w-5 rounded-md hover:bg-zinc-200 hover:text-zinc-600",
                  open ? "bg-zinc-200 text-zinc-600" : "text-zinc-600"
                )}
              />
            )}
            menuClassNames={clsx("right-0 mt-5 origin-top-right")}
          />
        )}
      </Wrapper>
      <ChallengeConfirmationWithProofModal
        activity={props.activity}
        isOpen={isConfirmation}
        title={t("translation:challenge:complete_action")}
        cancelButtonTitle={t("translation:common:cancel")}
        confirmButtonTitle={t("translation:challenge:complete")}
        onCancel={() => setIsConfirmation(false)}
        onConfirm={(data?: PostWriteRequest) => {
          setIsConfirmation(false);
          props.onComplete && props.onComplete(data);
        }}
      >
        <div
          className={clsx(
            "flex flex-row items-center gap-x-2 rounded-lg border-2 bg-white p-3 shadow-sm",
            borderColor
          )}
        >
          <div
            className={clsx(
              "h-10 w-10 flex-shrink-0 rounded-full",
              "flex items-center justify-center",
              bgColor
            )}
          >
            {Icon ? (
              <Icon className="h-6 w-6 text-white" />
            ) : (
              <span className="h-5 w-5 rounded-full border-2 border-white" />
            )}
          </div>
          <div className="flex-grow text-left text-sm font-normal leading-5 text-zinc-900">
            {props.activity.name}
          </div>
        </div>
      </ChallengeConfirmationWithProofModal>
    </>
  );
};
