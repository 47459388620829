/**
 *
 *
 * TeamMembers <MemberCard />
 *
 *
 *
 */
import { ArrowsPointingOutIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { _PeopleResponse } from "services/teambuilder/schemas";

import { Avatar } from "../../components/Avatar";
import { Button } from "../../components/ButtonV3";
import { fullName } from "../../utils/username";
import { UserDropdown } from "./UserDropdown";

interface Props {
  member: _PeopleResponse;
  currentUserIsAdmin: boolean;
  onEdit: () => void;
  onRemove: () => void;
  onEmailInvite: () => void;
  openProfileModal: (id: number) => void;
}

export const MemberCard = (props: Props) => {
  const { t } = useTranslation();
  return (
    <li
      className={clsx(
        "col-span-1 flex flex-col justify-start",
        "relative h-fit rounded-xl bg-white text-center",
        "border border-zinc-400",
        "min-h-full"
      )}
    >
      {props.currentUserIsAdmin && (
        <div className="absolute right-2.5 top-1">
          <UserDropdown
            member={props.member}
            onEdit={props.onEdit}
            onRemove={props.onRemove}
            onEmailInvite={props.onEmailInvite}
          />
        </div>
      )}

      <div className="pt-b flex flex-1 flex-col items-center justify-start px-4 pb-4 pt-8">
        <div className="flex flex-grow-0 justify-center">
          <Avatar user={props.member} size="xxl" />
        </div>

        <h3 className="mt-4 text-sm font-medium text-zinc-900">
          {fullName(props.member)}
        </h3>

        <dl className="mt-1">
          {props.member.jobTitle && (
            <>
              <dt className="sr-only">{t("translation:common:title")}</dt>
              <dd className="mb-1 text-sm text-zinc-900">
                {props.member.jobTitle}
              </dd>
            </>
          )}
          <dt className="sr-only">{t("translation:common:email")}</dt>
          <dd className="mb-1 truncate text-sm text-zinc-600">
            {props.member.email}
          </dd>
        </dl>
      </div>

      <div className="flex items-center justify-center rounded-b-xl bg-zinc-100 py-4">
        <Button
          variant="contained"
          size="sm"
          onClick={() => props.openProfileModal(props.member.id || 0)}
        >
          <ArrowsPointingOutIcon className="h-5 w-5" />
          {t("translation:common:user_guide")}
        </Button>
      </div>
    </li>
  );
};
