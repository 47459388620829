import { z } from "zod";

// Helper function to check if a string is a valid email
export const isEmail = (str: string) => {
  try {
    z.string().email().parse(str);
    return true;
  } catch {
    return false;
  }
};

// Helper function to check if a string is a valid domain
export const isDomain = (str: string) => {
  try {
    // Check if the string can be a URL and does not contain protocol
    z.string().url().parse(str);
    return true;
  } catch {
    return false;
  }
};

/**
 * Returns true if the string contains any url-like pattern, with any combination of {word}.{word}.
 *
 * Here's a breakdown of the pattern:
 * \b is a word boundary that matches the position between a word character and a non-word character.
 * \w+ matches one or more word characters (letters, digits, or underscores), representing the first word.
 * \. matches a literal period character.
 * \w+ matches one or more word characters again, representing the second word.
 * (?:\.\w+\.\w+)* is a non-capturing group that matches zero or more occurrences of the pattern .\w+.\w+. This pattern matches a period followed by a word, followed by another period, followed by another word. The ?: at the beginning of the group makes it a non-capturing group, which means it doesn't create a separate capturing group in the match results.
 * \b is another word boundary to ensure that the last word is not part of a larger word.
 */
export const isDomainLike = (str: string) =>
  /\b\w+\.\w+(?:\.\w+\.\w+)*\b/.test(str);
