/**
 *
 *
 * useBanterToolbox
 *
 *
 */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TEAM_EVERYONE_ID } from "../../consts";
import { useToast } from "../../hooks/useToast";
import {
  getBanterTopicsListQueryKey,
  useBanterTopicsDestroy,
  useBanterTopicsList,
} from "../../services/teambuilder/endpoints/banter/banter";
import { useTeamsRetrieve } from "../../services/teambuilder/endpoints/teams/teams";
import {
  BanterTopicsListParams,
  PaginatedTopicsModelList,
  TopicsModel,
} from "../../services/teambuilder/schemas";
import { optimisticMutationOptions } from "../../utils/optimistic-update";

export const useBanterToolbox = () => {
  const { t } = useTranslation();
  const LIMIT = 999;
  const { openToast } = useToast();
  const teamId = TEAM_EVERYONE_ID;

  /**
   * Team request
   */
  const { data: team } = useTeamsRetrieve(teamId, {
    query: {
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:load_team_failed"),
        });
      },
    },
  });

  /**
   * Topic delete confirmation modal state
   */
  const [selectedTopic, setSelectedTopic] = useState<TopicsModel>();

  /**
   * Topic update slide-over state
   */
  const [isTopicFormOpen, setIsTopicFormOpen] = useState(false);
  const openTopicForm = (topic?: TopicsModel) => {
    if (topic) setSelectedTopic(topic);
    setIsTopicFormOpen(true);
  };
  const closeTopicForm = () => {
    setIsTopicFormOpen(false);
    setSelectedTopic(undefined);
  };

  /**
   * Get topics request
   */
  const topicsListParams = {
    team: teamId,
    limit: LIMIT,
    ordering: "-created_at",
  } as BanterTopicsListParams;

  const topicsListQueryKey = getBanterTopicsListQueryKey(topicsListParams);

  const { data: topics, isLoading: isLoadingTopics } = useBanterTopicsList(
    topicsListParams,
    { query: { select: ({ data, meta }) => ({ data, meta }) } }
  );

  /**
   *
   * Delete Confirmation Modal
   *
   */
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  /**
   * Delete topic request
   */

  const { mutate: destroyTopic } = useBanterTopicsDestroy(
    optimisticMutationOptions<void, PaginatedTopicsModelList, { id: number }>({
      queryKey: topicsListQueryKey,
      optimisticUpdateFn: (context, requestVariables) => {
        setIsDeleteConfirmationModalOpen(false);
        return {
          ...context,
          data: context.data.filter((item) => item.id !== requestVariables?.id),
        };
      },
      onSuccess: () => {
        openToast({
          title: t("translation:common:success"),
          description: t("translation:toast:topic_delete_success"),
        });
      },
      onError: () => {
        openToast({
          title: t("translation:common:error"),
          description: t("translation:toast:topic_delete_failed"),
          type: "danger",
        });
      },
    })
  );

  const [topic, setTopic] = useState<TopicsModel | null>(null);

  return {
    isLoadingTopics,
    topics,
    isTopicFormOpen,
    openTopicForm,
    closeTopicForm,
    selectedTopic,
    topicsListQueryKey,
    team,
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
    destroyTopic,
    topic,
    setTopic,
  };
};
